const initState = {
    message: null,
    AppointmentsList: [],
    AppointmentsDocsList: [],
    NotesList: [],
    MedicalHistoryList: [],
    SocialHistoryList: [],
    MedicationList: [],
    MedicationsHistoryList: [],
    AllergiesHistoryList: [],
    ChatsHistoryList: [],
    UserInfo: [],
    PrescriptionList: []
}
const appointmentReducer = (state = initState, action) => {
    switch (action.type) {
        case 'EMR_APPOINTMENT_LIST':
            return {
                ...state,
                AppointmentsList: action.data,
                message: ''
            }
        case 'EMR_APPOINTMENTDOCS_LIST':
            return {
                ...state,
                AppointmentsDocsList: action.data,
                message: ''
            }
        case 'EMR_USER_INFO':
            return {
                ...state,
                UserInfo: action.data,
                message: ''
            }
        case 'EMR_NOTES_LIST':
            return {
                ...state,
                NotesList: action.data,
                message: '',
            }
        case 'EMR_NOTES_ERROR':
            return {
                ...state,
                NotesList: state.NotesList,
                message: action.message
            }
        case 'EMR_MEDICAL_HISTORY_LIST':
            return {
                ...state,
                MedicalHistoryList: action.data,
                message: ''
            }
        case 'EMR_MEDICAL_HISTORY_ERROR':
            return {
                ...state,
                MedicalHistoryList: state.MedicalHistoryList,
                message: action.message
            }
        case 'EMR_SOCIAL_HISTORY_LIST':
            return {
                ...state,
                SocialHistoryList: action.data,
                message: ''
            }
        case 'EMR_SOCIAL_HISTORY_ERROR':
            return {
                ...state,
                SocialHistoryList: state.SocialHistoryList,
                message: action.message
            }
        case 'EMR_MEDICATION_LIST':
            return {
                ...state,
                MedicationsHistoryList: action.data,
                message: ''
            }
        case 'UPDATE_MEDICATION':
            return {
                ...state,
                MedicationList: action.medications,
                message: ''
            }
        case 'EMR_MEDICATION_ERROR':
            return {
                ...state,
                MedicationsHistoryList: state.MedicationsHistoryList,
                message: action.message
            }
        case 'EMR_ALLERGIES_LIST':
            return {
                ...state,
                AllergiesHistoryList: action.data,
                message: ''
            }
        case 'EMR_ALLERGIES_ERROR':
            return {
                ...state,
                AllergiesHistoryList: state.AllergiesList,
                message: action.message
            }
        case 'EMR_CHAT_LIST':
            return {
                ...state,
                ChatsHistoryList: action.data,
                message: ''
            }
        case 'EMR_CHAT_ERROR':
            return {
                ...state,
                ChatsHistoryList: state.ChatsHistoryList,
                message: action.message
            }
        case 'EMR_PRESCRIPTION_LIST':
            return {
                ...state,
                PrescriptionList: action.data,
                message: ''
            }
        case 'RESET STATE':
            return {
                ...initState
            }
        default:
            return state;
    }
}

export default appointmentReducer