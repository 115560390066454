import React from 'react'
import { usePlacesWidget } from "react-google-autocomplete";
import { GOOGLEMAPKEY } from '../../config'
import { TextField } from '@material-ui/core'
var AddressParser = require('parse-google-address');
const GoogleStreetAddress = (props) => {
  const { ref } = usePlacesWidget({
    apiKey:GOOGLEMAPKEY,
    onPlaceSelected: (place) => {
     
	 AddressParser.Parse_Reverse_GeoCode_Address(place.address_components, function (err, ParsedData) {
			if (err) console.error(err);
			//console.log("Parsed Data--->", ParsedData);
			props.setSelectedPlace(ParsedData);			
		});
	 
    },
	options: {
	  types: ["address"]
	},
  });

  return <TextField
			inputRef={ref}
			value={props.street_adderss}
			label="Street Address *"
			style={{ marginBottom: '24px' }}	
			onChange={(e) => { props.setStreetAddress(e.target.value) }}	
			error={props.street_adderss === '' && !props.isFormValid ? true : false}
			helperText={props.street_adderss === '' && !props.isFormValid ? "Street Address is required" : ""}
										
		/>
}

export default GoogleStreetAddress