import React, { Component } from 'react'
import withStyles from "@material-ui/core/styles/withStyles"
import { Grid, Paper } from '@material-ui/core'
import SignupSidebar from '../registration/SignupSidebar'
import SignupIcon from '../../assets//images/signupIcon.png'
import { isMobile } from "react-device-detect"
import RegisterChoice from './registerChoice'
import SomeOneElse from './someOneElse'
import GetPatientName from './getPatientName'
import PersonalInfo from './personalInfo'
// import AddressInfo from './addressInfo'
import ContactInfo from './contactInfo'
// import HealthCard from './healthCard'
// import RhynoGo from './RhynoGo'
import Password from './password'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { invitedLinkVisited } from '../../store/actions/authActions'


const styles = (theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    font: {
        fontFamily: 'revert',
    },
    formOuterWrapper: {
        display: 'flex',
        flex: 1,
        minHeight: '100vh',
        padding: "100px 150px",
        marginLeft: '35%',
        backgroundColor: "#fafafa",
        [theme.breakpoints.down(1600)]: {
            padding: "50px 80px",
        },
        [theme.breakpoints.down(1366)]: {
            padding: "40px 60px",
        },
        [theme.breakpoints.down(1100)]: {
            marginLeft: '0',
            padding: "15px",
        },
    },
    signupIcon: {
        width: 50,
        height: 50,
        margin: '0 auto 20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        backgroundColor: '#785caf',
        borderRadius: '50%'
    },
    signupIconImg: {
        width: '61%'
    },
    title: {
        color: '#1cb5c5',
        fontSize: 38,
        textAlign: 'center',
        margin: '0 0 25px',
        [theme.breakpoints.down(767)]: {
            fontSize: 24,
        },
    },
})
class Steps extends Component {
    constructor(props) {
        super(props)
        let url = new URL(window.location.href)
        let invitedBy = url.searchParams.get('invitedby')
        let invitedEmail = url.searchParams.get('email')
        let disabledEmail = false
        if (invitedEmail)
            disabledEmail = true
        this.state = {
            step: 3,
            registerData: { invitedBy: invitedBy ? invitedBy : '', email: invitedEmail, disabledEmail, accountType: 'own', guardian_full_name: '', guardian_relationship: '', isLegalGuardian: '' }
        }
        this._isMounted = false;
        if (invitedBy) {
            props.invitedLinkVisited(JSON.stringify({ invitedBy: invitedBy, type: "patient", action: 'visited' }))
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true
    }
    updateStepNo = async (step, registerData) => {
        if (this._isMounted) {
            await this.setState({ step: step, registerData: { ...this.state.registerData, ...registerData } })
        }
    }
    render() {
        const { classes, auth } = this.props;
        if (auth.uid && auth.hasOwnProperty('is2FValidate') && auth.is2FValidate) return <Redirect to='/dashboard' />
        if (auth.signedInUserId && auth.isVerified === false) return <Redirect to='/verify' />
        return (
            <div>
                <div className={classes.container + " negativeMarign"}>
                    {isMobile ?
                        ""
                        :
                        <SignupSidebar />
                    }
                    <div className={classes.formOuterWrapper + " formOuterWrapper"}>
                        <Paper className={classes.paper}>
                            <div className={classes.signupIcon}>
                                <img className={classes.signupIconImg} src={SignupIcon} alt={"SignUP Icon"} />
                            </div>
                            <h2 className={classes.title}>CREATE AN ACCOUNT</h2>
                            <Grid className={classes.font}>
                                {
                                    this.state.step === 1 ?
                                        <RegisterChoice updateStepNo={this.updateStepNo} registerData={this.state.registerData} />
                                        :
                                        this.state.step === 2 ?
                                            <SomeOneElse updateStepNo={this.updateStepNo} registerData={this.state.registerData} />
                                            :
                                            this.state.step === 3 ?
                                                <GetPatientName updateStepNo={this.updateStepNo} registerData={this.state.registerData} />
                                                :
                                                this.state.step === 4 ?
                                                    <PersonalInfo updateStepNo={this.updateStepNo} registerData={this.state.registerData} />
                                                    :
                                                    this.state.step === 5 ?
                                                        // <AddressInfo updateStepNo={this.updateStepNo} registerData={this.state.registerData} />
                                                        // :
                                                        // this.state.step === 6 ?
                                                        //     <HealthCard updateStepNo={this.updateStepNo} registerData={this.state.registerData} />
                                                        //     :
                                                        //     this.state.step === 7 ?
                                                        //         <RhynoGo updateStepNo={this.updateStepNo} registerData={this.state.registerData} />
                                                        //         :
                                                        //         this.state.step === 8 ?
                                                        <ContactInfo updateStepNo={this.updateStepNo} registerData={this.state.registerData} />
                                                        :
                                                        this.state.step === 6 ?
                                                            <Password updateStepNo={this.updateStepNo} registerData={this.state.registerData} />
                                                            :
                                                            "None"
                                }
                            </Grid>
                        </Paper>
                    </div>
                </div>
            </div>

        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        invitedLinkVisited: (data) => invitedLinkVisited(data),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Steps));
