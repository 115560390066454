import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import ResponsiveDrawer from './components/layout/navbar'
import Footer from './components/layout/footer'
import SignIn from './components/registration/signin'
import ForegetPassword from './components/registration/foregetpassword'
import SignUp from './components/patientSignup/steps'
import Register from './components/registration/register'
import TC from './components/registration/TC'
import { Dashboard } from './components/dashboard/Dashboard'
import RhynoGoEMR from './components/RhynoGoEMR/RhynoGoEMR'
import Chat from './components/chat/chat'
// import MobileChat from './components/mobileChat/chat'
import Profile from './components/profile/profile'
import Contact from './components/contact/contact'
// import BookNow from './components/appointment/booknow'
import Verify from './components/verify/verify'
import Billing from './components/billing/billing'
import BillingHistory from './components/billing/billingHistory'
import TwoFactor from './components/twoFactor/twoFactor'
import Appointment from './components/appointment/appointment'
import Membership from './components/membership/membership'
// import ProviderList from './components/appointment/providerlist'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import Notification from '../src/components/notifications/Notifications'
import AllAppointments from './components/dashboard/AllAppointments/AllAppointments'
import Covid19Form from './components/covidform/Covid19Form'
import AgentOrPharmacyRegister from './components/agentOrPharmacyRegister/AgentOrPharmacyRegister'
import CreateRequisition from './components/requisitions/createRequisition'
import RequisitionList from './components/requisitions/RequisitionList'
import AddPatients from './components/subAccounts/AddPatients'
import SetPassword from './components/subAccounts/setPassword/SetPassword'
import CookiesModal from './components/cookies/CookiesModal'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3, 3, 0),
  },
  divContent: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    minHeight: 'calc(100vh - ' + theme.spacing(11) + 'px)',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100vw - ' + theme.spacing(6) + 'px)'
    }
  }
}));
let counter = 0;
function Main(props) {
  let [numberOfPageCalls, setNumberOfPageCalls] = useState(0)
  const setTotalCalls = (numberOfCalls, numberOfCounter) => {
    setNumberOfPageCalls(numberOfCalls)
    counter = 1
  }
  counter++
  const classes = useStyles();
  return (
    <div>
      {/* SKYPE CHAT 12 APR 2022
        CHAT DESCRIPTION: Can you have a script on entering the site to detect if cookies are enabled or not - if not enabled a popup can show that coockies are not enabled, please enable before continuing?
        */}
      <CookiesModal />
      <div style={{ display: counter >= numberOfPageCalls ? 'none' : 'block' }} className="automargin"><CircularProgress size={30} thickness={5}></CircularProgress></div>
      <BrowserRouter>
        <div style={{ visibility: counter < numberOfPageCalls ? 'hidden' : 'visible' }} className={classes.root}>
          <Notification />
          <ResponsiveDrawer />
          <Switch>
            <React.Fragment>
              <main className={classes.content}>
                <div className={classes.toolbar} />
                <div className={classes.divContent}>
                  <Route path='/signin' render={props => <SignIn setTotalCalls={setTotalCalls} isLoggedIn={false} isValid={false} />} />
                  <Route path='/forgetpassword' render={props => <ForegetPassword setTotalCalls={setTotalCalls} />} />
                  <Route path='/signup' render={props => <SignUp setTotalCalls={setTotalCalls} />} />
                  <Route path='/register' render={props => <Register setTotalCalls={setTotalCalls} />} />
                  <Route path='/dashboard' render={props => <Dashboard setTotalCalls={setTotalCalls} />} />
                  <Route path='/appointment' render={props => <Appointment setTotalCalls={setTotalCalls} />} />
                  <Route path='/all_appointments' render={props => <AllAppointments setTotalCalls={setTotalCalls} />} />
                  <Route path='/patients' render={props => <AddPatients setTotalCalls={setTotalCalls} />} />
                  <Route path='/requisition' render={props => <CreateRequisition setTotalCalls={setTotalCalls} />} />
                  <Route path='/requisitions_list' render={props => <RequisitionList setTotalCalls={setTotalCalls} />} />
                  {/* <Route path='/providerlist' render={props => <ProviderList setTotalCalls={setTotalCalls} />} /> */}
                  {/* <Route path='/book/:id' render={props => <BookNow {...props} setTotalCalls={setTotalCalls} TotalCalls={1} />}  /> */}
                  {/* <Route exact path='/emr/:id' render={props => <EMR {...props} setTotalCalls={setTotalCalls} TotalCalls={0} />} />
                  <Route exact path='/emr' render={props => <EMR {...props} setTotalCalls={setTotalCalls} TotalCalls={0} />} /> */}
                  <Route exact path='/emr/:id' render={props => <RhynoGoEMR {...props} setTotalCalls={setTotalCalls} TotalCalls={0} />} />
                  <Route exact path='/emr' render={props => <RhynoGoEMR {...props} setTotalCalls={setTotalCalls} TotalCalls={0} />} />
                  <Route path='/verify' render={props => <Verify setTotalCalls={setTotalCalls} />} />
                  <Route path='/billing' render={props => <Billing setTotalCalls={setTotalCalls} />} />
                  <Route path='/billing_history' render={props => <BillingHistory setTotalCalls={setTotalCalls} />} />
                  <Route path='/account' render={props => <Profile setTotalCalls={setTotalCalls} />} />
                  <Route path='/contact' render={props => <Contact setTotalCalls={setTotalCalls} />} />
                  <Route path='/TFAuthenticate' render={props => <TwoFactor setTotalCalls={setTotalCalls} />} />
                  <Route exact path='/chat/:doctorId/:userId/:appointmentId' render={props => <Chat {...props} setTotalCalls={setTotalCalls} TotalCalls={12} />} />
                  {/* <Route exact path='/mobileChat/:doctorId/:userId/:appointmentId' render={props => <MobileChat {...props} setTotalCalls={setTotalCalls} TotalCalls={12} />} /> */}
                  <Route exact path='/membership' render={props => <Membership setTotalCalls={setTotalCalls} TotalCalls={0} />} />
                  <Route exact path='/covid19form/:appointmentTime' render={props => <Covid19Form {...props} setTotalCalls={setTotalCalls} TotalCalls={0} />} />
                  <Route exact path='/tc' render={props => <TC />} />
                  <Route exact path='/agent_register' render={props => <AgentOrPharmacyRegister isAgent={1} setTotalCalls={setTotalCalls} />} />
                  <Route exact path='/pharmacy_register' render={props => <AgentOrPharmacyRegister isPharmacy={1} setTotalCalls={setTotalCalls} />} />
                  <Route exact path='/set_password/:id' render={props => <SetPassword setTotalCalls={setTotalCalls} />} />
                  <Route exact path='/' render={props => <Dashboard setTotalCalls={setTotalCalls} />} />
                  <Footer />
                </div>
              </main>
            </React.Fragment>
          </Switch>
        </div>
      </BrowserRouter>
    </div>
  );
}

Main.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.any,
};
const mapStateToProps = (state) => {
  return {
    state: state
  };
};
export default connect(mapStateToProps, null)(Main);
