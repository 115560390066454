import React, { useState, useEffect } from 'react'
//redux stuff
import { connect } from 'react-redux'
//others
import { confirmAlert } from 'react-confirm-alert'
import { updateAppointmentStatus, verifyHC } from '../../../store/actions/appointmentAction'
//External components
import StripeCheckOut from '../../appointment/stripe'
//components
import CalendarModal from './CalendarModal'
//helper functions
import { updateResults } from './utils'
//AntdD
import { Modal } from 'antd'
import styled from 'styled-components'
//MUI
import { Button, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

const StyledModal = styled(Modal)`
.ant-modal-title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    display:flex;
}
.ant-modal-header{
    border-bottom:none;
    background: rgb(0,178,181);
    border-radius: 1rem 1rem 0 0;
}
.ant-modal-body{
    text-align: center;
    padding:0;
    margin-bottom: 2rem;
}
.ant-modal-content{
    border-radius: 1rem;
}
.ant-modal-footer{
    text-align:center;
    border-top:none;
}
`

const styles = theme => ({
    cancelChangeButton: {
        width: '100%',
        marginTop: '3%',
        backgroundColor: 'rgb(240, 242, 245)',
        color: theme.palette.lightGrey.main,
        padding: "7px",
        borderRadius: '10px',
        border: 'none',
        height: '50px',

    },
    acceptButton: {
        width: '100%',
        borderRadius: '10px',
        border: 'none',
        color: 'white',
        height: '50px',
        padding: "7px",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: "#1f4037"
        },
    },
    modalMessage: {
        marginTop: '7%'
    },
    modalTitle: {
        color: 'white',
    }

})

const ChangedRequestsModal = (props) => {

    const [showModal, setShowModal] = useState(false)
    const [stripeOB, setStripeOb] = useState(
        { show: false, id: '', status: '', doctorId: '', paidamount: 0, holdamount: 0 }
    )
    const [calendarAndAppointmentData, setCalendarAndAppointmentData] = useState(
        { show: false, id: '', doctorId: '', userId: '', doctorName: '', type: "", appointementType: '' }
    )

    const { classes, toggleModal, modalVisible, row, changeData, userId, fullName, } = props;
    // console.log(row)
    // console.log(props)
    const acceptAppointment = async (id, status, doctorId, userId, fullName, stripeKey = '', hold = 0,) => {
        let title = "Are you sure to accept the appointment time change?";
        confirmAlert({
            title: 'Confirm',
            message: title,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        let dataObject = {
                            id: id,
                            status: status,
                            doctorId: doctorId,
                            userId: userId,
                            userName: fullName
                        }
                        if (stripeKey === '-1') {
                            dataObject.bookhCard = props.parentProps.account.data.hCard
                            dataObject.bookdateofHCExp = Math.floor(new Date(props.parentProps.account.data.dateofHCExp).getTime() / 1000)
                        }
                        else if (stripeKey !== '') {
                            dataObject.stripetoken = stripeKey
                            dataObject.holdamount = hold
                        }
                        let result = await props.updateAppointmentStatus(JSON.stringify(dataObject))
                        changeData(updateResults(result.data))
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        //   setLoading(false)
                    }
                }
            ]
        });
    }
    const cancelChangeAppointment = async (id, doctorId, userId, doctorName, appointmentType, start, status,
        duration, addedBy, holdamount, urgent) => {
        await setCalendarAndAppointmentData({
            show: true, start: start, status: status, duration: duration,
            id: id, doctorId: doctorId, userId: userId, doctorName: doctorName,
            type: "update", appointmentType: appointmentType, addedBy: addedBy,
            holdamount: holdamount, urgent: urgent
        })
        toggleModal()
    }
    const checkStripePayment = async (id, doctorId, holdamount) => {
        let result = await props.verifyHC(JSON.stringify(
            {
                doctorId: doctorId,
                card: props.parentProps.account.data.hCard,
                expiryDate: Math.floor(new Date(props.parentProps.account.data.dateofHCExp).getTime() / 1000),
                state: props.parentProps.account.data.state
            }))
        if (result.status === 1) {
            acceptAppointment(id, 'accepted', doctorId, userId, fullName, '-1')
        } else {
            let object = { show: true, id: id, status: 'accepted', doctorId: doctorId, paidamount: 0, holdamount: holdamount }
            await setStripeOb(object)
            document.getElementById('stripeButton').childNodes[0].click();
            //   setLoading(false)
        }
    }
    const updateAppointmentStripe = (data) => {
        paidAndScheduledAppointment(data.id, data.status, data.doctorId, userId, fullName, data.stripetoken, data.holdamount)
    }
    const onCancel = () => {
        setCalendarAndAppointmentData({ showCalendar: false, id: '', doctorId: '', userId: '', doctorName: '', type: '' })
    }
    const paidAndScheduledAppointment = async (id, status, doctorId, userId, fullName, stripeKey = '', hold = 0,) => {
        let title = "Your appointment has been scheduled";
        confirmAlert({
            title: 'Confirm',
            message: title,
            buttons: [
                {
                    label: 'Close and confirm',
                    onClick: async () => {
                        let dataObject = {
                            id: id,
                            status: status,
                            doctorId: doctorId,
                            userId: userId,
                            userName: fullName
                        }
                        if (stripeKey === '-1') {
                            dataObject.bookhCard = props.parentProps.account.data.hCard
                            dataObject.bookdateofHCExp = Math.floor(new Date(props.parentProps.account.data.dateofHCExp).getTime() / 1000)
                        }
                        else if (stripeKey !== '') {
                            dataObject.stripetoken = stripeKey
                            dataObject.holdamount = hold
                        }
                        let result = await props.updateAppointmentStatus(JSON.stringify(dataObject))
                        changeData(updateResults(result.data))
                    }
                }
            ]
        });
    }
    useEffect(() => {
        setShowModal(modalVisible)
        return () => {

        }
    }, [modalVisible])
    return (
        <div>
            {/* To pay for appointments that are added by doctors */}
            <StripeCheckOut appointmentData={stripeOB} addAppointment={updateAppointmentStripe} />
            {/* To cancel of change appointment dates */}
            {calendarAndAppointmentData.show ?
                <CalendarModal
                    cancel={onCancel}
                    changeData={changeData}
                    parentProps={props.parentProps}
                    appointmentData={calendarAndAppointmentData}
                />
                : null}
            <StyledModal
                visible={showModal}
                width={515}
                closable={true}
                zIndex={2000}
                onCancel={() => toggleModal()}
                title={
                    <Typography className={classes.modalTitle}>
                        Accept / Change Appointment
                </Typography>
                }
                footer={
                    <div className={classes.buttons}>
                        {row !== undefined ?
                            <>
                                {row.addedBy === 'doctor' ?
                                    <Button
                                        variant={"contained"}
                                        className={classes.acceptButton}
                                        onClick={() => {
                                            checkStripePayment(row.id, row.doctorId, row.holdamount,)
                                            toggleModal()
                                        }
                                        }>
                                        {/* Accept Appointment and pay  */}
                            Accept
                        </Button>
                                    :
                                    <Button
                                        variant={"contained"}
                                        className={classes.acceptButton}
                                        onClick={() => {
                                            acceptAppointment(row.id, 'accepted', row.doctorId, userId, fullName)
                                            toggleModal()
                                        }
                                        }>
                                        {/* Accept schedule change */}
                            Accept
                        </Button>
                                }
                                <Button className={classes.cancelChangeButton}
                                    onClick={() => {
                                        cancelChangeAppointment(row.id, row.doctorId, userId,
                                            row.doctorName, row.appointmentType, row.start, row.status,
                                            row.duration, row.addedBy, row.holdamount, row.urgent)
                                    }}
                                    variant='contained'>
                                    Cancel/Change
                    </Button>
                            </>
                            :
                            null}

                    </div>
                }
            >
                <div>
                    <Typography className={classes.modalMessage}>
                        {row !== undefined ?
                            row.acceptAppointmentMessage
                            :
                            null}
                    </Typography>
                </div>
            </StyledModal>
        </div>
    )
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateAppointmentStatus: (data) => updateAppointmentStatus(data),
        verifyHC: (data) => verifyHC(data)
    }
}
export default connect(null, mapDispatchToProps)(withStyles(styles)(ChangedRequestsModal))