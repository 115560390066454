import React, { useState } from 'react'
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Grid } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { panelStyle, chatPanelStyle, header, headerWithSort, mainStyle } from './style'
import NotesReqs from './NotesReqs'
import Appointments from './Appointments'
import SocialHistory from './SocialHistory'
import MedicalHistory from './MedicalHistory'
import ProgressTemplates from './ProgressTemplates'
import MedicationHistory from './MedicationHistory'
import AllergyHistory from './AllergyHistory'
import DocumentsList from './DocumentsList'
import ChatHistory from './Chats/ChatsHistory'
import { useSelector } from 'react-redux'

function SectionItem({ type, patientId, patientName, isRhynoGoPatient, noteTemplateDrag }) {

    const [expanded, setExpanded] = useState(true)

    const profile = useSelector(state => state.auth)

    const label = {
        "notes": "Notes/Reqs/Referrals",
        "appointments": "Appointments",
        "templates": "Progress Note Templates",
        "medical": "Medical History",
        "social": "Social History",
        "medication": "Current Medication",
        "allergy": "Allergies History",
        "documents": "Documents/Referrals from patient",
    }

    let headerStyle = header
    if (['templates', 'medical', 'medication', 'social', 'allergy'].indexOf(type) > -1)
        headerStyle = headerWithSort

    return (
        <Grid style={mainStyle}>
            {type === 'chat' ?
                <div style={chatPanelStyle} >
                    <ChatHistory patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} type={type} patientId={patientId} doctorId={profile && profile.uid} />
                </div>
                :
                <ExpansionPanel
                    style={panelStyle}
                    expanded={expanded} onChange={() => setExpanded(!expanded)}>
                    <ExpansionPanelSummary
                        expandIcon={type === 'chat' ? '' : <ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography style={headerStyle}>{label[type]}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        {
                            type === 'notes' ? <NotesReqs type={type} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} patientId={patientId} doctorId={profile && profile.uid} appointmentId={""} /> :
                                type === 'appointments' ? <Appointments patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} type={type} patientId={patientId} doctorId={profile && profile.uid} /> :
                                    type === 'documents' ? <DocumentsList patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} type={type} patientId={patientId} doctorId={profile && profile.uid} /> :
                                        type === 'templates' ? <ProgressTemplates noteTemplateDrag={noteTemplateDrag} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} type={type} patientId={patientId} doctorId={profile && profile.uid} /> :
                                            type === 'medical' ? <MedicalHistory patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} type={type} patientId={patientId} doctorId={profile && profile.uid} /> :
                                                type === 'social' ? <SocialHistory patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} type={type} patientId={patientId} doctorId={profile && profile.uid} /> :
                                                    type === 'medication' ? <MedicationHistory patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} type={type} patientId={patientId} doctorId={profile && profile.uid} /> :
                                                        type === 'allergy' ? <AllergyHistory patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} type={type} patientId={patientId} doctorId={profile && profile.uid} /> :
                                                            ""
                        }
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            }
        </Grid >
    )
}

export default SectionItem
