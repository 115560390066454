import React from 'react'
import moment from 'moment-timezone'
//MUI
import { Grid, MenuItem, Select } from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles"
const styles = theme => ({
    label: {
        color: theme.palette.lightGrey.main,
    },
    selectInput: {
        width: '100%',
        '& .MuiSelect-root': {
            color: theme.palette.lightGrey.main,
        },
        "& .Mui-focused": {
            color: 'black'
        }
    },
})

const ZonesInput = (props) => {
    const { classes, zone, changeZone } = props
    let zonesList = []
    let zoneList = moment.tz.names()
    for (let i = 0; i < zoneList.length; i++) {
        zonesList.push(<MenuItem key={i} value={zoneList[i]}>{zoneList[i]}</MenuItem>)
    }
    return (
        <div>
            <Grid>
                <label className={classes.label}>Zone</label>
                <br></br>
                <Select
                    label="Zone:"
                    required
                    className={classes.selectInput}
                    value={zone}
                    onChange={(e) => changeZone(e.target.value)}
                // style={{ display: this.state.edit_key !== 'zone' ? 'none' : 'flex' }}
                >
                    {zonesList}
                </Select>
            </Grid>
        </div>
    )
}
export default withStyles(styles)(ZonesInput)
