import React, { Component } from 'react'
import withStyles from '@material-ui/styles/withStyles'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import { TextField, Button, Checkbox, Typography, FormControlLabel, Grid, FormLabel } from '@material-ui/core'

const ExpansionPanel = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column'
    },
}))(MuiExpansionPanelDetails);




const styles = theme => ({
    gridBorder: {
        borderBottom: '1px solid #dfdfdf'
    },
    input: {
        margin: '3px 3px',
        width: '100px'
    },
    input1: {
        margin: '3px 0px 3px 3px',
        width: '180px'
    },
    hr: {
        border: '1px solid grey',
        width: '1px',
        margin: '5px'
    },
    hepatitis: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    psa: {
        display: 'flex',
        justifyContent: 'space-between'
    }
})


class RequisitionForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            expandedItem: false,
            noOftests: 1,
            isFormValid: true,
            form: {
                glucose: false,
                random: false,
                fasting: false,
                hba1c: false,
                creatinine: false,
                uric_acid: false,
                sodium: false,
                potassium: false,
                alt: false,
                phosphatase: false,
                billirubin: false,
                albumin: false,
                lipid_assessment: false,
                therapeutic: false,
                neonatal: false,
                cbc: false,
                prothrombin: false,
                urinalysis: false,
                albumin_ratio: false,
                pregnancy: false,
                mononucleosis: false,
                rubella: false,
                prenatal: false,
                antibodies: false,
                cervical: false,
                vaginal: false,
                vaginal_rectal: false,
                chlamydia: false,
                gc: false,
                sputum: false,
                throat: false,
                wound: false,
                urine: false,
                stool_culture: false,
                stool_ova: false,
                other_swabs: false,
                acute_hepatitis: false,
                chronic_hepatitis: false,
                immune_status: false,
                hepatitisA: false,
                hepatitisB: false,
                hepatitisC: false,
                total_psa: false,
                free_psa: false,
                insured_psa: false,
                uninsured_psa: false,
                insured_vitamin: false,
                uninsured_vitamin: false,
                childAgedays: '',
                childAgehours: '',
                clinicianTel: '',
                patientTel: '',
                drugName1: '',
                drugName2: '',
                time1: '',
                time2: '',
                time3: '',
                time4: '',
                time5: '',
                time6: '',
                test1: '',
                test2: '',
                test3: '',
                test4: '',
                test5: '',
                test6: '',
                test7: '',
                test8: '',
                test9: '',
                test10: '',
                test11: '',
            }
        }
    }

    addMore = () => {
        this.setState({ noOftests: parseInt(this.state.noOftests) + 1 })
    }

    handlePanelChange = (panel) => (event, newExpanded) => {
        this.setState({ expandedItem: newExpanded ? panel : false })
    }

    handleCheckBoxChange = (e) => {
        let formData = this.state.form
        formData[e.currentTarget.name] = !this.state.form[e.currentTarget.name]
        this.setState({ form: formData })
        this.props.updateRequisitionData(formData)
    }
    handleInputBoxChange = (e) => {
        let formData = this.state.form
        formData[e.currentTarget.name] = e.currentTarget.value

        if ((e.currentTarget.name).indexOf('test') === 0 && e.currentTarget.value === '') {
            this.props.updateRequisitionData(formData)
            this.setState({ form: formData, isFormValid: false })
        }
        else {
            this.props.updateRequisitionData(formData)
            this.setState({ form: formData, isFormValid: true })
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <ExpansionPanel square expanded={this.state.expandedItem === 'panel1'} onChange={this.handlePanelChange('panel1')}>
                    <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>Biochemistry</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.glucose} onChange={this.handleCheckBoxChange} name="glucose" />}
                                label="Glucose"
                            />
                            <FormControlLabel
                                control={<Checkbox disabled={!this.state.form.glucose} checked={this.state.form.random} onChange={this.handleCheckBoxChange} name="random" />}
                                label="Random"
                            />
                            <FormControlLabel
                                control={<Checkbox disabled={!this.state.form.glucose} checked={this.state.form.fasting} onChange={this.handleCheckBoxChange} name="fasting" />}
                                label="Fasting"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.hba1c} onChange={this.handleCheckBoxChange} name="hba1c" />}
                                label="HbA1C"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.creatinine} onChange={this.handleCheckBoxChange} name="creatinine" />}
                                label="creatinine (eGFR)"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.uric_acid} onChange={this.handleCheckBoxChange} name="uric_acid" />}
                                label="Uric Acid"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.sodium} onChange={this.handleCheckBoxChange} name="sodium" />}
                                label="Sodium"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.potassium} onChange={this.handleCheckBoxChange} name="potassium" />}
                                label="Potassium"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.alt} onChange={this.handleCheckBoxChange} name="alt" />}
                                label="ALT"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.phosphatase} onChange={this.handleCheckBoxChange} name="phosphatase" />}
                                label="Alk. Phosphatase"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.billirubin} onChange={this.handleCheckBoxChange} name="billirubin" />}
                                label="Billirubin"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.albumin} onChange={this.handleCheckBoxChange} name="albumin" />}
                                label="Albumin"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.lipid_assessment} onChange={this.handleCheckBoxChange} name="lipid_assessment" />}
                                label="Lipid Assessment (includes Cholesterol, HDL-C, Triglycerides, calculated LDL-C & Chol/HDL-C ratio; individual lipid tests may be ordered in the “Other Tests” section of this form)"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.albumin_ratio} onChange={this.handleCheckBoxChange} name="albumin_ratio" />}
                                label="Albumin / Creatinine Ratio, Urine"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.urinalysis} onChange={this.handleCheckBoxChange} name="urinalysis" />}
                                label="Urinalysis (Chemical)"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.neonatal} onChange={this.handleCheckBoxChange} name="neonatal" />}
                                label="Neonatal Billirubin"
                            />
                            <div>
                                <FormLabel>Child's Age:</FormLabel>
                                <input className={classes.input} value={this.state.form.childAgedays} onChange={this.handleInputBoxChange} name="childAgedays" />
                                <FormLabel>days</FormLabel>
                                <input className={classes.input} value={this.state.form.childAgehours} onChange={this.handleInputBoxChange} name="childAgehours" />
                                <FormLabel>hours</FormLabel>
                            </div>
                            <div>
                                <FormLabel>Clinician/Practitioner’s tel. no.</FormLabel>
                                <input className={classes.input1} value={this.state.form.clinicianTel} onChange={this.handleInputBoxChange} name="clinicianTel" />
                            </div>
                            <div>
                                <FormLabel>Patient’s 24 hr telephone no.</FormLabel>
                                <input className={classes.input1} value={this.state.form.patientTel} onChange={this.handleInputBoxChange} name="patientTel" />
                            </div>
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.therapeutic} onChange={this.handleCheckBoxChange} name="therapeutic" />}
                                label="Therapeutic Drug Monitoring:"
                            />
                            <div>
                                <FormLabel>Name of Drug #1</FormLabel>
                                <input className={classes.input1} value={this.state.form.drugName1} onChange={this.handleInputBoxChange} name="drugName1" />
                            </div>
                            <div>
                                <FormLabel>Name of Drug #2</FormLabel>
                                <input className={classes.input1} value={this.state.form.drugName2} onChange={this.handleInputBoxChange} name="drugName2" />
                            </div>
                            <div>
                                <FormLabel>Time Collected #1</FormLabel>
                                <input className={classes.input} value={this.state.form.time1} onChange={this.handleInputBoxChange} name="time1" />
                                <FormLabel>hr.</FormLabel>
                                <span className={classes.hr}></span>
                                <FormLabel>#2</FormLabel>
                                <input className={classes.input} value={this.state.form.time2} onChange={this.handleInputBoxChange} name="time2" />
                                <FormLabel>hr.</FormLabel>
                            </div>
                            <div>
                                <FormLabel>Time of Last Dose #1</FormLabel>
                                <input className={classes.input} value={this.state.form.time3} onChange={this.handleInputBoxChange} name="time3" />
                                <FormLabel>hr.</FormLabel>
                                <span className={classes.hr}></span>
                                <FormLabel>#2</FormLabel>
                                <input className={classes.input} value={this.state.form.time4} onChange={this.handleInputBoxChange} name="time4" />
                                <FormLabel>hr.</FormLabel>
                            </div>
                            <div>
                                <FormLabel>Time of Next Dose #1</FormLabel>
                                <input className={classes.input} value={this.state.form.time5} onChange={this.handleInputBoxChange} name="time5" />
                                <FormLabel>hr.</FormLabel>
                                <span className={classes.hr}></span>
                                <FormLabel>#2</FormLabel>
                                <input className={classes.input} value={this.state.form.time6} onChange={this.handleInputBoxChange} name="time6" />
                                <FormLabel>hr.</FormLabel>
                            </div>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel square expanded={this.state.expandedItem === 'panel2'} onChange={this.handlePanelChange('panel2')}>
                    <ExpansionPanelSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>Hematology</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.cbc} onChange={this.handleCheckBoxChange} name="cbc" />}
                                label="CBC"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.prothrombin} onChange={this.handleCheckBoxChange} name="prothrombin" />}
                                label="Prothrombin Time (INR)"
                            />
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel square expanded={this.state.expandedItem === 'panel3'} onChange={this.handlePanelChange('panel3')}>
                    <ExpansionPanelSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography>Immunology</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.pregnancy} onChange={this.handleCheckBoxChange} name="pregnancy" />}
                                label="Pregnancy Test (Urine)"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.mononucleosis} onChange={this.handleCheckBoxChange} name="mononucleosis" />}
                                label="Mononucleosis Screen"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.rubella} onChange={this.handleCheckBoxChange} name="rubella" />}
                                label="Rubella"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.prenatal} onChange={this.handleCheckBoxChange} name="prenatal" />}
                                label="Prenatal: ABO, RhD, Antibody Screen (titre and ident. if positive)"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.antibodies} onChange={this.handleCheckBoxChange} name="antibodies" />}
                                label="Repeat Prenatal Antibodies"
                            />
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel square expanded={this.state.expandedItem === 'panel4'} onChange={this.handlePanelChange('panel4')}>
                    <ExpansionPanelSummary aria-controls="panel4d-content" id="panel4d-header">
                        <Typography>Microbiology ID & Sensitivities (if warranted)</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.cervical} onChange={this.handleCheckBoxChange} name="cervical" />}
                                label="Cervical"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.vaginal} onChange={this.handleCheckBoxChange} name="vaginal" />}
                                label="Vaginal"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.vaginal_rectal} onChange={this.handleCheckBoxChange} name="vaginal_rectal" />}
                                label="Vaginal / Rectal – Group B Strep"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.chlamydia} onChange={this.handleCheckBoxChange} name="chlamydia" />}
                                label="Chlamydia (specify source):"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.gc} onChange={this.handleCheckBoxChange} name="gc" />}
                                label="GC (specify source)"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.sputum} onChange={this.handleCheckBoxChange} name="sputum" />}
                                label="Sputum"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.throat} onChange={this.handleCheckBoxChange} name="throat" />}
                                label="Throat"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.wound} onChange={this.handleCheckBoxChange} name="wound" />}
                                label="Wound (specify source):"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.urine} onChange={this.handleCheckBoxChange} name="urine" />}
                                label="Urine"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.stool_culture} onChange={this.handleCheckBoxChange} name="stool_culture" />}
                                label="Stool Culture"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.stool_ova} onChange={this.handleCheckBoxChange} name="stool_ova" />}
                                label="Stool Ova & Parasites"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.other_swabs} onChange={this.handleCheckBoxChange} name="other_swabs" />}
                                label="Other Swabs / Pus (specify source):"
                            />
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel square expanded={this.state.expandedItem === 'panel5'} onChange={this.handlePanelChange('panel5')}>
                    <ExpansionPanelSummary aria-controls="panel5d-content" id="panel5d-header">
                        <Typography>Viral Hepatitis</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.acute_hepatitis} onChange={this.handleCheckBoxChange} name="acute_hepatitis" />}
                                label="Acute Hepatitis"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.chronic_hepatitis} onChange={this.handleCheckBoxChange} name="chronic_hepatitis" />}
                                label="Chronic Hepatitis"
                            />
                        </Grid>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.immune_status} onChange={this.handleCheckBoxChange} name="immune_status" />}
                                label="Immune Status / Previous Exposure Speicify: "
                            />
                            <Grid className={classes.hepatitis}>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.form.hepatitisA} onChange={this.handleCheckBoxChange} name="hepatitisA" />}
                                    label="Hepatitis A"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.form.hepatitisB} onChange={this.handleCheckBoxChange} name="hepatitisB" />}
                                    label="Hepatitis B"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.form.hepatitisC} onChange={this.handleCheckBoxChange} name="hepatitisC" />}
                                    label="Hepatitis C"
                                />
                            </Grid>
                            <span>
                                or order individual hepatitis tests in the “Other Tests” section below
                            </span>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel square expanded={this.state.expandedItem === 'panel6'} onChange={this.handlePanelChange('panel6')}>
                    <ExpansionPanelSummary aria-controls="panel6d-content" id="panel6d-header">
                        <Typography>Prostate Specific Antigen(PSA)</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid className={classes.gridBorder + " " + classes.psa}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.total_psa} onChange={this.handleCheckBoxChange} name="total_psa" />}
                                label="Total PSA"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.free_psa} onChange={this.handleCheckBoxChange} name="free_psa" />}
                                label="Free PSA"
                            />
                        </Grid>
                        <span>
                            Specify one below:
                        </span>
                        <FormControlLabel
                            control={<Checkbox checked={this.state.form.insured_psa} onChange={this.handleCheckBoxChange} name="insured_psa" />}
                            label="Insured – Meets OHIP eligibility criteria"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={this.state.form.uninsured_psa} onChange={this.handleCheckBoxChange} name="uninsured_psa" />}
                            label="Uninsured – Screening: Patient responsible for payment"
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel square expanded={this.state.expandedItem === 'panel7'} onChange={this.handlePanelChange('panel7')}>
                    <ExpansionPanelSummary aria-controls="panel7d-content" id="panel7d-header">
                        <Typography>Vitamin D(25-Hydroxy)</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid className={classes.gridBorder}>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.insured_vitamin} onChange={this.handleCheckBoxChange} name="insured_vitamin" />}
                                label="Insured - Meets OHIP eligibility criteria:
                                osteopenia; osteoporosis; rickets;
                                renal disease; malabsorption syndromes;
                                medications affecting vitamin D metabolism"
                            />
                            <FormControlLabel
                                control={<Checkbox checked={this.state.form.uninsured_vitamin} onChange={this.handleCheckBoxChange} name="uninsured_vitamin" />}
                                label="Uninsured - Patient responsible for payment"
                            />
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel square expanded={this.state.expandedItem === 'panel8'} onChange={this.handlePanelChange('panel8')}>
                    <ExpansionPanelSummary aria-controls="panel8d-content" id="panel8d-header">
                        <Typography>Other Tests</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid>
                            {Array.from(Array(parseInt(this.state.noOftests))).map((val, key) => {
                                return (
                                    <TextField
                                        key={key}
                                        name={"test" + (key + 1)}
                                        value={this.state.form["test" + (key + 1)]}
                                        onChange={this.handleInputBoxChange}
                                        onBlur={this.handleInputBoxChange}
                                        style={{ marginBottom: '24px' }}
                                        label={"Test" + (key + 1)}
                                        error={!this.state.isFormValid && this.state.form["test" + (key + 1)] === '' ? true : false}
                                        helperText={!this.state.isFormValid && this.state.form["test" + (key + 1)] === '' ? "Invalid Data" : ""}
                                    />
                                )
                            })
                            }
                            {this.state.noOftests < 11 && <Button className={'btnCustomClass lightGreenClasstbn'} variant="contained" color={'secondary'} onClick={this.addMore}>Add More</Button>}
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

            </div>
        );
    }
}

export default withStyles(styles)(RequisitionForm)