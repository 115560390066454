import React, { Component } from "react";
import { connect } from "react-redux";
import { Paper, TextField, Button, Fab, IconButton, Select, CircularProgress, Grid, InputLabel, FormControl, FormGroup, FormControlLabel, Checkbox, MenuItem } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { teal, deepOrange, green, grey, red } from "@material-ui/core/colors";
import { fade } from "@material-ui/core/styles/colorManipulator";
import classNames from "clsx";
import { ViewState, EditingState, IntegratedEditing } from "@devexpress/dx-react-scheduler";
import { Scheduler, Toolbar, DayView, MonthView, WeekView, DateNavigator, ViewSwitcher, Appointments, AppointmentForm, AppointmentTooltip, Resources, DragDropProvider, CurrentTimeIndicator } from "@devexpress/dx-react-scheduler-material-ui";
import { getAppointments, updateAppointmentDataAPI, getPatientsList } from '../../store/actions/appointmentAction'
import { updateProfile, getAvailability } from '../../store/actions/authActions'
import moment from 'moment'
import { connectProps } from '@devexpress/dx-react-core'
import AddIcon from '@material-ui/icons/Add'
import Lens from '@material-ui/icons/Lens'
import AccessTime from '@material-ui/icons/AccessTime'
import Notes from '@material-ui/icons/Notes'
import Close from '@material-ui/icons/Close'
import CalendarToday from '@material-ui/icons/CalendarToday'
import Create from '@material-ui/icons/Create'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { isMobileOnly } from "react-device-detect"
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import functionsList from '../../assets/js/function.js'
import { Modal } from 'antd'
import AppointmentOptions from './appointmentOptions.js'
import AVAILABILITYOPERATION from './availabilityOperation.js'
import AVAILABILITY from '../profile/availability'
import ChangeAppointmentStatusModel from './changeAppointmentStatusModel'
const AppointmentSnapTime = 5

// SKYPE CONVERSATION
// 12 APR 2022
// please confirm when status is changed to cancel OR completed via cron OR doctor clicks on End Session. in all these cases change status button will show
// ADD NEW CANCELLED STATUS AND COLOR FOR THIS IN RESOURCES

const LOCATIONS = ["Scheduled", "On-Demand", "Availability", 'Finished', 'Cancelled'];
const resources = [
  {
    fieldName: "location",
    title: "Location",
    instances: [
      { id: LOCATIONS[0], text: LOCATIONS[0], color: teal },
      { id: LOCATIONS[1], text: LOCATIONS[1], color: deepOrange },
      { id: LOCATIONS[2], text: LOCATIONS[2], color: green['A200'] },
      { id: LOCATIONS[3], text: LOCATIONS[3], color: grey['400'] },
      { id: LOCATIONS[4], text: LOCATIONS[4], color: red['900'] }
    ]
  }
];
const DeepOrangeCheckbox = withStyles({
  root: {
    color: '#ee7650',
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

// SKYPE CONVERSATION
// 12 APR 2022
// please confirm when status is changed to cancel OR completed via cron OR doctor clicks on End Session. in all these cases change status button will show
// ADD CHECKBOX FOR CANCELLED IN LEGENDS
const RedCheckbox = withStyles({
  root: {
    color: '#b71c1c',
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);
const GreyCheckbox = withStyles({
  root: {
    color: '#616161',
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);
const PurpleCheckbox = withStyles({
  root: {
    color: '#54b3aa',
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);
const styles = ({ spacing, palette, breakpoints }) => ({
  scheduler: {
    height: 'calc(100vh - 277px)',
    position: 'relative',
    [breakpoints.down('sm')]: {
      height: '100%',
      "&>div>div.MuiToolbar-regular": {
        flexDirection: 'column',
        height: '40%'
      },
      "&>div>div.MuiToolbar-regular div:last-child": {
        marginBottom: spacing(2)
      }
    }
  },
  timeDiv: {
    display: 'flex'
  },
  paper: {
    maxWidth: '100%'
  },
  heading: {
    color: '#262626',
    fontWeight: 600,
    zIndex: 101,
    position: 'relative',
  },
  calendarOptions: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '60%',
    justifyContent: 'space-between',
    [breakpoints.down('sm')]: {
      order: 2,
      width: '100%'
    }
  },
  timeHeading: {
    color: '#00aaad',
    fontWeight: 600,
    textAlign: 'left',
    textTransform: 'uppercase',
    fontSize: '1.1rem',
    margin: 0,
    alignSelf: 'center'
  },
  legendHeading: {
    color: '#795bb2',
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: 0,
    marginRight: spacing(2)
  },
  w150: {
    width: '120px !important',
    marginLeft: spacing(1) + "px !important",
    height: spacing(4),
    marginRight: '0 !important'
  },
  font: {
    fontSize: '1rem'
  },
  icon: {
    cursor: 'pointer',
  },
  timer: {
    display: 'flex',
    justifyContent: 'space-between',
    [breakpoints.down('sm')]: { flexDirection: 'column' }
  },
  timerDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    [breakpoints.down('sm')]: { flexDirection: 'column' }
  },
  flexibleSpace: {
    display: "flex",
    alignItems: "center",
    width: '100%',
    flex: 1,
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      flex: 0,
      marginTop: spacing(2)
    }
  },
  textField: {
    width: "200px",
    marginLeft: spacing(1),
    marginTop: 0,
    marginBottom: 0,
    height: spacing(4.875)
  },
  locationSelector: {
    marginLeft: spacing(1),
    height: spacing(4.875),
    [breakpoints.down('sm')]: { margin: spacing(2, 1), width: '100%' }
  },
  button: {
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
    width: "max-content",
    "@media (max-width: 800px)": {
      width: spacing(2),
      fontSize: "0.75rem"
    }
  },
  selectedButton: {
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "rgb(2, 162, 147)"
    },
    border: `1px solid ${palette.primary[400]}!important`,
    borderLeft: `1px solid ${palette.primary[50]}!important`,
    "&:first-child": {
      borderLeft: `1px solid ${palette.primary[50]}!important`,
      background: "rgb(77, 182, 172)",
      [breakpoints.down('sm')]: { width: '50%' }
    },
    "&:first-child:hover": {
      borderLeft: `1px solid ${palette.primary[50]}!important`,
      background: "rgb(38, 166, 154)"
    },
    "&:last-child": {
      borderLeft: `1px solid ${palette.primary[50]}!important`,
      background: "rgb(255, 138, 101)",
      [breakpoints.down('sm')]: { width: '50%' }
    },
    "&:last-child:hover": {
      borderLeft: `1px solid ${palette.primary[50]}!important`,
      background: "rgb(255, 112, 67)"
    }
  },
  title: {
    fontWeight: "bold",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  textContainer: {
    lineHeight: 1,
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%"
  },
  time: {
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "none"
  },
  container: {
    width: "100%",
    padding: 0,
    paddingBottom: spacing(2)
  },
  weekendCell: {
    backgroundColor: fade(palette.action.disabledBackground, 0.04),
    "&:hover": {
      backgroundColor: fade(palette.action.disabledBackground, 0.04)
    },
    "&:focus": {
      backgroundColor: fade(palette.action.disabledBackground, 0.04)
    }
  },
  weekEnd: {
    backgroundColor: fade(palette.action.disabledBackground, 0.06)
  },
  loadingClass: {
    display: 'flex',
    height: '100%',
    position: 'absolute',
    alignItems: 'center',
    width: '100%',
    zIndex: 101,
    justifyContent: 'center',
    backgroundColor: '#908c8c1c'
  },
  legends: {
    display: 'flex',
    alignItems: 'center',
  },
  flexRow: {
    flexDirection: 'row'
  },
  addButton: {
    width: 'auto !important',
    borderRadius: spacing(2.2),
    padding: spacing(1.2, 2.4),
    position: 'unset !important',
    marginLeft: spacing(2),
    color: '#fff'
  },
  FabsClass: {
    display: 'flex',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      position: 'unset',
      "&>button": {
        marginBottom: spacing(2)
      }
    }
  },
  buttonDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  appointmentOptionsDiv: {
    width: '41%',
    [breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  customFooter: {
    backgroundColor: '#fafafa',
    position: 'fixed',
    top: '93vh',
    left: '260px',
    display: 'flex',
    zIndex: 1,
    minHeight: '64px',
    padding: spacing(0, 5),
    alignItems: 'center',
    width: 'calc(100vw - 260px)',
    justifyContent: 'space-between',
    [breakpoints.down('sm')]: {
      position: 'unset',
      flexDirection: 'column',
      width: '100%',
      padding: 0,
      backgroundColor: 'transparent'
    }
  }
});

const containerStyles = theme => ({
  container: {
    width: theme.spacing(68),
    padding: 0,
    paddingBottom: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  header: {
    overflow: 'hidden',
    paddingTop: theme.spacing(0.5),
  },
  closeButton: {
    float: 'right',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 2),
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  picker: {
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0,
    },
    width: '50%',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
  },
  icon: {
    margin: theme.spacing(2, 0),
    marginRight: theme.spacing(2),
  },
  textField: {
    width: '100%',
  },
  formContainer: {
    width: '100%',
    textAlign: 'left'
  },
  label: {
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(-.5)
  }
});

const useTooltipContentStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(3, 1),
    paddingTop: 0,
    backgroundColor: theme.palette.background.paper,
    boxSizing: 'border-box',
    width: '370px',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%'
    }
  },
  contentContainer: {
    paddingBottom: theme.spacing(1.5),
  },
  text: {
    ...theme.typography.body2,
    display: 'inline-block',
  },
  title: {
    ...theme.typography.h6,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
  },
  icon: {
    verticalAlign: 'middle',
  },
  contentItemIcon: {
    textAlign: 'center',
  },
  grayIcon: {
    color: theme.palette.action.active,
  },
  colorfulContent: {
    color: ({ color }) => color[300],
  },
  lens: {
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    verticalAlign: 'super',
  },
  lens1: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    verticalAlign: 'middle',
  },
  textCenter: {
    textAlign: 'center',
  },
  dateAndTitle: {
    lineHeight: 1.1,
  },
  titleContainer: {
    paddingBottom: theme.spacing(2),
  },
  container: {
    paddingBottom: theme.spacing(1.5),
  },
  button: {
    color: '#fff !important',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2)
    }
  },
  detailDiv: {
    flex: 1
  },
  buttonDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }
}));

const Header = withStyles({}, { name: 'Header' })(({
  children, appointmentData, classes, ...restProps
}) => {
  return (
    <>
      {appointmentData.location !== 'Availability' &&
        <AppointmentTooltip.Header
          {...restProps}
          appointmentData={appointmentData}
          showOpenButton
          showCloseButton
        >
        </AppointmentTooltip.Header>
      }
    </>
  )
});

const onContentReadyStyles = makeStyles((theme) => ({
  line: {
    height: "2px",
    borderTop: `2px ${theme.palette.primary.main} dotted`,
    width: "100%",
    transform: "translate(0, -1px)"
  },
  circle: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    borderRadius: "50%",
    transform: "translate(-50%, -50%)",
    background: theme.palette.primary.main
  },
  nowIndicator: {
    position: "absolute",
    zIndex: 1,
    left: 0,
    top: ({ top }) => top
  }
}));

const onContentReady = ({
  top, ...restProps
  // #FOLD_BLOCK
}) => {
  const classes = onContentReadyStyles({ top });

  return (
    <div {...restProps} id={"onContentReady"}>
      <div className={classNames(classes.nowIndicator, classes.circle)} />
      <div className={classNames(classes.nowIndicator, classes.line)} />
    </div>
  );
};

class AppointmentFormContainerBasic extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      appointmentChanges: {},
      patientsList: { data: props.patientsList },
    };

    this.getAppointmentData = () => {
      const { appointmentData } = this.props;
      return appointmentData;
    };
    this.getAppointmentChanges = () => {
      const { appointmentChanges } = this.state;
      return appointmentChanges;
    };

    this.changeAppointment = this.changeAppointment.bind(this);
    this.commitAppointment = this.commitAppointment.bind(this);
  }

  changeAppointment({ field, changes }) {

    let nextChanges = {
      ...this.getAppointmentChanges(),
      [field]: changes,
    };
    if (field[0] === 'startDate') {
      nextChanges = {
        ...this.getAppointmentChanges(),
        [field]: changes,
        'endDate': moment(changes).add(parseInt(this.props.slotTime), 'minutes')['_d'],
      };
    }
    this.setState({
      appointmentChanges: nextChanges,
    });
  }

  commitAppointment(type) {
    const { commitChanges } = this.props;
    const appointment = {
      ...this.getAppointmentData(),
      ...this.getAppointmentChanges(),
    };
    if (type === 'deleted') {
      commitChanges({ [type]: appointment.id });
    } else if (type === 'changed') {
      commitChanges({ [type]: { [appointment.id]: appointment } });
    } else {
      commitChanges({ [type]: appointment });
    }
    this.setState({
      appointmentChanges: {},
    });
  }

  render() {
    let {
      classes,
      visible,
      visibleChange,
      appointmentData,
      cancelAppointment,
      target,
      onHide,
      patientsList
    } = this.props;

    const { appointmentChanges } = this.state;
    const displayAppointmentData = {
      ...appointmentData,
      ...appointmentChanges,
    };

    if (appointmentData && appointmentData.location === 'Availability')
      visible = false
    const isNewAppointment = appointmentData && appointmentData.id === undefined;
    if (isNewAppointment) {
      displayAppointmentData['appointment type'] = 'Scheduled'
    }
    // const isNewAppointment = true;
    const applyChanges = isNewAppointment
      ? () => this.commitAppointment('added')
      : () => this.commitAppointment('changed');

    const textEditorProps = field => ({
      variant: 'outlined',
      onChange: ({ target: change }) => this.changeAppointment({
        field: [field], changes: change.value,
      }),
      value: displayAppointmentData[field] || '',
      label: field[0].toUpperCase() + field.slice(1),
      className: classes.textField,
    });

    const pickerEditorProps = field => ({
      className: classes.picker,
      // keyboard: true,
      ampm: false,
      value: displayAppointmentData[field],
      onChange: date => this.changeAppointment({
        field: [field], changes: date ? date.toDate() : new Date(displayAppointmentData[field]),
      }),
      inputVariant: 'outlined',
      format: 'DD/MM/YYYY HH:mm',
      onError: () => null,
    });

    const cancelChanges = () => {
      this.setState({
        appointmentChanges: {},
      });
      visibleChange();
      cancelAppointment();
    };
    return (
      <AppointmentForm.Overlay
        visible={visible}
        target={target}
        fullSize={false}
        onHide={onHide}
        id={"AppointmentOverLayChildDiv"}
      >
        <div>
          <div className={classes.header}>
            <IconButton
              className={classes.closeButton}
              onClick={cancelChanges}
            >
              <Close color="action" />
            </IconButton>
          </div>
          <div className={classes.content}>
            {!isNewAppointment ?
              <div className={classes.wrapper}>
                <Create className={classes.icon} color="action" />
                <TextField
                  disabled
                  {...textEditorProps('title')}
                />
              </div>
              :
              <div className={classes.wrapper}>
                <Create className={classes.icon} color="action" />
                <FormControl className={classes.formContainer}>
                  <InputLabel className={classes.label} id="patientName">Patient Name</InputLabel>
                  <Select
                    labelId={"patientName"}
                    {...textEditorProps('title')}
                  >
                    {patientsList.map((patient, key) => {
                      return (<MenuItem style={{ fontSize: '1rem' }} value={patient.patientId} key={key}>{patient.fullName}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </div>
            }
            <div className={classes.wrapper}>
              <CalendarToday className={classes.icon} color="action" />
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDateTimePicker
                  label="Start Date"
                  minutesStep={5}
                  {...pickerEditorProps('startDate')}
                />
                <KeyboardDateTimePicker
                  label="End Date"
                  minutesStep={5}
                  {...pickerEditorProps('endDate')}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className={classes.wrapper}>
              <AccountBoxIcon className={classes.icon} color="action" />
              <TextField
                disabled
                {...textEditorProps('appointment type')}
              />
            </div>
            <div className={classes.wrapper}>
              <Notes className={classes.icon} color="action" />
              <TextField
                disabled={isNewAppointment ? false : true}
                {...textEditorProps('reason')}
                multiline
                rows="6"
              />
            </div>
          </div>
          <div className={classes.buttonGroup}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={() => {
                visibleChange();
                applyChanges();
              }}
            >
              {isNewAppointment ? 'Create' : 'Save'}
            </Button>
          </div>
        </div>
      </AppointmentForm.Overlay>
    );
  }
}
const AppointmentFormContainer = withStyles(containerStyles, { name: 'AppointmentFormContainer' })(AppointmentFormContainerBasic);

class DashboardScheduler extends Component {
  constructor(props) {
    super(props)
    // let currentViewName = 'Work Week'
    let currentViewName = 'Week'
    if (isMobileOnly)
      currentViewName = 'Day'
    this.state = {
      currentDate: new Date().getTime(),
      data: [],
      showOptions: false,
      showAvailability: false,
      appointmentData: [],
      excludedDays: [],
      currentViewName: currentViewName,
      currentFilter: "",
      dayStartTime: props.dayStartTime,
      dayEndTime: props.dayEndTime,
      locations: LOCATIONS,
      editingFormVisible: false,
      confirmationVisible: false,
      loader: false,
      loading: true,
      loadingKey: '',
      message: '',
      patientsList: { data: [] },
      availabilityActionType: '',
      availabilityActionId: '',
      availabilityActionStartTime: 0,
      availabilityActionEndTime: 0,
      addAvailabilityStartTime: new Date().getTime(),
      visibilityState: false,
      // SKYPE CONVERSATION
      // 12 APR 2022
      // please confirm when status is changed to cancel OR completed via cron OR doctor clicks on End Session. in all these cases change status button will show
      // ADD LOCAL STATE FOR UPDATING APPOINTMENT STATUS
      changeStatusAppointmentId: '',
      showChangeStatusModal: false,
      FlexibleSpace: withStyles(styles, { name: "FlexibleSpace" })(
        ({ classes, ...restProps }) => (
          <Toolbar.FlexibleSpace {...restProps} className={classes.flexibleSpace}>
            <TextField
              placeholder="Filter"
              className={classes.textField}
              onChange={({ target }) => this.onCurrentFilterChange(target.value)}
              variant="outlined"
              hiddenLabel
              margin="dense"
            />
          </Toolbar.FlexibleSpace>
        )
      )
    };

    this.currentDateChange = async (currentDate) => {
      await this.setState({ currentDate: moment(currentDate) })
      this.getAppointmentsList()
    }

    this.toggleConfirmationVisible = this.toggleConfirmationVisible.bind(this);
    this.commitDeletedAppointment = this.commitDeletedAppointment.bind(this);
    this.toggleEditingFormVisibility = this.toggleEditingFormVisibility.bind(this);

    this.onEditingAppointmentChange = this.onEditingAppointmentChange.bind(this);
    this.onAddedAppointmentChange = this.onAddedAppointmentChange.bind(this);
    this.appointmentForm = connectProps(AppointmentFormContainer, () => {
      const {
        editingFormVisible,
        editingAppointment,
        data,
        addedAppointment,
        isNewAppointment,
        previousAppointment,

      } = this.state;

      const currentAppointment = !data ? {} : data
        .filter(appointment => editingAppointment && appointment.id === editingAppointment.id)[0]
        || addedAppointment;
      const cancelAppointment = () => {
        if (isNewAppointment) {
          this.setState({
            editingAppointment: previousAppointment,
            isNewAppointment: false,
          });
        }
      };

      return {
        visible: editingFormVisible,
        appointmentData: currentAppointment,
        commitChanges: this.commitChanges,
        visibleChange: this.toggleEditingFormVisibility,
        onEditingAppointmentChange: this.onEditingAppointmentChange,
        cancelAppointment,
        patientsList: this.state.patientsList.data,
        slotTime: this.props.account.slotTime
      };
    });

    this.getAppointmentsList()
  }

  isRestTime = (date) => date.getDay() === 0 || date.getDay() === 6;

  TimeTableCell = withStyles(styles, { name: "TimeTableCell" })(
    ({ classes, ...restProps }) => {
      const { startDate } = restProps;
      if (this.isRestTime(startDate)) {
        return (
          <WeekView.TimeTableCell
            {...restProps}
            className={classes.weekendCell}
            onDoubleClick={() => this.openAvailability(restProps)}
          />
        );
      }
      return <WeekView.TimeTableCell {...restProps} onDoubleClick={() => this.openAvailability(restProps)} />;
    }
  );

  DayScaleCell = withStyles(styles, { name: "DayScaleCell" })(
    ({ classes, ...restProps }) => {
      const { startDate } = restProps;
      if (startDate.getDay() === 0 || startDate.getDay() === 6) {
        return (
          <WeekView.DayScaleCell {...restProps} className={classes.weekEnd} onDoubleClick={() => this.openAvailability(restProps)} />
        );
      }
      return <WeekView.DayScaleCell {...restProps} onDoubleClick={() => this.openAvailability(restProps)} />;
    }
  );

  MonthTimeTableCell = withStyles(styles, { name: "TimeTableCell" })(
    ({ classes, ...restProps }) => {
      return <MonthView.TimeTableCell {...restProps} onDoubleClick={() => this.openAvailability(restProps)} />;
    }
  );

  MonthDayScaleCell = withStyles(styles, { name: "DayScaleCell" })(
    ({ classes, ...restProps }) => {
      return <MonthView.DayScaleCell {...restProps} onDoubleClick={() => this.openAvailability(restProps)} />;
    }
  );

  // #FOLD_BLOCK
  TooltipContent = ({
    appointmentData, formatDate, appointmentResources,
    // #FOLD_BLOCK
  }) => {
    const resource = appointmentResources[0];
    const classes = useTooltipContentStyles({ color: resource.color });
    return (
      <>
        {appointmentData.location !== 'Availability' ?
          <div className={classes.content}>
            <Grid className={classNames(classes.detailDiv)}>
              <Grid container alignItems="flex-start" className={classes.titleContainer}>
                <Grid item xs={2} className={classNames(classes.textCenter)}>
                  <Lens className={classNames(classes.lens, classes.colorfulContent)} />
                </Grid>
                <Grid item xs={10}>
                  <div>
                    <div className={classNames(classes.title, classes.dateAndTitle)}>
                      {appointmentData.title}
                    </div>
                    <div className={classNames(classes.text, classes.dateAndTitle)}>
                      {formatDate(appointmentData.startDate, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid container alignItems="center" className={classes.contentContainer}>
                <Grid item xs={2} className={classes.textCenter}>
                  <AccessTime className={classes.icon} />
                </Grid>
                <Grid item xs={10}>
                  <div className={classes.text}>
                    {`${formatDate(appointmentData.startDate, { hour: 'numeric', minute: 'numeric' })}
                  - ${formatDate(appointmentData.endDate, { hour: 'numeric', minute: 'numeric' })}`}
                  </div>
                </Grid>
              </Grid>
              <Grid container alignItems="center" key={`${resource.fieldName}_${resource.id}`}>
                <Grid item xs={2} className={classNames(classes.textCenter)}>
                  <Lens className={classNames(classes.lens1, classes.colorfulContent)} />
                </Grid>
                <Grid item xs={10}>
                  <span>
                    {resource.text}
                  </span>
                </Grid>
              </Grid>
              <Grid container alignItems="center" key={`${resource.fieldName}_${resource.id}`}>
                <Grid item xs={2} className={classNames(classes.textCenter)}>
                  <Lens className={classNames(classes.lens1, classes.colorfulContent)} />
                </Grid>
                <Grid item xs={10}>
                  <span>
                    {appointmentData.reason}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classNames(classes.buttonDiv)}>
              {/* <Button color="primary" className={classNames(classes.button)} variant={"contained"} href={"emr/" + appointmentData.userId}>VIEW EMR</Button> */}
              <Button className={'btnCustomClass lightGreenClasstbn'} variant={"contained"} href={"emr/" + appointmentData.userId}>VIEW EMR</Button>

              {appointmentData.remainingSeconds < 300 && appointmentData.location !== 'Finished' && appointmentData.location !== 'Cancelled' ?
                <Button className={'btnCustomClass lightBlueClasstbn'} color="secondary" className={classNames(classes.button)} variant={"contained"} href={"chat/" + appointmentData.doctorId + "/" + appointmentData.userId + "/" + appointmentData.id}>SEE PATIENT</Button>
                : ""}

              {appointmentData.completedMin <= 20 && appointmentData.completedMin >= 0 && appointmentData.location === 'Finished' ?
                <Button className={'btnCustomClass lightBlueClasstbn'} variant={"contained"} href={"chat/" + appointmentData.doctorId + "/" + appointmentData.userId + "/" + appointmentData.id}>SEE PATIENT</Button>
                : <>
                  {/* SKYPE CONVERSATION */}
                  {/* 12 APR 2022 */}
                  {/* please confirm when status is changed to cancel OR completed via cron OR doctor clicks on End Session. in all these cases change status button will show */}
                  {/* SHOW THE CHANGE STATUS BUTTON FOR CANCELLED AND COMPLETED APPOINTMENTS */}
                  {appointmentData.location === 'Cancelled' || appointmentData.location === 'Finished' ?
                    <Button className={'btnCustomClass lightBlueClasstbn'} onClick={() => this.changeStatus(appointmentData.id)} variant={"contained"} >Change Status</Button>
                    :
                    ""
                  }
                </>
              }
            </Grid>
          </div>
          :
          <div className={classes.content}>
            <Grid className={classNames(classes.buttonDiv)} style={{ width: '100%' }}>
              <Button color="primary" className={classNames(classes.button)} style={{ marginBottom: '20px' }} variant={"contained"} onClick={() => this.editAvailability('edit', appointmentData.id, appointmentData.start, appointmentData.end)}>Edit</Button>
              <Button color="secondary" className={classNames(classes.button)} variant={"contained"} onClick={() => this.editAvailability('delete', appointmentData.id)} >Delete</Button>
            </Grid>
          </div>
        }

      </>
    );
  };

  // SKYPE CONVERSATION
  // 12 APR 2022
  // please confirm when status is changed to cancel OR completed via cron OR doctor clicks on End Session. in all these cases change status button will show
  // CALL FUNCTION WHEN CHANGE STATUS BUTTON CLICKED OR MODAL CLOSED. IT SAVED ID IN STATE AND OPEN/CLOSE THE MODEL CHANGE STATUS MODAL
  changeStatus = (id, openModalStatus = true) => {
    this.setState({ changeStatusAppointmentId: id, showChangeStatusModal: openModalStatus, visibilityState: false })
  }
  allowDrag = (appointmentData) => {
    if (appointmentData.location === 'Availability')
      return false
    else
      return true
  };

  appointmentComponent = ({
    children, style, ...restProps
  }) => {
    return (
      <Appointments.Appointment
        {...restProps}
        style={style}
        className={restProps.data.location === 'Finished' ? "FinishedCalendarCellOnDashBoard" : restProps.data.location === 'Availability' ? "AvailabilityCalendarCellOnDashBoard" : restProps.data.location === 'Scheduled' ? "ScheduledCalendarCellOnDashBoard" : "OnDemandCalendarCellOnDashBoard"}
      >
        {children}
      </Appointments.Appointment>
    )
  }

  async componentDidMount() {
    let result = await this.props.getPatientsList(JSON.stringify({ doctorId: this.props.auth.uid }))
    this.setState({ patientsList: { data: result.data } })
  }

  editAvailability = (type, id, startTime = 0, endTime = 0) => {
    this.setState({ availabilityActionType: type, availabilityActionId: id, availabilityActionStartTime: startTime, availabilityActionEndTime: endTime, visibilityState: false })
  }

  onVisibilityChange = () => {
    this.setState({ visibilityState: !this.state.visibilityState })
  }

  openAvailability = (props) => {
    this.setState({ 'showAvailability': true, addAvailabilityStartTime: props.startDate })
  }
  onCancel = (type) => {
    let ceilValue = Math.ceil(moment().minute() / AppointmentSnapTime)

    let adddedMinutes = (ceilValue * AppointmentSnapTime) - moment().minute()
    if ((ceilValue * AppointmentSnapTime) === moment().minute() || ((ceilValue * AppointmentSnapTime) - 1) === moment().minute())
      adddedMinutes += AppointmentSnapTime

    this.setState({ [type]: !this.state[type], addAvailabilityStartTime: moment().add(adddedMinutes, 'minutes') })
  }

  createDate = () => {
    // Set Date To 00:00:00 AM
    let receivedDate = new Date(this.state.currentDate)

    if (this.state.currentViewName === 'Week' || this.state.currentViewName === 'Work Week')
      receivedDate.setDate(receivedDate.getDate() - receivedDate.getDay())
    else if (this.state.currentViewName === 'Month')
      receivedDate.setDate(1)
    receivedDate.setHours(0)
    receivedDate.setSeconds(0)
    receivedDate.setMinutes(0)
    receivedDate.toLocaleString('en-US', { timeZone: this.props.userZone })
    let receivedDateInMiliSeconds = new Date(receivedDate).getTime()

    return Math.ceil(receivedDateInMiliSeconds / 1000)
  }
  getAppointmentsList = async () => {
    this.setState({ loading: true })
    let appointmentsList = []
    let availabilitiesList = []
    let timeStampInUTC = await this.createDate()
    let appointmentData = await this.props.getAppointments({ doctorId: this.props.auth.uid, date: timeStampInUTC, type: this.state.currentViewName })
    let availablityData = await this.props.getAvailability({ doctorId: this.props.auth.uid, date: timeStampInUTC, type: this.state.currentViewName })

    if (appointmentData.status === 1) {
      if (appointmentData.isWorkWeek === 1) {
        await this.setState({ currentViewName: "Work Week", excludedDays: appointmentData.excludedDays })
      }
      else if (this.state.currentViewName === 'Work Week') {
        await this.setState({ currentViewName: "Week", excludedDays: appointmentData.excludedDays })
      }
      appointmentsList = appointmentData.data
    }

    if (availablityData.status === 1) {
      availabilitiesList = availablityData.data
    }
    if (availabilitiesList.length === 0 && appointmentsList.length === 0) {
      this.setState({ data: [] })
      this.setState({ loading: false })
    }
    else {
      this.updateListData(appointmentsList, availabilitiesList)
    }

  }
  updateListData = async (appointmentData, availablityData) => {
    await this.setState({ dayStartTime: this.props.dayStartTime, dayEndTime: this.props.dayEndTime })
    let appointmentArray = []
    let currentTime = Math.floor(new Date().getTime() / 1000)
    for (let i = 0; i < appointmentData.length; i++) {

      let makeAppointmentType = ''
      if (appointmentData[i].status === 'completed')
        makeAppointmentType = 'Finished'
      // SKYPE CONVERSATION
      // 12 APR 2022
      // please confirm when status is changed to cancel OR completed via cron OR doctor clicks on End Session. in all these cases change status button will show
      // ADD CANCELLED STATUS FOR CALENDAR APPOINTMENTS
      else if (appointmentData[i].status === "cancelled")
        makeAppointmentType = 'Cancelled'
      else if (appointmentData[i].temporaryStatus === 'completed' && appointmentData[i].appointmentFor < (currentTime - 3600))
        makeAppointmentType = 'Finished'
      else if (appointmentData[i].appointmentType === "schedule")
        makeAppointmentType = 'Scheduled'
      else if (appointmentData[i].appointmentType === "onDemand")
        makeAppointmentType = 'On-Demand'
      if (makeAppointmentType !== '') {
        let date = new Date(appointmentData[i].appointmentFor * 1000)
        let appointment = {
          title: appointmentData[i].userName,
          location: makeAppointmentType,
          'appointment type': makeAppointmentType,
          id: appointmentData[i].id,
          startDate: new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()),
          endDate: new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes() + appointmentData[i].duration),
          reason: appointmentData[i].reason,
          status: appointmentData[i].status,
          remainingSeconds: appointmentData[i].appointmentFor - currentTime,
          userId: appointmentData[i].userId,
          completedMin: Math.floor((currentTime - appointmentData[i].completedTime) / 60),
          doctorId: this.props.auth.uid
        }
        appointmentArray.push(appointment)
      }
    }
    for (let i = 0; i < availablityData.length; i++) {
      let startDate = new Date(availablityData[i].start * 1000)
      let endDate = new Date(availablityData[i].end * 1000)
      let availablity = {
        title: '',
        location: 'Availability',
        'appointment type': 'Availability',
        id: availablityData[i].id,
        start: availablityData[i].start,
        end: availablityData[i].end,
        startDate: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startDate.getHours(), startDate.getMinutes()),
        endDate: new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endDate.getHours(), endDate.getMinutes()),
        userId: '',
        doctorId: this.props.auth.uid
      }
      appointmentArray.push(availablity)
    }
    await this.setState({ appointmentData: appointmentArray })
    this.updateAppointmentData()
  }
  setDeletedAppointmentId(id) {
    this.setState({ deletedAppointmentId: id });
  }

  toggleEditingFormVisibility() {
    const { editingFormVisible } = this.state;
    this.setState({
      editingFormVisible: !editingFormVisible,
    });
  }

  toggleConfirmationVisible() {
    const { confirmationVisible } = this.state;
    this.setState({ confirmationVisible: !confirmationVisible });
  }

  commitDeletedAppointment() {
    this.setState((state) => {
      const { data, deletedAppointmentId } = state;
      const nextData = data.filter(appointment => appointment.id !== deletedAppointmentId);

      return { data: nextData, deletedAppointmentId: null };
    });
    this.toggleConfirmationVisible();
  }

  commitChanges = async ({ added, changed, deleted }) => {
    this.setState({ loading: true })
    if (changed) {
      let appointmentId = Object.keys(changed)[0]
      let appointmentsList = this.state.data
      for (let i = 0; i < appointmentsList.length; i++) {
        if (appointmentId === appointmentsList[i].id) {
          let newStart = Math.floor(new Date(changed[appointmentId].startDate).getTime() / 1000)
          let oldStart = Math.floor(new Date(appointmentsList[i].startDate).getTime() / 1000)
          let newEnd = Math.floor(new Date(changed[appointmentId].endDate).getTime() / 1000)
          let oldEnd = Math.floor(new Date(appointmentsList[i].endDate).getTime() / 1000)
          let currentTime = Math.floor(new Date().getTime() / 1000)
          let appointmentType = appointmentsList[i]['appointment type']
          if (appointmentType === 'Scheduled')
            appointmentType = 'schedule'
          else if (appointmentType === 'On-Demand')
            appointmentType = 'onDemand'
          if (appointmentType !== 'Availability') {
            if (new Date(newStart * 1000).getDate() !== new Date(oldStart * 1000).getDate()) {
              appointmentType = 'schedule'
            }
            let timeStampInUTC = await this.createDate()
            let updatedAppointmentObj = {
              id: appointmentId,
              startDate: newStart,
              previousStartDate: oldStart,
              status: appointmentsList[i].status,
              endDate: newEnd,
              isStartChanged: false,
              appointmentType: appointmentType,
              doctorId: this.props.auth.uid,
              doctorName: this.props.account.fullName,
              date: timeStampInUTC,
              type: this.state.currentViewName,
              updatedFrom: 'doctor',
              userId: appointmentsList[i].userId
            }
            if (newStart !== oldStart) {
              confirmAlert({
                title: 'Confirm',
                closeOnEscape: false,
                closeOnClickOutside: false,
                message: 'A notification will need to be sent to the patient requesting this appointment change before the appointment start time can be changed. Are you sure you wish to continue?',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: async () => {
                      updatedAppointmentObj.isStartChanged = true
                      await this.props.updateAppointmentDataAPI(JSON.stringify(updatedAppointmentObj))
                      await this.getAppointmentsList()
                    }
                  },
                  {
                    label: 'No',
                    onClick: () => {
                      changed[appointmentId].startDate = appointmentsList[i].startDate
                      changed[appointmentId].endDate = appointmentsList[i].endDate
                      this.setState({ loading: false })
                    }
                  }
                ]
              });
            }
            else if (oldEnd !== newEnd) {
              await this.props.updateAppointmentDataAPI(JSON.stringify(updatedAppointmentObj))
              await this.getAppointmentsList()
            }
            else {
              this.setState({ loading: false })
            }
            if (newStart < currentTime) {
              confirmAlert({
                title: 'Alert',
                closeOnEscape: false,
                closeOnClickOutside: false,
                message: 'Appointment Start time can not be less than current time',
                buttons: [
                  {
                    label: 'Ok',
                    onClick: () => {
                      this.setState({ loading: false })
                    }
                  }
                ]
              });
            }
            if (newEnd < newStart) {
              confirmAlert({
                title: 'Alert',
                closeOnEscape: false,
                closeOnClickOutside: false,
                message: 'Appointment end time can not be less than appointment start time',
                buttons: [
                  {
                    label: 'Ok',
                    onClick: () => {
                      this.setState({ loading: false })
                    }
                  }
                ]
              });
            }
          }
          else {
            this.setState({ loading: false })
          }
        }
      }
    }
    else {
      let status = ''
      if (added.startDate < (new Date().getTime())) {
        status = 'completed'
      }

      if (!added.hasOwnProperty('title')) {
        confirmAlert({
          title: 'Alert',
          closeOnEscape: false,
          closeOnClickOutside: false,
          message: 'Please select patient from list for appointment',
          buttons: [
            {
              label: 'Ok',
              onClick: () => {
                this.setState({ loading: false })
              }
            }
          ]
        });
      }
      else {
        let patientName = ''
        for (let i = 0; i < this.state.patientsList.data.length; i++) {
          let patientDetail = this.state.patientsList.data[i]
          if (patientDetail.patientId === added.title) {
            patientName = patientDetail.fullName
          }
        }
        let difference = Math.floor(added.endDate / 1000) - Math.floor(added.startDate / 1000)
        let addAppointment = {
          "appointmentFor": Math.floor(added.startDate / 1000),
          "appointmentType": "schedule",
          "bookCardType": "",
          "bookdateofHCExp": '',
          "bookdoctorNote": 1,
          "bookforms": 0,
          "bookhCard": "",
          "appointmentDuration": difference / 60,
          "bookreason": added.hasOwnProperty('reason') ? added.reason : "",
          "doctorId": this.props.auth.uid,
          "doctorName": this.props.account.fullName,
          "files": [],
          "holdamount": this.props.account.holdAmount,
          "paidamount": 0,
          "stripetoken": "",
          "userId": added.hasOwnProperty('title') ? added.title : "",
          "userName": patientName,
          "addedBy": 'doctor',
          "status": status,
          "skipBilling": false,
          "voucherCode": ''
        }
        let result = await functionsList.createOrder(addAppointment)
        this.setState({ loader: false })
        if (result[1] === 1) {
          confirmAlert({
            title: 'Success',
            closeOnEscape: false,
            closeOnClickOutside: false,
            message: 'Appointment created successfully',
            buttons: [
              {
                label: 'Ok',
                onClick: () => {
                  this.getAppointmentsList()
                }
              }
            ]
          });
        }
        else {
          confirmAlert({
            title: 'Alert',
            closeOnEscape: false,
            closeOnClickOutside: false,
            message: result[0],
            buttons: [
              {
                label: 'Ok',
                onClick: () => {
                  this.setState({ loading: false })
                }
              }
            ]
          });
        }
      }
    }

  }

  componentDidUpdate() {
    this.appointmentForm.update();
  }

  onEditingAppointmentChange(editingAppointment) {
    this.setState({ editingAppointment });
  }

  onAddedAppointmentChange(addedAppointment) {
    this.setState({ addedAppointment });
    const { editingAppointment } = this.state;
    if (editingAppointment !== undefined) {
      this.setState({
        previousAppointment: editingAppointment,
      });
    }
    this.setState({ editingAppointment: undefined, isNewAppointment: true });
  }

  onCurrentFilterChange = (val) => {
    this.setState({ currentFilter: val }, () => this.updateAppointmentData())
  }
  handleButtonClick = (locationName, locations) => {
    if (locations.indexOf(locationName) > -1) {
      var newLocations = locations.filter((location) => location !== locationName)
      this.setState({ locations: newLocations }, () => this.updateAppointmentData());
      return newLocations
    } else {
      const nextLocations = [...locations];
      nextLocations.push(locationName);
      this.setState({ locations: nextLocations }, () => this.updateAppointmentData())
      return nextLocations
    }
  };

  updateAppointmentData = async () => {
    let data = this.state.appointmentData
    let locations = this.state.locations
    if (this.state.currentFilter !== '')
      data = data.filter(dataItem => dataItem.title.toLocaleLowerCase().indexOf(this.state.currentFilter.toLocaleLowerCase()) > -1)
    data = data.filter((dataItem) => {
      if (locations.includes(dataItem.location))
        return dataItem
      return ''
    })
    await this.setState({ data: data })
    this.setState({ loading: false })
    if (this.state.currentViewName === 'Week' || this.state.currentViewName === 'Day') {
      let elem = document.getElementById("onContentReady")
      if (elem) {
        elem.scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
        elem.parentElement.style.zIndex = 2
      }

      // GET ALL AVAILABILTY EVENT
      let availabilityElements = document.getElementsByClassName('AvailabilityCalendarCellOnDashBoard')
      // CALCULATE CELL SPACE
      let cellSpace = (100 / 7).toFixed(4);
      for (let i = 0; i < availabilityElements.length; i++) {
        // GET AVAILABILITY LEFT SPACE
        let leftSpace = availabilityElements[i].parentElement.style.left
        leftSpace = leftSpace.replace("%", "");
        // CALCULATE THE DISTANCE FROM START OF CELL
        let partOf = Math.round(leftSpace / cellSpace)
        partOf = leftSpace - (partOf * cellSpace)
        // CALCULATE NEW LEFT
        leftSpace = (leftSpace - partOf).toFixed(4)
        leftSpace = leftSpace + "%"
        // SET WIDTH, LEFT AND Z-INDEX
        availabilityElements[i].parentElement.style.width = '14.2153%'
        if (this.state.currentViewName === 'Day')
          availabilityElements[i].parentElement.style.width = '100%'
        availabilityElements[i].parentElement.style.left = leftSpace
        availabilityElements[i].parentElement.style.zIndex = 1
      }

      // GET ALL FINISHED APPOINTMENTS
      let finishedElements = document.getElementsByClassName('FinishedCalendarCellOnDashBoard')
      // CALCULATE CELL SPACE
      let finishedcellSpace = (100 / 7).toFixed(4);
      for (let i = 0; i < finishedElements.length; i++) {
        // GET AVAILABILITY LEFT SPACE
        let leftSpace = finishedElements[i].parentElement.style.left
        leftSpace = leftSpace.replace("%", "");
        // CALCULATE THE DISTANCE FROM START OF CELL
        let partOf = Math.round(leftSpace / finishedcellSpace)
        partOf = leftSpace - (partOf * finishedcellSpace)
        // CALCULATE NEW LEFT
        leftSpace = (leftSpace - partOf).toFixed(4)
        leftSpace = leftSpace + "%"
        // SET WIDTH, LEFT AND Z-INDEX
        finishedElements[i].parentElement.style.width = '14.2153%'
        if (this.state.currentViewName === 'Day')
          finishedElements[i].parentElement.style.width = '100%'
        finishedElements[i].parentElement.style.left = leftSpace
        finishedElements[i].parentElement.style.zIndex = 1
      }

      // GET ALL SCHEDULED EVENT
      let scheduledElements = document.getElementsByClassName('ScheduledCalendarCellOnDashBoard')
      for (let i = 0; i < scheduledElements.length; i++) {
        // GET AVAILABILITY LEFT SPACE
        let leftSpace = scheduledElements[i].parentElement.style.left
        leftSpace = leftSpace.replace("%", "");
        // CALCULATE THE DISTANCE FROM START OF CELL
        let partOf = Math.floor(leftSpace / cellSpace)
        let addedLeft = 0

        leftSpace = partOf * cellSpace
        leftSpace = leftSpace + "%"
        // SET WIDTH, LEFT AND Z-INDEX
        scheduledElements[i].parentElement.style.left = leftSpace
        scheduledElements[i].parentElement.style.width = (13.5153 - addedLeft) + '%'
        if (this.state.currentViewName === 'Day') {
          scheduledElements[i].parentElement.style.width = (100 - addedLeft) + '%'
          scheduledElements[i].parentElement.style.left = 0
        }
        scheduledElements[i].parentElement.style.zIndex = 3
      }

      // GET ALL ONDEMAND EVENT
      let onDemandElements = document.getElementsByClassName('OnDemandCalendarCellOnDashBoard')
      for (let i = 0; i < onDemandElements.length; i++) {
        // GET AVAILABILITY LEFT SPACE
        let leftSpace = onDemandElements[i].parentElement.style.left
        leftSpace = leftSpace.replace("%", "");
        // CALCULATE THE DISTANCE FROM START OF CELL
        let partOf = Math.floor(leftSpace / cellSpace)
        // partOf = leftSpace - (partOf * cellSpace)
        let addedLeft = 0

        leftSpace = partOf * cellSpace
        leftSpace = leftSpace + "%"
        // SET WIDTH, LEFT AND Z-INDEX
        onDemandElements[i].parentElement.style.left = leftSpace
        onDemandElements[i].parentElement.style.width = (13.5153 - addedLeft) + '%'
        if (this.state.currentViewName === 'Day') {
          onDemandElements[i].parentElement.style.width = (100 - addedLeft) + '%'
          onDemandElements[i].parentElement.style.left = 0
        }
        onDemandElements[i].parentElement.style.zIndex = 4
      }
    }
  }

  onCurrentViewNameChange = async (viewName) => {
    await this.setState({ currentViewName: viewName })
    this.getAppointmentsList()
  }

  onTimeChange = async (e) => {
    if (e.target.name === 'dayStartTime' && parseInt(e.target.value) > parseInt(this.state.dayEndTime)) {
      this.setState({ 'message': 'Start time must be less than end time' })
    }
    else if (e.target.name === 'dayEndTime' && parseInt(e.target.value) <= parseInt(this.state.dayStartTime)) {
      this.setState({ 'message': 'End time must be greater than start time' })
    }
    else {
      this.setState({ loadingKey: e.target.name, loader: true, message: '' })
      let result = await this.props.updateProfile(this.props.auth.uid, [{ [e.target.name]: e.target.value }])
      this.props.updateProfileData(result)
      this.setState({ [e.target.name]: e.target.value, loadingKey: '', loader: false })
    }
  }

  render() {
    const { classes, account } = this.props
    return (
      <Paper className={classes.paper}>
        <h2 className={classes.heading}>Appointment Calendar</h2>
        {this.state.message !== '' ? <p>{this.state.message}</p> : ""}
        <div className={classes.timer}>
          <div className={classes.calendarOptions}>
            <Grid className={classes.timerDiv}>
              <div className={classes.timeDiv} style={{ marginRight: "1rem" }}>
                <h2 className={classes.timeHeading}>Start Time:</h2>
                <Select
                  value={this.state.dayStartTime}
                  onChange={this.onTimeChange}
                  variant="outlined"
                  className={classes.w150}
                  name={"dayStartTime"}
                >
                  <option className={classes.font + ' ' + classes.icon} value="0">12:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="1">1:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="2">2:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="3">3:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="4">4:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="5">5:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="6">6:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="7">7:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="8">8:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="9">9:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="10">10:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="11">11:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="12">12:00 PM</option>
                  <option className={classes.font + ' ' + classes.icon} value="13">1:00 PM</option>
                  <option className={classes.font + ' ' + classes.icon} value="14">2:00 PM</option>
                  <option className={classes.font + ' ' + classes.icon} value="15">3:00 PM</option>
                  <option className={classes.font + ' ' + classes.icon} value="16">4:00 PM</option>
                  <option className={classes.font + ' ' + classes.icon} value="17">5:00 PM</option>
                  <option className={classes.font + ' ' + classes.icon} value="18">6:00 PM</option>
                  <option className={classes.font + ' ' + classes.icon} value="19">7:00 PM</option>
                  <option className={classes.font + ' ' + classes.icon} value="20">8:00 PM</option>
                  <option className={classes.font + ' ' + classes.icon} value="21">9:00 PM</option>
                  <option className={classes.font + ' ' + classes.icon} value="22">10:00 PM</option>
                  <option className={classes.font + ' ' + classes.icon} value="23">11:00 PM</option>
                </Select>
                {this.state.loader && this.state.loadingKey === 'dayStartTime' ? <CircularProgress size={21} /> : ""}
              </div>
              <div className={classes.timeDiv}>
                <h2 className={classes.timeHeading}>End Time:</h2>
                <Select
                  value={this.state.dayEndTime}
                  onChange={this.onTimeChange}
                  variant="outlined"
                  className={classes.w150}
                  name={"dayEndTime"}
                >
                  <option className={classes.font + ' ' + classes.icon} value="0">12:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="1">1:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="2">2:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="3">3:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="4">4:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="5">5:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="6">6:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="7">7:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="8">8:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="9">9:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="10">10:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="11">11:00 AM</option>
                  <option className={classes.font + ' ' + classes.icon} value="12">12:00 PM</option>
                  <option className={classes.font + ' ' + classes.icon} value="13">1:00 PM</option>
                  <option className={classes.font + ' ' + classes.icon} value="14">2:00 PM</option>
                  <option className={classes.font + ' ' + classes.icon} value="15">3:00 PM</option>
                  <option className={classes.font + ' ' + classes.icon} value="16">4:00 PM</option>
                  <option className={classes.font + ' ' + classes.icon} value="17">5:00 PM</option>
                  <option className={classes.font + ' ' + classes.icon} value="18">6:00 PM</option>
                  <option className={classes.font + ' ' + classes.icon} value="19">7:00 PM</option>
                  <option className={classes.font + ' ' + classes.icon} value="20">8:00 PM</option>
                  <option className={classes.font + ' ' + classes.icon} value="21">9:00 PM</option>
                  <option className={classes.font + ' ' + classes.icon} value="22">10:00 PM</option>
                  <option className={classes.font + ' ' + classes.icon} value="23">11:00 PM</option>
                </Select>
                {this.state.loader && this.state.loadingKey === 'dayEndTime' ? <CircularProgress size={21} /> : ""}

              </div>
            </Grid>
            <Grid>
              <div className={classes.timeDiv}>
                <h2 className={classes.timeHeading}>Calendar type:</h2>
                <Select
                  value={this.state.currentViewName}
                  onChange={(e) => this.onCurrentViewNameChange(e.target.value)}
                  variant="outlined"
                  className={classes.w150}
                  name={"dayEndTime"}
                >
                  <option className={classes.font + ' ' + classes.icon} value="Day">Day</option>
                  <option className={classes.font + ' ' + classes.icon} value="Week">Week</option>
                  <option className={classes.font + ' ' + classes.icon} value="Month">Month</option>
                </Select>
              </div>
            </Grid>
          </div>
          <Grid className={classes.buttonDiv + ' ' + classes.appointmentOptionsDiv}>
            <AppointmentOptions />
          </Grid>
        </div>
        <div className={classes.scheduler + ' doctorDashboardScheduler'}>
          {this.state.loading ? <div className={classes.loadingClass}><CircularProgress size={21} /></div> : <div className={classes.loadingClass} style={{ visibility: 'hidden' }}></div>}
          <Scheduler data={this.state.data} >
            <ViewState
              currentDate={this.state.currentDate}
              onCurrentDateChange={this.currentDateChange}
              currentViewName={this.state.currentViewName}
              onCurrentViewNameChange={this.onCurrentViewNameChange}
            />
            <DayView
              startDayHour={parseInt(this.state.dayStartTime)}
              endDayHour={parseInt(this.state.dayEndTime) + 1}
              cellDuration={15}
              timeTableCellComponent={this.TimeTableCell}
              dayScaleCellComponent={this.DayScaleCell}
            />
            <WeekView
              startDayHour={parseInt(this.state.dayStartTime)}
              endDayHour={parseInt(this.state.dayEndTime) + 1}
              cellDuration={15}
              timeTableCellComponent={this.TimeTableCell}
              dayScaleCellComponent={this.DayScaleCell}
              intervalCount={1}
            />

            <MonthView
              timeTableCellComponent={this.MonthTimeTableCell}
              dayScaleCellComponent={this.MonthDayScaleCell}
            />

            <EditingState
              onCommitChanges={this.commitChanges}
              onEditingAppointmentChange={this.onEditingAppointmentChange}
            />
            <IntegratedEditing />

            <Appointments appointmentComponent={this.appointmentComponent} />
            <AppointmentTooltip
              visible={this.state.visibilityState}
              onVisibilityChange={this.onVisibilityChange}
              headerComponent={Header}
              contentComponent={this.TooltipContent}
            />
            <Resources data={resources} />

            <Toolbar flexibleSpaceComponent={this.state.FlexibleSpace} />
            <DateNavigator />

            <ViewSwitcher />

            <AppointmentForm
              overlayComponent={this.appointmentForm}
              visible={this.state.editingFormVisible}
              onVisibilityChange={this.toggleEditingFormVisibility}
            />
            <DragDropProvider allowDrag={(e) => this.allowDrag(e)} />
            <CurrentTimeIndicator shadePreviousAppointments={true} shadePreviousCells={true} indicatorComponent={onContentReady} />
          </Scheduler>
          <div className={classes.customFooter}>
            <div className={classes.legends} >
              <h2 className={classes.legendHeading} color="secondary">Legend:</h2>
              <FormGroup className={classes.flexRow}>
                <FormControlLabel
                  control={<PurpleCheckbox checked={this.state.locations.indexOf('Scheduled') > -1 ? true : false} onChange={this.handleChange} name="Scheduled" onClick={() => this.handleButtonClick('Scheduled', this.state.locations)} />}
                  label="Scheduled"
                />
                <FormControlLabel
                  control={<DeepOrangeCheckbox checked={this.state.locations.indexOf('On-Demand') > -1 ? true : false} onChange={this.handleChange} name="On-Demand" onClick={() => this.handleButtonClick('On-Demand', this.state.locations)} />}
                  label="On-Demand"
                />
                <FormControlLabel
                  control={<GreyCheckbox checked={this.state.locations.indexOf('Finished') > -1 ? true : false} onChange={this.handleChange} name="Finished" onClick={() => this.handleButtonClick('Finished', this.state.locations)} />}
                  label="Completed"
                />
                <FormControlLabel
                  control={<RedCheckbox checked={this.state.locations.indexOf('Cancelled') > -1 ? true : false} onChange={this.handleChange} name="Cancelled" onClick={() => this.handleButtonClick('Cancelled', this.state.locations)} />}
                  label="Cancelled"
                />
              </FormGroup>
            </div>
            <div className={classes.FabsClass}>
              <Fab
                color="primary"
                className={classes.addButton}
                onClick={() => this.onCancel('showAvailability')}
              >
                <AddIcon /> ADD AVAILABILITY
                </Fab>
              <Fab
                color="secondary"
                className={classes.addButton}
                onClick={() => {

                  let ceilValue = Math.ceil(moment().minute() / AppointmentSnapTime)

                  let adddedMinutes = (ceilValue * AppointmentSnapTime) - moment().minute()
                  if ((ceilValue * AppointmentSnapTime) === moment().minute() || ((ceilValue * AppointmentSnapTime) - 1) === moment().minute())
                    adddedMinutes += AppointmentSnapTime

                  this.setState({ editingFormVisible: true });
                  this.onEditingAppointmentChange(undefined);
                  this.onAddedAppointmentChange({
                    startDate: moment().add(adddedMinutes, 'minutes')['_d'],
                    endDate: moment().add(adddedMinutes + parseInt(account.slotTime), 'minutes')['_d']
                  });
                }}
              >
                <AddIcon /> ADD PATIENT APPOINTMENT
                </Fab>
            </div>
          </div>
        </div>

        <Modal
          className={"schedularModels"}
          title={this.state.showAvailability}
          visible={this.state.showAvailability}
          width={500}
          closable={false}
          onCancel={() => this.onCancel('showAvailability')}
          footer={
            null
          }
        >
          {this.state.showAvailability && <AVAILABILITY addAvailabilityStartTime={this.state.addAvailabilityStartTime} getAppointmentsList={this.getAppointmentsList} onCancel={this.onCancel} updateSchedulerFromAvailability={this.props.updateSchedulerFromAvailability} />}
        </Modal>
        <Modal
          className={"schedularModels"}
          title={this.state.showAvailability}
          visible={this.state.availabilityActionType !== '' ? true : false}
          width={400}
          closable={false}
          onCancel={() => this.editAvailability('', '')}
          footer={
            null
          }
        >
          {this.state.availabilityActionType !== '' &&
            <AVAILABILITYOPERATION getAppointmentsList={this.getAppointmentsList} availabilityActionType={this.state.availabilityActionType} availabilityActionId={this.state.availabilityActionId} availabilityActionStartTime={this.state.availabilityActionStartTime} availabilityActionEndTime={this.state.availabilityActionEndTime} editAvailability={this.editAvailability} />
          }
        </Modal>

        {/* SKYPE CONVERSATION
        12 APR 2022
        please confirm when status is changed to cancel OR completed via cron OR doctor clicks on End Session. in all these cases change status button will show
        SHOW CHANGE STATUS MODAL WHEN SHOWCHANGESTATUSMODAL STATE IS TRUE */}
        {this.state.showChangeStatusModal && <ChangeAppointmentStatusModel getAppointmentsList={this.getAppointmentsList} changeStatus={this.changeStatus} appointmentId={this.state.changeStatusAppointmentId} />}
      </Paper>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    userZone: state.account.data.zone,
    account: state.account.data,
    scheduleAppointments: state.appointment.appointmentschedule,
    dayStartTime: state.account.data.dayStartTime,
    dayEndTime: state.account.data.dayEndTime,
    appointmentsList: state.appointment.appointmentschedule
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAppointments: (data) => getAppointments(data),
    getPatientsList: (data) => getPatientsList(data),
    updateAppointmentDataAPI: (data) => updateAppointmentDataAPI(data),
    updateProfile: (uid, data) => updateProfile(uid, data),
    updateProfileData: (data) => dispatch(data),
    getAvailability: (data) => getAvailability(data)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DashboardScheduler));