import React, { Component } from 'react'
import { Grid, Button, Slider } from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles"

const styles = theme => ({
    font: {
        fontFamily: 'Roboto Regular',
    },
    popup: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    popupDiv: {
        backgroundColor: '#fff',
        padding: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: '2rem'
    },
    popupHeading: {
        fontFamily: 'inherit',
        fontWeight: 700,
        color: '#292929',
        fontSize: '1.2rem'
    },
    textTransform: {
        textTransform: 'unset'
    },
    popupButton: {
        marginTop: theme.spacing(5),
        fontFamily: 'inherit',
        fontSize: '1rem',
        color: "#fff",
        padding: '0px 50px',
        [theme.breakpoints.down('sm')]: { marginTop: theme.spacing(2) }
    },
    reasonDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        borderRadius: '2rem',

    },
    buttons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.down('sm')]: { flexDirection: 'column' },
        marginTop: '2rem',
        gap: '1rem'
    },

    info: {
        margin: theme.spacing(8, 0),
        fontSize: '1.1rem',
    },
    slider: {
        '& .MuiSlider-markLabel': {
            color: theme.palette.lightGrey.main,
        },
        '& .MuiSlider-thumb > span > span > span': {
            color: 'white'
        }
    },
    buttonCancel: {
        backgroundColor: 'rgb(240, 242, 245)',
        width: "120px",
        color: theme.palette.lightGrey.main,
        padding: "7px",
        borderRadius: '10px',
        border: 'none',
        height: '50px',
        "&:hover": {
            backgroundColor: "rgb(240, 242, 245)",
        },
    },
    buttonContinue: {
        width: "120px",
        borderRadius: '10px',
        border: 'none',
        color: 'white',
        height: '50px',
        padding: "7px",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: "white"
        },
    }
})

const marks = [
    {
        value: 1,
        label: "1"
    },
    {
        value: 2,
        label: "2"
    },
    {
        value: 3,
        label: "3"
    },
    {
        value: 4,
        label: "4"
    },
    {
        value: 5,
        label: "5"
    }
];
class Forms extends Component {
    constructor(props) {
        super(props)
        this._isMounted = false;

        this.state = {
            fillForms: false,
            noOfPages: 0
        }
         //FOR TASK RHYN-105
       //Please remove notes and forms to be filled screens for all virtual appointments. We will charge patients manually for these.
       //if ((props.appointmentData.doctorId === '')) {
       //     this.props.updateStepNo(7, { fillForm: false, noOfPages: 0 })
       // } 
	this.props.updateStepNo(7, { fillForm: false, noOfPages: 0, previousStep: props.appointmentData.previousStep })

    }
    nextStep = async () => {
        this.props.updateStepNo(7, { fillForm: this.state.fillForms, noOfPages: this.state.noOfPages, previousStep: 6 })
    }
    // SHOW HIDE NO OF FORMS SECTION
    showNoOfForms = () => {
        let noOfPages = 0
        if (!this.state.fillForms) {
            noOfPages = 1
        }
        if (this._isMounted)
            this.setState({ fillForms: !this.state.fillForms, noOfPages: noOfPages })
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true
    }
    change = (e, numberOfPages) => {
        if (this._isMounted)
            this.setState({ noOfPages: numberOfPages })
    }
    render() {
        let { classes } = this.props
        return (
            <Grid className={classes.font}>
                <Grid className={classes.popup}>
                    <Grid className={classes.popupDiv} style={{ width: '30rem' }}>

                        {!this.state.fillForms ?
                            <Grid>
                                <span className={classes.popupHeading}>Do you need {this.props.appointmentData.showDr && "Dr."} <span style={{ textTransform: "Capitalize" }}>{this.props.appointmentData.doctorName}</span>{!this.props.appointmentData.showDr && ", NP"} to fill out any forms?</span>
                                <p className={classes.info}>Each fill page is charged an additional CAD $10 and is not covered by provincial insurance</p>
                                <Grid className={classes.buttons}>
                                    <Button variant="contained" onClick={this.nextStep} color={'primary'} className={classes.popupButton}>
                                        No
                                    </Button>
                                    <Button variant="contained" onClick={this.showNoOfForms} color={'secondary'} className={classes.popupButton}>
                                        Yes
                                    </Button>
                                </Grid>
                            </Grid>
                            :
                            <Grid className={classes.reasonDiv}>
                                <span className={classes.popupHeading}>
                                    Please mention the number of pages that would need to be filled by {this.props.appointmentData.showDr && "Dr."}
                                </span>
                                <span style={{ textTransform: "Capitalize" }}>
                                    {this.props.appointmentData.doctorName}
                                    {!this.props.appointmentData.showDr && ", NP"}
                                </span>
                                <Slider
                                    defaultValue={1}
                                    min={1}
                                    max={5}
                                    className={classes.slider}
                                    aria-labelledby="discrete-slider-always"
                                    step={1}
                                    marks={marks}
                                    valueLabelDisplay="on"
                                    onChange={this.change}
                                />
                                <Grid className={classes.buttons}>
                                    <Button variant="contained" onClick={this.showNoOfForms} color={'primary'} className={classes.buttonCancel}>
                                        Back
                                    </Button>
                                    <Button variant="contained" onClick={this.nextStep} color={'secondary'} className={classes.buttonContinue}>
                                        Continue
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(Forms)