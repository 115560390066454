import React, { Component } from 'react'
import withStyles from "@material-ui/core/styles/withStyles"
import { connect } from 'react-redux'
import { createAndCheckAvailability } from '../../store/actions/authActions'
import moment from 'moment-timezone'
import 'antd/dist/antd.css'
import '../../App.css'
import "react-datepicker/dist/react-datepicker.css"
import ScheduleIcon from '@material-ui/icons/Schedule'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import DatePickerNew from "react-datepicker"
import { Radio, RadioGroup, FormControlLabel, ClickAwayListener, Grid, TextField, Select, Button, CircularProgress } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'


const styles = theme => ({
  cursor: {
    cursor: 'pointer',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  flex: {
    display: 'flex',
  },
  columnDirection: {
    flexDirection: 'column'
  },
  rowDirection: {
    flexDirection: 'row'
  },
  flexWrap: {
    flexWrap: 'wrap'
  },
  alignItemsCenter: {
    alignItems: 'center'
  },
  justifyContentBetween: {
    justifyContent: 'space-between'
  },
  justifyContentCenter: {
    justifyContent: 'center'
  },
  justifyContentEnd: {
    justifyContent: 'flex-end'
  },
  posRelative: {
    position: 'relative',
  },
  posAbsolute: {
    position: 'absolute',
    visibility: 'hidden',
    // opacity: 0,
    bottom: theme.spacing(0)
  },
  modal: {
    flexDirection: 'column',
    fontSize: '.9rem',
    fontWeight: 700,
  },
  headings: {
    color: '#008a8c',
    textAlign: 'center',
    fontSize: '1.3rem',
    fontWeight: 600,
    marginBottom: theme.spacing(.4)
  },
  w100: {
    width: '100px !important',
    marginLeft: theme.spacing(1) + "px !important",
    height: theme.spacing(4),
    marginRight: '0 !important'
  },
  mt_7: {
    marginTop: theme.spacing(1)
  },
  days: {
    backgroundColor: '#ebebeb',
    color: '#a7a6a6',
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    borderRadius: '100%',
    marginRight: theme.spacing(.5),
    marginBottom: theme.spacing(.5),
  },
  selected_days: {
    backgroundColor: '#00bbbe',
    color: '#fff'
  },
  radioButton: {
    width: '30%'
  },
  error: {
    color: 'red'
  },
});

class DoctorAvailability extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      DateName: moment(props.addAvailabilityStartTime).format('ddd, ll'),
      checkDate: false,
      checkStartTime: false,
      checkEndTime: false,
      startTime: moment(props.addAvailabilityStartTime).format('LT'),
      endTime: moment(props.addAvailabilityStartTime).add(1, 'hours').format('LT'),
      repeat_time: 0,
      repeat_type: 'week',
      selected_days: { days: [] },
      selected_dates: { dates: [] },
      endsType: 'never',
      endsValue: 0,
      monthlyType: 'day',
      error: ''
    }
  }

  openDate = (e) => {
    if (e.target.tagName === 'INPUT') {
      console.log('if')
      this.setState({ 'checkDate': true })
    }
    else if (e.target.tagName === 'DIV') {
      console.log('else')
      this.setState({ 'checkDate': false })
    }
  }
  checkStartTime = () => {
    this.setState({ 'checkStartTime': !this.state.checkStartTime })
  }
  checkEndTime = () => {
    this.setState({ 'checkEndTime': !this.state.checkEndTime })
  }
  closeDate = () => {
    // this.setState({'checkDate':false, 'checkStartTime': false, 'checkEndTime': false})
    this.setState({ 'checkStartTime': false, 'checkEndTime': false })
  }
  changeDate = (e, da) => {
    this.setState({ [da]: moment(e).format('ddd, ll') })
    setTimeout(() => { this.setState({ 'checkDate': false }) }, 500)
  }
  changeTime = (date, id) => {
    this.setState({ [id]: moment(date).format('LT') })
    if ('startTime' === id)
      this.setState({ 'checkStartTime': !this.state.checkStartTime })
    else
      this.setState({ 'checkEndTime': !this.state.checkEndTime })
  };

  onchange = (e, val) => {
    let key = e.target.name
    let value = e.target.value
    if (this.state.endsType === 'after' && key === 'endsValue') {
      if (value < 0)
        this.setState({ [key]: 0 })
      else
        this.setState({ [key]: value })
    }
    else {
      this.setState({ [key]: value })
    }
  }


  changeDays = (val) => {
    let selected_days = this.state.selected_days.days
    if (selected_days.indexOf(val) > -1)
      selected_days.splice(selected_days.indexOf(val), 1)
    else
      selected_days.push(val)

    this.setState({ selected_days: { days: selected_days } })
  }

  changeDates = (val) => {
    let selected_dates = this.state.selected_dates.dates
    if (selected_dates.indexOf(val) > -1)
      selected_dates.splice(selected_dates.indexOf(val), 1)
    else
      selected_dates.push(val)
    this.setState({ selected_dates: { dates: selected_dates } })
  }

  saveAvailability = async () => {
    // CALCULATE START AND END TIME IN UNIXTIMESTAMP
    let startTime = this.createTimeStamp(this.state.startTime)
    let endTime = this.createTimeStamp(this.state.endTime)
    let endsValue = this.state.endsValue

    // CHECK END TIME MUST BE GREATER THAN START TIME AND ENDS TIME MUST BE GREATER THAN END TIME
    if (endTime <= startTime)
      this.setState({ error: 'End time must be greateer than start time' })
    else if (endsValue < endTime && this.state.endsType === 'on')
      this.setState({ error: 'Ends value must be greater than end time' })
    else if (this.state.repeat_type === 'month' && this.state.monthlyType === 'day' && this.state.selected_dates.dates.length === 0)
      this.setState({ error: 'Please select dates' })
    else if (this.state.repeat_type === 'month' && this.state.monthlyType === 'first' && this.state.selected_days.days.length === 0)
      this.setState({ error: 'Please select days' })
    else {
      this.setState({ error: '', loading: true })
      if (this.state.endsType === 'on') {
        // GET SELECTED DATE FROM CALENDAR
        let selectedDate = new Date(moment(this.state.endsValue))
        let timezoneDifference = selectedDate.getTimezoneOffset()
        selectedDate.setHours(23)
        selectedDate.setMinutes(59)
        selectedDate.setMinutes(selectedDate.getMinutes() + timezoneDifference)
        selectedDate.setSeconds(0)
        selectedDate.setMilliseconds(0)
        endsValue = Math.floor(new Date(selectedDate).getTime() / 1000)
      }
      let sendObject = {
        startTime: startTime,
        endTime: endTime,
        repeat_time: this.state.repeat_time,
        repeat_type: this.state.repeat_type,
        selected_days: this.state.selected_days.days,
        selected_dates: this.state.selected_dates.dates,
        monthlyType: this.state.monthlyType,
        endsType: this.state.endsType,
        endsValue: endsValue,
        uid: this.props.auth.uid
      }

      // CALL API TO CHECK CONFLICTS AND ADD RECORDS
      let result = await this.props.createAndCheckAvailability(JSON.stringify(sendObject))
      this.setState({ loading: false })
      if (result.status) {
        this.props.getAppointmentsList()
        this.props.onCancel('showAvailability')
      }
      else {
        this.setState({ error: result.message })
      }
    }

  }

  createTimeStamp = (selectedTime) => {
    // GET SELECTED DATE FROM CALENDAR
    let selectedDate = new Date(moment(this.state.DateName))
    // let timezoneDifference = selectedDate.getTimezoneOffset()
    // SPLIT START TIME TO TIME AND AM/PM
    let ampm = selectedTime.split(' ')
    // SPLIT TIME TO HH:MM
    let time = ampm[0].split(':')
    // console.log(parseInt(time[0]))
    // ADD 12 IN HOURS IF ITS PM TIME
    if (ampm[1] === 'PM' && parseInt(time[0]) !== 12) {
      time[0] = parseInt(time[0]) + 12
    }
    // console.log(time[0])
    // SET HOURS AND MINUTES IN SELECTED DATE
    selectedDate.setHours(time[0])
    selectedDate.setMinutes(time[1])
    // selectedDate.setMinutes(selectedDate.getMinutes()+timezoneDifference)
    selectedDate.setMinutes(selectedDate.getMinutes())
    selectedDate.setSeconds(0)
    selectedDate.setMilliseconds(0)
    // CREATE START TIMESTAMP
    let newCreatedTime = Math.floor(new Date(selectedDate).getTime() / 1000)
    // console.log(new Date(newCreatedTime*1000))
    console.log(moment(newCreatedTime * 1000).isDST())
    return Math.ceil(newCreatedTime)
  }

  render() {
    const { classes, account } = this.props;
    if (account.message === undefined && this.state.closemodel) {
      this.clearState()
    }

    return (

      <Grid className={classes.modal + ' ' + classes.flex + ' ' + classes.columnDirection}>

        {this.state.error && <p className={classes.error}>{this.state.error}</p>}

        <div className={classes.flex + ' ' + classes.alignItemsCenter + ' ' + classes.justifyContentBetween} >
          <div className={classes.columnDirection}>
            <p className={classes.headings}>Day/Date</p>

            <div className={classes.cursor + ' ' + classes.posRelative + ' ' + classes.flex + ' ' + classes.alignItemsCenter} onClick={(e) => this.openDate(e)}>
              <ScheduleIcon className={classes.icon} />
              <span><u>{moment(this.state.DateName).format("D/MMM/YYYY")}</u></span>
              <ArrowDropDownIcon />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  className={classes.posAbsolute + ' availabilityDateCursor'}
                  style={{ visibility: 'visible', opacity: 0, width: '200px' }}
                  value={new Date(moment(this.state.DateName))}
                  defaultValue={moment()}
                  open={this.state.checkDate}
                  format="MM/dd/yyyy"
                  onChange={(e) => this.changeDate(e, 'DateName')} />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <ClickAwayListener onClickAway={this.closeDate}>
            <div className={classes.columnDirection}>
              <p className={classes.headings}>Time</p>
              <div className={classes.flex}>
                <div className={classes.posRelative + ' ' + classes.flex + ' ' + classes.alignItemsCenter}>
                  <span className={classes.cursor} onClick={this.checkStartTime}><u>{this.state.startTime}</u></span>
                  <ArrowDropDownIcon className={classes.cursor} onClick={this.checkStartTime} />
                  <DatePickerNew
                    showTimeSelectOnly
                    className={classes.posAbsolute + ' availability_timepicker'}
                    open={this.state.checkStartTime}
                    ariaLabelledBy="Start"
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    showTimeSelect
                    onChange={(e) => this.changeTime(e, 'startTime')}
                  />
                </div>
                <span className={classes.icon}>to</span>
                <div className={classes.posRelative + ' ' + classes.flex + ' ' + classes.alignItemsCenter}>
                  <span className={classes.cursor} onClick={this.checkEndTime}><u>{this.state.endTime}</u></span>
                  <ArrowDropDownIcon className={classes.cursor} onClick={this.checkEndTime} />
                  <DatePickerNew
                    showTimeSelectOnly
                    className={classes.posAbsolute + ' availability_timepicker'}
                    open={this.state.checkEndTime}
                    ariaLabelledBy="Start"
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    showTimeSelect
                    onChange={(e) => this.changeTime(e, 'endTime')}
                  />

                </div>
              </div>
            </div>
          </ClickAwayListener>
        </div>

        <Grid className={classes.mt_7}>
          <span>Repeat every</span>
          <TextField
            type="number"
            name="repeat_time"
            onChange={this.onchange}
            className={classes.w100}
            value={this.state.repeat_time}
          />
          <Select
            value={this.state.repeat_type}
            onChange={this.onchange}
            variant="outlined"
            className={classes.w100}
            name={"repeat_type"}
          >
            <option className={classes.font + ' ' + classes.icon} value="day">Day</option>
            <option className={classes.font + ' ' + classes.icon} value="week">Week</option>
            <option className={classes.font + ' ' + classes.icon} value="month">Month</option>
            <option className={classes.font + ' ' + classes.icon} value="year">Year</option>
          </Select>
        </Grid>


        <Grid className={classes.flex + ' ' + classes.columnDirection + ' ' + classes.mt_7}>
          {this.state.repeat_type !== 'day' && this.state.repeat_type !== 'year' &&
            <span>Repeat On</span>
          }
          {this.state.repeat_type === 'week' ?
            <div className={classes.flex + ' ' + classes.mt_7}>
              <span className={`${classes.cursor} ${classes.flex} ${classes.justifyContentCenter} ${classes.alignItemsCenter} ${classes.days} ${this.state.selected_days.days.indexOf('sunday') > -1 ? classes.selected_days : ""}`} onClick={() => this.changeDays('sunday')}>S</span>
              <span className={`${classes.cursor} ${classes.flex} ${classes.justifyContentCenter} ${classes.alignItemsCenter} ${classes.days} ${this.state.selected_days.days.indexOf('monday') > -1 ? classes.selected_days : ""}`} onClick={() => this.changeDays('monday')}>M</span>
              <span className={`${classes.cursor} ${classes.flex} ${classes.justifyContentCenter} ${classes.alignItemsCenter} ${classes.days} ${this.state.selected_days.days.indexOf('tuesday') > -1 ? classes.selected_days : ""}`} onClick={() => this.changeDays('tuesday')}>T</span>
              <span className={`${classes.cursor} ${classes.flex} ${classes.justifyContentCenter} ${classes.alignItemsCenter} ${classes.days} ${this.state.selected_days.days.indexOf('wednesday') > -1 ? classes.selected_days : ""}`} onClick={() => this.changeDays('wednesday')}>W</span>
              <span className={`${classes.cursor} ${classes.flex} ${classes.justifyContentCenter} ${classes.alignItemsCenter} ${classes.days} ${this.state.selected_days.days.indexOf('thursday') > -1 ? classes.selected_days : ""}`} onClick={() => this.changeDays('thursday')}>T</span>
              <span className={`${classes.cursor} ${classes.flex} ${classes.justifyContentCenter} ${classes.alignItemsCenter} ${classes.days} ${this.state.selected_days.days.indexOf('friday') > -1 ? classes.selected_days : ""}`} onClick={() => this.changeDays('friday')}>F</span>
              <span className={`${classes.cursor} ${classes.flex} ${classes.justifyContentCenter} ${classes.alignItemsCenter} ${classes.days} ${this.state.selected_days.days.indexOf('saturday') > -1 ? classes.selected_days : ""}`} onClick={() => this.changeDays('saturday')}>S</span>
            </div>
            :
            this.state.repeat_type === 'month' ?
              <div className={classes.flex + ' ' + classes.columnDirection + ' ' + classes.mt_7}>
                <RadioGroup className={classes.rowDirection + ' ' + classes.flex + ' ' + classes.mt_7} name="monthlyType" value={this.state.monthlyType} onChange={this.onchange}>
                  <FormControlLabel value="day" control={<Radio color="primary" />} label="Monthly on day" />
                  <FormControlLabel value="first" control={<Radio color="primary" />} label="Monthly on first" />
                </RadioGroup>
                {this.state.monthlyType === 'first' ?
                  <div className={classes.flex + ' ' + classes.mt_7}>
                    <span className={`${classes.cursor} ${classes.flex} ${classes.justifyContentCenter} ${classes.alignItemsCenter} ${classes.days} ${this.state.selected_days.days.indexOf('sunday') > -1 ? classes.selected_days : ""}`} onClick={() => this.changeDays('sunday')}>S</span>
                    <span className={`${classes.cursor} ${classes.flex} ${classes.justifyContentCenter} ${classes.alignItemsCenter} ${classes.days} ${this.state.selected_days.days.indexOf('monday') > -1 ? classes.selected_days : ""}`} onClick={() => this.changeDays('monday')}>M</span>
                    <span className={`${classes.cursor} ${classes.flex} ${classes.justifyContentCenter} ${classes.alignItemsCenter} ${classes.days} ${this.state.selected_days.days.indexOf('tuesday') > -1 ? classes.selected_days : ""}`} onClick={() => this.changeDays('tuesday')}>T</span>
                    <span className={`${classes.cursor} ${classes.flex} ${classes.justifyContentCenter} ${classes.alignItemsCenter} ${classes.days} ${this.state.selected_days.days.indexOf('wednesday') > -1 ? classes.selected_days : ""}`} onClick={() => this.changeDays('wednesday')}>W</span>
                    <span className={`${classes.cursor} ${classes.flex} ${classes.justifyContentCenter} ${classes.alignItemsCenter} ${classes.days} ${this.state.selected_days.days.indexOf('thursday') > -1 ? classes.selected_days : ""}`} onClick={() => this.changeDays('thursday')}>T</span>
                    <span className={`${classes.cursor} ${classes.flex} ${classes.justifyContentCenter} ${classes.alignItemsCenter} ${classes.days} ${this.state.selected_days.days.indexOf('friday') > -1 ? classes.selected_days : ""}`} onClick={() => this.changeDays('friday')}>F</span>
                    <span className={`${classes.cursor} ${classes.flex} ${classes.justifyContentCenter} ${classes.alignItemsCenter} ${classes.days} ${this.state.selected_days.days.indexOf('saturday') > -1 ? classes.selected_days : ""}`} onClick={() => this.changeDays('saturday')}>S</span>
                  </div>
                  :
                  <div className={classes.flex + ' ' + classes.flexWrap + ' ' + classes.mt_7}>
                    {[...Array(31)].map((e, i) => {
                      return <span className={`${classes.cursor} ${classes.flex} ${classes.justifyContentCenter} ${classes.alignItemsCenter} ${classes.days} ${this.state.selected_dates.dates.indexOf((i + 1)) > -1 ? classes.selected_days : ""}`} onClick={() => this.changeDates((i + 1))}>{(i + 1)}</span>
                    })}
                  </div>
                }
              </div>
              :
              ""
          }

        </Grid>

        <Grid className={classes.flex + ' ' + classes.columnDirection + ' ' + classes.mt_7}>
          <span>Ends</span>
          <RadioGroup name="endsType" value={this.state.endsType} onChange={this.onchange}>
            <div><FormControlLabel value="never" control={<Radio color="primary" />} label="Never" className={classes.radioButton} /></div>
            <div className={classes.flex + ' ' + classes.alignItemsCenter}>
              <FormControlLabel value="on" control={<Radio color="primary" />} label="On" className={classes.radioButton} />
              {/* <DatePicker disabled={this.state.endsType === 'on'?false:true} defaultValue={moment()} onChange={(e)=>this.changeDate(e,'endsValue')} format="ll" /> */}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  disabled={this.state.endsType === 'on' ? false : true}
                  value={this.state.endsType === 'on' ? this.state.endsValue ? moment(this.state.endsValue) : moment().subtract(1, 'days') : null}
                  defaultValue={moment()}
                  onChange={(e) => this.changeDate(e, 'endsValue')}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <FormControlLabel value="after" control={<Radio color="primary" />} label="After" className={classes.radioButton} />
              <TextField
                type="number"
                step="10" min="0" max="100"

                name="endsValue"
                onChange={this.onchange}
                className={classes.w100}
                disabled={this.state.endsType === 'after' ? false : true}
                value={this.state.endsType === 'after' ? this.state.endsValue : 0}
              />
                 occurences
              </div>
          </RadioGroup>
        </Grid>

        {!this.state.loading ?
          <Grid className={classes.flex + ' ' + classes.justifyContentEnd + ' ' + classes.mt_7}>
            <Button onClick={() => this.props.onCancel('showAvailability')} >Cancel</Button>
            <Button color="primary" onClick={this.saveAvailability}>Done</Button>
          </Grid>
          :
          <Grid className={classes.flex + ' ' + classes.justifyContentEnd + ' ' + classes.mt_7}>
            <CircularProgress size={24} />
          </Grid>
        }

      </Grid>

    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    account: state.account,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createAndCheckAvailability: (data) => createAndCheckAvailability(data)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DoctorAvailability));
