import React, { useState } from 'react'
//redux stuff
import { connect } from 'react-redux'
import { updateAppointmentStatus, verifyHC } from '../../../../store/actions/appointmentAction'
//components
import ChangedRequestsModal from '../ChangedRequestsModal'
//Material-ui
import { withStyles } from '@material-ui/styles'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
//helper functions
import {
  getDoctorNameFromChangeRequest,
  getNewDateFromChangeRequest,
  getDoctorNameFromDoctorsMadeAppointment,
  getDateFromDoctorsMadeAppointment,
  // updateResults
} from '../utils'
const styles = theme => ({
  container: {
    '& .MuiPaper-root': {
      borderRadius: '2rem',
      margin: '0',
    },
  },
  tableHeader: {
    "& .MuiTableCell-root.MuiTableCell-head": {
      fontWeight: 'bold'
    }
  },
  tableRowBody: {
    "& .MuiTableCell-body": {
      border: 'none',
    },
  },
  acceptButton: {
    width: '130px',
    textTransform: 'none',
    backgroundColor: 'rgb(216,243,243)',
    color: 'rgb(78,202,200)',
    "&:hover": {
      backgroundColor: 'rgb(216,243,243)',
    },
    borderRadius: '.5rem',
  },
  changed: {
    textTransform: 'none',
    backgroundColor: 'rgb(253,244,213)',
    color: 'rgb(242,181,39)',
    height: '2rem',
    "&:hover": {
      backgroundColor: 'rgb(253,244,213)',
      cursor: 'default'
    },
    width: '5rem',
    borderRadius: '.5rem',
  },
  added: {
    textTransform: 'none',
    backgroundColor: 'rgb(255,226,223)',
    color: 'rgb(255,78,62)',
    height: '2rem',
    "&:hover": {
      backgroundColor: 'rgb(255,226,223)',
      cursor: 'default'
    },
    width: '5rem',
    borderRadius: '.5rem',
  }
})
const ChangedRequestsMobile = (props) => {
  // console.log(props)
  // const [loading, setLoading] = useState(false)
  // const [idClicked,setIdClicked] = useState()

  const [showModal, setShowModal] = useState(false)
  const [rowInformation, setRowInformation] = useState()
  /* FOR TASK RHYN-102
    TASK DESCRIPTION: Add patient tab and subaccounts for age <16
    CHECK IS REDUX SUBACCOUNT STATE HAS KEY subAccountId THEN GET ALL DATA RELATED TO SUBACCOUNT */
  // const userId = props.parentProps.auth.uid;
  const userId = props.parentProps.subAccount.subAccountId ? props.parentProps.subAccount.subAccountId : props.parentProps.auth.uid
  const fullName = props.parentProps.account.data.fullName;
  const { classes, dataTable, changeData } = props;
  const toggleModal = () => {
    setShowModal(true)
    if (showModal === true) setShowModal(false)

  }
  if (dataTable.length > 0) {
    return (
      <div className={classes.container}>
        <h2 className={classes.tableTitles}>Updated appointments</h2>

        {/* Modal */}
        <ChangedRequestsModal
          parentProps={props.parentProps}
          toggleModal={toggleModal}
          modalVisible={showModal}
          row={rowInformation}
          changeData={changeData}
          userId={userId}
          fullName={fullName}
        />
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow className={classes.tableHeader}>
                <TableCell>Doctor</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.map((row, id) => (
                <TableRow
                  className={classes.tableRowBody}
                  key={id}>
                  <TableCell align="left">
                    {row.addedBy === 'doctor' ?
                      getDoctorNameFromDoctorsMadeAppointment(row.text)
                      :
                      getDoctorNameFromChangeRequest(row.text)
                    }
                    <br></br>
                    {row.addedBy === 'doctor' ?
                      getDateFromDoctorsMadeAppointment(row.text)
                      :
                      getNewDateFromChangeRequest(row.text)
                    }
                    <br></br>
                    <Button
                      className={
                        row.addedBy === 'patient' ? classes.changed : classes.added
                      }
                    >
                      {row.addedBy === 'patient' ? <>Changed</> : <>Added</>}
                    </Button>

                  </TableCell>
                  <TableCell align="center">
                    {/* {loading && idClicked===id ?  */}
                    {/* <CircularProgress size={24}></CircularProgress> */}
                    {/* : */}
                    {/* <div> */}
                    {/* {row.addedBy === 'doctor'? */}
                    {/* <Button
                                variant={"contained"} 
                                className={classes.acceptButton}
                                onClick={ () => {
                                    // setLoading(true);
                                    // setIdClicked(id)
                                    // checkStripePayment(row.id, row.doctorId, row.holdamount, )
                                    setRowInformation(row)
                                    toggleModal()

                                    }
                                }>
                                    {/* Accept Appointment */}
                    {/* Accept
                                </Button> */}
                    {/* : */}
                    <Button
                      variant={"contained"}
                      className={classes.acceptButton}
                      onClick={() => {
                        // setLoading(true);
                        // setIdClicked(id)
                        // acceptAppointment(row.id,  'accepted', row.doctorId, userId,fullName)
                        setRowInformation(row)
                        toggleModal()
                      }
                      }>
                      {/* Accept schedule change */}
                                    Accept/Change
                                </Button>
                    {/* } */}
                    {/* </div> */}
                    {/* } */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  }
  else {
    return (
      <></>
    )
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateAppointmentStatus: (data) => updateAppointmentStatus(data),
    verifyHC: (data) => verifyHC(data)
  }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(ChangedRequestsMobile))
