import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import withStyles from '@material-ui/styles/withStyles'
import Scheduler from './scheduler.js'
import { connect } from 'react-redux'


const styles = theme => ({
  parent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    flexDirection: 'column'
  },
  child: {
    justifyContent: 'start',
    width: '100%',
    borderRadius: theme.spacing(.5),
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  child1: {
    [theme.breakpoints.down('sm')]: {
      order: 2,
      marginTop: theme.spacing(9)
    }
  },
  child2: {
    [theme.breakpoints.down('sm')]: {
      order: 1,
      marginBottom: theme.spacing(2)
    }
  },
  availability: {
    position: 'relative',
    paddingBottom: theme.spacing(4),
    backgroundColor: '#fff'
  },
})
class doctorDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      updateScheduler: true,
    };
    props.NotificationStateUpdate()
  }
  updateSchedulerFromAvailability = () => {
    this.setState({ updateScheduler: false }, () => {
      this.setState({ updateScheduler: true })
    })
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.isNotificationUpdate) {
      this.props.NotificationStateUpdate()
      this.updateSchedulerFromAvailability()
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.parent}>
        <Grid className={classes.child + ' ' + classes.child1}>
          {this.state.updateScheduler ? <Scheduler updateSchedulerFromAvailability={this.updateSchedulerFromAvailability} /> : ""}
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    NotificationStateUpdate: () => dispatch({
      type: "NOTIFICATION_STATE_UPDATE",
      value: false
    }),
  }
}
export default connect(null, mapDispatchToProps)(withStyles(styles)(doctorDashboard));