import React from 'react'
import { Grid, CircularProgress, Button } from '@material-ui/core';
import { buttonCancel, buttonSave, buttonDelete, modelFooter, circularProgress } from './style'

function ModelFooter({ deleteClicked, onCloseModel, saveClicked, id, loader, loaderType }) {
        return (

            <Grid style={modelFooter}>
                {id && <Button style={buttonDelete} variant="contained" onClick={deleteClicked} > {loader && loaderType === 'delete' ? <CircularProgress style={circularProgress} size={24} /> : "Delete"} </Button>}
                <Button style={buttonCancel} variant="outlined" onClick={onCloseModel}>Cancel</Button>
                <Button variant="contained" style={buttonSave} onClick={saveClicked} > {loader && loaderType === 'save' ? <CircularProgress style={circularProgress} size={24} /> : "Save"} </Button>
            </Grid>
        )
    }

export default ModelFooter
