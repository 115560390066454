import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Button, CircularProgress, CssBaseline, TextField, Link, Grid, Box, Typography, Container, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { connect } from 'react-redux'
import { signIn } from '../../store/actions/authActions'
import { getSpecialities, getStates, getCountries } from '../../store/actions/authActions'
import '../../App.css';
import { Redirect } from 'react-router-dom';
import { TopTitle } from '../../config.js'
import cookie from 'react-cookies'
import { SKIP2FEMAILS } from '../../config'


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {TopTitle}
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const signinStyles = theme => ({
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(5)}px`,
    [theme.breakpoints.down('sm')]: { padding: theme.spacing(2) }
  },
  actions: {
    [theme.breakpoints.down('sm')]: { flexDirection: 'column' }
  },
  container: {
    maxWidth: "inherit"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});
let re = /\S+@\S+\.\S+/
class SignIn extends Component {

  state = {
    email: '',
    password: '',
    loading: false,
    disabled: true
  }
  componentDidMount() {
    this.props.setTotalCalls(4, 0)
    this.props.updateState(this.props.isValid, this.props.isLoggedIn)
  }

  handleChange = (e) => {
    this.props.resetState()
    if (e.target.value === '') {
      this.setState({
        [e.target.id + "Class"]: true
      })
    }
    if (e.target.value !== '') {
      this.setState({
        [e.target.id + "Class"]: false
      })
    }
    if (e.target.id === 'email') {
      if (!re.test(e.target.value))
        this.setState({ emailClass: true, emailError: 'Enter a valid email' })
    }
    this.setState({
      [e.target.id]: e.target.value
    }, this.checkDisable)
  }
  checkDisable = () => {
    if (this.state.email !== '' && this.state.password !== '' && this.state.emailClass === false)
      this.setState({ disabled: false })
    else
      this.setState({ disabled: true })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    let isSendCode = cookie.load(this.state.email)
    let sendCode = true
    if (isSendCode === 'true' || (this.state.email).indexOf(SKIP2FEMAILS) > 0) {
      sendCode = false
    }
    this.setState({ loading: true, disabled: true })
    // setTimeout(() => this.setState({ loading: false }), 2000);
    this.props.signIn({ email: this.state.email, password: this.state.password, isSendCode: sendCode })
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.disabled === true && this.props.authError !== '' && this.props.authError !== null) {
      this.setState({ disabled: false, loading: false })
    }
  }
  render() {
    const { classes, auth, authError } = this.props;
    //if its trusted devide or validate = true ... dashboard && uid 
    //if its not trusted and validate == false 2factor && signin = true
    if (auth.uid && auth.hasOwnProperty('is2FValidate') && auth.is2FValidate === true) return <Redirect to='/dashboard' />
    // if (auth.signedInUserId !== '' && auth.hasOwnProperty('is2FValidate') && auth.is2FValidate === false) return <Redirect to='/TFAuthenticate' />
    if (auth.isLoggedIn === true) return <Redirect to='/TFAuthenticate' />
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.container}>
          <Paper elevation={1} className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
          </Typography>
            {authError ? <h4 className="red-text-center">{authError}</h4> : null}
            <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                error={this.state.emailClass}
                helperText={this.state.emailClass ? this.state.emailError : ''}
                style={{ marginBottom: "16px" }}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={this.handleChange}
                onBlur={this.handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                error={this.state.passwordClass}
                helperText={this.state.passwordClass ? 'password is required' : ''}
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={this.handleChange}
                onBlur={this.handleChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                disabled={this.state.disabled}
                className={classes.submit}
              >
                {/* Sign In */}
                {this.state.loading && <CircularProgress size={21} />}
                {!this.state.loading && 'Sign In'}
              </Button>
              <Grid container className={classes.actions}>
                <Grid item xs>
                  <Link href="/forgetpassword" variant="body2">
                    Forgot password?
                </Link>
                </Grid>
                <Grid item>
                  <Link href="/signup" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </form>
            <Box mt={8}>
              <Copyright />
            </Box>
          </Paper>
        </div>
      </Container>
    );
  }
};

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  dispatch(getSpecialities())
  dispatch(getCountries())
  dispatch(getStates())
  return {
    signIn: (creds) => dispatch(signIn(creds)),
    updateState: (isValid, isLoggedIn) => dispatch({
      type: "STATE_UPDATE",
      isValid: isValid,
      isLoggedIn: isLoggedIn
    }),
    resetState: () => dispatch({
      type: "RESET_STATE",
    }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(signinStyles)(SignIn));