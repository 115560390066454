import React from 'react'
import { TextField, Grid, Button, CircularProgress } from '@material-ui/core'
import { modelBody, modelRow, buttonCancel, buttonSave, buttonSavedDisabled, modelFooter, buttonDelete, circularProgress, formRowLastField } from '../style'
import { Alert } from 'antd'
import AutoCompleteComponent from '../AutoCompleteComponent'
import { Link } from 'react-router-dom'

function PrescribtionForm({ loader, loaderType, id, deleted, message, isCustom, setIsCustom, formSubmitted, medication, setMedication, duration, setDuration, frequency, setFrequency, repeats, setRepeats, note, setNote, add, onCloseModel, prescriptions, noOfRows }) {
    return (
        <div>
            <div className='modal-body' style={modelBody}>
                <Grid>
                    {message ?
                        <Grid >
                            <Alert type="error" closable={true} message={message}></Alert>
                        </Grid>
                        : ""}
                </Grid>
                <Grid style={modelRow}>
                    <Link to="#" onClick={() => setIsCustom(!isCustom)}>{isCustom ? "Search Medication" : "Add Custom Medication"}</Link>
                </Grid>
                <Grid style={modelRow}>
                    <AutoCompleteComponent isCustom={isCustom} type={"Medication"} formSubmitted={formSubmitted} value={medication} setValue={setMedication} />
                    <TextField
                        type="text"
                        variant="outlined"
                        margin="normal"
                        style={formRowLastField}
                        label="Duration"
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                        error={formSubmitted && !duration}
                        helperText={formSubmitted && !duration && "Duration Field Required"}
                    />

                </Grid>
                <Grid style={modelRow}>
                    <TextField
                        type="text"
                        variant="outlined"
                        margin="normal"
                        label="Frequency"
                        value={frequency}
                        onChange={(e) => setFrequency(e.target.value)}
                        error={formSubmitted && !frequency}
                        helperText={formSubmitted && !frequency && "Frequency Field Required"}
                    />
                    <TextField
                        type="text"
                        variant="outlined"
                        margin="normal"
                        style={formRowLastField}
                        label="Repeats"
                        value={repeats}
                        onChange={(e) => setRepeats(e.target.value)}
                        error={formSubmitted && !repeats}
                        helperText={formSubmitted && !repeats && "Repeats Field Required"}
                    />

                </Grid>
                <Grid style={modelRow}>
                    <TextField
                        type="text"
                        variant="outlined"
                        margin="normal"
                        label="Note"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                    />
                </Grid>
            </div >
            <Grid style={modelFooter}>
                {id ? <Button style={buttonDelete} variant="contained" onClick={deleted}> {loader && loaderType === 'save' ? <CircularProgress style={circularProgress} size={24} /> : "Delete"} </Button> : ""}
                <Button disabled={noOfRows > 3} style={noOfRows > 3 ? buttonSavedDisabled : { ...buttonSave, ...{ padding: "7px 10px" } }} variant="contained" onClick={() => add(true)}> Save & Add More </Button>
                <Button style={buttonSave} variant="outlined" onClick={() => add(false)}>Save</Button>
                <Button variant="contained" style={buttonCancel} onClick={onCloseModel}>Cancel</Button>
            </Grid>
            {prescriptions}
        </div>
    )
}

export default PrescribtionForm
