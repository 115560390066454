
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'relative',
        height: '100%',
        paddingBottom: 110,
        textAlign: 'left',
    },
    fullWidthButton: {
        width: '100%',
        fontSize: 15,
        backgroundColor: '#795bb5'
    },
    bottomInnerStepper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 20,
    },
    link: {
        color: '#1cb5c5',
        fontSize: 17,
        textDecoration: 'none',
        [theme.breakpoints.down(767)]: {
            fontSize: 11,
        },
    },
    label: {
        color: "#795bb5 !important",
        fontSize: '17px !important',
    },
    button: {
        marginRight: 10,
        marginTop: 20,
        backgroundColor: '#795bb5 !important',
        color: '#fff !important',
        fontWeight: '400 !important',
        fontSize: '1rem !important',
        padding: '0.2rem 2rem !important',
    },
    stepperWrapper: {
        padding: 0,

    },
    bottomStepper: {
        position: 'absolute',
        bottom: 0,
        width: '100%'
    },
    formGroup: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0 -10px',
        [theme.breakpoints.down(767)]: {
            flexDirection: 'column',
            margin: '0',
        },
    },
    input: {
        width: '100%'
    },
    select: {
        width: '100%'
    },
    fullWidthInput: {
        width: '100%',
        margin: '0 10px 20px !important'
    },
    halfWidth: {
        maxWidth: 'calc(50% - 20px)',
        flex: 'calc(50% - 20px)',
        margin: '0 10px 20px',
        [theme.breakpoints.down(767)]: {
            maxWidth: 'calc(100%)',
            flex: 'calc(100%)',
            margin: '0 0 15px',
        },
    },
    title: {
        color: '#1cb5c5',
        fontSize: 38,
        textAlign: 'center',
        margin: '0 0 25px',
        [theme.breakpoints.down(767)]: {
            fontSize: 24,
        },
    },
    signupIcon: {
        width: 50,
        height: 50,
        margin: '0 auto 20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        backgroundColor: '#785caf',
        borderRadius: '50%'
    },
    signupIconImg: {
        width: '61%'
    },
    alignItemsCenter: {
        alignItems: 'center'
    },
    cursor: {
        cursor: 'pointer'
    },
    fullWidth: {
        width: '100%',
        margin: '0 0 20px'
    },
    halfWidthInfo: {
        maxWidth: 'calc(50% - 10px)',
        flex: 'calc(50% - 10px)',
        margin: '0 5px 0',
        [theme.breakpoints.down(767)]: {
            maxWidth: 'calc(100%)',
            flex: 'calc(100%)',
            margin: '0 0 15px',
        },
    },
    photoLabel: {
        zIndex: 1,
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        paddingLeft: '15px',
        transform: 'translate(0)'
    },
    uploadClass: {
        display: 'none'
    },
    mobileClass: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    cursorPointer: {
        cursor: 'pointer'
    }
}));

export default useStyles;