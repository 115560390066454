import React, { Component } from 'react'
import withStyles from "@material-ui/core/styles/withStyles"
import { Grid, Button, Select, FormControl, InputLabel, MenuItem, TextField } from '@material-ui/core'
import { connect } from 'react-redux'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { styled } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';

import Badge from '@material-ui/core/Badge';
import AkForTraveling from './AkForTraveling'

const styles = theme => ({
    popupDiv: {
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: 'auto',
        margin: 'auto',
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            width: '100%'
        }
    },
    font: {
        fontFamily: 'revert',
        width: '100%'
    },
    mainDiv: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    fieldsDiv: {
        display: 'flex',
        '& .MuiFormControl-root': {
            borderRadius: '0.2rem',
        },
        '& .MuiFormLabel-root': {
            paddingLeft: '3%'
        },
        '& .MuiInputBase-root': {
            paddingLeft: '3%'
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginBottom: '22px',
            borderBottom: '1px solid #80808026',
        }
    },
    fieldsDiv1: {
        flexDirection: 'column'
    },
    halfWidth: {
        maxWidth: 'calc(50% - 20px)',
        width: 'calc(50% - 20px)',
        flex: 'calc(50% - 20px)',
        margin: '0 10px 20px',
        [theme.breakpoints.down(767)]: {
            maxWidth: 'calc(100%)',
            width: 'calc(100%)',
            flex: 'calc(100%)',
            margin: '0 0 15px',
        },
    },
    onefourWidth: {
        maxWidth: 'calc(16% - 20px)',
        width: 'calc(16% - 20px)',
        flex: 'calc(16% - 20px)',
        margin: '0 10px 20px !important',
        [theme.breakpoints.down(767)]: {
            maxWidth: 'calc(100%)',
            width: 'calc(100%)',
            flex: 'calc(100%)',
            margin: '0 0 15px',
        },
    },
    center: {
        margin: 'auto',
        width: "50%",
        [theme.breakpoints.down(767)]: {
            width: '100%',
        },
    },
    alignLeft: {
        textAlign: 'left'
    },
    topSpace: {
        marginTop: theme.spacing(2)
    },
	cards: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    card: {
        width: 'auto',
        margin: '0 8px 20px',
		background: '#f1fbfb',
		position: 'relative',
    },
    fieldItem: {
        marginBottom: '10px !important',
		textAlign:'left'
    },
})

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 0,
    right: '0.8rem',
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
	color:'#fff',
	height: '25px',
	minWidth: '25px',
	borderRadius: '20px',
  },
}));
class RhynoSelectUsers extends Component {

    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = {
            // askForTraveling: (this.props.appointmentData.reason).indexOf('PCR') > -1 ? true : false,
            askForTraveling: false,
            showFields: false,
            numberOfUsers: 1,
            travelCountry: '',
            swab: 'Nasopharyngeal',
            membersData: {},
        }
    }

    changeNoOfUsers = (e) => {
        this.setState({ numberOfUsers: e.target.value })
    }

    changeName = (e, type, name) => {
        let membersData = this.state.membersData
        let value = ''
        if (type === 'date_of_birth')
            value = moment(e).format('YYYY/MM/DD')
        else if (type === 'health_card_number' || type === 'passport_no' ) {
            value = (e.target.value).trim()
            value = value.replace(/[^a-zA-Z0-9]/g, '')
            value = value.toUpperCase();
        }
        else
            value = e.target.value
        let userOject = { [type]: value, zip: this.props.account.zip, phone: this.props.account.countryDialCode + this.props.account.mobile }
        membersData[name] = { ...this.state.membersData[name], ...userOject }
        this.setState({ membersData: membersData })
    }

    showFields = () => {
        // if (this.state.numberOfUsers === 1) {
        //     let membersData = this.state.membersData
        //     let userOject = { 'first_name': this.props.account.firstName, 'last_name': this.props.account.lastName, date_of_birth: moment(this.props.account.dateofBirth).format('YYYY/MM/DD'), health_card_number: this.props.account.hCard, gender: this.props.account.gender, zip: this.props.account.zip, phone: this.props.account.countryDialCode + this.props.account.mobile }
        //     membersData[0] = userOject
        //     this.setState({ membersData: membersData, showFields: !this.state.showFields }, () => {
        //         this.nextStep()
        //     })
        // }
        // else {
        let membersData = {} //this.state.membersData
        let userOject = {}
        if (!(this.props.appointmentData.hasOwnProperty('isAgency') && this.props.appointmentData.isAgency))
            userOject = { 'first_name': this.props.account.firstName, 'last_name': this.props.account.lastName, passport_no:'', date_of_birth: moment(this.props.account.dateofBirth).format('YYYY/MM/DD'), health_card_number: this.props.account.hCard, gender: this.props.account.gender, zip: this.props.account.zip, phone: this.props.account.countryDialCode + this.props.account.mobile }
        membersData[0] = userOject
        this.setState({ membersData: membersData, showFields: !this.state.showFields })
        // }
    }

    updateDestination = (country, swab) => {
        this.setState({ travelCountry: country, swab, askForTraveling: false })
    }

    nextStep = () => {

        let isFormValid = true
        let membersListData = this.state.membersData
        for (let i = 0; i < this.state.numberOfUsers; i++) {
            let memberData = membersListData[i]
            if (memberData === undefined) {
                let userOject = { 'first_name': '', 'last_name': '', passport_no:'', date_of_birth: null, health_card_number: '', gender: '', zip: this.props.account.zip, phone: this.props.account.countryDialCode + this.props.account.mobile }
                membersListData[i] = userOject
                isFormValid = false
            }
            else {
                if (!memberData.hasOwnProperty('first_name'))
                    memberData.first_name = ''
                if (!memberData.hasOwnProperty('last_name'))
                    memberData.last_name = ''
                if (!memberData.hasOwnProperty('date_of_birth'))
                    memberData.date_of_birth = moment().format('YYYY/MM/DD')
                if (!memberData.hasOwnProperty('health_card_number'))
                    memberData.health_card_number = ''
                if (!memberData.hasOwnProperty('gender'))
                    memberData.gender = ''
                if (!memberData.hasOwnProperty('passport_no'))
                    memberData.passport_no = ''

                if (this.props.appointmentData.hasOwnProperty('requisitionPhoneNumber') && this.props.appointmentData.requisitionPhoneNumber !== '')
                    memberData.requisitionPhoneNumber = this.props.appointmentData.requisitionPhoneNumber
                if (this.props.appointmentData.hasOwnProperty('requisitionEmail') && this.props.appointmentData.requisitionEmail !== '')
                    memberData.requisitionEmail = this.props.appointmentData.requisitionEmail
                if (memberData.first_name === '' || memberData.last_name === '' || memberData.gender === '' || memberData.date_of_birth === null || memberData.date_of_birth === 'Invalid date') {
                    isFormValid = false
                }
            }

        }
        if (!isFormValid) {
            console.log("membersData", membersListData)
            this.setState({ membersData: membersListData })
        }

        if (isFormValid) {
            let cost = 0
            if (this.props.appointmentData.reason === 'PCR' || this.props.appointmentData.reason === 'URGENT PCR') {
                cost = this.props.appointmentData.rhynoGoCost * this.state.numberOfUsers
            }
            else if (this.props.appointmentData.reason === 'RAPID ANTIGEN') {
                cost = this.props.appointmentData.rhynoGoCost[0]
                if (this.state.numberOfUsers === 2)
                    cost = cost + this.props.appointmentData.rhynoGoCost[1]
                else if (this.state.numberOfUsers > 2)
                    cost = cost + this.props.appointmentData.rhynoGoCost[1] + (this.props.appointmentData.rhynoGoCost[2] * (this.state.numberOfUsers - 2))
            }

            let obj = {}
            obj.previousStep = 11
            obj.rhynoGoCost = cost.toFixed(2)
            obj.numberOfUsers = this.state.numberOfUsers
            obj.membersData = this.state.membersData
            obj.travelCountry = this.state.travelCountry
            obj.swab = this.state.swab

            this.props.updateStepNo(10, obj)
        }
    }
    render() {
        let { classes } = this.props
        return (
            <>
                <Grid className={classes.font}>
                    <Grid className={classes.popupDiv} >
                        <Grid className={classes.mainDiv}>
                            {this.state.askForTraveling ?
                                    <AkForTraveling updateDestination={this.updateDestination} />
                                    :
                                    !this.state.showFields  ?
                                <>
                                    <h2>How many in the same household require this test ?</h2>
                                        <FormControl variant="outlined" className={classes.center + ' ' + classes.fieldItem}>
                                        <InputLabel id="numberOfUsers">HouseHold Members</InputLabel>
                                        <Select
                                            labelId="country"
                                            id="numberOfUsers"
                                            name="numberOfUsers"
                                            label="HouseHold Members"
                                            variant="outlined"
                                            className={classes.alignLeft}
                                            fullWidth
                                            value={this.state.numberOfUsers}
                                            onChange={(e) => this.changeNoOfUsers(e)}
                                        >
                                                {Array.from(Array(25).keys()).map((item, key) => {
                                                    return <MenuItem key={key} value={item + 1}>{item + 1}</MenuItem>
                                            })
                                            }
                                        </Select>
                                    </FormControl>

                                    <Button variant="contained" onClick={() => this.showFields()} color={'primary'} className={classes.center}>
                                        Next
                                    </Button>
                                </>
                                :
                                <>
									<Grid className={classes.cards} >
                                     {Array.from(Array(this.state.numberOfUsers).keys()).map((item, key) => {
                                        return (
										  <StyledBadge badgeContent={item+1} color="primary" key={key}>
                                           <Card variant="outlined" className={classes.card}>
												<CardContent>
												<div className={classes.fieldItem}>	
                                                <TextField
                                                    value={this.state.membersData[item] ? this.state.membersData[item].first_name : ''}
                                                    onChange={(e) => this.changeName(e, 'first_name', item)}
                                                    onBlur={(e) => this.changeName(e, 'first_name', item)}
                                                    label="First & Middle Name *"
                                                    error={this.state.membersData[item] && this.state.membersData[item].first_name === '' ? true : false}
													variant="standard"
                                                    helperText={this.state.membersData[item] && this.state.membersData[item].first_name === '' ? 'Field Required' : ""}
                                                />
												</div>
												 <div className={classes.fieldItem}>
                                                <TextField
                                                    value={this.state.membersData[item] ? this.state.membersData[item].last_name : ''}
                                                    onChange={(e) => this.changeName(e, 'last_name', item)}
                                                    onBlur={(e) => this.changeName(e, 'last_name', item)}
                                                    label="Last Name *"
                                                    error={this.state.membersData[item] && this.state.membersData[item].last_name === '' ? true : false}
													variant="standard"
                                                    helperText={this.state.membersData[item] && this.state.membersData[item].last_name === '' ? 'Field Required' : ""}
                                                />
												</div>
												 <div className={classes.fieldItem}>
                                                <MuiPickersUtilsProvider
                                                    utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        autoOk
                                                        variant="inline"
                                                        label="Date of Birth *"
                                                        PopoverProps={{ style: { zIndex: 2000 }, }}
                                                        value={this.state.membersData[item] && this.state.membersData[item].date_of_birth ? this.state.membersData[item].date_of_birth : null}
                                                        placeholder={"dd/mm/yyyy"}
                                                        format="dd/MM/yyyy"
                                                        onChange={(e) => this.changeName(e, 'date_of_birth', item)}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={this.state.membersData[item] ? this.state.membersData[item].date_of_birth === 'Invalid date' ? true : this.state.membersData[item].date_of_birth === null ? true : false : false}
                                                        helperText={this.state.membersData[item] ? this.state.membersData[item].date_of_birth === 'Invalid date' ? 'Invalid date' : this.state.membersData[item].date_of_birth === null ? 'Field Required' : null : null}
                                                    />
                                                </MuiPickersUtilsProvider>
												</div>
												 <div className={classes.fieldItem}>
												<FormControl variant="outlined" fullWidth>
													<InputLabel variant="standard" htmlFor="uncontrolled-native">
													  Gender *
													</InputLabel>
													<NativeSelect
														defaultValue={this.state.membersData[item] ? this.state.membersData[item].gender : ''}
                                                        onChange={(e) => this.changeName(e, 'gender', item)}
														  inputProps={{
															name: 'gender',
															id: 'gender',
														  }}
                                                    >
													  <option value=''></option>
													  <option value='Male'>Male</option>
													  <option value='Female'>Female</option>
													</NativeSelect>
                                                    {this.state.membersData[item] && this.state.membersData[item].gender === '' && (
                                                        <p className="MuiFormHelperText-root Mui-error">{"Select Gender"}</p>
                                                    )}
                                                </FormControl>
												</div>
												 <div className={classes.fieldItem}>
                                                <TextField
                                                    value={this.state.membersData[item] ? this.state.membersData[item].health_card_number : ''}
                                                    onChange={(e) => this.changeName(e, 'health_card_number', item)}
                                                    onBlur={(e) => this.changeName(e, 'health_card_number', item)}
                                                    label="Health Card (optional)"
                                                />
												</div>
												 <div  className={classes.fieldItem}>
                                                    <TextField
                                                        value={this.state.membersData[item] ? this.state.membersData[item].passport_no : ''}
                                                        onChange={(e) => this.changeName(e, 'passport_no', item)}
                                                        onBlur={(e) => this.changeName(e, 'passport_no', item)}
                                                        label="Passport No (optional)"
                                                    />
												</div>
                                             </CardContent>
											</Card>
											</StyledBadge>
                                        )
                                    })
                                    }
									</Grid>
                                    <div>
                                        <Button variant="contained" onClick={() => this.showFields()} color={'primary'} className={classes.halfWidth}>
                                            Back
                                        </Button>
                                        <Button variant="contained" onClick={() => this.nextStep()} color={'primary'} className={classes.halfWidth}>
                                            Next
                                        </Button>
                                    </div>
                                </>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        account: state.account.data,
    }
}
export default connect(mapStateToProps, null)(withStyles(styles)(RhynoSelectUsers))
