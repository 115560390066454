import React, { useState } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import Functions from '../../assets/js/function'

function AutoCompleteComponent({ isCustom, type, formSubmitted, value, setValue }) {
    const [allergyListArray, setAllergyListArray] = useState([])
    const [showAutocompleteList, setShowAutocompleteList] = useState(false)

    const updateVal = (e, val) => {
        setValue(val)
        setShowAutocompleteList(false)
    }

    const allergyentered = async (e, val) => {
        if (val.length === 4) {
            let allergyArray = []
            setValue(val)
            let result = await Functions.searchMedicationList(val)
            allergyArray = result.data
            setShowAutocompleteList(true)
            setAllergyListArray(allergyArray)
            if (allergyArray.length === 0)
                setShowAutocompleteList(false)
            else
                setShowAutocompleteList(true)
            if (allergyArray.length === 1 && allergyArray[0] === val) {
                setShowAutocompleteList(false)
            }
        }
        else if (val.length < 4) {
            setValue(val)
            setShowAutocompleteList(false)
        }
    }

    return (
        <>
            {!isCustom ?
                <Autocomplete
                    fullWidth
                    value={value}
                    open={showAutocompleteList}
                    options={allergyListArray}
                    getOptionLabel={(option) => option}
                    onInputChange={allergyentered}
                    freeSolo
                    onChange={updateVal}
                    renderInput={(params) => <TextField
                        fullWidth {...params}
                        label="Search medication"
                        variant="outlined"
                        error={formSubmitted && !value}
                        helperText={formSubmitted && !value ? type + " Field Required" : ""}
                    />
                    }
                />
                :
                <TextField
                    type="text"
                    variant="outlined"
                    margin="normal"
                    label={type}
                    value={value}
                    onChange={(e) => updateVal(e, e.target.value)}
                    error={formSubmitted && !value}
                    helperText={formSubmitted && !value ? type + " Field Required" : ""}
                />
            }
        </>
    )
}

export default AutoCompleteComponent
