import React, { useState } from 'react'
import RequisitionForm from './RequisitionForm'
import { Button, Grid, CircularProgress } from '@material-ui/core'
import moment from 'moment'
import { CreateNote } from '../../../../store/actions/EMRAction'
import { useSelector } from 'react-redux';
import { viewMoreStyle } from '../../style'

function OntarioLabRequisition({ onCloseModel, isRhynoGoPatient, patientName, data }) {
    const { profile, userInfo } = useSelector(
        (state) => ({
            profile: state.account.data,
            userInfo: state.emr.UserInfo
        })
    );

    const [labRequisitionData, setLabRequisitionData] = useState({})
    const [loader, setLoader] = useState(false)

    const updateRequisitionData = (data) => {
        setLabRequisitionData(data)
    }

    const cancelLabRequisition = async () => {
        onCloseModel(false, {}, 1)
    }

    const saveRequisition = async () => {
        setLoader(true)
        let showDr = profile.speciality === 'Nurse Practitioner' ? '' : 'Dr. '
        let showNP = profile.speciality === 'Nurse Practitioner' ? ', NP' : ''
        // SET DOCTOR DATA VARIABLE
        const doctorData = {
            'name': showDr + profile.fullName + showNP,
            'address': profile.street + ", " + profile.city + ", " + profile.state + ", " + profile.country + "\nFax: " + profile.fax,
            'contactNumber1': profile.phone,
            'doctorSignature': profile.signature,
            'CPSO  Registration No': profile.licenseInfo,
            'ClinicianPractitioner Number': profile.billingInfo,
            'Date': moment().format('L')
        }
       
        //SKYPE_CONVERSATION: 26-MAY-2022 6:58PM
        //Hi Mazhar - There is a bug. If the health card has hypen or other characters (in older accounts),
        // the Ontario lab requisition generates an error as it causes a rendering issue.
        // Can you please fix by adding a code that if the health card contains special characters or spaces,
        // it automatically should get removed prior to rendering on the requisitions to be generated?
        // Dev work:Added below check to remove all special characters,spaces, letters rather than numbers
        console.log("userInfo",userInfo)
        let health_card_number = userInfo.hCard||''
        // if(health_card_number !== null || health_card_number !== ''){
        //     health_card_number = health_card_number.replace(/\D/g, '')
        //     console.log("health_card_number",health_card_number)
        // }

         // SET USER DATA VARIABLE
        const userData = {
            'patienFname': userInfo.firstName,
            'PatientOHIPLname': userInfo.lastName,
            'PatientAddress': userInfo.street + ", " + userInfo.city + ", " + userInfo.state + ", " + userInfo.zip + ", " + userInfo.country,
            'patientTelephone': userInfo.phone,
            'm_birth2': moment(userInfo.dateofBirth).format("M"),
            'd_birth2': moment(userInfo.dateofBirth).format("D"),
            'y_birth2': moment(userInfo.dateofBirth).format("YYYY"),
            'Health Number': health_card_number || '',
            'Province': userInfo.state,
            'sex': userInfo.gender,
        }

        let sentObj = JSON.stringify({
            noteType: "LabRequisition1",
            isRhynoGoPatient,
            patientName,
            userId: data.patientId,
            doctorId: data.doctorId,
            userData: userData,
            doctorData: doctorData,
            formData: labRequisitionData,
            pagination_id: data.pagination,
            appointmentId: data.hasOwnProperty('appointmentId') ? data.appointmentId : ""
        })
        // CREATE NOTE 
        let response = await CreateNote(sentObj)
        setLoader(false)
        onCloseModel(true, response.data, response.pagination_id)
    }

    return (
        <div>
            <RequisitionForm updateRequisitionData={updateRequisitionData} />
            <Grid style={buttonStyle}>
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={loader}
                    className={'btnCustomClass lightGreyClasstbn'}
                    style={viewMoreStyle}
                    onClick={cancelLabRequisition}>Cancel</Button>
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={loader}
                    className={'btnCustomClass lightBlueClasstbn'}
                    style={viewMoreStyle}
                    onClick={saveRequisition}>{loader ? <CircularProgress size={24} /> : "Save"}</Button>
            </Grid>
        </div>
    )
}
const buttonStyle = {
    display: 'flex',
    justifyContent: 'space-between'
}
export default OntarioLabRequisition
