import { isMobileOnly } from 'react-device-detect'

const panelStyle = {
    backgroundColor: 'white',
    padding: '10px',
    borderRadius: '10px',
    margin: '20px 5px 30px'
}
const chatPanelStyle = {
    backgroundColor: 'white',
    paddingBottom: '25px',
    margin: '20px 5px 40px',
    boxShadow: 'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px'
}
const mainStyle = {
    position: 'relative',
    width: '100%',
    minWidth: '100%'
}
const fabStyle = {
    bottom: '-45px',
    color: 'white',
    right: 0
}
const viewMoreStyle = {
    marginTop: '20px',
    width: 'fit-content'
}
const progress = {
    marginTop: '20px'
}
const sectionDetail = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
}
const item = {
    borderBottom: '1px solid #36363630',
    padding: '5px 0',
    width: '100%',
    textAlign: 'start',
    wordBreak: 'break-word'
}
const sections = {
    display: 'flex',
    flexDirection: isMobileOnly ? 'column' : 'row'
}
const header = {
    fontWeight: 900,
    textAlign: 'left'
}
const headerWithSort = {
    fontWeight: 900,
    textAlign: 'left',
    paddingLeft: '14px'
}
const tableRow = {
    display: 'flex',
    borderBottom: '1px solid #36363630',
}
const tableColumn = {
    padding: '5px 0',
    width: '100%',
    textAlign: 'start',
    wordBreak: 'break-word'
}
const editIcon = {
    cursor: 'pointer'
}
const headerItem = {
    fontWeight: 900,
    textAlign: 'left',
    borderBottom: '1px solid #36363630',
    padding: '5px 0',
    width: '100%',
    wordBreak: 'break-word'
}

const buttonCancel = {
    backgroundColor: 'rgb(240, 242, 245)',
    minWidth: "120px",
    color: 'white',
    padding: "7px 15px",
    borderRadius: '10px',
    border: 'none',
    height: '50px',
    margin: isMobileOnly ? '5px 0' : '0'
}
const buttonDelete = {
    minWidth: "120px",
    borderRadius: '10px',
    border: 'none',
    height: '50px',
    padding: "7px 15px",
    color: 'white',
    backgroundColor: "#df4759",
    "&:hover": {
        backgroundColor: "#c31432",
    },
    margin: isMobileOnly ? '5px 0' : '0'
}
const buttonSave = {
    minWidth: "120px",
    borderRadius: '10px',
    border: 'none',
    height: '50px',
    color: 'white',
    padding: "7px 15px",
    backgroundColor: 'rgb(0,178,181)',
    "&:hover": {
        backgroundColor: "rgb(0,178,181)",
        color: "#1f4037"
    },
    margin: isMobileOnly ? '5px 0' : '0'
}
const buttonSavedDisabled = {
    minWidth: "120px",
    borderRadius: '10px',
    border: 'none',
    height: '50px',
    color: 'white',
    padding: "7px 10px",
    backgroundColor: 'rgb(0 178 181 / 50%)',
    margin: isMobileOnly ? '5px 0' : '0'
}
const modelFooter = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    borderTop: '1px solid #80808024',
    paddingTop: '10px',
    flexDirection: isMobileOnly ? 'column' : 'row'
}
const modelBody = {
    display: 'flex',
    flexDirection: 'column'
}
const modelRow = {
    display: 'flex',
    marginTop: '10px',
    flexDirection: isMobileOnly ? 'column' : 'row',
}
const formRowLastField = {
    marginLeft: isMobileOnly ? '0' : '10px',
    marginTop: isMobileOnly ? '10px' : '0'
}
const circularProgress = {
    color: 'white'
}
const firstPage = {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
}
const usersListItem = {
    width: '100%',
    textAlign: 'start',
    wordBreak: 'break-word',
}
const seachFieldMargin = {
    margin: 'auto 5px',
}
const medicationButtons = {
    borderRadius: '10px',
    padding: "35px 10px",
    width: "48%",
}
const medicationSelection = {
    display: 'flex',
    justifyContent: 'space-between'
}
const noteItemsButton = {
    margin: '10px 20px',
    width: '-webkit-fill-available',
    padding: '10px',
    boxShadow: '2px 3px #80808052'
}
const hiddenElement = {
    display: 'none'
}
export {
    panelStyle,
    chatPanelStyle,
    mainStyle,
    fabStyle,
    viewMoreStyle,
    progress,
    sectionDetail,
    item,
    sections,
    header,
    headerWithSort,
    tableRow,
    editIcon,
    headerItem,
    buttonCancel,
    buttonSave,
    buttonSavedDisabled,
    buttonDelete,
    modelFooter,
    modelBody,
    modelRow,
    tableColumn,
    circularProgress,
    firstPage,
    usersListItem,
    seachFieldMargin,
    medicationButtons,
    medicationSelection,
    noteItemsButton,
    hiddenElement,
    formRowLastField
}