import React from 'react';
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { TopTitle } from '../../config.js'
import { isMobileOnly } from 'react-device-detect'


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    padd: {
        padding: theme.spacing(2)
    }
})
)
const Footer = (props) => {
    const classes = useStyles()
    const { auth } = props;
    let location = useLocation();
    return (
        <div>
            {/* {(auth.uid) && location.pathname.indexOf('/mobileChat') !== 0? */}
            {(auth.uid) && (!isMobileOnly || (isMobileOnly && location.pathname.indexOf('/chat') !== 0)) ?
                <Typography className={classes.padd} variant="body2" color="textSecondary" align="center">
                    {'Copyright © '}
                    {TopTitle}
                    {' '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography> : ''}
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}
export default connect(mapStateToProps)(Footer)