import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAllSubAccountsList, isSubAccountGreaterThan16 } from './API/API'
import { Grid, Typography } from '@material-ui/core'
import useStyles from './styles/style.js'
import SwitchAccountModal from './SwitchAccountModal'

function SwitchAccount() {
    const classes = useStyles()
    // GET AUTH FROM REDUX STATE
    const authState = useSelector(state => state.auth)
    const subAccountState = useSelector(state => state.subAccount)
    const accountState = useSelector(state => state.account.data)
    const dispatch = useDispatch()

    // SET LOCAL STATE VARIABEL
    const [totalSubAccounts, settotalSubAccounts] = useState(0)
    const [subAccountsList, setSubAccountsList] = useState([])
    const [showModal, setShowModal] = useState(false)

    // METHOD TO GET ALL SUBACCOUNTS LIST AND SET STATE
    const getAllSubAccount = async () => {

        /* FOR TASK RHYN-102
          TASK DESCRIPTION: Add patient tab and subaccounts for age <16
          CHECK IS REDUX SUBACCOUNT STATE HAS KEY subAccountId THEN GET ALL DATA RELATED TO SUBACCOUNT */
        // CALL METHOD THAT RETURNS ALL SUBAACOUNTS DATA
        // let result = await getAllSubAccountsList({ uid: subAccountState.subAccountId ? subAccountState.subAccountId : authState.uid })
        let result = await getAllSubAccountsList({ uid: authState.uid })
        if (result.status) {
            settotalSubAccounts(result.count)
            setSubAccountsList(result.data)
            dispatch(isSubAccountGreaterThan16(result.data))
        }
    }
    useEffect(() => {
        getAllSubAccount()
    }, [])
    return (
        <div>
            {accountState && accountState.role === 'Patient' && totalSubAccounts > 0 ?
                <Grid className={classes.switchAccountDiv}>
                    <h2 className={classes.accountUserName}>Patient Name: {accountState.fullName}</h2>
                    <Grid
                        onClick={() => setShowModal(!showModal)}
                        className={classes.switchAccount}>
                        <Typography
                            style={{
                                fontWeight: 'bold',
                                padding: '10px'
                            }}
                        >Switch Account
                </Typography>
                    </Grid>
                </Grid>
                :
                <></>}
            {showModal ?
                <SwitchAccountModal setShowModal={setShowModal} showModal={showModal} subAccountsList={subAccountsList} activeId={subAccountState.subAccountId ? subAccountState.subAccountId : authState.uid} />
                :
                <></>
            }
        </div >
    )
}

export default SwitchAccount
