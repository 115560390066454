import React, { Component } from 'react'
import { Grid, Button, FormControl, InputAdornment, InputLabel, OutlinedInput, TextField, CircularProgress } from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles"
import functionsList from '../../assets/js/function.js'

const styles = theme => ({
    font: {
        fontFamily: 'Roboto Regular',
    },
    popup: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    popupDiv: {
        backgroundColor: '#fff',
        padding: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: theme.spacing(5),
        [theme.breakpoints.down('sm')]: { padding: theme.spacing(2) }
    },
    popupHeading: {
        fontFamily: 'inherit',
        fontWeight: 700,
        color: '#383636',
        fontSize: '1.2rem'
    },
    popupButton: {
        marginTop: theme.spacing(2),
        fontFamily: 'inherit',
        fontSize: '1rem',
    },
    textTransform: {
        textTransform: 'unset'
    },
    files: {
        margin: theme.spacing(8, 0),
        width: '100%',
    },
    hasReferralSection: {
        margin: theme.spacing(0, 0, 10, 0),
    },
    uploadClass: {
        display: 'none'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    uploadFileDiv: {
        marginBottom: theme.spacing(2)
    },
    yesButton: {
        width: "120px",
        borderRadius: '10px',
        border: 'none',
        color: 'white',
        height: '50px',
        padding: "7px",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: "#1f4037"
        },
    },
    noButton: {
        backgroundColor: 'rgb(240, 242, 245)',
        width: "120px",
        color: theme.palette.lightGrey.main,
        padding: "7px",
        borderRadius: '10px',
        border: 'none',
        height: '50px',
        "&:hover": {
            backgroundColor: "rgb(240, 242, 245)",
        },
    }
})

let AppointmentNumber = ''
class RhynoGoDocuments extends Component {
    constructor(props) {
        super(props)
        this.state = {
            UploadFiles: 1,
            bookUploadFiles: {},
            appointmentData: {},
            loader: false,
            message: '',
            hasReferral: ''
        }
        this._isMounted = false;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true
    }
    nextStep = (step) => {
        this.props.updateStepNo(step, { previousStep: 9, rhynoGoDocuments: Object.values(this.state.bookUploadFiles) })
    }

    onImageChangeHandler = async (e) => {
        let filesArray = this.state.bookUploadFiles
        this.setState({ imageLoader: true })
        var result = await functionsList.saveImage(e, 'Appointment')
        result = JSON.parse(result)
        filesArray[AppointmentNumber] = { url: result.path, name: result.name }
        if (this._isMounted)
            this.setState({ bookUploadFiles: filesArray, imageLoader: false })
        if (this.state.UploadFiles < 5) {
            if (this._isMounted)
                this.setState({ UploadFiles: this.state.UploadFiles + 1 })
        }
        AppointmentNumber = ''

    }
    handleFileSelect = (type, key) => {
        AppointmentNumber = type
        if (this._isMounted)
            this.setState({ imageLoaderkey: key })
        document.getElementById('file').click();
    }

    hasReferral = (type) => {
        this.setState({ hasReferral: type })
    }
    render() {
        let { classes } = this.props
        return (
            <Grid className={classes.font}>
                <Grid className={classes.popup}>
                    <Grid className={classes.popupDiv} style={{ width: '31rem' }}>
                        {this.state.hasReferral === '' ?
                            <>
                                <span className={classes.popupHeading + ' ' + classes.hasReferralSection}>Do you have a referral/requisition from a provider for this service?</span>
                                <Grid className={classes.buttons}>
                                    <Button variant="contained" onClick={() => this.hasReferral(false)} className={classes.noButton}>
                                        No
                                </Button>
                                    {/* <Button variant="contained" onClick={()=>this.hasReferral(true)} color={'secondary'} className={classes.popupButton}> */}
                                    <Button variant="contained" onClick={() => this.nextStep(10)} className={classes.yesButton}>
                                        Yes
                                </Button>
                                </Grid>
                            </>
                            :
                            !this.state.hasReferral ?
                                <>
                                    <span className={classes.popupHeading + ' ' + classes.hasReferralSection}>Please book an appointment with a provider for the RHYNO GO service assessment.</span>
                                    <Grid className={classes.buttons}>
                                        <Button variant="contained" style={{ color: "#fff" }} onClick={() => this.hasReferral('')} color={'primary'} className={classes.popupButton}>
                                            Back
                                </Button>
                                        <Button variant="contained" onClick={() => this.nextStep(1)} color={'secondary'} className={classes.popupButton}>
                                            Continue
                                </Button>
                                    </Grid>
                                </>
                                :
                                <>
                                    <span className={classes.popupHeading}>please upload referral/requisition documents from a provider for this service.</span>
                                    <Grid className={classes.files}>
                                        {
                                            this.state.UploadFiles > 0 ?
                                                Array.from(Array(parseInt(this.state.UploadFiles))).map((val, key) => {
                                                    return (
                                                        <FormControl
                                                            fullWidth
                                                            variant="outlined"
                                                            className={classes.uploadFileDiv}
                                                            key={key}
                                                        >
                                                            <InputLabel htmlFor={"file_" + (key + 1)} className={classes.uploadDoc}>
                                                                {this.state.bookUploadFiles["document" + (key + 1)] ? this.state.bookUploadFiles["document" + (key + 1)]["name"] : 'Documents'}
                                                            </InputLabel>
                                                            <OutlinedInput
                                                                id={"file_" + (key + 1)}
                                                                type="text"
                                                                disabled={true}
                                                                endAdornment={
                                                                    <InputAdornment position="end">
                                                                        <Button
                                                                            type="button"
                                                                            fullWidth
                                                                            variant="contained"
                                                                            color="secondary"
                                                                            onClick={() => this.handleFileSelect('document' + (key + 1), key)}
                                                                        >
                                                                            {
                                                                                this.state.imageLoader && this.state.imageLoaderkey === key ?
                                                                                    <CircularProgress size={21} /> :
                                                                                    'Upload'
                                                                            }
                                                                        </Button>
                                                                    </InputAdornment>
                                                                }
                                                                labelWidth={70}
                                                            />
                                                        </FormControl>

                                                    )
                                                })
                                                : ""
                                        }
                                    </Grid>
                                    <Grid className={classes.buttons}>
                                        <Button variant="contained" style={{ color: "#fff" }} onClick={() => this.nextStep(4)} color={'primary'} className={classes.popupButton}>
                                            Back
                                </Button>
                                        <Button variant="contained" onClick={() => this.nextStep(10)} color={'secondary'} className={classes.popupButton}>
                                            Next
                                </Button>
                                    </Grid>
                                </>
                        }
                    </Grid>
                </Grid>
                <TextField
                    name="file"
                    type="file"
                    id="file"
                    className={classes.uploadClass}
                    onChange={this.onImageChangeHandler}
                />
            </Grid>
        );
    }
}
export default withStyles(styles)(RhynoGoDocuments)