import React from 'react'
import { Grid, Button } from '@material-ui/core'
import ModalStyle from '../../modelStyle'

function RequisitionCreatedModal() {
    const showRequisitions = () => {
        window.location.href = "/requisitions_list"
    }
    return (
        <div>
            <ModalStyle
                className="modal"
                title={
                    <Grid>
                        Requisition Created
                    </Grid>
                }
                closable={false}
                visible={true}
                width={'31rem'}
                zIndex={"2000"}
                footer={
                    <Button variant="contained" onClick={showRequisitions} className={"btnCustomClass lightBlueClasstbn "}>
                        Ok
                    </Button>
                }
            >
                <h4>Requisition Are Created Successfully</h4>
            </ModalStyle>
        </div>
    )
}

export default RequisitionCreatedModal
