import { Modal } from 'antd'
import styled from 'styled-components'


const ModalStyle = styled(Modal)`
.ant-modal-title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
}
.ant-modal-header{
    border-radius: 9px 9px 0 0;
    border-bottom:none;
}
.ant-modal-body{
    text-align: center;
    padding-top: 0;
    // justify-content: center;
}
.ant-modal-content{
    
    border-radius: 9px;
}
.ant-modal-footer{
    text-align:center;
    border-top:none;

}
`

export default ModalStyle