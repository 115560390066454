import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { TextField, CssBaseline, Container, Typography, Paper, Avatar, Button, Box, CircularProgress } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { TopTitle } from '../../config.js'
import Functions from '../../assets/js/function'
import { resendVerificationCode, sendVerificationCode } from '../../store/actions/authActions'
import cookie from 'react-cookies'
import { TIMEEXPIRY } from '../../config.js'
import { SKIP2FEMAILS } from '../../config'



function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {TopTitle}
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const signupStyles = theme => ({
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(5)}px`,
    [theme.breakpoints.down('sm')]: { padding: theme.spacing(2) }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  container: {
    maxWidth: "inherit"
  },
  message: {
    padding: theme.spacing(5, 0)
  },
  signin: {
    marginTop: theme.spacing(2)
  },
  submit: {
    marginTop: theme.spacing(2)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('sm')]: { flexDirection: 'column' }
  },
  lastButton: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: { marginLeft: theme.spacing(0) }
  },
  buttonsDiv: {
    display: 'flex',
    width: 'auto',
    [theme.breakpoints.down('sm')]: { width: '100%', flexDirection: 'column' }
  }
})

class twoFactor extends Component {

  constructor(props) {
    super(props)
    this.state = {
      code: '',
      disable: true,
      error: false,
      loader: false,
      status: false,
      message: '',
      redirectToSignIn: false,
    }
    if (props.account && props.account.data) {
      this.state.userEmail = props.account.data.email
      let isRedirect = cookie.load(props.account.data.email)
      if (isRedirect === 'true' || (props.account.data.hasOwnProperty('email') && (props.account.data.email).indexOf(SKIP2FEMAILS) > 0)) {
        props.verify2F(1, '')
        this.state.redirectToDashboard = true
      }
      else {
        this.state.redirectToDashboard = false
      }
    }
    if (props.auth && props.auth.signedInUserId) {
      this.state.uid = props.auth.signedInUserId
    }
    if (props.auth.hasMobileNumber === false) {
      this.setCodeType('email')
      this.state.codeType = 'email'
    }
    else {
      this.state.codeType = ''
    }
  }
  verify2F = async () => {
    this.setState({ loader: true })
    let result = await Functions.verify2F(JSON.stringify({ userId: this.state.uid, code: this.state.code }))
    this.setState({ status: result.status, message: result.message })
    if (result.status === 1) {
      const expires = new Date()
      expires.setDate(Date.now() + TIMEEXPIRY)
      // cookie.save(uid, true, { path: '/', expires})
      cookie.save(result.data.email, true, { path: '/', expires })
      this.setState({ email: result.data.email })
      this.props.updateProfileData(result.data)
    }
  }
  componentDidMount() {
    this.props.updateState()
    this.props.setTotalCalls(0)
  }
  componentDidUpdate() {
    if (this.state.loader === true && this.props.auth.is2FMessage && this.props.auth.is2FMessage === '') {
      this.setState({ loader: false })
    }
    else {
      setTimeout(() => {
        this.setState({ loader: false })
      }, 2000)
    }
  }

  // NOT NOW ACTION FOR COOKIES
  notNow = async () => {
    cookie.remove(this.state.email, { path: '/' })
    await this.props.verify2F(this.state.status, this.state.message)
  }

  // TRUSTED ACTION FOR COOKIES
  trust = async () => {
    await this.props.verify2F(this.state.status, this.state.message)
  }

  handleChange = (e) => {
    this.setState({ code: e.target.value, error: false, disable: false })
    if (e.target.value === '') {
      this.setState({ error: true, disable: true })
    }
  }

  setCodeType = (type) => {
    this.setState({ codeType: type })
    this.props.sendVerificationCode({ uid: this.state.uid, codeType: type }, () => this.props.verify2F(1, ''))
  }
  // RESEND VERIFICATION CODE
  resendVerificationCode = () => {
    this.props.resendVerificationCode({ uid: this.state.uid, codeType: this.state.codeType }, () => this.props.verify2F(1, ''))
  }
  render() {
    const { classes, auth } = this.props;
    if (!auth.isVerified) {
      return <Redirect to="/verify" />;
    }
    if (auth.is2FValidate === 1 || this.state.redirectToDashboard) {
      return <Redirect to="/dashboard" />;
    }
    if (this.state.redirectToSignIn) {
      return <Redirect to="/signin" />;
    }

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.container}>
          {this.state.codeType === '' ?
            <Paper elevation={1} className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Authenticate
        </Typography>
              {this.state.message ? <h4 className="red-text-center">{this.state.message}</h4> : null}
              {auth.is2FMessage ? <h4 className="red-text-center">{auth.is2FMessage}</h4> : null}
              <Typography className={classes.message} color="textSecondary" component="h1" variant="h5">
                How do you  want to get your security code?
        </Typography>

              <Button
                type="button"
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => this.setCodeType('email')}
                className={classes.submit}
              >
                {this.state.loader ? <CircularProgress size={24} /> : "Email"}
              </Button>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => this.setCodeType('phone')}
                className={classes.submit}
              >
                Text Message
        </Button>
              <Box mt={8}>
                <Copyright />
              </Box>
            </Paper>
            :
            !this.state.status ?
              <Paper elevation={1} className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Authenticate
            </Typography>
                {this.state.message ? <h4 className="red-text-center">{this.state.message}</h4> : null}
                {auth.is2FMessage ? <h4 className="red-text-center">{auth.is2FMessage}</h4> : null}
                <Typography className={classes.message} color="textSecondary" component="h1" variant="h5">
                  We sent a verification code to your {this.state.codeType}. Enter the code from {this.state.codeType} in the field below
            </Typography>

                <TextField
                  className={classes.label}
                  variant="outlined"
                  margin="normal"
                  autoFocus
                  label="Authetication Code"
                  required
                  error={this.state.error}
                  helperText={this.state.error ? "Authentication Code field required" : ""}
                  value={this.state.code}
                  onChange={this.handleChange}
                  onBlur={this.handleChange}
                />


                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  disabled={this.state.disable}
                  onClick={() => this.verify2F()}
                  className={classes.submit}
                >
                  {this.state.loader ? <CircularProgress size={24} /> : "Verify Code"}
                </Button>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={() => this.resendVerificationCode()}
                  className={classes.submit}
                >
                  Resend Code
            </Button>
                <Box mt={8}>
                  <Copyright />
                </Box>
              </Paper>
              :
              <Paper elevation={1} className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Trust this browser?
            </Typography>
                <Typography className={classes.message} color="textSecondary" component="h1" variant="h5">
                  If you choose to trust this browser, you will not be asked for a verification code the next time you sign in
            </Typography>
                <div className={classes.buttons}>
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    disabled={this.state.disable}
                    onClick={() => this.notNow()}
                    className={classes.submit}
                  >
                    {this.state.loader ? <CircularProgress size={24} /> : "TRUST ONCE"}
                  </Button>
                  <div className={classes.buttonsDiv}>
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      disabled={this.state.disable}
                      onClick={() => this.trust()}
                      className={classes.submit + ' ' + classes.lastButton}
                    >
                      {this.state.loader ? <CircularProgress size={24} /> : "TRUST THIS DEVICE"}
                    </Button>
                  </div>
                </div>
                <Box mt={8}>
                  <Copyright />
                </Box>
              </Paper>
          }
        </div>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    // UPDATE STATE ACCORDING TO VERIFIED 2FACTOR 
    verify2F: (is2FValidate, is2FMessage) => dispatch({
      type: "TwoFactor_Code",
      is2FValidate: is2FValidate,
      is2FMessage: is2FMessage
    }),
    // UPDATE PROFILE DATA IN REDUX
    updateProfileData: (data) => dispatch({
      type: 'ACCOUNT_DATA',
      user_data: data
    }),

    // UPDATE STATE TO GO TO SIGN IN PAGE WHEN ANY OTHER PAGE OPEN
    updateState: () => dispatch({
      type: "STATE_UPDATE",
      isValid: false,
      isLoggedIn: false
    }),
    resendVerificationCode: (data) => dispatch(resendVerificationCode(data)),
    sendVerificationCode: (data) => dispatch(sendVerificationCode(data))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(signupStyles)(twoFactor));