import React from 'react'
//MUI
import { Grid, } from '@material-ui/core'
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import withStyles from "@material-ui/core/styles/withStyles"
const styles = theme => ({
    label: {
        color: theme.palette.lightGrey.main,
    }

})


const NotificationInputChoice = (props) => {
    const { classes, notification, changeNotificationType } = props;
    return (
        <div>
            <Grid>
                <label className={classes.label}>Receive Notification Via</label>
                <br></br>
                <ToggleButtonGroup
                    value={notification}
                    exclusive
                    onChange={(e, val) => {
                        changeNotificationType(val)
                    }
                    }
                    aria-label="text alignment"
                >
                    <ToggleButton value="email" aria-label="centered">
                        Email
                    </ToggleButton>
                    <ToggleButton value="phone" aria-label="centered">
                        Phone
                    </ToggleButton>
                </ToggleButtonGroup>
            </Grid>



        </div>
    )
}

export default withStyles(styles)(NotificationInputChoice)
