import React, { Component } from 'react'
import DoctorProfile from './doctorprofile'
import Account from './UserAccount/Account'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import '../../App.css'
class Profile extends Component {
  componentDidMount() {
    if (this.props.hasOwnProperty('account') && this.props.account.hasOwnProperty('data') && this.props.account.data.hasOwnProperty('role') && this.props.account.data.role === 'Doctor')
      this.props.setTotalCalls(5)
    else if (this.props.hasOwnProperty('account') && this.props.account.hasOwnProperty('data') && this.props.account.data.hasOwnProperty('role') && this.props.account.data.role === 'Patient')
      this.props.setTotalCalls(5)
  }
  render() {
    const { auth, account } = this.props;
    if (!auth.uid) return <Redirect to='/signin' />
    if (!auth.isVerified) {
      return <Redirect to="/verify" />;
    }
    return (
      <div>{account.data && (account.data.role === 'Patient' || account.data.role === 'Agency' || account.data.role === 'Pharmacy' || account.data.role === 'Staff') ? <Account /> : <DoctorProfile />}</div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    account: state.account
  };
};
export default connect(mapStateToProps, null)(Profile);