import { createSlice } from '@reduxjs/toolkit';

export const SubAccountsReducer = createSlice({
    name: 'subaccount',
    initialState: {
        subAccountId: null,
        removedSubAccountData: null,
    },
    reducers: {
        update: (state, action) => {
            console.log(action.payload)
            state.subAccountId = action.payload
        },
        removeSubAccount: (state, action) => {
            state.removedSubAccountData = action.payload
        },
    },
});
