const initState = {
    authError: null,
    search_doctors: [],
    NotificationUpdated: false,
    SubscriptionInvited: true
}
const appointmentReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SEARCH_DOCTOR_SUCCESS':
            return {
                ...state,
                authError: '',
                search_doctors: action.data,
                totalRecords: action.totalRecords,
                startIndex: action.startIndex,
                endIndex: action.endIndex,
                appointmentmessage: ''
            }
        case 'SEARCH_DOCTOR_ERROR':
            return {
                ...state,
                authError: action.message,
                search_doctors: [],
                totalRecords: 0,
                startIndex: '',
                endIndex: '',
                appointmentmessage: ''
            }
        case 'GET_AVAILABILITY_TIME_SUCCESS':
            return {
                ...state,
                doctorAvailability: action.data,
                appointmentmessage: ''
            }
        case 'UPDATE_SYMPOTEMS':
            return {
                ...state,
                availableSymptoms: action.symptoms,
                appointmentmessage: ''
            }
        case 'APPOINTMENT_ADDED':
            return {
                ...state,
                appointmentmessage: action.message
            }
        case 'APPOINTMENT_LIST':
            return {
                ...state,
                ['appointment' + action.appointmentType]: action.data,
                message: action.message
            }
        case 'DOCTORS_LIST':
            return {
                ...state,
                DoctorList: action.data
            }
        case 'PROVIDER_LIST':
            return {
                ...state,
                ProviderList: action.data
            }
        case 'NOTIFICATION_STATE_UPDATE':
            return {
                ...state,
                NotificationUpdated: action.value
            }
        case 'SUBSCRIPTION_INVITED':
            return {
                ...state,
                SubscriptionInvited: action.value
            }
        case 'STATUS_UPDATE':
            return {
                ...state
            }
        default:
            return state;
    }
}

export default appointmentReducer
