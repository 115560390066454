import React, { Component } from 'react'
import withStyles from "@material-ui/core/styles/withStyles"
import { Grid, Button, TextField } from '@material-ui/core'

const styles = theme => ({
    popup: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column'
    },
    popupDiv: {
        backgroundColor: '#fff',
        padding: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '35rem',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            width: '100%'
        }
    },
    popupButton: {
        marginBottom: theme.spacing(2),
        fontFamily: 'inherit',
        color: '#ffffff'
    },
    popupHeading: {
        fontFamily: 'inherit',
        fontWeight: 700,
        paddingBottom: theme.spacing(6),
        color: '#795bb2',
        fontSize: theme.spacing(2.4)
    },
    font: {
        fontFamily: 'revert',
    },
    mainDiv: {
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    formButton: {
        display: 'flex',
        justifyContent: 'space-between'
    }
})
class getPatientName extends Component {

    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = {
            isFormValid: true,
            first_name: props.registerData.hasOwnProperty('firstName') ? props.registerData.firstName : '',
            last_name: props.registerData.hasOwnProperty('lastName') ? props.registerData.lastName : ''
        }
    }

    componentDidMount() {
        this._isMounted = true
    }

    updateGuardianType = (type) => {
        this.setState({ guardian_type: type })
    }

    updateFormData = (step) => {
        this.props.updateStepNo(step, { firstName: this.state.first_name.trim(), lastName: this.state.last_name.trim() })
    }
    // FORM DATA UPDATED
    change = (e, text) => {
        if (this._isMounted) {
            if (text === 'first_name') {
                this.setState({ isFormValid: true, first_name: (e.target.value).trimStart() })
            }
            else {
                this.setState({ isFormValid: true, last_name: (e.target.value).trimStart() })
            }
        }
    }
    validateForm = () => {
        if (this.state.first_name !== '' && this.state.last_name !== '')
            this.updateFormData(4)
        else
            this.setState({ isFormValid: false })
    }
    render() {
        let { classes } = this.props

        return (
            <Grid className={classes.font}>
                <Grid className={classes.popup}>
                    <Grid className={classes.popupDiv} >
                        <Grid className={classes.mainDiv}>
                            <span className={classes.popupHeading}>
                                {this.props.registerData.accountType === 'own' ?
                                    "Please enter your first and last name"
                                    :
                                    "Please enter patient's first and last name"
                                }
                            </span>

                            <TextField
                                value={this.state.first_name}
                                onChange={(e) => this.change(e, 'first_name')}
                                onBlur={(e) => this.change(e, 'first_name')}
                                label="First & Middle Name *"
                                style={{ marginBottom: '24px' }}
                                error={this.state.first_name === '' && !this.state.isFormValid ? true : false}
                                helperText={this.state.first_name === '' && !this.state.isFormValid ? "First & Middle Name is required" : ""}
                                autoFocus
                            />

                            <TextField
                                value={this.state.last_name}
                                onChange={(e) => this.change(e, 'last_name')}
                                onBlur={(e) => this.change(e, 'last_name')}
                                label="Last Name *"
                                style={{ marginBottom: '24px' }}
                                error={this.state.last_name === '' && !this.state.isFormValid ? true : false}
                                helperText={this.state.last_name === '' && !this.state.isFormValid ? "Last Name is required" : ""}
                            />

                            <Grid className={classes.formButton}>
                                {/* <Button variant="contained" onClick={() => this.updateFormData(1)} color={'primary'} className={classes.popupButton}>
                                    Back
                                    </Button> */}
                                <p></p>
                                <Button variant="contained" onClick={() => this.validateForm()} color={'secondary'} className={classes.popupButton}>
                                    Next
                                    </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        )
    }
}
export default withStyles(styles)(getPatientName);
