import React, { useState, useEffect } from 'react'
import { Grid, Typography, CircularProgress, Fab } from '@material-ui/core'
import { progress, sectionDetail, tableRow, headerItem, mainStyle, fabStyle } from './style'
import { getAllAllergyHistory, updateEMRState } from './API/API'
import ViewMore from './ViewMore'
import TableRow from './TableRow'
import SectionModal from './SectionModal'
import AddIcon from "@material-ui/icons/Add"
import { Alert } from 'antd'
import { useDispatch } from 'react-redux'

function AllergyHistory({ isRhynoGoPatient, patientName, type, patientId, doctorId }) {

    const [allergyHistory, setAllergyHistory] = useState([])
    const [pagination, setPagination] = useState('')
    const [loader, setLoader] = useState(true)
    const [openModel, setOpenModel] = useState(false)
    const [successMessage, setSuccessMessage] = useState()
    const dispatch = useDispatch()

    const getAllUserAllergyHistory = async () => {
        setLoader(true)
        let result = await getAllAllergyHistory(patientId, doctorId, pagination, isRhynoGoPatient, patientName)
        let allAllergyHistory = allergyHistory.concat(result.data)
        setAllergyHistory(allAllergyHistory)
        setPagination(result.pagination_id)
        setLoader(false)
        dispatch(updateEMRState({ data: allAllergyHistory, type: "EMR_ALLERGIES_LIST" }))
    }

    useEffect(() => {
        getAllUserAllergyHistory()
    }, [])

    const openCloseModel = (e, isSuccess = false, action, data, pagination_id, isChild = false) => {
        if (isSuccess) {
            setSuccessMessage(`Allergy History ${action} Successfully`)
            setAllergyHistory(data)
            dispatch(updateEMRState({ data: data, type: "EMR_ALLERGIES_LIST" }))
            setPagination(pagination_id)
        }
        else {
            setSuccessMessage('')
        }
        if (!isChild) {
            setOpenModel(!openModel)
        }
    }

    const alertClosed = () => {
        setSuccessMessage('')
    }

    return (
        <Grid style={mainStyle}>
            <Grid style={sectionDetail}>
                {successMessage && <Alert type="success" closable={true} onClose={alertClosed} message={successMessage}></Alert>}
                <Grid style={sectionDetail}>
                    <Grid style={tableRow} key={-1}>
                        <Typography style={headerItem}>{'Allergy'}</Typography>
                        <Typography style={headerItem}>{'Reaction'}</Typography>
                        <Typography>{''}</Typography>
                    </Grid>
                    {allergyHistory.map((allergy, key) => <TableRow
                        key={key}
                        type="allergy"
                        row={allergy}
                        data={{ patientId, doctorId, pagination }}
                        onChangeRecord={openCloseModel}
                        id={allergy.id}
                        patientName={patientName}
                        isRhynoGoPatient={isRhynoGoPatient}
                        col1={allergy.allergy}
                        col2={allergy.reaction}
                    />)
                    }
                    {loader && <CircularProgress style={progress} size={24} />}
                    {pagination !== -1 && <ViewMore loader={loader} getMoreRecords={getAllUserAllergyHistory} />}
                </Grid>
                <Fab color="primary" style={fabStyle}>
                    <AddIcon onClick={openCloseModel} />
                </Fab>
                {openModel && <SectionModal patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} type={type} onCloseModel={openCloseModel} data={{ patientId, doctorId, pagination }} />}
            </Grid>
        </Grid>
    )
}

export default AllergyHistory
