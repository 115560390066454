import React, { Component } from 'react'
import withStyles from '@material-ui/styles/withStyles'
import { TextField, Grid } from '@material-ui/core'

const styles = theme => ({
    space: {
        margin: theme.spacing(1, 0) + " !important"
    }
})

class RequisitionForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            form: {
                type: '',
                part: '',
                info: '',
                CCProvider: '',
                CCFax: '',
            },
        }
    }

    onChange = (e) => {
        // GET VALUES ARRAY
        let valuesArray = this.state.form
        // SET VALUE FOR A SPECIFIC FIELD OF FORM
        valuesArray[e.target.name] = e.target.value

        // SET STATE VARIABLES FOR ERROR AND VALUES
        this.setState({ form: valuesArray })
        this.props.updateRequisitionData(valuesArray)
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid>
                <TextField
                    variant="outlined"
                    label="Type Of Scan*"
                    name={"type"}
                    className={classes.space}
                    error={!this.state.form.type && this.props.formSubmitted}
                    helperText={!this.state.form.type && this.props.formSubmitted ? "Type of scan field required" : ""}
                    value={this.state.form.type}
                    onChange={this.onChange}
                    onBlur={this.onChange}
                />
                <TextField
                    variant="outlined"
                    label="Body Part*"
                    name={"part"}
                    className={classes.space}
                    error={!this.state.form.part && this.props.formSubmitted}
                    helperText={!this.state.form.part && this.props.formSubmitted ? "Body part field required" : ""}
                    value={this.state.form.part}
                    onChange={this.onChange}
                    onBlur={this.onChange}
                />
                <TextField
                    multiline
                    rows={4}
                    fullWidth
                    className={classes.space}
                    variant="outlined"
                    label="Clinical Info*"
                    name={"info"}
                    error={!this.state.form.info && this.props.formSubmitted}
                    helperText={!this.state.form.info && this.props.formSubmitted ? "Clinical info field required" : ""}
                    value={this.state.form.info}
                    onChange={this.onChange}
                    onBlur={this.onChange}
                />
                <TextField
                    variant="outlined"
                    label="CC Provider Name"
                    name={"CCProvider"}
                    className={classes.space}
                    value={this.state.form.CCProvider}
                    onChange={this.onChange}
                    onBlur={this.onChange}
                />
                <TextField
                    variant="outlined"
                    label="CC Provider Fax"
                    name={"CCFax"}
                    className={classes.space}
                    value={this.state.form.CCFax}
                    onChange={this.onChange}
                    onBlur={this.onChange}
                />
            </Grid>
        );
    }
}

export default withStyles(styles)(RequisitionForm)