import React, { useState } from 'react'
import { tableColumn, tableRow, editIcon } from './style'
import EditIcon from '@material-ui/icons/Edit'
import { Typography } from '@material-ui/core'
import SectionModal from './SectionModal'

function TableRow({ patientName, isRhynoGoPatient, id, col1, col2, type, row, onChangeRecord, data }) {
    const [openModel, setOpenModel] = useState(false)

    const openCloseModel = (e, isSuccess = false, action, data, pagination_id) => {
        if (isSuccess) {
            onChangeRecord(e, isSuccess, action, data, pagination_id, true)
        }
        else {
            onChangeRecord(e, isSuccess, action, data, pagination_id, true)
        }
        setOpenModel(!openModel)
    }
    return (
        <div style={tableRow}>
            {col1 && <Typography style={tableColumn} > {col1}</Typography>}
            {col2 && <Typography style={tableColumn} > {col2}</Typography>}
            <EditIcon onClick={() => openCloseModel(id)} style={editIcon} />

            {openModel && <SectionModal patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} type={type} id={id} row={row} data={data} onCloseModel={openCloseModel} />}
        </div>
    )
}

export default TableRow
