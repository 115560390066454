import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles"
import { Container, Grid, TextField, Button, CircularProgress } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import { Modal } from 'antd'
import RhynoGo from '../patientSignup/RhynoGo'
import _ from 'lodash'
import { confirmAlert } from 'react-confirm-alert'
import { createSubscription, cancelSubscription, updateSubscriptionMembers } from '../../store/actions/subscriptionActions'
import { getUpdatedUserInfo } from '../../store/actions/authActions'
import styled from 'styled-components'

const Box = styled.div`
width:300px;
height:200px;
background-color:white;
border-radius:20px;
text-align:center;
.title{
  padding-top:20px;
  font-size:25px;
  font-weigth:bold;
  padding-bottom:10px;
}
.back-button{
  padding:10px;
  margin-right: 25%;
  width: 100px;
  cursor:pointer;
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color:rgb(240,242,245);
  color:rgbc(126,141,141);
}

.continue-button{
  padding:10px;
  width: 100px;
  cursor:pointer;
  height: 40px;
  background-color: #00bbbe;
  border-radius: 5px;
  border: none;
  color:white;
}
.description{
  color:rgb(127,142,142);
  padding-bottom: 10px;
}
`


const styles = theme => ({
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: '#fff',
    borderRadius: theme.spacing(.7),
    padding: `${theme.spacing(4)}px ${theme.spacing(10)}px ${theme.spacing(10)}px`,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2)
    }
  },
  actions: {
    [theme.breakpoints.down('sm')]: { flexDirection: 'column' }
  },
  container: {
    maxWidth: '800px'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  alignLeft: {
    textAlign: 'left',
  },
  pages: {
    display: 'flex',
    marginTop: theme.spacing(1)
  },
  pageItem: {
    width: '80px !important',
    marginLeft: '0 !important'
  },
  uploadClass: {
    display: 'none'
  },
  rowDirection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  cursor: {
    cursor: 'pointer'
  },
  changeMembers: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '30px'
  },
  changeMembersSpan: {
    color: '#795bb2',
    fontWeight: 700,
    fontSize: '1.3rem',
    textAlign: 'center'
  },
  changeMembersButton: {
    margin: '1rem 0 0',
    color: '#fff',
    backgroundColor: 'red'
  },
  memberButton: {
    color: '#fff',
    margin: '1rem 0 0',
  },
  membersButton: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  member: {
    padding: '5px 25px',
    backgroundColor: '#80808059',
    fontWeight: 600,
    color: '#000',
    borderRadius: '30px',
    textAlign: 'center',
    position: 'relative'
  },
  membersDiv: {
    width: 'fit-content',
    margin: 'auto'
  },
  button: {
    color: '#fff'
  },
});

class Membership extends Component {
  constructor(props) {
    super(props)
    this.state = {
      uid: props.auth.uid,
      loading: false,
      updateMembers: false,
      showMembersList: false,
      changeSubscription: false,
      listOfSubscribedUsers: [],
      loadComponent: true,
      SubscriptionError: ''
    }
  }

  updateSubscription = async (data) => {
    this.setState({ loading: true })
    data.uid = this.state.uid
    data.email = this.props.account.data.email
    let result = await this.props.createSubscription(JSON.stringify(data))
    if (result.status)
      await this.updateProfileAgain()
    else {
      this.setState({ SubscriptionError: result.message })
      this.setState({ loading: false })
    }
  }
  addMoreEmailAddress = () => {
    let subscriptionMembers = this.state.listOfSubscribedUsers
    subscriptionMembers = subscriptionMembers.filter(val => Boolean(val))
    this.setState({ listOfSubscribedUsers: subscriptionMembers })
    let length = subscriptionMembers.length

    let newLength = length
    if (length < 8) {
      newLength = length + 1
    }
    subscriptionMembers.length = newLength
    subscriptionMembers.fill('', length, newLength)
    this.setState({ listOfSubscribedUsers: subscriptionMembers })
  }
  cancelSubscription = async (type, active_subscription_id, status, deletion_type) => {
    if (status === 0) {
      let subscriptionMembers = this.props.account.data.listOfSubscribedUsers
      subscriptionMembers = subscriptionMembers.filter(val => Boolean(val))
      console.log(subscriptionMembers)
      let length = subscriptionMembers.length
      let newLength = length
      if (length < 8) {
        newLength = length + 1
      }
      subscriptionMembers.length = newLength
      subscriptionMembers.fill('', length, newLength)
      this.setState({ changeSubscription: !this.state.changeSubscription, listOfSubscribedUsers: subscriptionMembers })
    }
    else {
      confirmAlert({
        /* 
        Styled overlay in app.css
        */
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <Box>
                <h1 className='title'>Confirm</h1>
                <p className='description'>Do you wish to cancel this subscription ?</p>
                <button className='back-button'
                  onClick={() => {
                    this.setState({ updateMembers: false, changeSubscription: false });
                    onClose();
                  }}> Back</button>
                <button className='continue-button'
                  onClick={
                    async () => {
                      this.setState({ loading: true, edit_key: deletion_type === 'other' ? deletion_type : 'cancel_subscription' })
                      await this.props.cancelSubscription(JSON.stringify({ type: type, id: this.state.uid, active_subscription_id: active_subscription_id, email: this.props.account.data.email, deletion_type: deletion_type }))
                      await this.updateProfileAgain();
                      //needs to be fixed to load CircularProgress
                      // if (this.state.loading == false) onClose();
                      onClose();
                    }}
                >
                  {this.state.loading ? <CircularProgress style={{
                    color: '#fff !important',
                  }} size={21} /> : "Continue"}
                </button>
              </Box>
            </div >
          );
        }
      })
    }
  }

  changeSubscription = () => {
    this.setState({ changeSubscription: false, showMembersList: false })
  }

  updateMembers = () => {
    this.setState({ updateMembers: !this.state.updateMembers, changeSubscription: false, showMembersList: false, listOfSubscribedUsers: this.props.account.data.listOfSubscribedUsers })
  }

  updateMemberList = (email, action) => {
    let listOfSubscribedUsers = this.state.listOfSubscribedUsers
    listOfSubscribedUsers[action] = email
    this.setState({ listOfSubscribedUsers: listOfSubscribedUsers })
  }

  updateSubscriptionMembers = async (active_subscription_id) => {

    confirmAlert({
      title: 'Confirm',
      message: 'Are you sure you want to update the members?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            let emails = _.cloneDeep(this.state.listOfSubscribedUsers)
            if (emails.indexOf(this.props.account.data.email) === -1)
              emails.push(this.props.account.data.email)

            var filteredEmail = emails.filter(function (el) {
              return el !== '';
            });
            this.setState({ loading: true, edit_key: 'update_subscription_members' })
            await this.props.updateSubscriptionMembers(JSON.stringify({ active_subscription_id: active_subscription_id, emails: filteredEmail, uid: this.state.uid }))
            await this.updateProfileAgain()
          }
        },
        {
          label: 'No',
          onClick: () => {
            this.setState({ updateMembers: false, changeSubscription: false, showMembersList: false })
          }
        }
      ]
    });
  }

  updateProfileAgain = () => {
    setTimeout(async () => {
      let result = await this.props.getUpdatedUserInfo({ userId: this.state.uid })
      this.props.updateProfileData(result)
      this.setState({ updateMembers: false, listOfSubscribedUsers: [], loading: false, edit_key: '', edit_val: '', changeSubscription: false, showMembersList: false, loadComponent: false }, () => {
        this.setState({ loadComponent: true })
      })
    }, 3000)
  }

  removeSubscriptionError = () => {
    this.setState({ SubscriptionError: '' })
  }

  addRemoveMembers = () => {
    this.setState({ showMembersList: !this.state.showMembersList })
  }

  render() {
    const { auth, classes, account } = this.props
    if (!auth.uid) {
      return <Redirect to="/signin" />;
    }
    if (!auth.isVerified) {
      return <Redirect to="/verify" />;
    }
    return (
      <Container className={classes.container} component="main" maxWidth="sm">
        {this.state.loadComponent === true &&
          <RhynoGo updateSubscription={this.updateSubscription} cancelSubscription={this.cancelSubscription} edit_key={this.state.edit_key} loading={this.state.loading} />
        }
        <Modal
          title={""}
          visible={this.state.changeSubscription}
          width={500}
          onCancel={() => this.changeSubscription()}
          footer={null}
        >
          <Grid className={classes.changeMembers}>
            <span className={classes.changeMembersSpan}>What would you like to do?</span>
            {this.state.showMembersList ? <>
            {this.state.listOfSubscribedUsers.map((member, i) => {
              return (<div>
                <TextField
                  value={member}
                  onChange={(e) => { this.updateMemberList(e.target.value, i) }}
                  onBlur={(e) => { this.updateMemberList(e.target.value, i) }}
                  label="Add Email"
                  style={{ marginBottom: '24px' }}
                />
              </div>)
            })}

            {this.state.listOfSubscribedUsers.length < 8 ?
              <Button className={classes.memberButton} variant="contained" color="primary" onClick={this.addMoreEmailAddress} >
                Add More
              </Button>
              : <p></p>
            }

            <Button className={classes.memberButton} variant="contained" color="primary" onClick={() => this.updateSubscriptionMembers(account.data.active_subscription_id)}>
              {this.state.loading && this.state.edit_key === 'update_subscription_members' ? <CircularProgress className={classes.button} size={21} /> : "Confirm"}
            </Button>

              <Button className={classes.memberButton} variant="contained" color="primary" onClick={() => this.addRemoveMembers()}>
                Back
              </Button>
            </>
              :
              <>
                <Button className={classes.memberButton} variant="contained" color="primary" onClick={() => this.addRemoveMembers()}>
                  Add Member
                </Button>

            <Button className={classes.changeMembersButton} variant="contained" color="primary" onClick={() => this.cancelSubscription(account.data.subscriptionType, account.data.active_subscription_id, 1, 'current')}>
              {this.state.loading && this.state.edit_key === 'cancel_subscription' ? <CircularProgress className={classes.button} size={21} /> : "Cancel Subscription"}
            </Button>
              </>
            }
          </Grid>
        </Modal>
        <Modal
          title={""}
          visible={this.state.SubscriptionError !== '' ? true : false}
          width={400}
          onCancel={() => this.removeSubscriptionError()}
          footer={
            <Button variant="contained" color="primary" onClick={() => this.removeSubscriptionError()}>
              Cancel
            </Button>
          }
        >
          <Grid className={classes.changeMembers}>

            <span className={classes.changeMembersSpan}>{this.state.SubscriptionError}</span>

          </Grid>
        </Modal>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createSubscription: (data) => createSubscription(data),
    cancelSubscription: (data) => cancelSubscription(data),
    getUpdatedUserInfo: (data) => getUpdatedUserInfo(data),
    updateSubscriptionMembers: (data) => updateSubscriptionMembers(data),
    updateProfileData: (data) => dispatch(data)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Membership));