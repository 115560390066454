const initState = {
    authError: null
}
const billingReducer = (state = initState, action) => {
    switch (action.type) {
        case 'BILLING_DETAIL_SUCCESS':
            return {
                ...state,
                data: action.data,
                message: action.message
            }
        case 'BILLING_DETAIL_ERROR':
            return {
                ...state,
                data: action.data,
                message: action.message
            }
        default:
            return state;
    }
}
export default billingReducer