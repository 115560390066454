import React, { Component } from 'react'
import withStyles from '@material-ui/styles/withStyles'
import { TextField, Grid, Link } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { getDoctorsListForAppointment } from '../../../../store/actions/appointmentAction'

const styles = theme => ({
    space: {
        margin: theme.spacing(1, 0) + " !important"
    }
})

class RequisitionForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showCustomProvider: false,
            doctorList: [],
            showOptionsList: false,
            form: {
                doctorId: '',
                speciality: '',
                referralinfo: '',
                referralFax: ''
            },
        }
    }

    onChange = (e) => {
        // GET VALUES ARRAY
        let valuesArray = this.state.form
        // SET VALUE FOR A SPECIFIC FIELD OF FORM
        valuesArray[e.target.name] = e.target.value

        // SET STATE VARIABLES FOR ERROR AND VALUES
        this.setState({ form: valuesArray })
        this.props.updateRequisitionData(valuesArray)
    }

    nameChange = (e, val) => {

        // GET VALUES ARRAY
        let valuesArray = this.state.form

        if (val !== null) {
            valuesArray['speciality'] = val.name
            valuesArray['doctorId'] = val.id
        }
        else {
            valuesArray['speciality'] = ''
            valuesArray['doctorId'] = ''
        }

        // SET STATE VARIABLES FOR ERROR AND VALUES
        this.setState({ form: valuesArray, showOptionsList: false })
        this.props.updateRequisitionData(valuesArray)
    }

    nameChanged = async (e, val) => {
        if (val.length > 2 && val !== this.state.form.speciality) {
            let doctorList = await getDoctorsListForAppointment(val)
            this.setState({ showOptionsList: true, doctorList: doctorList })
        }
        else {
            this.setState({ showOptionsList: false, doctorList: [] })
        }
    }

    updateCustomProvider = () => {
        let valuesArray = this.state.form
        valuesArray['speciality'] = ''
        valuesArray['doctorId'] = ''
        this.setState({ showCustomProvider: !this.state.showCustomProvider, form: valuesArray })
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid>
                <Link onClick={() => this.updateCustomProvider()} className={classes.linkClass} variant="body2">
                    {this.state.showCustomProvider ?
                        "Search Rhynocare Doctor"
                        : "Add External Doctor"}
                </Link>
                {!this.state.showCustomProvider ?
                    <Autocomplete
                        id="combo-box-demo"
                        value={this.state.form.speciality}
                        options={this.state.doctorList}
                        getOptionLabel={(option) => option ? option.name : ""}
                        freeSolo
                        fullWidth
                        onChange={this.nameChange}
                        onInputChange={this.nameChanged}
                        open={this.state.showOptionsList}
                        renderInput={(params) => <TextField className={classes.space} {...params} label="Search Doctor's name" variant="outlined" />}
                    />
                    :
                    <TextField
                        multiline
                        fullWidth
                        variant="outlined"
                        className={classes.space}
                        label="Doctor Name"
                        name={"speciality"}
                        value={this.state.form.speciality}
                        onChange={this.onChange}
                        onBlur={this.onChange}
                        error={!this.state.form.speciality && this.props.formSubmitted}
                        helperText={!this.state.form.speciality && this.props.formSubmitted ? "Doctor Name field required" : ""}
                    />
                }
                <TextField
                    multiline
                    rows={10}
                    fullWidth
                    variant="outlined"
                    className={classes.space}
                    label="Referral Note*"
                    name={"referralinfo"}
                    error={!this.state.form.referralinfo && this.props.formSubmitted}
                    helperText={!this.state.form.referralinfo && this.props.formSubmitted ? "Referral Note field required" : ""}
                    value={this.state.form.referralinfo}
                    onChange={this.onChange}
                    onBlur={this.onChange}
                />
                <TextField
                    multiline
                    fullWidth
                    variant="outlined"
                    className={classes.space}
                    label="Fax Number"
                    name={"referralFax"}
                    value={this.state.form.referralFax}
                    onChange={this.onChange}
                    onBlur={this.onChange}
                />
            </Grid>
        );
    }
}

export default withStyles(styles)(RequisitionForm)