import React, { useState, useEffect } from 'react'
import { Grid, CircularProgress, Fab } from '@material-ui/core'
import { progress, sectionDetail, mainStyle, fabStyle } from '../style'
import { getAllChats, updateEMRState } from '../API/API'
import ChatRecord from './ChatRecord'
import UserDetail from './UserDetail'
import ViewMore from '../ViewMore'
import AddIcon from "@material-ui/icons/Add"
import { Alert } from 'antd'
import SectionModal from '../SectionModal'
import { useSelector, useDispatch } from 'react-redux'

function ChatHistory({ isRhynoGoPatient, patientName, type, patientId, doctorId }) {

    const [pagination, setPagination] = useState(0)
    const [loader, setLoader] = useState(true)
    const [openModel, setOpenModel] = useState(false)
    const [successMessage, setSuccessMessage] = useState()
    const chats = useSelector(state => state.emr.ChatsHistoryList)
    const dispatch = useDispatch()

    const getAllUserNotes = async (doctorChanged, pagination_id, searchIds, doctor) => {
        setLoader(true)
        let result = await getAllChats(patientId, doctor, pagination_id, isRhynoGoPatient, patientName, searchIds)
        let data = []
        if (doctorChanged || pagination_id === 0)
            data = result.data
        else
            data = chats.concat(result.data)
        dispatch(updateEMRState({ data: data, type: "EMR_CHAT_LIST" }))
        setPagination(result.pagination_id)
        setLoader(false)
    }

    useEffect(() => {
        getAllUserNotes(false, pagination, [], doctorId)
    }, [])

    const openCloseModel = (e, isSuccess = false, action, data, pagination_id, isChild = false) => {
        if (isSuccess) {
            setSuccessMessage(`Chat ${action} Successfully`)
            dispatch(updateEMRState({ data: data, type: "EMR_CHAT_LIST" }))
            setPagination(pagination_id)
        }
        else {
            setSuccessMessage('')
        }
        if (!isChild) {
            setOpenModel(!openModel)
        }
    }

    const alertClosed = () => {
        setSuccessMessage('')
    }
    return (
        <Grid style={mainStyle}>
            <Grid style={sectionDetail}>
                <UserDetail getAllUserNotes={getAllUserNotes} pagination={pagination} patientId={patientId} openCloseModel={openCloseModel} />
                {successMessage && <Alert type="success" closable={true} onClose={alertClosed} message={successMessage}></Alert>}
                {chats && chats.map((chat, key) =>
                    <ChatRecord key={key} parentKey={key} chat={chat} patientId={patientId} parentDoctorId={doctorId} pagination={pagination} openCloseModel={openCloseModel} isRhynoGoPatient={isRhynoGoPatient} patientName={patientName} />
                )
                }
                {loader && <div style={chatPadding}><CircularProgress style={progress} size={24} /></div>}
                {pagination !== -1 && <div style={chatPadding}><ViewMore loader={loader} getMoreRecords={getAllUserNotes} /></div>}
            </Grid >
            <Fab color="primary" style={{ ...fabStyle, ...chatPadding }}>
                <AddIcon onClick={openCloseModel} />
            </Fab>
            {openModel && <SectionModal patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} type={type} onCloseModel={openCloseModel} data={{ patientId, doctorId, pagination }} />}
        </Grid >
    )
}
const chatPadding = {
    margin: '0 30px',
    textAlign: 'left'
}
export default ChatHistory