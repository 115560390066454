import React from 'react'
import { isMobile } from "react-device-detect"
import SignupSidebar from '../../registration/SignupSidebar'
import SetPasswordForm from './SetPasswordForm'
import { Paper } from '@material-ui/core'
import useStyles from '../styles/style'

function SetPassword() {
    const classes = useStyles();

    return (
        <div>
            {/* FOR TASK RHYN-102
            TASK DESCRIPTION: Add patient tab and subaccounts for age <16
            IF NOT MOBILE THEN SHOW THE LEFT BAR SAME AS MOVILE SIGN IN */}
            {isMobile ?
                ""
                :
                <SignupSidebar />
            }
            {/* FOR TASK RHYN-102
            TASK DESCRIPTION: Add patient tab and subaccounts for age <16
            SHOW SET PASSWORD FORM */}
            <div className={classes.formOuterWrapper + " formOuterWrapper"}>
                <Paper className={classes.paper}>
                    <SetPasswordForm />
                </Paper>
            </div>
        </div>
    )
}

export default SetPassword
