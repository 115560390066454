import React from 'react'
import { Modal } from 'antd'
import { Grid, Button } from '@material-ui/core'

const TodayAppointmentMessage = ({ setShowTodayModel, showTodayModel }) => {
    return (
        <Modal
            visible={true}
            width={400}
            zIndex={2000}
            onCancel={null}
            closable={false}
            footer={
                <Grid>
                    <Button className={"btnCustomClass lightBlueClasstbn"}
                        variant="contained"
                        onClick={() => setShowTodayModel(!showTodayModel)}
                    >Ok</Button>
                </Grid>
            }
        >
            <Grid>
                <h2>For same day changes, please call 4168308181</h2>
            </Grid>

        </Modal>
    )
}

export default TodayAppointmentMessage
