import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Fab, TextField, Button, CircularProgress } from '@material-ui/core'
import withStyles from '@material-ui/styles/withStyles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from "@material-ui/icons/Add"
import { Modal, Alert } from 'antd'
import styled from 'styled-components'
import EditIcon from '@material-ui/icons/Edit'
import { createMedicalHistory, updateMedicalHistory, deleteMedicalHistory, getMedicalHistoryList } from '../../store/actions/EMRAction'
import { DragSource } from "react-dnd";


const StyledModal = styled(Modal)`
.ant-modal-title {
    color: white;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
}
.ant-modal-header{
    background: rgb(0,178,181);
    border-radius: 9px 9px 0 0;
}
.ant-modal-body{
    justify-content: space-between
}
.ant-modal-content{
    border-radius: 9px;
}
.ant-modal-close-x{
    color:white;
}
.ant-modal-footer{
    text-align:center;
}
.modal-body{
    display:flex;
    justify-content:space-between;
}
`;

const styles = theme => ({
    parent: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
        position: 'relative',
        width: '100%',
        '& .MuiPaper-root': {
            borderRadius: '20px',
            // maxHeight:'200px',
            minHeight: '50px',
            // overflow:'scroll'

        },
    },
    panel: {
        padding: 0,
        width: '100%',
        marginBottom: theme.spacing(3)
    },
    modal: {
        display: 'flex',
        flexDirection: 'column'
    },
    error: {
        marginBottom: theme.spacing(2)
    },
    heading: {
        marginLeft: theme.spacing(4)
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'baseline',
        paddingBottom: theme.spacing(5)
    },
    add: {
        color: 'white',
        bottom: '0 !important',
        "&:hover": {
            backgroundColor: theme.palette.primary.darker,
        },
    },
    item: {
        borderBottom: '1px solid #80808036',
        width: '100%',
        textAlign: 'left',
        marginBottom: theme.spacing(1),
        display: 'grid',
        justifyItems: 'baseline',
        gridTemplateColumns: '48% 48% 4%'
    },
    itemHead: {
        fontWeight: 700,
    },
    itemButton: {
        cursor: 'pointer',
        fontSize: '15px',
        '&:hover': {
            color: theme.palette.primary.main
        }
    },
    field: {
        marginBottom: theme.spacing(2),
    },
    buttonCancel: {
        backgroundColor: 'rgb(240, 242, 245)',
        width: "120px",
        color: theme.palette.lightGrey.main,
        padding: "7px",
        borderRadius: '10px',
        border: 'none',
        height: '50px',

    },
    buttonDelete: {
        width: "120px",
        borderRadius: '10px',
        border: 'none',
        height: '50px',
        padding: "7px",
        color: 'white',
        backgroundColor: "#df4759",
        "&:hover": {
            backgroundColor: "#c31432",
        },
    },
    buttonSave: {
        width: "120px",
        borderRadius: '10px',
        border: 'none',
        height: '50px',
        color: 'white',
        padding: "7px",
        backgroundColor: 'rgb(0,178,181)',
        "&:hover": {
            backgroundColor: "rgb(0,178,181)",
            color: "#1f4037"
        },
    }

})

const tile = {
    beginDrag(props, monitor, component) {
        return { type: "medical", history: component.state.medicalHistoryList.data };
    }
};

const collect = (connect) => ({
    dragSource: connect.dragSource()
});


class MedicalHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: true,
            addMedicalHistory: false,
            DiagnoseVal: '',
            DiagnoseYear: '',
            disable: true,
            diagnoseerror: false,
            yearerror: false,
            loader: false,
            id: '',
            userId: props.userId,
            doctorId: props.doctorId,
            medicalHistoryList: { data: [] },
            responseMessage: '',
            pagination_id: 0,
            loadItems: true
        }
    }
    componentDidMount() {
        this.getMedicalHistory()
    }
    componentDidUpdate() {
        if (this.state.loader === true && this.state.responseMessage !== '') {
            this.setState({ errormsg: this.state.responseMessage, loader: false, disable: false })
        }
        else {
            setTimeout(() => {
                if (this.state.loader === true && this.state.responseMessage === '')
                    this.setState({ errormsg: '', addMedicalHistory: false, loader: false, DiagnoseVal: '', DiagnoseYear: '', id: '', disable: true })
            }, 1000)
        }
    }
    expanded = () => {
        this.setState({ expanded: !this.state.expanded })
    }
    addMedicalHistory = () => {
        this.setState({ addMedicalHistory: true })
    }
    getMedicalHistory = async () => {
        let result = await this.props.getMedicalHistoryList(JSON.stringify({ userId: this.state.userId, doctorId: this.state.doctorId, pagination_id: this.state.pagination_id }))

        let previousData = this.state.medicalHistoryList.data
        if (this.state.pagination_id === 0)
            previousData = result.data
        else
            previousData = previousData.concat(result.data)
        this.setState({ loadItems: false, medicalHistoryList: { data: previousData }, responseMessage: result.message, pagination_id: result.pagination_id })
    }
    onSave = async () => {
        this.setState({ loader: true })
        let result = ''
        if (this.state.id === '')
            result = await this.props.createMedicalHistory(JSON.stringify({ year: this.state.DiagnoseYear, diagnose: this.state.DiagnoseVal, userId: this.state.userId, doctorId: this.state.doctorId, pagination_id: this.state.pagination_id }))
        else
            result = await this.props.updateMedicalHistory(JSON.stringify({ year: this.state.DiagnoseYear, diagnose: this.state.DiagnoseVal, userId: this.state.userId, doctorId: this.state.doctorId, id: this.state.id, pagination_id: this.state.pagination_id }))
        let previousData = result.data
        this.setState({ medicalHistoryList: { data: previousData }, responseMessage: result.message, pagination_id: result.pagination_id })
    }
    onDelete = async () => {
        this.setState({ loader: true })
        let result = await this.props.deleteMedicalHistory(JSON.stringify({ id: this.state.id, userId: this.state.userId, doctorId: this.state.doctorId, pagination_id: this.state.pagination_id }))
        let previousData = result.data
        this.setState({ medicalHistoryList: { data: previousData }, responseMessage: result.message, pagination_id: result.pagination_id })
    }
    onUpdate = async (year, id, diagnose) => {
        this.setState({ addMedicalHistory: true, DiagnoseYear: year, id: id, DiagnoseVal: diagnose, disable: false })
    }
    onCancel = () => {
        this.setState({ errormsg: '', addMedicalHistory: false, loader: false, DiagnoseVal: '' })
    }
    handleChange = (e) => {
        let fieldname = e.target.name
        this.setState({ [e.target.name]: e.target.value }, (name = fieldname) => {
            if (this.state.DiagnoseVal !== '' && this.state.DiagnoseYear !== '') {
                this.setState({ disable: false, error: false, diagnoseerror: false, yearerror: false })
            }
            else {
                let column = ''
                let bool = true
                if (name === 'DiagnoseVal' && this.state.DiagnoseVal === '') {
                    column = 'diagnoseerror'
                    bool = true
                }
                else if (name === 'DiagnoseVal') {
                    bool = false
                    column = 'diagnoseerror'
                }

                if (name === 'DiagnoseYear' && this.state.DiagnoseYear === '') {
                    column = 'yearerror'
                    bool = true
                }
                else if (name === 'DiagnoseYear') {
                    bool = false
                    column = 'yearerror'
                }

                this.setState({ [column]: bool, disable: true })

            }
        }
        )
    }
    render() {
        const { dragSource, classes } = this.props;
        return dragSource(
            <div className={classes.parent}>
                <ExpansionPanel
                    expanded={this.state.expanded} onChange={this.expanded} className={classes.panel}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading + " " + classes.itemHead}>Medical History</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.list}>
                        <>
                            {!this.state.loadItems ?
                                this.state.medicalHistoryList.data.length === 0 ?
                                    <p key={0}>No Medical History Found</p> :
                                    <>
                                        <Grid className={classes.item} key={-1}>
                                            <Typography className={classes.itemHead}>{'Past Diagnoses'}</Typography>
                                            <Typography className={classes.itemHead}>{'Year'}</Typography>
                                            <Typography className={classes.itemHead}>{''}</Typography>
                                        </Grid>
                                        {this.state.medicalHistoryList.data.map((medHistory, key) => {
                                            return <Grid className={classes.item} key={key}>
                                                <Typography>{medHistory.diagnose}</Typography>
                                                <Typography>{medHistory.year}</Typography>
                                                <EditIcon onClick={() => this.onUpdate(medHistory.year, medHistory.id, medHistory.diagnose)} className={classes.itemButton} />
                                            </Grid>
                                        })
                                        }
                                        {this.state.pagination_id !== -1 &&
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => this.getMedicalHistory()}
                                                className={classes.submit + " " + classes.searchItem}
                                            >
                                                View More
                                            </Button>
                                        }
                                    </>
                                :
                                <CircularProgress size={24} />
                            }
                        </>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <Fab color="primary" className={classes.add} onClick={this.addMedicalHistory}>
                    <AddIcon />
                </Fab>
                <StyledModal
                    title={"Add Medical History"}
                    className='modal'
                    visible={this.state.addMedicalHistory}
                    width={515}
                    onCancel={this.onCancel}
                    footer={
                        <Grid>
                            {this.state.loader ?
                                <CircularProgress size={24} /> :
                                <Grid>
                                    {this.state.id !== "" ?
                                        <Button style={{ marginRight: '10%' }} className={classes.buttonDelete} variant="contained" onClick={this.onDelete}>Delete</Button> : ""}
                                    {this.state.id !== "" ?
                                        <Button style={{ marginRight: '10%' }} className={classes.buttonCancel} variant="outlined" onClick={this.onCancel}>Cancel</Button>
                                        : <Button style={{ marginRight: '50%' }} className={classes.buttonCancel} variant="outlined" onClick={this.onCancel}>Cancel</Button>
                                    }
                                    <Button disabled={this.state.disable} className={classes.buttonSave} variant="contained" onClick={this.onSave}>Save</Button>
                                </Grid>
                            }
                        </Grid>
                    }
                >
                    <div className='modal-body'>
                        <Grid className={classes.modal}>
                            {this.state.errormsg ?
                                <Grid className={classes.error}>
                                    <Alert type="error" closable={true} message={this.state.errormsg}></Alert>
                                </Grid>
                                : ""}
                        </Grid>
                        <Grid className={classes.field}>
                            <TextField
                                type="text"
                                variant="outlined"
                                margin="normal"
                                autoFocus
                                required
                                label="Add Diagnosis"
                                name="DiagnoseVal"
                                error={this.state.diagnoseerror}
                                helperText={this.state.diagnoseerror ? "Diagnosis Value field required" : ""}
                                value={this.state.DiagnoseVal}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        <Grid>
                            <TextField
                                type="text"
                                variant="outlined"
                                margin="normal"
                                required
                                style={{ paddingLeft: '10px' }}
                                name="DiagnoseYear"
                                label="Diagnosis Year"
                                error={this.state.yearerror}
                                helperText={this.state.yearerror ? "Diagnosis Year field required" : ""}
                                value={this.state.DiagnoseYear}
                                onChange={this.handleChange}
                            />
                        </Grid>

                    </div>


                </StyledModal>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        createMedicalHistory: (data) => createMedicalHistory(data),
        updateMedicalHistory: (data) => updateMedicalHistory(data),
        deleteMedicalHistory: (data) => deleteMedicalHistory(data),
        getMedicalHistoryList: (data) => getMedicalHistoryList(data),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DragSource("history", tile, collect)(MedicalHistory)))