import React, { useState } from 'react'
import { Modal } from 'antd'
import { Button, Grid, CircularProgress } from "@material-ui/core";
import Functions from '../../assets/js/function'

function ChangeAppointmentStatusModel({ appointmentId, changeStatus, getAppointmentsList }) {
    const [loader, setLoader] = useState(false)
    const [status, setStatus] = useState('')
    const updateStatus = async (status) => {
        setStatus(status)
        setLoader(true)
        // UPDATE APPOINTMENT STATUS
        let result = await Functions.providerUpdateAppointmentStatus({ appointmentId, status })
        if (result.status) {
            // UPDATE APPOINTMENT LIST IN SCHEDULAR
            getAppointmentsList()
            // CLOSE CHANGE STATUS MODEL
            changeStatus('', false)
        }
    }
    return (
        <Modal
            title={'Change Appointment Status'}
            visible={true}
            width={400}
            closable={true}
            onCancel={() => changeStatus('', false)}
            footer={null}
        >
            <Grid style={style.parent}>
                {/* SHOW CANCEL AND COMPLETE BUTTONS FOR APPOINTMENT */}
                <Button disabled={loader} style={style.button} className={'btnCustomClass lightGreenClasstbn'} onClick={() => updateStatus('completed')} variant={"contained"} >{loader && status === 'completed' ? <CircularProgress size={24} /> : "Complete Appointment"}</Button>
                <Button disabled={loader} style={style.button} className={'btnCustomClass lightRedClasstbn'} onClick={() => updateStatus('cancelled')} variant={"contained"} >{loader && status === 'cancelled' ? <CircularProgress color={"red"} size={24} /> : "Cancel Appointment"}</Button>
            </Grid>
        </Modal>
    )
}

const style = {
    parent: {
        display: 'flex',
        flexDirection: 'column'
    },
    button: {
        margin: '10px 0'
    }
}

export default ChangeAppointmentStatusModel
