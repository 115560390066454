import React, { useState } from 'react'
import { Alert } from 'antd'
import { Grid, TextField } from '@material-ui/core';
import ModelFooter from './ModelFooter'
import { modelBody, modelRow, formRowLastField } from './style'
import { createUpdateAllergyHistoryItem, deleteAllergyHistoryItem } from './API/API'
import AutoCompleteComponent from './AutoCompleteComponent'
import { Link } from 'react-router-dom';

function AllergyForm({ patientName, isRhynoGoPatient, row, id, onCloseModel, data }) {
    const [allergy, setAllergy] = useState(row && row.allergy ? row.allergy : '')
    const [reaction, setReaction] = useState(row && row.reaction ? row.reaction : '')
    const [isCustom, setIsCustom] = useState(false)

    const [message, setMessage] = useState('')
    const [loader, setLoader] = useState(false)
    const [loaderType, setLoaderType] = useState('')
    const [formSubmitted, setFormSubmitted] = useState(false)

    const saved = async () => {
        setFormSubmitted(true)

        if (reaction && allergy) {
            setLoader(true)
            setLoaderType('save')
            let result = ''
            if (id)
                result = await createUpdateAllergyHistoryItem({ reaction: reaction, allergy: allergy, userId: data.patientId, doctorId: data.doctorId, pagination_id: data.pagination, id: id, patientName: patientName, isRhynoGoPatient: isRhynoGoPatient })
            else
                result = await createUpdateAllergyHistoryItem({ reaction: reaction, allergy: allergy, userId: data.patientId, doctorId: data.doctorId, pagination_id: data.pagination, patientName: patientName, isRhynoGoPatient: isRhynoGoPatient })

            setLoader(false)
            if (result.message)
                setMessage(result.message)
            else
                onCloseModel('', true, 'Saved', result.data, result.pagination_id)
        }
    }
    const deleted = async () => {
        setLoader(true)
        setLoaderType('delete')
        let result = await deleteAllergyHistoryItem({ userId: data.patientId, doctorId: data.doctorId, pagination_id: data.pagination, id: id, patientName: patientName, isRhynoGoPatient: isRhynoGoPatient })
        setLoader(false)
        if (result.message)
            setMessage(result.message)
        else
            onCloseModel('', true, 'Deleted', result.data, result.pagination_id)
    }

    return (
        <>
            <div className='modal-body' style={modelBody}>
                <Grid>
                    {message ?
                        <Grid >
                            <Alert type="error" closable={true} message={message}></Alert>
                        </Grid>
                        : ""}
                </Grid>
                <Grid style={modelRow}>
                    <Link href="#" onClick={() => setIsCustom(!isCustom)}>{isCustom ? "Search Medication" : "Add Custom Medication"}</Link>
                </Grid>
                <Grid style={modelRow}>
                    <AutoCompleteComponent isCustom={isCustom} type={"Allergy"} formSubmitted={formSubmitted} value={allergy} setValue={setAllergy} />
                    <TextField
                        type="text"
                        variant="outlined"
                        margin="normal"
                        style={formRowLastField}
                        label="Comment"
                        value={reaction}
                        onChange={(e) => setReaction(e.target.value)}
                        error={formSubmitted && !reaction}
                        helperText={formSubmitted && !reaction && "Reaction Field Required"}
                    />
                </Grid>
            </div >
            <ModelFooter saveClicked={saved} onCloseModel={onCloseModel} deleteClicked={deleted} loader={loader} id={id} loaderType={loaderType} />
        </>
    )
}

export default AllergyForm
