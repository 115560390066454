import React, { Component } from 'react'
import { connect } from 'react-redux'
import withStyles from "@material-ui/core/styles/withStyles"
import { updateBillingItems } from '../../store/actions/BillingAction'
import { Redirect } from 'react-router-dom'
import { Table, TableBody, TableHead, TableRow, TableCell, Select, MenuItem, Grid, Button, CircularProgress, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'
import Functions from '../../assets/js/function'


const styles = theme => ({
    fontWeight: {
        fontWeight: 700,
    },
    pointer: {
        cursor: 'pointer'
    },
    autocomplete: {
        width: '50%',
        margin: theme.spacing(0, 1, 2)
    },
    autocomplete1: {
        width: '100%',
        margin: theme.spacing(0, 1, 2)
    },
    alert: {
        marginBottom: theme.spacing(3)
    },
    table: {
        marginBottom: theme.spacing(2)
    },
    rating: {
        fontWeight: 700
    },
    selector: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    export: {
        float: 'right',
        backgroundColor: '#795bb2',
        padding: theme.spacing(1, 2),
        color: '#fff !important',
        borderRadius: theme.spacing(.5)
    },
    addCustomFee: {
        width: '100%',
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
    },
    addCustomFeeFields: {
        margin: theme.spacing(.7, 1) + " !important",
        width: '96% !important'
    }
});

class AddBilling extends Component {
    constructor(props) {
        super(props)
        this.state = {
            appointmentId: this.props.appointmentId,
            showData: false,
            isRequiredCodes: false,
            showBillingNumber: false,
            billingCodes: { data: [] },
            diagnosticCodes: { data: [] },
            feeArray: [],
            diagnostic: { code: '', description: '' },
            showCustomFee: false,
            dateOfAdmission: null,
            customFeeDisabled: true,
            customFeeCode: '',
            customFeeCodeDescription: '',
            customFeeCodeAmount: '',
            billingData: {},
            appointmentData: {},
            newRecord: '',
            billingAmount: 0,
            disabled: true,
            totalCADAmount: '',
            loading: false
        };
    }
    // FORM DATA UPDATED
    change = (e) => {
        this.setState({ dateOfAdmission: Math.floor(new Date(e).getTime() / 1000) })
    }
    addCustomFee = () => {
        this.setState({ showCustomFee: true })
    }
    async componentDidMount() {
        let result = this.props.getAppointmentDetailResult
        if (result.status) {
            if (result.data.isRequiredCodes)
                this.setState({ showData: true, appointmentData: result.data.appointment, billingData: result.data.billing, showBillingNumber: result.data.showBillingNumber, isRequiredCodes: result.data.isRequiredCodes, billingCodes: { data: result.data.codes.billingCodes }, diagnosticCodes: { data: result.data.codes.diagnosticCodes } })
            else
                this.setState({ billingData: result.data.billing, appointmentData: result.data.appointment, isRequiredCodes: result.data.isRequiredCodes, showData: true })

            if (result.data.billing.hasOwnProperty('doctorId')) {
                let billingObject = result.data.billing
                let feeAmount = billingObject.feeAmount.split(',')
                let feeDescriptions = billingObject.feeDescriptions.split(',')
                let feeCodes = billingObject.feeCode.split(',')
                let feeUnits = billingObject.feeUnits.split(',')
                let feeTypes = billingObject.feeTypes.split(',')
                let feeArrayData = []
                for (let i = 0; i < feeAmount.length; i++) {
                    feeArrayData.push({ code: feeCodes[i], description: feeDescriptions[i], price: feeAmount[i], type: feeTypes[i], unit: feeUnits[i] })
                }
                this.setState({
                    newRecord: false, diagnostic: billingObject.diagnosticCode, dateOfAdmission: billingObject.dateOfAdmission,
                    billingNumber: billingObject.billingNumber, feeArray: feeArrayData, totalCADAmount: billingObject.totalAmount
                })
                let resultNew = await this.props.updateBillingItems(JSON.stringify({ items: feeArrayData }))
                this.setState({ feeArray: resultNew.data }, () => this.calculateTotalBill())
            }
            else {
                this.setState({ newRecord: true }, () => {
                    if (result.data.appointment.skipBilling) {
                        this.saveBilling(false)
                    }
                })

            }
        }
    }
    saveBilling = async (saveBillingStatus = true) => {
        this.setState({ loading: true })
        let feeCodes = [], feeUnits = [], feeTypes = [], feeDescriptions = [], feeAmount = []
        for (let i = 0; i < this.state.feeArray.length; i++) {
            feeCodes.push(this.state.feeArray[i].code)
            feeUnits.push(this.state.feeArray[i].unit)
            feeDescriptions.push(this.state.feeArray[i].description)
            feeAmount.push(this.state.feeArray[i].price)
            if (this.state.feeArray[i].type === '' || this.state.feeArray[i].type === undefined)
                feeTypes.push('')
            else
                feeTypes.push(this.state.feeArray[i].type)
        }
        if (!this.state.isRequiredCodes) feeCodes = ['Cash Pay']
        let object = { saveBilling: saveBillingStatus, dateOfAdmission: this.state.dateOfAdmission, doctorId: this.state.appointmentData.doctorId, billingNumber: this.state.billingNumber, patientId: this.state.appointmentData.patientId, appointmentId: this.state.appointmentData.appointmentId, totalAmount: String(this.state.billingAmount), feeCode: feeCodes.join(', '), feeTypes: feeTypes.join(', '), feeDescriptions: feeDescriptions.join(', '), feeAmount: feeAmount.join(', '), feeUnits: feeUnits.join(', '), diagnosticCode: this.state.diagnostic, cardType: this.state.appointmentData.cardType }
        if (!this.state.newRecord)
            object.id = this.state.billingData.id

        if (this.state.newRecord) {
            await Functions.saveDoctorBilling(JSON.stringify(object))
            this.setState({ loading: false })
            this.props.billingSaved(saveBillingStatus)
        }
        else {
            await Functions.updateDoctorBilling(JSON.stringify(object))
            this.setState({ loading: false })
            this.props.billingSaved()
        }
    }
    cancelbilling = () => {
        this.props.cancelbilling()
    }
    feeChange = async (e, val) => {
        if (val === null) {
            val = []
        }
        let result = await this.props.updateBillingItems(JSON.stringify({ items: val }))
        this.setState({ feeArray: result.data }, () => this.calculateTotalBill())
    }
    changeUnit = async (e, key) => {
        let val = this.state.feeArray
        val[key].unit = e.target.value
        let result = await this.props.updateBillingItems(JSON.stringify({ items: val }))
        this.setState({ feeArray: result.data }, () => this.calculateTotalBill())
    }
    customFeeChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, this.checkCustomFeeDisable)
    }
    checkCustomFeeDisable = () => {
        if (this.state.customFeeCode !== '' && this.state.customFeeCodeAmount !== '' && this.state.customFeeCodeDescription !== '') {
            this.setState({ customFeeDisabled: false })
        }
        else {
            this.setState({ customFeeDisabled: true })
        }
    }
    addCustomFeeInArray = () => {
        let val = this.state.feeArray
        val.push({ code: this.state.customFeeCode, description: this.state.customFeeCodeDescription, price: this.state.customFeeCodeAmount, type: "", unit: 1 })
        if (val !== null) {
            this.setState({ feeArray: val }, () => this.calculateTotalBill())
        }
        this.setState({ showCustomFee: false, customFeeDisabled: true, customFeeCode: '', customFeeCodeDescription: '', customFeeCodeAmount: '' })
    }

    calculateTotalBill = () => {
        let val = this.state.feeArray
        let amount = 0
        for (let i = 0; i < val.length; i++)
            amount += parseFloat(val[i].price)
        this.setState({ billingAmount: parseFloat(amount).toFixed(2) }, () => this.checkDisable())
    }
    diagnosticChange = (e, val) => {
        if (val !== null) {
            this.setState({ diagnostic: val }, () => this.checkDisable())
        }
        else {
            this.setState({ diagnostic: { code: '', description: '', fee: undefined, type: undefined } }, () => this.checkDisable())
        }
    }
    billingNumber = (e) => {
        this.setState({ billingNumber: e.target.value }, () => this.checkDisable())
    }
    checkDisable = () => {
        if (this.state.diagnostic.code === '' || this.state.feeArray.length === 0 || (this.state.showBillingNumber === true && this.state.billingNumber === ''))
            this.setState({ disabled: true })
        else
            this.setState({ disabled: false })
    }
    totalCADAmount = (e) => {
        if (e.target.value !== '') {
            this.setState({ disabled: false, billingAmount: e.target.value, totalCADAmount: e.target.value })
        }
        else {
            this.setState({ disabled: true, billingAmount: e.target.value, totalCADAmount: e.target.value })
        }
    }
    render() {
        const { classes, auth } = this.props;
        if (!auth.uid) {
            return <Redirect to="/signin" />;
        }
        if (!auth.isVerified) {
            return <Redirect to="/verify" />;
        }

        return (
            <div>
                {!this.state.showData || this.state.appointmentData.skipBilling ? <CircularProgress size={24} /> :
                    this.state.isRequiredCodes ?
                        <div>
                            {this.state.feeArray.length > 0 ?
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Fee Code</TableCell>
                                            <TableCell>Amount</TableCell>
                                            <TableCell>Units</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.feeArray.map((feeItem, key) => {
                                            return (<TableRow key={key}>
                                                <TableCell>{feeItem.code}</TableCell>
                                                <TableCell>{feeItem.price}</TableCell>
                                                {feeItem.type === 'unit' ?
                                                    <TableCell>
                                                        <Select
                                                            className={classes.label}
                                                            onChange={(e) => this.changeUnit(e, key)}
                                                            value={feeItem.unit}
                                                            fullWidth
                                                        >
                                                            <MenuItem value="1">1</MenuItem>
                                                            <MenuItem value="2">2</MenuItem>
                                                            <MenuItem value="3">3</MenuItem>
                                                            <MenuItem value="4">4</MenuItem>
                                                            <MenuItem value="5">5</MenuItem>
                                                        </Select>
                                                    </TableCell>
                                                    :
                                                    <TableCell>1</TableCell>
                                                }
                                            </TableRow>)
                                        }
                                        )
                                        }
                                    </TableBody>
                                </Table>
                                : ""
                            }
                            <Grid className={classes.addCustomFee}>
                                <Button className={classes.addCustomFeeFields} color="secondary" variant="contained" onClick={this.addCustomFee}>Add Custom Fee</Button>
                                <Grid style={{ display: this.state.showCustomFee === true ? 'block' : 'none' }}>
                                    <TextField
                                        className={classes.addCustomFeeFields}
                                        type="text"
                                        variant="outlined"
                                        margin="normal"
                                        autoFocus
                                        required
                                        label="Fee Code"
                                        name="customFeeCode"
                                        value={this.state.customFeeCode}
                                        onChange={this.customFeeChange}
                                    />
                                    <TextField
                                        className={classes.addCustomFeeFields}
                                        type="text"
                                        variant="outlined"
                                        margin="normal"
                                        autoFocus
                                        required
                                        name="customFeeCodeDescription"
                                        label="Fee Code Description"
                                        value={this.state.customFeeCodeDescription}
                                        onChange={this.customFeeChange}
                                    />
                                    <TextField
                                        className={classes.addCustomFeeFields}
                                        type="text"
                                        variant="outlined"
                                        margin="normal"
                                        autoFocus
                                        required
                                        label="Fee Code Amount"
                                        name="customFeeCodeAmount"
                                        value={this.state.customFeeCodeAmount}
                                        onChange={this.customFeeChange}
                                    />
                                    <Button className={classes.addCustomFeeFields} disabled={this.state.customFeeDisabled} fullWidth color="secondary" variant="contained" onClick={this.addCustomFeeInArray}>Add</Button>
                                </Grid>
                            </Grid>

                            <Grid className={classes.selector}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    backfill="true"
                                    value={this.state.feeArray}
                                    options={this.state.billingCodes.data}
                                    className={classes.autocomplete}
                                    getOptionLabel={(option) => option.code}
                                    multiple={true}
                                    freeSolo
                                    onChange={this.feeChange}
                                    renderInput={(params) => <TextField {...params} label="Search Fee Code" variant="outlined" />}
                                />
                                <Autocomplete
                                    id="combo-box-demo1"
                                    backfill="true"
                                    multiple={true}
                                    value={this.state.feeArray}
                                    options={this.state.billingCodes.data}
                                    className={classes.autocomplete}
                                    getOptionLabel={(option) => option.description}
                                    freeSolo
                                    onChange={this.feeChange}
                                    renderInput={(params) => <TextField {...params} label="Search Fee Description" variant="outlined" />}
                                />
                            </Grid>

                            <Grid className={classes.selector}>
                                <Autocomplete
                                    id="combo-box-demo2"
                                    backfill="true"
                                    value={this.state.diagnostic}
                                    options={this.state.diagnosticCodes.data}
                                    className={classes.autocomplete1}
                                    getOptionLabel={(option) => option.code}
                                    freeSolo
                                    onChange={this.diagnosticChange}
                                    renderInput={(params) => <TextField {...params} label="Search Diagnostic Code" variant="outlined" />}
                                />
                            </Grid>
                            {this.state.showBillingNumber ?
                                <Grid className={classes.selector}>
                                    <TextField
                                        className={classes.addCustomFeeFields}
                                        type="text"
                                        variant="outlined"
                                        margin="normal"
                                        label="Referring doctor billing number"
                                        name="billingNumber"
                                        error={this.state.billingError}
                                        helperText={this.state.billingError ? "Billing Number required" : ""}
                                        value={this.state.billingNumber}
                                        onChange={this.billingNumber}
                                        onBlur={this.billingNumber}
                                    />
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            variant="inline"
                                            label="Date of admission(optional)"
                                            format="MM/dd/yyyy"
                                            placeholder={"Date of admission(optional)"}
                                            onChange={(e) => this.change(e)}
                                            value={this.state.dateOfAdmission ? moment(this.state.dateOfAdmission * 1000).format('YYYY/MM/DD') : null}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                :
                                <Grid className={classes.selector}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            variant="inline"
                                            label="Date of admission(optional)"
                                            format="MM/dd/yyyy"
                                            placeholder={"Date of admission(optional)"}
                                            onChange={(e) => this.change(e)}
                                            value={this.state.dateOfAdmission ? moment(this.state.dateOfAdmission * 1000).format('YYYY/MM/DD') : null}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            }
                            <p>Billing Amount: ${this.state.billingAmount}</p>
                        </div> :
                        // this.state.appointmentData.hasStripeToken ?
                        <Grid className={classes.selector}>
                            <TextField
                                className={classes.addCustomFeeFields}
                                type="text"
                                variant="outlined"
                                margin="normal"
                                label="Amount (CAD)"
                                name="totalCADAmount"
                                value={this.state.totalCADAmount}
                                onChange={this.totalCADAmount}
                                onBlur={this.totalCADAmount}
                            />
                        </Grid>
                    // :
                    // <>
                    //     <CircularProgress size={24} />
                    // </>
                }

                {this.state.newRecord === true || this.state.newRecord === false ?
                    <div style={{ textAlign: 'end' }}>
                        <Button color={'secondary'} disabled={this.state.disabled} onClick={() => this.saveBilling(true)} variant="contained">{this.state.loading ? <CircularProgress size={24} /> : this.state.newRecord ? "Save" : "Update"}</Button>
                        {this.state.newRecord === false ? <Button onClick={this.cancelbilling} style={{ marginLeft: '10px' }} variant={"outlined"}>Cancel</Button> : ""}
                    </div>
                    : ""}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        account: state.account
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateBillingItems: (data) => updateBillingItems(data),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddBilling));