import React, { useState } from 'react'
//Dates
import momentSetDate from 'moment'
import DateFnsUtils from '@date-io/date-fns'
//MUI
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import withStyles from "@material-ui/core/styles/withStyles"
const styles = theme => ({
    label: {
        color: theme.palette.lightGrey.main,
    },
    expiryInput: {
        '& .MuiInputBase-root': {
            color: theme.palette.lightGrey.main,
        },
        "& .Mui-focused": {
            color: 'black'
        },
        '& .MuiButtonBase-root': {
            position: 'relative',
            left: '1rem'
        }
    }

})
const ExpireCardInput = (props) => {
    const { classes, dateOfHCExp, changeDateOfHCExp } = props
    const [error, setError] = useState(false)

    const handleChangeDate = (e) => {
        console.log(e);
        if (e === '' || momentSetDate(e).format('YYYY-MM-DD') === 'Invalid date') {
            setError(true)
        }
        else {
            setError(false)
            changeDateOfHCExp(momentSetDate(e).format('YYYY-MM-DD'))
        }
    }
    return (
        <div>
            {/* <label className={classes.label}>Date of birth</label> */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    // style={{ display: this.state.edit_key !== 'dateofBirth' ? 'none' : 'flex' }}
                    // className={classes.label + " editable_field"}
                    autoOk
                    // variant="inline"
                    // margin="normal"
                    // autoFocus
                    required
                    className={classes.expiryInput}
                    error={error}
                    label="Expiry date"
                    helperText={error && dateOfHCExp === '' ? "Expiry date required" : error && dateOfHCExp !== '' ? "Invalid Format" : ""}
                    value={dateOfHCExp !== '' ? momentSetDate(dateOfHCExp).format('YYYY/MM/DD') : null}
                    onChange={(e) => handleChangeDate(e)}
                    // format="MM/dd/yyyy"
                    placeholder={"dd/mm/yyyy"}
                    format="dd/MM/yyyy"
                // InputLabelProps={{
                //   shrink: true,
                // }}
                />
            </MuiPickersUtilsProvider>
        </div>
    )
}

export default withStyles(styles)(ExpireCardInput)
