import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getAppointments, } from '../../../store/actions/appointmentAction'
import { Redirect } from 'react-router-dom'
//child components
import UpcomingAppointments from './UpcomingAppointments'
import ChangedRequests from './ChangedRequests'
//mobile components
import UpcomingAppointmentsMobile from './Mobile/UpcomingAppointmentsMobile'
import ChangedRequestsMobile from './Mobile/ChangedRequestsMobile'
//MUI
import { withStyles } from '@material-ui/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
//utils
import { updateAppointmentsList, removeAppointmentsWithChangedStatus } from './utils'
import SwitchAccount from '../../subAccounts/SwitchAccount'

const styles = (theme) => ({
    tableTitles: {
        textAlign: 'center'
    }
})

function AllAppointments(props) {
    const [data, setData] = useState();
    const { classes, auth } = props;
    const [width, setWidth] = useState(window.innerWidth);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getAppointmentsListFromAPI = () => {
        /* SKYPE CONVERSATION
        G-Man, 10:33 AM
        Please disable and perhaps put a note there as well - 'Cancel/Change option will be available in a few minutes'
        AUTO LOAD APPOINTMENT AFTER 5 SECONDS IF THERE ARE ANY APPOINTMENT CREATION IS IN PROGRESS */
        let hasAnyUpdatingAppointment = null

        /* FOR TASK RHYN-102
        TASK DESCRIPTION: Add patient tab and subaccounts for age <16
        CHECK IS REDUX SUBACCOUNT STATE HAS KEY subAccountId THEN GET ALL DATA RELATED TO SUBACCOUNT */
        updateAppointmentsList(props.subAccount.subAccountId ? props.subAccount.subAccountId : props.auth.uid, props.getAppointments, props.account.data.zip)
            // eslint-disable-next-line no-loop-func
            .then(data => {
                hasAnyUpdatingAppointment = data.hasAnyUpdatingAppointment
                console.log(data)
                setData(data)
                if (hasAnyUpdatingAppointment) {
                    setTimeout(() => {
                        getAppointmentsListFromAPI()
                    }, 5000)
                }
            })
    }
    // console.log(props) 
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        if (props.account && props.account.data && auth.uid) {
            //getAppointments
            getAppointmentsListFromAPI()
        }
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [auth.uid, props.account])

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth)
    };

    const isMobile = width <= 500;


    if (!auth.uid) {
        return <Redirect to="/signin" />;
    }
    else {
        if (isMobile) {
            return (
                <div>
                    {/* SKYPE CONVERSATION
                    G-Man, 9:43 AM
                    Let us just keep it on:
                    - Right side of logo on desktop
                    - Top of all pages on Account, appointments, book now, Dashboard in mobile.
                    SHOW SWITCH ACCOUNT BUTTON IN MOBILE VIEW */}
                    <SwitchAccount />
                    {data === undefined ?
                        <CircularProgress size={24} />
                        :
                        <div>
                            <h2 className={classes.tableTitles}>Upcoming appointments</h2>
                            <UpcomingAppointmentsMobile parentProps={props} changeData={setData} dataTable={removeAppointmentsWithChangedStatus(data.upcommingRequests)} />
                            <br></br>
                            {/* <h2 className={classes.tableTitles}>Updated appointments</h2> */}
                            <ChangedRequestsMobile parentProps={props} changeData={setData} dataTable={data.changedRequests} />
                        </div>
                    }
                </div>
            )
        }
        else {
            return (
                <div>
                    {/* <h1>Appointments</h1> */}
                    {data === undefined ?
                        <CircularProgress size={24} />
                        :
                        <div>
                            <h2 className={classes.tableTitles}>Upcoming appointments</h2>
                            <UpcomingAppointments parentProps={props} changeData={setData} dataTable={removeAppointmentsWithChangedStatus(data.upcommingRequests)} />
                            <br></br>
                            {/* <h2 className={classes.tableTitles}>Updated appointments</h2> */}
                            <ChangedRequests parentProps={props} changeData={setData} dataTable={data.changedRequests} />
                        </div>
                    }
                </div>
            )
        }
    }
}
const mapStateToProps = (state) => {
    /* FOR TASK RHYN-102
    TASK DESCRIPTION: Add patient tab and subaccounts for age <16
    GET THE SUBACCOUNT STATE TO GET DATA ACCORDING TO SUBACCOUNT IS SUBACCOUNT DATA EXIST IN REDUX */
    return {
        auth: state.auth,
        account: state.account,
        subAccount: state.subAccount
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getAppointments: (data) => getAppointments(data),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AllAppointments))