import React, { Component } from 'react'
import { Grid, Button, Avatar, TextField, CircularProgress } from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles"
import HealthCardImg from "../../assets/images/healthcards-3.png"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'
import { verifyHC } from '../../store/actions/appointmentAction'
import { connect } from 'react-redux'
import { Modal } from 'antd'
import { updateProfile } from '../../store/actions/authActions'



const styles = theme => ({
    font: {
        fontFamily: 'Roboto Regular',
    },
    popup: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    popupDiv: {
        backgroundColor: '#fff',
        padding: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: { padding: theme.spacing(2) }
    },
    popupHeading: {
        fontFamily: 'inherit',
        fontWeight: 700,
        color: '#292929',
        fontSize: '1.2rem',
    },
    textTransform: {
        textTransform: 'unset'
    },
    reasonList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: { marginBottom: theme.spacing(4) }
    },
    avatar: {
        width: '100%',
        height: '100%',
        marginTop: theme.spacing(3),
    },
    popupButton: {
        marginTop: theme.spacing(5),
        fontFamily: 'inherit',
        fontSize: '1rem',
        color: "#fff",
        padding: '0px 50px',
        [theme.breakpoints.down('sm')]: { marginTop: theme.spacing(2) }
    },
    reasonDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.down('sm')]: { flexDirection: 'column' }
    },
    modelText: {
        fontFamily: 'inherit',
        fontWeight: 600,
        color: '#3b3c3c',
        fontSize: '1rem'
    }
})


class HealthCard extends Component {
    constructor(props) {
        super(props)
        this._isMounted = false;

        this.state = {
            getHC: false,
            healthCard: props.userHealthCard ? props.userHealthCard : "",
            HCExpiryDate: props.userHCExp ? Math.floor(new Date(props.userHCExp).getTime() / 1000) : '',
            isValidHC: false,
            isFormValid: true,
            showModel: false,
            loading: false
        }

        // if ((props.appointmentData.doctorState !== props.appointmentData.patientState || props.appointmentData.referralOption === 4 || props.appointmentData.doctorId === '' || !props.appointmentData.showDr)) {
        if (true) {
            this.props.updateStepNo(6, { previousStep: props.appointmentData.previousStep })
            // this.props.updateStepNo(6, { healthCard: '', HCExpiryDate: '', isValidHC: props.appointmentData.doctorId === '' ? true : false, previousStep: props.appointmentData.previousStep })
        }
    }
    nextStep = async (step) => {
        // USER HAS NO CARD OR USER HAS CARD AND DATA ENTERED
        if (!this.state.getHC || (this.state.getHC && this.state.isFormValid))
            this.props.updateStepNo(step, { healthCard: this.state.healthCard, HCExpiryDate: this.state.HCExpiryDate, isValidHC: this.state.isValidHC, previousStep: 5 })
    }
    // SHOW HIDE HEALTH CARD INFO SECTION
    getHC = () => {
        if (this._isMounted)
            this.setState({ getHC: !this.state.getHC })
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true
    }
    // FORM DATA UPDATED
    change = (e, text) => {
        if (this._isMounted) {
            if (text === 'date') {
                this.setState({ isValidHC: false, HCExpiryDate: Math.floor(new Date(e).getTime() / 1000) })
            }
            else {
                this.setState({ isValidHC: false, healthCard: e.target.value })
            }
        }
    }
    // VERIFY FORM
    verifyForm = () => {
        if (this.state.healthCard === '' || this.state.HCExpiryDate === '' || isNaN(this.state.HCExpiryDate)) {
            if (this._isMounted)
                this.setState({ isFormValid: false })
        }
        else {
            if (this._isMounted)
                this.setState({ isFormValid: true }, () => this.validateCard())
        }
    }
    // VALIDATE CARD
    validateCard = async () => {
        this.setState({ loading: true })
        let updateResult = await this.props.updateProfile(this.props.uid, [
            { hCard: this.state.healthCard, dateofHCExp: this.state.HCExpiryDate, hCard_added: true }
        ])
        this.props.updateProfileData(updateResult)

        let result = await this.props.verifyHC(JSON.stringify({ card: this.state.healthCard, expiryDate: this.state.HCExpiryDate, state: this.props.appointmentData.patientState }))
        this.setState({ loading: false })
        if (result.status === 1) {

            if (this._isMounted)
                this.setState({ isValidHC: true }, () => this.nextStep(6))
        }
        else {
            if (this._isMounted)
                this.setState({ showModel: true })
        }
    }

    onCancel = () => {
        if (this._isMounted)
            this.setState({ showModel: false })
    }
    render() {
        let { classes } = this.props
        return (
            <Grid className={classes.font}>
                <Grid className={classes.popup}>
                    <Grid className={classes.popupDiv} style={{ width: '35rem' }}>

                        {!this.state.getHC ?
                            <Grid>
                                <span className={classes.popupHeading}>Do you have a valid health card?</span>
                                <Grid className={classes.reasonList}>
                                    <div><Avatar variant="square" alt="Something Else" src={HealthCardImg} className={classes.avatar}></Avatar></div>
                                </Grid>
                                <Grid className={classes.buttons}>
                                    <Button variant="contained" onClick={() => this.nextStep(6)} color={'primary'} className={classes.popupButton}>
                                        No
                                    </Button>
                                    <Button variant="contained" onClick={this.getHC} color={'secondary'} className={classes.popupButton}>
                                        Yes
                                    </Button>
                                </Grid>
                            </Grid>
                            :
                            <Grid className={classes.reasonDiv}>
                                <span className={classes.popupHeading}>Please enter and check your health card information</span>
                                <TextField
                                    value={this.state.healthCard}
                                    onChange={(e) => this.change(e, '')}
                                    onBlur={(e) => this.change(e, '')}
                                    label="Health Card No"
                                    variant="outlined"
                                    style={{ marginTop: '24px', marginBottom: '16px' }}
                                    required={true}
                                    error={this.state.healthCard === '' && !this.state.isFormValid ? true : false}
                                    autoFocus
                                />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        autoOk
                                        variant="inline"
                                        label="Date of HC Exp"
                                        format="dd/MM/yyyy"
                                        placeholder={"Date of HC Exp"}
                                        onChange={(e) => this.change(e, 'date')}
                                        value={this.state.HCExpiryDate ? moment(this.state.HCExpiryDate * 1000).format('YYYY/MM/DD') : null}
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={(this.state.HCExpiryDate === '' || isNaN(this.state.HCExpiryDate)) && !this.state.isFormValid ? true : false}
                                        required
                                    />
                                </MuiPickersUtilsProvider>
                                <Grid className={classes.buttons}>
                                    <Button variant="contained" onClick={this.getHC} color={'primary'} className={classes.popupButton}>
                                        Back
                                    </Button>
                                    <Button variant="contained" onClick={this.verifyForm} color={'secondary'} className={classes.popupButton}>
                                        {this.state.loading ? <CircularProgress size={24} /> : "Continue"}
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                {this.state.showModel ?
                    <Modal
                        title={"Health Card"}
                        visible={true}
                        width={500}
                        onCancel={this.onCancel}
                        footer={
                            <Grid>
                                <Button color="primary" variant="contained" style={{ color: "#fff" }} onClick={this.onCancel}>RECHECK</Button>
                                <Button color="secondary" variant="contained" onClick={() => { this.nextStep(6) }}>PROCEED WITHOUT COVERAGE</Button>
                            </Grid>
                        }
                    >
                        <Grid className={classes.modal}>
                            <span className={classes.modelText}>The health card doesn't appear to be valid. Please check your heath card information. You may proceed to continue without provincial health coverage</span>
                        </Grid>
                    </Modal>
                    :
                    ""
                }
            </Grid>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userHealthCard: state.account.data.hCard,
        userHCExp: state.account.data.dateofHCExp,
        uid: state.auth.uid
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        verifyHC: (data) => verifyHC(data),
        updateProfile: (uid, data) => updateProfile(uid, data),
        updateProfileData: (data) => dispatch(data)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(HealthCard))