import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import UserDashboard from './userDashboard'
import DoctorDashboard from './doctorDashboard'

export function Dashboard(props) {

  const [callPropsMethod, setCallPropsMethod] = useState(true)
  const { authState } = useSelector(
    (state) => ({ authState: state.auth })
  );

  const { accountState } = useSelector(
    (state) => ({ accountState: state.account })
  );
  const { isNotificationUpdate } = useSelector(
    (state) => ({ isNotificationUpdate: state.appointment.NotificationUpdated })
  );

  useEffect(() => {
    if (authState) {
      if (!authState.uid) {
        window.location.href = "/signin"
      }
      if (!authState.isVerified) {
        window.location.href = "/verify"
      }
      if (accountState.data && accountState.data.role === 'Doctor' && accountState.data.RhynoGo) {
        window.location.href = "/emr"
      }
      if (accountState.data && accountState.data.role === 'Agency') {
        window.location.href = "/appointment"
      }
      if (accountState.data && accountState.data.role === 'Pharmacy') {
        window.location.href = "/requisition"
      }
    }
  }, [authState, accountState]);

  useEffect(() => {

    if (accountState.hasOwnProperty('data') && accountState.data.hasOwnProperty("role") && callPropsMethod) {
      setCallPropsMethod(false)
      if (accountState.data.role === 'Doctor') {
        props.setTotalCalls(1)
      }
      if (accountState.data.role === 'Patient') {
        props.setTotalCalls(7)
      }

    }

  }, [accountState, callPropsMethod, props])

  return (
    <div>
      {authState.uid ?
        accountState.data && accountState.data.role === 'Doctor' && !accountState.data.RhynoGo
          ?
          <DoctorDashboard isNotificationUpdate={isNotificationUpdate} />
          :
          accountState.data && accountState.data.role === 'Patient' ?
            <UserDashboard isNotificationUpdate={isNotificationUpdate} />
            :
            ""
        :
        ""
      }
    </div>
  );

}