import React, { useState } from 'react'
import RequisitionForm from './RequisitionForm'
import { CreateNote } from '../../../../store/actions/EMRAction'

function Uploads({ onCloseModel, isRhynoGoPatient, patientName, data }) {
    const [loader, setLoader] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false)

    const saveRequisition = async (uploadRequisitionData) => {
        setLoader(true)
        setFormSubmitted(true)

        uploadRequisitionData.userId = data.patientId
        uploadRequisitionData.doctorId = data.doctorId
        uploadRequisitionData.pagination_id = data.pagination
        uploadRequisitionData.appointmentId = data.hasOwnProperty('appointmentId') ? data.appointmentId : ""
        uploadRequisitionData.isRhynoGoPatient = isRhynoGoPatient
        uploadRequisitionData.patientName = patientName

        // CREATE NOTE
        let response = await CreateNote(JSON.stringify(uploadRequisitionData))
        setLoader(false)
        onCloseModel(true, response.data, response.pagination_id)
    }

    return (
        <RequisitionForm saveRequisition={saveRequisition} loader={loader} formSubmitted={formSubmitted} />
    )
}
export default Uploads
