const initState = {
    authError: null,
    isUpdate: 0,
    isLoggedIn: false,
    isValid: false,
    hasMobileNumber: false
}
const authReducer = (state = initState, action) => {
    let signedInUserId = '', uid = ''
    switch (action.type) {
        case 'LOGIN_ERROR':
            return {
                ...state,
                authError: action.message
            }
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                authError: null,
                uid: '',
                signedInUserId: action.uid,
                isLoggedIn: true,
                isVerified: action.isVerified,
                verificationType: action.verificationType,
                is2FValidate: false,
                is2FMessage: '',
                hasMobileNumber: action.hasMobileNumber
            }
        case 'STATE_UPDATE':
            return {
                ...state,
                isLoggedIn: action.isLoggedIn,
                isValid: action.isValid,
                authError: '',
            }
        case 'SIGNUP_SUCCESS':
            return {
                ...state,
                authError: action.type,
                uid: '',
                signedInUserId: action.uid,
                isVerified: action.isVerified,
                verificationType: action.verificationType,
                hasMobileNumber: action.hasMobileNumber
            }
        case 'EMAIL_VERIFIED':
            if (action.status === true) {
                uid = state.signedInUserId
                signedInUserId = ''
            }
            else {
                uid = ''
                signedInUserId = state.signedInUserId
            }
            let verificationType = ''
            if (action.hasOwnProperty('verificationType'))
                verificationType = action.verificationType
            return {
                ...state,
                isVerified: action.status,
                is2FValidate: 1,
                is2FMessage: '',
                uid: uid,
                verificationType: verificationType,
                signedInUserId: signedInUserId
            }
        case 'TwoFactor_Code':
            if (action.is2FValidate === 1) {
                uid = state.signedInUserId
                signedInUserId = ''
            }
            else {
                uid = ''
                signedInUserId = state.signedInUserId
            }
            return {
                ...state,
                is2FValidate: action.is2FValidate,
                is2FMessage: action.is2FMessage,
                uid: uid,
                signedInUserId: signedInUserId
            }
        case 'SIGNUP_ERROR':
            return {
                ...state,
                authError: action.message
            }
        case 'FORGET_PASSWORD_SUCCESS':
            return {
                ...state,
                ForgetError: action.message
            }
        case 'FORGET_PASSWORD_ERROR':
            return {
                ...state,
                ForgetError: action.message
            }
        case 'RESEND_PASSWORD_SUCCESS':
            return {
                ...state,
                Resndmessage: action.message
            }
        case 'RESEND_PASSWORD_ERROR':
            return {
                ...state,
                Resndmessage: action.message
            }
        case 'RESEND_CODE':
            return {
                ...state,
                is2FMessage: action.message
            }
        case 'SIGN_OUT':
            return {
                ...state,
                uid: '',
                signedInUserId: '',
                authError: ''
            }
        case 'RESET_STATE':
            return initState
        default:
            return state;
    }
}

export default authReducer