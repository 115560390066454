import React, { Component } from 'react'
import { Grid, Button, Typography, Box } from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles"
import { Modal } from 'antd'
import styled from 'styled-components'

const SickNotesModal = styled(Modal)`
.ant-modal-title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    display:flex;

}
.ant-modal-header{
    border-bottom:none;
    border-radius: 1rem 1rem 0 0;
}
.ant-modal-body{
    text-align: center;
    padding:0;
    margin-bottom: 2rem;
}
.ant-modal-content{
    border-radius: 1rem;
}
.ant-modal-footer{
    text-align:center;
    border-top:none;

}
`
const styles = theme => ({
    font: {
        fontFamily: 'Roboto Regular',
    },
    popup: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    popupDiv: {
        backgroundColor: '#fff',
        padding: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: { padding: theme.spacing(2) },
        zIndex: 2000,

    },
    popupHeading: {
        fontFamily: 'inherit',
        fontWeight: 700,
        color: '#383636',
        fontSize: '1.2rem'
    },
    popupButton: {
        marginTop: theme.spacing(2),
        fontFamily: 'inherit',
        fontSize: '1rem',
    },
    textTransform: {
        textTransform: 'unset'
    },
    info: {
        display: 'flex',
        margin: theme.spacing(2, 0),
        fontSize: '1.1rem',
        fontWeight: 500
    },
    boxContainer: {
        gap: '1rem',
        flexWrap: 'wrap',
        display: 'flex',
        justifyContent: 'center',
    },
    pinkBox: {
        width: '15rem',
        height: '8rem',
        backgroundColor: 'rgb(255 221 160)',
        '&:hover': {
            cursor: 'pointer',
            background: 'rgb(255 198 97)',
        },
        justifyContent: 'center',
        border: 'none',
        borderRadius: '1.25rem'


    },
    purpleBox: {
        width: '15rem',
        height: '8rem',
        backgroundColor: 'rgb(255,234,234)',
        '&:hover': {
            cursor: 'pointer',
            background: 'rgb(251 174 174)',
        },
        justifyContent: 'center',
        border: 'none',
        borderRadius: '1.25rem'
    },
    yellowBox: {
        width: '15rem',
        height: '8rem',
        backgroundColor: 'rgb(239,232,254)',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgb(209 191 249)',
        },
        justifyContent: 'center',
        border: 'none',
        borderRadius: '1.25rem'
    },
    boxText: {
        fontSize: '1.5rem',
        fontWeigth: '500',
        paddingTop: '10%',
    },
    backButton: {
        textTransform: 'none',
        color: theme.palette.lightGrey.main,
        backgroundColor: 'rgb(240, 242, 245)',
        width: "120px",
        padding: "7px",
        borderRadius: '1rem',
        border: 'none',
        height: '50px',
    },
    subHeader: {
        width: '27rem',
        margin: 'auto',
        color: theme.palette.lightGrey.main,
        fontSize: '.9rem'
    },
    headerGrid: {
        width: '22rem',
        margin: 'auto',
        marginTop: '5%'
    }
})
class SickNote extends Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = {
            modal: true
        }
        //FOR TASK RHYN-105
       //Please remove notes and forms to be filled screens for all virtual appointments. We will charge patients manually for these.
       //if ((props.appointmentData.doctorId === '')) {
       //     this.props.updateStepNo(8, { noteOption: 1 })
       // }       
       this.props.updateStepNo(8, { noteOption: 1, previousStep: props.appointmentData.previousStep })
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true
    }
    nextStep = (step, noteOption) => {
        this.props.updateStepNo(step, { noteOption, previousStep: 7 })
    }
    showModal = () => {
        if (this._isMounted)
            this.setState({ showModal: true })
    }
    onCancel = () => {
        if (this._isMounted)
            this.setState({ showModal: false })
    }
    render() {
        let { classes } = this.props
        return (
            <SickNotesModal
                visible={this.state.modal}
                className="modal"
                closable={false}
                zIndex={2000}
                width={800}
                title={
                    <Grid className={classes.headerGrid}>
                        <span className={classes.popupHeading}>
                            Do you need a Sick Note or a letter from {this.props.appointmentData.showDr && "Dr."}
                            <span style={{ textTransform: "Capitalize" }}>
                                {this.props.appointmentData.doctorName}
                            </span>
                            {!this.props.appointmentData.showDr && ", NP"}?
                    </span>
                    </Grid>
                }
                footer={
                    <>
                        <Button
                            onClick={() => {
                                this.setState({ modal: false })
                                this.nextStep(this.props.appointmentData.previousStep, 0)
                            }
                            }
                            className={classes.backButton}
                        >
                            Back
                </Button>
                    </>
                }
            >
                <p className={classes.info}>
                    <Typography className={classes.subHeader}>
                        A sick note or letter from a provider has an additional CAD $20 fee that is not covered by provincial insurance
                </Typography>
                </p>
                <Box className={classes.boxContainer}>
                    <div
                        onClick={() => {
                            this.setState({ modal: false })
                            this.nextStep(8, 1)
                        }

                        }
                        className={classes.pinkBox}>
                        <Typography className={classes.boxText}>
                            I do not need a sick note or a letter
                    </Typography>
                    </div>
                    <div
                        onClick={() => {
                            this.setState({ modal: false })
                            this.nextStep(8, 2)
                        }
                        }
                        className={classes.purpleBox}>
                        <Typography
                            className={classes.boxText}
                        >
                            I need a sick note for work
                    </Typography>
                    </div>
                    <div
                        onClick={() => {
                            this.setState({ modal: false })
                            this.nextStep(8, 3)
                        }
                        }
                        className={classes.yellowBox}>
                        <Typography className={classes.boxText}>
                            I need a letter from the provider
                    </Typography>
                    </div>
                </Box>
            </SickNotesModal>


        );
    }
}
export default withStyles(styles)(SickNote)