import React, { useState } from 'react'
import { Button, FormControl, InputLabel, Select, MenuItem, Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'

function AkForTraveling({ updateDestination }) {

    const [chooseTraveling, setChooseTraveling] = useState(false)
    const [country, setCountry] = useState('')
    const [isFormSubmitted, setisFormSubmitted] = useState(false)
    const countriesList = useSelector(state => state.general.countries)

    const countrySelected = () => {
        setisFormSubmitted(true)
        if(country !== '' ) {
        let ms127Countries = ['AG', 'BB', 'JP', 'SX', 'CW']
        if(ms127Countries.indexOf(country.code) > -1){
            updateDestination(country.name, 'Nasopharyngeal')
        }
        else{
            updateDestination(country.name, 'Anterior Nasal')
        }
    }
    }

    return (
        <div style={{width:'20rem',margin:'auto'}}>
            {!chooseTraveling ? <>
                <h2>Is this test for travel purposes?</h2>
                <Grid style={styles}>
                    <Button variant="contained" onClick={() => updateDestination('Non-travel', 'Nasopharyngeal')} className={"btnCustomClass lightBlueClasstbn "}>
                        No
                    </Button>
                    <Button variant="contained" onClick={() => setChooseTraveling(true)} className={"btnCustomClass lightBlueClasstbn "}>
                        Yes
                    </Button>
                </Grid>
            </>
                :
                <>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="country">Select Country</InputLabel>
                        <Select
                            labelId="country"
                            id="country"
                            name="country"
                            label="Country"
                            variant="outlined"
                            fullWidth
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            style={{textAlign:'left'}}
                            error={country === '' && isFormSubmitted ? true : false}
                        >
                            {countriesList.map((item, key) => {
                                console.log(item)
                                return <MenuItem key={key} value={item}>{item.name}</MenuItem>
                            })
                            }
                        </Select>
                        {country === '' && isFormSubmitted && (<p className="MuiFormHelperText-root Mui-error textLeft" style={textLeft}>{"Country is required"}</p>)}
                    </FormControl>
                    <Grid style={styles}>
                        <Button variant="contained" onClick={() => setChooseTraveling(false)} className={"btnCustomClass lightBlueClasstbn "}>
                            Cancel
                    </Button>
                        <Button variant="contained" onClick={countrySelected} className={"btnCustomClass lightBlueClasstbn "}>
                            Next
                    </Button>
                    </Grid>
                </>
            }
        </div>
    )
}

const styles = {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop:'30px',
}
const textLeft = {
    textAlign: 'left'
}

export default AkForTraveling
