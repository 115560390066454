import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Button } from '@material-ui/core'
import withStyles from '@material-ui/styles/withStyles'
import MedHistory from './MedHistory.js'
import PatientMedications from './PatientMedications.js'
import SocialHistory from './SocialHistory.js'
import Allergies from './Allergies.js'
import AppointmentList from './appointmentList.js'
import { getUserDashboardData } from '../../store/actions/EMRAction'
import { getCountries } from '../../store/actions/authActions'
import { isMobileOnly } from "react-device-detect"
import Alert from '@material-ui/lab/Alert';
import AppointmentDocs from './appointmentDocs'
import PatientNotes from './PatientNotes.js'
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend"
import { Modal } from 'antd'
import AlertImg from '../../assets//images/Illustration.svg'
import { getUserInfo } from '../../store/actions/EMRAction'
import { getUpdatedUserInfo } from '../../store/actions/authActions'
import SwitchAccount from '../subAccounts/SwitchAccount'

const styles = theme => ({
  parent: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    }
  },
  child: {
    justifyContent: 'start',
    height: "fit-Content",
    borderRadius: theme.spacing(.2),
    margin: theme.spacing(0, 1)
  },
  space: {
    margin: theme.spacing(2.5)
  },
  child1: {
    [theme.breakpoints.down('sm')]: {
      order: 2
    }
  },
  child2: {
    [theme.breakpoints.down('sm')]: {
      order: 1,
    }
  },
  alert: {
    // backgroundColor: 'rgb(0,178,181)',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '0.5rem',
    color: 'white',
    textAlign: 'left',
    marginBottom: theme.spacing(2)
  },
  changeMembersSpan: {
    color: '#795bb2',
    fontWeight: 700,
    fontSize: '1.2rem',
    textAlign: 'center',
    display: 'flex'
  },
  alertBackgroundImg: {
    position: 'relative',
    bottom: '64px',
    left: '120px',
    height: '150px'
  },
  alertBody: {
    display: 'flex',
  },
  alertText: {
    fontSize: '1vw'

  },
  alertBodyImg: {
    width: '131px',
    position: 'relative',
    top: '36px',
    left: '14px'
  }


})
class userDashboard extends Component {


  constructor(props) {
    super(props)
    /* FOR TASK RHYN-102
      TASK DESCRIPTION: Add patient tab and subaccounts for age <16
      CHECK IS REDUX SUBACCOUNT STATE HAS KEY subAccountId THEN GET ALL DATA RELATED TO SUBACCOUNT */
    this.state = {
      loadAppointmentList: true,
      showInviteSubscription: props.account.noOfSubscriptions,
      userId: props.subAccount.subAccountId ? props.subAccount.subAccountId : props.auth.uid
    }
    props.NotificationStateUpdate()
  }
  // GET ALL DATA FOR PATIENT DASHBOARD
  async componentDidMount() {
    if (this.props.countries) {
      let countriesList = this.props.countries
      let updateCountryNeeds = countriesList.filter(country => country.code === 'CW')
      if (updateCountryNeeds.length === 0)
        this.props.updateCountriesList()
    }

    let typeArray = {
      'notes': 'EMR_NOTES_LIST',
      'medical': 'EMR_MEDICAL_HISTORY_LIST',
      'social': 'EMR_SOCIAL_HISTORY_LIST',
      'medication': 'EMR_MEDICATION_LIST',
      'appointmentsDocs': 'EMR_APPOINTMENTDOCS_LIST',
      'allergies': 'EMR_ALLERGIES_LIST',
    }
    /* FOR TASK RHYN-102
    TASK DESCRIPTION: Add patient tab and subaccounts for age <16
    UPDATE THE PROFILE AND EMR INFO FOR MAIN USER OR SWITCHED SUBACCOUNT */
    this.props.getUserInfo({ userId: this.state.userId })
    let profileResult = await this.props.getUpdatedUserInfo({ userId: this.state.userId })
    this.props.updateProfileData(profileResult)

    let result = await getUserDashboardData({ userId: this.state.userId })
    for (let [key, val] of Object.entries(result)) {
      val = JSON.parse(val)
      if (val.status === 1) {
        this.props.getUserDashboardData({
          type: typeArray[key],
          data: val.data,
          message: ''
        })
      }
      else {
        this.props.getUserDashboardData({
          type: typeArray[key],
          message: val.message,
          data: ''
        })
      }
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.isNotificationUpdate) {
      this.props.NotificationStateUpdate()
      // UPDATE APPOINTMENT LIST
      this.setState({ loadAppointmentList: false }, () => { this.setState({ loadAppointmentList: true }) })

    }
  }
  render() {
    const { classes, auth, name, account } = this.props;
    return (
      <div>
        <DndProvider backend={HTML5Backend}>
          {/* UPDATE ORDER OF DIVS */}
          {isMobileOnly ?
            <Grid className={classes.parent + " emr"}>
              {/* SKYPE CONVERSATION
                G-Man, 9:43 AM
                Let us just keep it on:
                - Right side of logo on desktop
                - Top of all pages on Account, appointments, book now, Dashboard in mobile.
                SHOW SWITCH ACCOUNT BUTTON IN MOBILE VIEW */}
              <SwitchAccount />
              <Alert variant="filled" className={classes.alert}>HELLO {name}, TO BOOK AN AT HOME COVID-19 TEST, CLICK ON 'BOOK NOW'.
                        PLEASE FILL OUT YOUR MEDICAL HISTORY BELOW TO THE BEST OF YOUR KNOWLEDGE PRIOR TO
                        BOOKING WITH A PROVIDER FOR AN ONLINE CONSULTATION.</Alert>
              <Grid className={classes.child + ' ' + classes.child2} xs={12} sm={6}>
                {this.state.loadAppointmentList ? <AppointmentList userId={this.state.userId} /> : ""}
                <div className={classes.space}></div>
              </Grid>
              <Grid className={classes.child + ' ' + classes.child1} xs={12} sm={6}>
                <Allergies onRef={ref => this.callAllergies = ref} userId={this.state.userId} />
                <MedHistory onRef={ref => this.callMedical = ref} userId={this.state.userId} />
                <PatientMedications onRef={ref => this.callMedications = ref} userId={this.state.userId} />
              </Grid>
              <Grid className={classes.child + ' ' + classes.child1} xs={12} sm={6}>
                {/* <DocumentsHistory /> */}
                <PatientNotes userId={this.state.userId} parentPage={'EMR'} />
                <AppointmentDocs userId={this.state.userId} user={'patient'} />
                <SocialHistory onRef={ref => this.callSocial = ref} userId={this.state.userId} />
              </Grid>
            </Grid>
            :
            <Grid className={classes.parent + " emr"}>
              <Grid className={classes.child} xs={12} sm={6}>
                <Alert
                  style={{
                    height: '10rem'
                  }}
                  variant="filled"
                  className={classes.alert}>
                  <Grid className={classes.alertBody}>
                    <Grid className={classes.alertText}>
                      HELLO {name}, TO BOOK AN AT HOME COVID-19 TEST, CLICK ON 'BOOK NOW'.
                        PLEASE FILL OUT YOUR MEDICAL HISTORY BELOW TO THE BEST OF YOUR KNOWLEDGE PRIOR TO
                        BOOKING WITH A PROVIDER FOR AN ONLINE CONSULTATION.
                    </Grid>
                    <Grid>
                      <img className={classes.alertBodyImg} src={AlertImg} alt={""} />
                    </Grid>
                  </Grid>
                </Alert>
                <Allergies onRef={ref => this.callAllergies = ref} userId={this.state.userId} />
                <SocialHistory onRef={ref => this.callSocial = ref} userId={this.state.userId} />
                <AppointmentDocs userId={this.state.userId} user={'patient'} />
                {/* <DocumentsHistory /> */}
              </Grid>
              <Grid className={classes.child} xs={12} sm={6}>
                {this.state.loadAppointmentList ? <AppointmentList userId={this.state.userId} /> : ""}
                <div className={classes.space}></div>
                <PatientMedications onRef={ref => this.callMedications = ref} userId={this.state.userId} />
                <MedHistory onRef={ref => this.callMedical = ref} userId={this.state.userId} />
                <PatientNotes userId={this.state.userId} parentPage={'EMR'} />
              </Grid>
            </Grid>
          }
        </DndProvider>
        {parseInt(this.state.showInviteSubscription) > 1 && this.props.SubscriptionInvited &&
          <Modal
            title={"Subscription Invitation"}
            visible={this.state.showInviteSubscription > 1 ? true : false}
            width={515}
            onCancel={() => { this.setState({ showInviteSubscription: 0 }, () => { this.props.SubscriptionInvitationUpdate() }) }}
            footer={
              <Button variant="contained" color="secondary" onClick={() => { this.setState({ showInviteSubscription: 0 }) }}>
                OK
              </Button>
            }
          >
            <Grid className={classes.changeMembers}>
              <span className={classes.changeMembersSpan}>
                {account.subscriptionType === 'family' ?
                  "You have already subscribed to another group subscription. If you wish to join into this group subscription, please cancel the other subscription first."
                  :
                  "You have already subscribed to an individual subscription. If you wish to join into a group subscription, please cancel the individual subscription first."
                }
              </span>
            </Grid>
          </Modal>
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  /* FOR TASK RHYN-102
    TASK DESCRIPTION: Add patient tab and subaccounts for age <16
    GET THE SUBACCOUNT STATE TO GET DATA ACCORDING TO SUBACCOUNT IS SUBACCOUNT DATA EXIST IN REDUX */
  return {
    auth: state.auth,
    name: state.account.data.fullName.toUpperCase(),
    account: state.account.data,
    SubscriptionInvited: state.appointment.SubscriptionInvited,
    countries: state.general.countries,
    subAccount: state.subAccount
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserDashboardData: (data) => dispatch(data),
    updateCountriesList: () => dispatch(getCountries()),
    getUserInfo: (data) => dispatch(getUserInfo(data)),
    getUpdatedUserInfo: (data) => getUpdatedUserInfo(data),
    updateProfileData: (data) => dispatch(data),
    NotificationStateUpdate: () => dispatch({
      type: "NOTIFICATION_STATE_UPDATE",
      value: false
    }),
    SubscriptionInvitationUpdate: () => dispatch({
      type: "SUBSCRIPTION_INVITED",
      value: false
    }),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(userDashboard));
