import {
    getNotesList,
    getAppointmentsList,
    getNoteTemplates,
    createNoteTemplate,
    updateNoteTemplate,
    deleteNoteTemplate,
    getSocialHistoryList,
    createSocialHistory,
    updateSocialHistory,
    deleteSocialHistory,
    getMedicalHistoryList,
    createMedicalHistory,
    updateMedicalHistory,
    deleteMedicalHistory,
    updateAllergyHistory,
    createAllergyHistory,
    deleteAllergyHistory,
    getAllergiesList,
    getMedicationHistoryList,
    createMedicationsHistory,
    updateMedicationsHistory,
    deleteMedicationsHistory,
    getChatsHistoryList,
    createChatsHistory,
    updateChatsHistory,
    deleteChatsHistory,
    getAppointmentsDocsList,
    updateUserInfo,
    updateEMRStateData,
    CreateNote
} from '../../../store/actions/EMRAction'
import { getUpdatedUserInfo } from '../../../store/actions/authActions'
import { RynoGoUsersReducer } from '../../../store/reducers/RynoGoUsersReducer'
const RynoGoUsersActions = RynoGoUsersReducer.actions

const setAllRhynoGoPatients = (data) => dispatch => {
    dispatch(RynoGoUsersActions.listUsers(data))
    return ''
}
const getAllNotes = async (patientId, doctorId, pagination_id, isRhynoGoPatient, patientName) => {
    let result = await getNotesList(JSON.stringify({ userId: patientId, doctorId, pagination_id, isRhynoGoPatient, patientName }))
    return result
}
const getAllAppointments = async (patientId, doctorId, pagination_id, isRhynoGoPatient, patientName) => {
    let result = await getAppointmentsList(JSON.stringify({ isEMR: true, userId: patientId, doctorId: doctorId, pagination_id: pagination_id, isRhynoGoPatient, patientName }))
    return result
}
const getAllProgressTemplates = async (patientId, doctorId, pagination_id, isRhynoGoPatient, patientName) => {
    let result = await getNoteTemplates(JSON.stringify({ userId: patientId, doctorId, pagination_id, isRhynoGoPatient, patientName }))
    return result
}
const getAllSocialHistory = async (patientId, doctorId, pagination_id, isRhynoGoPatient, patientName) => {
    let result = await getSocialHistoryList(JSON.stringify({ userId: patientId, doctorId, pagination_id, isRhynoGoPatient, patientName }))
    return result
}
const getAllMedicalHistory = async (patientId, doctorId, pagination_id, isRhynoGoPatient, patientName) => {
    let result = await getMedicalHistoryList(JSON.stringify({ userId: patientId, doctorId, pagination_id, isRhynoGoPatient, patientName }))
    return result
}
const getAllAllergyHistory = async (patientId, doctorId, pagination_id, isRhynoGoPatient, patientName) => {
    let result = await getAllergiesList(JSON.stringify({ userId: patientId, doctorId, pagination_id, isRhynoGoPatient, patientName }))
    return result
}
const getAllMedicationHistory = async (patientId, doctorId, pagination_id, isRhynoGoPatient, patientName) => {
    let result = await getMedicationHistoryList(JSON.stringify({ userId: patientId, doctorId, pagination_id, isRhynoGoPatient, patientName }))
    return result
}
const getAllChats = async (patientId, doctorId, pagination_id, isRhynoGoPatient, patientName, searchIdsArray) => {
    let result = getChatsHistoryList({ userId: patientId, doctorId, pagination_id, isRhynoGoPatient, patientName, searchIdsArray })
    return result
}
const getDocHistory = async (patientId, pagination_id) => {
    let result = getAppointmentsDocsList(JSON.stringify({ userId: patientId, pagination_id }))
    return result
}
const createUpdateNoteTemplateItem = async (data) => {
    let result = ''
    if (data.hasOwnProperty('id'))
        result = await updateNoteTemplate(JSON.stringify(data))
    else
        result = await createNoteTemplate(JSON.stringify(data))
    return result
}

const deleteNoteTemplateItem = async (data) => {
    let result = ''
    if (data.hasOwnProperty('id'))
        result = await deleteNoteTemplate(JSON.stringify(data))
    return result
}

const createUpdateChatItem = async (data) => {
    let result = ''
    if (data.hasOwnProperty('id'))
        result = await updateChatsHistory(JSON.stringify(data))
    else
        result = await createChatsHistory(JSON.stringify(data))
    return result
}

const deleteUpdateChatItem = async (data) => {
    let result = ''
    if (data.hasOwnProperty('id'))
        result = await deleteChatsHistory(JSON.stringify(data))
    return result
}

const createUpdateMedicalHistoryItem = async (data) => {
    let result = ''
    if (data.hasOwnProperty('id'))
        result = await updateMedicalHistory(JSON.stringify(data))
    else
        result = await createMedicalHistory(JSON.stringify(data))
    return result
}
const deleteMedicalHistoryItem = async (data) => {
    let result = ''
    if (data.hasOwnProperty('id'))
        result = await deleteMedicalHistory(JSON.stringify(data))
    return result
}

const createUpdateSocialHistoryItem = async (data) => {
    let result = ''
    if (data.hasOwnProperty('id'))
        result = await updateSocialHistory(JSON.stringify(data))
    else
        result = await createSocialHistory(JSON.stringify(data))
    return result
}
const deleteSocialHistoryItem = async (data) => {
    let result = ''
    if (data.hasOwnProperty('id'))
        result = await deleteSocialHistory(JSON.stringify(data))
    return result
}

const createUpdateAllergyHistoryItem = async (data) => {
    let result = ''
    if (data.hasOwnProperty('id'))
        result = await updateAllergyHistory(JSON.stringify(data))
    else
        result = await createAllergyHistory(JSON.stringify(data))
    return result
}
const deleteAllergyHistoryItem = async (data) => {
    let result = ''
    if (data.hasOwnProperty('id'))
        result = await deleteAllergyHistory(JSON.stringify(data))
    return result
}

const createUpdateMedicationHistoryItem = async (data) => {
    let result = ''
    if (data.hasOwnProperty('id'))
        result = await updateMedicationsHistory(JSON.stringify(data))
    else
        result = await createMedicationsHistory(JSON.stringify(data))
    return result
}

const createUpdatePrescription = async (data) => {
    let result = await CreateNote(JSON.stringify(data))
    return result
}
const deleteMedicationHistoryItem = async (data) => {
    let result = ''
    if (data.hasOwnProperty('id'))
        result = await deleteMedicationsHistory(JSON.stringify(data))
    return result
}
const getPatientDetail = (data) => async dispatch => {
    let user = await getUpdatedUserInfo({ userId: data.id })
    if (user && user.user_data) {
        let userInfo = user.user_data
        if (data.name) {
            userInfo.fullName = data.name
            userInfo.dateofBirth = data.dob
        }
        dispatch(updateUserInfo(userInfo))
    }
}
const updateEMRState = (data) => async dispatch => {
    dispatch(updateEMRStateData(data))
}


export {
    setAllRhynoGoPatients,
    getAllNotes,
    getAllAppointments,
    getAllProgressTemplates,
    getAllSocialHistory,
    createUpdateSocialHistoryItem,
    deleteSocialHistoryItem,
    getAllMedicalHistory,
    getAllAllergyHistory,
    getAllMedicationHistory,
    createUpdateNoteTemplateItem,
    deleteNoteTemplateItem,
    createUpdateMedicalHistoryItem,
    deleteMedicalHistoryItem,
    createUpdateAllergyHistoryItem,
    deleteAllergyHistoryItem,
    createUpdateMedicationHistoryItem,
    deleteMedicationHistoryItem,
    getPatientDetail,
    getAllChats,
    createUpdateChatItem,
    deleteUpdateChatItem,
    getDocHistory,
    updateEMRState,
    createUpdatePrescription
}