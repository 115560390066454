import React, { useState } from 'react'
import { Grid, Button } from '@material-ui/core'
import { medicationButtons, medicationSelection } from '../style'
import AddMedicationToFile from './AddMedicationToFile.js'
import PrescribeMedication from './PrescribeMedication.js'

function MedicationForm({ patientName, isRhynoGoPatient, data, onCloseModel, row, id }) {
    const [type, setType] = useState(id ? 'prescription' : '')
    return (
        <>
            {type === '' ?
                <Grid style={medicationSelection}>
                    <Button style={medicationButtons} className={'lightGreenClasstbn'} fullWidth variant="contained" onClick={() => setType('prescription')}>PRESCRIBE A NEW MEDICATION</Button>
                    <Button style={medicationButtons} className={'lightBlueClasstbn'} fullWidth variant="contained" onClick={() => setType('addToFile')}>ADD A MEDICATION TO FILE</Button>
                </Grid>
                :
                type === 'addToFile' ?
                    <AddMedicationToFile patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} data={data} onCloseModel={onCloseModel} row={row} id={id} />
                    :
                    type === 'prescription' ?
                        <PrescribeMedication patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} data={data} onCloseModel={onCloseModel} row={row} id={id} />
                        :
                        ""
            }
        </>
    )
}

export default MedicationForm
