import React, { Component } from 'react'
import withStyles from '@material-ui/styles/withStyles'
import { TextField, Select, Switch, CircularProgress, Grid } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import { connect } from 'react-redux'
import { updateProfile } from '../../store/actions/authActions'



const styles = theme => ({
  parent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    backgroundColor: "#fff",
    fontFamily: 'Roboto Regular',
    right: 0,
    position: 'absolute',
    top: '-2.5rem',
    zIndex: 100,
    [theme.breakpoints.down('sm')]: {
      position: 'unset'
    }
  },
  sectionHead: {
    textAlign: 'center',
    width: '100px',
    fontWeight: 700,
    color: '#00aaad',
    marginRight: theme.spacing(3),
    fontSize: '1.1rem',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  item: {
    width: '100%',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    fontFamily: "inherit",
  },
  w150: {
    width: '115px !important',
    marginLeft: theme.spacing(1) + "px !important",
    height: theme.spacing(4),
    marginRight: '0 !important',
    backgroundColor: '#ffffff'
  },
  font: {
    fontSize: '1rem'
  },
  icon: {
    cursor: 'pointer',
  },
  relative: {
    position: 'relative',
  },
  columnDirection: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#795bb2',
    padding: '10px',
    borderRadius: '10px',
    color: '#fff',
    fontWeight: 600
  },
  holdAmount: {
    backgroundColor: 'transparent',
  }
})
class AppointmentOptions extends Component {

  constructor(props) {
    super(props)
    this.state = {
      key: '',
      val: '',
      loading: false,
      uid: props.uid,
      online: props.accountData.online
    }
  }
  onchange = async (e, val) => {
    this.setState({ loading: true })
    let key = e.target.name
    let value = e.target.value
    if (key === 'online')
      value = val
    await this.setState({ key: key, val: value })
    if (key !== 'holdAmount')
      this.onSave()
  }

  onSave = async () => {
    let key = this.state.key
    if (key === 'online') {
      this.setState({ loading: false, value: '', key: '', online: !this.state.online })
    }
    if (key === 'holdAmount')
      this.setState({ key: 'holdamountSubmit' })
    let result = await this.props.updateProfile(this.state.uid, [{ [key]: this.state.val }])
    this.props.updateProfileData(result)
    this.setState({ loading: false, value: '', key: '' })
  }

  onCancel = () => {
    this.setState({ loading: false, key: '', val: '' })
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.relative}>
        <Grid className={classes.parent}>
          <h2 className={classes.sectionHead}>Options</h2>
          <div className={classes.columnDirection}>
            <div className={classes.item}>
              <span>Go online to see patients on demand</span>
              <Grid style={{ marginLeft: 'auto' }}>
                <Switch
                  checked={this.state.online}
                  onChange={(e, val) => this.onchange(e, val)}
                  color="#fff"
                  name="online"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                {this.state.loading && this.state.key === 'online' ? <CircularProgress size={21} color="#fff" /> : ""}
              </Grid>
            </div>
            <div className={classes.item}>
              <span>Default time slot for scheduled appointments</span>
              <Select
                value={this.props.accountData.slotTime}
                onChange={this.onchange}
                variant="outlined"
                className={classes.w150}
                name={"slotTime"}
              >
                <option className={classes.font + ' ' + classes.icon} value="5">5 min</option>
                <option className={classes.font + ' ' + classes.icon} value="10">10 min</option>
                <option className={classes.font + ' ' + classes.icon} value="15">15 min</option>
                <option className={classes.font + ' ' + classes.icon} value="20">20 min</option>
                <option className={classes.font + ' ' + classes.icon} value="25">25 min</option>
                <option className={classes.font + ' ' + classes.icon} value="30">30 min</option>
                <option className={classes.font + ' ' + classes.icon} value="35">35 min</option>
                <option className={classes.font + ' ' + classes.icon} value="40">40 min</option>
                <option className={classes.font + ' ' + classes.icon} value="45">45 min</option>
                <option className={classes.font + ' ' + classes.icon} value="50">50 min</option>
                <option className={classes.font + ' ' + classes.icon} value="55">55 min</option>
                <option className={classes.font + ' ' + classes.icon} value="60">1 hour</option>
                <option className={classes.font + ' ' + classes.icon} value="65">1 hour 5 min</option>
                <option className={classes.font + ' ' + classes.icon} value="70">1 hour 10 min</option>
                <option className={classes.font + ' ' + classes.icon} value="75">1 hour 15 min</option>
                <option className={classes.font + ' ' + classes.icon} value="80">1 hour 20 min</option>
                <option className={classes.font + ' ' + classes.icon} value="85">1 hour 25 min</option>
                <option className={classes.font + ' ' + classes.icon} value="90">1 hour 30 min</option>
                <option className={classes.font + ' ' + classes.icon} value="95">1 hour 35 min</option>
                <option className={classes.font + ' ' + classes.icon} value="100">1 hour 40 min</option>
                <option className={classes.font + ' ' + classes.icon} value="105">1 hour 45 min</option>
                <option className={classes.font + ' ' + classes.icon} value="110">1 hour 50 min</option>
                <option className={classes.font + ' ' + classes.icon} value="115">1 hour 55 min</option>
                <option className={classes.font + ' ' + classes.icon} value="120">2 hours</option>
              </Select>
              {this.state.loading && this.state.key === 'slotTime' ? <CircularProgress size={21} color="#fff" /> : ""}
            </div>
            <div className={classes.item}>
              <span>Maximum hold amount for cash pay patients(CAD$)</span>
              <TextField
                name="holdAmount"
                onChange={this.onchange}
                className={classes.w150 + ' apoointmentOptionsHoldAmount ' + classes.holdAmount}
                value={this.state.val !== '' && (this.state.key === 'holdAmount' || this.state.key === 'holdamountSubmit') ? this.state.val : this.props.accountData.holdAmount}
              />
              {this.state.loading && this.state.key === 'holdAmount' ?
                <div>
                  <CheckIcon
                    className={classes.icon}
                    onClick={() => {
                      this.onSave()
                    }}>
                  </CheckIcon>
                  <ClearIcon
                    className={classes.icon}
                    onClick={() => {
                      this.onCancel()
                    }}>
                  </ClearIcon>
                </div>
                : ""}
              {this.state.loading && this.state.key === 'holdamountSubmit' ? <CircularProgress size={21} color="#fff" /> : ""}
            </div>
          </div>
        </Grid>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    accountData: state.account.data,
    uid: state.auth.uid
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateProfile: (uid, data) => updateProfile(uid, data),
    updateProfileData: (data) => dispatch(data)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AppointmentOptions))
