import React from 'react'
import { Paper } from '@material-ui/core'
import { Formik } from "formik"
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import SignupSidebar from '../registration/SignupSidebar'
import useStyles from '../registration/signup.style'
import * as Yup from "yup"
import SignUp from "./AgentOrPharmacySignUp"
import { isMobile } from "react-device-detect"
import { GOOGLEMAPKEY, CONFIGUSSTATESLIST, CONFIGCANADASTATESLIST, AVAILABLEDOCTORCOUNTRIES } from '../../config'

function AgentOrPharmacyRegister(props) {
    const [locationStatus, setLocationStatus] = React.useState(0)
    const [country, setCountry] = React.useState('Canada')
    const [state, setState] = React.useState('')
    const [step, setStep] = React.useState(0)

    let validationSchema1 = Yup.object().shape({
        password: Yup.string("Enter your password")
            .matches(
                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})((?=.*[A-Z]){1}).*$/,
                "Password must contain at least 8 characters, one uppercase, and one special case character"
            )
            .required("Enter your password"),
        confirmPassword: Yup.string("Enter your password")
            .required("Confirm your password")
            .oneOf([Yup.ref("password")], "Password does not match"),
        checkedTC: Yup.boolean().oneOf([true], 'Must Accept Terms and Conditions'),
    });
    let validationSchema0 = Yup.object().shape({
        firstName: Yup.string("Enter a First Name")
            .required("First Name is required"),
        lastName: Yup.string("Enter a Last Name")
            .required("Last Name is required"),
        email: Yup.string("Enter your email")
            .email("Enter a valid email")
            .required("Email is required"),
        city: Yup.string("Enter a City")
            .required("City is required"),
        zip: Yup.string("Enter a Postal Code/Zip")
            .required("Postal Code/Zip is required"),
        street: Yup.string("Enter a Street Address")
            .required("Street Address is required"),
        agency: Yup.string("Enter a Agency Name")
            .required("Agency Name is required"),
        phone: Yup.string()
            .test('Phone Number', 'Phone Number must be 10 digits long', function (value) {
                console.log("value", value)
                if (value === undefined || value === '' || value.length < 16) {
                    return false;
                }
                return true
            }),
        state: Yup.string().required("State is required")
            .oneOf(CONFIGCANADASTATESLIST.map(a => a.name).concat(CONFIGUSSTATESLIST.map(a => a.name)),
                'State is required '),
        country: Yup.string().required("Country is required")
            .oneOf(AVAILABLEDOCTORCOUNTRIES.map(a => a.name),
                'Country is required '),
    });

    let schemaArray = [validationSchema0, validationSchema1]

    const fetchCoordinates = () => {
        return new Promise((resolve, reject) => {
            try {

                if (!navigator.geolocation) {
                    reject("Browser does not support geolocation API");
                }

                navigator.geolocation.getCurrentPosition(
                    location => {
                        resolve(location.coords);
                    },
                    error => {
                        switch (error.code) {
                            case "PERMISSION_DENIED":
                                return reject("Permission denied to get location");
                            case "TIMEOUT":
                                return reject("Timeout waiting for user response");
                            case "POSITION_UNAVAILABLE":
                                return reject("position not available");
                            default: {
                                return reject("Cannot detect user location");
                            }
                        }
                    },
                    {
                        timeout: 1000,
                    }
                );

            } catch (error) {
                return reject("Browser does not support geolocation API");
            }
        });
    }

    const fetchAddressWithGoogle = apiKey => async ({ longitude, latitude }) => {
        try {
            let result = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=false&key=${apiKey}`,
                { mode: "cors" }
            )
            let returnData = result
            if (result.ok) {
                returnData = result.json();
            }
            if (result.status === "OK") {
                returnData = result;
            }
            return returnData
        }
        catch (e) {
            throw new Error(`Cannot query Google Maps API: ${e}`);
        }
    }
    const extractCountry = res => {
        try {
            let countryName = res.results
                .reduce((comps, res) => comps.concat(res.address_components), [])
                .filter(x => x)
                .find(comp => comp.types.includes("country"))
                .long_name;
            let stateName = res.results
                .reduce((comps, res) => comps.concat(res.address_components), [])
                .filter(x => x)
                .find(comp => comp.types.includes("administrative_area_level_1"))
                .long_name;
            return { country: countryName, state: stateName }
        } catch (e) {
            throw new Error(`Cannot get country code from Google Maps response`);
        }
    }

    const browserLocation = async apiKey => {
        try {
            let result = await fetchCoordinates()
                .then(await fetchAddressWithGoogle(apiKey))
                .then(await extractCountry)
                .catch((error) => {
                })
            if (result) {
                setCountry(result.country)
                setState(result.state)
            }
            setLocationStatus(1)
        }
        catch (error) {
        }
    }

    browserLocation(GOOGLEMAPKEY);

    const selectSchema = async (stepNumber) => {
        setStep(stepNumber)
    }
console.log('country',country)

    const classes = useStyles();
    const { auth } = props;
    if (auth.uid && auth.hasOwnProperty('is2FValidate') && auth.is2FValidate) return <Redirect to='/dashboard' />
    if (auth.signedInUserId && auth.isVerified === false) return <Redirect to='/verify' />

    let appointmentType = props.hasOwnProperty('isAgent') && props.isAgent ? "Agency" : props.hasOwnProperty('isPharmacy') && props.isPharmacy ? "Pharmacy" : ""

    const values = { type: appointmentType, captcha: '', firstName: "", agency: "", lastName: "", email: "", phone: "", street: "", state: state, city: "", zip: "", country: country, confirmPassword: "", password: "", checkedTC: false };
    return (
        <div>
            {locationStatus ?
                <div className={classes.container + " negativeMarign"}>
                    {isMobile ?
                        ""
                        :
                        <SignupSidebar />
                    }

                    <div className={classes.formOuterWrapper + " formOuterWrapper"}>
                        <Paper className={classes.paper}>
                            <Formik
                                onSubmit={values => {
                                    console.log('submit')
                                }}
                                validate={values => {
                                }}
                                initialValues={values}

                                validationSchema={schemaArray[step]}
                                render={props => <SignUp {...props} selectSchema={selectSchema} />}
                            />
                        </Paper>
                    </div>
                </div>
                : ""}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}
export default connect(mapStateToProps, null)(AgentOrPharmacyRegister);