import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { AppBar, CssBaseline, Divider, Drawer, Hidden, IconButton, Toolbar, Avatar } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import DoctorLinks from './doctorlinks'
import UserLinks from './userlinks'
import AgencyLinks from './agencylinks'
import PharmacyLinks from './pharmacylinks'
import SignedOutLinks from './signedoutlinks'
import { connect } from 'react-redux'
import Favicon from '../../assets/images/RhynoCare.png'
import SwitchAccount from '../subAccounts/SwitchAccount'
import '../../App.css'

let drawerWidth = 240
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  logo: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
      justifyContent: 'end',
      borderRadius: 0,
      // backgroundColor: "#fff",
      marginLeft: theme.spacing(-3),
      padding: theme.spacing(1, 3),
      minHeight: theme.spacing(8),
      // borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      order: 2,
      borderRadius: 0
    },
  },
  title: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3)
    },
  },
  drawerHide: {
    [theme.breakpoints.up('sm')]: {
      width: 0,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  appBarFull: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      zIndex: '1201'
    },
    backgroundColor: 'white'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  menuButtonHide: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    }
  },
  hideDrawer: {
    width: 0
  }
}));

function ResponsiveDrawer(props) {
  const { auth, container, account } = props;
  const [drawerShow, setDrawerShow] = React.useState(true);
  let location = useLocation();

  // FOR TASK RHYN-102
  // TASK DESCRIPTION: Add patient tab and subaccounts for age <16
  // SET isSignedOutLink TO TRUE IF IT IS SET PASSWORD URL
  let isSignedOutLink = location.pathname.startsWith('/set_password') ? true : false

  if (!isSignedOutLink && location.pathname.indexOf('/chat') === 0 && account.data && account.data.role === 'Doctor' && drawerShow === true) {
    setDrawerShow(false)
  }
  else if (!isSignedOutLink && drawerShow === false && location.pathname.indexOf('/chat') !== 0) {

    setDrawerShow(true)
  }

  // FOR TASK RHYN-102
  // TASK DESCRIPTION: Add patient tab and subaccounts for age <16
  // CHECK isSignedOutLink IF TRUE AND LEFT MENU SHOWN THEN HIDE LEFT MENU
  if (drawerShow === true && isSignedOutLink) {
    setDrawerShow(false)
  }

  const links = auth.uid ?
    account.data && account.data.role === 'Doctor' ? <DoctorLinks /> :
    account.data && account.data.role === 'Patient' ? <UserLinks /> :
    account.data && account.data.role === 'Pharmacy' ? <PharmacyLinks /> :
    account.data && account.data.role === 'Agency' ? <AgencyLinks /> : <SignedOutLinks />
   : <SignedOutLinks />
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [])
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const isMobile = width <= 500;

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      {links}
    </div>
  );

  return (
    <div>
      {auth.uid ?
        <div>
          <CssBaseline />
          {/* FOR TASK RHYN-102
          TASK DESCRIPTION: Add patient tab and subaccounts for age <16
          CHECK isSignedOutLink IF TRUE THEN HIDE TOP BAR */}
          {!isSignedOutLink &&
            <AppBar position="fixed" className={classes.appBarFull}>
              <Toolbar>
                {isMobile ?
                  null
                  :
                  <>
                    <Avatar className={classes.logo + " header_logo"} alt="Remy Sharp" src={Favicon} />
                    {/* SKYPE CONVERSATION
                      G-Man, 9:43 AM
                      Let us just keep it on:
                      - Right side of logo on desktop
                      - Top of all pages on Account, appointments, book now, Dashboard in mobile.
                      SHOW SWITCH ACCOUNT BUTTON IN MOBILE VIEW */}
                    <SwitchAccount />
                  </>
                }
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>

              </Toolbar>
            </AppBar>
          }
          <nav className={classes.drawer} style={{ display: drawerShow ? 'block' : 'none' }} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
        </div>
        : ""}
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    account: state.account,
  }
}

export default connect(mapStateToProps)(ResponsiveDrawer);