import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, TableCell, CircularProgress } from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles"
import { isMobileOnly } from "react-device-detect"
import { ViewState, EditingState } from "@devexpress/dx-react-scheduler"
import {
    Scheduler,
    MonthView,
    Appointments,
    Toolbar,
    DateNavigator,
    AppointmentTooltip,
} from "@devexpress/dx-react-scheduler-material-ui"
import { connectProps } from '@devexpress/dx-react-core'
import { getDoctorAvailability } from '../../store/actions/appointmentAction'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EventIcon from '@material-ui/icons/Event';

const styles = theme => ({
    scheduler: {
        width: '95%',
        fontFamily: 'revert',
        maxWidth: '1200px',
        margin: 'auto',
        // paddingTop: theme.spacing(1),
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            "&>div>div.MuiToolbar-regular": {
                display: 'flex',
                flexDirection: 'column'
            }
        }
    },
    toolbarForTest: {
        display: 'none'
    },
    cell: {
        color: "#838383 !important",
        position: "relative",
        userSelect: "none",
        verticalAlign: "top",
        padding: 0,
        height: 100,
        border: '1px solid #e7e7e7',
        fontWeight: 500,
    },
    hideCell: {
        visibility: 'hidden',
        lineHeight: 0,
        padding: 0,
        fontSize: 0,
        border: 0
    },
    text: {
        padding: "0.5em",
        textAlign: "center"
    },
    appointment: {
        height: 'fit-content',
        borderRadius: "10px",
        backgroundColor: 'rgb(216,243,243)',
        '& > div': {
            color: 'rgb(78,202,200)',
        },
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        fontFamily: 'inherit',
        "&:hover": {
            // opacity: 0.6,
            backgroundColor: 'rgb(216,243,243)',
        }
    },
    apptContent: {
        "&>div>div": {
            whiteSpace: "normal !important",
            lineHeight: 1.2,
            fontSize: '1rem',
            fontWeight: 500,
        }
    },
    flexibleSpace: {
        flex: "none",
        fontSize: '1.2rem',
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: { width: '100%' }
    },
    slotsList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    slotsListItem: {
        width: '46%',
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '3px',
        marginBottom: '3%',
        padding: '1%',
        color: '#fff',
        fontFamily: 'inherit',
        fontSize: '1.05rem',
        fontWeight: 600,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: { width: '100%' }
    },
    dateClass: {
        fontSize: theme.spacing(3),
        color: "#00bbbe"
    },
    headerClass: {
        borderRadius: '10px 10px 0px 0',
        padding: '10px 0px',
        fontSize: '1.2rem',
        fontWeight: 600,
        // color: '#fff'
    },
    textField: {
        position: 'absolute',
        visibility: 'hidden',
        left: 0,
        top: '30px'
    },
    capitalize: {
        textTransform: 'Capitalize'
    }
})

const DayScaleCell = (props) => (
    <MonthView.DayScaleCell
        {...props}
        style={{ textAlign: "center", fontWeight: "bold" }}
    />
);

// #FOLD_BLOCK
const CellBase = React.memo(
    ({
        classes,
        startDate,
        formatDate,
        ...restProps
        // #FOLD_BLOCK
    }) => {
        const isFirstMonthDay = startDate.getDate() === 1;
        const formatOptions = isFirstMonthDay
            ? { day: "numeric", month: "long" }
            : { day: "numeric" };
        return (
            <>
                {
                    !restProps.otherMonth ?
                        <TableCell tabIndex={0} className={classes.cell}>
                            <div className={classes.content}></div>
                            <div className={classes.text}>
                                {formatDate(startDate, formatOptions)}
                            </div>
                        </TableCell>
                        :
                        <TableCell tabIndex={0} className={classes.hideCell}>
                            <div className={classes.content}></div>
                            <div className={classes.text}>
                                {formatDate(startDate, formatOptions)}
                            </div>
                        </TableCell>
                }
            </>
        );
    }
);

const TimeTableCell = withStyles(styles, { name: "Cell" })(CellBase);

const Appointment = withStyles(styles, {
    name: "Appointment"
})(({ classes, ...restProps }) => (
    <Appointments.Appointment {...restProps} className={classes.appointment} />
));

const AppointmentContent = withStyles(styles, {
    name: "AppointmentContent"
})(({ classes, ...restProps }) => (
    <Appointments.AppointmentContent
        {...restProps}
        className={classes.apptContent}
    />
));


class Calendar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            currentDate: new Date().getTime(),
            data: [],
            loader: false,
            open: false,
            doctorId: props.doctorId,
            type: props.appointmentData.type ? props.appointmentData.type : "",
            next: 0,
            previous: 0
        };
        this._isMounted = false;
        this.commitChanges = this.commitChanges.bind(this);
        this.flexibleSpace = connectProps(withStyles(styles, { name: 'FlexibleSpace' })(({
            classes
        }) => (
                <Toolbar.FlexibleSpace className={classes.flexibleSpace}>
                    <span>Choose amongst the available dates & times for <span className={classes.capitalize}>{props.appointmentData.doctorName}</span></span>
                </Toolbar.FlexibleSpace>
            )), () => {
            });
        this.currentDateChange = async (currentDate) => {
            // console.log(currentDate)
            if (this._isMounted) {
                if (currentDate !== 0) {
                    await this.setState({ currentDate: currentDate, data: [], open: false })
                    this.clickButton()
                }
            }
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true
        this.clickButton()
    }
    nextStep = (slot) => {
        let stepNo = 3
        this.props.updateStepNo(stepNo, { slot, previousStep: 2 })
    }
    Content = withStyles(styles, { name: 'Content' })(({
        children, appointmentData, classes, ...restProps
    }) => (
            <AppointmentTooltip.Content className={'appointmentSlotsModel'} {...restProps} appointmentData={appointmentData}>
                <Grid container alignItems="center">
                    <Grid item xs={12} className={classes.slotsList}>
                        {this.state.type === "" ?
                            appointmentData.slots.map((item, key) => {
                                return <span onClick={() => { this.nextStep(item) }} className={classes.slotsListItem} key={key}>{moment(new Date(item * 1000)).format("hh:mm A")}</span>
                            })
                            :
                            appointmentData.slots.map((item, key) => {
                                return <span onClick={() => { this.props.updateAppointment(item) }} className={classes.slotsListItem} key={key}>{moment(new Date(item * 1000)).format("hh:mm A")}</span>
                            })
                        }
                    </Grid>
                </Grid>
            </AppointmentTooltip.Content>
        ));
    // #FOLD_BLOCK
    commitChanges({ added, changed, deleted }) {
        if (this._isMounted) {
            this.setState((state) => {
                let { data } = state;
                if (added) {
                    const startingAddedId =
                        data.length > 0 ? data[data.length - 1].id + 1 : 0;
                    data = [...data, { id: startingAddedId, ...added }];
                }
                if (changed) {
                    data = data.map((appointment) =>
                        changed[appointment.id]
                            ? { ...appointment, ...changed[appointment.id] }
                            : appointment
                    );
                }
                if (deleted !== undefined) {
                    data = data.filter((appointment) => appointment.id !== deleted);
                }
                return { data };
            });
        }
    }
    // GENERATE DATE
    calculateDate = (date) => {
        // Set Date To 00:00:00 AM
        let receivedDate = new Date(date)
        let newCreatedDate = new Date()
        newCreatedDate.setHours(receivedDate.getTimezoneOffset() / (-60))
        newCreatedDate.setHours(0)
        newCreatedDate.setSeconds(0)
        newCreatedDate.setMilliseconds(0)
        newCreatedDate.setMinutes(0)
        if (!isMobileOnly)
            newCreatedDate.setDate(1)
        else
            newCreatedDate.setDate(receivedDate.getDate())
        newCreatedDate.setMonth(receivedDate.getMonth())
        newCreatedDate.setFullYear(receivedDate.getFullYear())
        // console.log(new Date(newCreatedDate))
        // newCreatedDate.toLocaleString('en-US', { timeZone: this.props.userZone })
        // console.log(new Date(newCreatedDate))
        let receivedDateInMiliSeconds = new Date(newCreatedDate).getTime()
        let timeStampInUTC = Math.ceil(receivedDateInMiliSeconds / 1000)
        return timeStampInUTC
    }
    clickButton = async () => {
        if (this._isMounted) {
            this.setState({ loader: true })
        }
        let timeStamp = this.calculateDate(this.state.currentDate)
        let appointmentsData = []
        if (isMobileOnly)
            appointmentsData = await this.props.getDoctorAvailability({ type: 'daily', date: timeStamp, doctorId: this.props.appointmentData.doctorId })
        else
            appointmentsData = await this.props.getDoctorAvailability({ type: 'monthly', date: timeStamp, doctorId: this.props.appointmentData.doctorId })
        let timeSlotDifference = appointmentsData.data.slotDifference
        let appointments = {}

        let currentUserTime = Math.floor(new Date().getTime() / 1000)
        if (this.props.appointmentData.isRhynoGoAppointment) {
            currentUserTime = currentUserTime + (60 * 60 * 2)
        }
        if (appointmentsData.data.slots.length > 0) {
            for (let i = 0; i < appointmentsData.data.slots.length; i++) {
                let slotTime = appointmentsData.data.slots[i]
                if (slotTime > currentUserTime) {
                    let slotDate = new Date(slotTime * 1000)
                    let key = slotDate.getFullYear() + '-' + slotDate.getMonth() + '-' + slotDate.getDate()
                    if (appointments.hasOwnProperty(key)) {
                        let counter = parseInt(appointments[key].count) + 1
                        let slots = appointments[key].slots
                        slots.push(slotTime)
                        appointments[key].count = counter
                        appointments[key].title = counter + " slots available"
                        appointments[key].slots = slots
                    }
                    else {
                        appointments[key] = {
                            count: 1,
                            title: 1 + " slots available",
                            slots: [slotTime],
                            startDate: new Date(slotDate.getFullYear(), slotDate.getMonth(), slotDate.getDate(), slotDate.getHours(), slotDate.getMinutes()),
                            endDate: new Date(slotDate.getFullYear(), slotDate.getMonth(), slotDate.getDate(), slotDate.getHours(), slotDate.getMinutes() + timeSlotDifference)
                        }
                    }
                }
            }
            appointments = Object.values(appointments)
            if (this._isMounted) {
                this.setState({ data: appointments, loader: false, next: appointmentsData.data.next > 0 ? moment(appointmentsData.data.next * 1000)['_d'] : 0, previous: appointmentsData.data.previous ? moment(appointmentsData.data.previous * 1000)['_d'] : 0 })
            }
        }
        else if (appointmentsData.data.next > 0) {
            this.currentDateChange(moment(appointmentsData.data.next * 1000)['_d'])
        }
        else {
            this.setState({ data: [], loader: false, next: 0, previous: appointmentsData.data.previous ? moment(appointmentsData.data.previous * 1000)['_d'] : 0 })
        }
    }
    render() {
        const { classes } = this.props
        return (
            <Grid className={classes.scheduler + " appointmentScheduler"}>
                {!isMobileOnly ?
                    <Scheduler data={this.state.data}>
                        <EditingState onCommitChanges={this.commitChanges} />
                        <ViewState
                            currentDate={this.state.currentDate}
                            onCurrentDateChange={this.currentDateChange}
                        />
                        <MonthView
                            timeTableCellComponent={TimeTableCell}
                            dayScaleCellComponent={DayScaleCell}
                        />

                        <Appointments
                            appointmentComponent={Appointment}
                            appointmentContentComponent={AppointmentContent}
                        />
                        <Toolbar flexibleSpaceComponent={this.flexibleSpace} />
                        <DateNavigator />
                        <AppointmentTooltip
                            contentComponent={this.Content}
                            showCloseButton
                        />
                        {this.state.loader ? <div style={{ position: 'absolute', zIndex: 1, top: '50%', left: '50%' }}><CircularProgress style={{ border: 0 }} size={35} /></div> : ""}
                    </Scheduler>
                    :
                    <>
                        <div className={classes.headerClass}>
                            <div>
                                <ChevronLeftIcon fontSize={'large'} color={this.state.previous === 0 ? 'disabled' : 'inherit'} onClick={() => this.currentDateChange(this.state.previous)} style={{ zIndex: 10 }} />
                                <ChevronRightIcon fontSize={'large'} color={this.state.next === 0 ? 'disabled' : 'inherit'} onClick={() => this.currentDateChange(this.state.next)} style={{ zIndex: 10 }} />
                                <EventIcon fontSize={'large'} onClick={() => { this.setState({ open: !this.state.open }) }} style={{ zIndex: 10 }} />

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        disableToolbar
                                        variant="inline"
                                        autoOk
                                        label="Only calendar"
                                        helperText="No year selection"
                                        value={this.state.currentDate && this.state.currentDate}
                                        onChange={this.currentDateChange}
                                        open={this.state.open}
                                        className={classes.textField}
                                    />
                                </MuiPickersUtilsProvider>

                            </div>
                            <span>Choose amongst the available dates & times for doctor <span className={classes.capitalize}>{this.props.appointmentData.doctorName}</span>
                            </span>
                        </div>
                        <p className={classes.dateClass} variant="contained">{this.state.currentDate > 0 && moment(this.state.currentDate).format('dddd - MMMM D, YYYY')}</p>
                        <Grid item xs={12} className={classes.slotsList}>
                            {this.state.loader ?
                                <CircularProgress style={{ border: 0 }} size={24} />
                                :
                                this.state.data.length > 0 ?
                                    this.state.type === "" ?
                                        this.state.data[0].slots.map((item, key) => {
                                            return <span onClick={() => { this.nextStep(item) }} className={classes.slotsListItem} key={key}>{moment(new Date(item * 1000)).format("hh:mm A")}</span>
                                        })
                                        :
                                        this.state.data[0].slots.map((item, key) => {
                                            return <span onClick={() => { this.props.updateAppointment(item) }} className={classes.slotsListItem} key={key}>{moment(new Date(item * 1000)).format("hh:mm A")}</span>
                                        })
                                    :
                                    "No Slot Are Found"
                            }
                        </Grid>
                    </>
                }
            </Grid>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getDoctorAvailability: (data) => getDoctorAvailability(data),
    }
}
export default connect(null, mapDispatchToProps)(withStyles(styles)(Calendar));