import React, { Component } from 'react'
import withStyles from "@material-ui/core/styles/withStyles"
import { Grid, Button, Avatar, TextField, CircularProgress } from '@material-ui/core'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'
import { verifyHC } from '../../store/actions/appointmentAction'
import { connect } from 'react-redux'
import { Modal } from 'antd'
import HealthCardImg from "../../assets/images/healthcards-3.png"
import styled from 'styled-components'

const QueryModal = styled(Modal)`
.ant-modal-title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
}
.ant-modal-header{
    border-radius: 9px 9px 0 0;
    border-bottom:none;
}
.ant-modal-body{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}
.ant-modal-content{
    border-radius: 9px;
}
.ant-modal-close-x{
    color:black;
}
.ant-modal-footer{
    text-align:center;
    border-top:none;

}

`

const styles = theme => ({
    popup: {
        display: 'flex',
        borderRadius: '56px',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column',

    },
    popupDiv: {
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '35rem',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
    },
    popupButton: {
        marginBottom: theme.spacing(2),
        fontFamily: 'inherit',
        color: '#ffffff',
        width: "120px",
        padding: "0.4 rem",
        borderRadius: '0.6 rem',
        border: 'none',
        height: '50px',
    },
    popupButton1: {
        marginBottom: theme.spacing(2),
        fontFamily: 'inherit',
        color: '#ffffff',
        width: "120px",
        borderRadius: '0.6 rem',
        border: 'none',
        height: '50px',
        padding: "0.4 rem",
    },
    popupHeading: {
        fontFamily: 'inherit',
        fontWeight: 700,
        paddingBottom: theme.spacing(3),
        fontSize: theme.spacing(2.4)
    },
    font: {
        fontFamily: 'revert',
    },
    mainDiv: {
        display: 'flex',
        margin: 'auto',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },
        '& .MuiFormControl-root': {
            border: '1px solid rgb(228, 233, 236)',
            borderRadius: '0.5rem',
            width: '100%',
            marginBottom: '2rem',
            height: '57px',
            '& .MuiFormLabel-root': {
                paddingLeft: '10px'
            },
            '& .MuiInputBase-root': {
                paddingLeft: '10px'
            }

        }

    },
    formButton: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    modelText: {
        fontFamily: 'inherit',
        fontWeight: 600,
        color: '#3b3c3c',
        fontSize: '1rem'
    },
    reasonList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: { marginBottom: theme.spacing(4) }
    },
    avatar: {
        width: '85%',
        height: '100%',
        margin: 'auto',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '85%',
        margin: 'auto',
        paddingTop: theme.spacing(3),
        // [theme.breakpoints.down('sm')]: { flexDirection: 'column' }
    },

})
class getHealthCard extends Component {

    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = {
            isFormValid: true,
            card_type: '',
            hCard: props.account.hCard,
            HCExpDate: props.account.dateofHCExp ? props.account.dateofHCExp : null,
            loading: false,
            showModel: false,
            queryModal: true,
            queryModalGetCardInfo: false,
            width: window.innerWidth
        }
    }
    componentDidMount() {

        this._isMounted = true
        if (this.state.card_type === '') this.setState({ queryModal: true })
        else {
            this.setState({ queryModal: false })
        }
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);

    }
    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth })
    };
    updateCardType = (type) => {
        this.setState({ card_type: type })
    }
    updateFormData = async (isSkipped = '') => {
        this.setState({ loading: true })
        if (this.state.card_type === 'yes' && isSkipped === '') {
            let result = await this.props.verifyHC(JSON.stringify({ card: this.state.hCard, expiryDate: this.state.HCExpDate, state: this.props.account.state }))
            if (result.status === 1) {
                if (this._isMounted)
                    this.props.UpdateHealthCard({ hCard: this.state.hCard, dateofHCExp: this.state.HCExpDate, hCard_added: true })
            }
            else {
                if (this._isMounted)
                    this.setState({ showModel: true, loading: false, queryModal: false })
            }
        }
        else if (this.state.card_type === 'yes') {
            this.props.UpdateHealthCard({ hCard: '', dateofHCExp: '', hCard_added: true })
        }
        else {
            this.props.UpdateHealthCard({ hCard: '', dateofHCExp: '', hCard_added: false })
        }
    }

    onCancel = () => {
        if (this._isMounted)
            this.setState({ showModel: false })
    }

    // FORM DATA UPDATED
    change = (e, text) => {
        if (this._isMounted) {
            if (text === 'hCard') {
                // this.setState({ hCard: e.target.value })
                let value = (e.target.value).trim()
                value = value.replace(/[^a-zA-Z0-9]/g, '')
                value = value.toUpperCase();
                this.setState({ hCard: value })
            }
            else {
                console.log(moment(e).format('YYYY/MM/DD'))
                this.setState({ HCExpDate: moment(e).format('YYYY/MM/DD') })
            }
        }
    }
    validateForm = () => {
        if (this.state.HCExpDate !== null && this.state.hCard !== '' && this.state.HCExpDate !== 'Invalid date')
            this.updateFormData()
        else
            this.setState({ isFormValid: false })
    }
    render() {
        let { classes } = this.props
        const isMobile = this.state.width <= 600;
        return (
            <div className={classes.font}>
                <QueryModal
                    className="modal"
                    title={"Do you have a valid health card?"}
                    visible={this.state.queryModal}
                    width={515}
                    //  maskStyle={{margin:'0'}}
                    style={isMobile ? { margin: '0' } : {}}
                    zIndex={"2000"}
                    closable={false}
                    keyboard={false}
                    onCancel={() => { this.setState({ queryModal: false }) }}
                    footer={
                        <Grid className={classes.buttons}>
                            <Button variant="contained"
                                onClick={() => {
                                    this.updateFormData()
                                    // this.setState(loading:true)
                                    // this.setState({queryModal:false})
                                }
                                }
                                color={'primary'}
                                className={classes.popupButton}>
                                {this.state.loading ? <CircularProgress style={{ color: 'white' }} size={24} /> : "No"}
                            </Button>
                            <Button variant="contained" onClick={() => {
                                this.updateCardType('yes')
                                this.setState({ queryModal: false })
                                this.setState({ queryModalGetCardInfo: true })
                            }
                            } color={'primary'} className={classes.popupButton}>
                                Yes
                            </Button>
                        </Grid>
                    }
                >
                    {/* modal body */}
                    <Grid className={classes.mainDiv}>
                        <Grid className={classes.reasonList}>
                            <div><Avatar variant="square" alt="Something Else" src={HealthCardImg} className={classes.avatar}></Avatar></div>
                        </Grid>

                    </Grid>
                </QueryModal>

                <QueryModal
                    className="modal"
                    title={
                        <span className={classes.popupHeading}>
                            Please enter your health card information
                        </span>
                    }
                    visible={this.state.queryModalGetCardInfo}
                    width={515}
                    style={isMobile ? { margin: '0' } : {}}
                    zIndex={"2000"}
                    closable={false}
                    // onCancel={()=>{this.setState({queryModalGetCardInfo:false})}}
                    footer={
                        <Grid className={classes.formButton}>
                            <Button variant="contained"
                                onClick={() => {
                                    this.updateCardType('');
                                    this.setState({ queryModal: true })
                                    this.setState({ queryModalGetCardInfo: false })
                                }
                                }
                                className={classes.popupButton1}>
                                Back
                                </Button>
                            <Button variant="contained" onClick={() => this.validateForm()} color={'primary'} className={classes.popupButton1}>
                                {this.state.loading ? <CircularProgress size={24} /> : "Continue"}
                            </Button>
                            <Button variant="contained" onClick={() => this.updateFormData('skipped')} color={'primary'} className={classes.popupButton1}>
                                Skip
                            </Button>
                        </Grid>
                    }
                >
                    {/* Modal body */}
                    <Grid className={classes.mainDiv}>

                        <TextField
                            value={this.state.hCard}
                            onChange={(e) => this.change(e, 'hCard')}
                            onBlur={(e) => this.change(e, 'hCard')}
                            label="Health Card"
                            // style={{ marginBottom: '24px' }}
                            // autoFocus
                            InputProps={{
                                disableUnderline: true
                            }}
                            error={this.state.hCard === '' && !this.state.isFormValid ? true : false}
                            helperText={this.state.hCard === '' && !this.state.isFormValid ? "Health Card is required" : ""}
                        />
                        <MuiPickersUtilsProvider
                            utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoOk
                                variant="inline"
                                id="HCExpDate"
                                name="HCExpDate"
                                label="Date of Expiry"
                                PopoverProps={{
                                    style: { zIndex: 2000 },
                                }}
                                InputProps={{
                                    disableUnderline: true
                                }}
                                sx={{
                                    backgroundColor: 'red'
                                }}
                                value={this.state.HCExpDate}
                                placeholder={"dd/mm/yyyy"}
                                // format="MM/dd/yyyy"
                                format="dd/MM/yyyy"
                                // style={{ marginBottom: '24px',}}
                                onChange={(e) => this.change(e, 'HCExpDate')}
                                // onBlur={(e) => this.change(e,'HCExpDate')}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={this.state.HCExpDate === null && !this.state.isFormValid ? true : this.state.HCExpDate === 'Invalid date' && !this.state.isFormValid ? true : false}
                                helperText={this.state.HCExpDate === null && !this.state.isFormValid ? "HC Expire Date is required" : this.state.HCExpDate === 'Invalid date' && !this.state.isFormValid ? 'HC Expire Date format is incorrect' : ""}
                            />
                        </MuiPickersUtilsProvider>

                    </Grid>
                </QueryModal>

                <QueryModal
                    title={"Health Card"}
                    visible={this.state.showModel}
                    width={500}
                    style={isMobile ? { margin: '0' } : {}}
                    zIndex={"2000"}
                    onCancel={this.onCancel}
                    footer={
                        <Grid>
                            <Button color="primary" variant="contained" className={classes.popupButton1} onClick={this.onCancel}>Ok</Button>
                        </Grid>
                    }
                >
                    <Grid className={classes.modal}>
                        <span className={classes.modelText}>The health card doesn't appear to be valid. Please check your heath card information.</span>
                    </Grid>
                </QueryModal>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        account: state.account.data,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        verifyHC: (data) => verifyHC(data)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(getHealthCard));
