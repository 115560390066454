import React, { useState } from 'react'
import { Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, TextField, Button, CircularProgress } from '@material-ui/core'
import Functions from '../../../assets/js/function'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import moment from 'moment'
import { Alert } from 'antd'
import { createUpdateChatItem, deleteUpdateChatItem } from '../API/API'

function ChatRecord({ chat, parentKey, parentDoctorId, patientId, pagination, openCloseModel, isRhynoGoPatient, patientName }) {

    const [searchDoctorId, setSearchDoctorId] = useState(parentDoctorId)
    const [expansionkey, setExpansionkey] = useState('')
    const [messageEditVal, setMessageEditVal] = useState('')
    const [editerrormsg, setEditerrormsg] = useState('')
    const [editerror, setEditerror] = useState(false)
    const [loader, setLoader] = useState(false)
    const [disableEdit, setDisableEdit] = useState(false)

    const onClick = (key, chat, doctorId, chatId) => {
        if (key === expansionkey)
            key = -1
        if (key >= 0 && doctorId !== parentDoctorId) {
            Functions.chatViewedByProvider(JSON.stringify({ createdBy: doctorId, viewedBy: doctorId, chatId: chatId }))
        }
        setExpansionkey(key)
        setMessageEditVal(chat)
    }

    const handleEditChange = (e) => {
        setMessageEditVal(e.target.value)
        if (e.target.value !== '') {
            setDisableEdit(false)
            setEditerror(false)
        }
        else {
            setDisableEdit(true)
            setEditerror(true)
        }
    }

    const onUpdate = async (type, id) => {
        setLoader(true)
        let searchIdsArray = searchDoctorId.split(',')
        let doctorId = searchIdsArray[0]
        if (searchIdsArray.length === 1) {
            searchIdsArray = []
        }

        let result = ''
        let pagination_id = pagination

        let action = ''

        if (type === 1) {
            action = 'Edit'
            result = await createUpdateChatItem({ message: messageEditVal, id: id, userId: patientId, doctorId: doctorId, viewerId: doctorId, pagination_id: pagination_id, searchDoctorIds: searchIdsArray, patientName: patientName, isRhynoGoPatient: isRhynoGoPatient })
        }
        else {
            action = 'Delete'
            result = await deleteUpdateChatItem({ id: id, userId: patientId, doctorId: doctorId, viewerId: doctorId, pagination_id: pagination_id, searchDoctorIds: searchIdsArray, patientName: patientName, isRhynoGoPatient: isRhynoGoPatient })
            setExpansionkey(-1)
        }
        let previousData = result.data
        openCloseModel(null, true, action, previousData, pagination_id, true)
        setLoader(false)
    }
    return (
        <ExpansionPanel key={chat.id} expanded={parentKey === expansionkey ? true : false} style={styling.panel}>
            <ExpansionPanelSummary onClick={() => onClick(parentKey, chat.message, chat.doctorId, chat.id)}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <div style={styling.header}>
                    <Typography>Progress Note: Date - {moment(chat.created_at * 1000).format('MMMM D, YYYY')}
                        {chat.doctorId !== parentDoctorId ?
                            <div>
                                {chat.speciality !== 'Nurse Practitioner' && 'Dr. '}{chat.doctorName}{chat.speciality === 'Nurse Practitioner' && ', NP'} ({chat.speciality})
                                        </div>
                            : ""}
                    </Typography>
                    <Typography>Last Edited: {moment(chat.updated_at * 1000).format('MMMM D, YYYY')}</Typography>
                </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={styling.list}>
                {editerrormsg ?
                    <Grid>
                        <Alert type="error" closable={true} message={editerrormsg}></Alert>
                    </Grid>
                    : ""}
                <TextField
                    type="text"
                    variant="outlined"
                    margin="normal"
                    multiline
                    autoFocus
                    required
                    label="Message"
                    error={editerror}
                    helperText={editerror ? "Message field required" : ""}
                    value={messageEditVal}
                    onChange={handleEditChange}
                />
                <div style={styling.listButtons}>
                    {loader ?
                        <CircularProgress size={24} /> :
                        chat.doctorId === parentDoctorId ?
                            <div>
                                <Button style={styling.listButton} color="primary" disabled={disableEdit} variant="contained" onClick={() => onUpdate(1, chat.id)}>Save</Button>
                                <Button style={styling.listButton} variant="contained" color="secondary" onClick={() => onUpdate(0, chat.id)}>Delete</Button>
                            </div>
                            : ""
                    }
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )
}

const styling = {
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        textAlign: 'left'
    },
    panel: {
        padding: 0,
        width: '100%',
        maxWidth: '100%',
        backgroundColor: '#fff',
        borderBottom: '1px solid #80808036',
        marginBottom: '16px'
    },
    list: {
        display: 'flex',
        flexDirection: 'column'
    },
    listButtons: {
        display: 'flex',
        marginTop: '16px'
    },
    listButton: {
        marginRight: '16px'
    },
}

export default ChatRecord