import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
//Redux
import { connect } from 'react-redux'
import { getSpecialities, getStates, getCountries } from '../../../store/actions/authActions'
//Dates
//Child components
import Form from './Form'
//MUI
import withStyles from "@material-ui/core/styles/withStyles"
import SwitchAccount from '../../subAccounts/SwitchAccount'
import { isMobileOnly } from "react-device-detect"

const styles = theme => ({

})
const Account = (props) => {
  const { classes, auth, subAccount, account, availablecountries, availableStates } = props;
  // console.log(props)
  useEffect(() => {
    if (!auth.uid) return <Redirect to='/signin' />
    if (!auth.isVerified) {
      return <Redirect to="/verify" />;
    }

  }, [auth.uid, auth.isVerified])

  return (
    <div className={classes.container}>
      <div>
        {/* SKYPE CONVERSATION
          G-Man, 9:43 AM
          Let us just keep it on:
          - Right side of logo on desktop
          - Top of all pages on Account, appointments, book now, Dashboard in mobile.
          SHOW SWITCH ACCOUNT BUTTON IN MOBILE VIEW */}
        {isMobileOnly && <SwitchAccount />}
        <Form
          auth={auth}
          subAccount={subAccount}
          account={account}
          availablecountries={availablecountries}
          availableStates={availableStates} />
      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  /* FOR TASK RHYN-102
    TASK DESCRIPTION: Add patient tab and subaccounts for age <16
    GET THE SUBACCOUNT STATE TO GET DATA ACCORDING TO SUBACCOUNT IS SUBACCOUNT DATA EXIST IN REDUX */
  return {
    auth: state.auth,
    account: state.account,
    availablecountries: state.general.countries,
    availableStates: state.general.states,
    subAccount: state.subAccount
  };
};
const mapDispatchToProps = (dispatch) => {
  dispatch(getSpecialities())
  dispatch(getCountries())
  dispatch(getStates())
  return {}
  // return {
  //   updateProfile: (uid, data) => updateProfile(uid, data),
  //   updateProfileData: (data) => dispatch(data)
  // }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Account));