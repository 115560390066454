import React, { Component } from 'react'
import { Grid, Paper, Avatar, TextField, MenuItem, Select, InputBase, CircularProgress } from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles"
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import EditIcon from '@material-ui/icons/Edit'
import { updateProfile } from '../../store/actions/authActions'
import '../../App.css'
import functionsList from '../../assets/js/function.js'
import { getSpecialities, getStates, getCountries, getLanguages } from '../../store/actions/authActions'
import { Multiselect } from 'multiselect-react-dropdown'
import SignatureCanvas from 'react-signature-canvas'
import moment from 'moment-timezone'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import momentSetDate from 'moment'
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { AVAILABLEDOCTORCOUNTRIES } from '../../config'
import { Modal } from 'antd'
const { confirm } = Modal

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: theme.spacing(2.2, 1.8),
    textAlign: 'left',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#009688',
      borderWidth: '2px',
      backgroundColor: "#fff"
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);
const styles = theme => ({
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: 'space-between',
    maxWidth: '95%',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      maxWidth: '100%',
      padding: 0
    }
  },
  sections: {
    display: 'flex',
    width: '47%',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  flexColumn: {
    flexDirection: 'column',
  },
  sectionpart: {
    backgroundColor: '#fff',
    padding: theme.spacing(3.5, 1.5),
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(.7)
  },
  profile: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: `${theme.spacing(1)}px`
  },
  profileItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #c1b8b859',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`
  },
  icon: {
    cursor: 'pointer',
  },
  label: {
    width: '40%',
    textAlign: 'left'
  },
  textarea: {
    width: '87%',
    textAlign: 'left'
  },
  posRelative: {
    position: 'relative'
  },
  initials: {
    textTransform: 'uppercase'
  },
  photoClass: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  photo: {
    width: '7rem',
    height: '7rem',
    cursor: 'pointer'
  },
  photoInfo: {
    width: '100%',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  textareaClass: {
    width: '100%'
  },
  uploadClass: {
    display: 'none'
  },
  photoloader: {
    position: 'absolute',
    bottom: '40%',
    left: '40%'
  },
  sigImage: {
    [theme.breakpoints.down('sm')]: {
      width: '100px'
    }
  },
  border: {
    border: '1px solid #c1b8b859'
  }
});

class doctorProfile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      edit_key: '',
      edit_val: '',
      uid: '',
      error: false,
      langVal: [],
    }
  }
  handleChangeDate = (e) => {
    this.setState({
      [this.state.edit_key + 'Val']: momentSetDate(e).format('YYYY-MM-DD'),
      'edit_val': momentSetDate(e).format('YYYY-MM-DD'),
    });
    if (e === '' || momentSetDate(e).format('YYYY-MM-DD') === 'Invalid date') {
      this.setState({ error: true })
    }
    else {
      this.setState({ error: false })
    }
  }
  handleChange = (e) => {
    let val = e.target.value
    if (this.state.edit_key === 'zip') {
      val = e.target.value.toUpperCase()
      val = val.replace(' ', '')
    }

    this.setState({
      [this.state.edit_key + 'Val']: val,
      'edit_val': val,
    });
    if (e.target.value === '') {
      this.setState({ error: true })
    }
    else {
      this.setState({ error: false })
    }
  }

  handleLangChange = (selectedList, selectedItem) => {
    let string = [];
    selectedList.map((val, key) => {
      string.push(val['name'])
      return ''
    })
    this.setState({
      [this.state.edit_key + 'Val']: string,
      'edit_val': string,
      langVal: selectedList
    });
    if (string.toString() === '') {
      this.setState({ error: true })
    }
    else {
      this.setState({ error: false })
    }
  }
  createLngVal = (val) => {
    let selectedList = []
    if (val !== undefined)
      val.map((val) => {
        selectedList.push({ name: val })
        return ''
      })
    this.setState({ edit_key: 'language', langVal: selectedList, error: false })
  }

  componentDidUpdate(prevprops) {
    if (this.state.loading) {
      if (prevprops.account.data[this.state.edit_key] !== this.props.account.data[this.state.edit_key]) {
        this.setState({ loading: false, edit_key: '', edit_val: '' })
      }
      else {
        setTimeout(() => this.setState({ loading: false, edit_key: '', edit_val: '' }), 10000);
      }
    }
  }
  componentDidMount() {
	if(this.props.auth) {  
		this.setState({ uid: this.props.auth.uid })
	}
  }
  
  onSave = async (val) => {
    let currentZone = this.props.account.data.zone
    let newZone = this.state.edit_val
    let result = ''

    if (this.state.edit_key === 'country') {
      let key = this.state.edit_key, value = this.state.edit_val
      let availableCountriesList = AVAILABLEDOCTORCOUNTRIES
      for (let i = 0; i < availableCountriesList.length; i++) {
        if (availableCountriesList[i].name === value) {
          this.setState({ loading: true })
          result = await this.props.updateProfile(this.state.uid, [{ [key]: value }, { countryCode: availableCountriesList[i].code }, { countryDialCode: availableCountriesList[i].dial_code }])
          this.props.updateProfileData(result)
        }
      }
    }
    else if (this.state.edit_key === 'zone' && this.state.edit_val !== '') {
      confirm({
        title: 'Your availability is visible in ' + currentZone + ', its now visible in ' + newZone,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          let key = this.state.edit_key, value = this.state.edit_val
          this.setState({ loading: true })
          result = await this.props.updateProfile(this.state.uid, [{ [key]: value }])
          this.props.updateProfileData(result)
        },
        onCancel: () => {
          this.setState({ loading: false, edit_key: '', edit_val: '' })
        },
      });
    }
    else if (this.state.error === false && (this.state.edit_val !== '' || this.state.edit_key === 'phone' || this.state.edit_key === 'mobile' || this.state.edit_key === 'street' || this.state.edit_key === 'zip')) {
      let key = this.state.edit_key, value = this.state.edit_val
      this.setState({ loading: true })
      result = await this.props.updateProfile(this.state.uid, [{ [key]: value }])
      this.props.updateProfileData(result)
    }
    else {
      this.setState({ loading: false })
    }
  }
  onSaveSignature = () => {
    this.setState({
      loading: true,
      edit_val: this.sigPad.getTrimmedCanvas().toDataURL("image/png")
    }, async () => {
      let key = this.state.edit_key, value = this.state.edit_val
      setTimeout(() => this.setState({ loading: false, edit_key: '', edit_val: '' }), 2000);
      let result = await this.props.updateProfile(this.state.uid, [{ [key]: value }])
      this.props.updateProfileData(result)
    });
  }
  onCancel = val => {
    this.setState({ edit_key: '', edit_val: '' })
  }
  onImageChangeHandler = async (e) => {
    this.setState({ loading: true, edit_key: 'photo' })
    var result = await functionsList.saveImage(e, this.state.imageType)
    result = JSON.parse(result)
    this.setState({ photoname: result.name, photoUrl: result.path })
    setTimeout(() => this.setState({ loading: false, edit_key: '', edit_val: '' }), 2000);
    let updateResult = await this.props.updateProfile(this.state.uid, [{ 'photo': result.path }])
    this.props.updateProfileData(updateResult)
  }
  handleFileSelect = (type) => {
    this.setState({ imageType: type })
    document.getElementById('file').click();
  }
  render() {
    const { classes, auth, account, availableSpecialities, availableStates, availableLanguages } = this.props;
    if (!auth.uid) return <Redirect to='/signin' />
    if (!auth.isVerified) {
      return <Redirect to="/verify" />;
    }
    //this.state.uid = auth.uid
    // this.setState({ uid: auth.uid })
    let SpecialitiesList = [], USStatesList = [], CanadaStatesList = [], LanguageList = []
    if (availableSpecialities) {
      SpecialitiesList = availableSpecialities.map((availableSpeciality) =>
        <MenuItem value={availableSpeciality} key={availableSpeciality}>{availableSpeciality}</MenuItem>
      )
    }
    if (availableLanguages) {
      availableLanguages.map((availableLanguage) =>
        LanguageList.push({ name: availableLanguage })
      )
    }
    if (availableStates) {
      USStatesList = (availableStates.US).map((availablestate, key) =>
        <MenuItem value={availablestate.name} key={availablestate}>{availablestate.name}</MenuItem>
      )
      CanadaStatesList = (availableStates.CA).map((availablestate, key) =>
        <MenuItem value={availablestate.name} key={availablestate}>{availablestate.name}</MenuItem>
      )
    }
    let zonesList = []
    let zoneList = moment.tz.names()
    for (let i = 0; i < zoneList.length; i++) {
      zonesList.push(<MenuItem key={i} value={zoneList[i]}>{zoneList[i]}</MenuItem>)
    }
    return (
      <React.Fragment>
        <div className="profilePage">
          <Paper elevation={1} className={classes.paper}>
            <div className={classes.sections + ' ' + classes.sectionpart + ' shadow'}>
              <Avatar className={classes.initials}>{account.data.initials}</Avatar>
              <div className={classes.profile}>
                <Grid className={classes.profileItem}>
                  <label className={classes.label}>First & Middle Name</label>
                  <label style={{ display: this.state.edit_key === 'firstName' ? 'none' : 'flex' }} className={classes.label}>{account.data.firstName}</label>
                  <TextField
                    style={{ display: this.state.edit_key !== 'firstName' ? 'none' : 'flex' }}
                    className={classes.label + " editable_field"}
                    variant="outlined"
                    margin="normal"
                    autoFocus
                    label="First & Middle Name"
                    required
                    error={this.state.error}
                    helperText={this.state.error ? "First & Middle Name field required" : ""}
                    value={this.state.edit_val}
                    onChange={this.handleChange}
                  />
                  {this.state.loading && this.state.edit_key === 'firstName' ?
                    <CircularProgress size={21} /> :
                    <EditIcon
                      className={classes.icon}
                      style={{ display: this.state.edit_key === 'firstName' ? 'none' : 'flex' }}
                      onClick={() => {
                        this.setState({ edit_key: 'firstName', edit_val: account.data.firstName, error: false })
                      }}>
                    </EditIcon>
                  }
                  <CheckIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'firstName' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onSave()
                    }}>
                  </CheckIcon>
                  <ClearIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'firstName' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onCancel()
                    }}>
                  </ClearIcon>
                </Grid>
                <Grid className={classes.profileItem}>
                  <label className={classes.label}>Last Name</label>
                  <label style={{ display: this.state.edit_key === 'lastName' ? 'none' : 'flex' }} className={classes.label}>{account.data.lastName}</label>
                  <TextField
                    style={{ display: this.state.edit_key !== 'lastName' ? 'none' : 'flex' }}
                    className={classes.label + " editable_field"}
                    variant="outlined"
                    margin="normal"
                    autoFocus
                    label="Last Name"
                    required
                    error={this.state.error}
                    helperText={this.state.error ? "Last Name field required" : ""}
                    value={this.state.edit_val}
                    onChange={this.handleChange}
                  />
                  {this.state.loading && this.state.edit_key === 'lastName' ?
                    <CircularProgress size={21} /> :
                    <EditIcon
                      className={classes.icon}
                      style={{ display: this.state.edit_key === 'lastName' ? 'none' : 'flex' }}
                      onClick={() => {
                        this.setState({ edit_key: 'lastName', edit_val: account.data.lastName, error: false })
                      }}>
                    </EditIcon>
                  }

                  <CheckIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'lastName' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onSave()
                    }}>
                  </CheckIcon>
                  <ClearIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'lastName' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onCancel()
                    }}>
                  </ClearIcon>
                </Grid>
                <Grid className={classes.profileItem}>
                  <label className={classes.label}>Date of Birth</label>
                  <label style={{ display: this.state.edit_key === 'dateofBirth' ? 'none' : 'flex' }} className={classes.label}>{account.data.dateofBirth ? momentSetDate(account.data.dateofBirth).format('DD-MM-YYYY') : ""}</label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      style={{ display: this.state.edit_key !== 'dateofBirth' ? 'none' : 'flex' }}
                      className={classes.label + " editable_field"}
                      autoOk
                      variant="inline"
                      margin="normal"
                      autoFocus
                      label="Date Of Birth"
                      required
                      // format="MM/dd/yyyy"
                      placeholder={"dd/mm/yyyy"}
                      format="dd/MM/yyyy"
                      error={this.state.error}
                      helperText={this.state.error && this.state.edit_val === '' ? "Date Of Birth field required" : this.state.error && this.state.edit_val !== '' ? "Invalid Format" : ""}
                      value={this.state.edit_val ? momentSetDate(this.state.edit_val).format('YYYY/MM/DD') : null}
                      onChange={this.handleChangeDate}
                    />
                  </MuiPickersUtilsProvider>
                  {this.state.loading && this.state.edit_key === 'dateofBirth' ?
                    <CircularProgress size={21} /> :
                    <EditIcon
                      className={classes.icon}
                      style={{ display: this.state.edit_key === 'dateofBirth' ? 'none' : 'flex' }}
                      onClick={() => {
                        this.setState({ edit_key: 'dateofBirth', edit_val: account.data.dateofBirth, error: false })
                      }}>
                    </EditIcon>
                  }
                  <CheckIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'dateofBirth' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onSave()
                    }}>
                  </CheckIcon>
                  <ClearIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'dateofBirth' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onCancel()
                    }}>
                  </ClearIcon>
                </Grid>
                <Grid className={classes.profileItem}>
                  <label className={classes.label}>Billing Info</label>
                  <label style={{ display: this.state.edit_key === 'billingInfo' ? 'none' : 'flex' }} className={classes.label}>{account.data.billingInfo}</label>
                  <TextField
                    style={{ display: this.state.edit_key !== 'billingInfo' ? 'none' : 'flex' }}
                    className={classes.label + " editable_field"}
                    variant="outlined"
                    margin="normal"
                    autoFocus
                    label="Billing Info"
                    required
                    error={this.state.error}
                    helperText={this.state.error ? "BillingInfo field required" : ""}
                    value={this.state.edit_val}
                    onChange={this.handleChange}
                  />
                  {this.state.loading && this.state.edit_key === 'billingInfo' ?
                    <CircularProgress size={21} /> :
                    <EditIcon
                      className={classes.icon}
                      style={{ display: this.state.edit_key === 'billingInfo' ? 'none' : 'flex' }}
                      onClick={() => {
                        this.setState({ edit_key: 'billingInfo', edit_val: account.data.billingInfo, error: false })
                      }}>
                    </EditIcon>
                  }
                  <CheckIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'billingInfo' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onSave()
                    }}>
                  </CheckIcon>
                  <ClearIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'billingInfo' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onCancel()
                    }}>
                  </ClearIcon>
                </Grid>
                <Grid className={classes.profileItem}>
                  <label className={classes.label}>LicenseInfo</label>
                  <label style={{ display: this.state.edit_key === 'licenseInfo' ? 'none' : 'flex' }} className={classes.label}>{account.data.licenseInfo}</label>
                  <TextField
                    style={{ display: this.state.edit_key !== 'licenseInfo' ? 'none' : 'flex' }}
                    className={classes.label + " editable_field"}
                    variant="outlined"
                    margin="normal"
                    autoFocus
                    label="License Info"
                    required
                    error={this.state.error}
                    helperText={this.state.error ? "License Info field required" : ""}
                    value={this.state.edit_val}
                    onChange={this.handleChange}
                  />
                  {this.state.loading && this.state.edit_key === 'licenseInfo' ?
                    <CircularProgress size={21} /> :
                    <EditIcon
                      className={classes.icon}
                      style={{ display: this.state.edit_key === 'licenseInfo' ? 'none' : 'flex' }}
                      onClick={() => {
                        this.setState({ edit_key: 'licenseInfo', edit_val: account.data.licenseInfo, error: false })
                      }}>
                    </EditIcon>
                  }
                  <CheckIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'licenseInfo' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onSave()
                    }}>
                  </CheckIcon>
                  <ClearIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'licenseInfo' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onCancel()
                    }}>
                  </ClearIcon>
                </Grid>


                <Grid className={classes.profileItem}>
                  <label className={classes.label}>Phone Number</label>
                  <label style={{ display: this.state.edit_key === 'phone' ? 'none' : 'flex' }} className={classes.label}>{account.data.countryDialCode + account.data.phone}</label>
                  <TextField
                    style={{ display: this.state.edit_key !== 'phone' ? 'none' : 'flex' }}
                    className={classes.label + " editable_field"}
                    variant="outlined"
                    margin="normal"
                    autoFocus
                    label="Phone No"
                    value={this.state.edit_val}
                    onChange={this.handleChange}
                  />
                  {this.state.loading && this.state.edit_key === 'phone' ?
                    <CircularProgress size={21} /> :
                    <EditIcon
                      className={classes.icon}
                      style={{ display: this.state.edit_key === 'phone' ? 'none' : 'flex' }}
                      onClick={() => {
                        this.setState({ edit_key: 'phone', edit_val: account.data.phone, error: false })
                      }}>
                    </EditIcon>
                  }
                  <CheckIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'phone' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onSave()
                    }}>
                  </CheckIcon>
                  <ClearIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'phone' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onCancel()
                    }}>
                  </ClearIcon>
                </Grid>


                <Grid className={classes.profileItem}>
                  <label className={classes.label}>Mobile Number</label>
                  <label style={{ display: this.state.edit_key === 'mobile' ? 'none' : 'flex' }} className={classes.label}>{account.data.countryDialCode + account.data.mobile}</label>
                  <TextField
                    style={{ display: this.state.edit_key !== 'mobile' ? 'none' : 'flex' }}
                    className={classes.label + " editable_field"}
                    variant="outlined"
                    margin="normal"
                    autoFocus
                    label="Mobile No"
                    value={this.state.edit_val}
                    onChange={this.handleChange}
                  />
                  {this.state.loading && this.state.edit_key === 'mobile' ?
                    <CircularProgress size={21} /> :
                    <EditIcon
                      className={classes.icon}
                      style={{ display: this.state.edit_key === 'mobile' ? 'none' : 'flex' }}
                      onClick={() => {
                        this.setState({ edit_key: 'mobile', edit_val: account.data.mobile, error: false })
                      }}>
                    </EditIcon>
                  }
                  <CheckIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'mobile' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onSave()
                    }}>
                  </CheckIcon>
                  <ClearIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'mobile' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onCancel()
                    }}>
                  </ClearIcon>
                </Grid>




                <Grid className={classes.profileItem}>
                  <label className={classes.label}>Street Adress</label>
                  <label style={{ display: this.state.edit_key === 'street' ? 'none' : 'flex' }} className={classes.label}>{account.data.street}</label>
                  <TextField
                    style={{ display: this.state.edit_key !== 'street' ? 'none' : 'flex' }}
                    className={classes.label + " editable_field"}
                    variant="outlined"
                    margin="normal"
                    autoFocus
                    label="Street Address"
                    value={this.state.edit_val}
                    onChange={this.handleChange}
                  />
                  {this.state.loading && this.state.edit_key === 'street' ?
                    <CircularProgress size={21} /> :
                    <EditIcon
                      className={classes.icon}
                      style={{ display: this.state.edit_key === 'street' ? 'none' : 'flex' }}
                      onClick={() => {
                        this.setState({ edit_key: 'street', edit_val: account.data.street, error: false })
                      }}>
                    </EditIcon>
                  }
                  <CheckIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'street' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onSave()
                    }}>
                  </CheckIcon>
                  <ClearIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'street' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onCancel()
                    }}>
                  </ClearIcon>
                </Grid>
                <Grid className={classes.profileItem}>
                  <label className={classes.label}>City</label>
                  <label style={{ display: this.state.edit_key === 'city' ? 'none' : 'flex' }} className={classes.label}>{account.data.city}</label>
                  <TextField
                    style={{ display: this.state.edit_key !== 'city' ? 'none' : 'flex' }}
                    className={classes.label + " editable_field"}
                    variant="outlined"
                    margin="normal"
                    autoFocus
                    label="City"
                    required
                    error={this.state.error}
                    helperText={this.state.error ? "City field required" : ""}
                    value={this.state.edit_val}
                    onChange={this.handleChange}
                  />
                  {this.state.loading && this.state.edit_key === 'city' ?
                    <CircularProgress size={21} /> :

                    <EditIcon
                      className={classes.icon}
                      style={{ display: this.state.edit_key === 'city' ? 'none' : 'flex' }}
                      onClick={() => {
                        this.setState({ edit_key: 'city', edit_val: account.data.city, error: false })
                      }}>
                    </EditIcon>
                  }
                  <CheckIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'city' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onSave()
                    }}>
                  </CheckIcon>
                  <ClearIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'city' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onCancel()
                    }}>
                  </ClearIcon>
                </Grid>
                <Grid className={classes.profileItem}>
                  <label className={classes.label}>Country</label>
                  <label style={{ display: this.state.edit_key === 'country' ? 'none' : 'flex' }} className={classes.label}>{account.data.country}</label>
                  <Select
                    value={this.state.edit_val}
                    onChange={this.handleChange}
                    style={{ display: this.state.edit_key !== 'country' ? 'none' : 'flex' }}
                    className={classes.label + " editable_field"}
                    input={<BootstrapInput />}
                  >
                    <MenuItem value='United States of America'>United States of America</MenuItem>
                    <MenuItem value='Canada'>Canada</MenuItem>
                  </Select>
                  {this.state.loading && this.state.edit_key === 'country' ?
                    <CircularProgress size={21} /> :
                    <EditIcon
                      className={classes.icon}
                      style={{ display: this.state.edit_key === 'country' ? 'none' : 'flex' }}
                      onClick={() => {
                        this.setState({ edit_key: 'country', edit_val: account.data.country, error: false })
                      }}>
                    </EditIcon>
                  }
                  <CheckIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'country' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onSave()
                    }}>
                  </CheckIcon>
                  <ClearIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'country' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onCancel()
                    }}>
                  </ClearIcon>
                </Grid>

                <Grid className={classes.profileItem}>
                  <label className={classes.label}>State/Province</label>
                  <label style={{ display: this.state.edit_key === 'state' ? 'none' : 'flex' }} className={classes.label}>{account.data.state}</label>
                  <Select
                    label="State"
                    required
                    error={this.state.error}
                    value={this.state.edit_val}
                    onChange={this.handleChange}
                    style={{ display: this.state.edit_key !== 'state' ? 'none' : 'flex' }}
                    className={classes.label + " editable_field"}
                    input={<BootstrapInput />}
                  >
                    {account.data.country === 'United States of America' ? USStatesList : CanadaStatesList}
                  </Select>
                  {this.state.loading && this.state.edit_key === 'state' ?
                    <CircularProgress size={21} /> :
                    <EditIcon
                      className={classes.icon}
                      style={{ display: this.state.edit_key === 'state' ? 'none' : 'flex' }}
                      onClick={() => {
                        this.setState({ edit_key: 'state', edit_val: account.data.state, error: false })
                      }}>
                    </EditIcon>
                  }
                  <CheckIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'state' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onSave()
                    }}>
                  </CheckIcon>
                  <ClearIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'state' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onCancel()
                    }}>
                  </ClearIcon>
                </Grid>
                <Grid className={classes.profileItem}>
                  <label className={classes.label}>Zip/Postal Code</label>
                  <label style={{ display: this.state.edit_key === 'zip' ? 'none' : 'flex' }} className={classes.label}>{account.data.zip.toString()}</label>
                  <TextField
                    style={{ display: this.state.edit_key !== 'zip' ? 'none' : 'flex' }}
                    className={classes.label + " editable_field"}
                    variant="outlined"
                    margin="normal"
                    autoFocus
                    label="Zip"
                    value={this.state.edit_val.toString()}
                    onChange={this.handleChange}
                  />
                  {this.state.loading && this.state.edit_key === 'zip' ?
                    <CircularProgress size={21} /> :

                    <EditIcon
                      className={classes.icon}
                      style={{ display: this.state.edit_key === 'zip' ? 'none' : 'flex' }}
                      onClick={() => {
                        this.setState({ edit_key: 'zip', edit_val: account.data.zip, error: false })
                      }}>
                    </EditIcon>
                  }
                  <CheckIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'zip' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onSave()
                    }}>
                  </CheckIcon>
                  <ClearIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'zip' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onCancel()
                    }}>
                  </ClearIcon>
                </Grid>


                <Grid className={classes.profileItem}>
                  <label className={classes.label}>Fax Number</label>
                  <label style={{ display: this.state.edit_key === 'fax' ? 'none' : 'flex' }} className={classes.label}>{account.data.fax}</label>
                  <TextField
                    style={{ display: this.state.edit_key !== 'fax' ? 'none' : 'flex' }}
                    className={classes.label + " editable_field"}
                    variant="outlined"
                    margin="normal"
                    autoFocus
                    label="Fax Number"
                    required
                    error={this.state.error}
                    helperText={this.state.error ? "Fax Number field required" : ""}
                    value={this.state.edit_val}
                    onChange={this.handleChange}
                  />
                  {this.state.loading && this.state.edit_key === 'fax' ?
                    <CircularProgress size={21} /> :
                    <EditIcon
                      className={classes.icon}
                      style={{ display: this.state.edit_key === 'fax' ? 'none' : 'flex' }}
                      onClick={() => {
                        this.setState({ edit_key: 'fax', edit_val: account.data.fax, error: false })
                      }}>
                    </EditIcon>
                  }
                  <CheckIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'fax' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onSave()
                    }}>
                  </CheckIcon>
                  <ClearIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'fax' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onCancel()
                    }}>
                  </ClearIcon>
                </Grid>


                <Grid className={classes.profileItem}>
                  <label className={classes.label}>Zone</label>
                  <label style={{ display: this.state.edit_key === 'zone' ? 'none' : 'flex' }} className={classes.label}>{account.data.zone}</label>
                  <Select
                    label="Zone"
                    required
                    error={this.state.error}
                    value={this.state.edit_val}
                    onChange={this.handleChange}
                    style={{ display: this.state.edit_key !== 'zone' ? 'none' : 'flex' }}
                    className={classes.label + " editable_field"}
                    input={<BootstrapInput />}
                  >
                    {zonesList}
                  </Select>
                  {this.state.loading && this.state.edit_key === 'zone' ?
                    <CircularProgress size={21} /> :
                    <EditIcon
                      className={classes.icon}
                      style={{ display: this.state.edit_key === 'zone' ? 'none' : 'flex' }}
                      onClick={() => {
                        this.setState({ edit_key: 'zone', edit_val: account.data.zone, error: false })
                      }}>
                    </EditIcon>
                  }
                  <CheckIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'zone' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onSave()
                    }}>
                  </CheckIcon>
                  <ClearIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'zone' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onCancel()
                    }}>
                  </ClearIcon>
                </Grid>

                <Grid className={classes.profileItem}>
                  <label className={classes.label}>Receive Notification Via</label>
                  <ToggleButtonGroup
                    value={account.data.notificationType}
                    exclusive
                    onChange={async (e, val) => {
                      if (val === 'email' || val === 'phone') {
                        this.setState({ edit_key: 'notificationType', edit_val: val, error: false }, () => this.onSave())
                      }
                    }}
                    aria-label="text alignment"
                  >
                    <ToggleButton value="email" aria-label="centered">
                      Email
                    </ToggleButton>
                    <ToggleButton value="phone" aria-label="centered">
                      Phone
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {this.state.loading && this.state.edit_key === 'notificationType' ? <CircularProgress size={21} /> : ""}
                </Grid>





                <Grid className={classes.profileItem}>
                  <label className={classes.label}>Signature</label>
                  <label style={{ display: this.state.edit_key === 'signature' ? 'none' : 'flex' }} className={classes.label}>
                    <img className={classes.sigImage} alt={""} src={account.data.signature} />
                  </label>
                  <div className={classes.border} style={{ display: this.state.edit_key !== 'signature' ? 'none' : 'flex' }}>
                    <SignatureCanvas style={{ display: this.state.edit_key !== 'signature' ? 'none !important' : 'flex !important' }}
                      penColor='black'
                      canvasProps={{ width: 200, height: 150, className: 'sigCanvas' }} ref={ref => {
                        this.sigPad = ref;
                      }}
                      backgroundColor="#00000000" />
                  </div>
                  {this.state.loading && this.state.edit_key === 'signature' ?
                    <CircularProgress size={21} /> :

                    <EditIcon
                      className={classes.icon}
                      style={{ display: this.state.edit_key === 'signature' ? 'none' : 'flex' }}
                      onClick={() => {
                        this.setState({ edit_key: 'signature', edit_val: account.data.signature, error: false })
                      }}>
                    </EditIcon>
                  }
                  <CheckIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'signature' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onSaveSignature()
                    }}>
                  </CheckIcon>
                  <ClearIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'signature' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onCancel()
                    }}>
                  </ClearIcon>
                </Grid>

              </div>
            </div>
            <div className={classes.sections + ' ' + classes.flexColumn}>
              <div className={classes.sectionpart + ' shadow'}>

                <div className={classes.photoClass}>
                  <div className={classes.posRelative}>
                    <Avatar onClick={() => this.handleFileSelect('profile_photo')} className={classes.photo + ' userphoto'} text={"resd"} variant="circular" src={account.data.photo}></Avatar>
                    {this.state.loading && this.state.edit_key === 'photo' ?
                      <CircularProgress className={classes.photoloader} size={30} />
                      : ""}
                  </div>
                  <div className={classes.photoInfo}>
                    <Grid className={classes.profileItem}>
                      <label className={classes.label}>Languages</label>
                      <label style={{ display: this.state.edit_key === 'language' ? 'none' : 'flex' }} className={classes.label}>{account.data.language ? (account.data.language.toString()) : ""}</label>

                      <div style={{ display: this.state.edit_key !== 'language' ? 'none' : 'flex' }}>
                        <Multiselect
                          options={LanguageList} // Options to display in the dropdown
                          selectedValues={this.state.langVal} // Preselected value to persist in dropdown
                          required
                          error={this.state.error}
                          onSelect={this.handleLangChange} // Function will trigger on select event
                          onRemove={this.handleLangChange} // Function will trigger on remove event
                          displayValue="name" // Property name to display in the dropdown options
                        />
                      </div>
                      {this.state.loading && this.state.edit_key === 'language' ?
                        <CircularProgress size={21} /> :

                        <EditIcon
                          className={classes.icon}
                          style={{ display: this.state.edit_key === 'language' ? 'none' : 'flex' }}
                          onClick={() => {
                            this.createLngVal(account.data.language)
                          }}>
                        </EditIcon>
                      }
                      <CheckIcon
                        disabled={true}
                        style={{ display: this.state.loading || this.state.edit_key !== 'language' ? 'none' : 'flex' }}
                        className={classes.icon}
                        onClick={() => {
                          this.onSave()
                        }}>
                      </CheckIcon>
                      <ClearIcon
                        disabled={true}
                        style={{ display: this.state.loading || this.state.edit_key !== 'language' ? 'none' : 'flex' }}
                        className={classes.icon}
                        onClick={() => {
                          this.onCancel()
                        }}>
                      </ClearIcon>
                    </Grid>
                    <Grid className={classes.profileItem}>
                      <label className={classes.label}>Speciality</label>
                      <label style={{ display: this.state.edit_key === 'speciality' ? 'none' : 'flex' }} className={classes.label}>{account.data.speciality}</label>
                      <Select
                        value={(this.state.edit_val && this.state.edit_val !== '') ? this.state.edit_val : account.data.speciality}
                        onChange={this.handleChange}
                        style={{ display: this.state.edit_key !== 'speciality' ? 'none' : 'flex' }}
                        className={classes.label + " editable_field"}
                        input={<BootstrapInput />}
                      >
                        {SpecialitiesList}
                      </Select>
                      {this.state.loading && this.state.edit_key === 'speciality' ?
                        <CircularProgress size={21} /> :
                        <EditIcon
                          className={classes.icon}
                          style={{ display: this.state.edit_key === 'speciality' ? 'none' : 'flex' }}
                          onClick={() => {
                            this.setState({ edit_key: 'speciality', edit_val: account.data.speciality, error: false })
                          }}>
                        </EditIcon>
                      }
                      <CheckIcon
                        disabled={true}
                        style={{ display: this.state.loading || this.state.edit_key !== 'speciality' ? 'none' : 'flex' }}
                        className={classes.icon}
                        onClick={() => {
                          this.onSave()
                        }}>
                      </CheckIcon>
                      <ClearIcon
                        disabled={true}
                        style={{ display: this.state.loading || this.state.edit_key !== 'speciality' ? 'none' : 'flex' }}
                        className={classes.icon}
                        onClick={() => {
                          this.onCancel()
                        }}>
                      </ClearIcon>
                    </Grid>
                  </div>
                </div>
                <Grid className={classes.profileItem}>
                  <label className={classes.textareaClass}>Profile Data (Visible to public) :</label>
                </Grid>
                <Grid className={classes.profileItem}>
                  <label style={{ display: this.state.edit_key === 'accountInfo' ? 'none' : 'flex' }} className={classes.textarea}>{account.data.accountInfo}</label>
                  <TextField
                    style={{ display: this.state.edit_key !== 'accountInfo' ? 'none' : 'flex' }}
                    type="text"
                    multiline
                    className={classes.textarea + " editable_field1"}
                    variant="outlined"
                    margin="normal"
                    autoFocus
                    label="Profile Data"
                    required
                    error={this.state.error}
                    helperText={this.state.error ? "Profile Data field required" : ""}
                    value={this.state.edit_val}
                    onChange={this.handleChange}
                  />
                  {this.state.loading && this.state.edit_key === 'accountInfo' ?
                    <CircularProgress size={21} /> :
                    <EditIcon
                      className={classes.icon}
                      style={{ display: this.state.edit_key === 'accountInfo' ? 'none' : 'flex' }}
                      onClick={() => {
                        this.setState({ edit_key: 'accountInfo', edit_val: account.data.accountInfo, error: false })
                      }}>
                    </EditIcon>
                  }
                  <CheckIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'accountInfo' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onSave()
                    }}>
                  </CheckIcon>
                  <ClearIcon
                    disabled={true}
                    style={{ display: this.state.loading || this.state.edit_key !== 'accountInfo' ? 'none' : 'flex' }}
                    className={classes.icon}
                    onClick={() => {
                      this.onCancel()
                    }}>
                  </ClearIcon>
                </Grid>
              </div>
              {/* <div className={classes.sectionpart +' '+classes.posRelative+' shadow'}>
                      <Availability></Availability>
                </div> */}
            </div>
          </Paper>
        </div>
        <TextField
          name="file"
          type="file"
          id="file"
          className={classes.uploadClass}
          onChange={this.onImageChangeHandler}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    account: state.account,
    availableSpecialities: state.general.specialities,
    availableStates: state.general.states,
    availableLanguages: state.general.languages,
  };
};
const mapDispatchToProps = (dispatch) => {
  dispatch(getSpecialities())
  dispatch(getCountries())
  dispatch(getStates())
  dispatch(getLanguages())
  return {
    updateProfile: (uid, data) => updateProfile(uid, data),
    updateProfileData: (data) => dispatch(data)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(doctorProfile));