import { APIURL } from '../../config.js'
import functions from '../../assets/js/function'


export const getAllBillingCodes = () => {
  return fetch(APIURL + '/getAllBillingCodes', {
    method: 'POST',
    async: false,
    headers: { 'content-type': 'application/json' }
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(error => {
      return {
        status: 0,
        message: error.message
      }
    });
}

export const generateEDTFle = (data) => {
  return fetch(APIURL + '/generateEDTFle', {
    method: 'POST',
    async: false,
    body: data,
    headers: { 'content-type': 'application/json' }
  })
    .then(response => response.json())
    .then(data => {
      return {}
    })
    .catch(error => {
      return {
        status: 0,
        message: error.message
      }
    });
}

export const getBillingDetails = (data) => {
  return async (dispatch, getState) => {
    let firebaseToken = await functions.getIdToken()
    if (firebaseToken !== '') {
      fetch(APIURL + '/getBillingDetails', {
        method: 'POST',
        async: false,
        body: data,
        headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 1) {
            dispatch({
              type: 'BILLING_DETAIL_SUCCESS',
              data: data
            })
          }
          else {
            dispatch({
              type: 'BILLING_DETAIL_ERROR',
              message: data.message
            })
          }
        })
        .catch(error => {
          dispatch({
            type: 'BILLING_DETAIL_ERROR',
            payload: error.message
          })
        });
    } else {
      return ''
    }
  }
}

export const getBillingHistory = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    return fetch(APIURL + '/getBillingHistory', {
      method: 'POST',
      async: false,
      body: data,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        return data
      })
      .catch(error => {
        return {
          status: 0,
          message: error.message
        }
      });
  } else {
    return {
      status: 0,
      message: 'token not generated'
    }
  }
}
export const exportBillingDetails = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    return fetch(APIURL + '/exportBillingDetails', {
      method: 'POST',
      async: false,
      body: data,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1)
          return data
      })
      .catch(error => {
        return {
          status: 0,
          payload: error.message
        }
      });
  } else {
    return ''
  }
}
export const updateBillingDetails = (uid) => {
  return async (dispatch, getState) => {
    let firebaseToken = await functions.getIdToken()
    if (firebaseToken !== '') {
      let jsonmap = JSON.stringify({ doctorId: uid });
      fetch(APIURL + '/updateBillingDetails', {
        method: 'POST',
        async: false,
        body: jsonmap,
        headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 1) {
            dispatch({
              type: 'BILLING_DETAIL_SUCCESS',
              data: data.data
            })
          }
          else {
            dispatch({
              type: 'BILLING_DETAIL_ERROR',
              message: data.message
            })
          }
        })
        .catch(error => {
          dispatch({
            type: 'BILLING_DETAIL_ERROR',
            payload: error.message
          })
        });
    } else {
      return ''
    }
  }
}



// UPDATE BILLING ITEMS AND PRICES
export const updateBillingItems = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let response = await fetch(APIURL + '/updateBillingItems', {
      method: 'POST',
      async: false,
      body: data,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
    response = await response.json()
    return {
      status: response.status,
      data: response.data,
    }
  } else {
    return {
      status: 0,
      data: [],
    }
  }
}