const isModifierKey = (event) => {
    const key = event.keyCode;
    return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
        (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
        (key > 36 && key < 41) || // Allow left, up, right, down
        (
            // Allow Ctrl/Command + A,C,V,X,Z
            (event.ctrlKey === true || event.metaKey === true) &&
            (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
        )
};

const formatPhoneNumber = (event) => {
    if (isModifierKey(event)) { return; }

    // I am lazy and don't like to type things more than once
    let target = event.target.value;
    target = target.replace(/^0+/, '')
    const input = target.replace(/\D/g, '').substring(0, 10); // First fifteen digits of input only
    target = input
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 15);

    if (input.length > 6) { target = `(${zip}) ${middle} - ${last}`; }
    else if (input.length > 3) { target = `(${zip}) ${middle}`; }
    else if (input.length > 0) { target = `(${zip}`; }
    return target
};

const formatHealthCard = (val) => {
    let value = val.trim()
    value = value.replace(/[^a-zA-Z0-9]/g, '')
    value = value.toUpperCase();
    return value
};

const formatZip = (val) => {
    let value = val.toUpperCase()
    value = value.replace(' ', '')
    value = value.replace(/[^a-zA-Z0-9]/g, '')
    return value
};

export { formatPhoneNumber, formatHealthCard, formatZip }