import React, { useState, useEffect } from 'react'
import { Grid, TextField, Button, CircularProgress, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import momentSetDate from 'moment'
import Functions from '../../assets/js/function'
import { useDispatch, useSelector } from 'react-redux'
import { setAllRhynoGoPatients, getPatientDetail, updateEMRState } from './API/API'
import { header, mainStyle, modelBody, modelRow, seachFieldMargin } from './style'
import { updateUserInfo } from '../../store/actions/EMRAction'

function SearchAllPatients({ setPatientId, setPatientName, setIsRhynoGoPatient, patientId }) {
    const [expand, setExpand] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [dob, setDob] = useState(null)
    const [hcard, setHcard] = useState('')
    const [loader, setLoader] = useState(false)
    const [totalFetchedRecords, setTotalFetchedRecords] = useState(0)

    const profile = useSelector(state => state.auth)

    const dispatch = useDispatch()

    const onSearch = async () => {
        dispatch(setAllRhynoGoPatients([]))
        dispatch(updateUserInfo({}))
        setPatientId('')
        setLoader(true)
        let result = await Functions.searchPatientsFromAlgolia({ lastName: lastName, firstName: firstName, dob: dob, hcard: hcard, accountId: profile ? profile.id : '', })
        dispatch(setAllRhynoGoPatients(result.data))
        setLoader(false)
        setTotalFetchedRecords(result.data.length)
        if (result.data.length === 1) {
            setPatientName(result.data[0].name)
            setIsRhynoGoPatient(result.data[0].isRhynoGo)
            setPatientId(result.data[0].id)
            dispatch(getPatientDetail(result.data[0]))
        }
    }

    useEffect(() => {
        if (patientId && totalFetchedRecords !== 1) {
            setPatientId(patientId)
            setIsRhynoGoPatient(0)
            dispatch(getPatientDetail({ id: patientId }))
        }
        else if(totalFetchedRecords === 0){
            dispatch(setAllRhynoGoPatients([]))
            dispatch(updateUserInfo({}))
        }
    }, [dispatch, patientId, setIsRhynoGoPatient, setPatientId, totalFetchedRecords])

    useEffect(() => {
        if (patientId)
            dispatch(updateEMRState({ data: [], type: "EMR_CHAT_LIST" }))
    }, [patientId])

    return (
        <div xs={12} sm={12} md={12}>
            <ExpansionPanel style={{ ...mainStyle, ...{ padding: '.3rem' } }} expanded={expand} onChange={() => setExpand(!expand)}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography style={header}>SEARCH</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={modelBody}>
                    <Grid style={modelRow}>
                        <TextField label={"First Name"} style={seachFieldMargin} variant="outlined" name={"firstName"} onChange={e => setFirstName(e.target.value)} value={firstName}></TextField>
                        <TextField label={"Last Name"} style={seachFieldMargin} variant="outlined" name={"lastName"} onChange={e => setLastName(e.target.value)} value={lastName}></TextField>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker autoOk style={seachFieldMargin} variant="outlined" placeholder={"dd/mm/yyyy"} format="dd/MM/yyyy" name={"dob"} onChange={(e) => setDob(momentSetDate(e).format('YYYY/MM/DD'))} value={dob} />
                        </MuiPickersUtilsProvider>
                        <TextField label={"Health Card"} style={seachFieldMargin} variant="outlined" name={"hcard"} onChange={e => setHcard(e.target.value)} value={hcard}></TextField>
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            className={"btnCustomClass lightGreenClasstbn"}
                            onClick={onSearch}
                        >
                            {loader && <CircularProgress size={21} />}
                            {!loader && 'Search'}
                        </Button>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div >
    )
}

export default SearchAllPatients
