import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Fab, TextField, Button, CircularProgress, MenuItem, Select, InputBase } from '@material-ui/core'
import withStyles from '@material-ui/styles/withStyles'
import { Modal, Alert } from 'antd'
import AddIcon from "@material-ui/icons/Add"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import EditIcon from '@material-ui/icons/Edit'
import { createSocialHistory, updateSocialHistory, deleteSocialHistory, getSocialHistoryList } from '../../store/actions/EMRAction'
import { DragSource } from "react-dnd";
import styled from 'styled-components'

const StyledModal = styled(Modal)`
.ant-modal-title {
    color: white;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
}
.ant-modal-header{
    background: rgb(0,178,181);
    border-radius: 9px 9px 0 0;
}

.ant-modal-content{
    border-radius: 9px;
}
.ant-modal-close-x{
    color:white;
}
.ant-modal-footer{
    text-align:center;
    border-top:none;
}
`;

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            // marginTop: theme.spacing(3),
        },
        color: theme.palette.lightGrey.main,
        width: '100%'
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: theme.spacing(.75),
        textAlign: 'left',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#009688',
            borderWidth: '2px',
            backgroundColor: "#fff"
            // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);
const styles = theme => ({
    parent: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
        position: 'relative',
        width: '100%',
        '& .MuiPaper-root': {
            borderRadius: '20px',
            // maxheight:'200px',
            minHeight: '50px',
            // overflow:'scroll'
        },
    },
    panel: {
        padding: 0,
        width: '100%',
        marginBottom: theme.spacing(3)
    },
    // modal: {
    //     display: 'flex',
    //     flexDirection: 'column'
    // },
    error: {
        marginBottom: theme.spacing(2)
    },
    heading: {
        marginLeft: theme.spacing(4)
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'baseline',
        paddingBottom: theme.spacing(5)
    },
    add: {
        color: 'white',
        bottom: '0 !important',
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    item: {
        borderBottom: '1px solid #80808036',
        width: '100%',
        marginBottom: theme.spacing(1),
        display: 'grid',
        justifyItems: 'baseline',
        textAlign: 'left',
        gridTemplateColumns: '48% 48% 4%'
    },
    itemHead: {
        fontWeight: 700,
    },
    itemButton: {
        cursor: 'pointer',
        fontSize: '15px',
        '&:hover': {
            color: theme.palette.primary.main
        }
    },
    field: {
        marginBottom: theme.spacing(2)
    },
    buttonCancel: {
        backgroundColor: 'rgb(240, 242, 245)',
        width: "120px",
        color: theme.palette.lightGrey.main,
        padding: "7px",
        borderRadius: '10px',
        border: 'none',
        height: '50px',

    },
    buttonDelete: {
        width: "120px",
        borderRadius: '10px',
        border: 'none',
        height: '50px',
        padding: "7px",
        color: 'white',
        backgroundColor: "#df4759",
        "&:hover": {
            backgroundColor: "#c31432",
        },
    },
    buttonSave: {
        width: "120px",
        borderRadius: '10px',
        border: 'none',
        height: '50px',
        color: 'white',
        padding: "7px",
        backgroundColor: 'rgb(0,178,181)',
        "&:hover": {
            backgroundColor: "rgb(0,178,181)",
            color: "#1f4037"
        },
    },
    viewMoreButton: {
        width: "120px",
        borderRadius: '10px',
        border: 'none',
        padding: "7px",
        height: '3rem',
        textTransform: 'none',
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: "#1f4037"
        },
    }
})

const tile = {
    beginDrag(props, monitor, component) {
        return { type: "social", history: component.state.socialHistoryList.data };
    }
};

const collect = (connect) => ({
    dragSource: connect.dragSource()
});

class SocialHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: true,
            addSocialHistory: false,
            type: 'Smoking',
            comment: '',
            disable: false,
            typeerror: false,
            commenterror: false,
            loader: false,
            id: '',
            doctorId: props.doctorId,
            userId: props.userId,
            socialHistoryList: { data: [] },
            responseMessage: '',
            pagination_id: 0,
            loadItems: true
        }
    }
    componentDidMount() {
        this.getSocialHistory()
    }

    componentDidUpdate() {
        if (this.state.loader === true && this.state.responseMessage !== '') {
            this.setState({ errormsg: this.state.responseMessage, loader: false, disable: false })
        }
        else {
            setTimeout(() => {
                if (this.state.loader === true && this.state.responseMessage === '')
                    this.setState({ errormsg: '', addSocialHistory: false, loader: false, type: 'Smoking', comment: '', id: '', disable: false })
            }, 1000)
        }
    }
    expanded = () => {
        this.setState({ expanded: !this.state.expanded })
    }
    addSocialHistory = () => {
        this.setState({ addSocialHistory: true })
    }
    getSocialHistory = async () => {
        let result = await this.props.getSocialHistoryList(JSON.stringify({ userId: this.state.userId, doctorId: this.state.doctorId, pagination_id: this.state.pagination_id }))
        let previousData = this.state.socialHistoryList.data
        if (this.state.pagination_id === 0)
            previousData = result.data
        else
            previousData = previousData.concat(result.data)
        this.setState({ loadItems: false, socialHistoryList: { data: previousData }, responseMessage: result.message, pagination_id: result.pagination_id })
    }
    onSave = async () => {
        this.setState({ loader: true })
        let result = ''
        if (this.state.id === '')
            result = await this.props.createSocialHistory(JSON.stringify({ comment: this.state.comment, type: this.state.type, userId: this.state.userId, doctorId: this.state.doctorId, pagination_id: this.state.pagination_id }))
        else
            result = await this.props.updateSocialHistory(JSON.stringify({ comment: this.state.comment, type: this.state.type, userId: this.state.userId, doctorId: this.state.doctorId, id: this.state.id, pagination_id: this.state.pagination_id }))

        let previousData = result.data
        this.setState({ socialHistoryList: { data: previousData }, responseMessage: result.message, pagination_id: result.pagination_id })
    }
    onDelete = async () => {
        this.setState({ loader: true })
        let result = await this.props.deleteSocialHistory(JSON.stringify({ id: this.state.id, userId: this.state.userId, doctorId: this.state.doctorId, pagination_id: this.state.pagination_id }))
        let previousData = result.data
        this.setState({ socialHistoryList: { data: previousData }, responseMessage: result.message, pagination_id: result.pagination_id })
    }
    onUpdate = async (comment, id, type) => {
        this.setState({ addSocialHistory: true, comment: comment, id: id, type: type, disable: false })
    }
    onCancel = () => {
        this.setState({ errormsg: '', addSocialHistory: false, loader: false, type: 'Smoking', comment: '' })
    }
    handleChange = (e) => {
        this.setState({ comment: e.target.value }, this.checkDisable)
        if (e.target.value === '')
            this.setState({ commenterror: true })
        else
            this.setState({ commenterror: false })
    }
    changeType = (e) => {
        this.setState({ type: e.target.value }, this.checkDisable)

    }
    checkDisable = () => {
        if (this.state.type !== '')
            this.setState({ disable: false })
        else
            this.setState({ disable: true })
    }
    render() {
        const { dragSource, classes } = this.props;
        return dragSource(
            <div className={classes.parent}>
                <ExpansionPanel expanded={this.state.expanded} onChange={this.expanded} className={classes.panel}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading + " " + classes.itemHead}>Social History</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.list}>
                        <>
                            {!this.state.loadItems ?
                                this.state.socialHistoryList.data.length === 0 ?
                                    <p key={0}>No Social History Found</p> :
                                    <>
                                        <Grid className={classes.item} key={-1}>
                                            {/* <Typography className={classes.itemHead}>{'Type'}</Typography>
                                            <Typography className={classes.itemHead}>{'Comments'}</Typography> */}
                                            <Typography className={classes.itemHead}>{''}</Typography>
                                        </Grid>
                                        {this.state.socialHistoryList.data.map((socHistory, key) => {
                                            return <Grid className={classes.item} key={key}>
                                                <Typography>{socHistory.type}</Typography>
                                                <Typography>{socHistory.comment}</Typography>
                                                <EditIcon onClick={() => this.onUpdate(socHistory.comment, socHistory.id, socHistory.type)} className={classes.itemButton} />
                                            </Grid>
                                        })
                                        }
                                        {this.state.pagination_id !== -1 &&
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => this.getSocialHistory()}
                                                className={classes.viewMoreButton}
                                            >
                                                View More
                                            </Button>
                                        }
                                    </>
                                :
                                <CircularProgress size={24} />
                            }
                        </>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <Fab color="primary" className={classes.add} onClick={this.addSocialHistory}>
                    <AddIcon />
                </Fab>
                <StyledModal
                    title={"Add Social History"}
                    visible={this.state.addSocialHistory}
                    width={515}
                    onCancel={this.onCancel}
                    footer={
                        <Grid>
                            {this.state.loader ?
                                <CircularProgress size={24} /> :
                                //add class to style buttons spacing
                                <Grid>
                                    {this.state.id !== "" ?
                                        <Button style={{ marginRight: '10%' }} className={classes.buttonDelete} variant="contained" onClick={this.onDelete}>Delete</Button> : ""}
                                    {this.state.id !== "" ?
                                        <Button style={{ marginRight: '10%' }} className={classes.buttonCancel} variant="outlined" onClick={this.onCancel}>Cancel</Button>
                                        : <Button style={{ marginRight: '50%' }} className={classes.buttonCancel} variant="outlined" onClick={this.onCancel}>Cancel</Button>
                                    }
                                    <Button disabled={this.state.disable} className={classes.buttonSave} variant="contained" onClick={this.onSave}>Save</Button>
                                </Grid>
                            }
                        </Grid>
                    }
                >

                    <Grid className={classes.modal}>
                        {this.state.errormsg ?
                            <Grid className={classes.error}>
                                <Alert type="error" closable={true} message={this.state.errormsg}></Alert>
                            </Grid>
                            : ""}
                    </Grid>
                    <Grid className={classes.field}>
                        <Typography>Type</Typography>
                        <Select
                            className={classes.label}
                            onChange={this.changeType}
                            value={this.state.type}
                            fullWidth
                            input={<BootstrapInput />}
                        >
                            <MenuItem value='Smoking'>Smoking</MenuItem>
                            <MenuItem value='No Smoking'>No Smoking</MenuItem>
                            <MenuItem value='Alcohol'>Alcohol</MenuItem>
                            <MenuItem value='No Alcohol Consumption'>No Alcohol Consumption</MenuItem>
                            <MenuItem value='Recreational drug use'>Recreational drug use</MenuItem>
                            <MenuItem value='Living with'>Living with</MenuItem>
                            <MenuItem value='Sexual Identity'>Sexual Identity</MenuItem>
                        </Select>
                    </Grid>
                    <Grid>
                        {/* <Typography>Comments</Typography> */}

                        <TextField
                            type="text"
                            variant="outlined"
                            margin="normal"
                            name="Comments"
                            label="Comments"
                            value={this.state.comment}
                            onChange={this.handleChange}
                            style={{ color: 'rgb(110,127,127)', height: '50px' }}
                        />
                    </Grid>
                </StyledModal>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        createSocialHistory: (data) => createSocialHistory(data),
        updateSocialHistory: (data) => updateSocialHistory(data),
        deleteSocialHistory: (data) => deleteSocialHistory(data),
        getSocialHistoryList: (data) => getSocialHistoryList(data),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DragSource("history", tile, collect)(SocialHistory)))