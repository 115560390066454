import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button, CircularProgress, CssBaseline, TextField, Link, Grid, Box, Typography, Container, Paper } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { forgetPassword } from '../../store/actions/authActions';
import '../../App.css';
import { Redirect } from 'react-router-dom';
import { TopTitle } from '../../config.js'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {TopTitle}
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const signinStyles = theme => ({
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing.unit * 5}px`,
    [theme.breakpoints.down('sm')]: { padding: theme.spacing(2) }
  },
  actions: {
    [theme.breakpoints.down('sm')]: { flexDirection: 'column' }
  },
  container: {
    maxWidth: "inherit"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});
let re = /\S+@\S+\.\S+/
class ForgetPassword extends Component {

  state = {
    email: '',
    loading: false,
    disabled: true
  }
  componentDidMount() {
    this.props.setTotalCalls(2)
  }
  handleChange = (e) => {
    let val = e.target.value
    console.log(val)
    val = val.replace(' ', '')

    if (val === '') {
      this.setState({
        [e.target.id + "Class"]: true
      })
    }
    if (val !== '') {
      this.setState({
        [e.target.id + "Class"]: false
      })
    }
    if (!re.test(val))
      this.setState({ emailClass: true, emailError: 'Enter a valid email', disabled: true })
    else
      this.setState({ disabled: false })
    this.setState({
      [e.target.id]: val
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true })
    setTimeout(() => this.setState({ loading: false }), 2000);
    this.props.forgetPassword({ email: this.state.email })
  }
  render() {
    const { classes, auth, authError } = this.props;
    if (auth.uid) return <Redirect to='/dashboard' />
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.container}>
          <Paper elevation={1} className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Password
          </Typography>
            {authError ? <h4 className="red-text-center">{authError}</h4> : null}
            <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                error={this.state.emailClass}
                helperText={this.state.emailClass ? this.state.emailError : ''}
                style={{ marginBottom: "16px" }}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={this.state.email}
                onChange={this.handleChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                disabled={this.state.disabled ? true : false}
                className={classes.submit}
              >
                {/* Sign In */}
                {this.state.loading && <CircularProgress size={21} />}
                {!this.state.loading && 'Reset Password'}
              </Button>
              <Grid container className={classes.actions}>
                <Grid item xs>
                  <Link href="/signin" variant="body2">
                    Sign In
                </Link>
                </Grid>
                <Grid item>
                  <Link href="/signup" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </form>
            <Box mt={8}>
              <Copyright />
            </Box>
          </Paper>
        </div>
      </Container>
    );
  }
};

ForgetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.ForgetError,
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    forgetPassword: (creds) => dispatch(forgetPassword(creds))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(signinStyles)(ForgetPassword));