import React, { Component } from 'react'
import { Grid, Button, Radio, RadioGroup, FormControlLabel, CircularProgress } from '@material-ui/core'
import withStyles from '@material-ui/styles/withStyles'
import { connect } from 'react-redux'
import { updateAvailability, deleteAvailability } from '../../store/actions/authActions'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import DatePickerNew from "react-datepicker"
import moment from "moment"



const styles = theme => ({
  parent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  columnDirection: {
    flexDirection: 'column'
  },
  w100: {
    width: '100%'
  },
  headings: {
    color: '#008a8c',
    textAlign: 'center',
    fontSize: '1.3rem',
    fontWeight: 600,
    marginBottom: theme.spacing(.4)
  },
  flex: {
    display: 'flex',
  },
  alignItemsCenter: {
    alignItems: 'center'
  },
  posRelative: {
    position: 'relative',
  },
  posAbsolute: {
    position: 'absolute',
    visibility: 'hidden',
    bottom: theme.spacing(0)
  },
  cursor: {
    cursor: 'pointer',
  },
  justifyContentAround: {
    justifyContent: 'space-around'
  },
  button: {
    color: '#fff !important',
    marginLeft: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2)
    }
  },
  buttonDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4)
  },
  error: {
    color: 'red'
  },
  stepType: {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: theme.spacing(2.5)
  }
})
class AppointmentOperations extends Component {

  constructor(props) {
    super(props)
    this.state = {
      type: props.availabilityActionType,
      id: props.availabilityActionId,
      start: props.availabilityActionStartTime,
      startTime: moment(props.availabilityActionStartTime * 1000).format('LT'),
      endTime: moment(props.availabilityActionEndTime * 1000).format('LT'),
      uid: props.uid,
      step: props.availabilityActionType === 'edit' ? 1 : 2,
      loading: false,
      checkStartTime: false,
      checkEndTime: false,
      error: '',
      actionType: 'this'
    }
  }

  onchange = (e) => {
    let value = e.target.value
    this.setState({ actionType: value })
  }

  createTimeStamp = (selectedTime) => {
    // GET SELECTED DATE FROM CALENDAR
    let selectedDate = new Date(moment(this.state.start * 1000))
    // SPLIT START TIME TO TIME AND AM/PM
    let ampm = selectedTime.split(' ')
    // SPLIT TIME TO HH:MM
    let time = ampm[0].split(':')
    // console.log(parseInt(time[0]))
    // ADD 12 IN HOURS IF ITS PM TIME
    if (ampm[1] === 'PM' && parseInt(time[0]) !== 12) {
      time[0] = parseInt(time[0]) + 12
    }
    // console.log(time[0])
    // SET HOURS AND MINUTES IN SELECTED DATE
    selectedDate.setHours(time[0])
    selectedDate.setMinutes(time[1])
    selectedDate.setMinutes(selectedDate.getMinutes())
    selectedDate.setSeconds(0)
    selectedDate.setMilliseconds(0)
    // CREATE START TIMESTAMP
    let newCreatedTime = Math.floor(new Date(selectedDate).getTime() / 1000)
    // console.log(new Date(newCreatedTime*1000))
    return Math.ceil(newCreatedTime)
  }

  changeTime = (date, id) => {
    this.setState({ [id]: moment(date).format('LT') })
    if ('startTime' === id)
      this.setState({ 'checkStartTime': !this.state.checkStartTime })
    else
      this.setState({ 'checkEndTime': !this.state.checkEndTime })
  };

  nextStep = () => {
    let start = this.createTimeStamp(this.state.startTime)
    let end = this.createTimeStamp(this.state.endTime)
    if (end > start)
      this.setState({ step: 2, error: '' })
    else
      this.setState({ error: 'End time must be greater than start time' })
  }
  updateData = async () => {
    this.setState({ loading: true })
    let result = {}
    if (this.state.type === 'delete') {
      result = await this.props.deleteAvailability(JSON.stringify({ user_id: this.state.uid, type: this.state.actionType, id: this.state.id }))
    }
    else {
      result = await this.props.updateAvailability(JSON.stringify({
        user_id: this.state.uid,
        type: this.state.actionType,
        id: this.state.id,
        startTime: this.createTimeStamp(this.state.startTime),
        endTime: this.createTimeStamp(this.state.endTime)
      }))
    }
    this.setState({ loading: false })
    if (result.status) {

      this.props.editAvailability('', '')
      this.props.getAppointmentsList()
    }
    else {
      this.setState({ error: result.message })
    }
  }
  backClicked = () => {
    if (this.state.step === 2 && this.state.type === 'edit')
      this.setState({ step: 1 })
    else
      this.props.editAvailability('', '')
  }
  checkStartTime = () => {
    this.setState({ 'checkStartTime': !this.state.checkStartTime })
  }
  checkEndTime = () => {
    this.setState({ 'checkEndTime': !this.state.checkEndTime })
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.error && <p className={classes.error}>{this.state.error}</p>}
        <div className={classes.parent}>
          {this.state.step === 1 ?
            <div className={classes.columnDirection + ' ' + classes.w100}>
              <p className={classes.headings}>Please Select Time</p>
              <div className={classes.flex + ' ' + classes.justifyContentAround}>
                <div className={classes.posRelative + ' ' + classes.flex + ' ' + classes.alignItemsCenter}>
                  <span className={classes.cursor} onClick={this.checkStartTime}><u>{this.state.startTime}</u></span>
                  <ArrowDropDownIcon className={classes.cursor} onClick={this.checkStartTime} />
                  <DatePickerNew
                    showTimeSelectOnly
                    className={classes.posAbsolute + ' availability_timepicker'}
                    open={this.state.checkStartTime}
                    ariaLabelledBy="Start"
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    showTimeSelect
                    onChange={(e) => this.changeTime(e, 'startTime')}
                  />
                </div>
                <span className={classes.icon}>to</span>
                <div className={classes.posRelative + ' ' + classes.flex + ' ' + classes.alignItemsCenter}>
                  <span className={classes.cursor} onClick={this.checkEndTime}><u>{this.state.endTime}</u></span>
                  <ArrowDropDownIcon className={classes.cursor} onClick={this.checkEndTime} />
                  <DatePickerNew
                    showTimeSelectOnly
                    className={classes.posAbsolute + ' availability_timepicker'}
                    open={this.state.checkEndTime}
                    ariaLabelledBy="Start"
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    showTimeSelect
                    onChange={(e) => this.changeTime(e, 'endTime')}
                  />
                </div>
              </div>
              {/* <p className={classes.error}>{this.state.error}</p> */}
              <div className={classes.buttonDiv}>
                <Button color="secondary" className={classes.button} variant={"contained"} onClick={this.backClicked} >Cancel</Button>
                <Button color="primary" className={classes.button} variant={"contained"} onClick={this.nextStep} >Next</Button>
              </div>
            </div>
            :
            <Grid className={classes.w100}>
              <p className={classes.stepType}>{this.state.type}</p>
              <RadioGroup name="actionType" value={this.state.actionType} onChange={this.onchange}>
                <div><FormControlLabel value="this" control={<Radio color="primary" />} label="This availability" className={classes.radioButton} /></div>
                <div><FormControlLabel value="following" control={<Radio color="primary" />} label="This and following availability" className={classes.radioButton} /></div>
                <div><FormControlLabel value="all" control={<Radio color="primary" />} label="All availability" className={classes.radioButton} /></div>
              </RadioGroup>
              {!this.state.loading ?
                <div className={classes.buttonDiv}>
                  <Button color="secondary" className={classes.button} variant={"contained"} onClick={this.backClicked} >{this.state.type === 'edit' ? 'Back' : 'Cancel'}</Button>
                  <Button color="primary" className={classes.button} variant={"contained"} onClick={this.updateData} >{this.state.type === 'edit' ? 'Update' : 'Delete'}</Button>
                </div>
                :
                <div className={classes.buttonDiv}>
                  <CircularProgress size={24} />
                </div>
              }
            </Grid>
          }
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    uid: state.auth.uid
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateAvailability: (data) => updateAvailability(data),
    deleteAvailability: (data) => deleteAvailability(data),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AppointmentOperations))
