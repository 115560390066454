import React from 'react'
import { Button, Table, TableBody, Paper, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { Modal } from 'antd'
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { switchToOtherAccount } from './API/API'

// STYLE THE SUBACCOUNTS TABLE ROWS AND COLUMNS
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function SwitchAccountModal({ setShowModal, showModal, subAccountsList, activeId }) {
    const dispatch = useDispatch()

    // UDPATE REDUX STATE WHEN SWITCH BUTTON IS CLICKED
    const switchUser = (id) => {
        dispatch(switchToOtherAccount(id))
        window.location.href = "/dashboard"
    }

    return (
        <Modal
            title={"Sub Accounts List"}
            visible={showModal}
            width={600}
            onCancel={() => setShowModal(!showModal)}
            footer={null}
        >
            <TableContainer component={Paper}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell align="right">Date Of Birth</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subAccountsList.map((account) => (
                            <StyledTableRow key={account.name}>
                                <StyledTableCell component="th" scope="row">
                                    {account.name}
                                </StyledTableCell>
                                <StyledTableCell align="right">{moment(account.dob, 'YYYY/MM/DD').format('D MMM, YYYY')}</StyledTableCell>
                                <StyledTableCell align="right">
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={() => switchUser(account.id)}
                                        disabled={account.id === activeId}
                                    >
                                        Switch
							        </Button>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Modal>
    )
}

export default SwitchAccountModal
