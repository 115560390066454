import React, { useRef, useEffect } from 'react'
import { Checkbox, FormControlLabel, FormGroup, Stepper, Step, StepLabel, StepContent, Button, CircularProgress, InputAdornment, TextField, InputLabel, MenuItem, FormControl, Select } from '@material-ui/core'
import SignupIcon from '../../assets//images/signupIcon.png'
import useStyles from '../registration/stepper.style'
import moment from 'moment-timezone'
import { Modal } from 'antd'
import TC from '../registration/TC'
import { connect } from 'react-redux'
import { getSpecialities, getStates } from '../../store/actions/authActions'
import { CONFIGUSSTATESLIST, CONFIGCANADASTATESLIST, AVAILABLEDOCTORCOUNTRIES, RECAPTCHASITEKEY } from '../../config'
import { signUp } from '../../store/actions/authActions'
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3'

import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

function getSteps() {
  return ['Enter your **TYPE** information', 'Choose Password'];
}


function StepperForm(props) {

  let recaptcha = useRef(null)
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [showTCModal, setshowTCModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [countryDialCode, setCountryDialCode] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('');
  // SHOW PASSWORD STATES
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  // Add disabled button functionality on button click as cleint changed it 3/10/2020
  const [disabled, setDisabled] = React.useState(false);
  const steps = getSteps();
  const verifyCallback = async (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!  
    // console.log('recaptchatoken:'+recaptchaToken)
    await setFieldValue('captcha', recaptchaToken, true).then(() => {
      if (props.isValid && activeStep === 1) {
        submitFormAfterToken(recaptchaToken)
      }
    })
  }
  const updateToken = () => {
    // you will get a new token in verifyCallback
    recaptcha.current.execute();
  }

  useEffect(() => {
    loadReCaptcha(RECAPTCHASITEKEY);
  }, []);


  const handleNext = (step) => {

    props.submitForm().then(() => {
      if (props.isValid) {
        // if(props.isValid)
        props.selectSchema(step + 1)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        props.validateForm()
        props.setTouched({});
      }
    })
      .catch(error => {
      })
  };

  const handleBack = (step) => {
    props.selectSchema(step - 1)
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  // FORMATE NUMBER

  const isModifierKey = (event) => {
    const key = event.keyCode;
    return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
      (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      (
        // Allow Ctrl/Command + A,C,V,X,Z
        (event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
      )
  };


  const formatPhoneNumber = (event) => {
    if (isModifierKey(event)) { return; }

    // I am lazy and don't like to type things more than once
    let target = event.target.value;
    target = target.replace(/^0+/, '')
    const input = target.replace(/\D/g, '').substring(0, 10); // First fifteen digits of input only
    target = input
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 15);

    if (input.length > 6) { target = `(${zip}) ${middle} - ${last}`; }
    else if (input.length > 3) { target = `(${zip}) ${middle}`; }
    else if (input.length > 0) { target = `(${zip}`; }
    return target
  };


  var { values: { zone = moment.tz.guess(), agency, type = 'Agency', city, zip, firstName, lastName, email, country, state, phone, street, password, confirmPassword, checkedTC = false },
    errors,
    touched,
    authError,
    handleChange,
    setFieldValue,
    setFieldTouched
  } = props;
  if (authError && loading) {
    setLoading(false)
    // change disabled button on Error occured as cleint changed it 3/10/2020
    setDisabled(false)
  }
  for (let i = 0; i < AVAILABLEDOCTORCOUNTRIES.length; i++) {
    if (country === AVAILABLEDOCTORCOUNTRIES[i].name && countryDialCode === '') {
      setCountryDialCode(AVAILABLEDOCTORCOUNTRIES[i].dial_code)
      setCountryCode(AVAILABLEDOCTORCOUNTRIES[i].code)
    }
  }
  const change = (name, e) => {
    props.resetState()
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);

    if (name === 'country') {
      for (let i = 0; i < AVAILABLEDOCTORCOUNTRIES.length; i++) {
        if (e.target.value === AVAILABLEDOCTORCOUNTRIES[i].name)
          setCountryDialCode(AVAILABLEDOCTORCOUNTRIES[i].dial_code)
        setCountryCode(AVAILABLEDOCTORCOUNTRIES[i].code)
      }
    }

    if (name === 'phone' || name === 'mobile' || name === 'fax') {
      let phoneVal = formatPhoneNumber(e)
      setFieldValue(e.target.id, phoneVal, true).then(() => {
        if (e.relatedTarget !== null && e.relatedTarget !== undefined) e.relatedTarget.click()
      });
    }
    else if (e.target.name === 'checkedTC') {
      setFieldValue(e.target.name, name, true).then(() => {
        if (e.relatedTarget !== null && e.relatedTarget !== undefined) e.relatedTarget.click()
      });
    }
    else if (e.target.name === 'firstName' || e.target.name === 'lastName') {
      setFieldValue(name, (e.target.value).trimStart(), true).then(() => {
        if (e.relatedTarget !== null && e.relatedTarget !== undefined) e.relatedTarget.click()
      });
    }
    else if (e.target.name === 'zip') {
      let val = e.target.value
      val = val.replace(' ', '')
      val = val.replace(/[^a-zA-Z0-9]/g, '')
      val = val.toUpperCase()
      setFieldValue(name, val, true).then(() => {
        if (e.relatedTarget !== null && e.relatedTarget !== undefined) e.relatedTarget.click()
      });
    }
    else {
      setFieldValue(name, e.target.value, true).then(() => {
        if (e.relatedTarget !== null && e.relatedTarget !== undefined && e.relatedTarget.id === 'submitForm') e.relatedTarget.click()
      });
    }
  };


  // SHOW/HIDE TERMS AND CONDITION MODAL
  const changeTC = (status) => {
    setshowTCModal(!showTCModal)
    if (status) {
      document.getElementById('checkedTC').click()
    }
  }

  const clickSubmit = async (e) => {
    if (props.isValid && activeStep === 1) {
      setLoading(true)
      // change disabled button on Error occured as cleint changed it 3/10/2020
      setDisabled(true)
      updateToken()
    }
    else {
      e.preventDefault()
      if (activeStep === 0 || activeStep === 1) {
        handleNext(activeStep)
      }
    }
  }
  const submitFormAfterToken = (token) => {
    props.signUp({
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email,
      password: password,
      phone: phone,
      street: street,
      state: state,
      city: city,
      country: country,
      zip: zip,
      countryCode: countryCode,
      countryDialCode: countryDialCode,
      type: type,
      zone: zone,
      captcha: token,
      name: agency
    })
  }
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div>
            <div className={classes.formGroup}>
              <div className={classes.halfWidth}>
                <TextField
                  autoFocus
                  className={classes.input}
                  autoComplete="fname"
                  name="firstName"
                  fullWidth
                  id="firstName"
                  label="First & Middle Name *"
                  value={firstName}
                  helperText={touched.firstName ? errors.firstName : ""}
                  error={touched.firstName && Boolean(errors.firstName)}
                  onChange={change.bind(null, "firstName")}
                  onBlur={change.bind(null, "firstName")}
                />
              </div>
              <div className={classes.halfWidth}>
                <TextField
                  className={classes.input}
                  fullWidth
                  id="lastName"
                  label="Last Name *"
                  name="lastName"
                  autoComplete="lname"
                  value={lastName}
                  helperText={touched.lastName ? errors.lastName : ""}
                  error={touched.lastName && Boolean(errors.lastName)}
                  onChange={change.bind(null, "lastName")}
                  onBlur={change.bind(null, "lastName")}
                />
              </div>
            </div>

            <div className={classes.formGroup + " m-0"}>
              <div className={classes.halfWidth}>
                <TextField
                  className={classes.input}
                  fullWidth
                  id="agency"
                  label={type === 'Agency' ? "Agency Name*" : "Pharmacy Name*"}
                  name="Agency"
                  autoComplete="Agency"
                  value={agency}
                  helperText={touched.agency ? errors.agency : ""}
                  error={touched.agency && Boolean(errors.agency)}
                  onChange={change.bind(null, "agency")}
                  onBlur={change.bind(null, "agency")}
                />
              </div>
              <div className={classes.halfWidth}>
                <TextField
                  className={classes.input}
                  autoComplete="street"
                  name="street"
                  fullWidth
                  id="street"
                  label="Street Address *"
                  value={street}
                  helperText={touched.street ? errors.street : ""}
                  error={touched.street && Boolean(errors.street)}
                  onChange={change.bind(null, "street")}
                  onBlur={change.bind(null, "street")}
                />
              </div>
            </div>
            <div className={classes.formGroup + " m-0"}>
              <div className={classes.halfWidth}>
                <TextField
                  className={classes.input}
                  fullWidth
                  id="city"
                  label="City *"
                  name="city"
                  autoComplete="city"
                  value={city}
                  helperText={touched.city ? errors.city : ""}
                  error={touched.city && Boolean(errors.city)}
                  onChange={change.bind(null, "city")}
                  onBlur={change.bind(null, "city")}
                />
              </div>
              <div className={classes.halfWidth}>
                <TextField
                  fullWidth
                  id="zip"
                  label="Postal Code/Zip *"
                  name="zip"
                  autoComplete="zip"
                  value={zip}
                  helperText={touched.zip ? errors.zip : ""}
                  error={touched.zip && Boolean(errors.zip)}
                  onChange={change.bind(null, "zip")}
                  onBlur={change.bind(null, "zip")}
                />
              </div>
            </div>
            <div className={classes.formGroup}>
              <div className={classes.halfWidth}>
                <FormControl variant="outlined" className={classes.select}>
                  <InputLabel id="country">Province/State *</InputLabel>
                  <Select
                    fullWidth
                    labelId="state"
                    label="Province/State *"
                    className={classes.textLeft}
                    value={state}
                    helperText={touched.state ? errors.state : ""}
                    error={touched.state && Boolean(errors.state)}
                    onChange={change.bind(null, "state")}
                  >
                    {country === 'United States of America' ? USStatesList : CanadaStatesList}
                  </Select>
                  {errors.state && touched.state && (
                    <p className="MuiFormHelperText-root Mui-error">{errors.state}</p>
                  )}
                </FormControl>
              </div>
              <div className={classes.halfWidth}>
                <FormControl variant="outlined" className={classes.select}>
                  <InputLabel id="country">Country *</InputLabel>
                  <Select
                    labelId="country"
                    id="country"
                    name="country"
                    label="Country *"
                    variant="outlined"
                    fullWidth
                    className={classes.textLeft}
                    value={country}
                    helperText={touched.country ? errors.country : ""}
                    error={touched.country && Boolean(errors.country)}
                    onChange={change.bind(null, "country")}
                  >
                    <MenuItem value='United States of America'>United States of America</MenuItem>
                    <MenuItem value='Canada'>Canada</MenuItem>
                  </Select>
                  {errors.country && touched.country && (
                    <p className="MuiFormHelperText-root Mui-error">{errors.country}</p>
                  )}
                </FormControl>
              </div>
            </div>
            <div className={classes.formGroup}>
              <div className={classes.halfWidth}>
                <div className={classes.mobileClass}>
                  <p style={{ marginBottom: '7px' }}>{countryDialCode}</p>
                  <TextField
                    style={{ marginLeft: '5px' }}
                    name="phone"
                    fullWidth
                    id="phone"
                    label="Phone Number *"
                    autoComplete="phone"
                    value={phone}
                    helperText={touched.phone ? errors.phone : ""}
                    error={touched.phone && Boolean(errors.phone)}
                    onChange={change.bind(null, "phone")}
                    onBlur={change.bind(null, "phone")}
                  />
                </div>
              </div>
              <div className={classes.halfWidth}>
                <div className={classes.mobileClass}>
                  <TextField
                    name="email"
                    fullWidth
                    style={{ marginLeft: '5px' }}
                    id="email"
                    label="Email *"
                    autoComplete="email"
                    value={email}
                    onChange={change.bind(null, "email")}
                    onBlur={change.bind(null, "email")}
                    helperText={touched.email ? errors.email : ""}
                    error={touched.email && Boolean(errors.email)}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      case 1:
        return <div>
          <div className={classes.formGroup}>
            <TextField
              className={classes.fullWidthInput}
              fullWidth
              autoFocus
              name="password"
              label="Choose a Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              value={password}
              helperText={touched.password ? errors.password : ""}
              error={touched.password && Boolean(errors.password)}
              onChange={change.bind(null, "password")}
              onBlur={change.bind(null, "password")}
              InputProps={{
                endAdornment:
                  <InputAdornment className={classes.cursorPointer} position="end" onClick={() => { setShowPassword(!showPassword) }}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </InputAdornment>
              }}
            />
          </div>
          <div className={classes.formGroup}>
            <TextField
              className={classes.fullWidthInput}
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              id="confirmPassword"
              autoComplete="current-password"
              value={confirmPassword}
              helperText={touched.confirmPassword ? errors.confirmPassword : ""}
              error={touched.confirmPassword && Boolean(errors.confirmPassword)}
              onChange={change.bind(null, "confirmPassword")}
              onBlur={change.bind(null, "confirmPassword")}
              InputProps={{
                endAdornment:
                  <InputAdornment className={classes.cursorPointer} position="end" onClick={() => { setShowConfirmPassword(!showConfirmPassword) }}>
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </InputAdornment>
              }}
            />
          </div>
          <div className={classes.formGroup}>
            <FormGroup row className={classes.alignItemsCenter} style={{ width: '100%' }} >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedTC}
                    name="checkedTC"
                    id="checkedTC"
                    onChange={change.bind('checkedTC', !checkedTC)}
                    helperText={touched.checkedTC ? errors.checkedTC : ""}
                    error={touched.checkedTC && Boolean(errors.checkedTC)}
                  />
                }
              />
              <label style={{ width: "75%" }}>I accept the <span className={classes.cursor} onClick={() => changeTC(false)}>Terms and Conditions</span></label>
              {errors.checkedTC && touched.checkedTC && (
                <p className="MuiFormHelperText-root Mui-error"><br />{errors.checkedTC}</p>
              )}
            </FormGroup>
          </div>


        </div>;
      default:
        return 'Unknown step';
    }
  }
  let USStatesList = [], CanadaStatesList = []

  let availableStates = {
    "US": CONFIGUSSTATESLIST,
    "Canada": CONFIGCANADASTATESLIST
  }
  if (availableStates) {
    USStatesList = (availableStates.US).map((availablestate, key) =>
      <MenuItem value={availablestate.name} key={key}>{availablestate.name}</MenuItem>
    )
    CanadaStatesList = (availableStates.Canada).map((availablestate, key) =>
      <MenuItem value={availablestate.name} key={key}>{availablestate.name}</MenuItem>
    )
  }
  return (
    <form className={classes.root + " notLoggedInPages"}>
      <ReCaptcha
        ref={recaptcha}
        sitekey={RECAPTCHASITEKEY}
        verifyCallback={verifyCallback}
      />
      <div className={classes.signupIcon}>
        <img className={classes.signupIconImg} src={SignupIcon} alt={"SignUP Icon"} />
      </div>
      <h2 className={classes.title}>CREATE AN ACCOUNT</h2>
      {authError ? <h4 className="red-text-center">{authError}</h4> : null}
      <Stepper activeStep={activeStep} className={classes.stepperWrapper} orientation="vertical">
        {steps.map((label, index) => {
          label = label.replace('**TYPE**', type === 'Agency' ? 'Agency' : 'Pharmacy')
          return <Step key={label}>
            <StepLabel className={classes.label + " label"}>{label}</StepLabel>
            <StepContent>
              {getStepContent(index)}
              <div className={classes.actionsContainer}>
                <div>
                  {activeStep !== 0 &&
                    <Button
                      disabled={activeStep === 0}
                      onClick={() => handleBack(activeStep)}
                      className={classes.button}
                    >
                      Back
                  </Button>
                  }
                  {activeStep !== steps.length - 1 && <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleNext(activeStep)}
                    className={classes.button}
                  >
                    Next
                  </Button>}
                </div>
              </div>
            </StepContent>
          </Step>
        })}
      </Stepper>
      <div className={classes.bottomStepper}>
        {/* add disbaled prop as cleint changed it 3/10/2020 */}
        <Button
          type="button"
          className={classes.fullWidthButton}
          variant="contained"
          fullWidth
          id="submitForm"
          color="secondary"
          disabled={disabled}
          onClick={clickSubmit}
        >
          {loading && <CircularProgress size={21} />}
          {!loading && 'Sign Up'}
        </Button>
      </div>
      <Modal
        visible={showTCModal}
        width={800}
        onCancel={() => changeTC(false)}
        footer={
          <div>
            <Button color="" variant="contained" onClick={() => changeTC(true)}>ACCEPT</Button>
          </div>
        }
      >
        <TC />
      </Modal>
    </form>
  );
}


const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    availableStates: state.general.states,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (newUser) => dispatch(signUp(newUser)),
    getSpecialities: () => dispatch(getSpecialities()),
    getStates: () => dispatch(getStates()),
    resetState: () => dispatch({
      type: "RESET_STATE",
    }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StepperForm)
