import React, { Component } from 'react'
import { connect } from 'react-redux'
import withStyles from '@material-ui/styles/withStyles'
import { Checkbox, FormControlLabel, Grid, Radio, Button, CircularProgress, TextField } from '@material-ui/core'
import Functions from '../../assets/js/function'
import { Modal } from 'antd'
import { Redirect } from 'react-router-dom'

const styles = theme => ({
    font: {
        fontFamily: 'Roboto Regular',
    },
    popup: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    popupDiv: {
        backgroundColor: '#fff',
        padding: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: '2rem',
        width: '70%',
        [theme.breakpoints.down('sm')]: { width: '100%', padding: theme.spacing(2) }
    },
    heading: {
        fontFamily: 'inherit',
        fontWeight: 600,
        color: '#383636',
        fontSize: '1rem',
        paddingTop: theme.spacing(3),
        display: 'flex'
    },
    gridBorder: {
        textAlign: 'left'
    },
    symptoms: {
        display: 'flex',
        flexWrap: 'wrap',
        border: '1px solid #dfdfdf',
        padding: '15px',
        [theme.breakpoints.down('sm')]: { flexDirection: 'column', }
    },
    symptomItem: {
        flexBasis: '30%'
    },
    button: {
        marginTop: theme.spacing(5),
        padding: theme.spacing(1, 7)
    }
})


class Covid19Form extends Component {
    constructor(props) {
        super(props)
        let appointmentTime = props.match.params.appointmentTime
        let date = new Date(appointmentTime * 1000)
        this.state = {
            userId: props.auth.uid,
            loader: false,
            showModal: false,
            redirectTo: '',
            form: {
                patient_name: props.account.fullName,
                year: date.getFullYear().toString(),
                month: (date.getMonth() + 1).toString(),
                day: date.getDate().toString(),
                fever: false,
                loss_taste_smell: false,
                new_cough: false,
                chills: false,
                chronic_cough: false,
                headache: false,
                sob: false,
                muscle_aches: false,
                throat: false,
                nausea: false,
                nose: false,
                pink_eye: false,
                referral: '',
                symptoms: { radio: '' },
                travel: { radio: '' },
                contact: { radio: '' },
                exposure: { radio: '' },
                app: { radio: '' },
                age: { radio: '' },
            },
            appointmentTime: appointmentTime
        }
    }

    handleCheckBoxChange = (e) => {
        let formData = this.state.form
        if (formData[e.currentTarget.name].hasOwnProperty('radio'))
            formData[e.currentTarget.name] = { radio: e.target.value }
        else
            formData[e.currentTarget.name] = !this.state.form[e.currentTarget.name]

        this.setState({ form: formData })
    }

    handleReferralChange = (e) => {
        let formData = this.state.form
        formData['referral'] = e.target.value

        this.setState({ form: formData })
    }

    saveCovidForm = async () => {
        this.setState({ loader: true })
        await Functions.CreateRequisition(JSON.stringify({ userId: this.state.userId, form: this.state.form, appointmentTime: this.state.appointmentTime }))
        this.setState({ loader: false, showModal: true })
    }

    modalClick = () => {
        this.setState({ redirectTo: '/dashboard' })
    }

    render() {
        const { classes } = this.props;
        if (this.state.redirectTo !== '') {
            return <Redirect to={this.state.redirectTo} />;
        }
        return (

            <Grid className={classes.font}>
                <Grid className={classes.popup}>
                    <Grid className={classes.popupDiv}>
                        <Grid className={classes.gridBorder}>
                            <Grid>
                                <label className={classes.heading}>Are you experience any of the following symptoms?</label>
                                <Grid>
                                    <FormControlLabel
                                        control={<Radio value="yes" checked={this.state.form.symptoms.radio === 'yes' ? true : false} onChange={this.handleCheckBoxChange} name="symptoms" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        control={<Radio value="no" checked={this.state.form.symptoms.radio === 'no' ? true : false} onChange={this.handleCheckBoxChange} name="symptoms" />}
                                        label="No"
                                    />
                                </Grid>
                                <Grid className={classes.symptoms}>
                                    <Grid className={classes.symptomItem}>
                                        <FormControlLabel
                                            disabled={this.state.form.symptoms.radio === 'yes' ? false : true}
                                            control={<Checkbox checked={this.state.form.fever} onChange={this.handleCheckBoxChange} name="fever" />}
                                            label="Fever"
                                        />
                                    </Grid>
                                    <Grid className={classes.symptomItem}>
                                        <FormControlLabel
                                            disabled={this.state.form.symptoms.radio === 'yes' ? false : true}
                                            control={<Checkbox checked={this.state.form.loss_taste_smell} onChange={this.handleCheckBoxChange} name="loss_taste_smell" />}
                                            label="New loss of taste or smell"
                                        />
                                    </Grid>
                                    <Grid className={classes.symptomItem}>
                                        <FormControlLabel
                                            disabled={this.state.form.symptoms.radio === 'yes' ? false : true}
                                            control={<Checkbox checked={this.state.form.new_cough} onChange={this.handleCheckBoxChange} name="new_cough" />}
                                            label="New onset of cough"
                                        />
                                    </Grid>
                                    <Grid className={classes.symptomItem}>
                                        <FormControlLabel
                                            disabled={this.state.form.symptoms.radio === 'yes' ? false : true}
                                            control={<Checkbox checked={this.state.form.chills} onChange={this.handleCheckBoxChange} name="chills" />}
                                            label="Chills"
                                        />
                                    </Grid>
                                    <Grid className={classes.symptomItem}>
                                        <FormControlLabel
                                            disabled={this.state.form.symptoms.radio === 'yes' ? false : true}
                                            control={<Checkbox checked={this.state.form.chronic_cough} onChange={this.handleCheckBoxChange} name="chronic_cough" />}
                                            label="Worsening chronic cough"
                                        />
                                    </Grid>
                                    <Grid className={classes.symptomItem}>
                                        <FormControlLabel
                                            disabled={this.state.form.symptoms.radio === 'yes' ? false : true}
                                            control={<Checkbox checked={this.state.form.headache} onChange={this.handleCheckBoxChange} name="headache" />}
                                            label="Headache"
                                        />
                                    </Grid>
                                    <Grid className={classes.symptomItem}>
                                        <FormControlLabel
                                            disabled={this.state.form.symptoms.radio === 'yes' ? false : true}
                                            control={<Checkbox checked={this.state.form.sob} onChange={this.handleCheckBoxChange} name="sob" />}
                                            label="Shortness of breath/difficulty breathing"
                                        />
                                    </Grid>
                                    <Grid className={classes.symptomItem}>
                                        <FormControlLabel
                                            disabled={this.state.form.symptoms.radio === 'yes' ? false : true}
                                            control={<Checkbox checked={this.state.form.muscle_aches} onChange={this.handleCheckBoxChange} name="muscle_aches" />}
                                            label="Muscle or body aches"
                                        />
                                    </Grid>
                                    <Grid className={classes.symptomItem}>
                                        <FormControlLabel
                                            disabled={this.state.form.symptoms.radio === 'yes' ? false : true}
                                            control={<Checkbox checked={this.state.form.throat} onChange={this.handleCheckBoxChange} name="throat" />}
                                            label="Sore throat"
                                        />
                                    </Grid>
                                    <Grid className={classes.symptomItem}>
                                        <FormControlLabel
                                            disabled={this.state.form.symptoms.radio === 'yes' ? false : true}
                                            control={<Checkbox checked={this.state.form.nausea} onChange={this.handleCheckBoxChange} name="nausea" />}
                                            label="Nausea or vomiting"
                                        />
                                    </Grid>
                                    <Grid className={classes.symptomItem}>
                                        <FormControlLabel
                                            disabled={this.state.form.symptoms.radio === 'yes' ? false : true}
                                            control={<Checkbox checked={this.state.form.swallowing} onChange={this.handleCheckBoxChange} name="swallowing" />}
                                            label="Difficulty swallowing"
                                        />
                                    </Grid>
                                    <Grid className={classes.symptomItem}>
                                        <FormControlLabel
                                            disabled={this.state.form.symptoms.radio === 'yes' ? false : true}
                                            control={<Checkbox checked={this.state.form.pink_eye} onChange={this.handleCheckBoxChange} name="pink_eye" />}
                                            label="Pink eye"
                                        />
                                    </Grid>
                                    <Grid className={classes.symptomItem}>
                                        <FormControlLabel
                                            disabled={this.state.form.symptoms.radio === 'yes' ? false : true}
                                            control={<Checkbox checked={this.state.form.nose} onChange={this.handleCheckBoxChange} name="nose" />}
                                            label="Runny nose or nasal congestion"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid>
                                <label className={classes.heading}>In the past 14 days, did you return from travel outside of Canada?</label>
                                <Grid>
                                    <FormControlLabel
                                        control={<Radio value="yes" checked={this.state.form.travel.radio === 'yes' ? true : false} onChange={this.handleCheckBoxChange} name="travel" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        control={<Radio value="no" checked={this.state.form.travel.radio === 'no' ? true : false} onChange={this.handleCheckBoxChange} name="travel" />}
                                        label="No"
                                    />
                                </Grid>
                            </Grid>

                            <Grid>
                                <label className={classes.heading}>In the past 14 days, have you been identified as a "close contact" of someone who currently has COVID-19?</label>
                                <Grid>
                                    <FormControlLabel
                                        control={<Radio value="yes" checked={this.state.form.contact.radio === 'yes' ? true : false} onChange={this.handleCheckBoxChange} name="contact" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        control={<Radio value="no" checked={this.state.form.contact.radio === 'no' ? true : false} onChange={this.handleCheckBoxChange} name="contact" />}
                                        label="No"
                                    />
                                </Grid>
                            </Grid>

                            <Grid>
                                <label className={classes.heading}>Have you been advised to get tested for COVID-19 by your local public health unit due to exposure to a confirmed case or as a part of an outbreak investigation?</label>
                                <Grid>
                                    <FormControlLabel
                                        control={<Radio value="yes" checked={this.state.form.exposure.radio === 'yes' ? true : false} onChange={this.handleCheckBoxChange} name="exposure" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        control={<Radio value="no" checked={this.state.form.exposure.radio === 'no' ? true : false} onChange={this.handleCheckBoxChange} name="exposure" />}
                                        label="No"
                                    />
                                </Grid>
                            </Grid>

                            <Grid>
                                <label className={classes.heading}>Have you been advised to get tested for COVID-19 through an exposure notification through the COVID-19 alert app?</label>
                                <Grid>
                                    <FormControlLabel
                                        control={<Radio value="yes" checked={this.state.form.app.radio === 'yes' ? true : false} onChange={this.handleCheckBoxChange} name="app" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        control={<Radio value="no" checked={this.state.form.app.radio === 'no' ? true : false} onChange={this.handleCheckBoxChange} name="app" />}
                                        label="No"
                                    />
                                </Grid>
                            </Grid>

                            <Grid>
                                <label className={classes.heading}>Are you over the age of 70 and experiencing any of the following: delirium, unexplained or increased number of falls, acute functional decline, and/or worsening chronic conditions?</label>
                                <Grid>
                                    <FormControlLabel
                                        control={<Radio value="yes" checked={this.state.form.age.radio === 'yes' ? true : false} onChange={this.handleCheckBoxChange} name="age" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        control={<Radio value="no" checked={this.state.form.age.radio === 'no' ? true : false} onChange={this.handleCheckBoxChange} name="age" />}
                                        label="No"
                                    />
                                </Grid>
                            </Grid>

                            <Grid>
                                <label className={classes.heading}>If you were referred by a a travel agent, please type your referral</label>
                                <Grid>
                                    <TextField
                                        value={this.state.form.referral}
                                        onChange={(e) => this.handleReferralChange(e)}
                                        onBlur={(e) => this.handleReferralChange(e)}
                                        label="Referral"
                                        // style={{ marginBottom: '24px' }}
                                        // autoFocus
                                        InputProps={{
                                            // disableUnderline: true
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid>
                                <Button color="secondary" className={classes.button} variant="contained" onClick={() => this.saveCovidForm()}>{this.state.loader ? <CircularProgress size={24} /> : "Save"}</Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
                <Modal
                    title={"Covid Form Created"}
                    visible={this.state.showModal}
                    width={400}
                    closable={false}
                    footer={
                        <Grid>
                            <Button color="secondary" variant="contained" onClick={this.modalClick}>OK</Button>
                        </Grid>
                    }
                >
                    <Grid className={classes.modal}>
                        <p>Form Submitted Successfully</p>
                    </Grid>
                </Modal>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        account: state.account.data
    };
};
const mapDispatchToProps = (dispatch) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Covid19Form))