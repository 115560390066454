import { APIURL, WEBSITEURL } from '../../config.js'
import functions from '../../assets/js/function'

export const getSearchedDoctors = async (search) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = JSON.stringify(search);
    return fetch(APIURL + '/searchDoctors', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            type: 'SEARCH_DOCTOR_SUCCESS',
            data: data.data,
            message: '',
            totalRecords: data.totalResults,
          }
        }
        else {
          return {
            type: 'SEARCH_DOCTOR_ERROR',
            message: data.message
          }
        }
      })
      .catch(error => {
        return {
          type: 'SEARCH_DOCTOR_ERROR',
          payload: error
        }
      });
  }
  else {
    return ''
  }
}

export const getDoctorAvailability = async (search) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = JSON.stringify(search);
    return fetch(APIURL + '/getDoctorAvailability', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            data: data.data,
          }
        }
        else {
          return {
            data: [],
          }
        }
      })
      .catch(error => {
        return {
          data: [],
        }
      });
  }
  else {
    return ''
  }
}
export const verifyHC = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data;
    return fetch(APIURL + '/verifyHealthCard', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            status: 1,
          }
        }
        else {
          return {
            status: 0,
            message: data.message
          }
        }
      })
      .catch(error => {
        return {
          status: 0,
          message: error.message
        }
      });
  }
  else {
    return 0
  }
}

export const getSearchedFiltersData = async (search) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = JSON.stringify(search);
    return fetch(APIURL + '/getFiltersData', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            data: data.data,
          }
        }
        else {
          return {
            data: [],
          }
        }
      })
      .catch(error => {
        return {
          data: [],
        }
      });
  }
  else {
    return ''
  }
}

export const getAvailabilityTime = async (id, time) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = JSON.stringify({ doctorId: id, date: time });
    let response = await fetch(APIURL + '/getAvailabilityTime', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })

    response = await response.json()
    if (response.status === 1) {
      return {
        type: 'GET_AVAILABILITY_TIME_SUCCESS',
        data: response.data,
        message: response.message
      }
    }
    else {
      return {
        type: 'GET_AVAILABILITY_TIME_SUCCESS',
        message: response.message
      }
    }
  } else {
    return ''
  }
}
export const getSymptomsList = () => {
  return (dispatch, getState) => {
    fetch(WEBSITEURL + '/symptoms.json')
      .then(response => response.json())
      .then(symptoms => {
        dispatch({
          type: 'UPDATE_SYMPOTEMS',
          symptoms: symptoms.symptoms
        })
      })
  }
}
export const updateAppointment = (data) => {
  return async (dispatch, getState) => {
    let firebaseToken = await functions.getIdToken()
    if (firebaseToken !== '') {
      let jsonmap = JSON.stringify(data);
      fetch(APIURL + '/updateAppointment', {
        method: 'POST',
        async: false,
        body: jsonmap,
        headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 1) {
            dispatch({
              type: 'APPOINTMENT_ADDED',
              message: data.message
            })
          }
          else {
            dispatch({
              type: 'APPOINTMENT_ADDED',
              message: data.message
            })
          }
        })
        .catch(error => {
          dispatch({
            type: 'APPOINTMENT_ADDED',
            payload: error
          })
        });
    } else {
      return ''
    }
  }
}

export const getAppointments = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = JSON.stringify(data);
    return fetch(APIURL + '/getAppointment', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            status: data.status,
            data: data.data,
            isWorkWeek: data.isWorkWeek,
            excludedDays: data.excludedDays,
            message: '',
          }
        }
        else {
          return {
            status: data.status,
            message: data.message,
            isWorkWeek: 0,
            excludedDays: [],
            data: '',
          }
        }
      })
      .catch(error => {
        return {
          status: data.status,
          error: error.message,
          isWorkWeek: 0,
          excludedDays: [],
          data: '',
        }
      });
  } else {
    return ''
  }

}


export const getDoctorsListForAppointment = async (val) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = JSON.stringify({ doctorName: val });
    return fetch(APIURL + '/getDoctorsListForAppointment', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        return data.data
      })
      .catch(error => {
        return []
      });
  } else {
    return ''
  }
}


export const getProviderList = () => {
  return async (dispatch, getState) => {
    let firebaseToken = await functions.getIdToken()
    if (firebaseToken !== '') {
      let jsonmap = '';
      fetch(APIURL + '/getProviderList', {
        method: 'POST',
        async: false,
        body: jsonmap,
        headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
      })
        .then(response => response.json())
        .then(data => {
          dispatch({
            type: 'PROVIDER_LIST',
            data: data.data
          })
        })
        .catch(error => {
          dispatch({
            type: 'PROVIDER_LIST',
            data: []
          })
        });
    } else {
      return ''
    }
  }
}

export const updateAppointmentStatus = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let response = await fetch(APIURL + '/updateAppointmentStatus', {
      method: 'POST',
      async: false,
      body: data,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
    response = await response.json()
    return {
      status: response.status,
      data: response.data,
    }
  } else {
    return {
      status: 0,
      data: [],
    }
  }
}

export const updateRhynoGoTime = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let response = await fetch(APIURL + '/updateRhynoGoTime', {
      method: 'POST',
      async: false,
      body: data,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
    response = await response.json()
    return {
      status: response.status
    }
  }
  else {
    return {
      status: 0
    }
  }
}

export const updateRhynoGoStatus = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let response = await fetch(APIURL + '/updateRhynoGoStatus', {
      method: 'POST',
      async: false,
      body: data,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
    response = await response.json()
    return {
      status: response.status,
      data: response.data,
    }
  } else {
    return {
      status: 0,
      data: [],
    }
  }
}

export const checkRhynoGoStatus = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let response = await fetch(APIURL + '/checkRhynoGoStatus', {
      method: 'POST',
      async: false,
      body: data,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
    response = await response.json()
    return {
      status: response.status,
      appointmentStatus: response.appointmentStatus,
    }
  } else {
    return {
      status: 0,
      appointmentStatus: 0,
    }
  }
}

export const getPatientsList = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let response = await fetch(APIURL + '/getPatientsList', {
      method: 'POST',
      async: false,
      body: data,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
    response = await response.json()
    return {
      status: response.status,
      data: response.data,
    }
  } else {
    return {
      status: 0,
      data: [],
    }
  }
}

// GET APPOINTMENT DETAIL
export const getAppointmentDetail = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let response = await fetch(APIURL + '/getAppointmentDetail', {
      method: 'POST',
      async: false,
      body: data,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
    response = await response.json()
    return {
      status: response.status,
      data: response.data,
    }
  } else {
    return {
      status: 0,
      data: [],
    }
  }
}

export const updateAppointmentDataAPI = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let response = await fetch(APIURL + '/updateAppointmentData', {
      method: 'POST',
      async: false,
      body: data,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
    response = await response.json()
    return {
      data: response.data,
    }
  } else {
    return ''
  }
}

export const updateAppointmentDuration = (data) => {
  return async (dispatch, getState) => {
    let firebaseToken = await functions.getIdToken()
    if (firebaseToken !== '') {
      let jsonmap = data
      fetch(APIURL + '/updateAppointmentDuration', {
        method: 'POST',
        async: false,
        body: jsonmap,
        headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
      })
        .then(response => response.json())
        .then(data => {
          dispatch({
            type: 'APPOINTMENT_LIST',
            data: data.data,
            appointmentType: 'schedule'
          })
        })
        .catch(error => {
          dispatch({
            type: 'APPOINTMENT_LIST',
            data: [],
            appointmentType: 'schedule'
          })
        });
    } else {
      return ''
    }
  }
}
