import { Button, CircularProgress } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import VideocamIcon from '@material-ui/icons/Videocam'
import { Chat as ChatUI } from '@progress/kendo-react-conversational-ui'
import { Client as ConversationsClient ,Conversation} from "@twilio/conversations"
import React, { Component } from 'react'
import { isMobileOnly } from 'react-device-detect'
import FileIcon, { defaultStyles } from "react-file-icon"
import Chat from 'twilio-chat'
import { APIURL } from '../../config.js'

 
function MessageTemplate(props, user,otherUserId) {
  console.log("props ",props)
  let pattern = /^###########/
  if(!pattern.test(props?.item?.text?.props?.children||'')){
    return (<div> 
      {  
        <div className="k-bubble">
          <div style={{ marginLeft: props.item.author.id === user.id ? 'auto' : "0" }}>
            {(props.item.type === "media")?(<>
              {/* <Image
              width={200}
              src={props.item.url}   
            /> */}
            <img  src={props.item.url}  alt="loading...." width="300" height="auto"></img>
            </>):(<>{props.item.text}</>) } 
            
            
            </div>
        </div>
        }
    </div>
    )
  }
  else{
    let message = props?.item?.text?.props?.children+'';
    console.log('message',message)
    message = message.replaceAll('##################','');
    console.log('message',message)

     return(<>
     <div> 
      
      <div className="k-bubble k-systemMessage" >
        <div style={{ margin: 'auto' }}>{message}</div>
  </div>
      
   </div>
   </>)
  }

}
class ChatApp extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      error: null,
      isLoading: false,
      completeLoaded: false,
      messages: [],
      twillioMessages: [],
      appointmentId: props.AppointmentId,
      chatName: 'chat_' + props.doctorId + '_' + props.userId + '_' + props.AppointmentId,
      role: props.role,
      meetingId: props.meetingId ? props.meetingId : '',
      videoStarted: false,
      twillioConversatioToken:'',
      statusString: null,
      conversationsReady: false,
      conversations: [],
      selectedConversationSid: null,
      newMessage: "",
      loadingState: 'initializing',
      boundConversations: new Set()
    };

    if(props.role === 'Doctor')
    {
      this.user = {
        id: props.doctorId+props.AppointmentId,
        name: props.username,
      };
      this.otherUser = props.doctorName
      this.otherUserId= props.userId
  
    }else{
      this.user = {
        id: props.userId+props.AppointmentId,
        name: props.username,
      };
        this.otherUser = props.doctorName
        this.otherUserId= props.doctorId
  
    }

    this.setupChatClient = this.setupChatClient.bind(this);
    this.messagesLoaded = this.messagesLoaded.bind(this);
    this.messageAdded = this.messageAdded.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  componentDidMount() {
    
    this.props.onRef(this)
    

    fetch(APIURL + '/chat/token', {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      method: 'POST',
      body: `identity=${encodeURIComponent(this.props.username)}`
    })
      .then(res => res.json())
      .then(data => Chat.create(data.token))
      .then(this.setupChatClient)
      .catch(this.handleError);

    this.CreatingTwillioConversation()
    this.twillioConversationToken()
     
  }

  //SkypeConversation 30-May 2:52 AM
  // The chat scrolls automatically when the other member sends a message but for the same person,
  // it does not scroll down once a message is sent.
  // The chat should scroll down to the last message automatically when a message is sent.
  // Dev : added this function and call this on when new message loaded on componentdidupdate function
  // and when the messagesend function is called 
   scrollToBottom() {
     console.log("scroll hit")
    const messageList = document.querySelector('.k-message-list');
    console.log("messageList",messageList )
    if (messageList) {
      messageList.scroll(0, messageList.scrollHeight)
    }
  }
  //CreateingTwillioConversation:
  async CreatingTwillioConversation(){
 
      console.log('*** CreateingTwillioConversation hit ***' )
      let conversationName = this.props.doctorId + this.props.userId + this.props.AppointmentId
      let patientId = this.props.userId + this.props.AppointmentId
      let doctorId = this.props.doctorId + this.props.AppointmentId
      fetch(APIURL+'/chat/createConversation',{
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ patientId:patientId, doctorId:doctorId, conversationName:conversationName})
      })
      .then(response => response.json())
      .then(data => { 
        {console.log("===>",data);
      
    }});
   
  }

  //Create twillioConversationToken 
  twillioConversationToken() {
   

    fetch(APIURL+'/chat/twilliotoken',{
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ Id: this.user.id,UserId:this.otherUserId})
    })
    .then(response => response.json())
    .then(data => {
      console.log("token response data ",data)
      this.otherUser =data.user
      this.setState({twillioConversatioToken:data.token},this.initConversations)});
  }
  
  //twillio Conversation Handler
  initConversations = async () => {
    try{
    console.log('initConversations hit : ',this.state.twillioConversatioToken)
    // window.conversationsClient = ConversationsClient;
    console.log('1')
     this.conversationsClient = new ConversationsClient(this.state.twillioConversatioToken);
     console.log('2')
     this.setState({ statusString: "Connecting to Twilio… " });
     console.log('3',this.conversationsClient)
     this.conversationsClient.on("connectionStateChanged", (state) => {
       console.log("state :",state)
       if (state === "connecting")
         this.setState({
           statusString: "Connecting to Twilio…",
           status: "default"
         });
       if (state === "connected") {
         this.setState({
           statusString: "You are connected.",
           status: "success"
         });
       }
       if (state === "disconnecting")
         this.setState({
           statusString: "Disconnecting from Twilio…",
           conversationsReady: false,
           status: "default"
         });
       if (state === "disconnected")
         this.setState({
           statusString: "Disconnected.",
           conversationsReady: false,
           status: "warning"
         });
       if (state === "denied")
         this.setState({
           statusString: "Failed to connect.",
           conversationsReady: false,
           status: "error"
         });
     });
     console.log('4')
     this.conversationsClient.on("conversationJoined", (conversation) => {
      console.log("conversation",conversation)
        let conversationName = this.props.doctorId + this.props.userId + this.props.AppointmentId
        if(conversation.channelState.friendlyName === conversationName){
           this.loadMessagesFor(conversation)
          this.setState({ conversations: conversation })
        }
      //  this.setState({ conversations: [...this.state.conversations, conversation] });
     });
     console.log('5')
     this.conversationsClient.on("conversationLeft", (thisConversation) => {
   
       this.setState({
         conversations: [...this.state.conversations.filter((it) => it !== thisConversation)]
       });
     });
    }catch(error){
      console.log("ERROR in init conversation Function : ",error.message)
    }
   };

  handleError(error) {
    console.error(error);
    this.setState({
      error: 'Could not load chat.'
    });
  }

  loadMessagesFor = (thisConversation) => {

        thisConversation.getMessages()
            .then(async(messagePaginator) => {
              console.log("messagePaginator",messagePaginator)
             
              for(let i=0; i<messagePaginator.items.length||1;i++){
                
                let mapItem =messagePaginator.items[i]
                let url =''
                if(mapItem.state.type === 'media'){
                  const responce = await  mapItem.media.getContentTemporaryUrl()
                  url = responce;
                }
                let pattern = /^###########/
              
                this.setState(prevState => ({
                  twillioMessages: [
                    ...prevState.twillioMessages,
                    {
                      url:url,
                      type:mapItem.state.type|| '',
                      text: <p>{mapItem.state.body|| '-'}</p>,
                      // author: { id: mapItem.state.author||'', name: mapItem.state.author||'' },
                      author: { 
                        id:  pattern.test(mapItem.state.body)?'':(mapItem.state.author||''),
                      //  name: (mapItem.state.author === this.props.userId)?this.props.username:'otherUser' },
                      name: pattern.test(mapItem.state.body)?'':((mapItem.state.author === this.user.id)?this.props.username:this.otherUser) },
                      timestamp: mapItem.state.timestamp || 'no time',
                    }
                  ]
                }));

             
              }
              // Promise.all(messagePaginator.items.map(async(mapItem)=>{ }))
              console.log("state message :",this.state.twillioMessages)
                    // this.setState({ twillioMessages: messagePaginator.items, loadingState: 'ready' });
            })
            .catch(err => {
                console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
                this.setState({ loadingState: "failed" });
            });

          //   //extra
          //   if (!this.state.boundConversations.has(this.state.conversations)) {
          //     let newConversation = this.state.conversations;
          //     newConversation.on('messageAdded', m => this.messageAdded(m, newConversation));
          //     this.setState({boundConversations: new Set([...this.state.boundConversations, newConversation])});
          // }
    };
      // adding new messages to previous message
  messageAdded = async(message, targetConversation) => {
    let pattern = /^###########/
    console.log("messageAdded",message,targetConversation)
    let url =''
    if(message.state.type === 'media'){
      const responce = await  message.media.getContentTemporaryUrl()
      console.log("responce media ",responce)
      url = responce;
    }
    this.setState(prevState => ({
      twillioMessages: [
        ...prevState.twillioMessages,
        {
          url:url,
          type:message.state.type|| '',
          text: <p>{message.state.body|| '-'}</p>,
          // author: { id: message.state.author||'', name: message.state.author||'' },
          author: { id: pattern.test(message.state.body)?'':(message.state.author||''),
          name: pattern.test(message.state.body)?'':((message.state.author === this.user.id)?this.props.username:this.otherUser) },
          timestamp: message.state.timestamp || 'no time',
        }
      ]
    }));


  };

  sendMessage = async (event) => {
     console.log('event.message.text',event.message.text)
    console.log('event.message.text ',event)
    const message = event.message.text;
   this.state.conversations.sendMessage(event.message.text);
   console.log("previos messages:",this.state.twillioMessages)
     //SkypeConversation 30-May 2:52 AM
  // The chat scrolls automatically when the other member sends a message but for the same person,
  // it does not scroll down once a message is sent.
  // The chat should scroll down to the last message automatically when a message is sent.
  // Dev : added this function and call this on when new message loaded on componentdidupdate function
  // and when the messagesend function is called 
   this.scrollToBottom()
  };

  setupChatClient(client) {
    this.client = client;
    this.client
      .getChannelByUniqueName(this.state.chatName)
      .then(channel => channel)
      .catch(error => {
        if (error.body.code === 50300) {
          return this.client.createChannel({ uniqueName: this.state.chatName });
        } else {
          this.handleError(error);
        }
      })
      .then(channel => {
        this.channel = channel;
        return this.channel.join().catch(() => { });
      })
      .then(() => {
        let channelId = this.channel.sid
        let memberId = ''

        this.client.getUserChannelDescriptors().then(function (paginator) {
          for (let i = 0; i < paginator.items.length; i++) {
            var channelItem = paginator.items[i];
            if (channelItem.sid === channelId) {
              console.table('Channel: ', channelItem.descriptor.member_sid);
              memberId = channelItem.descriptor.member_sid
            }

          }
        });

        setTimeout(() => {
          let map = this.channel.membersEntity.members
          let noOfUsers = map._c.size
          console.log(noOfUsers)
          if (noOfUsers === 0)
            noOfUsers = 1
          this.props.updateRoomMembersCount(noOfUsers)

          this.props.updateDataForChannelMember({ channel_id: channelId, member_id: memberId, appointment_id: this.props.AppointmentId })

        }, 1000)

        this.channel.getMessages().then(this.messagesLoaded);
        this.channel.on('messageAdded', this.messageAdded);
        this.channel.on('memberJoined', (member) => {
          this.props.updateRoomMembersCount(2)
          console.log({ body: `${member.identity} has joined the channel.` })
        })
        this.channel.on('memberLeft', (member) => {
          this.props.endPatientSession()
          console.log(member.identity + 'has left the channel.');
        })
      })
      .catch(this.handleError);

    this.client.on("tokenAboutToExpire", async () => {
      console.log('tokenAboutToexpire')
    });

    this.client.on("tokenExpired", async () => {
      console.log('tokenExpired')
    });
  }
  async twilioMessageToKendoMessage(message) {

    if (message.type === 'media') {
    }
    else {
      return {
        text: message.body,
        author: { id: message.author, name: message.author },
        timestamp: message.timestamp,
      };
    }
  }
  updateMeetingId = (accountType) => {
    // this.setState({ meetingId: meetingId })
    // SKYPE CONVERSATION
    // G-Man, 7:50 AM
    // @Muhammad When patient clicks on video option can you immediately display the video? Currently patient has to click on video icon to show the video conference but that is an extra step. Also, please change the message to remove 'click on the video account...'
    // IF SPEAK OPTION IS VIDEO THEN OPEN THE ZOOM MEETING
    if (accountType === 'Patient') {
      this.props.startVideoCall()
      this.setState({ videoStarted: false })
    }
  }
  sendSystemMessage = (message) => {
      //  this.channel.sendMessage("##################" + message + "##################");
   
   this.state.conversations.sendMessage("##################" + message + "##################");
  }
  updateAttachments = async () => {
    let urlsList = []
    console.log("message hit -----  ")
    for (let message of this.state.twillioMessages) {
      console.log(message)
      if (message.type === 'media') {
          urlsList.push({ name: message.timestamp, url: message.url, contentType: message.media })
      }
    }
     this.props.updateAppointmentAttachments(urlsList)
  }
  // updateAttachments = async () => {
  //   let urlsList = []
  //   for (let message of this.channel.messagesEntity.messagesByIndex._c) {
  //     if (message.state.type === 'media') {
  //       await message.media.getContentUrl().then(function (url) {
  //         urlsList.push({ name: message.media.state.filename, url: url, contentType: message.media.state.contentType })
  //       })
  //     }
  //   }
  //   this.props.updateAppointmentAttachments(urlsList)
  // }

  async messagesLoaded(messagePage) {
    // console.log('messages loaded')
    let messagesList = []
    for (let i = 0; i < messagePage.items.length; i++) {
      let message = messagePage.items[i]
      if (message.body !== null && (message.body).indexOf('##################') === 0) {
        let body = message.body
        body = body.replace('##################', '')
        body = body.replace('##################', '')
        messagesList.push({
          text: <p>{body}</p>,
          author: { id: '', name: '' },
          timestamp: 0,
        })
      }
      else
        if (message.body !== null)
          messagesList.push({
            text: message.body,
            author: { id: message.author, name: message.author },
            timestamp: message.timestamp,
          })
        else {
          let messageMedia = await message.media.getContentUrl().then(function (url) {
            // log media temporary URL
            var [type, extension] = message.media.state.contentType.split('/')
            if (type === 'image')
              return {
                text: <img style={{ width: 150 }} src={url} alt={"loading issue"} draggable={false} />,
                author: { id: message.author, name: message.author },
                timestamp: message.timestamp,
              }
            else
              return {
                text: <a href={url} target="_blank" rel="noopener noreferrer"><FileIcon size={64} extension={extension.toUpperCase()} {...defaultStyles[extension]} /></a>,
                author: { id: message.author, name: message.author },
                timestamp: message.timestamp,
              }
          });
          messagesList.push(messageMedia)
        }
    }

    this.setState({
      messages: messagesList
    });
    this.setState({ isLoading: false, completeLoaded: true });
    this.props.updateGetSpeakOption()
  }
  // async messageAdded(message) {
  //   if (message.body !== null && (message.body).indexOf('##################') === 0) {
  //     let body = message.body
  //     body = body.replace('##################', '')
  //     body = body.replace('##################', '')
  //     this.setState(prevState => ({
  //       messages: [
  //         ...prevState.messages,
  //         {
  //           text: <p>{body}</p>,
  //           author: { id: '', name: '' },
  //           timestamp: 0,
  //         }
  //       ]
  //     }));
  //   }
  //   else
  //     if (message.body !== null)
  //       this.setState(prevState => ({
  //         messages: [
  //           ...prevState.messages,
  //           {
  //             text: message.body,
  //             author: { id: message.author, name: message.author },
  //             timestamp: message.timestamp,
  //           }
  //         ]
  //       }));
  //     else {
  //       let messageMedia = await message.media.getContentUrl().then(function (url) {
  //         var [type, extension] = message.media.state.contentType.split('/')
  //         if (type === 'image')
  //           return {
  //             text: <img style={{ width: 150 }} alt={"loading issue"} src={url} draggable={false} />,
  //             author: { id: message.author, name: message.author },
  //             timestamp: message.timestamp,
  //           }
  //         else
  //           return {
  //             text: <a href={url} target="_blank" rel="noopener noreferrer"><FileIcon size={64} extension={extension.toUpperCase()} {...defaultStyles[extension]} /></a>,
  //             author: { id: message.author, name: message.author },
  //             timestamp: message.timestamp,
  //           }
  //       });
  //       this.setState(prevState => ({
  //         messages: [
  //           ...prevState.messages,
  //           messageMedia
  //         ]
  //       }));
  //     }

  // }
  // sendMessage(event) {
  //   console.log("this.state.message",this.state.messages)
  //   this.channel.sendMessage(event.message.text);
  // }
  componentWillUnmount() {
    this.props.onRef(null)
    this.client.shutdown();
  }
  handleInputChange = (e) => {
    let file = e.target.files[0];
    console.log(file)
    const formData = new FormData();
    formData.append('file', file);
    // this.channel.sendMessage(formData);
    this.state.conversations.sendMessage({contentType: file.type, media: file});
  }
  uploadButton = () => {
    return (
      <React.Fragment>
        <input type='file' onChange={this.handleInputChange} style={{ display: 'none' }} ref={el => this.fileUpload = el} />
        <button className={'k-button k-flat k-button-icon'} onClick={() => this.fileUpload.click()}>
          <AttachFileIcon colorPrimary={"red"} />
        </button>
      </React.Fragment>
    )
  }
  customMessage = (props) => {

    return <React.Fragment>
      <div>
        {props.messageInput}
        {this.uploadButton()}
      </div>
      {props.sendButton}

      {!this.state.videoStarted ?  <VideocamIcon onClick={() => { this.props.startVideoCall(); this.setState({ videoStarted: false }) }} className={'chatToCall'} /> : <CircularProgress size={24} style={{ padding: 0, backgroundColor: 'transparent', marginLeft: '10px' }} /> }
    {/* {!this.state.videoStarted ? this.state.meetingId ? <VideocamIcon onClick={() => { this.props.startVideoCall(); this.setState({ videoStarted: true }) }} className={'chatToCall'} /> : <CircularProgress size={24} style={{ padding: 0, backgroundColor: 'transparent', marginLeft: '10px' }} /> : ""} */}

    </React.Fragment>;
  }
  hideChatBox = () => {
    this.props.hideChatBox()
  }

  componentDidUpdate = (oldProps, oldState) => {
    if (this.state.conversations !== oldState.conversations) {
      
        // this.loadMessagesFor(this.state.conversations);

        if (!this.state.boundConversations.has(this.state.conversations)) {
            let newConversation = this.state.conversations;
            newConversation.on('messageAdded', m => this.messageAdded(m, newConversation));
            this.setState({boundConversations: new Set([...this.state.boundConversations, newConversation])});
        }
    }
      //SkypeConversation 30-May 2:52 AM
  // The chat scrolls automatically when the other member sends a message but for the same person,
  // it does not scroll down once a message is sent.
  // The chat should scroll down to the last message automatically when a message is sent.
  // Dev : added this function and call this on when new message loaded on componentdidupdate function
  // and when the messagesend function is called 
    this.scrollToBottom()
  };

  render() {
    if (this.state.error) {
      return <p>{this.state.error}</p>;
    } else if (this.state.isLoading) {
      return <p>Loading chat...</p>;
    }
    if (this.props.close === 1) {
      this.client.shutdown();
    }
    return (
      <div className={"clientChat"}>
        <div className={"chatHeader"}>
          <p>Chat </p>
          {isMobileOnly ?
            <div className={"mobileVideo"}>
              {this.props.videoStatus ? <Button onClick={() => this.props.hideBoxes('Video')}>Video</Button> : ""}
              {this.state.role === 'Doctor' ? <Button onClick={() => this.props.hideBoxes('Progress')}>Notes</Button> : ""}
            </div>
            : ""}
        </div>
        {!this.state.completeLoaded ? <CircularProgress style={{ marginTop: '10px' }} size={24} /> :
          <ChatUI
            user={this.user}
            messages={this.state.twillioMessages}
            onMessageSend={this.sendMessage}
            placeholder={'Type a message...'}
            messageBox={this.customMessage}
            messageTemplate={(props) => MessageTemplate(props, this.user,this.otherUserId+this.props.AppointmentId)}
          />
        }
      </div>
    );
  }
}

export default ChatApp;