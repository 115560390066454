import React, { Component } from 'react'
import withStyles from "@material-ui/core/styles/withStyles"
import { Grid, Button, Select, FormControl, InputLabel, MenuItem } from '@material-ui/core'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'


const styles = theme => ({
    popup: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column'
    },
    popupDiv: {
        backgroundColor: '#fff',
        padding: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '35rem',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            width: '100%'
        }
    },
    popupButton: {
        marginBottom: theme.spacing(2),
        fontFamily: 'inherit',
        color: '#ffffff'
    },
    popupHeading: {
        fontFamily: 'inherit',
        fontWeight: 700,
        paddingBottom: theme.spacing(6),
        color: '#795bb2',
        fontSize: theme.spacing(2.4)
    },
    font: {
        fontFamily: 'revert',
    },
    mainDiv: {
        display: 'flex',
        flexDirection: 'column',
        width: '60%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    formButton: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    textLeft: {
        textAlign: 'left'
    }
})
class personalInfo extends Component {

    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = {
            isFormValid: true,
            dob: props.registerData.hasOwnProperty('dateofBirth') ? props.registerData.dateofBirth : null,
            gender: props.registerData.hasOwnProperty('gender') ? props.registerData.gender : ''
        }
    }

    componentDidMount() {
        this._isMounted = true
    }

    updateFormData = (step) => {
        this.props.updateStepNo(step, { dateofBirth: this.state.dob, gender: this.state.gender })
    }
    // FORM DATA UPDATED
    change = (e, text) => {
        if (this._isMounted) {
            if (text === 'dob') {
                this.setState({ isFormValid: true, dob: moment(e).format('YYYY/MM/DD') })
            }
            else {
                this.setState({ isFormValid: true, gender: e.target.value })
            }
        }
    }
    validateForm = () => {
        if (this.state.dob !== '' && this.state.gender !== '' && this.state.dob !== 'Invalid date')
            this.updateFormData(5)
        else
            this.setState({ isFormValid: false })
    }
    render() {
        let { classes } = this.props

        return (
            <Grid className={classes.font}>
                <Grid className={classes.popup}>
                    <Grid className={classes.popupDiv} >
                        <Grid className={classes.mainDiv}>
                            <span className={classes.popupHeading}>
                                {this.props.registerData.accountType === 'own' ?
                                    "Please enter your personal information"
                                    :
                                    "Please enter patient's personal information"
                                }
                            </span>

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    id="dob"
                                    name="dob"
                                    label="Date of Birth *"
                                    value={this.state.dob}
                                    // placeholder={"mm/dd/yyyy"}
                                    // format="MM/dd/yyyy"
                                    placeholder={"dd/mm/yyyy"}
                                    format="dd/MM/yyyy"
                                    style={{ marginBottom: '24px' }}
                                    onChange={(e) => this.change(e, 'dob')}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    maxDate={moment()}
                                    error={this.state.dob === null && !this.state.isFormValid ? true : this.state.dob === 'Invalid date' && !this.state.isFormValid ? true : false}
                                    helperText={this.state.dob === null && !this.state.isFormValid ? "Date of birth is required" : this.state.dob === 'Invalid date' && !this.state.isFormValid ? 'Date format is incorrect' : ""}
                                />
                            </MuiPickersUtilsProvider>

                            <FormControl variant="outlined" fullWidth style={{ marginBottom: '24px' }}>
                                <InputLabel id="gender">Sex *</InputLabel>
                                <Select
                                    labelId="gender"
                                    id="gender"
                                    name="gender"
                                    label="Sex *"
                                    variant="outlined"
                                    className={classes.textLeft}
                                    fullWidth
                                    value={this.state.gender}
                                    error={this.state.gender === '' && !this.state.isFormValid ? true : false}
                                    onChange={(e) => this.change(e, "gender")}
                                >
                                    <MenuItem value='Male'>Male</MenuItem>
                                    <MenuItem value='Female'>Female</MenuItem>
                                </Select>
                                {this.state.gender === '' && !this.state.isFormValid && (
                                    <p className="MuiFormHelperText-root Mui-error textLeft">{"Gender is required"}</p>
                                )}
                            </FormControl>

                            <Grid className={classes.formButton}>
                                <Button variant="contained" onClick={() => this.updateFormData(3)} color={'primary'} className={classes.popupButton}>
                                    Back
                                    </Button>
                                <Button variant="contained" onClick={() => this.validateForm()} color={'secondary'} className={classes.popupButton}>
                                    Next
                                    </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        )
    }
}
export default withStyles(styles)(personalInfo);
