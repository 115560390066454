import React, { useState } from 'react'
import cookie from 'react-cookies'
import { Redirect } from 'react-router-dom'
//Material-ui
import { withStyles } from '@material-ui/styles'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
//Child components
import CalendarModal from './CalendarModal'
import TodayAppointmentMessage from './TodayAppointmentMessage'
//helper functions
import { getTimeFromUnixTimeStamp, getDoctorNameFromRhynoGoAppointments, getMembersDetail } from './utils';
const styles = theme => ({
    container: {
        '& .MuiPaper-root': {
            borderRadius: '2rem',
            // margin: '0',
        },
    },
    tableHeader: {
        "& .MuiTableCell-root.MuiTableCell-head": {
            fontWeight: 'bold'
        }
    },
    tableRowBody: {
        "& .MuiTableCell-body": {
            border: 'none',
        },
    },
    buttonCancelChange: {
        width: '130px',
        textTransform: 'none',
        backgroundColor: 'rgb(216,243,243)',
        color: 'rgb(78,202,200)',
        borderRadius: '.5rem',
        "&:hover": {
            backgroundColor: 'rgb(216,243,243)',
        },
    },
    buttonSeeProvider: {
        width: '130px',
        textTransform: 'none',
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        borderRadius: '.5rem',
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    accepted: {
        textTransform: 'none',
        backgroundColor: 'rgb(216,245,223)',
        color: 'rgb(26,195,83)',
        height: '2rem',
        borderRadius: '.5rem',
        "&:hover": {
            backgroundColor: 'rgb(216,245,223)',
            cursor: 'default'
        },
        width: '5rem'
    },
    completed: {
        textTransform: 'none',
        backgroundColor: 'rgb(220 218 218)',
        color: 'rgb(128 128 128)',
        height: '2rem',
        borderRadius: '.5rem',
        "&:hover": {
            backgroundColor: 'rgb(220 218 218)',
            cursor: 'default'
        },
        width: '5rem'
    },
    pending: {
        textTransform: 'none',
        backgroundColor: 'rgb(255 195 217)',
        color: 'rgb(255 0 0)',
        height: '2rem',
        borderRadius: '.5rem',
        "&:hover": {
            backgroundColor: 'rgb(255 195 217)',
            cursor: 'default'
        },
        width: '5rem'
    },
    changed: {
        textTransform: 'none',
        backgroundColor: 'rgb(253,244,213)',
        color: 'rgb(242,181,39)',
        height: '2rem',
        borderRadius: '.5rem',
        "&:hover": {
            backgroundColor: 'rgb(253,244,213)',
            cursor: 'default'
        },
        width: '5rem'
    },
    added: {
        textTransform: 'none',
        backgroundColor: 'rgb(255,226,223)',
        color: 'rgb(255,78,62)',
        borderRadius: '.5rem',
        height: '2rem',
        "&:hover": {
            backgroundColor: 'rgb(255,226,223)',
            cursor: 'default'
        },
        width: '5rem'
    },
    capitalize: {
        textTransform: 'Capitalize'
    },
    lastCell: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '225px',
        marginLeft: 'auto'
    }
})

const UpcomingAppointments = (props) => {
    const { classes, dataTable, changeData } = props;
    const [calendarAndAppointmentData, setCalendarAndAppointmentData] = useState(
        { show: false, id: '', doctorId: '', userId: '', doctorName: '', type: "", appointementType: '' }
    )
    const [chatPage] = useState('chat')
    const [redirectToChat, setRedirectToChat] = useState('')
    const [showTodayModel, setShowTodayModel] = useState(false)

    // console.log(props)
    /* FOR TASK RHYN-102
    TASK DESCRIPTION: Add patient tab and subaccounts for age <16
    CHECK IS REDUX SUBACCOUNT STATE HAS KEY subAccountId THEN GET ALL DATA RELATED TO SUBACCOUNT */
    // const userId = props.parentProps.auth.uid;
    const userId = props.parentProps.subAccount.subAccountId ? props.parentProps.subAccount.subAccountId : props.parentProps.auth.uid
    const cancelChangeAppointment = async (id, doctorId, userId, doctorName, appointmentType, start, status,
        duration, addedBy, holdamount, urgent, userSelectedZipCode, isUrgentPCR) => {
        await setCalendarAndAppointmentData({
            show: true, start: start, status: status, duration: duration,
            id: id, doctorId: doctorId, userId: userId, doctorName: doctorName,
            type: "update", appointmentType: appointmentType, addedBy: addedBy,
            holdamount: holdamount, urgent: urgent, userSelectedZipCode: userSelectedZipCode, isUrgentPCR: isUrgentPCR
        })
    }
    const onCancel = async () => {
        await setCalendarAndAppointmentData({ showCalendar: false, id: '', doctorId: '', userId: '', doctorName: '', type: '' })
        //remove overflow hidden when react-confirm-alert closes
        document.querySelector('body').style.overflow = 'auto';
    }
    const moveToChat = (name, link) => {
        cookie.save('doctorName', name)
        setRedirectToChat(link)
    }
    const renderStatus = (urgent, status, addedBy) => {
        switch (true) {
            case (urgent):
                return 'Pending';
            case (status === 'accepted' && (addedBy === 'patient' || addedBy === 'agency')):
                return 'Accepted';
            case (status === 'changed' && (addedBy === 'patient' || addedBy === 'agency')):
                return 'Changed';
            case (status === 'changed' && addedBy === 'doctor'):
                return 'Added';
            case (status === 'completed'):
                return 'Completed';
            default:
                return;
        }
    }
    const statusStyle = (urgent, status, addedBy) => {
        switch (true) {
            case (urgent):
                return classes.pending;
            case (status === 'accepted' && (addedBy === 'patient' || addedBy === 'agency')):
                return classes.accepted;
            case (status === 'changed' && (addedBy === 'patient' || addedBy === 'agency')):
                return classes.changed;
            case (status === 'changed' && addedBy === 'doctor'):
                return classes.added;
            case (status === 'completed'):
                return classes.completed;
            default:
                return;
        }
    }
    if (dataTable.length > 0) {
        if (redirectToChat !== '') {
            return <Redirect to={redirectToChat} />;
        }
        return (
            <div className={classes.container}>
                {calendarAndAppointmentData.show ?
                    <CalendarModal
                        cancel={onCancel}
                        changeData={changeData}
                        parentProps={props.parentProps}
                        appointmentData={calendarAndAppointmentData}
                    />
                    : null}
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow className={classes.tableHeader}>
                                <TableCell>Provider</TableCell>
                                {props.parentProps.account.data.role === 'Agency' ? <TableCell align="left">Members</TableCell> : ""}
                                <TableCell align="left">Date and time</TableCell>
                                <TableCell align="left">Status</TableCell>
                                {/* <TableCell align="left">Type of appointment</TableCell> */}
                                <TableCell align="center">Details</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataTable.map((row, id) => (
                                <TableRow
                                    className={classes.tableRowBody}
                                    key={id}>
                                    <TableCell className={classes.capitalize} align="left">{row.appointmentType === "rhynogo" ? getDoctorNameFromRhynoGoAppointments(row.text) : row.doctorName}</TableCell>
                                    {props.parentProps.account.data.role === 'Agency' ?
                                        <TableCell className={classes.capitalize} align="left">{getMembersDetail(row.rhynoGoMembers)}</TableCell>
                                        :
                                        ""
                                    }
                                    <TableCell component="th" scope="row">
                                        {row.appointmentType === 'onDemand' ? <>on-Demand</> : getTimeFromUnixTimeStamp(row.start, row.appointmentType)}
                                    </TableCell>
                                    <TableCell align="left">
                                        <Button
                                            className={
                                                statusStyle(row.urgent, row.status, row.addedBy)
                                            }
                                        >
                                            {renderStatus(row.urgent, row.status, row.addedBy)}
                                        </Button>
                                    </TableCell>
                                    <TableCell className={classes.lastCell} align="center">
                                        {!row.urgent ? row.remainingSeconds > 300 ?
                                            <>
                                                {/* SKYPE CONVERSATION
                                                G-Man, 10:33 AM
                                                Please disable and perhaps put a note there as well - 'Cancel/Change option will be available in a few minutes'
                                                show the note to wait for few minutes to cancel/update appointment if status is updating and disable button */}
                                                <Button
                                                    variant={"contained"}
                                                    className={classes.buttonCancelChange}
                                                    onClick={() => {
                                                        row.isTodayAppointment ? setShowTodayModel(!showTodayModel) : cancelChangeAppointment(row.id, row.doctorId, userId,
                                                            row.doctorName, row.appointmentType, row.start, row.status,
                                                            row.duration, row.addedBy, row.holdamount, row.urgent, row.userSelectedZipCode, row.isUrgentPCR)
                                                    }}
                                                    disabled={row.appointmentCreationStatus === 'updating' || row.appointmentCreationStatus === 'created'}
                                                >
                                                    Cancel/Change
                                                </Button>
                                                {(row.appointmentCreationStatus === 'updating' || row.appointmentCreationStatus === 'created') && 'Cancel/Change option will be available in a few minutes'}
                                            </>
                                            : row.appointmentType !== 'rhynogo' ?
                                                <Button
                                                    variant={"contained"}
                                                    className={classes.buttonSeeProvider}
                                                    onClick={() => {
                                                        moveToChat(row.doctorName, chatPage + "/" +
                                                            row.doctorId + "/" + userId + "/" + row.id)
                                                    }

                                                    }>
                                                    See provider
                                            </Button>
                                                : ""
                                            :
                                            <>
                                            
                                                {/* SKYPE CONVERSATION
                                                G-Man, 10:33 AM
                                                Please disable and perhaps put a note there as well - 'Cancel/Change option will be available in a few minutes'
                                                show the note to wait for few minutes to cancel/update appointment if status is updating and disable button */}
                                                <Button
                                                    variant={"contained"}
                                                    className={classes.buttonSeeProvider}
                                                    onClick={() => {
                                                        cancelChangeAppointment(row.id, row.doctorId, userId,
                                                            row.doctorName, row.appointmentType, row.start, row.status,
                                                            row.duration, row.addedBy, row.holdamount, row.urgent, row.userSelectedZipCode, row.isUrgentPCR)
                                                    }}
                                                    disabled={row.appointmentCreationStatus === 'updating' || row.appointmentCreationStatus === 'created'}
                                                >Set Time
                                                </Button>
                                                {(row.appointmentCreationStatus === 'updating'  || row.appointmentCreationStatus === 'created') && 'Set Time option will be available in a few minutes'}
                                            </>
                                        }
                                    </TableCell>
                                    {/* <TableCell align="left"> {row.status === 'changed'? null :`<DetailsButton>`} </TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {showTodayModel && <TodayAppointmentMessage setShowTodayModel={setShowTodayModel} showTodayModel={showTodayModel} />}
            </div>
        )
    }
    else {
        return (
            <h2>No appointments made yet....</h2>
        )
    }
}
export default withStyles(styles)(UpcomingAppointments)
