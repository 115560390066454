import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = (theme) => ({
  heading: {
    fontSize: theme.spacing(3),
    fontWeight: 700,
  },
  paragraph: {
    fontSize: theme.spacing(2),
  },
  paragraphHeading: {
    fontWeight: 700,
    fontSize: theme.spacing(2),
  },
  rynocareHeading: {
    textAlign: "center",
    textIndent: 0,
    lineHeight: "106%",
  },
  textAlignLeft: {
    textAlign: "left",
    textIndent: 0,
    lineHeight: "106%",
    
  },
  paddingLeft: {
    paddingLeft: "6pt",
    lineHeight: "106%",
  },
  paddingTop: {
    paddingTop: "12pt",
    lineHeight: "106%",
  },
  lineHeight: {
    lineHeight: "106%",
  },
  paragraph: {
    paddingLeft: "6pt",
    textAlign: "left",
    textIndent: 0,
    lineHeight: "106%",
  },
  underLine: {
    textDecorationLine: "underline",
    lineHeight: "106%",
  },
  justify: {
    textAlign: "justify",
    lineHeight: "106%",
  },
});

class TC extends Component {
  render() {
    const { classes } = this.props;
    return (
      <>
        <h1 className={classes.rynocareHeading}>RhynoCare Terms of Use</h1>

        <p className={classes.textAlignLeft}>
          <br />
        </p>

        <p className={classes.textAlignLeft}>
          These RhynoCare Terms of Use were last Updated:{" "}
          <span class="s1">May 05, 2022</span>
        </p>

        <h3 className={classes.textAlignLeft}>
          <a name="a0">Contractual Relationship</a>
        </h3>

        <p className={`${classes.paragraph}`}>
          These RhynoCare Terms of Use (“Terms”) govern the access and/or use,
          by individuals who have registered with RhynoCare and have a User
          Account or a profile in a User Account as explained below (“you”,
          “your”, “User”), of applications, websites, content, products and
          services (collectively referred to as the “RhynoCare Platform”), owned
          and operated by RhynoCare Incorporation (“RhynoCare”, “we”, “us”).
        </p>

        <p className={`${classes.paragraph}`}>
          For clarity, these Terms set out circumstances in which you may use
          the RhynoCare Platform on behalf of another individual, and where you
          are doing so (as permitted), references to “you” and “your” in these
          Terms include the child or other individual listed in your account for
          whom you have the legal authority to seek Healthcare Services as
          defined below (each a “Registered Dependent“).
        </p>

        <p className={`${classes.paragraph}`}>
          By accessing and using the RhynoCare Platform, or participating in any
          RhynoCare Programs, you acknowledge and agree to be bound by these
          Terms, which establish a contractual relationship between you and
          RhynoCare.
        </p>

        <p className={`${classes.paragraph}`}>
          If you do not agree to these Terms at any point, you must immediately
          cease accessing the RhynoCare Platform and participating in any
          RhynoCare Programs.
        </p>

        <p className={`${classes.paragraph}`}>
          We reserve the right, at our sole discretion, to modify, alter or
          otherwise update these Terms at any time. By using the RhynoCare
          Platform or participating in any Programs, after the amendment of the
          Terms, you are agreeing to be bound by such modifications, alterations
          or updates. The date on which these Terms were last amended will
          appear at the top.
        </p>
        <p className={`${classes.paragraph}`}>
          On notice to you, RhynoCare may immediately terminate these Terms and/
          or any RhynoCare Programs, and/or access to the RhynoCare Platform or
          participation in any RhynoCare Programs, or generally cease offering
          or deny access to the RhynoCare Platform or any portion thereof and/or
          any RhynoCare Programs, at any time, for any reason.
        </p>

        <h3
          className={`${(classes.paragraph, classes.underLine)}`}
          style={{ paddingLeft: "6pt" }}
        >
          <a name="a1">
            Authorized Physicians and Authorized Non-Physician Providers on
            RhynoCare’s Platform
          </a>
        </h3>

        <p
          class="s2"
          className={`${(classes.textAlignLeft, classes.underLine)}`}
          style={{ paddingLeft: "6pt" }}
        >
          Authorized Physicians
        </p>

        <p className={`${classes.paragraph}`}>
          All physicians providing Healthcare Services or Informational
          Services, as defined below, (each an “Authorized Physician”) are
          independent, regulated health professionals registered to practice
          medicine in a Province or Territory of Canada.
        </p>

        <p className={`${classes.paragraph}`}>
          RhynoCare is not a healthcare provider. Authorized Physicians alone
          are responsible for the Healthcare Services and Informational Services
          they provide through the RhynoCare Platform.
        </p>

        <p className={`${classes.paragraph}`}>
          To the extent permitted by the law in the Province or Territory in
          which you reside, Authorized Physicians may be located in your
          Province or Territory or another Province or Territory of Canada.
        </p>

        <p className={`${classes.paragraph}`}>
          If you want information about any particular Authorized Physician,
          consult their profile on the register on the website of the medical
          regulator (in most Provinces, the “College of Physicians and Surgeons”
          of the Province), in the Province or Territory in which the Physician
          Practices.
        </p>

        <p
          class="s2"
          className={`${(classes.paddingLeft, classes.underLine)}`}
          style={{ paddingLeft: "6pt" }}
        >
          Authorized Non-Physician Providers
        </p>

        <p className={`${classes.paragraph}`}>
          All non-physician providers providing Healthcare Services or
          Informational Services, as defined below, (each an “Authorized
          Non-Physician Provider”) are independent, regulated health
          professionals (such as psychotherapists or nurses) registered to
          provide healthcare in a Province or Territory of Canada.
        </p>
        <p className={`${classes.paragraph}`}>
          RhynoCare is not a healthcare provider. Authorized Non-Physician
          Providers alone are responsible for the Healthcare Services and
          Informational Services they provide through the RhynoCare Platform.
        </p>
        <p className={`${classes.paragraph}`}>
          To the extent permitted by the law in the Province or Territory in
          which you reside, Authorized Non-Physician Providers may be located in
          your Province or Territory or another Province or Territory of Canada.
        </p>

        <p className={`${classes.paragraph}`}>
          If you want information about any particular Authorized Non-Physician
          Provider, you can consult their profile on the register on the website
          of their respective regulator (in most Provinces, named a “College”),
          in the Province or Territory in which the Non-Physician Provider
          Practices.
        </p>

        <h3 className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          <a name="a2">Services</a>
        </h3>

        <p className={`${classes.paragraph}`}>
          Authorized Physicians and Authorized Non-Physician Providers may
          provide two kinds of services through the RhynoCare Platform: Health
          care services (“Healthcare Services”) and/or general information about
          diseases and conditions and how they are treated in Canada
          (“Informational Services”).
        </p>
        <p className={`${classes.paragraph}`}>
          Telemedicine is the delivery of health care and other services using
          interactive text, audio and video technology, where the patient and
          the physician are not in the same physical location.
        </p>
        <p className={`${classes.paragraph}`}>
          During your telemedicine consultation with an Authorized Physician or
          a Non-Physician Provider, you will be asked to provide the Authorized
          Physician or Non-Physician Provider certain Personal Information
          including Personal Health Information (collectively referred to
          together as “Information”). When discussing Healthcare Services,
          information typically refers to Personal Health Information (“PHI”),
          and when discussing Informational Services, Information typically
          refers to information that is not specifically about you or your
          health, such as information in questions you would be comfortable
          asking of an expert during the call-in portion of a radio br/oadcast
          on health-related matters. PHI is not required for Informational
          services, and we advise you to not to submit PHI in the course of
          accessing Informational Services.
        </p>
        <p className={`${classes.paragraph}`}>
          The type of services that are available to you through the RhynoCare
          Platform depends on your province of residence and the location you
          are in at the time you request the services. This is because
          telemedicine is regulated by the Provinces and Territories and the
          rules differ or may differ from time to time, among the Provinces and
          Territories.
        </p>

        <p className={`${classes.paragraph}`}>
          RhynoCare does not make any representations or warranties about the
          training or skill of any Providers who deliver services via the
          Platform. You are ultimately responsible for choosing your particular
          Provider.
        </p>

        <h3 className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          <a name="a3">Healthcare Services, Limits on Healthcare Services</a>
        </h3>

        <p className={`${classes.paragraph}`}>
          The relationship between you and an Authorized Physician providing
          Healthcare Services is a physician-patient relationship. RhynoCare is
          not a party to that relationship.
        </p>

        <p className={`${classes.paragraph}`}>
          The relationship between you and an Authorized Non-Physician Provider
          providing Healthcare Services is a provider-patient relationship.
          RhynoCare is not a party to that relationship.
        </p>
        <p className={`${classes.paragraph}`}>
          There are restrictions on the Healthcare Services that can be provided
          through the RhynoCare Platform. YOU MUST NOT USE THE RhynoCare
          PLATFORM FOR EMERGENCY MEDICAL NEEDS. IF YOU BELIEVE THAT YOU ARE
          DEALING WITH A MEDICAL EMERGENCY, CALL 911 IMMEDIATELY OR ATTEND YOUR
          NEAREST EMERGENCY ROOM.
        </p>
        <p className={`${classes.paragraph}`}>
          The following symptoms are{" "}
          <span class="s3">specifically excluded</span>
          <span class="s4"></span>
          from the RhynoCare Platform: Chest pains, difficulty br/eathing, fever
          in children under the age of 3 months, blackouts or feeling like you
          may blackout, any impairment in level of consciousness, stroke like
          symptoms such as impaired sensation or paralysis to one side of your
          body, inability to speak or comprehend language, any abdominal pain
          severe enough that you are unable to comfortably walk.
        </p>
        <p className={`${classes.paragraph}`}>
          You agree that any prescriptions you acquire from an Authorized
          Physician or an Authorized Non-Physician Provider are solely for the
          personal use of the individual named on the prescription.
        </p>
        <p className={`${classes.paragraph}`} style={{ paddingTop: "2px" }}>
          You agree to fully and carefully read all provided Product Information
          and labels, and to contact a Physician or Pharmacist if you have any
          questions regarding the prescription or medication.
        </p>

        <p className={`${classes.paragraph}`}>
          You should report any medication reactions, side effects or other
          adverse events to your family physician or, if you do not have one, to
          the nearest walk-in clinic or emergency room or call 911 if any
          reaction is an emergency situation.
        </p>

        <p className={`${classes.paragraph}`}>
          Do not report drug reactions, side effects or adverse events through
          the RhynoCare Platform.
        </p>

        <p className={`${classes.paragraph}`}>
          Authorized Physicians and Authorized Non-Physician Providers have the
          discretion to refuse or discontinue the provision of Healthcare
          Services to any User at any time, including for actual or potential
          misuse of the Healthcare Services by a User.
        </p>

        <h3 className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          <a name="a4">Not a Replacement for Primary Care.</a>
        </h3>
        <p className={`${classes.paragraph}`}>
          Please note that interaction with an Authorized Physician through the
          RhynoCare Platform is not intended to take the place of appointments
          with your regular primary care provider.
        </p>
        <p className={`${classes.paragraph}`}>
          If you do not have an established relationship with a primary care
          provider, you are encouraged to develop one. You should seek emergency
          help or follow-up care when recommended by the Authorized Physician.
        </p>
        <p className={`${classes.paragraph}`}>
          We do not provide your family physician with a report about the
          Healthcare Services that you receive through the RhynoCare Platform.
          So your family physician can take into consideration these Healthcare
          Services, including any prescriptions, it is always advisable that you
          inform your physician about the Healthcare Services you receive,
          including the issues that caused you to request them. The RhynoCare
          Platform enables you to request a copy of your visit report to provide
          to a physician of your choice if necessary.
        </p>

        <h3 className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          <a name="a5">
            Informational Services. Limits on Informational Services
          </a>
        </h3>

        <p className={`${classes.paragraph}`}>
          Informational Services are for general educational purposes only
          (similar to advice that might be provided by a 3rd party medical
          expert on a phone in radio show), and are not Healthcare Services.
          RhynoCare is not a party to your relationship with an Authorized
          Physician or an Authorized Non- Physician Provider providing
          Informational Services.
        </p>
        <p className={`${classes.paragraph}`}>
          Informational Services do not replace consultations with qualified
          medical or other relevant healthcare professionals. You agree that you
          bear all risk associated with the use of or reliance on Informational
          Services, and release and hold RhynoCare, Authorized Physicians, and
          Authorized Non- Physician Providers providing Informational Services
          harmless from and against any claim, demand, action, cause of action,
          damage, loss, cost, liability or expense, including reasonable legal
          fees, which may be made or br/ought against or incurred or suffered by
          RhynoCare or an Authorized Physician, or an Authorized Non-Physician
          Provider directly or indirectly as a result of, in respect of, or
          arising out of your reliance on Informational Services.
        </p>
        <p className={`${classes.paragraph}`}>
          Authorized Physicians and Authorized Non-Physician Providers have the
          discretion to refuse or discontinue the provision of some or all
          Informational Services to any User at any time, including for actual
          or potential misuse of the Informational Services by a User and where
          the information being requested is not general information but in the
          nature of a diagnosis or treatment.
        </p>

        <h3 className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          <a name="a6">Privacy</a>
        </h3>

        <p className={`${classes.paragraph}`}>
          Our collection, use, disclosure, retention and disposal and
          destruction of Information are governed by our Privacy Policy, located
          at{" "}
          <a href="http://www.RhynoCare.com/privacy" target="_blank">
            www.RhynoCare.com/privacy.
          </a>
        </p>
        <p className={`${classes.paragraph}`}>
          You must review our Privacy Policy prior to agreeing to these Terms as
          the Privacy Policy is incorporated by reference into, and accordingly
          is part of, these Terms.
        </p>
        <p className={`${classes.paragraph}`}>
          By authorizing someone else (a “Third Party”) to pay the fees for your
          Healthcare Services or Informational Services, as described in the
          section
        </p>
        <p className={`${classes.paragraph}`}>
          on Payment below and to the extent permitted under the section on
          Third Party Payment below, you are representing to RhynoCare, and
          RhynoCare will rely on your representation, that:
        </p>
        <ol id="l1">
          <li data-list-text="1.">
            <p className={`${classes.paragraph}`}>
              You consent to us using the email address provided to us by the
              Third Party to communicate with you regarding the RhynoCare
              Platform and Healthcare Services and/or Informational Services;
            </p>
          </li>
          <li data-list-text="2.">
            <p className={`${classes.paragraph}`}>
              Disclosing the information that you have requested Healthcare
              Services to the Third Party to permit RhynoCare to process payment
              for the Healthcare Services and/or Informational Services; and
            </p>
          </li>
          <li data-list-text="3.">
            <p className={`${classes.paragraph}`}>
              Notifying the Third Party of any changes or termination of your
              access to the RhynoCare Platform.
            </p>
          </li>
        </ol>
        <p className={`${classes.paragraph}`}>
          If you have questions about our privacy program, our management of
          your Information, or the personal information that we disclose to
          Third Party payers, please contact us as set out in the Privacy
          Policy.
        </p>

        <h3 className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          <a name="a7">The RhynoCare Platform and Healthcare Services</a>
        </h3>
        <p className={`${classes.paragraph}`}>
          The RhynoCare Platform also enables you to; store information
          (including PHI created or compiled in the course of providing
          Healthcare Services) and to share it with Authorized Physicians and
          Authorized Non-Physician Providers; and receive and store
          prescriptions and sick notes, among other documents, from Authorized
          Physicians and Authorized Non-Physician Providers electronically.
        </p>
        <p className={`${classes.paragraph}`}>
          The RhynoCare Platform offers optional services that RhynoCare will
          perform on your behalf, at your direction, including the ability to;
          transmit securely a summary report about your Healthcare Services to a
          physician of your choice; fax securely your prescription to your
          chosen pharmacy and where available, securely transmit your
          information to the prescription delivery service.
        </p>
        <h3 className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          <a name="a8">The RhynoCare Platform and Informational Services</a>
        </h3>
        <p className={`${classes.paragraph}`}>
          The RhynoCare Platform enables you to access the online provision of
          general medical information from Authorized Physicians and Authorized
        </p>
        <p className={`${classes.paragraph}`}>
          Non-Physician Providers. You acknowledge and agree that the RhynoCare
          Platform and Informational Services are made available solely for your
          personal and non-commercial use.
        </p>

        <p
          class="s5"
          className={`${(classes.textAlignLeft, classes.underLine)}`}
          style={{ paddingLeft: "6pt" }}
        >
          For Clarity
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          BY REGISTERING TO USE THE RHYNOCARE PLATFORM, YOU ACKNOWLEDGE THAT YOU
          UNDERSTAND AND AGREE THAT:
        </p>

        <ol id="l2">
          <li data-list-text="1.">
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              The RhynoCare Platform is a technology platform that facilitates
              connecting Users (and any individuals for whom they have created a
              profile in their User Account) with Authorized Physicians and
              Authorized Non-Physician Providers for the provision of Healthcare
              Services and/ or Informational Services. RhynoCare is not a party
              to the relationship between you and any Authorized Physician or
              Authorized Non- Physician Providers providing Healthcare Services
              and/or Informational Services.
            </p>
          </li>
          <li data-list-text="2.">
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              Authorized Physicians and Authorized Non-Physician Providers are
              independent contractors who are not employed by RhynoCare or
              otherwise affiliated with RhynoCare.
            </p>
          </li>
          <li data-list-text="3.">
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              Authorized Physicians and Authorized Non-Physician Providers are
              solely responsible for the Healthcare Services and Informational
              Services that they provide to you. This includes compliance with
              standards of care, record keeping and other professional
              obligations.
            </p>
          </li>
          <li data-list-text="4.">
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              As with any other medical consultation, no results can be
              guaranteed or assured: your Authorized Physician or Authorized
              Non-Physician Provider may determine that Healthcare Services are
              not appropriate for some or all of your treatment needs, and
              accordingly may elect not to provide Healthcare Services to you
              through the RhynoCare Platform.
            </p>
          </li>
          <li data-list-text="5.">
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              As with any other health care service, there are risks associated
              with the use of the Healthcare Services.
            </p>
          </li>
          <li data-list-text="6.">
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              There are risks associated with any inappropriate reliance on
              Informational Services.
            </p>
          </li>
          <li data-list-text="7.">
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              The RhynoCare Platform may at times not adequately facilitate the
              transmission of information in a way that allows the Authorized
              Physician or Authorized Non-Physician Provider to provide
              Healthcare Services or informational services ( eg. poor
              resolutions of images).
            </p>
          </li>
          <li data-list-text="8.">
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              You are responsible for providing the Authorized Physician and
              Authorized Non-Physician Provider with the information that they
              advise is required in order to provide you with Healthcare
              Services or Informational Services.
            </p>
          </li>
          <li data-list-text="9.">
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              The laws that protect the privacy and security of PHI apply to
              telemedicine; (again, please see our Privacy Policy).
            </p>
          </li>
          <li data-list-text="10.">
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              The content and material appearing on the RhynoCare Platform could
              include technical, typographical or photographic errors. RhynoCare
              does not warrant that any of the materials provided are accurate
              or current (see the Section on “Content” below).
            </p>
          </li>
        </ol>

        <h3 className={`${classes.paragraph}`}>
          <a name="a9">Content</a>
        </h3>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          None of the information or materials (collectively, excluding
          Healthcare Services, (“Content”) available through the RhynoCare
          Platform is medical advice, treatment, diagnosis or an endorsement,
          representation or warranty that any particular medication or treatment
          is safe, appropriate, or effective for you. Content is for
          informational purposes only. It is your responsibility to discuss any
          Content with your family physician or another healthcare provider
          prior to relying on it and any reliance on Content is at your own
          risk.
        </p>

        <h3 className={`${classes.paragraph}`}>
          <a name="a10">Ownership</a>
        </h3>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          The RhynoCare Platform, all Contents and rights therein, except PHI,
          are and shall remain RhynoCare’s property or the property of
          RhynoCare’s licensors. Neither these Terms nor your use of the
          RhynoCare Platform convey or grant to you any rights to use or
          reference in any manner
        </p>
        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          RhynoCare’s br/and elements, company name, logo, product and service
          names, trademarks or services marks or those of RhynoCare’s licensors.
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          You are not permitted to copy or otherwise reproduce, distribute,
          modify, create derivative works of, publicly display, publicly
          perform, republish, download, store or transmit any Content, except as
          expressly permitted by these Terms.
        </p>

        <h3 className={`${classes.paragraph}`}>
          <a name="a11">User Accounts</a>
        </h3>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          In order to use the RhynoCare Platform, including obtaining Healthcare
          Services or Informational Services, you must register for and maintain
          an active personal account (“User Account”).
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          You must be at least 18 years of age to create a User Account, unless
          you are associated with a Third Party that permits individuals under
          the age of 18 to create a User Account (e.g. such as an international
          student in Canada associated with a specific insurance provider that
          is paying Fees on your behalf) and you meet the Third Party minimum
          age requirement specified to you in any additional terms at the time
          you create the User Account.
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          You are only permitted to request Healthcare Services for you or for
          Registered Dependents you have added to your User Account. You must
          create a separate “profile” for each Registered Dependent, which will
          include the person’s name and other personal information. Again, you
          are only permitted to request Healthcare Services for yourself or
          Registered Dependents. You may request Informational Services for you
          only.
        </p>
        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          You are responsible for all activity that occurs under your User
          Account, and you agree to maintain the security and secrecy of your
          User Account username and password at all times. Unless otherwise
          permitted by RhynoCare in writing, you may only possess one User
          Account.
        </p>

        <h3 className={`${classes.paragraph}`}>
          <a name="a12">Restrictions</a>
        </h3>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          While using the RhynoCare Platform, you may not, and represent and
          promise that you will not:
        </p>
        <ol id="l3">
          <li data-list-text="1.">
            <p className={`${classes.paragraph}`}>
              Remove or alter any copyright, trademark, br/and elements or other
              proprietary notices.
            </p>
          </li>
          <li data-list-text="2.">
            <p className={`${classes.paragraph}`}>
              Reproduce, modify, prepare derivative works based upon,
              distribute, license, lease, sell, resell, transfer, publicly
              display, publicly perform, transmit, stream, br/oadcast or
              otherwise exploit the RhynoCare Platform except as expressly
              permitted by RhynoCare.
            </p>
          </li>
          <li data-list-text="3.">
            <p className={`${classes.paragraph}`}>
              Decompile, reverse engineer or disassemble the RhynoCare Platform
              except as may be permitted by applicable law.
            </p>
          </li>
          <li data-list-text="4.">
            <p className={`${classes.paragraph}`}>
              Link to, mirror or frame any portion of the RhynoCare Platform.
            </p>
          </li>
          <li data-list-text="5.">
            <p className={`${classes.paragraph}`}>
              Cause or launch any programs or scripts for the purpose of
              scraping, indexing, surveying, or otherwise data mining any
              portion of the RhynoCare Platform, or unduly burdening or
              hindering the operation and/or functionality of any aspect of the
              RhynoCare Platform.
            </p>
          </li>
          <li data-list-text="6.">
            <p className={`${classes.paragraph}`}>
              Attempt to gain unauthorized access to or impair any aspect of the
              RhynoCare Platform, or its related systems or networks.
            </p>
          </li>
          <li data-list-text="7.">
            <p className={`${classes.paragraph}`}>
              Impersonate any person or entity, or otherwise misrepresent your
              affiliation with a person or entity.
            </p>
          </li>
          <li data-list-text="8.">
            <p className={`${classes.paragraph}`}>
              Use the RhynoCare Platform in a manner that violates any laws.
            </p>
          </li>
          <li data-list-text="9.">
            <p className={`${classes.paragraph}`}>
              You agree to refrain from abusive language or inappropriate
              conduct when communicating with anyone through the RhynoCare
              Platform, including Authorized Physicians and Authorized
              Non-Physician Providers.
            </p>
          </li>
          <li data-list-text="10.">
            <p className={`${classes.paragraph}`}>
              You agree that you will not contact Authorized Physicians and
              Authorized Non-Physician Providers in respect of Healthcare
              Services or Informational Services except through the RhynoCare
              Platform.
            </p>
          </li>
        </ol>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          For clarity, RhynoCare has no interest in or control over any
          interaction that you may have with Authorized Physicians and
          Authorized Non-Physician
        </p>
        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          Providers outside the RhynoCare Platform and unrelated to Healthcare
          Services or Informational Services.
        </p>

        <h3 className={`${classes.paragraph}`}>
          <a name="a13">Termination</a>
        </h3>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          As set out above, RhynoCare reserves the right to terminate any User’s
          access or use of the RhynoCare Platform at any time, for any reason.
          If your User Account is terminated, RhynoCare will have no further
          obligation to provide access to the RhynoCare Platform, except to the
          extent there is an obligation to provide you with access to your
          information, or we are requested to do so, for a limited period, by an
          Authorized Physician or Authorized Non-Physician Provider in
          connection with their professional obligations to you.
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          You may deactivate your User Account at any time, for any reason, by
          logging in to your User Account at https://app.RhynoCare.com/signin.
          Please see our Privacy Policy in relation to our management of
          information on the deactivation of a User Account.
        </p>

        <h3 className={`${classes.paragraph}`}>
          <a name="a14">Fees/Physician Payment/ Payment Options</a>
        </h3>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          Certain Healthcare Services are provided for a fee. The fees for
          Healthcare Services are only for those elements of Healthcare Services
          not covered by the provincial health plan in your Province or
          Territory of residence. We do not charge for services that are covered
          by provincial health plans. Where you have questions about fees
          charged for Healthcare Services, please contact us.
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          You understand that you will be charged a fee for certain Healthcare
          Services and/or Informational Services you receive from an Authorized
          Physician and/or an Authorized Non-Physician Provider, unless the fees
          are paid by someone else (e.g. your employer) as permitted under these
          Terms. There are two options available for payment: pay-per-visit or
          membership. These options are described below.
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          RhynoCare facilitates payment of the fees to the Authorized Physician
          or Authorized Non-Physician Provider. Payment of the fees through the
          credit card validation and processing arrangement described below
          shall be
        </p>
        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          considered the same as payment made directly to the Authorized
          Physician or Authorized Non-Physician Provider. All fees for
          Healthcare Services and Informational Services are in Canadian dollars
          and inclusive of all applicable taxes.
        </p>

        <p
          class="s2"
          className={`${(classes.textAlignLeft, classes.paddingLeft)}`}
          style={{ textDecorationLine: "underline" }}
        >
          Pay-per-visit option:
          <span class="p">
            You may pay the fees for Healthcare Services and Informational
            Services (“Fees”) at the time you request Healthcare Services and/or
            Informational Services. RhynoCare requires you to submit credit card
            information prior to any Healthcare Services or Informational
            Services being provided to you for the purpose of validation of the
            card.
          </span>
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          You will not be able to begin accessing Healthcare Services or
          Informational Services through the RhynoCare Platform if the credit
          card information you provide is inaccurate and/or if your credit card
          is declined at the point of validation.
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          You agree that RhynoCare, on behalf of Authorized Physicians and
          Authorized Non-Physician Providers, may validate your credit card and
          put a hold on it in the amount of the Fees. RhynoCare will notify you
          of any applicable Fees and will only have the charge processed to your
          credit card after you have obtained the corresponding Healthcare
          Services or Informational Services. RhynoCare will provide you with a
          receipt for the Fees you pay.
        </p>

        <p
          class="s2"
          className={`${classes.paragraph}`}
          style={{ textDecorationLine: "underline" }}
        >
          Membership option:
          <span class="p">RhynoCare may allow you to purchase access</span>
        </p>
        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          to certain specified Healthcare Services or Informational Services
          during a fixed membership term for a flat fee (“Membership Fee”).
          RhynoCare will notify you of the applicable Membership Fee and the
          Healthcare Services and Informational Services that are covered by the
          Membership Fee in any given year, and will have the Membership Fee
          processed to your credit card before the first time you obtain
          Healthcare Services or Informational Services under your membership.
          For the duration of your membership, you will not be charged
          additional fees for any Healthcare Services or Informational Services
          covered by your membership. The only additional fees would be for
          Healthcare Services or Informational Services you request that are
          outside of your membership and accordingly not covered by your
          Membership Fee. For access to Healthcare Services or Informational
          Services not covered by your membership, you must use the
        </p>
        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          pay-per-visit-option. Your membership will automatically renew until
          cancelled by you in the Billing section of your Account Settings. In
          the event of an increase in the Membership Fee, we will notify you in
          advance, and ask whether you want to continue your membership. Your
          Membership Fees are non-refundable, except where RhynoCare terminates
          your access to the RhynoCare Platform, in which case RhynoCare will
          give you a pro rata refund of your Membership Fee. For clarity,
          RhynoCare will refund the portion of your Membership Fee corresponding
          to the number of months remaining on your membership on the date of
          termination.
        </p>

        <p class="s6" className={`${classes.paragraph}`}>
          <a name="a15">RhynoGo Refunds</a>
        </p>

        <p class="s1" className={`${classes.paragraph}`}>
          For canceled appointments, if more than 24 hours prior, payment will
          be refunded minus a service charge of $10 per person per transaction.
        </p>
        <p class="s1" className={`${classes.paragraph}`}>
          Cancellations on the same day will incur a fee of 50% of the charges
          per person per
        </p>
        <p class="s1" className={`${classes.paragraph}`}>
          service.
        </p>

        <h3 className={`${classes.paragraph}`}>
          <a name="a16">Third Party Payment/Coverage</a>
        </h3>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          RhynoCare may permit a Third Party (e.g. employer) to pay the Fees
          and/ or Membership Fees for some or all of the Healthcare Services or
          Informational Services provided to a User or group of Users. In these
          circumstances, RhynoCare has a contract with the Third Party, under
          which the Third Party discloses the name and contact information,
          including the email address, of the User or Users who have agreed to
          register with RhynoCare to obtain Healthcare Services and/or
          Informational Services, and for whom the Third Party will pay the Fees
          or Membership Fees for some or all of the Healthcare Services or
          Informational Services they receive. The payment option will be
          determined by the Third Party and RhynoCare. RhynoCare is not a party
          to and has no control over the agreement between any User and a Third
          Party payer. Coverage by a Third Party does not release a User from
          the obligation to comply with these Terms except the Terms related to
          his or her direct payment of the Fees or Membership Fees. RhynoCare
          will inform Users of any changes to our termination of their Third
          Party coverage. Users whose Third Party coverage is terminated may be
          given the option of continuing to use the RhynoCare Platform under
          their existing account so long as they pay the Fees or Membership
          Fees.
        </p>

        <h3 className={`${classes.paragraph}`}>
          <a name="a17">Disclaimer</a>
        </h3>

        <p className={`${classes.paragraph}`}>
          The Rhynocare Platform Is Provided “As Is” And “As Available.”
          Rhynocare
        </p>
        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          Warranties Of Merchantability, Fitness For A Particular Purpose And
          Non- Infringement. In Addition, Rhynocare Makes No Representation,
          Warranty, Or Guarantee Regarding The Reliability, Timeliness, Quality,
          Suitability Or Availability Of The Services Requested Through The Use
          Of The Rhynocare Platform, Or That The Services Will Be Uninterrupted
          Or Error- Free. Rhynocare Does Not Guarantee The Quality, Suitability
          Or Safety Of Healthcare Services Or Informational Services. You Agree
          To Assume All Risk Arising Out Of Your Use Of The Rhynocare Platform,
          And Any Risk Related To The Healthcare Services And Informational
          Services.
        </p>

        <h3 className={`${classes.paragraph}`}>
          <a name="a18">Limitation Of Rhynocare’s Liability</a>
        </h3>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          Under No Circumstance Shall Rhynocare Be Liable For Indirect,
          Incidental, Special, Exemplary, Punitive Or Consequential Damages,
          Including Professional Negligence, Personal Injury, Lost Income Or
          Lost Data, Related To, In Connection With, Or Otherwise Resulting From
          Any Use Of The Rhynocare Platform And/Or Health Care Services And/Or
          Informational Services Provided By Authorized Physicians And/Or
          Authorized Non- Physician Providers. Rhynocare, Its Directors,
          Officers, Employees, Shareholders, Affiliates, Agents And Independent
          Third Party Contractors Shall Not Be Liable For Any Damages, Liability
          Or Losses Arising Out Of:
        </p>
        <ol id="l4">
          <li data-list-text="(1)">
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              Your Use Of Or Reliance On The Rhynocare Platform And/Or
              Healthcare Services And/Or Informational Services, Or Your
              Inability To Access Or Use The Rhynocare Platform And/Or
              Healthcare Services And/ Or Informational Services; Or (2) Any
              Transaction Or Relationship Between You And Any Authorized
              Physician Or Any Authorized Non-Physician Provider. Rhynocare
              Shall Not Be Liable For Delay Or Failure In Performance Resulting
              From Causes Beyond Rhynocare’s Reasonable Control. You Agree That
              Rhynocare Has No Responsibility Or Liability To You Related To
              Your Use Of The Rhynocare Site Or The Healthcare Services And/Or
              Informational Services Provided To You By Authorized Physicians
              And Authorized Non-Physician Providers.
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              No liability for computers or networks used to access your account
              RhynoCare is not liable for your information stored or recorded by
              any computer, tablet, mobile device or any network, whether public
              or private, that you may use to access the RhynoCare Platform.
            </p>
            <h3 className={`${( classes.paddingLeft)}`}>
              <a name="a19">Indemnity</a>
            </h3>

            <p className={`${( classes.paddingLeft)}`}>
              You agree to indemnify and hold RhynoCare (which in this Section
              includes its directors, officers, employees, agents and
              shareholders) harmless from any and all claims, demands, losses,
              liabilities, and expenses (including reasonable legal fees)
              arising out of or in connection with: (i) your use of the
              RhynoCare Platform, Healthcare Services and/or Informational
              Services obtained through your use of the RhynoCare Platform; (ii)
              your br/each or violation of any of these Terms; or (iii) your
              violation of the rights of any third party, including Authorized
              Physicians and/or Authorized Non- Physician Providers, other
              Users, any person on whose behalf you request Healthcare Services
              and/or Informational Services, and/or referred friends. Through
              the RhynoCare Platform, you may use certain services that are not
              provided by RhynoCare and that we identify to you for your
              convenience, e.g., prescription delivery services. To the extent
              that a service is not provided by RhynoCare, you will be given
              notice prior to using that service, and if you elect to proceed,
              you acknowledge and agree that you will be contracting with the
              service provider and not RhynoCare.
            </p>

            <h3 className={`${classes.paragraph}`}>
              <a name="a20">General Provisions</a>
            </h3>

            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Entire Agreement
            </p>
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              These Terms constitute the entire agreement and understanding of
              RhynoCare and you with respect to their subject matter and
              replaces and supersedes all prior or contemporaneous agreements or
              undertakings regarding the subject matter.
            </p>

            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Governing Law
            </p>
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              These Terms are governed by the laws of Ontario and the laws of
              Canada applicable therein, excluding any conflict of laws which
              would lead to the application of any other laws. By accessing the
              RhynoCare Platform, you agree that any disputes or matters arising
              from, connected with, or related to these Terms shall be br/ought
              to the provincial and federal courts located in the City of
              Toronto, Ontario.
            </p>

            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Non-assignment
            </p>
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              These Terms are not assignable, transferable, or to be
              sub-licensed by you except with RhynoCare’s prior written consent.
              RhynoCare may assign,
            </p>
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              transfer, or delegate any of its rights and obligations hereunder
              without your consent.
            </p>

            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Links to External Websites
            </p>
            <p className={`${classes.paragraph}`}>
              Links on RhynoCare’s Platform to other websites or resources
              including those operated by parties other than RhynoCare, are
              provided for your convenience. RhynoCare is not responsible for
              the availability of such websites or resources and does not
              endorse or accept responsibility for the content of such external
              websites or resources and has no responsibility for or control
              over the terms of use or privacy policy (if any) of the operators
              of the external websites or resources. Your access and viewing of
              any third party websites or resources is conducted at your own
              risk. RhynoCare is not responsible even where we link you to those
              resources or refer you for your convenience.
            </p>

            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Severability
            </p>
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              If any provision in these terms is held to be invalid, void, or
              unenforceable, such provision (or the part of it that is making it
              invalid, void or unenforceable) will be struck and not affect the
              validity of and enforceability of the remaining provisions.
            </p>

            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Contact Us
            </p>
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              If you have questions about the RhynoCare Platform or its use, you
              can contact RhynoCare Support by sending an email
            </p>
            <p className={`${classes.paragraph}`}>
              <a href="mailto:info@RhynoCare.com" class="a" target="_blank">
                to{" "}
              </a>
              <a href="mailto:info@RhynoCare.com" class="s7" target="_blank">
                info
              </a>
              <a href="mailto:info@RhynoCare.com" target="_blank">
                @RhynoCare.com
              </a>
            </p>

            <h2
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Rhynocare United States/International Terms of Use
            </h2>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              Terms applicable only to individuals seeking services in the
              United States of America or from other International countries.
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              These terms and conditions of use (“Terms of Use”) govern your use
              of interfaces and properties (e.g., websites and mobile
              applications) licensed by RhynoCare and products (“Products”)
              available to users through the Platform. RhynoCare (“we,” “us,”
              and “our”) contracts with RhynoCare regarding online telehealth
              medical consultations and secure messaging
            </p>
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              between RhynoCare physicians and other healthcare professionals
              (individually the “Provider” and collectively the “Providers”) and
              their patients. The professional medical services and the
              non-clinical Site services (which are provided by RhynoCare) are
              collectively referred to in this Terms of Use as the “Services”.
              The terms “you” and “your” means you, your dependent(s) if any,
              and any other person accessing your RhynoCare Account.
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              Your acceptance of, and compliance with, these Terms of Use is a
              condition to your use of the Site and Services and purchase of
              Products. By clicking “Sign Up” and or “Register”, you acknowledge
              that you have read, understand, and accept all terms and
              conditions contained within the Terms of Use, Notice of Privacy
              Practices and Privacy Policy. If you do not agree to be bound by
              these terms, you are not authorized to access or use this Site or
              Services; promptly exit this Site.
            </p>

            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Binding Arbitration
            </p>
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              These Terms of Use provide that all disputes between you and
              RhynoCare that in any way relate to these Terms of Use or your use
              of the Site will be resolved by BINDING ARBITRATION. ACCORDINGLY,
              YOU AGREE TO GIVE UP YOUR RIGHT TO GO TO COURT (INCLUDING IN A
              CLASS
            </p>
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              ACTION PROCEEDING) to assert or defend your rights under these
              Terms of Use. Your rights will be determined by a NEUTRAL
              ARBITRATOR and NOT a judge or jury and your claims cannot be
              br/ought as a class action.
            </p>
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              Please review the Section below entitled Dispute Resolution;
              Arbitration Agreement for the details regarding your agreement to
              arbitrate any disputes with RhynoCare.
            </p>

            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Privacy Practices
            </p>
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              You agree that information provided by you in connection with the
              Services and Site shall be governed by the RhynoCare Privacy
              Policy which is hereby incorporated and made a part of this
              Agreement. You agree that information provided by you in
              connection with the Services shall also be governed by RhynoCare’s
              Notice of Privacy Practices and is hereby incorporated and made a
              part of this Agreement.
            </p>

            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Services Provided
            </p>
            <h3 className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              <a name="a21">No Medical Care or Advice by RhynoCare.</a>
            </h3>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              We offer an online communication platform for Providers and their
              patients to connect via the Site through the use of synchronous
              and asynchronous telecommunications technologies. The Site
              facilitates communication between patients and Providers.
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              RhynoCare does not provide medical advice or care. Rhynocare
              Providers deliver clinical services via the RhynoCare Platform to
              their patients.
            </p>
            <p className={`${classes.paragraph}`}>
              Providers are not contracted or employed by RhynoCare.
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              Providers are not contracted or employed by RhynoCare. The
              Providers, and not RhynoCare, are responsible for the quality and
              appropriateness of the care they render to you.
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              The Providers are independent of RhynoCare and are merely using
              the Site as a way to communicate with you. Any information or
              advice received from a Provider comes from them alone, and not
              from RhynoCare. Your interactions with the providers via the Site
              are not intended to take the place of your relationship with your
              regular health care practitioners or primary care physician.
              Neither RhynoCare, nor any of its subsidiaries or affiliates or
              any third party who may promote the Site or Service or provide a
              link to the Service, shall be liable for any professional advice
              obtained from a Provider via the Site or Service, nor any
              information obtained on the Site. RhynoCare does not recommend or
              endorse any specific Providers, tests, physicians, medications,
              products, or procedures. You acknowledge that your reliance on any
              Providers or information delivered by the Providers via the Site
              or Service is solely at your own risk and you assume full
              responsibility for all risks associated herewith.
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              RhynoCare does not make any representations or warranties about
              the training or skill of any Providers who deliver services via
              the Site or Service. You will be provided with available Providers
              based solely on the information you submit to the Site. You are
              ultimately responsible for choosing your particular Provider.
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              The content of the Site and the Services, including without
              limitation, text, copy, audio, video, photographs, illustrations,
              graphics and other visuals, is
            </p>
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              for informational purposes only and does not constitute
              professional medical advice, diagnosis, treatment, or
              recommendations of any kind by RhynoCare.
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              You should always seek the advice of your qualified health care
              professionals with any questions or concerns you may have
              regarding your individual needs and any medical conditions.
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              All information provided by RhynoCare, or in connection with any
              communications supported by RhynoCare, is intended to be for
              general information purposes only, and is in no way intended to
              create a provider- patient relationship as defined by state or
              federal law.
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              While RhynoCare facilitates your selection of, and communications
              with, Providers, RhynoCare does not provide medical services, and
              the doctor- patient relationship is between you and theProvider
              you select.
            </p>

            <p
              class="s5"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Not for Emergencies
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              IF YOU ARE EXPERIENCING A MEDICAL EMERGENCY, YOU SHOULD DIAL “911”
              IMMEDIATELY.
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              RhynoCare’s Site and Services are not for medical emergencies or
              urgent situations. You should not disregard or delay to seek
              medical advice based on anything that appears or does not appear
              on the Site. If you believe you have an emergency, call 9-1-1
              immediately.
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              You should seek emergency help or follow up care when recommended
              by a Provider or when otherwise needed. You should continue to
              consult with your primary provider and other healthcare
              professionals as recommended.
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              Always seek the advice of a physician or other qualified
              healthcare provider concerning questions you have regarding a
              medical condition and before stopping, starting, or modifying any
              treatment or modification.
            </p>

            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Risks of Telehealth Services
            </p>
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              By using the Services, you acknowledge the potential risks
              associated with telehealth services. These include but are not
              limited to the following: information transmitted may not be
              sufficient (e.g. poor resolution of images) to allow for
              appropriate medical or health care decision making by the
              Provider; delays in evaluation or treatment could occur due to
              failures of electronic equipment; a lack of access to your medical
              records may result in adverse drug interactions or allergic
              reactions or other judgment errors; although the electronic
              systems we use incorporate network and software security protocols
              to protect the privacy and security of health information, those
              protocols could fail causing a br/each of privacy of your health
              information.
            </p>

            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Prescription Policy
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              RhynoCare does not endorse any specific medication, pharmacy, or
              pharmacologic product. If a Provider prescribes a medication,
              he/she will limit supply based upon state regulations and will
              only prescribe a medication as determined in his/her own
              discretion and professional judgment. There is no guarantee a
              prescription will be written. Providers do not prescribe DEA
              controlled substances or scheduled medications, or certain other
              drugs which may be harmful because of their potential for abuse.
              Providers reserve the right to deny care for actual or potential
              misuse of the Services.
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              You agree that any prescriptions that you acquire from a Provider
              will be solely for your personal use. You agree to fully and
              carefully read all provided product information and labels and to
              contact a physician or pharmacist if you have any questions
              regarding the prescription.
            </p>
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              RhynoCare fully honor’s patient freedom of choice and, if you
              receive a prescription for a medication, you always have the
              option to instruct RhynoCare to transmit that prescription to the
              pharmacy of your choice.
            </p>

            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Not an Insurance Product
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              RhynoCare is not an insurer. The Services are not insurance
              products, and the amounts you pay to RhynoCare are not insurance
              premiums. If you desire any type of health or other insurance, you
              will need to purchase such insurance separately.
            </p>

            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Availability of Services
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              RhynoCare operates subject to state and federal regulations, and
              the Services may not be available in your state. You represent
              that you are not a person barred from enrolling for and/or
              receiving the Services under the laws of the United States or
              other applicable jurisdictions in which you may be located. Access
              to and use of the Site and/or the Services is limited exclusively
              to users located in States within the United States where the
              Services are available. Services are not available to users
              located outside the United States. Accessing the Site or Services
              from jurisdictions where content is illegal, or where we do not
              offer Services, is prohibited.
            </p>

            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Eligibility, Site Access, Security and Restrictions
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              In order to access the Site and the Services, you represent and
              warrant that you are older than 18 years of age. You agree to
              fully, accurately, and truthfully create your RhynoCare Account
              (“Account”), including but not limited to your name, mailing
              address, phone number, email address, and password, which become
              your RhynoCare ID and credentials.
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              The RhynoCare ID and/or credentials are personal to you, and you
              are solely responsible for maintaining the confidentiality of your
              RhynoCare ID and/or credentials, and for all activities that occur
              under such RhynoCare ID and/or credentials. You agree to prohibit
              anyone else from using your RhynoCare ID and/or credentials and
              agree to immediately notify RhynoCare of any actual or suspected
              unauthorized use of your RhynoCare ID and/or credentials or other
              security concerns of which you become aware. Your access to the
              Site may be revoked by RhynoCare at any time with or without
              cause.
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              You are prohibited from violating or attempting to violate the
              security of the Site, including, without limitation, (a) accessing
              data not intended for such user or logging onto a server or an
              account which the user is not authorized to access; or (b)
              attempting to probe, scan or test the vulnerability of a system or
              network or to br/each security or authentication measures without
              proper authorization; or (c) accessing or using the Site or any
              portion
            </p>
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              thereof without authorization, in violation of these Terms of Use
              or in violation of applicable law.
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              You may not use any scraper, crawler, spider, robot or other
              automated means of any kind to access or copy data on the Site,
              deep-link to any feature or content on the Site, bypass our robot
              exclusion headers or other measures we may use to prevent or
              restrict access to the Site. Violations of system or network
              security may result in civil or criminal liability.
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              RhynoCare will investigate occurrences that may involve such
              violations and may involve, and cooperate with, law enforcement
              authorities in prosecuting users who are involved in such
              violations. You agree not to use any device, software or routine
              to interfere or attempt to interfere with the proper working of
              this Site or any activity being conducted on this Site.
            </p>

            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Electronic Communications
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              When you use the Site or Services, or send e-mails, messages, and
              other communications from your desktop or mobile device to us, you
              are communicating with us electronically. You consent to receive
              communications from us electronically. You agree that (a) all
              agreements and consents can be signed electronically and (b) all
              notices, disclosures, and other communications that we provide to
              you electronically satisfy any legal requirement that such notices
              and other communications be in writing. In addition, you agree to
              receive results electronically by e-mail.
            </p>
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              RhynoCare may contact you by telephone, mail, or email to verify
              your account information. RhynoCare may request further
              information from you and you agree to provide such further
              information to ensure that you have not fraudulently created your
              Account. If you do not provide this information in the manner
              requested within 14 days of the request, we reserve the right to
              suspend, discontinue, or deny your access to and use of the Site
              and the Services until you provide the information to us as
              requested.
            </p>

            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Consent to Receive Calls and Text Messages
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              By providing your mobile number, you are agreeing to be contacted
              by or on behalf of RhynoCare at the mobile number you have
              provided, including calls and text messages, to receive
              informational, Product or Service related (e.g., progress
              tracking, refill reminders, checkup reminders, etc.)
            </p>
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              and marketing communications relating to the Site and Services.
              Message and data rates may apply. For help, text the word HELP to
              (4<span class="s1">16</span>)<span class="s1">830</span>-
              <span class="s1">8181</span>. To stop receiving text messages text
              the word STOP to (4<span class="s1">16</span>)
              <span class="s1">830-8181</span>. We may confirm your opt out by
              text message. If you subscribe to multiple types of text messages
              from us, we may unsubscribe you from the service that most
              recently sent you a message and/or respond to your STOP message by
              texting you a request to identify services you wish to stop.
            </p>
            <p className={`${classes.paragraph}`}>
              Keep in mind that if you stop receiving text messages from us you
              may not receive important and helpful information and reminders
              about your progress and treatment.
            </p>

            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Consent To Testing
            </p>

            <p className={`${classes.paragraph}`}>
              By booking through our platform, you and all members being booked
              by you automatically agree to consent to all testing services
              provided by our staff, for the testing you are booking for.
            </p>

            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Ownership Of The Site And Related Materials; Additional
              Restrictions
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              All pages within this Site and any material made available for
              download are the property of RhynoCare, or its licensors or
              suppliers, as applicable. The Site is protected by United States
              and international copyright and trademark laws.
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              All rights not expressly granted to you in these Terms of Use are
              reserved and retained by RhynoCare or its licensors, suppliers,
              publishers, rights holders, or other content providers. Neither
              the Site and Services, nor any part of the Site and Services, may
              be reproduced, duplicated, copied, sold, resold, visited, or
              otherwise exploited for any commercial purpose without express
              written consent of RhynoCare. You may not frame or utilize framing
              techniques to enclose any trademark, logo, or other proprietary
              information (including images, text, page layout, or form) of
              RhynoCare without express written consent. You may not use any
              meta tags or any other “hidden text” utilizing RhynoCare’s name or
              trademarks without the express written consent of RhynoCare. You
              may not misuse the Site or Services. You may use the Site and
              Services only as permitted by law. The content of the Site,
              including without limitation the files, documents, text,
              photographs, images, audio, and video, and any materials accessed
              through or made available for use or download through this Site
              may not be copied, distributed, modified, reproduced, published or
              used, in whole or in part, except for purposes authorized or
              approved in writing by RhynoCare. You may not frame or
            </p>
            <p className={`${classes.paragraph}`}>
              utilize framing techniques to enclose, or deep linking to, any
              name, trademarks, service marks, logo, content or other
              proprietary information (including; images, text, page layout, or
              form) of RhynoCare without our express written consent.
            </p>

            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              No Users Under 1<span class="s8">6 </span>
              Years Old
            </p>

            <p className={`${classes.paragraph}`}>
              The Site and Services are only for users of the age of 1
              <span class="s1">6</span>. If you are under the age of 1
              <span class="s1">6</span>, please do not attempt to register with
              us at this Site or provide any personal information about yourself
              to us. If we learn that we have collected personal information
              from someone under the age of 1<span class="s1">6</span>, we will
              promptly delete that information. If you believe we have collected
              personal information from someone under the age of 1
              <span class="s1">6</span>, please Contact us at{" "}
              <span class="s1">(416)830-8181</span>.
            </p>

            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Accuracy of Information; Functionality
            </p>

            <p className={`${classes.paragraph}`}>
              Although RhynoCare attempts to ensure the integrity and
              accurateness of the Site and Product descriptions, it makes no
              representations, warranties or guarantees whatsoever as to the
              correctness or accuracy of the Site, Product descriptions and
              other content on the Site. It is possible that the Site could
              include typographical errors, inaccuracies or other errors, and
              that unauthorized additions, deletions and alterations could be
              made to the Site by third parties. In the event that an inaccuracy
              arises, please inform RhynoCare so that it can be corrected. If a
              Product described on our Site is not as described when you receive
              it, or the packaging on the Site does not match the product to
              receive, your sole remedy is to return it to us in unused and
              undamaged condition. Information contained on the Site may be
              changed or updated without notice. Additionally, RhynoCare shall
              have no responsibility or liability for information or content
              posted to the Site from any non-RhynoCare affiliated third party.
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              RhynoCare reserves complete and sole discretion with respect to
              the operation of the Site and the Services. We may withdraw,
              suspend, or discontinue any functionality or feature of the Site
              or the Services among other things. We are not responsible for
              transmission errors, corruption, or compromise of information
              carried over local or interchange telecommunications carrier. We
              are not responsible for maintaining information arising from use
              of the Site or with respect to the Services. We reserve the right
              to maintain, delete, or destroy all communications or
            </p>
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              information posted or uploaded to the Site or the Services in
              accordance with our internal record retention and/or destruction
              policies.
            </p>

            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Links to Other Sites
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              RhynoCare makes no representations whatsoever about any other
              website that you may access through this Site. When you access a
              non-RhynoCare site, please understand that it is independent from
              RhynoCare, and that RhynoCare has no control over the content on
              that website. In addition, a link to a non-RhynoCare website does
              not mean that RhynoCare endorses or accepts any responsibility for
              the content, or the use, of the linked site. It is up to you to
              take precautions to ensure that whatever you select for your use
              or download is free of such items as viruses, worms, Trojan
              horses, and other items of a destructive nature. If you decide to
              access any of the third party sites linked to this Site, you do
              this entirely at your own risk.
            </p>

            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              User Information
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              If you submit, upload, post or transmit any health information,
              medical history, conditions, problems, symptoms, personal
              information, consent forms, agreements, requests, comments, ideas,
              suggestions, information, files, videos, images or other materials
              to us or our Site (“User Information”), you agree not to provide
              any User Information that (1) is false, inaccurate, defamatory,
              abusive, libelous, unlawful, obscene, threatening, harassing,
              fraudulent, pornographic, or harmful, or that could encourage
              criminal or unethical behaviour, (2) violates or infringes the
              privacy, copyright, trademark, trade dress, trade secrets or
              intellectual property rights of any person or entity, or (3)
              contains or transmits a virus or any other harmful component. You
              agree not to contact other site users through unsolicited e-mail,
              telephone calls, mailings or any other method of communication.
              You represent and warrant to RhynoCare that you have the legal
              right and authorization to provide all User Information to
              RhynoCare for use as set forth herein and required by the
              RhynoCare Provider.
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              You agree not to (i) access the Site or use the Services in any
              unlawful way or for any unlawful purpose; (ii) post or transmit
              (a) a message under a false name, or (b) any data, materials,
              content, or information (including,
            </p>
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              without limitation, advice, and recommendations) (collectively
              “Information”) which is (1) libelous, defamatory, obscene,
              fraudulent, false, or contrary to the ownership or intellectual
              property rights of any other person, or (2) contains or promotes
              any virus, worm, Trojan horse, time bomb, malware, or other
              computer programming or code that is designed or intended to
              damage, destroy, intercept, download, interfere, manipulate, or
              otherwise interrupt or expropriate the Site or the Services,
              personal information, software, equipment, servers, or Information
              or facilitate or promote hacking or similar conduct; (iii)
              impersonate or misrepresent your identity or falsely state or
              misrepresent your affiliation with a person or entity; (iv)
              tamper, hack, spoof, copy, modify, or otherwise corrupt the
              administration, security, or proper function of the Site or the
              Services; (v) use robots or scripts with the Site; (vi) attempt to
              reverse engine, reverse assemble, reverse compile, decompile,
              disassemble, translate, or otherwise alter, defraud, or create
              false results from any executable code, information on, or
              received by this Site; (vii) to have any antivirus or anti-spyware
              software running that is set to override the internet br/owser’s
              cookies setting; (viii) incorrectly identify the sender of any
              message transmitted to RhynoCare.
            </p>
            <p className={`${classes.paragraph}`}>
              You may not alter the attribution or origin of electronic mail,
              messages, or
            </p>
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              posting; (ix) harvest or collect Protected Health Information
              about any other individual who uses the Site or the Services; (x)
              infringe or facilitate infringement on any copyright, patent,
              trademark, trade secret, or other proprietary, publicity, or
              privacy rights of any party, including such rights of third
              parties.
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              You agree to defend, indemnify and hold harmless RhynoCare and the
              Providers from and against all third party claims, damages and
              expenses (including reasonable attorneys’ fees) against or
              incurred by us arising out of any User Information you upload to
              or transmit through the Site.
            </p>

            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Claims of Copyright Infringement
            </p>

            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              We disclaim any responsibility or liability for copyrighted
              materials posted on our site. If you believe that your work has
              been copied in a manner that constitutes copyright infringement,
              please follow the procedures set forth below.
            </p>
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              RhynoCare respects the intellectual property rights of others and
              expects its users to do the same. In accordance with the Digital
              Millennium Copyright Act (“DMCA”), we will respond promptly to
              notices of alleged infringement that are reported to RhynoCare’s
              Designated Copyright Agent, identified below.
            </p>
            <p
              class="s2"
              className={`${classes.paragraph}`}
              style={{ textDecorationLine: "underline" }}
            >
              Notices of Alleged Infringement for Content Made Available on the
              Site
              <span class="p">
                If you are a copyright owner, authorized to act on behalf of
                one, or
              </span>
            </p>
            <p className={`${classes.paragraph}`}>
              authorized to act under any exclusive right under copyright,
              please report
            </p>
            <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
              alleged copyright infringements taking place on or through our
              Site by sending us a notice (“Notice”) complying with the
              following requirements.
            </p>

            <ol id="l5">
              <li data-list-text="1.">
                <p className={`${classes.paragraph}`}>
                  Identify the copyrighted works that you claim have been
                  infringed.
                </p>
              </li>
              <li data-list-text="2.">
                <p className={`${classes.paragraph}`}>
                  Identify the material or link you claim is infringing (or the
                  subject of infringing activity) and that access to which is to
                  be disabled, including at a minimum, if applicable, the URL of
                  the link shown on the Site where such material may be found.
                </p>
              </li>
              <li data-list-text="3.">
                <p className={`${classes.paragraph}`}>
                  Provide your mailing address, telephone number, and, if
                  available, email address.
                </p>
              </li>
              <li data-list-text="4.">
                <p className={`${classes.paragraph}`}>
                  Include both of the following statements in the body of the
                  Notice: “I hereby state that I have a good faith belief that
                  the disputed use of the copyrighted material is not authorized
                  by the copyright owner, its agent, or the law (e.g., as a fair
                  use).”
                </p>

                <p className={`${classes.paragraph}`}>
                  “I hereby state that the information in this Notice is
                  accurate and, under penalty of perjury, that I am the owner,
                  or authorized to act on behalf of the owner, of the copyright
                  or of an exclusive right under the copyright that is allegedly
                  infringed.”
                </p>
              </li>
              <li data-list-text="5.">
                <p className={`${classes.paragraph}`}>
                  Provide your full legal name and your electronic or physical
                  signature.
                </p>
              </li>
              <li data-list-text="6.">
                <p className={`${classes.paragraph}`}>
                  Deliver this Notice, with all items completed, to our
                  Copyright Agent:
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p className={`${classes.paragraph}`} style={{ paddingLeft: "42px" }}>
          RhynoCare Incorporated
        </p>
        <p className={`${classes.paragraph}`} style={{ paddingLeft: "42px" }}>
          ATTN: Chief Compliance Officer
        </p>
        <p
          class="s2"
          className={`${classes.paragraph}`}
          
        >
          <a href="mailto:info@Rhynocare.com" class="s9" target="_blank" style={{ textDecorationLine: "underline" }}>
            info@Rhynocare.com <span class="p"></span>
          
          </a>
          Intellectual Property
        </p>
        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          With the exception of your electronic medical record, RhynoCare
          retains all rights, title, and interest in and to the Site, the
          Services and any information, products, documentation, software, or
          other materials on the Site, and any patent, copyright, trade secret,
          trademark, service mark, or other intellectual property, or
          proprietary right in any of the foregoing, except for information on
          the Site licensed by RhynoCare. The information available through the
          Site and the Services is the property of RhynoCare.
        </p>
        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          You agree not to store, copy, modify, reproduce, retransmit,
          distribute, disseminate, rent, lease, loan, sell, publish, br/oadcast,
          display, or circulate such information to anyone. Use, reproduction,
          copying, or redistribution of RhynoCare trademarks, service marks, and
          logos are strictly prohibited without the prior written permission of
          RhynoCare. The immediately foregoing sentence also applies to any
          third party trademarks, service marks, and logos posted on the Site.
          Nothing contained on the Site should be construed as granting, by
          implication, estoppel, waiver or otherwise, any license or right to
          use any trademarks, service marks or logos displayed on the Site
          without the written grant thereof by RhynoCare or the third party
          owner of such trademarks, service marks, and/or logos. The Site may
          contain other proprietary notices and copyright information, the terms
          of which you agree to follow.
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          RhynoCare may delete any information provided by you that it deems in
          its sole discretion fraudulent, abusive, defamatory, obscene, or in
          violation of copyright, trademark, or other intellectual property or
          ownership right of any other person or entity.
        </p>

        <h3 className={`${classes.paragraph}`}>
          <a name="a22">Disclaimer of Warranties</a>
        </h3>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          RHYNOCARE DOES NOT WARRANT THAT ACCESS TO OR USE OF THE SITE WILL BE
          UNINTERRUPTED OR ERROR-FREE OR THAT DEFECTS IN THE SITE WILL BE
          CORRECTED. THIS SITE, INCLUDING
        </p>
        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          ANY CONTENT OR INFORMATION CONTAINED WITHIN IT OR ANY SITE-RELATED
          SERVICE, IS PROVIDED “AS IS,” WITH ALL FAULTS, WITH NO REPRESENTATIONS
          OR WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT
          NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
          A PARTICULAR PURPOSE, QUALITY OF INFORMATION, QUIET ENJOYMENT, AND
          TITLE/NON-INFRINGEMENT. RHYNOCARE DOES NOT WARRANT THE ACCURACY,
          COMPLETENESS OR TIMELINESS OF THE INFORMATION OBTAINED THROUGH THE
          SITE.
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR USE OF THIS SITE,
          SITE-RELATED SERVICES, AND LINKED WEBSITES. RHYNOCARE DOES NOT WARRANT
          THAT FILES AVAILABLE FOR DOWNLOAD WILL BE FREE OF VIRUSES, WORMS,
          TROJAN HORSES OR OTHER DESTRUCTIVE PROGRAMMING. YOU ARE RESPONSIBLE
          FOR IMPLEMENTING PROCEDURES SUFFICIENT TO SATISFY YOUR NEEDS FOR DATA
          BACK UP AND SECURITY.
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          WARRANTIES RELATING TO PRODUCTS OR SERVICES OFFERED, SOLD AND
          DISTRIBUTED BY RHYNOCARE ARE SUBJECT TO SEPARATE WARRANTY TERMS AND
          CONDITIONS, IF ANY, PROVIDED BY RHYNOCARE OR THIRD PARTIES WITH OR IN
          CONNECTION WITH THE APPLICABLE PRODUCTS OR SERVICES.
        </p>

        <p
          class="s2"
          className={`${classes.paragraph}`}
          style={{ textDecorationLine: "underline" }}
        >
          Limitation of Liability Regarding Use of Site
        </p>

        <h3 className={`${classes.paragraph}`}>
          <a name="a23">EXCEPT AS PROVIDED BY LAW, AND WITHOUT LIMITATION:</a>
        </h3>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          RHYNOCARE SHALL NOT BE LIABLE FOR THE ACTS OR OMISSIONS OF THE
          PROVIDERS. RHYNOCARE AND ANY THIRD PARTIES MENTIONED ON THIS SITE ARE
          NEITHER RESPONSIBLE NOR LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
          CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE, OR OTHER DAMAGES
          WHATSOEVER (INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOST
          PROFITS, LOST DATA, OR BUSINESS INTERRUPTION) ARISING OUT OF OR
          RELATING IN ANY WAY TO THE SITE, SITE-RELATED SERVICES, CONTENT OR
          INFORMATION CONTAINED WITHIN THE SITE, AND/OR ANY LINKED WEBSITE,
        </p>
        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY
          AND WHETHER OR NOT ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOUR
          SOLE REMEDY FOR DISSATISFACTION WITH THE SITE, SITE-RELATED SERVICES,
          AND/ OR LINKED WEBSITES IS TO STOP USING THE SITE AND/OR THOSE
          SERVICES. TO THE EXTENT ANY ASPECTS OF THE FOREGOING LIMITATIONS OF
          LIABILITY ARE NOT ENFORCEABLE, THE MAXIMUM LIABILITY OF RHYNOCARE TO
          YOU WITH RESPECT TO YOUR USE OF THIS SITE IS $500 (FIVE HUNDRED
          DOLLARS). YOU HEREBY AGREE TO WAIVE, TO THE FULLEST EXTENT PERMITTED
          BY LAW, ALL LAWS THAT LIMIT THE EFFICACY OF SUCH INDEMNIFICATIONS OR
          RELEASES.
        </p>

        <p
          class="s2"
          className={`${classes.paragraph}`}
          style={{ textDecorationLine: "underline" }}
        >
          No Third Party Rights
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          Unless expressly stated in the Terms of Use to the contrary, nothing
          herein is intended to confer any rights or remedies on any persons
          other than you, RhynoCare and its affiliates. Nothing in the Terms of
          Use is intended to relieve or discharge the obligation or liability of
          any third persons to you, RhynoCare and its affiliates, nor shall any
          provision give any third parties any right of subr/ogation or action
          over against you, RhynoCare and its affiliates.
        </p>

        <p
          class="s2"
          className={`${classes.paragraph}`}
          style={{ textDecorationLine: "underline" }}
        >
          Assignment
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          You may not assign, transfer, or delegate the Terms of Use or any part
          thereof without RhynoCare’s prior written consent. RhynoCare may
          freely transfer, assign, or delegate all or any part of the Terms of
          Use, and any rights or duties hereunder or thereunder. The Terms of
          Use will be binding upon and inure to the benefit of the heirs,
          successors, and permitted assignees of the parties.
        </p>

        <p
          class="s2"
          className={`${classes.paragraph}`}
          style={{ textDecorationLine: "underline" }}
        >
          Supplemental Terms Applicable to Providers
        </p>
        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          These supplemental terms apply to Providers in addition the other
          provisions of these Terms of Use. In the event of a conflict between
          the supplemental terms and any other terms herein, the supplemental
          terms shall prevail.
        </p>
        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          To be a healthcare provider using the Site (“Provider” or “you”) you
          must be a licensed physician, nurse practitioner, or healthcare
          professional and must agree to comply with all laws, medical board
          rules and other rules and regulations applicable to you as a Provider
          or otherwise. Your relationship with the RhynoCare users (including
          your RhynoCare patients) is directly between you and the patient.
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          The patient will never have a physician-patient relationship with
          RhynoCare.
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          RhynoCare does not practice medicine and offers no medical services.
          As set forth more fully below,
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          Provider is solely responsible for all agreements, consents, notices
          and other interactions with patients and other consumers. Without
          limiting the generality of the foregoing, Provider and RhynoCare is
          solely responsible for all billings and collections from patients and
          other consumers, and RhynoCare shall have no liability whatsoever to
          Provider with respect to any amounts owed by any patient or other
          consumer to Provider.
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          RhynoCare does not provide any medical advice, legal advice, or
          representations in any way regarding any legal or medical issues
          associated with Provider, goods or services offered by Provider,
          including but not limited any compliance obligations or steps
          necessary to comply with any state or federal laws and regulations.
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          Provider should seek legal counsel regarding any legal and compliance
          issues, and should not rely on any materials or content associated
          with the Services in determining Provider’s compliance obligations
          under law.
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          Provider and RhynoCare agree that RhynoCare is not providing, to
          Customer or anyone else, medical advice or legal advice.
        </p>
        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          Provider will use the Site and Services only in accordance with
          applicable standards of good medical practice. While software products
          such as the Site and Services can facilitate and improve the quality
          of service that Provider can offer patients, many factors, including
          the provider/patient relationship can affect a patient outcome, and
          with intricate and interdependent technologies and complex
          decision-making it is often
        </p>
        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          difficult or impossible to accurately determine what the factors were
          and in what proportion they affected an outcome.
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          Provider shall be solely responsible for their use of the Site and
          Services, and the provision of medical services to Provider’s
          patients. In this regard, Provider releases RhynoCare and waives any
          and all potential claims against RhynoCare as a result of Provider’s
          use of the Site and Services, and the provision of services to
          Provider’s patients.
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          As a result of the complexities and uncertainties inherent in the
          patient care process, Provider agrees to defend, indemnify and hold
          RhynoCare harmless from any claim by or on behalf of any patient of
          Provider, or by or on behalf of any other third party or person
          claiming damage by virtue of a familial or financial relationship with
          such a patient, which is br/ought against RhynoCare, regardless of the
          cause if such claim arises for any reason whatsoever, out of
          Provider’s use or operation of the Site and Services. To the extent
          applicable, Provider will obtain RhynoCare’s prior written consent to
          any settlement or judgment in which Provider agrees to any finding of
          fault of RhynoCare or defect in the Site or Services.
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          RhynoCare will promptly notify Provider in writing of any claim
          subject to this indemnification, promptly provide Provider with the
          information reasonably required for the defense of the same, and grant
          to Provider exclusive control over its defense and settlement.
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          If you submit, upload, transmit, or post any consents, notices,
          advice, recommendations, comments, files, videos, images or other
          materials to us or our Site (“Provider Content”) or provide any
          Provider Content to patients or other consumers, you agree not to
          provide any Provider Content that (1) is defamatory, abusive,
          libelous, unlawful, obscene, threatening, harassing, fraudulent,
          pornographic, or harmful, or that could encourage criminal or
          unethical behavior, (2) violates or infringes the privacy, copyright,
          trademark, trade dress, trade secrets or intellectual property rights
          of any person or entity, or (3) contains or transmits a virus or any
          other harmful component. Provider is solely responsible for obtaining
          all necessary agreements and consents from, and providing all required
          notices to, patients and other consumers. You agree not to contact
          other users through unsolicited e-mail, telephone calls, mailings or
          any other method of
        </p>
        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          communication. You represent and warrant to RhynoCare that you have
          the legal right and authorization to upload all Provider Content at
          the Site.
        </p>
        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          RhynoCare shall have a royalty-free, irrevocable, transferable right
          and license to use the Provider Content however RhynoCare desires,
          including without limitation, to copy, modify, delete in its entirety,
          adapt, publish, translate, create derivative works from and/or sell
          and/or distribute such Provider Content and/or incorporate such
          Provider Content into any form, medium or technology throughout the
          world. RhynoCare is and shall be under no obligation (1) to maintain
          any Provider Content in confidence; (2) to pay to you any compensation
          for any Provider Content; or (3) to respond to any Provider Content.
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          RhynoCare does not regularly review Provider Content, but does reserve
          the right (but not the obligation) to monitor and edit or remove any
          Provider Content submitted to the Site. You grant RhynoCare the right
          to use the name that you submit in connection with any Provider
          Content. You agree not to use a false email address, impersonate any
          person or entity, or otherwise mislead as to the origin of any
          Provider Content. You are and shall remain solely responsible for the
          content of any Provider Content you post to the Site or provide to
          patients or other consumers. RhynoCare and its affiliates take no
          responsibility and assume no liability for any Provider Content
          submitted by you or any third party.
        </p>

        <p
          class="s2"
          className={`${classes.paragraph}`}
          style={{ textDecorationLine: "underline" }}
        >
          Dispute Resolution/Arbitration Agreement
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          We will try work in good faith to resolve any issue you have with
          Site, including Products and Services ordered or purchased through the
          Site, if you br/ing that issue to the attention of our customer
          service department. However, we realize that there may be rare cases
          where we may not be able to resolve an issue to a customer’s
          satisfaction.
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          You and RhynoCare agree that any dispute, claim or controversy arising
          out of or relating in any way to these Terms of Use or your use of the
          Site, including Products and Services ordered or purchased through the
          Site, shall be determined by binding arbitration instead of in courts
          of general jurisdiction. Arbitration is more informal than br/inging a
          lawsuit in court.
        </p>
        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          Arbitration uses a neutral arbitrator instead of a judge or jury, and
          is subject to very limited review by courts. Arbitration allows for
          more limited
        </p>
        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          discovery than in court, however, we agree to cooperate with each
          other to agree to reasonable discovery in light of the issues involved
          and amount of the claim. Arbitrators can award the same damages and
          relief that a court can award, but in so doing, the arbitrator shall
          apply substantive law regarding damages as if the matter had been
          br/ought in court, including without limitation, the law on punitive
          damages as applied by the United States Supreme Court. You agree that,
          by agreeing to these Terms of Use, the U.S. Federal Arbitration Act
          governs the interpretation and enforcement of this provision, and that
          you and RhynoCare are each waiving the right to a trial by jury or to
          participate in a class action. This arbitration provision shall
          survive termination of these Terms of Use and any other contractual
          relationship between you and RhynoCare.
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          
            If you desire to assert a claim against RhynoCare, and you therefore
            elect to seek arbitration, you must first send to RhynoCare, by
            certified mail, a written notice of your claim (“Notice”). The
            Notice to RhynoCare should be addressed to: RhynoCare 60 Colborne
            St., Suite 2004, Toronto, Ontario, Canada, M5E0B7 (“Notice
            Address”). If RhynoCare desires to assert a claim against you and
            therefore elects to seek arbitration, it will send, by certified
            mail, a written Notice to the most recent address we have on file or
            otherwise in our records for you. A Notice, whether sent by you or
            by RhynoCare, must (a) describe the nature and basis of the claim or
            dispute; and (b) set forth the specific relief sought (“Demand”). If
            RhynoCare and you do not reach an agreement to resolve the claim
            within 30 days after the Notice is received, you or RhynoCare may
            commence an arbitration proceeding or file a claim in small claims
            court. During the arbitration, the amount of any settlement offer
            made by RhynoCare or you shall not be disclosed to the arbitrator.
            You may download or copy a form Notice and a form to initiate
            arbitration from the American Arbitration Association at
            www.adr.org. If you are required to pay a filing fee, after
            RhynoCare receives notice at the Notice Address that you have
            commenced arbitration, it will promptly reimburse you for your
            payment of the filing fee, unless your claim is for more than US
            $10,000. The arbitration will be governed by the Commercial
            Arbitration Rules and the Supplementary Procedures for Consumer
            Related Disputes (collectively, “AAA Rules”) of the American
            Arbitration Association (“AAA”), as modified by these Terms of Use,
            and will be administered by the AAA. The AAA Rules and Forms are
            available online at www.adr.org,
    
          by calling the AAA at 1-800-778-7879, or
        </p>
        <p className={`${( classes.paragraph)}`}>
          by requesting them from us by writing to us at the Notice Address. The
          arbitrator is bound by the terms of these Terms of Use. All issues are
          for the arbitrator to decide, including issues relating to the scope
          and enforceability of these Terms of Use, including this arbitration
          agreement. Unless RhynoCare and you agree otherwise, any arbitration
          hearings will take place in the county (or parish) of your billing
          address. (If you reside outside of the United States, any arbitration
          hearings will take place in your country of residence at a location
          reasonably convenient to you, but will remain subject to the AAA Rules
          including the AAA rules regarding the selection of an arbitrator). If
          your claim is for US $10,000 or less, we agree that you may choose
          whether the arbitration will be conducted solely on the basis of
          documents submitted to the arbitrator, through a telephonic hearing,
          or by an in-person hearing as established by the AAA Rules. If your
          claim exceeds US $10,000, the right to a hearing will be determined by
          the AAA Rules. Regardless of the manner in which the arbitration is
          conducted, the arbitrator shall issue a reasoned written decision
          sufficient to explain the essential findings and conclusions on which
          the award is based. If the arbitrator issues you an award that is
          greater than the value of RhynoCare’s last written settlement offer
          made before an arbitrator was selected (or if RhynoCare did not make a
          settlement offer before an arbitrator was selected), then RhynoCare
          will pay you the amount of the award or US $1,000, whichever is
          greater. Except as expressly set forth herein, the payment of all
          filing, administration and arbitrator fees will be governed by the AAA
          Rules. Each party shall pay for its own costs and attorneys’ fees, if
          any. However, if any party prevails on a statutory claim that affords
          the prevailing party attorneys’ fees, or if there is a written
          agreement providing for payment or recovery attorneys’ fees, the
          arbitrator may award reasonable fees to the prevailing party, under
          the standards for fee shifting provided by law.
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          YOU AND RhynoCare AGREE THAT EACH MAY br/ING CLAIMS AGAINST THE OTHER
          ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
          CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          Further, unless both you and RhynoCare agree otherwise, the arbitrator
          may not consolidate more than one person’s claims with your claims,
          and may not otherwise preside over any form of a representative or
          class
        </p>
        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          proceeding. The arbitrator may award declaratory or injunctive relief
          only in favor of the individual party seeking relief and only to the
          extent necessary to provide relief warranted by that party’s
          individual claim.
        </p>

        <p className={`${classes.paragraph}`}>
          If this Agreement to Arbitrate provision is found to be unenforceable,
          then
        </p>
        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          (a) the entirety of this arbitration provision shall be null and void,
          but the remaining provisions of these Terms of Use shall remain in
          full force and effect; and (b) exclusive jurisdiction and venue for
          any claims will be in state or federal courts located in and for
          Hillsborough County, Florida.
        </p>

        <p
          class="s2"
          className={`${classes.paragraph}`}
          style={{ textDecorationLine: "underline" }}
        >
          Force Majeure
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          We will not be deemed to be in br/each of these terms or liable for
          any br/each of these terms or our privacy policy due to any event or
          occurrence beyond our reasonable control, including without
          limitation, acts of God, terrorism, war, invasion, failures of any
          public networks, electrical shortages, earthquakes or floods, civil
          disorder, strikes, fire or other disaster.
        </p>

        <p
          class="s2"
          className={`${classes.paragraph}`}
          style={{ textDecorationLine: "underline" }}
        >
          Indemnification
        </p>

        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          You agree to defend, indemnify, and hold harmless RhynoCare and any
          affiliates from and against any and all rights, demands, losses,
          liabilities, damages, claims, causes of action, actions, and suits (no
          matter whether at law or equity), fees, costs, and attorney’s fees of
          any kind whatsoever arising directly or indirectly out of or in
          connection with: (i) your use or misuse of the Site, Products or
          Services or any information posted on the Site; (ii) your br/each of
          the Terms of Use or Privacy Policy; (iii) the content or subject
          matter of any information you provide to RhynoCare or any Provider or
          customer service agent; and/or (iv) any negligent or wrongful act or
          omission by you in your use or misuse of the Site, Products or
          Services or any information on the Site, including without limitation,
          infringement of third party intellectual property rights, privacy
          rights, or negligent or wrongful conduct.
        </p>

        <p
          class="s2"
          className={`${classes.paragraph}`}
          style={{ textDecorationLine: "underline" }}
        >
          Revisions
        </p>
        <p className={`${(classes.textAlignLeft, classes.paddingLeft)}`}>
          RhynoCare reserves the right, in its sole discretion, to terminate
          your access to all or part of this Site, with or without cause, and
          with or without notice. RhynoCare reserves the right to modify these
          Terms of Use at any time, effective upon posting. Any use of this
          website after such changes will be deemed an acceptance of those
          changes. You agree to review the Terms of Use each time you access
          this website so that you may be aware of any changes to these Terms.
          In the event that any of the Terms of Use are held by a court or other
          tribunal of competent jurisdiction to be unenforceable, such
          provisions shall be limited or eliminated to the minimum extent
          necessary so that these Terms of Use shall otherwise remain in full
          force and effect. These Terms of Use constitute the entire agreement
          between RhynoCare and you pertaining to the subject matter hereof. In
          its sole discretion, RhynoCare may from time-to-time revise these
          Terms of Use by updating this posting. You should, therefore,
          periodically visit this page to review the current Terms of Use, so
          you are aware of any such revisions to which you are bound. Certain
          provisions of these Terms of Use may be superseded by expressly
          designated legal notices or terms located on particular pages within
          this Site.
        </p>

        <p className={`${classes.paragraph}`}>
          Copyright/Trademark Information. All rights reserved. All trademarks,
          logos and service marks (“Marks”) displayed on the Site are our
          property or the property of other third parties. You are not permitted
          to use these Marks without our prior written consent or the consent of
          such third party which may own the Marks.
        </p>

        <p
          class="s8"
          className={`${classes.paragraph}`}
          style={{ textDecorationLine: "underline" }}
        >
          Abusive behavior to our employees
        </p>

        <p class="s1" className={`${classes.paragraph}`}>
          We have a Zero tolerance policy towards any abusive language or
          threats toward our employees and will result in immediate cancellation
          of your appointment and denial of services
        </p>

        <p
          class="s2"
          className={`${classes.paragraph}`}
          style={{ textDecorationLine: "underline" }}
        >
          Contact Us
        </p>

        <p className={`${classes.paragraph}`}>
         
            If you have questions about the RhynoCare Platform or its use, you
            can contact RhynoCare Support by sending an email to{" "}
          
          <a
            href="mailto:info@RhynoCare.com"
            class="s9"
            target="_blank"
            style={{ textDecorationLine: "underline" }}
          >
            info
          </a>
          <a
            href="mailto:info@RhynoCare.com"
            class="s10"
            target="_blank"
            style={{ textDecorationLine: "underline" }}
          >
            @RhynoCare.com
          </a>
          <a href="mailto:info@RhynoCare.com" target="_blank">
            .
          </a>
        </p>
      </>
    );
  }
}

export default withStyles(styles)(TC);
