import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, CircularProgress, FormControl, InputAdornment, InputLabel, OutlinedInput, TextField, Fab } from '@material-ui/core'
import withStyles from '@material-ui/styles/withStyles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import { getAppointmentsDocsList, CreatePatientDoc } from '../../store/actions/EMRAction'
import AddIcon from "@material-ui/icons/Add"
import BackupIcon from '@material-ui/icons/Backup';
import { Modal } from 'antd'
import functionsList from '../../assets/js/function'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
.ant-modal-title {
    // color: white;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
}
.ant-modal-header{
    // background: rgb(0,178,181);
    border-radius: 9px 9px 0 0;
    border-bottom:none;
}

.ant-modal-content{
    border-radius: 9px;
}
.ant-modal-close-x{
    color:white;
}
.ant-modal-footer{
    text-align:center;
    border-top:none;
}
`;


const styles = theme => ({
    parent: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
        position: 'relative',
        width: '100%',
        '& .MuiPaper-root': {
            borderRadius: '20px',
            // maxHeight:'200px',
            minHeight: '50px',
            // overflow:'scroll'
        },

    },
    panel: {
        padding: 0,
        width: '100%',
        marginBottom: theme.spacing(3)
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'baseline',
        paddingBottom: theme.spacing(5)
    },
    item: {
        borderBottom: '1px solid #80808036',
        width: '100%',
        textAlign: 'left',
        marginBottom: theme.spacing(1),
        overflowWrap: 'anywhere'
    },
    uploadClass: {
        display: 'none'
    },
    add: {
        color: 'white',
        bottom: '0 !important',
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    buttonCancel: {
        backgroundColor: 'rgb(240, 242, 245)',
        width: "120px",
        color: theme.palette.lightGrey.main,
        padding: "7px",
        borderRadius: '10px',
        border: 'none',
        height: '50px',

    },
    uploadBoxContainer: {
        '& .MuiInputAdornment-root': {
            zIndex: 1,
            position: 'relative',
            right: '35%',
            top: '20%',

        },
        '& .MuiInputBase-root': {
            height: '15rem',
            '& >fieldset': {
                border: '2px solid ' + theme.palette.primary.main + ' !important',
                borderRadius: '1rem',
                borderStyle: 'dashed !important',
                backgroundColor: 'rgb(241, 251,250)',

            },
        }
    },
    buttonUploadDocuments: {
        width: "120px",
        borderRadius: '10px',
        border: 'none',
        color: 'white',
        height: '50px',
        padding: "7px",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: "#1f4037"
        },
    },
    uploadDocsLabel: {
        '&:nth-of-type(1)': {
            left: '31%',
            top: '44%',
        },
        '&:nth-of-type(2)': {
            left: '44%',
            top: '53%'
        }
    },
    uploadIcon: {
        position: 'relative',
        left: '44%',
        top: '5rem',
        zIndex: 1,
        fontSize: '4rem',
        color: theme.palette.primary.main

    }
})
class AppointmentDocs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.userId,
            expanded: true,
            appointmentDocumentsList: { data: [] },
            responseMessage: '',
            pagination_id: 0,
            loader: true
        }
    }
    expanded = () => {
        this.setState({ expanded: !this.state.expanded })
    }
    componentDidMount() {
        this.getAppointmentDocs()
    }
    getAppointmentDocs = async () => {
        let result = await this.props.getAppointmentsDocsList(JSON.stringify({ userId: this.state.userId, pagination_id: this.state.pagination_id }))
        let previousData = this.state.appointmentDocumentsList.data
        if (this.state.pagination_id === 0)
            previousData = result.data
        else
            previousData = previousData.concat(result.data)
        this.setState({ loader: false, appointmentDocumentsList: { data: previousData }, responseMessage: result.message, pagination_id: result.pagination_id })
    }
    // SHOW HIDE MODAL
    showHideModal = () => {
        this.setState({ openModal: true })
    }

    // SET STATE WHEN USER CLICK ON CANCEL BUTTON OF MODAL OR CLICK OUTSIDE OF MODAL
    onCancel = () => {
        this.setState({ openModal: false })
    }

    // WHEN IMAGE SELECTED
    onImageChangeHandler = async (e) => {
        this.setState({ loadinguploader: true })
        var result = await functionsList.saveImage(e, 'Appointment')
        result = JSON.parse(result)
        let response = await CreatePatientDoc(JSON.stringify({
            userId: this.state.userId,
            link: result.path,
            name: result.name,
            pagination_id: this.state.pagination_id
        }))
        let previousData = response.data
        this.setState({ notesHistoryList: { data: previousData }, responseMessage: response.message, pagination_id: 0, loadinguploader: false })
        this.getAppointmentDocs()
    }

    // OPEN FILE PICKER WHEN UPLOAD BUTTON CLICK
    handleFileSelect = () => {
        document.getElementById('file').click();
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid className={classes.parent}>
                <ExpansionPanel expanded={this.state.expanded} onChange={this.expanded} className={classes.panel}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Documents/Referrals from Patient</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.list}>
                        <>
                            {!this.state.loader ?
                                this.state.appointmentDocumentsList.data.length === 0 ?
                                    <p key={0}>No Appointments Docs Found</p> :
                                    <>
                                        {this.state.appointmentDocumentsList.data.map((appointment, key) => {
                                            return <Typography className={classes.item} key={key}>
                                                {appointment.name}
                                                {appointment.link && appointment.link !== '' ? <a href={appointment.link} target="_blank" rel="noopener noreferrer"><PictureAsPdfIcon color="primary" /></a> : ""}
                                            </Typography>
                                        })
                                        }
                                        {this.state.pagination_id !== -1 &&
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => this.getAppointmentDocs()}
                                                className={classes.submit + " " + classes.searchItem}
                                            >
                                                View More
                                            </Button>
                                        }
                                    </>
                                :
                                <CircularProgress size={24} />
                            }
                        </>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                {this.props.user === 'patient' &&
                    <Fab color="primary" className={classes.add} onClick={this.showHideModal}>
                        <AddIcon />
                    </Fab>
                }
                <StyledModal
                    title={"Upload documents"}
                    visible={this.state.openModal}
                    width={this.state.modalWidth}
                    className={"NoteModal"}
                    onCancel={this.onCancel}
                    zIndex={2000}
                    footer={
                        <Grid>
                            <Button variant="outlined" className={classes.buttonCancel} onClick={this.onCancel}>Cancel</Button>
                        </Grid>
                    }
                >
                    <Grid className={classes.uploadBoxContainer}>
                        <FormControl
                            fullWidth
                            variant="outlined"
                            className={classes.uploadFileDiv}
                        >
                            {/* <InputLabel htmlFor={"fileDiv"} className={classes.uploadDoc}>
                                Documents
                            </InputLabel> */}
                            <BackupIcon className={classes.uploadIcon} />
                            <InputLabel htmlFor={"fileDiv"} className={classes.uploadDocsLabel}>
                                Drag & Drop your files
                            </InputLabel>
                            <InputLabel htmlFor={"fileDiv"} className={classes.uploadDocsLabel}>
                                OR
                            </InputLabel>


                            <OutlinedInput
                                id={"fileDiv"}
                                type="text"
                                disabled={true}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <Button
                                            type="button"
                                            fullWidth
                                            className={classes.buttonUploadDocuments}
                                            onClick={() => this.handleFileSelect()}
                                        >
                                            {
                                                this.state.loadinguploader ?
                                                    <CircularProgress size={21} /> :
                                                    'Browse Files'
                                            }
                                        </Button>
                                    </InputAdornment>
                                }
                                labelWidth={70}
                            />
                        </FormControl>
                    </Grid>
                    <TextField
                        name="file"
                        type="file"
                        id="file"
                        className={classes.uploadClass}
                        onChange={this.onImageChangeHandler}
                    />
                </StyledModal>
            </Grid>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAppointmentsDocsList: (data) => getAppointmentsDocsList(data),
    }
}
export default connect(null, mapDispatchToProps)(withStyles(styles)(AppointmentDocs));