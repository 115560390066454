import { isMobileOnly } from "react-device-detect"
import { createLocalTracks } from "twilio-video"


/*******************************************************************************/
/*******************************************************************************/

const localTracks = {
    audio: null,
    video: null
};

/*******************************************************************************/
/*******************************************************************************/

const getConnection = async (roomName) => {
    // ConnectOptions settings for a video web application.
    const connectOptions = {
        // Available only in Small Group or Group Rooms only. Please set "Room Type"
        // to "Group" or "Small Group" in your Twilio Console:
        // https://www.twilio.com/console/video/configure
        bandwidthProfile: {
            video: {
                dominantSpeakerPriority: 'high',
                mode: 'collaboration',
                renderDimensions: {
                    high: { height: 720, width: 1280 },
                    standard: { height: 90, width: 160 }
                }
            }
        },

        // Available only in Small Group or Group Rooms only. Please set "Room Type"
        // to "Group" or "Small Group" in your Twilio Console:
        // https://www.twilio.com/console/video/configure
        dominantSpeaker: true,

        // Comment this line to disable verbose logging.
        // logLevel: 'debug',

        // Comment this line if you are playing music.
        maxAudioBitrate: 16000,

        // VP8 simulcast enables the media server in a Small Group or Group Room
        // to adapt your encoded video quality for each RemoteParticipant based on
        // their individual bandwidth constraints. This has no utility if you are
        // using Peer-to-Peer Rooms, so you can comment this line.
        preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],

        // Capture 720p video @ 24 fps.
        video: { height: 720, frameRate: 24, width: 1280 }
    };

    // For mobile browsers, limit the maximum incoming video bitrate to 2.5 Mbps.
    if (isMobileOnly) {
        connectOptions.bandwidthProfile.video.maxSubscriptionBitrate = 2500000;


        let kind = 'audio';
        // Get all the devices from browser dafult function
        const devices = await navigator.mediaDevices.enumerateDevices();

        // Filter only Audio device.
        let audioDevices = devices.filter(device => device.kind === `${kind}input`);
        // console.log('audioDevices');  
        // console.log(audioDevices);

        // Create a new LocalTrack from the 1st given Device ID.
        let audioDeviceID = audioDevices[0].deviceId;
        const [track] = await createLocalTracks({ [kind]: { audioDeviceID } });

        // Stop the previous LocalTrack, if present.
        // if (localTracks[kind]) {
        //   localTracks[kind].stop();
        // }

        localTracks[kind] = track;

        // Filter only video device.
        kind = 'video';
        let videoDevices = devices.filter(device => device.kind === `${kind}input`);
        // console.log('videoDevices');
        // console.log(videoDevices);

        // Create a new LocalTrack from the given Device ID.
        let videoDeviceID = videoDevices[0].deviceId;
        const [videotrack] = await createLocalTracks({ [kind]: { videoDeviceID } });

        // Render the current LocalTrack.
        localTracks[kind] = videotrack;
        // console.log('this.localTracks');
        // console.log(localTracks);


        // Add the specified audio device ID to ConnectOptions.
        connectOptions.audio = { deviceId: { exact: audioDeviceID } };

        // Add the specified video device ID to ConnectOptions.
        connectOptions.video.deviceId = { exact: videoDeviceID };
    }
    else {
        connectOptions.audio = true
    }

    // Add the specified Room name to ConnectOptions.
    connectOptions.name = roomName;

    return connectOptions
}

export default getConnection