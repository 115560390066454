import React, { Component } from 'react'
import { connect } from 'react-redux'
import withStyles from "@material-ui/core/styles/withStyles"
import { getBillingDetails, exportBillingDetails, getAllBillingCodes } from '../../store/actions/BillingAction'
import { Redirect } from 'react-router-dom'
import { Table, TableBody, TableHead, TableRow, TableCell, Button, CircularProgress, Grid } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { CSVLink } from "react-csv"
import moment from 'moment'
import { Modal } from 'antd'
import AddBilling from './AddBilling'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'




const styles = theme => ({
  fontWeight: {
    fontWeight: 700,
  },
  pointer: {
    cursor: 'pointer'
  },
  autocomplete: {
    width: '50%',
    margin: theme.spacing(0, 1, 2)
  },
  autocomplete1: {
    width: '100%',
    margin: theme.spacing(0, 1, 2)
  },
  alert: {
    marginBottom: theme.spacing(3)
  },
  table: {
    marginBottom: theme.spacing(2)
  },
  rating: {
    fontWeight: 700
  },
  selector: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  export: {
    float: 'right',
    backgroundColor: '#795bb2 !important',
    padding: theme.spacing(1, 2),
    marginLeft: theme.spacing(1),
    color: '#fff !important',
    borderRadius: theme.spacing(.5),
    border: 'transparent',
    cursor: 'pointer'
  },
  addCustomFee: {
    width: '100%',
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
  addCustomFeeFields: {
    margin: theme.spacing(.7, 0) + " !important"
  },
  date: {
    width: 'max-content !important',
    margin: '0 1rem !important'
  },
  filter: {
    display: 'flex',
    alignItems: 'center'
  },
  pagination: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1)
  }
});

class Billing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModel: false,
      appointmentId: '',
      fromDate: moment().startOf('month'),
      toDate: moment().endOf('month'),
      csvData: { data: [] },
      headers: { data: [] },
      loading: '',
      editObjectData: {},
      redirectToHistory: false,
      updatedAppointRecord: ''
    };
    this.getBillingDetailsFromApi()
  }
  async componentDidMount() {
    this.props.setTotalCalls(3)
    let result = await this.props.getAllBillingCodes()

    this.setState({ billingCodeList: result.data })
    if (this.state.updatedAppointRecord !== '') {
      this.updateBilling(this.state.updatedAppointRecord)
    }
  }
  updateBilling = async (billingItem) => {
    try {
      this.setState({ updatedAppointRecord: billingItem })
      let billingCodes = []
      if (billingItem.cardType === 'Ontario')
        billingCodes = this.state.billingCodeList.ontarioBillingCodesList
      else if (billingItem.cardType === 'British Columbia')
        billingCodes = this.state.billingCodeList.bcBillingCodesList
      else if (billingItem.cardType === 'Alberta')
        billingCodes = this.state.billingCodeList.albertaBillingCodesList
      let dataObject = {
        showBillingNumber: this.props.billing.data.showBillingNumber,
        isRequiredCodes: billingItem.hcard !== '' ? true : false,
        codes: { diagnosticCodes: this.state.billingCodeList.diagnosticCodes, billingCodes: billingCodes },
        billing: billingItem,
        appointment: {
          appointmentId: billingItem.appointmentId,
          cardType: billingItem.cardType,
          doctorId: billingItem.doctorId,
          patientId: billingItem.patientId
        }
      }
      this.setState({ editObjectData: { status: 1, data: dataObject }, appointmentId: billingItem.appointmentId, showModel: true, updatedAppointRecord: '' })
    } catch (error) {
      this.setState({ showModel: true })
    }
  }
  billingSaved = () => {
    this.setState({ appointmentId: '', showModel: false })
    this.getBillingDetailsFromApi(0)
  }
  getBillingDetailsFromApi = async (pagination_id = 0, type = 'next') => {
    this.setState({ loading: type })
    await this.props.getBillingDetails(JSON.stringify({ pagination_id: pagination_id, type: type, doctorId: this.props.auth.uid, fromDate: Math.floor(new Date(this.state.fromDate).getTime() / 1000), toDate: Math.floor(new Date(this.state.toDate).getTime() / 1000) }))
    this.setState({ loading: '' })
  }
  exportBillingDetailsFromApi = async () => {
    this.setState({ loading: 'export' })
    let result = await this.props.exportBillingDetails(JSON.stringify({ doctorId: this.props.auth.uid, fromDate: Math.floor(new Date(this.state.fromDate).getTime() / 1000), toDate: Math.floor(new Date(this.state.toDate).getTime() / 1000) }))
    let headers = [
      { label: "Service Date", key: "createdAt" },
      { label: "First Name", key: "firstName" },
      { label: "Last Name", key: "lastName" },
      { label: "Date Of Birth", key: "dateOfBirth" },
      { label: "Health Card", key: "hCard" },
      { label: "Fee Code", key: "feeCode" },
      { label: "Diagnostic code", key: "diagnosticCode" },
      { label: "HC Amount", key: "hcAmount" },
      { label: "Units", key: "units" },
      { label: "Total Amount", key: "totalAmount" },
      { label: "Admission Date", key: "admissionDate" }
    ];
    if (this.props.account.data.speciality !== 'Family Medicine')
      headers.splice(4, 0, { label: "Referring Billing Number", key: "billingNumber" });
    let csvData = []
    let rowNumber = 0

    if (result && result.data && result.data.length > 0) {
      result.data.map((billingItem) => {
        csvData.push({
          createdAt: moment(billingItem.createdAt).format("D/MMM/YYYY"),
          firstName: billingItem.firstName,
          lastName: billingItem.lastName,
          dateOfBirth: moment(billingItem.dob).format("D/MMM/YYYY"),
          hCard: billingItem.hcard,
          feeCode: billingItem.feeCode,
          diagnosticCode: billingItem.diagnosticCode.code,
          hcAmount: "$" + String(billingItem.HCAmount),
          units: billingItem.feeUnits,
          totalAmount: "$" + String(billingItem.totalAmount),
          admissionDate: moment(billingItem.dateOfAdmission * 1000).format('L')
        })
        if (this.props.account.data.speciality !== 'Family Medicine')
          csvData[rowNumber++].billingNumber = billingItem.billingNumber
        return ''
      })
    } else {
      csvData.push({
        createdAt: "No Record Found"
      })
    }
    this.setState({ csvData: { data: csvData }, headers: { data: headers } }, () => {
      setTimeout(() => {
        document.getElementById('download_csv').click()
        this.setState({ loading: '' })
      }, 1000);
    })
  }
  cancelbilling = () => {
    this.setState({ appointmentId: '', showModel: false })
  }
  changeDate = (e, type) => {
    this.setState({ [type]: moment(e) }, () => this.getBillingDetailsFromApi(0, 'load'))
  }
  showBillingHistory = () => {
    this.setState({ redirectToHistory: true })
  }
  render() {
    const { classes, auth, billing, account } = this.props;
    if (!auth.uid) {
      return <Redirect to="/signin" />;
    }
    if (!auth.isVerified) {
      return <Redirect to="/verify" />;
    }
    if (this.state.redirectToHistory) {
      return <Redirect to="/billing_history" />;
    }
    return (
      <div>
        <div className={classes.filter}>
          <span className={classes.fontWeight}>From</span>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              className={classes.date}
              value={this.state.fromDate}
              format="dd/MMMM/yyy"
              onChange={(e) => this.changeDate(e, 'fromDate')}
            />
          </MuiPickersUtilsProvider>
          <span className={classes.fontWeight}>To</span>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              className={classes.date}
              value={this.state.toDate}
              format="dd/MMMM/yyy"
              onChange={(e) => this.changeDate(e, 'toDate')}
            />
          </MuiPickersUtilsProvider>
        </div>

        <Button className={classes.export} onClick={this.exportBillingDetailsFromApi}>
          {this.state.loading === 'export' ? <CircularProgress size={24} /> : "EXPORT Data To CSV"}
        </Button>
        <CSVLink style={{ display: "none" }} id='download_csv' filename={"billing.csv"} className={classes.export} data={this.state.csvData.data} headers={this.state.headers.data}>
          EXPORT
        </CSVLink>
        {account.data.country === 'Canada' && account.data.state === 'Ontario' &&
          <Button className={classes.export} onClick={this.showBillingHistory}>
            Billing Histroy
          </Button>
        }
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.fontWeight}>Service Date</TableCell>
              <TableCell className={classes.fontWeight}>First Name</TableCell>
              <TableCell className={classes.fontWeight}>Last Name</TableCell>
              <TableCell className={classes.fontWeight}>Date Of Birth</TableCell>
              {this.props.account.data.speciality === 'Family Medicine' ? <></> : <TableCell className={classes.fontWeight}>Billing Number</TableCell>}
              <TableCell className={classes.fontWeight}>Health Card</TableCell>
              <TableCell className={classes.fontWeight}>Fee Code</TableCell>
              <TableCell className={classes.fontWeight}>Diagnostic Code</TableCell>
              <TableCell className={classes.fontWeight}>Amount(HC)</TableCell>
              <TableCell className={classes.fontWeight}>Amount(Total)</TableCell>
              <TableCell className={classes.fontWeight}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {billing && billing.data && billing.data.data && billing.data.data.length > 0 ?
              billing.data.data.map((billingItem, key) => {
                return (<TableRow key={key}>
                  <TableCell>{moment(billingItem.createdAt).format("D/MMM/YYYY")}</TableCell>
                  <TableCell>{billingItem.firstName}</TableCell>
                  <TableCell>{billingItem.lastName}</TableCell>
                  <TableCell>{moment(billingItem.dob).format("D/MMM/YYYY")}</TableCell>
                  {this.props.account.data.speciality === 'Family Medicine' ? <></> : <TableCell>{billingItem.billingNumber}</TableCell>}
                  <TableCell>{billingItem.hcard}</TableCell>
                  <TableCell>{billingItem.feeCode}</TableCell>
                  <TableCell>{billingItem.diagnosticCode.code}</TableCell>
                  <TableCell>${billingItem.HCAmount}</TableCell>
                  <TableCell>${billingItem.totalAmount}</TableCell>
                  <TableCell><EditIcon className={classes.pointer} onClick={(e) => this.updateBilling(billingItem)} /></TableCell>
                </TableRow>)
              })
              :
              <TableRow>
                <TableCell colSpan={this.props.account.data.speciality === 'Family Medicine' ? 10 : 11} className={classes.fontWeight}>No Record Found</TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
        <div className={classes.pagination}>
          <div>{billing && billing.data && billing.data.hasPrevious && <Button className={classes.export} onClick={() => this.getBillingDetailsFromApi(billing.data.pagination_id, 'previous')}>
            {this.state.loading === 'Previous' ? <CircularProgress size={24} /> : "Previous"}
          </Button>}</div>
          <div>{billing && billing.data && billing.data.hasNext && <Button className={classes.export} onClick={() => this.getBillingDetailsFromApi(billing.data.pagination_id)}>
            {this.state.loading === 'Next' ? <CircularProgress size={24} /> : "Next"}
          </Button>}</div>
        </div>
        {/* CHAT ROOM ENDED BY DOCTOR MODEL */}
        <Modal
          title={"Update Billing"}
          visible={this.state.showModel}
          width={650}
          onCancel={this.cancelbilling}
          className={"ModelToHideFooter"}
          footer={<Grid></Grid>}
        >
          {this.state.appointmentId === '' ?
            "Please Wait "
            :
            <AddBilling getAppointmentDetailResult={this.state.editObjectData} appointmentId={this.state.appointmentId} billingSaved={this.billingSaved} cancelbilling={this.cancelbilling} />
          }
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    account: state.account,
    billing: state.billing,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getBillingDetails: (data) => dispatch(getBillingDetails(data)),
    exportBillingDetails: (data) => exportBillingDetails(data),
    getAllBillingCodes: () => getAllBillingCodes(),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Billing));