import React, { useState } from 'react'
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles"
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardTimePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'
import { formatPhoneNumber, formatHealthCard, formatZip } from '../../assets/js/general'

const styles = theme => ({
    container: {
        textAlign: 'left',
        border: '1px solid #8080803b',
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        marginBottom: '20px',
        margin: 'auto',
        boxShadow: '0px 0px 14px -6px gray'
    },
    formData: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    editIcon: {
        fontSize: '1rem',
        color: theme.palette.lightGrey.main,
    },
    label: {
        color: theme.palette.lightGrey.main,
    },
    inputGrid: {
        margin: '4% 1% 0',
        width: '30%',
        [theme.breakpoints.down('sm')]: { width: '100%' }
    },
    areaGrid: {
        margin: '4% 1% 0',
        width: '95%',
    },
    textFieldInput: {
        '& .MuiInputBase-root': {
            color: theme.palette.lightGrey.main,
        },
        "& .Mui-focused": {
            color: 'black'
        }
    },
    selectInput: {
        width: '100%',
        '& .MuiSelect-root': {
            color: theme.palette.lightGrey.main,
        },
        "& .Mui-focused": {
            color: 'black'
        }
    },
    buttonUpdate: {
        width: "120px",
        borderRadius: '10px',
        border: 'none',
        color: 'white',
        height: '40px',
        padding: "7px",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: "#1f4037"
        },
        marginTop: '4%'
    },
})
const RequisitionItem = (props) => {
    const { classes, index, updateRequisitionsData, requisitionData } = props;
    const [first_name, setFirst_name] = useState('')
    const [last_name, setLast_name] = useState('')
    const [email, setEmail] = useState('')
    const [date_of_birth, setDate_of_birth] = useState(null)
    const [zip, setZip] = useState('')
    const [phone, setPhone] = useState('')
    const [gender, setGender] = useState('')
    const [collection_date, setCollection_date] = useState(null)
    const [collection_time, setCollection_time] = useState(null)
    const [health_card_number, setHealth_card_number] = useState('')
    const [note, setNote] = useState('')
    const [passport_no, setPassport_no] = useState('')

    const updateRequisitionData = (key, value) => {
        let currentItem = requisitionData
        if (!currentItem) {
            currentItem = { [key]: value }
        } else {
            currentItem[key] = value
        }

        updateRequisitionsData(currentItem, index)
    }
    return (
        <div className={classes.container}>
            <Grid className={classes.formData}>
                <Grid className={classes.inputGrid}>
                    <label className={classes.label}>First & Middle Name</label>
                    <TextField
                        className={classes.textFieldInput}
                        value={first_name}
                        onChange={(e) => {
                            setFirst_name(e.target.value)
                            updateRequisitionData('first_name', e.target.value)
                        }}
                        error={requisitionData.hasOwnProperty('first_name') && requisitionData.first_name === '' ? true : false}
                        helperText={requisitionData.hasOwnProperty('first_name') && requisitionData.first_name === '' ? "First & Middle Name is required" : ""}
                    />
                </Grid>
                <Grid className={classes.inputGrid}>
                    <label className={classes.label}>Last Name</label>
                    <TextField
                        className={classes.textFieldInput}
                        value={last_name}
                        onChange={(e) => {
                            setLast_name(e.target.value)
                            updateRequisitionData('last_name', e.target.value)
                        }
                        }
                        error={requisitionData.hasOwnProperty('last_name') && requisitionData.last_name === '' ? true : false}
                        helperText={requisitionData.hasOwnProperty('last_name') && requisitionData.last_name === '' ? "Last Name is required" : ""}
                    />
                </Grid>
                <Grid className={classes.inputGrid}>
                    <label className={classes.label}>Email</label>
                    <TextField
                        className={classes.textFieldInput}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                            updateRequisitionData('email', e.target.value)
                        }
                        }
                        error={requisitionData.hasOwnProperty('email') && requisitionData.email === '' ? true : false}
                        helperText={requisitionData.hasOwnProperty('email') && requisitionData.email === '' ? "Email is required" : ""}
                    />
                </Grid>
                <Grid className={classes.inputGrid}>
                    <label className={classes.label}>Phone</label>
                    <TextField
                        value={phone}
                        onChange={(e) => {
                            let phoneVal = formatPhoneNumber(e)
                            setPhone(phoneVal)
                            updateRequisitionData('phone', phoneVal)
                        }
                        }
                        error={requisitionData.hasOwnProperty('phone') && requisitionData.phone === '' ? true : false}
                        helperText={requisitionData.hasOwnProperty('phone') && requisitionData.phone === '' ? "Phone is required" : ""}
                    />
                </Grid>
                <Grid className={classes.inputGrid}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            autoOk
                            variant="inline"
                            label="Date of Birth *"
                            value={date_of_birth}
                            placeholder={"yyyy/mm/dd"}
                            format="yyyy/MM/dd"
                            style={{ marginBottom: '24px' }}
                            onChange={(e) => {
                                setDate_of_birth(moment(e).format('YYYY/MM/DD'))
                                updateRequisitionData('date_of_birth', moment(e).format('YYYY/MM/DD'))
                            }}
                            className={classes.textFieldInput}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={requisitionData.hasOwnProperty('date_of_birth') && requisitionData.date_of_birth === '' ? true : requisitionData.hasOwnProperty('date_of_birth') && requisitionData.date_of_birth === 'Invalid date' ? true : false}
                            helperText={requisitionData.hasOwnProperty('date_of_birth') && requisitionData.date_of_birth === '' ? 'Date of Birth is required' : requisitionData.hasOwnProperty('date_of_birth') && requisitionData.date_of_birth === 'Invalid date' ? 'Date format is incorrect' : false}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid className={classes.inputGrid}>
                    <FormControl variant="outlined" fullWidth style={{ marginBottom: '24px' }}>
                        <InputLabel id="gender">Sex *</InputLabel>
                        <Select
                            labelId="gender"
                            id="gender"
                            name="gender"
                            label="Sex *"
                            variant="outlined"
                            className={classes.selectInput}
                            fullWidth
                            value={gender}
                            error={requisitionData.hasOwnProperty('gender') && requisitionData.gender === '' ? true : false}
                            onChange={(e) => {
                                setGender(e.target.value)
                                updateRequisitionData('gender', e.target.value)
                            }}
                        >
                            <MenuItem value='Male'>Male</MenuItem>
                            <MenuItem value='Female'>Female</MenuItem>
                        </Select>
                        {requisitionData.hasOwnProperty('gender') && requisitionData.gender === '' && (
                            <p className="MuiFormHelperText-root Mui-error textLeft">{"Gender is required"}</p>
                        )}
                    </FormControl>
                </Grid>
                <Grid className={classes.inputGrid}>
                    <label className={classes.label}>Health Card</label>
                    <TextField
                        className={classes.textFieldInput}
                        value={health_card_number}
                        onChange={(e) => {
                            let value = formatHealthCard(e.target.value)
                            setHealth_card_number(value)
                            updateRequisitionData('health_card_number', value)
                        }}
                    />
                </Grid>
                <Grid className={classes.inputGrid}>
                    <label className={classes.label}>Passport No</label>
                    <TextField
                        className={classes.textFieldInput}
                        value={passport_no}
                        onChange={(e) => {
                            let value = formatHealthCard(e.target.value)
                            setPassport_no(value)
                            updateRequisitionData('passport_no', value)
                        }}
                    />
                </Grid>
                <Grid className={classes.inputGrid}>
                    <label className={classes.label}>Postal Code</label>
                    <TextField
                        className={classes.textFieldInput}
                        value={zip}
                        onChange={(e) => {
                            let val = formatZip(e.target.value)
                            setZip(val)
                            updateRequisitionData('zip', val)
                        }}
                        error={requisitionData.hasOwnProperty('zip') && requisitionData.zip === '' ? true : false}
                        helperText={requisitionData.hasOwnProperty('zip') && requisitionData.zip === '' ? "Postal Code is required" : ""}
                    />
                </Grid>
                <Grid className={classes.inputGrid}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            autoOk
                            variant="inline"
                            label="Collection Date *"
                            value={collection_date}
                            placeholder={"yyyy/mm/dd"}
                            format="yyyy/MM/dd"
                            style={{ marginBottom: '24px' }}
                            onChange={(e) => {
                                setCollection_date(moment(e).format('YYYY/MM/DD'))
                                updateRequisitionData('collection_date', moment(e).format('YYYY/MM/DD'))
                            }}
                            className={classes.textFieldInput}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={requisitionData.hasOwnProperty('collection_date') && requisitionData.collection_date === '' ? true : requisitionData.hasOwnProperty('collection_date') && requisitionData.collection_date === 'Invalid date' ? true : false}
                            helperText={requisitionData.hasOwnProperty('collection_date') && requisitionData.collection_date === '' ? 'Collection Date is required' : requisitionData.hasOwnProperty('collection_date') && requisitionData.collection_date === 'Invalid date' ? 'Date format is incorrect' : false}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid className={classes.inputGrid}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="Time picker"
                            className={classes.textFieldInput}
                            ampm={false}
                            value={collection_time}
                            onChange={(e) => {
                                setCollection_time(e)
                                updateRequisitionData('collection_time', moment(e).format('H:m'))
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                            error={requisitionData.hasOwnProperty('collection_time') && requisitionData.collection_time === '' ? true : requisitionData.hasOwnProperty('collection_time') && requisitionData.collection_time === 'Invalid date' ? true : false}
                            helperText={requisitionData.hasOwnProperty('collection_time') && requisitionData.collection_time === '' ? 'Collection Time is required' : requisitionData.hasOwnProperty('collection_time') && requisitionData.collection_time === 'Invalid date' ? 'Date format is incorrect' : false}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
                <Grid className={classes.areaGrid}>
                    <label className={classes.label}>Clinical Note</label>
                    <TextField
                        className={classes.textFieldInput}
                        value={note}
                        multiline
                        rows={5}
                        variant={"outlined"}
                        onChange={(e) => {
                            setNote(e.target.value)
                            updateRequisitionData('note', e.target.value)
                        }}
                        error={requisitionData.hasOwnProperty('zip') && requisitionData.zip === '' ? true : false}
                        helperText={requisitionData.hasOwnProperty('zip') && requisitionData.zip === '' ? "Postal Code is required" : ""}
                    />
                </Grid>
        </div>
    )
}


export default withStyles(styles)(RequisitionItem)
