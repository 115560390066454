import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Button, Menu, MenuItem, Badge } from '@material-ui/core'
import withStyles from '@material-ui/styles/withStyles'
import Functions from '../../assets/js/function'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { GETNOTIFICATIONTIME } from '../../config'
import moment from 'moment'
import { getUpdatedUserInfo } from '../../store/actions/authActions'
import CompleteSubAccountData from '../subAccounts/CompleteSubAccountData'

const styles = theme => ({
    parent: {
        position: 'fixed',
        top: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 1300
    },
    item: {
        width: '400px',
        // borderBottom: '1px solid #e3d7d7',
        borderLeft: '3px solid rgb( 51,11,250)',
        padding: '10px',
        backgroundColor: 'rgb(247, 248, 251)',
        wordBreak: 'break-word',
        marginBottom: '1rem',
        whiteSpace: 'break-spaces',
        "&:lastChild": {
            borderBottom: '0px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        },

    },
    bookNowButton: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        textTransform: 'none',
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        }
    },
    notificationsIcon: {
        color: theme.palette.lightGrey.main
    },
    chatClass: {
        right: '8rem'
    }
})

class Notifications extends Component {

    constructor(props) {
        super(props);

        // FOR TASK RHYN-102
        // TASK DESCRIPTION: Add patient tab and subaccounts for age <16
        // CHECK THE PAGE LINK IF IT IS SET PASSWORD OR CHAT PAGE THEN SET THE SHOWNOTIFICATIONICON STATE TO TRUE SO THAT NOTICIATION ICON WILL NOT BE SHOWN

        let path = new URL(window.location.href)
        let isSignedOutLink = path.pathname.startsWith('/set_password') || path.pathname.startsWith('/chat') ? true : false


        /* FOR TASK RHYN-102
          TASK DESCRIPTION: Add patient tab and subaccounts for age <16
          CHECK IS REDUX SUBACCOUNT STATE HAS KEY subAccountId THEN GET ALL DATA RELATED TO SUBACCOUNT */
        this.state = {
            anchorEl: null,
            notificationList: { data: [] },
            noOfNotifications: 0,
            path: path,
            showNotificationIcon: isSignedOutLink,
            userId: props.subAccount.subAccountId ? props.subAccount.subAccountId : props.auth.uid
        }
    }
    async componentDidMount() {
        // FOR TASK RHYN-102
        // TASK DESCRIPTION: Add patient tab and subaccounts for age <16
        // CHECK SHOWNOTIFICATIONICON STATE IF TRUE THEN WE WILL NOT RUN API TO GET NOTIFICATIONS LIST
        // SHOWNOTIFICATIONICON IS TRUE, SO WILL CHECK IT WITH ! SIGN SO THAT CONDITION WILL FAIL AS WE DONT'T NEED TO GET NOTIFICATION LIST WHEN ITS TRUE
        if (!this.state.showNotificationIcon) {
            if (this.props.auth && this.state.userId) {
                let result = await Functions.getNotificationsList(this.state.userId)
                if (result.updatedSubscription) {
                    let result = await this.props.getUpdatedUserInfo({ userId: this.state.userId })
                    this.props.updateProfileData(result)
                }
                this.setState({ notificationList: { data: result.data }, noOfNotifications: result.totalCount })
            }
            setInterval(async () => {
                if (this.props.auth && this.state.userId) {
                    let result = await Functions.getNotificationsList(this.state.userId)
                    this.setState({ notificationList: { data: result.data }, noOfNotifications: result.totalCount })
                    if (result.totalCount > 0) {
                        this.props.NotificationStateUpdate()
                    }
                    if (result.updatedSubscription) {
                        let result = await this.props.getUpdatedUserInfo({ userId: this.state.userId })
                        this.props.updateProfileData(result)
                    }
                    if (this.props.account && this.props.account && this.props.account.role === 'Patient' && this.props.account.useSubscriptionToken) {
                        if (this.props.account.subscriptionEndsAt && moment().unix() > this.props.account.subscriptionEndsAt) {
                            console.log('update user profile')
                            this.props.updateProfileData(result)
                        }
                    }
                }
            }, GETNOTIFICATIONTIME);
        }
    }
    handleClick = async (event) => {
        this.setState({ anchorEl: event.currentTarget });
        Functions.updateNotificationsList(this.state.userId)
        this.setState({ noOfNotifications: 0 })
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    render() {
        const { classes, auth } = this.props;

        return (
            <>
                {auth.uid ?
                    <Grid className={classes.parent}>
                        {this.props.account.role === 'Patient' ?
                            <a style={{ textDecoration: 'none !important' }} href='/appointment'>
                                <Button
                                    variant={"contained"}
                                    className={'btnCustomClass lightBlueClasstbn ' + classes.bookNowButton + ` ${this.state.path.pathname.startsWith('/chat') ? classes.chatClass : " "}`}>
                                    Book Now
                                </Button>
                            </a>
                            : null
                        }

                        {/* FOR TASK RHYN-102
                        TASK DESCRIPTION: Add patient tab and subaccounts for age <16
                        CHECK IS REDUX SUBACCOUNT REMOVED removedSubAccountData KEY IS SET */}
                        {this.props.account.role === 'Patient' ?
                            <CompleteSubAccountData />
                            : null
                        }
                        {/* FOR TASK RHYN-102
                        TASK DESCRIPTION: Add patient tab and subaccounts for age <16
                        CHECK SHOWNOTIFICATIONICON STATE IF TRUE THEN WE WILL NOT RUN API TO GET NOTIFICATIONS LIST
                        SHOWNOTIFICATIONICON IS TRUE, SO WILL CHECK IT WITH ! SIGN SO THAT CONDITION WILL FAIL AS WE DONT'T NEED TO GET NOTIFICATION LIST WHEN ITS TRUE */}
                        {!this.state.showNotificationIcon &&
                            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
                                <Badge badgeContent={this.state.noOfNotifications} color="secondary">
                                    <NotificationsIcon className={classes.notificationsIcon} />
                                </Badge>
                            </Button>
                        }

                        <Menu
                            id="simple-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.handleClose}
                        >
                            {this.state.notificationList && this.state.notificationList.data && this.state.notificationList.data.map((notification, key) => {
                                let notification_data = ''
                                let dateTime, date, display;
                                let notification_message
                                if (notification.date > 0) {

                                    dateTime = moment(notification.date * 1000).format('h:mm a')
                                    if (notification.isRhynoGo)
                                        dateTime = moment(notification.date * 1000).tz('America/New_York').format('h:mm a') + " to " + moment(notification.date * 1000).tz('America/New_York').add(2, 'hours').format('h:mm a')
                                    notification_message = notification.message
                                    notification_message = notification_message.split(' ')
                                    if (notification_message.indexOf('requests') !== -1) {
                                        notification_data = notification_message.join(' ')
                                        notification_data = notification_data.replace('[APPOINTMENTDATE]', moment(notification.date * 1000).format('MMM D, YYYY'))
                                        notification_data = notification_data.replace('[APPOINTMENTTIME]', '')
                                        // notification_data = notification_message.replace('[APPOINTMENTDATE]', '')
                                        notification_data = notification_data.substr(0, notification_data.length - 5);
                                        display = 1;
                                    }
                                    else if (notification_message.indexOf('RHYNO') !== -1) {
                                        let scheduledIndex = notification_message.indexOf('scheduled')
                                        if (notification_message[scheduledIndex + 1] === 'for' && notification_message[scheduledIndex + 3] === 'members')
                                            notification_message.splice(scheduledIndex + 4,)
                                        else
                                            notification_message.splice(scheduledIndex + 1,)

                                        notification_message.join(' ')
                                        notification_data = notification_message.join(' ')
                                        date = moment(notification.date * 1000).format('MMM D, YYYY')
                                        display = 0;
                                    }
                                    else {
                                        if (notification_message.indexOf('scheduled') !== -1) {
                                            notification_message.splice(notification_message.indexOf('scheduled'),)
                                            notification_message.join(' ')
                                            notification_data = notification_message.join(' ')
                                            date = moment(notification.date * 1000).format('MMM D, YYYY')
                                            display = 0;
                                        }
                                        else {
                                            notification_message.splice(notification_message.indexOf('[APPOINTMENTDATE]') - 1,)
                                            notification_message.join(' ')
                                            notification_data = notification_message.join(' ')
                                            date = moment(notification.date * 1000).format('MMM D, YYYY')
                                            display = 0;
                                        }
                                    }

                                    if (notification.isRhynoGo)
                                        date = moment(notification.date * 1000).tz('America/New_York').format('MMM D, YYYY')
                                }
                                else {
                                    notification_data = notification.message
                                }
                                return (
                                    <MenuItem
                                        className={classes.item}
                                        key={key}
                                        onClick={this.handleClose}>
                                        {display === 0 ?
                                            <>
                                                {date} at {dateTime}
                                                <br></br>
                                            </> : null}

                                        {notification_data}
                                    </MenuItem>)
                            })}
                        </Menu>
                    </Grid>
                    : ""}
            </>
        );
    }
}
const mapStateToProps = (state) => {
    /* FOR TASK RHYN-102
    TASK DESCRIPTION: Add patient tab and subaccounts for age <16
    GET THE SUBACCOUNT STATE TO GET DATA ACCORDING TO SUBACCOUNT IS SUBACCOUNT DATA EXIST IN REDUX */
    return {
        auth: state.auth,
        account: state.account.data,
        subAccount: state.subAccount
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        NotificationStateUpdate: () => dispatch({
            type: "NOTIFICATION_STATE_UPDATE",
            value: true
        }),
        getUpdatedUserInfo: (data) => getUpdatedUserInfo(data),
        updateProfileData: (data) => dispatch(data)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Notifications));