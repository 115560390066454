import React, { useState } from 'react'
import { Button, Grid, CircularProgress } from '@material-ui/core'
import { CreateNote } from '../../../../store/actions/EMRAction'
import { viewMoreStyle } from '../../style'
import { useSelector } from 'react-redux';
import RhynoGoCalendar from '../../../appointment/rhynogocalendar'
import functionsList from '../../../../assets/js/function'
import { Modal } from 'antd'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
.ant-modal-title {
    color: white;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
}
.ant-modal-header{
    background: rgb(0,178,181);
    border-radius: 9px 9px 0 0;
}
.ant-modal-body{
    justify-content: space-between
}
.ant-modal-content{
    border-radius: 9px;
}
.ant-modal-close-x{
    color:white;
}
.ant-modal-footer{
    text-align:center;
}
.modal-body{
    display:flex;
    justify-content:space-between;
}
`;
function TravelPCRRequisition({ onCloseModel, isRhynoGoPatient, patientName, data }) {

    const { profile, userInfo } = useSelector(
        (state) => ({
            profile: state.account.data,
            userInfo: state.emr.UserInfo
        })
    );

    const [loader, setLoader] = useState(false)
    const [scheduleTimeFroAppointment, setScheduleTimeFroAppointment] = useState(false)
    const [type, setType] = useState('')

    const saveRequisition = async () => {
        setLoader(true)

        setType('requisition')
        let sentObj = JSON.stringify({
            noteType: 'Covid19TravelPCRRequisition',
            userId: data.patientId,
            doctorId: data.doctorId,
            isRhynoGoPatient,
            patientName,
            userData: {},
            doctorData: {},
            formData: {},
            pagination_id: data.pagination,
            appointmentId: data.hasOwnProperty('appointmentId') ? data.appointmentId : "",
            sendEmail: true,
            sendNotification: 0
        })
        // CREATE NOTE 
        let response = await CreateNote(sentObj)
        setLoader(false)
        onCloseModel(true, response.data, response.pagination_id)
        setLoader(false)
    }

    const openAppointmentModel = () => {
        setScheduleTimeFroAppointment(true)
    }

    const getAppointmentSelectedTime = async (time) => {
        setScheduleTimeFroAppointment(false)
        setLoader(true)
        setType('appointment')
        let sentObj = {
            "noteType": "Covid19TravelPCRRequisition",
            "userId": data.patientId,
            "doctorId": data.doctorId,
            isRhynoGoPatient,
            patientName,
            "userData": {
                health_card_number: userInfo.hCard,
                first_name: userInfo.firstName,
                last_name: userInfo.lastName,
                zip: userInfo.zip,
                phone: userInfo.phone,
                date_of_birth: userInfo.dateofBirth,
                gender: userInfo.gender
            },
            "doctorData": {},
            "formData": {},
            "pagination_id": 0,
            "appointmentId": "",
            "sendEmail": false
        }
        let appointmentObject = {
            "appointmentType": "rhynogo",
            "doctorId": "",
            "doctorName": "",
            "userId": data.patientId,
            "userName": userInfo.fullName,
            "bookreason": "PCR COVID-19 testing",
            "bookdateofHCExp": "",
            "bookhCard": "",
            "appointmentFor": time,
            "bookCardType": "",
            "bookdoctorNote": 1,
            "bookforms": 0,
            "holdamount": 0,
            "appointmentDuration": 15,
            "isCovid19": 1,
            "files": [],
            "addedBy": "doctor",
            "active_subscription_id": "",
            "isRhynoGoAppointment": true,
            "rhynoGoDocuments": [],
            "reasonType": "\"AT HOME COVID-19 TESTING FOR TRAVEL (<12 HOURS)\"",
            "doctorSpecialty": "",
            "outTime": 1,
            "rhynoGoAddress": "",
            "numberOfUsers": 0,
            "membersData": {},
            "voucherCode": "",
            "rhynoGoAmount": 0,
            "skipBilling": false,
            "paidamount": 0,
            "stripetoken": "",
            "requisition": sentObj
        }

        await functionsList.createOrder(appointmentObject)
        let response = await CreateNote(JSON.stringify(sentObj))
        setLoader(false)
        onCloseModel(true, response.data, response.pagination_id)
    }

    return (
        <div>
            {scheduleTimeFroAppointment ?
                <StyledModal
                    title={'Calendar'}
                    className='modal'
                    visible={true}
                    width={1000}
                    onCancel={onCloseModel}
                    footer={null}
                >
                    <RhynoGoCalendar updateRhynoGoTime={getAppointmentSelectedTime} appointmentData={{ type: 'rhynogo', userSelectedZipCode: profile.zip }} />
                </StyledModal>
                :
                <Grid>
                    <h2>Do you have appointment details for this patient?</h2>
                    <Grid style={buttonStyle}>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={loader}
                            className={'btnCustomClass lightGreyClasstbn'}
                            style={viewMoreStyle}
                            onClick={saveRequisition}>{loader && type === 'requisition' ? <CircularProgress size={24} /> : "No"}</Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={loader}
                            className={'btnCustomClass lightBlueClasstbn'}
                            style={viewMoreStyle}
                            onClick={openAppointmentModel}>{loader && type === 'appointment' ? <CircularProgress size={24} /> : "Yes"}</Button>
                    </Grid>
                </Grid>
            }
        </div>
    )
}
const buttonStyle = {
    display: 'flex',
    justifyContent: 'space-between'
}
export default TravelPCRRequisition
