import React, { useState } from 'react'
import { Alert } from 'antd'
import { Grid, TextField } from '@material-ui/core';
import ModelFooter from './ModelFooter'
import { modelBody, modelRow, formRowLastField } from './style'
import { createUpdateMedicalHistoryItem, deleteMedicalHistoryItem } from './API/API'

function MedicalForm({ patientName, isRhynoGoPatient, row, id, onCloseModel, data }) {

    const [diagnose, setDiagnose] = useState(row && row.diagnose ? row.diagnose : '')
    const [year, setYear] = useState(row && row.year ? row.year : '')

    const [message, setMessage] = useState('')
    const [loader, setLoader] = useState(false)
    const [loaderType, setLoaderType] = useState('')
    const [formSubmitted, setFormSubmitted] = useState(false)

    const saved = async () => {
        setFormSubmitted(true)

        if (year && diagnose) {
            setLoader(true)
            setLoaderType('save')
            let result = ''
            if (id)
                result = await createUpdateMedicalHistoryItem({ year: year, diagnose: diagnose, userId: data.patientId, doctorId: data.doctorId, pagination_id: data.pagination, id: id, patientName: patientName, isRhynoGoPatient: isRhynoGoPatient })
            else
                result = await createUpdateMedicalHistoryItem({ year: year, diagnose: diagnose, userId: data.patientId, doctorId: data.doctorId, pagination_id: data.pagination, patientName: patientName, isRhynoGoPatient: isRhynoGoPatient })

            setLoader(false)
            if (result.message)
                setMessage(result.message)
            else
                onCloseModel('', true, 'Saved', result.data, result.pagination_id)
        }
    }
    const deleted = async () => {
        setLoader(true)
        setLoaderType('delete')
        let result = await deleteMedicalHistoryItem({ userId: data.patientId, doctorId: data.doctorId, pagination_id: data.pagination, id: id, patientName: patientName, isRhynoGoPatient: isRhynoGoPatient })
        setLoader(false)
        if (result.message)
            setMessage(result.message)
        else
            onCloseModel('', true, 'Deleted', result.data, result.pagination_id)
    }

    return (
        <>
            <div className='modal-body' style={modelBody}>
                <Grid>
                    {message ?
                        <Grid >
                            <Alert type="error" closable={true} message={message}></Alert>
                        </Grid>
                        : ""}
                </Grid>
                <Grid style={modelRow}>
                    <TextField
                        type="text"
                        variant="outlined"
                        margin="normal"
                        autoFocus
                        required
                        label="Add Diagnosis"
                        name="DiagnoseVal"
                        value={diagnose}
                        onChange={(e) => setDiagnose(e.target.value)}
                        error={formSubmitted && !diagnose}
                        helperText={formSubmitted && !diagnose && "Diagnoses Field Required"}
                    />
                    <TextField
                        type="text"
                        variant="outlined"
                        margin="normal"
                        required
                        style={formRowLastField}
                        name="DiagnoseYear"
                        label="Diagnosis Year"
                        value={year}
                        onChange={(e) => setYear(e.target.value)}
                        error={formSubmitted && !year}
                        helperText={formSubmitted && !year && "Year Field Required"}
                    />
                </Grid>
            </div>
            <ModelFooter saveClicked={saved} onCloseModel={onCloseModel} deleteClicked={deleted} loader={loader} id={id} loaderType={loaderType} />
        </>
    )
}

export default MedicalForm
