import React from 'react'
import { List, ListItem, ListItemIcon, ListItemText, Grid } from '@material-ui/core'
// import DashboardIcon from '@material-ui/icons/Dashboard'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import AssignmentIndSharpIcon from '@material-ui/icons/AssignmentIndSharp'
// import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions';
// import HomeIcon from '../../assets/images/HomeSideBar.svg'
import LogoutIcon from '../../assets/images/LogoutIcon.svg'
import Logo from '../../assets/images/SideBarLogo.svg';
import withStyles from '@material-ui/styles/withStyles'
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import TodayIcon from '@material-ui/icons/Today';
// import SwapHorizSharpIcon from '@material-ui/icons/SwapHorizSharp';
// import { switchToOtherAccount } from '../subAccounts/API/API'

const styles = theme => ({
    container: {
        '& .MuiListItemText-root': {
            color: theme.palette.lightGrey.main
        }
    },
    helpBox: {
        backgroundColor: 'rgb(229, 247,247)',
        height: '100px',
        bottom: '20px',
        margin: 'auto',
        position: 'relative',
        width: '90%',
        borderRadius: '9px',
    },
    header: {
        color: '#00bbbe',
        fontWeight: 'bold',
    },
    subHeader: {
        color: theme.palette.lightGrey.main
    },
    imgLogo: {
        width: '9rem',
        marginTop: '1rem',
        marginLeft: '15%'
    },
    imgIcons: {
        width: '1.3rem'
    },
    muiIcons: {
        color: theme.palette.lightGrey.main,
        fontSize: '1.7rem'
    }
})

const SignedInLinks = (props) => {

    const { classes } = props
    return (
        <Grid className={classes.container}>
            <List>
                <ListItem key={1} button divider component="a" href="/dashboard" >
                    <ListItemIcon>
                        {/* <img className={classes.imgIcons} src={HomeIcon} alt='homeIcon' /> */}
                        <HomeIcon className={classes.muiIcons} />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard"></ListItemText>
                </ListItem>
                {/* CHANGE LABEL FROM APPOINTMENT TO BOOK NOW */}
                <ListItem key={2} button divider component="a" href="/appointment" >
                    <ListItemIcon>
                        <AssignmentIndSharpIcon className={classes.muiIcons} />
                    </ListItemIcon>
                    <ListItemText primary="Book Now">
                    </ListItemText>
                </ListItem>
                <ListItem key={8} button divider component="a" href="/patients" >
                    <ListItemIcon>
                        <SupervisorAccountIcon className={classes.muiIcons} />
                    </ListItemIcon>
                    <ListItemText primary="Add Patient">
                    </ListItemText>
                </ListItem>
                <ListItem
                    key={3} button divider component="a" href="/contact" >
                    <ListItemIcon><ContactMailIcon className={classes.muiIcons} /></ListItemIcon>
                    <ListItemText primary="Contact"></ListItemText>
                </ListItem>
                <ListItem key={4} button divider component="a" href="/all_appointments">
                    <ListItemIcon>
                        <TodayIcon className={classes.muiIcons} />
                    </ListItemIcon>
                    <ListItemText primary="Appointments"></ListItemText>
                </ListItem>
                <ListItem key={5} button divider component="a" href="/membership" >
                    <ListItemIcon>
                        <PeopleIcon className={classes.muiIcons} />
                    </ListItemIcon>
                    <ListItemText primary="Membership"></ListItemText>
                </ListItem>
                <ListItem key={6} button divider component="a" href="/account" >
                    <ListItemIcon>
                        <AccountCircleIcon className={classes.muiIcons} />
                    </ListItemIcon>
                    <ListItemText primary="Account"></ListItemText>
                </ListItem>
                {/* {props.subAccount && props.subAccount.subAccountId &&
                    <ListItem key={7} button divider component="a" onClick={() => props.switchBackToMainUser()} >
                        <ListItemIcon>
                            <SwapHorizSharpIcon className={classes.muiIcons} />
                        </ListItemIcon>
                        <ListItemText primary="Switch Account"></ListItemText>
                    </ListItem>
                } */}
                <ListItem key={8} button divider component="a"
                    onClick={() => props.signOut()} >
                    <ListItemIcon>
                        <img className={classes.imgIcons} src={LogoutIcon} alt='logoutButton' />
                    </ListItemIcon>
                    <ListItemText primary="Sign Out"></ListItemText>
                </ListItem>
            </List>
            <Grid>
                <img className={classes.imgLogo} alt='Logo' src={Logo}></img>
            </Grid>
            <Grid className={classes.helpBox}>
                <br></br>
                <span className={classes.header}>Do you need help ?</span>
                <br></br>
                <br></br>
                <a href="tel:+18557496646"><span className={classes.subHeader}>1-855-RHYNO-GO</span></a>
            </Grid>
        </Grid>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        // subAccount: state.subAccount
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
        // switchBackToMainUser: () => dispatch(switchToOtherAccount(null))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignedInLinks))
