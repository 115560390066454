import React from 'react'
//MUI
import { MenuItem, Select, TextField } from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles"
import EditIcon from '@material-ui/icons/Edit'
const styles = theme => ({
  label: {
    color: theme.palette.lightGrey.main,
  },
  selectInput: {
    width: '100%',
    '& .MuiSelect-root': {
      color: theme.palette.lightGrey.main,
    },
    "& .Mui-focused": {
      color: 'black'
    }
  },
  container: {

  },
  textFieldInput: {
    '& .MuiInputBase-root': {
      color: theme.palette.lightGrey.main,
    },
    "& .Mui-focused": {
      color: 'black'
    }
  },
  editIcon: {
    fontSize: '1rem',
    color: theme.palette.lightGrey.main,
  },

})
const LocationsInputs = (props) => {
  // console.log(props) 
  const { classes, country, changeCountry,
    province, changeProvince,
    availablecountries, availableStates } = props

  let countriesList = [], USStatesList = [], CanadaStatesList = []
  if (availablecountries) {
    countriesList = availablecountries.map((availablecountry, key) =>
      <MenuItem key={key} value={availablecountry.name}>{availablecountry.name}</MenuItem>
    )
  }
  if (availableStates) {
    USStatesList = (availableStates.US).map((availablestate, key) =>
      <MenuItem key={key} value={availablestate.name}>{availablestate.name}</MenuItem>
    )
    CanadaStatesList = (availableStates.CA).map((availablestate, key) =>
      <MenuItem key={key} value={availablestate.name}>{availablestate.name}</MenuItem>
    )
  }
  return (
    <div className={classes.container}>
      {/* <h3>Locations</h3> */}
      <label className={classes.label}>Country</label>
      <br></br>
      <Select
        value={country}
        className={classes.selectInput}
        onChange={(e) => {
          changeCountry(e.target.value)
          // console.log(e.target.value)
        }}
        label="Country"
      //   style={{ display: this.state.edit_key !== 'country' ? 'none' : 'flex' }}
      //   className={classes.label + " editable_field"}
      >
        {countriesList}
      </Select>
      <br></br>
      <label className={classes.label}>Province/State</label>
      <br></br>
      {country === 'Canada' ?
        <>
          <Select
            // onChange={this.handleChange}
            // style={{ display: this.state.edit_key !== 'state' ? 'none' : 'flex' }}
            // className={classes.label + " editable_field"}
            className={classes.selectInput}
            required
            label="State:"
            value={province}
            onChange={(e) => {
              changeProvince(e.target.value)
            }}
          >
            {CanadaStatesList}
          </Select>
        </>
        :
        null}
      {country === 'United States of America' ?
        <>
          <Select
            // onChange={this.handleChange}
            // style={{ display: this.state.edit_key !== 'state' ? 'none' : 'flex' }}
            // className={classes.label + " editable_field"}
            className={classes.selectInput}
            required
            label="State:"
            value={province}
            onChange={(e) => {
              changeProvince(e.target.value)
            }}
          >
            {USStatesList}
          </Select>

        </>
        :
        null}
      {country === 'Canada' || country === 'United States of America' ?
        null
        :
        <TextField
          value={province}
          className={classes.textFieldInput}
          onChange={(e) => changeProvince(e.target.value)}
          InputProps={{
            endAdornment: (
              <EditIcon className={classes.editIcon} />
            )
          }}
        />
      }

    </div>
  )
}
export default withStyles(styles)(LocationsInputs)