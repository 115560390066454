import React, { useState } from 'react'
import RequisitionForm from './RequisitionForm'
import { Button, Grid, CircularProgress } from '@material-ui/core'
import { CreateNote } from '../../../../store/actions/EMRAction'
import { viewMoreStyle } from '../../style'
import moment from 'moment'
import { useSelector } from 'react-redux';

function DoctorNote({ onCloseModel, isRhynoGoPatient, patientName, data }) {

    const { profile, userInfo } = useSelector(
        (state) => ({
            profile: state.account.data,
            userInfo: state.emr.UserInfo
        })
    );

    const [noteRequisitionData, setNoteRequisitionData] = useState({})
    const [loader, setLoader] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false)

    const updateRequisitionData = (data) => {
        setNoteRequisitionData(data)
    }

    const cancelNoteRequisition = async () => {
        onCloseModel(false, {}, 1)
    }

    const saveRequisition = async () => {
        setLoader(true)
        setFormSubmitted(true)

        if (noteRequisitionData.Note) {
            let showDr = profile.speciality === 'Nurse Practitioner' ? '' : 'Dr. '
            let showNP = profile.speciality === 'Nurse Practitioner' ? ', NP' : ''

            let addressVar = profile.city + ", " + profile.state + ", " + profile.zip + ", " + profile.country
            addressVar = addressVar.replace(', ,', ",")
            // SET DOCTOR DATA VARIABLE
            let doctorData = {
                'DoctorName': showDr + profile.fullName + showNP,
                'DoctorPhone': profile.phone,
                'DoctorFax': profile.fax,
                'DoctorStreet': profile.street,
                'DoctorCityStateZip': addressVar,
                'Lincense': profile.licenseInfo,
                'Billing': profile.billingInfo,
                'signature': profile.signature,
                'Date': moment().format('L')
            }
            // SET DOCTOR DATA VARIABLE
            let userData = {
                'PatientName': userInfo.fullName,
                'PatientDOB': moment(userInfo.dateofBirth).format("D") + '/' + moment(userInfo.dateofBirth).format("M") + '/' + moment(userInfo.dateofBirth).format("YYYY"),
                'PatientHC': userInfo.hCard,
                'PatientSex': userInfo.gender,
            }

            // CREATE COVID REQUISITION 
            let sentObj = JSON.stringify({
                noteType: 'DoctorNote',
                userId: data.patientId,
                doctorId: data.doctorId,
                isRhynoGoPatient,
                patientName,
                userData: userData,
                doctorData: doctorData,
                formData: noteRequisitionData,
                pagination_id: data.pagination,
                appointmentId: data.hasOwnProperty('appointmentId') ? data.appointmentId : ""
            })
            // CREATE NOTE 
            let response = await CreateNote(sentObj)
            setLoader(false)
            onCloseModel(true, response.data, response.pagination_id)
        }
        else {
            setLoader(false)
        }
    }

    return (
        <div>
            <RequisitionForm formSubmitted={formSubmitted} updateRequisitionData={updateRequisitionData} />
            <Grid style={buttonStyle}>
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={loader}
                    className={'btnCustomClass lightGreyClasstbn'}
                    style={viewMoreStyle}
                    onClick={cancelNoteRequisition}>Cancel</Button>
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={loader}
                    className={'btnCustomClass lightBlueClasstbn'}
                    style={viewMoreStyle}
                    onClick={saveRequisition}>{loader ? <CircularProgress size={24} /> : "Save"}</Button>
            </Grid>
        </div>
    )
}
const buttonStyle = {
    display: 'flex',
    justifyContent: 'space-between'
}
export default DoctorNote
