import React, { Component } from 'react'
import withStyles from "@material-ui/core/styles/withStyles"
import { Grid, Button, TextField, CircularProgress } from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email';

const styles = theme => ({
    popup: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column'
    },
    popupDiv: {
        borderRadius: "20px",
        backgroundColor: '#fff',
        padding: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '35rem',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            width: '100%'
        }
    },
    popupButton: {
        padding: '10px',
        width: '150px',
        marginLeft: '50px',
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        },
        marginBottom: theme.spacing(2),
        fontFamily: 'inherit',
        color: '#ffffff'
    },
    popupHeading: {
        fontFamily: 'inherit',
        fontWeight: 700,
        paddingBottom: theme.spacing(6),
        // color: '#795bb2',
        fontSize: theme.spacing(2.4)
    },
    font: {
        fontFamily: 'revert',
    },
    mainDiv: {
        display: 'flex',
        flexDirection: 'column',
        width: '75%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    formButton: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    mobile_number: {
        display: 'flex',
        alignItems: 'center'
    },
    emailInput: {
        display: 'flex',
    },
    emailIcon: {
        fontSize: '15px'

    }
})
class GetFamilyEmailsList extends Component {

    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = {
            askForEmails: true,
            isFormValid: true,
            emails: { data: [] },
            email: '',
            noOfemailAddress: 1
        }
    }

    componentDidMount() {
        this._isMounted = true
    }

    // FORM DATA UPDATED
    change = (e, text) => {
        let val = e.target.value
        if (this._isMounted) {
            this.setState({ isFormValid: true, email: val })
        }
    }

    submitEmails = () => {
        let emailsList = this.state.emails.data
        if (this.state.email !== '') {
            emailsList.push(this.state.email)
            this.setState({ emails: { data: emailsList } })
        }
        this.props.emailsList(emailsList)
    }
    addMoreEmailAddress = () => {
        //email validation
        //const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const emailRegex = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRegex.test(this.state.email) === false || this.state.email === '') {
            this.setState({ isFormValid: false });
            console.log('invalid email');
            return;
        }
        if (this.state.email !== '') {
            let emailsList = this.state.emails.data
            emailsList.push(this.state.email)
            this.setState({ noOfemailAddress: this.state.noOfemailAddress + 1, emails: { data: emailsList } })
            //clear state after pushing to array
            this.setState({ email: '' })
        }
    }
    render() {
        let { classes } = this.props

        return (
            <Grid className={classes.font}>
                <Grid className={classes.popup}>
                    <Grid className={classes.popupDiv}>

                        {this.state.askForEmails ?
                            <Grid className={classes.mainDiv}>
                                <span className={classes.popupHeading}>Do you wish to add your family members?</span>

                                <Grid className={classes.formButton}>
                                    <Button variant="contained" onClick={() => this.setState({ askForEmails: false })} color={'primary'} className={classes.popupButton}>
                                        Yes
                                    </Button>
                                    <Button variant="contained" onClick={() => this.submitEmails()} color={'secondary'} className={classes.popupButton}>
                                        {this.props.loading ? <CircularProgress className={classes.button} size={21} /> : "No"}
                                    </Button>
                                </Grid>
                            </Grid>
                            :
                        <Grid className={classes.mainDiv}>
                            <span className={classes.popupHeading}>Please enter your family member's email address (must be different from your account)</span>
                            {
                                this.state.noOfemailAddress > 0 ?
                                    Array.from(Array(parseInt(this.state.noOfemailAddress))).map((val, key) => {
                                        return (

                                            <TextField
                                                key={key}
                                                id={"email_" + (key + 1)}
                                                value={this.state.emails.data["document" + (key + 1)]}
                                                onChange={(e) => this.change(e, 'email')}
                                                onBlur={(e) => this.change(e, 'email')}
                                                label="Email Address"
                                                style={{ marginBottom: '24px' }}
                                                error={!this.state.isFormValid}
                                                helperText={!this.state.isFormValid ? "Invalid Email format" : ""}
                                                InputProps={{
                                                    endAdornment: (
                                                        <EmailIcon className={classes.emailIcon} />
                                                    )
                                                }}
                                            />

                                        )
                                    })
                                    : ""
                            }

                            <Grid className={classes.formButton}>
                                    {this.state.noOfemailAddress < 8 ?
                                    <Button variant="contained" onClick={this.addMoreEmailAddress} color={'primary'} className={classes.popupButton}>
                                        Add More
                                    </Button>
                                    : <p></p>
                                }
                                <Button variant="contained" onClick={() => this.submitEmails()} color={'secondary'} className={classes.popupButton}>
                                    {this.props.loading ? <CircularProgress className={classes.button} size={21} /> : "Submit"}
                                </Button>
                            </Grid>
                        </Grid>
                        }


                    </Grid>

                </Grid>
            </Grid>
        )
    }
}
export default withStyles(styles)(GetFamilyEmailsList);
