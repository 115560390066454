import React, { useState, useEffect } from 'react'
import { Grid, Typography, CircularProgress } from '@material-ui/core'
import { progress, sectionDetail, mainStyle, item } from './style'
import { getDocHistory } from './API/API'
import ViewMore from './ViewMore'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'

function DocumentsList({ patientId }) {

    const [documentsHistory, setDocumentsHistory] = useState([])
    const [pagination, setPagination] = useState('')
    const [loader, setLoader] = useState(true)

    const getUserPatientDocHistory = async () => {
        setLoader(true)
        let result = await getDocHistory(patientId, pagination)
        setDocumentsHistory(documentsHistory.concat(result.data))
        setPagination(result.pagination_id)
        setLoader(false)
    }

    useEffect(() => {
        getUserPatientDocHistory()
    }, [])

    return (
        <Grid style={mainStyle}>
            <Grid style={sectionDetail}>
                <Grid style={sectionDetail}>
                    {documentsHistory.map((doc, key) =>
                        <Typography key={key} align={"left"} style={item}>
                            {decodeURI(doc.name)}
                            {doc.link && doc.link !== '' ? <a href={doc.link} target="_blank" rel="noopener noreferrer"><PictureAsPdfIcon color="primary" /></a> : ""}
                        </Typography>)
                    }
                    {loader && <CircularProgress style={progress} size={24} />}
                    {pagination !== -1 && <ViewMore loader={loader} getMoreRecords={getUserPatientDocHistory} />}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DocumentsList
