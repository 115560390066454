import React, { useState, useEffect } from 'react'
import SectionItem from './SectionItem'
import { Grid } from '@material-ui/core'
import { sections } from './style'
import DragDrop from './DragDrop'
import { useDrag, useDrop } from 'react-dnd'
import { useSelector, useDispatch } from 'react-redux';
import { updateEMRState } from './API/API'
import DehazeSharpIcon from '@material-ui/icons/DehazeSharp'
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
    arrayMove
} from 'react-sortable-hoc';

const DragHandle = sortableHandle(({ }) => <DehazeSharpIcon style={{ position: 'absolute', zIndex: 1, top: '1.4rem', left: '1rem', cursor: 'all-scroll' }} />);

const SortableItem = sortableElement(({ value }) => (
    <div style={{ position: 'relative', textAlign: 'left', }}>
        <DragHandle />
        {value}
    </div>
));

const SortableContainer = sortableContainer(({ children }) => {
    return <ul>{children}</ul>;
});

function EMRComponents({ patientId, patientName, isRhynoGoPatient }) {

    const { emr } = useSelector(
        (state) => ({
            emr: state.emr
        })
    );

    const chat_ref = React.useRef(emr);

    const dispatch = useDispatch()

    const setChat = x => {
        chat_ref.current = x; // keep updated
        dispatch(updateEMRState({ data: x['ChatsHistoryList'], type: "EMR_CHAT_LIST" }))
    }
    let dragging = DragDrop(useDrag, useDrop, useSelector, setChat, chat_ref)

    useEffect(() => {
        setChat(emr)
    }, [emr['ChatsHistoryList']])

    const [sortableItems, setSortableItems] = useState([
        {
            id: "ProgressNoteTemplates",
            item: <SectionItem type={"templates"} noteTemplateDrag={dragging.noteTemplateDrag} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} patientId={patientId} />
        },
        {
            id: "MedHistory",
            item: <div ref={dragging.medicalDrag}>
                <SectionItem type={"medical"} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} patientId={patientId} />
            </div>
        },
        {
            id: "SocialHistory",
            item: <div ref={dragging.socialDrag}>
                <SectionItem type={"social"} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} patientId={patientId} />
            </div>
        },
        {
            id: "Medication",
            item: <div ref={dragging.medicationDrag}>
                <SectionItem type={"medication"} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} patientId={patientId} />
            </div>
        },
        {
            id: "Allergies",
            item: <div ref={dragging.allergyDrag}>
                <SectionItem type={"allergy"} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} patientId={patientId} />
            </div>
        }
    ])

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setSortableItems(arrayMove(sortableItems, oldIndex, newIndex))
    };

    return (
        <Grid style={sections} className={"emr"}>
            <Grid item xs={12} sm={12} md={3}>
                <SectionItem type={"appointments"} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} patientId={patientId} />
                <SectionItem type={"notes"} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} patientId={patientId} />
                {!isRhynoGoPatient && <SectionItem type={"documents"} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} patientId={patientId} />}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <div ref={dragging.drop}>
                    <div>
                        <SectionItem type={"chat"} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} patientId={patientId} />
                    </div>
                </div>
            </Grid>

            {/* <Grid item xs={12} sm={12} md={3}>
                <SectionItem type={"templates"} noteTemplateDrag={dragging.noteTemplateDrag} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} patientId={patientId} />
                <div ref={dragging.medicalDrag}>
                    <SectionItem type={"medical"} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} patientId={patientId} />
                </div>
                <div ref={dragging.socialDrag}>
                    <SectionItem type={"social"} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} patientId={patientId} />
                </div>
                <div ref={dragging.medicationDrag}>
                    <SectionItem type={"medication"} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} patientId={patientId} />
                </div>
                <div ref={dragging.allergyDrag}>
                    <SectionItem type={"allergy"} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} patientId={patientId} />
                </div>
            </Grid> */}

            <Grid item xs={12} sm={12} md={3} className={"EMRSortingClass"}>
                <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                    {sortableItems.map(({ id, item }, index) => (
                        <SortableItem key={`item-${id}`} index={index} value={item} />
                    ))}
                </SortableContainer>
            </Grid>
        </Grid>
    )
}
export default EMRComponents
