import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getSearchedDoctors, getDoctorsListForAppointment, getSearchedFiltersData } from '../../store/actions/appointmentAction'
import withStyles from "@material-ui/core/styles/withStyles"
import LocalHospitalRoundedIcon from '@material-ui/icons/LocalHospitalRounded'
import { Grid, Avatar, Button, CircularProgress, Select, MenuItem, FormControl, InputLabel, TextField } from '@material-ui/core'
import { SEARCHRECORDSLIMIT } from '../../config.js'
import SearchResults from './searchresult'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import { Autocomplete } from '@material-ui/lab'
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import { isMobileOnly } from 'react-device-detect'
import ConfirmAddress from './ConfirmAddress'
import styled from 'styled-components'
import { Modal } from 'antd'
import { Typography } from '@material-ui/core'

const BookAppointmentType = styled(Modal)`
.ant-modal-title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
}
.ant-modal-header{
    border-radius: 9px 9px 0 0;
    border-bottom:none;
}
.ant-modal-body{
    text-align: center;
    // justify-content: center;
}
.ant-modal-content{
    
    border-radius: 9px;
}
.ant-modal-footer{
    text-align:center;
    border-top:none;

}


`

const styles = theme => ({
    popup: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    popupDiv: {
        backgroundColor: '#fff',
        marginTop: '3%',
        padding: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: { padding: theme.spacing(2) }
    },
    avatar: {
        margin: 'auto',
        width: '80px',
        position: 'relative',
        bottom: '50px',
        height: '80px',
        backgroundColor: theme.palette.primary.main,
    },
    addressPopUpAvatar: {
        margin: 'auto',
        width: '80px',
        position: 'relative',
        marginBottom: '-3rem',
        bottom: '75px',
        height: '80px',
        backgroundColor: theme.palette.primary.main,

    },
    popupButton: {
        marginBottom: theme.spacing(2),
        fontFamily: 'inherit',
        color: 'white'
    },
    popupSpan: {
        margin: theme.spacing(2.5, 0, 8),
        fontSize: '1.2rem',
        fontFamily: 'inherit',
        fontWeight: 600,
        color: "#979797"
    },
    popupHeading: {
        fontFamily: 'inherit',
        fontWeight: 700
    },
    popupP: {
        margin: theme.spacing(2, 0),
        fontSize: '1.1rem',
        fontFamily: 'inherit',
        fontWeight: 600,
    },
    font: {
        fontFamily: 'revert',
    },
    filters: {
        display: 'flex',
        background: 'white',
        padding: '5px',
        borderRadius: '.5rem',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: { flexDirection: 'column' }
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '90%',
        [theme.breakpoints.down('sm')]: { width: '100%', margin: theme.spacing(1, 0) }
    },
    selectEmpty: {
        marginTop: theme.spacing(.75),
        width: '100%'
    },
    filterlist: {
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: { minWidth: '100%' }
    },
    selectLabel: {
        marginLeft: theme.spacing(1.5),
    },
    NameField: {
        marginTop: theme.spacing(.5),
        [theme.breakpoints.down('sm')]: { width: '100%' }
    },
    NameFieldText: {
        width: '200px',
        [theme.breakpoints.down('sm')]: { width: '100%' }
    },
    paginationDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down('sm')]: { marginTop: theme.spacing(2) }
    },
    pagination: {
        margin: theme.spacing(0, 1)
    },
    icon: {
        border: 'none',
        padding: theme.spacing(.7),
        backgroundColor: "inherit",
        width: theme.spacing(5.5),
        height: theme.spacing(4),
        cursor: 'pointer'
    },
    filterTag: {
        fontFamily: 'inherit',
        fontSize: '1.2rem',
        marginRight: theme.spacing(2),
        color: '#795bb2',
        fontWeight: '700'
    },
    expansionpanel: {
        maxWidth: '100%',
        padding: 0,
        margin: '0 !important',
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: { width: '100%' }
    },
    panelSummary: {
        minHeight: '0px !important'
    },
    filterItemList: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: { flexDirection: 'column' }
    },
    dateFilter: {
        marginRight: '4px',
        minWidth: '150px',
        maxWidth: '150px',
        [theme.breakpoints.down('sm')]: { minWidth: '100%', maxWidth: '100%' }
    },
    confirmAddress: {
        lineHeight: "1.5",
        fontSize: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    subHeader: {
        color: theme.palette.lightGrey.main,
    },
    header: {
        fontSize: '20px',
        fontWeight: 'bold',
    },
    bookingOptions: {
        justifyContent: 'center',
        gap: '1rem',
        flexWrap: 'wrap',
        paddingTop: '20px',
        display: 'flex',
        // justifyContent:'space-between'
    },
    rhynoGoService: {
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        width: '250px',
        height: '100px',
        borderRadius: '20px',
        backgroundColor: 'rgb(255 221 160)',
        '&:hover': {
            cursor: 'pointer',
            background: 'rgb(255 198 97)',
        }
    },
    seeProviderNo: {
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        width: '250px',
        height: '100px',
        borderRadius: '20px',
        backgroundColor: 'rgb(255,234,234)',
        '&:hover': {
            cursor: 'pointer',
            background: 'rgb(251 174 174)',
        }
    },
    futureAppointment: {
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        width: '250px',
        height: '100px',
        borderRadius: '20px',
        backgroundColor: 'rgb(239,232,254)',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgb(209 191 249)',

        }
    },
    bookingText: {
        fontSize: '20px',
        fontWeight: '500',
        padding: '10px'
    },
    buttonBack: {
        backgroundColor: 'rgb(240, 242, 245)',
        width: "100%",
        color: theme.palette.lightGrey.main,
        padding: "7px",
        borderRadius: '10px',
        border: 'none',
        height: '50px',
    },
    postCodeButton: {
        width: "100%",
        borderRadius: '10px',
        marginBottom: '5%',
        border: 'none',
        color: 'white',
        height: '50px',
        padding: "7px",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: "#1f4037"
        },
    }
})
class Filters extends Component {

    constructor(props) {
        super(props)
        let state = 'any';
        let speciality = 'any';
        let showAppointmentPopUp = true;
        if (props.appointmentData.speciality !== '') {
            speciality = props.appointmentData.speciality
            showAppointmentPopUp = false
        }
        if (props.appointmentData.allowedSpecialities) {
            //check for empty array to avoid undefined error
            if (props.appointmentData.allowedSpecialities.length > 0) {
                showAppointmentPopUp = false
                speciality = props.appointmentData.allowedSpecialities[0]
            }
        }

        if (this.props.appointmentData.hasOwnProperty('showProviderAppointmentTypes') && this.props.appointmentData.showProviderAppointmentTypes) {
            showAppointmentPopUp = false
        }
        // SETTING STATE FOR PAGE
        let permanentAdress = props.account.data.street + " " + props.account.data.city + " " + props.account.data.state + ", " + props.account.data.zip + " " + props.account.data.country
        this.state = {
            hasProviderAppointment: false,
            permanentAddress: permanentAdress,
            confirmPermanentAddress: props.appointmentData.hasOwnProperty('isAgency') && props.appointmentData.isAgency ? false : true,
            showAppointmentPopUp: showAppointmentPopUp,
            showNoOnDemandProvidersPopUp: false,
            searchType: '',
            showAddressPopUp: false,
            postCodeConfirmed: true,
            loading: false,
            specialtiesList: [],
            statesList: [],
            languagesList: [],
            doctorList: [],
            showOptionsList: false,
            showEmptyMessage: false,
            timeDifferenceInMiliSeconds: 0,
            expanded: isMobileOnly ? false : true,
            width: window.innerWidth,
            filter: {
                'speciality': speciality,
                'availability': "",
                'language': "any",
                'location': state,
                'doctorId': '',
                'online': false,
                'page': 0,
                'doctorName': '',
            },
        }
        this._isMounted = false;
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }
    componentDidMount() {
        this._isMounted = true
        this.calculateTimeDifference()
        if (this.props.appointmentData.speciality !== '')
            this.setSearchType(this.props.appointmentData.appointmentType)

        if (this.props.appointmentData.appointmentType === 'rhynogo' && this.props.appointmentData.reason !== '') {
            this.setState({ showAppointmentPopUp: false })
            this.props.appointmentData.isRhynoGoAppointment = true
            if (this.props.appointmentData.covidType === 'AT HOME WELLNESS VISITS')
                this.setSearchType('schedule')
            else
                this.setSearchType('onDemand')
        }

        if (this.props.appointmentData.hasOwnProperty('showProviderAppointmentTypes') && this.props.appointmentData.showProviderAppointmentTypes) {
            this.setState({ loading: true, searchType: this.props.appointmentData.appointmentType })
            this.setSearchType(this.props.appointmentData.appointmentType)
        }
        window.addEventListener('resize', this.handleWindowSizeChange);
    }
    calculateTimeDifference() {
        // Browser Time Zone Difference In Minutes With UTC
        let browserDifferce = new Date().getTimezoneOffset() * -1
        // User Time Zone Difference In Minutes With UTC
        let userDifference = moment.tz.zone(this.props.account.data.zone).utcOffset(moment.utc()) * -1
        // Get time Difference In Minutes
        let timeDifferenceInMinutes = userDifference - browserDifferce
        // Get time Difference In MilliSeconds
        let timeDifferenceInMiliSeconds = timeDifferenceInMinutes * 60 * 1000
        if (this._isMounted)
            this.setState({ timeDifferenceInMiliSeconds: timeDifferenceInMiliSeconds })
    }

    // GENERATE DATE
    calculateDate = (date) => {
        // Set Date To 00:00:00 AM
        let receivedDate = new Date(date)
        receivedDate.setHours(0)
        receivedDate.setSeconds(0)
        receivedDate.setMinutes(0)

        let receivedDateInMiliSeconds = new Date(receivedDate).getTime()

        // Add TimeZone Difference In Update Date
        let generatedDate = receivedDateInMiliSeconds + this.state.timeDifferenceInMiliSeconds
        return Math.ceil(generatedDate / 1000)
    }
    // Check wether device is mobile by window width
    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth })
    };

    // AVAILABILITY CHANGE FILTER
    dateChange = async (e) => {
        let createdDate = await this.calculateDate(e)
        var filterArray = this.state.filter
        filterArray['availability'] = createdDate
        if (this._isMounted)
            this.setState({ filter: filterArray }, () => this.searchDoctors())
    }
    // SET SEARCH TYPE FOR APPOINTMENT
    setSearchTypeForHealthCard = (type) => {
        this.props.calculateStepNo(false, type)
    }
    setSearchType = (type) => {
        if (this.state.statesList.length === 0) {
            this.updateSearchesList()
        }
        // GET FILTERS LIST
        let filters = this.state.filter
        // CHECK SEARCH ONLINE DOCTORS OR ALL
        if (type === 'onDemand')
            filters['online'] = true
        else
            filters['online'] = false
        // UPDATE FILTER LIST AND SEARCH TYPE AND CALL SEARCH DOCTOR TO GET DOCTORS LIST
        if (this._isMounted) {
            if (type === 'rhynogo')
                this.setState({ searchType: type, showAddressPopUp: true, showAppointmentPopUp: false })
            else if (type === '')
                this.setState({ searchType: "", showAppointmentPopUp: true, postCodeConfirmed: true })
            else
                this.setState({ searchType: type, filter: filters }, () => this.searchDoctors())
        }

    }

    // CHECK CONFIRMED POSTCODE
    checkConfirmPostcode = (phoneNumber = '', email = '') => {

        if ((this.state.confirmPermanentAddress && this.props.account.data.permanentPostCodeConfirmed) ||
            (!this.state.confirmPermanentAddress && this.props.account.data.temporaryPostCodeConfirmed)) {
            let userData = this.props.account.data
            /* FOR TASK RHYN-102
            TASK DESCRIPTION: Add patient tab and subaccounts for age <16
            CHECK IS REDUX SUBACCOUNT STATE HAS KEY subAccountId THEN GET ALL DATA RELATED TO SUBACCOUNT */
            let userId = this.props.subAccount.subAccountId ? this.props.subAccount.subAccountId : this.props.auth.uid
            // let userId = this.props.auth.uid
            let userSelectedZipCode = this.props.account.data.zip
            let rhynoGoAddress = this.state.permanentAddress
            if (!this.state.confirmPermanentAddress) {
                rhynoGoAddress = this.props.account.data.temporary_street + " "
                    + this.props.account.data.temporary_city + " "
                    + this.props.account.data.temporary_state + ", "
                    + this.props.account.data.temporary_zip + " "
                    + this.props.account.data.temporary_country
                userSelectedZipCode = this.props.account.data.temporary_zip
            }

            this.props.updateStepNo(4, {
                isRhynoGoAppointment: true,
                rhynoGoAddress: rhynoGoAddress,
                isRhynoGoUser: userData.RhynoGo,
                doctorId: '',
                doctorName: '',
                appointmentType: this.state.searchType,
                doctorSpecialty: '',
                doctorState: '', patientState: userData.state,
                previousStep: 1, userId: userId,
                userName: userData.fullName,
                holdamount: 0,
                appointmentduration: 15,
                userSelectedZipCode: userSelectedZipCode,
                requisitionPhoneNumber: phoneNumber,
                requisitionEmail: email
            })
        }
        else
            this.setState({ postCodeConfirmed: false, showAddressPopUp: false })
    }

    // EXPAND UNEXPAND FILTER
    expanded = () => {
        if (this._isMounted)
            this.setState({ expanded: !this.state.expanded })
    }
    // SEARCH DOCTORS
    searchDoctors = async () => {
        // GET FILTERS
        var filters = this.state.filter
        // SET LOADER
        if (this._isMounted)
            this.setState({ loading: true })
        // SET FILTERING PARAMS
        let sarchParams = {
            availability: filters.availability,
            doctorId: filters.doctorId,
            language: filters.language === 'any' ? '' : filters.language,
            location: filters.location === 'any' ? '' : filters.location,
            online: filters.online,
            page: filters.page,
            speciality: filters.speciality === 'any' ? '' : filters.speciality
        }
        // CALL METHOD TO GET DOCTORS
        let data = await this.props.getSearchedDoctors(sarchParams)


        // IF THERE ARE NO ONLINE DOCTORS SHOW NO DOCTORS POPUP
        if (this.props.appointmentData.hasOwnProperty('isRhynoGoAppointment') &&
            this.props.appointmentData.isRhynoGoAppointment &&
            filters['online'] &&
            data.data.length === 0) {
            this.setSearchType('schedule')
        }
        else {
            if (filters['online'] && data.data.length === 0) {
                if (this._isMounted) {
                    this.setState({ showNoOnDemandProvidersPopUp: true, searchType: '', showAppointmentPopUp: false })
                }
            }
            else {
                // SHOW PAGINATION AND RESULTS
                let pageNo = filters['page']
                let paginationVal = ''
                let showMessage = false
                if (data.data.length !== 0)
                    paginationVal = (pageNo * SEARCHRECORDSLIMIT) + ' to ' + ((pageNo * SEARCHRECORDSLIMIT) + (data.data.length)) + ' of ' + data.totalRecords
                else {
                    paginationVal = data.totalRecords + ' to ' + data.totalRecords + ' of ' + data.totalRecords
                    showMessage = true
                }
                // UPDATE LOCAL STATE
                if (this._isMounted)
                    this.setState({ showEmptyMessage: showMessage, pagination: paginationVal, showNoOnDemandProvidersPopUp: false, showAppointmentPopUp: false })
                // UPDATE REDUX STATE
                this.props.updateSearchedDoctors(data)
            }
            if (this._isMounted)
                this.setState({ loading: false })
        }
    }

    // GET RECORDS FOR PAGINATION
    getMore = (type) => {
        var filters = this.state.filter
        let pageNo = parseInt(filters['page'])
        if (type === 'next')
            pageNo++
        else if (type === 'start')
            pageNo = 0
        else
            pageNo--
        if (pageNo < 0)
            pageNo = 0

        filters['page'] = pageNo
        // SET STATE AND CALL METHOD TO SEARCH DOCTORS
        if (this._isMounted)
            this.setState({ filter: filters }, () => this.searchDoctors())
    }

    // GET SEARCH PARAMS LIST
    updateSearchesList = async () => {
        // GET FILTER DATA FROM API
        let filtersData = await this.props.getSearchedFiltersData()
        let SpecialitiesList = [], StatesList = [], languages = []
        if (filtersData.data.specialties) {
            SpecialitiesList = filtersData.data.specialties.map((availableSpeciality, key) => {
                if (this.props.appointmentData.appointmentType !== 'rhynogo' || (this.props.appointmentData.allowedSpecialities && this.props.appointmentData.allowedSpecialities.indexOf(availableSpeciality) > -1))
                    return (<MenuItem key={key} value={availableSpeciality}>{availableSpeciality}</MenuItem>)
                else
                    return ''
            }
            )
        }
        if (filtersData.data.locations) {
            StatesList = filtersData.data.locations.map((availablestate, key) =>
                <MenuItem key={key + "a"} value={availablestate}>{availablestate}</MenuItem>
            )
        }
        if (filtersData.data.languages) {
            languages = filtersData.data.languages.map((availablelanguages, key) =>
                <MenuItem key={key + "a"} value={availablelanguages}>{availablelanguages}</MenuItem>
            )
        }
        if (this._isMounted)
            this.setState({ specialtiesList: SpecialitiesList, statesList: StatesList, languagesList: languages })
    }

    nameChange = (e, val) => {
        var filterArray = this.state.filter
        if (val !== null) {
            filterArray['doctorName'] = val.name
            filterArray['doctorId'] = val.id
        }
        else {
            filterArray['doctorName'] = ''
            filterArray['doctorId'] = ''
        }
        if (this._isMounted)
            this.setState({ filter: filterArray, showOptionsList: false }, () => this.searchDoctors())
    }
    nameChanged = async (e, val) => {
        var filterArray = this.state.filter
        if (val !== null) {
            filterArray['doctorName'] = val
        }
        else {
            filterArray['doctorName'] = ''
        }
        if (val.length > 2) {
            let doctorList = await this.props.getDoctorsListForAppointment(val)
            if (this._isMounted)
                this.setState({ showOptionsList: true, doctorList: doctorList })
        }
        else {
            if (this._isMounted)
                this.setState({ showOptionsList: false, doctorList: [] })
        }
        if (this._isMounted)
            this.setState({ filter: filterArray })
    }

    // FILTER CHANGE
    change = (e) => {
        var filterArray = this.state.filter
        var key = e.target.name
        filterArray[key] = e.target.value
        if (this._isMounted)
            this.setState({ filter: filterArray }, () => this.searchDoctors())
    }
    render() {
        let { classes } = this.props

        const isMobile = this.state.width <= 600;
        return (
            <Grid className={classes.font}>
                <BookAppointmentType
                    className="modal"
                    title={
                        <Grid>
                            <Avatar className={classes.avatar}>
                                <LocalHospitalRoundedIcon />
                            </Avatar>
                            <Typography className={classes.header}>
                                Book an appointment
                        </Typography>
                        </Grid>
                    }
                    closable={false}
                    visible={this.state.showAppointmentPopUp}
                    width={700}
                    zIndex={"2000"}
                    footer={<></>}

                >
                    {!this.state.hasProviderAppointment ?
                        <Grid className={classes.bookingOptions}>
                            <Grid
                                onClick={() => {
                                    this.setState({ loading: true })
                                    this.setSearchType('rhynogo')
                                }

                                }
                                className={classes.rhynoGoService}>
                                {!(this.props.appointmentData.hasOwnProperty('hideRhynoGoOption') && this.props.appointmentData.hideRhynoGoOption) ?
                                    <div>
                                        {this.state.loading && this.state.searchType === 'rhynogo' ?
                                            <CircularProgress size={24} />
                                            :
                                            <Typography
                                                className={classes.bookingText}
                                            >
                                                At home testing service
                                            </Typography>
                                        }
                                    </div>
                                    : null
                                }
                            </Grid>
                            <Grid
                                onClick={() => {
                                    this.setState({ loading: true, hasProviderAppointment: true })
                                }
                                }
                                style={{ paddingLeft: '0.6rem' }} className={classes.seeProviderNo}>
                                {this.state.loading && this.state.searchType === 'onDemand' ?
                                    <CircularProgress size={24} />
                                    :
                                    <Typography
                                        className={classes.bookingText}>
                                        See a provider virtually
                                </Typography>
                                }
                            </Grid>
                        </Grid>
                        :
                        <Grid className={classes.bookingOptions}>
                            <Grid
                                onClick={() => {
                                    this.setState({ loading: true })
                                    this.setSearchTypeForHealthCard('onDemand')
                                }
                                }
                                style={{ paddingLeft: '0.6rem' }} className={classes.seeProviderNo}>
                                {this.state.loading && this.state.searchType === 'onDemand' ?
                                    <CircularProgress size={24} />
                                    :
                                    <Typography
                                        className={classes.bookingText}>
                                        As soon as possible
                                </Typography>
                                }
                            </Grid>
                            <Grid
                                onClick={() => {
                                    this.setState({ loading: true })
                                    this.setSearchTypeForHealthCard('schedule')
                                }
                                }
                                className={classes.futureAppointment}>
                                {this.state.loading && this.state.searchType === 'schedule' ?
                                    <CircularProgress size={24} />
                                    :
                                    <Typography
                                        className={classes.bookingText}>
                                        Choose a date/time
                                </Typography>
                                }
                            </Grid>
                        </Grid>
                    }
                </BookAppointmentType>


                {this.state.showAppointmentPopUp ? null
                    :
                    this.state.showNoOnDemandProvidersPopUp ?
                        <Grid className={classes.popup}>
                            <Grid className={classes.popupDiv} style={{ width: '23rem' }}>
                                <Avatar className={classes.avatar}>
                                    <LocalHospitalRoundedIcon />
                                </Avatar>
                                <p className={classes.popupP}>Dear user, Rhynocare providers are super popular today and are currently over subscribed. At this time further ondemand services can not be supported. Please use the scheduler to select a convenient appointment with a provider, thanks!</p>
                                <Button variant="contained" color="secondary" onClick={() => this.setSearchType('schedule')} fullWidth className={classes.popupButton}>
                                    {this.state.loading && this.state.searchType === 'schedule' ?
                                        <CircularProgress size={24} />
                                        :
                                        "CONTINUE TO SCHEDULE"
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                        :
                        this.state.showAddressPopUp ?
                            <Grid className={classes.popup}>
                                <Grid className={classes.popupDiv} style={isMobile ? { marginTop: '17%', width: '23rem' } : { width: '23rem' }}>
                                    <Avatar className={classes.addressPopUpAvatar}>
                                        <LocalHospitalRoundedIcon />
                                    </Avatar>
                                    {this.state.confirmPermanentAddress ?
                                        <>
                                            <p className={classes.popupP}>Your home address is "{this.state.permanentAddress}". Would you like your RhynoGo service here?</p>
                                            <Button variant="contained"
                                                color="primary"
                                                onClick={() => this.checkConfirmPostcode()}
                                                fullWidth className={classes.popupButton}>
                                                Yes and confirm
                                            </Button>
                                            <Button variant="contained" color="primary"
                                                onClick={() => {
                                                    this.setState({ confirmPermanentAddress: false })
                                                }
                                                }
                                                fullWidth
                                                className={classes.popupButton}>
                                                No
                                            </Button>
                                            <Button variant="contained" style={{ color: 'rgb(110,127,127)' }}
                                                onClick={() => {
                                                    this.setSearchType('')
                                                    this.setState({ confirmPermanentAddress: false })
                                                    this.setState({ showAddressPopUp: false })
                                                }
                                                }
                                                fullWidth
                                                className={classes.popupButton} >
                                                {this.state.loading && this.state.searchType === '' ?
                                                    <CircularProgress size={24} />
                                                    :
                                                    "Back"
                                                }
                                            </Button>
                                        </>
                                        :
                                        <>
                                            <p className={classes.popupP}>Please {this.props.appointmentData.hasOwnProperty('isAgency') && this.props.appointmentData.isAgency ? 'enter the' : 'confirm your'} address.</p>
                                            <ConfirmAddress checkConfirmPostcode={this.checkConfirmPostcode} />
                                            {/* show confirm address */}
                                            {!(this.props.appointmentData.hasOwnProperty('isAgency') && this.props.appointmentData.isAgency) &&
                                                <Button
                                                    variant="contained"
                                                    onClick={() => {
                                                        // this.setSearchType('')
                                                        this.setState({ confirmPermanentAddress: true })
                                                    }
                                                    }
                                                    fullWidth
                                                    className={classes.buttonBack}
                                                    style={isMobile ? { width: '90%', color: 'rgb(110,127,127)' } : { color: 'rgb(110,127,127)' }}
                                                >
                                                    {this.state.loading && this.state.searchType === '' ?
                                                        <CircularProgress size={24} />
                                                        :
                                                        "Back"
                                                    }
                                                </Button>
                                            }
                                        </>
                                    }
                                </Grid>
                            </Grid>
                            :
                            !this.state.postCodeConfirmed ?
                                <Grid className={classes.popup}>
                                    <Grid className={classes.popupDiv} style={{ width: '23rem' }}>
                                        <Avatar className={classes.avatar}>
                                            <LocalHospitalRoundedIcon />
                                        </Avatar>
                                        <p className={classes.popupP}>Unfortunately, RHYNO GO is not yet available in your region.
                                        Please send an email with your request to
                                        <br></br>
                                            <span style={{ color: 'rgb(110,127,127)' }}>rhynogo@rhynocare.com </span>
                                            <br></br>
                                        for future availabilities.</p>
                                        <Button variant="contained"
                                            onClick={() => {
                                                if (this.props.appointmentData.hasOwnProperty('showProviderAppointmentTypes') && this.props.appointmentData.showProviderAppointmentTypes) {
                                                    this.setState({ loading: true, searchType: this.props.appointmentData.appointmentType })
                                                    this.setSearchType(this.props.appointmentData.appointmentType)
                                                }
                                                else
                                                    this.setSearchType('')
                                            }
                                            }
                                            fullWidth className={classes.postCodeButton}>
                                            {this.state.loading && this.state.searchType === '' ?
                                                <CircularProgress size={24} />
                                                :
                                                "Back"
                                            }
                                        </Button>
                                    </Grid>
                                </Grid>
                                :
                                // filters!!
                                <Grid>
                                    <Grid className={classes.filters}>
                                        {this.state.searchType === 'schedule' ?
                                            <Grid className={classes.filterlist + ' ' + classes.dateFilter}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        autoOk
                                                        variant="inline"
                                                        id="date"
                                                        name="date"
                                                        onChange={(e) => this.dateChange(e)}
                                                        // format="MM/dd/yyyy"
                                                        // placeholder="mm/dd/yyyy"
                                                        placeholder={"dd/mm/yyyy"}
                                                        format="dd/MM/yyyy"
                                                        label="Date"
                                                        value={!this.state.filter.availability ? null : moment((this.state.filter.availability * 1000) - this.state.timeDifferenceInMiliSeconds).format('YYYY/MM/DD')}
                                                        className={classes.textField}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            :
                                            ""
                                        }

                                        <Grid className={classes.NameField + " doctorSearch"}>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                value={{ name: this.state.filter.doctorName, id: this.state.filter.doctorId }}
                                                options={this.state.doctorList}
                                                getOptionLabel={(option) => option.name}
                                                freeSolo
                                                onChange={this.nameChange}
                                                onInputChange={this.nameChanged}
                                                className={classes.NameFieldText}
                                                open={this.state.showOptionsList}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        label="Doctor's name"
                                                        variant="outlined"
                                                    />
                                                }
                                            />
                                        </Grid>

                                        <Grid className={classes.filterlist}>
                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="specialityLabel" className={classes.selectLabel}>
                                                    Speciality
                                                </InputLabel>
                                                <Select
                                                    labelId='specialityLabel'
                                                    name="speciality"
                                                    style={{ color: 'rgb(110,127,127)' }}
                                                    value={this.state.filter.speciality}
                                                    onChange={this.change}
                                                    label={"Speciality"}
                                                    autoWidth
                                                    variant={"outlined"}
                                                    className={classes.selectEmpty}
                                                >
                                                    <MenuItem key={0} value="any">Any</MenuItem>
                                                    {this.state.specialtiesList}
                                                </Select>
                                            </FormControl>
                                        </Grid>


                                        <Grid className={classes.filterlist}>
                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="languageLabel" className={classes.selectLabel}>
                                                    Language
                                                </InputLabel>
                                                <Select
                                                    labelId='languageLabel'
                                                    name="language"
                                                    style={{ color: 'rgb(110,127,127)' }}
                                                    value={this.state.filter.language}
                                                    onChange={this.change}
                                                    label={"Language"}
                                                    autoWidth
                                                    variant={"outlined"}
                                                    className={classes.selectEmpty}
                                                >
                                                    <MenuItem key={0} value="any">Any</MenuItem>
                                                    {this.state.languagesList}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid className={classes.filterlist}>
                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="locationLabel" className={classes.selectLabel}>
                                                    Location
                                            </InputLabel>
                                                <Select
                                                    labelId='locationLabel'
                                                    name="location"
                                                    style={{ color: 'rgb(110,127,127)' }}
                                                    value={this.state.filter.location}
                                                    onChange={this.change}
                                                    label={"Location"}
                                                    autoWidth
                                                    variant={"outlined"}
                                                    className={classes.selectEmpty}
                                                >
                                                    <MenuItem value="any">Any</MenuItem>
                                                    {this.state.statesList}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid className={classes.paginationDiv}>
                                            <RefreshRoundedIcon
                                                onClick={(e) => this.getMore('start')} className={classes.icon} />
                                            {/* <Divider className={classes.divider} orientation="vertical" flexItem /> */}
                                            {/* <p className={classes.pagination}>{this.state.pagination}</p> */}
                                            <NavigateBeforeIcon
                                                onClick={(e) => this.getMore('previous')} className={classes.icon} />
                                            <NavigateNextIcon
                                                onClick={(e) => this.getMore('next')}
                                                className={classes.icon} />
                                            {/* <Divider className={classes.divider} orientation="vertical" flexItem /> */}
                                        </Grid>
                                    </Grid>
                                    {this.state.loading ?
                                        <CircularProgress size={24} />
                                        :
                                        this.state.showEmptyMessage ?
                                            <p>No Results Found</p>
                                            :
                                            <SearchResults updateStepNo={this.props.updateStepNo} searchType={this.state.searchType} />
                                    }
                                </Grid>
                }
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    /* FOR TASK RHYN-102
    TASK DESCRIPTION: Add patient tab and subaccounts for age <16
    GET THE SUBACCOUNT STATE TO GET DATA ACCORDING TO SUBACCOUNT IS SUBACCOUNT DATA EXIST IN REDUX */
    return {
        auth: state.auth,
        account: state.account,
        subAccount: state.subAccount
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateSearchedDoctors: (data) => dispatch(data),
        getSearchedDoctors: (data) => getSearchedDoctors(data),
        getSearchedFiltersData: () => getSearchedFiltersData(),
        getDoctorsListForAppointment: (val) => getDoctorsListForAppointment(val)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Filters));




