import React, { Component } from 'react'
import { Grid, Button } from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles"
import { Modal } from 'antd'

const styles = theme => ({
    font: {
        fontFamily: 'Roboto Regular',
    },
    popup: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    popupDiv: {
        backgroundColor: '#fff',
        padding: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: { padding: theme.spacing(2) }
    },
    popupHeading: {
        fontFamily: 'inherit',
        fontWeight: 700,
        color: '#383636',
        fontSize: '1.2rem'
    },
    popupButton: {
        marginTop: theme.spacing(2),
        fontFamily: 'inherit',
        fontSize: '1rem',
    },
    textTransform: {
        textTransform: 'unset'
    },
    modelText: {
        fontFamily: 'inherit',
        fontWeight: 600,
        color: '#3b3c3c',
        fontSize: '1rem'
    }
})


class OptionsList extends Component {
    constructor(props) {
        super(props)
        if (props.appointmentData.patientState !== props.appointmentData.doctorState || props.appointmentData.showList === 0) {
            this.props.updateStepNo(4, { referralOption: 0, previousStep: props.appointmentData.previousStep })
        }
        this.state = {
            showModal: false
        }
        this._isMounted = false;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true
    }
    nextStep = (referralOption) => {
        let stepNo = 4
        if (referralOption === 0) {
            stepNo = this.props.appointmentData.previousStep
        }
        let specialty = ''
        if (referralOption === 3) {
            specialty = "Family Medicine"
            stepNo = 1
        }
        this.props.updateStepNo(stepNo, { referralOption, previousStep: 3, speciality: specialty })
    }
    showModal = () => {
        if (this._isMounted)
            this.setState({ showModal: true })
    }
    onCancel = () => {
        if (this._isMounted)
            this.setState({ showModal: false })
    }
    render() {
        let { classes } = this.props
        return (
            <Grid className={classes.font}>
                <Grid className={classes.popup}>
                    <Grid className={classes.popupDiv} style={{ width: '30rem' }}>
                        <span className={classes.popupHeading}>Provincial healthcare providers require insured patients to be seen by specialist with referral. Do you have a referral?</span>
                        <Button variant="outlined" onClick={() => this.nextStep(1)} fullWidth className={classes.popupButton + ' ' + classes.textTransform}>
                            I have been referred by a RhynoCare provider
                </Button>
                        <Button variant="outlined" onClick={() => this.nextStep(2)} fullWidth className={classes.popupButton + ' ' + classes.textTransform}>
                            I will upload or have uploaded a referral
                </Button>
                        <Button variant="outlined" onClick={() => this.showModal(3)} fullWidth className={classes.popupButton + ' ' + classes.textTransform}>
                            I would like to request a referral
                </Button>
                        <Button variant="outlined" onClick={() => this.nextStep(4)} fullWidth className={classes.popupButton + ' ' + classes.textTransform}>
                            I would rather pay for the visit using my card
                </Button>
                        <Button variant="contained" onClick={() => this.nextStep(0)} color={'primary'} style={{ color: "#fff", padding: '0px 50px' }} className={classes.popupButton}>
                            Back
                </Button>
                    </Grid>
                </Grid>
                {this.state.showModal ?
                    <Modal
                        title={"Appointment Booking"}
                        visible={true}
                        width={400}
                        onCancel={this.onCancel}
                        footer={
                            <Grid>
                                <Button color="primary" variant="contained" onClick={() => { this.nextStep(3) }}>OK</Button>
                            </Grid>
                        }
                    >
                        <Grid className={classes.modal}>
                            <span className={classes.modelText}>Please book an appointment with one of our family doctors first in order to request a referral to a specialist.</span>
                        </Grid>
                    </Modal>
                    :
                    ""
                }
            </Grid>
        );
    }
}
export default withStyles(styles)(OptionsList)