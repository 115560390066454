import React, { Component } from 'react'
import withStyles from '@material-ui/styles/withStyles'
import { Grid, Button, CircularProgress, TextField } from '@material-ui/core'
import functionsList from '../../../../assets/js/function'
import { viewMoreStyle } from '../../style'

const styles = theme => ({
    space: {
        margin: theme.spacing(1, 0) + " !important"
    }
})

class RequisitionForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            type: '',
            uploadImage: false,
            askForNotification: false,
            uploadedFileObject: {},
            form: {

            },
        }
    }

    onChange = (e) => {
        // GET VALUES ARRAY
        let valuesArray = this.state.form
        this.props.updateRequisitionData(valuesArray)
    }

    setType = (type) => {
        this.setState({ type })
        document.getElementById('file').click();
    }

    // WHEN IMAGE SELECTED
    onImageChangeHandler = async (e) => {
        this.setState({ uploadImage: true })
        var result = await functionsList.saveImage(e, 'requisitions', this.state.type)
        result = JSON.parse(result)
        let UploaderObejct = {
            noteType: this.state.type,
            link: result.path,
            name: result.name,
        }
        if (this.state.type === 'Uploads' || this.state.type === 'Covid19TravelResult') {
            this.setState({ uploadedFileObject: UploaderObejct, askForNotification: true, uploadImage: false })
        }
        else {
            this.props.saveRequisition(UploaderObejct)
        }
    }

    sendResult = async (val) => {
        this.setState({ askForNotification: false })
        let fileUploaderObject = this.state.uploadedFileObject
        fileUploaderObject.sendNotification = val
        this.props.saveRequisition(fileUploaderObject)

    }

    render() {
        const { classes } = this.props;
        return (
            <Grid>
                {this.state.askForNotification ?
                    <Grid>
                        <h2>Would you like to send a notification?</h2>
                        <Grid style={buttonStyle}>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={'btnCustomClass lightGreyClasstbn'}
                                style={viewMoreStyle}
                                onClick={() => this.sendResult(0)}>No</Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={'btnCustomClass lightBlueClasstbn'}
                                style={viewMoreStyle}
                                onClick={() => this.sendResult(1)}>Yes</Button>
                        </Grid>
                    </Grid>
                    :
                    <Grid>
                        <Button className={classes.space} fullWidth variant="outlined" onClick={() => this.setType('Uploads')}>
                            {(this.state.uploadImage || this.props.loader) && this.state.type === 'Uploads' ?
                                <CircularProgress size={25} />
                                :
                                "Result"
                            }
                        </Button>
                        <Button className={classes.space} fullWidth variant="outlined" onClick={() => this.setType('Covid19Form')}>
                            {(this.state.uploadImage || this.props.loader) && this.state.type === 'Covid19Form' ?
                                <CircularProgress size={25} />
                                :
                                "Covid 19 Form"
                            }
                        </Button>
                        <Button className={classes.space} fullWidth variant="outlined" onClick={() => this.setType('Covid19TravelResult')}>
                            {(this.state.uploadImage || this.props.loader) && this.state.type === 'Covid19TravelResult' ?
                                <CircularProgress size={25} />
                                :
                                "COVID-19 Travel Results"
                            }
                        </Button>
                    </Grid>
                }
                <TextField
                    name="file"
                    type="file"
                    id="file"
                    style={{ display: 'none' }}
                    onChange={this.onImageChangeHandler}
                />
            </Grid>
        );
    }
}
const buttonStyle = {
    display: 'flex',
    justifyContent: 'space-between'
}
export default withStyles(styles)(RequisitionForm)