import React, { useState, useEffect } from 'react'
import { Grid, Typography, CircularProgress, Fab } from '@material-ui/core'
import { progress, sectionDetail, item, mainStyle, fabStyle } from './style'
import { getAllNotes } from './API/API'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import moment from 'moment'
import ViewMore from './ViewMore'
import SectionModal from './SectionModal'
import AddIcon from "@material-ui/icons/Add"

function NotesReqs({ isRhynoGoPatient, patientName, type, patientId, doctorId, appointmentId, ...props }) {

    const [notes, setNotes] = useState([])
    const [pagination, setPagination] = useState('')
    const [loader, setLoader] = useState(true)
    const [openModel, setOpenModel] = useState(false)

    const getAllUserNotes = async () => {
        setLoader(true)
        let result = await getAllNotes(patientId, doctorId, pagination, isRhynoGoPatient, patientName)
        setNotes(notes.concat(result.data))
        setPagination(result.pagination_id)
        setLoader(false)
    }

    useEffect(() => {
        getAllUserNotes()
        if (appointmentId) {
            setOpenModel(true)
        }
    }, [appointmentId])

    const openCloseModel = (e, isSuccess = false, data, pagination_id, isChild = false) => {
        console.log('closed')
        if (isSuccess) {
            setNotes(data)
            setPagination(pagination_id)
        }
        if (!isChild) {
            setOpenModel(!openModel)
            if (appointmentId) {
                props.closeDocumentSection()
            }
        }
    }

    return (
        <Grid style={mainStyle}>
            {appointmentId === '' &&
                <>
                    <Grid style={sectionDetail}>
                        {notes.map((note, key) =>
                            <Typography key={key} align={"left"} style={item}>
                                {decodeURI(note.name)}
                                {' on '}
                                {moment(note.created_at * 1000).format('MMMM D, YYYY H:mm:ss a')}
                                {note.url && note.url !== '' ? <a href={note.url} target="_blank" rel="noopener noreferrer"><PictureAsPdfIcon color="primary" /></a> : ""}
                            </Typography>
                        )
                        }
                        {loader && <CircularProgress style={progress} size={24} />}
                        {pagination !== -1 && <ViewMore loader={loader} getMoreRecords={getAllUserNotes} />}
                    </Grid >
                    <Fab color="primary" style={fabStyle}>
                        <AddIcon onClick={openCloseModel} />
                    </Fab>
                </>
            }
            {openModel && <SectionModal patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} type={type} onCloseModel={openCloseModel} data={{ patientId, doctorId, pagination, appointmentId }} />}
        </Grid >
    )
}

export default NotesReqs