import React, { Component } from 'react'
import withStyles from "@material-ui/core/styles/withStyles"
import { Grid, Button, TextField } from '@material-ui/core'

const styles = theme => ({
    popup: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column'
    },
    popupDiv: {
        backgroundColor: '#fff',
        padding: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '35rem',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            width: '100%'
        }
    },
    popupButton: {
        margin: 'auto',
        width: '30%',
        marginBottom: theme.spacing(3),
        fontFamily: 'inherit',
        color: '#ffffff'
    },
    popupButton1: {
        marginBottom: theme.spacing(3),
        fontFamily: 'inherit',
        color: '#ffffff'
    },
    popupHeading: {
        fontFamily: 'inherit',
        fontWeight: 700,
        paddingBottom: theme.spacing(6),
        color: '#795bb2',
        fontSize: theme.spacing(2.4)
    },
    font: {
        fontFamily: 'revert',
    },
    mainDiv: {
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    mainDiv1: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    formButton: {
        display: 'flex',
        justifyContent: 'space-between'
    }
})
class someOneElse extends Component {

    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = {
            guardian_type: '',
            isFormValid: true,
            full_name: props.registerData.hasOwnProperty('guardian_full_name') ? props.registerData.guardian_full_name : '',
            relationship: props.registerData.hasOwnProperty('guardian_relationship') ? props.registerData.guardian_relationship : ''
        }
        if (this.props.registerData.accountType === 'own') {
            this.updateFormData(3)
        }
    }

    componentDidMount() {
        this._isMounted = true
    }

    updateGuardianType = (type) => {
        this.setState({ guardian_type: type })
    }

    updateFormData = (step) => {
        this.props.updateStepNo(step, { isLegalGuardian: this.state.guardian_type, guardian_full_name: this.state.full_name, guardian_relationship: this.state.relationship })
    }
    // FORM DATA UPDATED
    change = (e, text) => {
        if (this._isMounted) {
            if (text === 'full_name') {
                this.setState({ isFormValid: true, full_name: e.target.value })
            }
            else {
                this.setState({ isFormValid: true, relationship: e.target.value })
            }
        }
    }
    validateForm = () => {
        if (this.state.full_name !== '' && this.state.relationship !== '')
            this.updateFormData(3)
        else
            this.setState({ isFormValid: false })
    }
    render() {
        let { classes } = this.props

        return (
            <Grid className={classes.font}>
                <Grid className={classes.popup}>
                    <Grid className={classes.popupDiv}>
                        {this.state.guardian_type === '' ?
                            <Grid className={classes.mainDiv}>
                                <span className={classes.popupHeading}>Are you a parent or legal guardian?</span>
                                <Button variant="contained" onClick={() => this.updateGuardianType('yes')} color={'secondary'} className={classes.popupButton}>
                                    Yes
                                </Button>
                                <Button variant="contained" onClick={() => this.updateGuardianType('no')} color={'secondary'} className={classes.popupButton}>
                                    No
                                </Button>
                            </Grid>
                            :
                            this.state.guardian_type === 'yes' ?
                                <Grid className={classes.mainDiv}>
                                    <span className={classes.popupHeading}>Please enter your full name and relation with the patient</span>

                                    <TextField
                                        value={this.state.full_name}
                                        onChange={(e) => this.change(e, 'full_name')}
                                        onBlur={(e) => this.change(e, 'full_name')}
                                        label="Full Name"
                                        style={{ marginBottom: '24px' }}
                                        error={this.state.full_name === '' && !this.state.isFormValid ? true : false}
                                        helperText={this.state.full_name === '' && !this.state.isFormValid ? "Full Name is required" : ""}
                                        autoFocus
                                    />

                                    <TextField
                                        value={this.state.relationship}
                                        onChange={(e) => this.change(e, 'relationship')}
                                        onBlur={(e) => this.change(e, 'relationship')}
                                        label="Relationship"
                                        style={{ marginBottom: '24px' }}
                                        error={this.state.relationship === '' && !this.state.isFormValid ? true : false}
                                        helperText={this.state.relationship === '' && !this.state.isFormValid ? "Relationship is required" : ""}
                                    />

                                    <Grid className={classes.formButton}>
                                        <Button variant="contained" onClick={() => this.updateFormData(1)} color={'primary'} className={classes.popupButton1}>
                                            Back
                                    </Button>
                                        <Button variant="contained" onClick={() => this.validateForm()} color={'secondary'} className={classes.popupButton1}>
                                            Next
                                    </Button>
                                    </Grid>
                                </Grid>
                                :
                                <Grid className={classes.mainDiv1}>
                                    <span className={classes.popupHeading}>As per our terms, you can not register an account for someone else unless you are a parent or legal guardian of that patient. Please request the patient, parent or legal guardian to complete the signup process.</span>
                                    <Button variant="contained" onClick={() => this.updateFormData(1)} color={'primary'} className={classes.popupButton}>
                                        Back
                                </Button>
                                </Grid>
                        }
                    </Grid>

                </Grid>
            </Grid>
        )
    }
}
export default withStyles(styles)(someOneElse);
