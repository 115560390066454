import moment from "moment";

//helper functions 

const updateAppointmentsList = async (userId, getAppointments, zip = '') => {
  let result = await getAppointments({ userId: userId })
  return updateResults(result.data, zip)
}
/**
 * 
 * @param {*} result data from updateAppointmentsList function or other
 * @returns {Array} 2 arrays changedRequests && upcommingRequests
 */
const updateResults = (result, zip = '') => {
  // console.log(result)
  let changedRequests = []
  let upcommingRequests = []

  /* SKYPE CONVERSATION
  G-Man, 10:33 AM
  Please disable and perhaps put a note there as well - 'Cancel/Change option will be available in a few minutes'
  show the note to wait for few minutes to cancel/update appointment if status is updating and disable button */
  let hasAnyUpdatingAppointment = false

  let currentTime = Math.floor(new Date().getTime() / 1000)
  for (let i = 0; i < result.length; i++) {
    let drText = 'Dr. '
    let npText = ''
    if (!result[i].showDr) {
      drText = ''
      npText = ', NP'
    }

    /* SKYPE CONVERSATION
    G-Man, 10:33 AM
    Please disable and perhaps put a note there as well - 'Cancel/Change option will be available in a few minutes'
    show the note to wait for few minutes to cancel/update appointment if status is updating and disable button */
    if (result[i].appointmentCreationStatus === 'updating' || result[i].appointmentCreationStatus === 'created') {
      hasAnyUpdatingAppointment = true
    }
    let drName = drText + result[i].doctorName + npText

    result[i].doctorName = drName

    let isTodayAppointment = false

    if (result[i].appointmentType === 'rhynogo') {
      let currentDateTime = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }))

      let isConsideredAsCurrentDay = false
      if (currentDateTime.getHours() > 22 || (currentDateTime.getHours() === 22 && currentDateTime.getMinutes() > 30)) {
        isConsideredAsCurrentDay = true
      }
      let currentYMD = (currentDateTime.getFullYear()) + '' + (currentDateTime.getMonth()) + '' + (currentDateTime.getDate())

      currentDateTime.setDate(currentDateTime.getDate() + 1)
      let tomorrowYMD = (currentDateTime.getFullYear()) + '' + (currentDateTime.getMonth()) + '' + (currentDateTime.getDate())

      let appointmentTime = new Date(new Date(result[i].appointmentFor * 1000).toLocaleString('en-US', { timeZone: 'America/New_York' }))
      let appointmentYMD = (appointmentTime.getFullYear()) + '' + (appointmentTime.getMonth()) + '' + (appointmentTime.getDate())


      if (appointmentYMD === currentYMD || (isConsideredAsCurrentDay && appointmentYMD === tomorrowYMD))
        isTodayAppointment = true
    }

    if (result[i].status === 'changed') {
      let changeText = ''
      if (result[i].prevTime !== 0)
        changeText = drName + " requests change of appointment from " + moment(result[i].prevTime * 1000).format('MMM D, YYYY [at] h:mm a') + " to " + moment(result[i].appointmentFor * 1000).format('MMM D, YYYY [at] h:mm a')
      else
        changeText = drName + " added an appintment at " + moment(result[i].appointmentFor * 1000).format('MMM D, YYYY [at] h:mm a')
      changedRequests.push({
        text: changeText,
        addedBy: result[i].addedBy,
        holdamount: result[i].holdamount,
        id: result[i].id,
        doctorId: result[i].doctorId,
        doctorName: result[i].doctorName,
        appointmentType: result[i].appointmentType,
        start: result[i].appointmentFor,
        duration: result[i].duration,
        status: result[i].status,
        userSelectedZipCode: result[i].userSelectedZipCode === '' ? zip : result[i].userSelectedZipCode,
        isUrgentPCR: result[i].isUrgentPCR,
        rhynoGoMembers: result[i].rhynoGoMembers,
        isTodayAppointment,
        appointmentCreationStatus: result[i].appointmentCreationStatus,
        acceptAppointmentMessage: "Would you like to accept this appointment for " + moment(result[i].appointmentFor * 1000).format('MMM D, YYYY [at] h:mm a') + " with " + drName
      })

    } else {

      let remainingSeconds = 0
      let text = ''
      let unique = false
      if (result[i].appointmentFor > 0) {
        remainingSeconds = result[i].appointmentFor - currentTime
        if (result[i].appointmentType === 'rhynogo' || (result[i].hasOwnProperty('isRhynoGoAppointment') && result[i].isRhynoGoAppointment)) {
          drName = 'RhynoGo '
          if (remainingSeconds >= 300)
            drName += 'for ' + result[i].reason
        }


        text = drName + " on " + moment(result[i].appointmentFor * 1000).format('MMM D, YYYY [at] h:mm a')
        if (remainingSeconds < 300) {
          text = drName + "for " + result[i].reason
        }
      }
      else {
        unique = true
        text = 'RhynoGo for ' + result[i].reason
      }
      upcommingRequests.push({
        text: text,
        id: result[i].id,
        doctorName: result[i].doctorName,
        doctorId: result[i].doctorId,
        remainingSeconds: remainingSeconds,
        appointmentType: result[i].appointmentType,
        start: result[i].appointmentFor,
        duration: result[i].duration,
        addedBy: result[i].addedBy,
        holdamount: result[i].holdamount,
        status: result[i].status,
        urgent: unique,
        isUrgentPCR: result[i].isUrgentPCR,
        rhynoGoMembers: result[i].rhynoGoMembers,
        isTodayAppointment,
        appointmentCreationStatus: result[i].appointmentCreationStatus,
        userSelectedZipCode: result[i].userSelectedZipCode === '' ? zip : result[i].userSelectedZipCode,
      })
    }
  }
  return { changedRequests, upcommingRequests, hasAnyUpdatingAppointment }
}

/**
 * 
 * @param {String} text from promise 
 * @returns {String} with date and time
 */
const getDateAndTime = (text) => {
  const stringArray = text.split(' ');
  let time = stringArray.splice(stringArray.indexOf('on') + 1,).join(' ')
  let type = stringArray.splice(stringArray.indexOf('for') + 1,)
  type.splice(-1, 1);
  return time;
}
/**
 * 
 * @param {String} text 
 * @returns {String} with the oldDate 
 */
const getOldDateFromChangeRequest = (text) => {
  const stringArray = text.split(' ')
  const index = stringArray.indexOf('from')
  const indexToCut = stringArray.indexOf('to')
  const oldDate = stringArray.slice(index + 1, indexToCut).join(' ')
  return oldDate;
}
/**
 * 
 * @param {String} text 
 * @returns {String} with the newDate
 */
const getNewDateFromChangeRequest = (text) => {
  const stringArray = text.split(' ')
  const index = stringArray.indexOf('to')
  const newDate = stringArray.slice(index + 1,).join(' ')
  return newDate;
}
/**
 * 
 * @param {String} text 
 * @returns {String} with the doctors name
 */
const getDoctorNameFromChangeRequest = (text) => {
  const stringArray = text.split(' ');
  const index = stringArray.indexOf('requests')
  const drName = stringArray.splice(0, index).join(' ')
  return drName;
}

/**
 * 
 * @param {String} text 
 * @returns {String} doctor that made the appointment
 */
const getDoctorNameFromDoctorsMadeAppointment = (text) => {
  const stringArray = text.split(' ')
  const indexToCut = stringArray.indexOf('added')
  const drName = stringArray.slice(0, indexToCut).join(' ')
  return drName;
}
/**
 * 
 * @param {String} text 
 * @returns {String} with the date that the doctor scheduled
 */
const getDateFromDoctorsMadeAppointment = (text) => {
  const stringArray = text.split(' ');
  const indexStart = stringArray.indexOf('at')
  const dateAndTime = stringArray.splice(indexStart + 1,).join(' ')
  return dateAndTime;
}

/**
 * 
 * @param {Array} appointments 
 * @returns {Array} with items removed where status == changed
 */
const removeAppointmentsWithChangedStatus = (appointments) => {
  let i, length;
  length = appointments.length;
  for (i = 0; i < length; i++) {
    if (appointments[i].status === 'changed') {
      appointments.splice(i, 1);
      length--;
    }
  }
  return appointments
}

const getTimeFromUnixTimeStamp = (unixTimeStamp, type = "") => {
  let time = ''
  if (unixTimeStamp)
    time = moment(unixTimeStamp * 1000).format('MMM D, YYYY [at] h:mm a')
  let time1 = ''
  if (type === 'rhynogo') {
    time = ''
    time1 = moment(unixTimeStamp * 1000).tz('America/New_York').format('MMM D, YYYY [at] h:mm a') + " to " + moment(unixTimeStamp * 1000).tz('America/New_York').add(2, 'hours').format('h:mm a')
  }
  return time + time1
}

const getDoctorNameFromRhynoGoAppointments = (text) => {
  const stringArray = text.split(' ')
  let indexToCut = stringArray.indexOf('on')
  if (indexToCut < 0)
    indexToCut = stringArray.length
  const drName = stringArray.slice(0, indexToCut).join(' ')
  return drName;
}

const getMembersDetail = (members) => {
  let membersString = []
  for (let [, val] of Object.entries(members))
    membersString.push(val.first_name + ' ' + val.last_name)
  return membersString.join(', ')
}
export {
  updateAppointmentsList,
  updateResults,
  getDateAndTime,
  getOldDateFromChangeRequest,
  getDoctorNameFromChangeRequest,
  getNewDateFromChangeRequest,
  getDoctorNameFromDoctorsMadeAppointment,
  getDateFromDoctorsMadeAppointment,
  removeAppointmentsWithChangedStatus,
  getTimeFromUnixTimeStamp,
  getDoctorNameFromRhynoGoAppointments,
  getMembersDetail
}