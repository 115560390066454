import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Grid, Select, FormControl, InputLabel, MenuItem } from '@material-ui/core'
import moment from 'moment'
import RefreshIcon from '@material-ui/icons/Refresh'
import Functions from '../../../assets/js/function'

function UserDetail({ patientId, pagination, getAllUserNotes }) {

    const [searchDoctorId, serSearchDoctorId] = useState('')
    const [allDoctorList, setAllDoctorList] = useState('')

    const { userInfo } = useSelector(
        (state) => ({
            userInfo: state.emr.UserInfo
        })
    );

    const getChatHistoryAllList = async (list, doctorChanged = 0) => {
        serSearchDoctorId(list)

        let searchIdsArray = (list).split(',')
        let doctorId = searchIdsArray[0]
        if (searchIdsArray.length === 1) {
            searchIdsArray = []
        }
        let pagination_id = pagination
        if (doctorChanged) {
            pagination_id = 0
        }

        getAllUserNotes(doctorChanged, pagination_id, searchIdsArray, doctorId)

    }

    const getUsersList = async () => {
        if (patientId) {
            let result = await Functions.searchPatients({ type: 'Patient', accountId: patientId })
            let usersArray = []
            let doctorIdsList = []
            for (let i = 0; i < result.data.length; i++) {
                usersArray.push(<MenuItem key={i} value={result.data[i].id}>{result.data[i].name} - {result.data[i].speciality}</MenuItem>)
                doctorIdsList.push(result.data[i].id)
            }

            if (usersArray.length === 0)
                usersArray.push(<MenuItem key={0} value={-1}>No Doctors Found</MenuItem>)
            else
                usersArray.push(<MenuItem key={-1} value={doctorIdsList.join(',')}>All</MenuItem>)
            setAllDoctorList(usersArray)
        }
    }

    useEffect(() => {
        getUsersList()
    }, [searchDoctorId])

    return (
        <div>
            <div style={styles.parent}>
                <Grid>
                    <FormControl variant="outlined" style={styles.doctorsList}>
                        <InputLabel id="user">Select Doctor To View Notes</InputLabel>
                        <Select
                            labelId="user"
                            label="Select Doctor To View Notes"
                            name="doctorId"
                            variant="outlined"
                            required
                            fullWidth
                            value={searchDoctorId}
                            onChange={(event) => getChatHistoryAllList(event.target.value, 1)}
                        >
                            {allDoctorList}
                        </Select>
                        <RefreshIcon onClick={getUsersList} />
                    </FormControl>
                </Grid>

                {userInfo && userInfo.fullName ?
                    <>
                        <div style={styles.titleDiv}>
                            <Grid style={styles.title}>{userInfo.fullName}<span style={styles.titleSpan}>-{userInfo.phone}</span></Grid>
                            <span style={{ ...styles.title, ...styles.titleSpan }}>{userInfo.email}-{userInfo.street}, {userInfo.city}, {userInfo.zip}, {userInfo.state}, {userInfo.country}</span>
                            {userInfo.hCard && <span style={{ ...styles.title, ...styles.titleSpan }}>Health Card {userInfo.hCard} will expire on {moment(userInfo.dateofHCExp).format('MMMM D, YYYY')}</span>}
                        </div>
                        <Grid style={styles.info}>
                            {userInfo.fullName}
                            {', DOB '}
                            {moment(userInfo.dateofBirth).format('MMMM D, YYYY')}
                            {', '}
                            {userInfo.gender === 'Male' ? 'M' : 'F'}
                        </Grid>
                    </>
                    : ""
                }
            </div>
        </div >
    )
}

const styles = {
    parent: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        width: '100%',
        flexDirection: 'column',
        marginBottom: '40px',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
    },
    doctorsList: {
        display: 'flex',
        alignItems: 'center',
        margin: '2rem 3% 1rem',
        width: '94%',
        flexDirection: 'row',
        textAlign: 'left'
    },
    titleDiv: {
        backgroundColor: '#00bbbe',
        display: 'flex',
        flexDirection: 'column',
        padding: '15px'
    },
    title: {
        textAlign: 'left',
        fontWeight: 700,
        color: '#fff',
        fontSize: '20px'
    },
    titleSpan: {
        fontSize: '16px'
    },
    info: {
        textAlign: 'left',
        fontWeight: 700,
        color: '#000',
        padding: '15px',
        fontSize: '18px'
    }
}

export default UserDetail