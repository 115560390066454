import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getAccountVerification } from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { resendAccountVerification } from '../../store/actions/authActions'
import { CssBaseline, Container, Typography, Paper, Avatar, Button, Box, CircularProgress, TextField } from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { signOut } from '../../store/actions/authActions'
import { TopTitle } from '../../config.js'
import Functions from '../../assets/js/function'
import cookie from 'react-cookies'
import { TIMEEXPIRY } from '../../config.js'




function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {TopTitle}
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const signupStyles = theme => ({
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(5)}px`,
    [theme.breakpoints.down('sm')]: { padding: theme.spacing(2) }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  container: {
    maxWidth: "inherit"
  },
  message: {
    padding: theme.spacing(5, 0)
  },
  signin: {
    marginTop: theme.spacing(2)
  },
  submit: {
    marginTop: theme.spacing(2)
  },
})

class Verify extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      userId: props.auth.signedInUserId
    }

    if (props.auth.isVerified === false) {
      this.props.getVerification(props.auth.signedInUserId)
    }

    if (props.auth.hasMobileNumber === false) {
      this.setVerifyType('email')
      this.state.codeType = 'email'
    }
    else {
      this.state.codeType = ''
    }
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false })
    }, 3000)
  }
  handleChange = (e) => {
    this.setState({ code: e.target.value, error: false, disable: false })
    if (e.target.value === '') {
      this.setState({ error: true, disable: true })
    }
  }
  setVerifyType = (type) => {
    this.setState({ codeType: type })
    this.props.resendAccountVerification(this.state.userId, type)
  }
  resendEmail = () => {
    this.props.resendAccountVerification(this.state.userId, this.state.codeType)
  }

  verifyAccount = async () => {
    this.setState({ loader: true })
    let result = await Functions.verifyAccount(JSON.stringify({ userId: this.state.userId, code: this.state.code }))
    this.setState({ status: result.status, message: result.message })
    if (result.status === 1) {
      const expires = new Date()
      expires.setDate(Date.now() + TIMEEXPIRY)
      // cookie.save(uid, true, { path: '/', expires})
      cookie.save(result.data.email, true, { path: '/', expires })
      this.setState({ email: result.data.email })
      await this.props.updateProfileData(result.data)
      await this.props.getVerification(this.state.userId)
    }
  }


  render() {
    const { classes, auth } = this.props;

    if (auth.isVerified === true) {
      return <Redirect to="/dashboard" />;
    }
    if (!auth.signedInUserId) {
      return <Redirect to="/signin" />;
    }


    return (
      <Container component="main" maxWidth="xs">
        {this.state.loading ?
          <CircularProgress size={24} />
          :
          <>
            <CssBaseline />
            {auth.verificationType === 'user' ?
              <div className={classes.container}>
                {this.state.codeType === '' ?
                  <Paper elevation={1} className={classes.paper}>
                    <Avatar className={classes.avatar}>
                      <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                      Verify
            </Typography>
                    {this.state.message ? <h4 className="red-text-center">{this.state.message}</h4> : null}
                    {auth.is2FMessage ? <h4 className="red-text-center">{auth.is2FMessage}</h4> : null}
                    <Typography className={classes.message} color="textSecondary" component="h1" variant="h5">
                      How do you want to verify your account?
            </Typography>

                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={() => this.setVerifyType('email')}
                      className={classes.submit}
                    >
                      {this.state.loader ? <CircularProgress size={24} /> : "Email"}
                    </Button>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={() => this.setVerifyType('phone')}
                      className={classes.submit}
                    >
                      Text Message
            </Button>
                    <Box mt={8}>
                      <Copyright />
                    </Box>
                  </Paper>
                  :
                  <Paper elevation={1} className={classes.paper}>
                    <Avatar className={classes.avatar}>
                      <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                      Verify
            </Typography>
                    {auth.Resndmessage ? <h4 className="red-text-center">{auth.Resndmessage}</h4> : null}
                    {this.state.codeType === 'email' ?
                      <Typography className={classes.message} color="textSecondary" component="h1" variant="h5">
                        Please verify your email address to access this portal and if you don't receive any email please click the resend button to send again
              </Typography>
                      :
                      <div>
                        <Typography className={classes.message} color="textSecondary" component="h1" variant="h5">
                          We sent a verification code to your {this.state.codeType}. Enter the code from {this.state.codeType} in the field below
                </Typography>

                        <TextField
                          className={classes.label}
                          variant="outlined"
                          margin="normal"
                          autoFocus
                          label="Verification Code"
                          required
                          error={this.state.error}
                          helperText={this.state.error ? "Verification Code field required" : ""}
                          value={this.state.code}
                          onChange={this.handleChange}
                          onBlur={this.handleChange}
                        />


                        <Button
                          type="button"
                          fullWidth
                          variant="contained"
                          color="secondary"
                          disabled={this.state.disable}
                          onClick={() => this.verifyAccount()}
                          className={classes.submit}
                        >
                          {this.state.loader ? <CircularProgress size={24} /> : "Verify Code"}
                        </Button>
                      </div>
                    }
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={() => this.resendEmail()}
                      className={classes.submit}
                    >
                      Resend
            </Button>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={this.props.signOut}
                      className={classes.signin}
                    >
                      Sign In
            </Button>
                    <Box mt={8}>
                      <Copyright />
                    </Box>
                  </Paper>
                }
              </div>
              :
              <div className={classes.container}>
                <Paper elevation={1} className={classes.paper}>
                  <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Verify
          </Typography>
                  <Typography className={classes.message} color="textSecondary" component="h1" variant="h5">
                    Your provider credentials are currently being validated by RhynoCare Inc. and should be complete within 24 hours
          </Typography>

                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={this.props.signOut}
                    className={classes.signin}
                  >
                    Sign In
            </Button>
                </Paper>
              </div>
            }
          </>
        }
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    account: state.account.data,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    // UPDATE PROFILE DATA IN REDUX
    updateProfileData: (data) => dispatch({
      type: 'ACCOUNT_DATA',
      user_data: data
    }),
    getVerification: (uid) => dispatch(getAccountVerification(uid)),
    resendAccountVerification: (userId, type) => dispatch(resendAccountVerification(userId, type)),
    signOut: () => dispatch(signOut())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(signupStyles)(Verify));