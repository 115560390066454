import React, { useState } from 'react'
import { Alert } from 'antd'
import { Grid, TextField, Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import ModelFooter from './ModelFooter'
import { modelBody, modelRow, formRowLastField } from './style'
import { createUpdateSocialHistoryItem, deleteSocialHistoryItem } from './API/API'

function SocialForm({ patientName, isRhynoGoPatient, row, id, onCloseModel, data }) {
    const [type, setType] = useState(row && row.type ? row.type : '')
    const [comment, setComment] = useState(row && row.comment ? row.comment : '')

    const [message, setMessage] = useState('')
    const [loader, setLoader] = useState(false)
    const [loaderType, setLoaderType] = useState('')
    const [formSubmitted, setFormSubmitted] = useState(false)

    const saved = async () => {
        setFormSubmitted(true)

        if (comment && type) {
            setLoader(true)
            setLoaderType('save')
            let result = ''
            if (id)
                result = await createUpdateSocialHistoryItem({ comment: comment, type: type, userId: data.patientId, doctorId: data.doctorId, pagination_id: data.pagination, id: id, patientName: patientName, isRhynoGoPatient: isRhynoGoPatient })
            else
                result = await createUpdateSocialHistoryItem({ comment: comment, type: type, userId: data.patientId, doctorId: data.doctorId, pagination_id: data.pagination, patientName: patientName, isRhynoGoPatient: isRhynoGoPatient })

            setLoader(false)
            if (result.message)
                setMessage(result.message)
            else
                onCloseModel('', true, 'Saved', result.data, result.pagination_id)
        }
    }
    const deleted = async () => {
        setLoader(true)
        setLoaderType('delete')
        let result = await deleteSocialHistoryItem({ userId: data.patientId, doctorId: data.doctorId, pagination_id: data.pagination, id: id, patientName: patientName, isRhynoGoPatient: isRhynoGoPatient })
        setLoader(false)
        if (result.message)
            setMessage(result.message)
        else
            onCloseModel('', true, 'Deleted', result.data, result.pagination_id)
    }

    return (
        <>
            <div className='modal-body' style={modelBody}>
                <Grid>
                    {message ?
                        <Grid >
                            <Alert type="error" closable={true} message={message}></Alert>
                        </Grid>
                        : ""}
                </Grid>
                <Grid style={modelRow}>

                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="type">Type</InputLabel>
                        <Select
                            labelId="type"
                            id="type"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            label="Type"
                            error={formSubmitted && !type}
                        >
                            <MenuItem value='Smoking'>Smoking</MenuItem>
                            <MenuItem value='No Smoking'>No Smoking</MenuItem>
                            <MenuItem value='Alcohol'>Alcohol</MenuItem>
                            <MenuItem value='No Alcohol Consumption'>No Alcohol Consumption</MenuItem>
                            <MenuItem value='Recreational drug use'>Recreational drug use</MenuItem>
                            <MenuItem value='Living with'>Living with</MenuItem>
                            <MenuItem value='Sexual Identity'>Sexual Identity</MenuItem>
                        </Select>
                        {formSubmitted && !type && <p className="MuiFormHelperText-root Mui-error">Select Type</p>}

                    </FormControl>

                    <TextField
                        type="text"
                        variant="outlined"
                        margin="normal"
                        style={formRowLastField}
                        label="Comment"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        error={formSubmitted && !comment}
                        helperText={formSubmitted && !comment && "Comment Field Required"}
                    />
                </Grid>
            </div >
            <ModelFooter saveClicked={saved} onCloseModel={onCloseModel} deleteClicked={deleted} loader={loader} id={id} loaderType={loaderType} />
        </>
    )
}

export default SocialForm
