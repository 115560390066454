function DragDrop(useDrag, useDrop, useSelector, setChat, chat_ref) {

    const { profile } = useSelector(
        (state) => ({
            profile: state.account.data
        })
    );
    const { auth } = useSelector(
        (state) => ({
            auth: state.auth
        })
    );
    const [{ }, medicalDrag] = useDrag({
        item: {
            type: 'medical'
        },
        type: 'medical',
        collect: (monitor) => ({
        })
    })
    const [{ }, socialDrag] = useDrag({
        item: {
            type: 'social'
        },
        type: 'social',
        collect: (monitor) => ({
        })
    })
    const [{ }, allergyDrag] = useDrag({
        item: {
            type: 'allergy'
        },
        type: 'allergy',
        collect: (monitor) => ({
        })
    })
    const [{ }, medicationDrag] = useDrag({
        item: {
            type: 'medication'
        },
        type: 'medication',
        collect: (monitor) => ({
        })
    })
    const [{ }, noteTemplateDrag] = useDrag({
        item: {
            type: 'noteTemplate'
        },
        type: 'noteTemplate',
        collect: (monitor) => ({
        })
    })

    const [collectedProps, drop] = useDrop(() => ({
        accept: ["medical", "social", "allergy", "medication", 'noteTemplate'],
        drop: (item, monitor) => {
            let types = { 'medical': 'MedicalHistoryList', 'social': 'SocialHistoryList', 'allergy': 'AllergiesHistoryList', 'medication': 'MedicationsHistoryList' }
            const type = item.type

            let itemsArray = []
            let items = []
            if (types[type])
                items = chat_ref.current[types[type]]
            else
                items.push({ name: item.name })

            items.filter(itemval => {
                if (type === "medical")
                    itemsArray.push(itemval.diagnose + ' ' + itemval.year)
                else if (type === "medication")
                    itemsArray.push(itemval.medication + ' ' + itemval.frequency)
                else if (type === "social")
                    itemsArray.push(itemval.type + ' ' + itemval.comment)
                else if (type === "allergy")
                    itemsArray.push(itemval.allergy + ' ' + itemval.reaction)
                else if (type === "noteTemplate")
                    itemsArray.push(itemval.name)
                return ''
            })
            itemsArray = itemsArray.join(', ')

            let previousData = chat_ref.current['ChatsHistoryList']
            previousData.splice(0, 0, {
                created_at: Math.floor(new Date().getTime() / 1000),
                updated_at: Math.floor(new Date().getTime() / 1000),
                doctorId: auth.uid,
                doctorName: profile.fullName,
                speciality: profile.speciality,
                message: itemsArray
            })
            let currentData = chat_ref.current
            currentData['ChatsHistoryList'] = previousData
            setChat(currentData)
        }
    }))

    return { medicalDrag, socialDrag, medicationDrag, allergyDrag, noteTemplateDrag, drop }
}

export default DragDrop
