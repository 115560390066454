import React, { useState } from 'react'
import { Alert } from 'antd'
import { Grid, TextField } from '@material-ui/core';
import ModelFooter from './ModelFooter'
import { modelBody, modelRow } from './style'
import { createUpdateNoteTemplateItem, deleteNoteTemplateItem } from './API/API'

function ProgressTemplateForm({ patientName, isRhynoGoPatient, row, id, onCloseModel, data }) {

    const [name, setName] = useState(row && row.name ? row.name : '')
    const [message, setMessage] = useState('')
    const [loader, setLoader] = useState(false)
    const [loaderType, setLoaderType] = useState('')
    const [formSubmitted, setFormSubmitted] = useState(false)

    const saved = async () => {
        setFormSubmitted(true)

        if (name) {
            setLoader(true)
            setLoaderType('save')
            let result = ''
            if (id)
                result = await createUpdateNoteTemplateItem({ name: name, userId: data.patientId, doctorId: data.doctorId, pagination_id: data.pagination, id: id, patientName: patientName, isRhynoGoPatient: isRhynoGoPatient })
            else
                result = await createUpdateNoteTemplateItem({ name: name, userId: data.patientId, doctorId: data.doctorId, pagination_id: data.pagination, patientName: patientName, isRhynoGoPatient: isRhynoGoPatient })

            setLoader(false)
            if (result.message)
                setMessage(result.message)
            else
                onCloseModel('', true, 'Saved', result.data, result.pagination_id)
        }
    }
    const deleted = async () => {
        setLoader(true)
        setLoaderType('delete')
        let result = await deleteNoteTemplateItem({ userId: data.patientId, doctorId: data.doctorId, pagination_id: data.pagination, id: id, patientName: patientName, isRhynoGoPatient: isRhynoGoPatient })
        setLoader(false)
        if (result.message)
            setMessage(result.message)
        else
            onCloseModel('', true, 'Deleted', result.data, result.pagination_id)
    }

    return (
        <>
            <div className='modal-body' style={modelBody}>
                <Grid>
                    {message ?
                        <Grid >
                            <Alert type="error" closable={true} message={message}></Alert>
                        </Grid>
                        : ""}
                </Grid>
                <Grid style={modelRow}>
                    <TextField
                        type="text"
                        variant="outlined"
                        margin="normal"
                        autoFocus
                        required
                        label="Add Template"
                        name="name"
                        value={name}
                        error={formSubmitted && !name}
                        helperText={formSubmitted && !name && "Template Field Required"}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Grid>
            </div>
            <ModelFooter saveClicked={saved} onCloseModel={onCloseModel} deleteClicked={deleted} loader={loader} id={id} loaderType={loaderType} />
        </>
    )
}

export default ProgressTemplateForm
