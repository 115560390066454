import React, { Component } from 'react'
import withStyles from "@material-ui/core/styles/withStyles"
import { Grid, Button, TextField, CircularProgress } from '@material-ui/core'
import GetFamilyEmailsList from './GetFamilyEmailsList'
import StripeCheckOut from '../appointment/stripe'
import { connect } from 'react-redux';
import styled from 'styled-components'
import { SUBSCRIPTIONMEMBERPRICE, SUBSCRIPTIONOWNERPRICE } from '../../config'

const Dot = styled.span`
height: 7px;
width:  7px;
background-color: rgb(0,188,66);
border-radius: 50%;
display: inline-block;
`

const styles = theme => ({
    popup: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column'
    },
    popupDiv: {
        backgroundColor: '#fff',
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '35rem',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            width: '100%',
        }
    },
    subscriptionDiv: {
        width: '50rem',
        backgroundColor: 'inherit',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: 0
        }
    },
    popupButton: {
        marginBottom: theme.spacing(2),
        fontFamily: 'inherit',
        width: theme.spacing(20),
        margin: theme.spacing(1, 'auto')
    },
    popupHeading: {
        fontFamily: 'inherit',
        fontWeight: 'bold',
        fontSize: theme.spacing(2.4)
    },
    font: {
        fontFamily: 'revert',
    },
    mainDiv: {
        display: 'flex',
        flexDirection: 'column'
    },
    whiteColor: {
        color: '#fff'
    },
    subscriptionTypes: {
        backgroundColor: 'inherit',
        display: 'flex',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        }
    },
    subscriptionItem: {
        borderRadius: "0px 25px 20px 20px",
        backgroundColor: 'white',
        "&:hover": {
            boxShadow: "5px 10px 18px grey",
        },
        padding: theme.spacing(2),
        margin: theme.spacing(1),
        textAlign: 'left',
        width: '100%',
    },
    subscriptionFirstItem: {
        paddingTop: theme.spacing(3),
    },
    subscriptionItemList: {
        fontSize: '14px',
        fontWeight: 500,

    },
    button: {
        color: '#fff',
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        }
    },
    subscriptionHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        width: '100%'
    },
    subscriptionItemsDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'baseline'
    },
    buttonChoose: {
        textTransform: 'none',
        color: 'white',
        fontWeight: 'bold',
        paddingTop: '20px',
        paddingBottom: '20px',
        borderRadius: '15px',
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: "#1f4037"
        },
    },
    payType: {
        marginBottom: theme.spacing(2),
    }
})
class RhynoGo extends Component {

    constructor(props) {
        super(props)
        this.state = {
            hasSubscriptionPaymentType: false,
            hasSubscriptionToken: false,
            stripeToken: '',
            token: '',
            errorMessage: '',
            subscriptionType: '',
            emailsList: { data: [] },
            amountForStripe: { paidamount: 0, holdamount: 0, isSignUp: true },
            isSubscribed: props.account.data.subscriptionStatus,
            userSubscription: props.account.data.subscriptionType,
            loader: false
        }

        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true
    }

    setSubscriptionType = (val) => {
        this.setState({ subscriptionType: val })
        if (val === 'individual')
            this.setState({ hasSubscriptionPaymentType: true })
    }
    setEmailList = (emailsList) => {
        this.setState({ emailsList: { data: emailsList } })
        this.setState({ hasSubscriptionPaymentType: true })
    }
    clickStripeButton = () => {
        this.setState({ loader: true })
        let priceAmount = this.state.emailsList.data.length * SUBSCRIPTIONMEMBERPRICE
        priceAmount += SUBSCRIPTIONOWNERPRICE
        this.setState({ amountForStripe: { paidamount: priceAmount, holdamount: 0, isSignUp: true } }, () => {
        document.getElementById('stripeButton').childNodes[0].click();
        })

    }
    stripeToken = (token = '') => {
        this.setState({ loader: true })
        let useToken = false
        if (token === '') {
            token = this.state.token
            useToken = true
        }
        else {
            token = token.stripetoken
        }
        this.props.updateSubscription({ tokenUsed: useToken, token: token, subscriptionType: this.state.subscriptionType, familyEmails: this.state.emailsList.data })
    }

    updateTokenInfo = () => {
        this.setState({ hasSubscriptionToken: true, hasSubscriptionPaymentType: false })
    }

    updatePaymentToken = (e) => {
        this.setState({ token: e.target.value })
    }

    render() {
        let { classes, account } = this.props

        return (
            <Grid className={classes.font}>
                <Grid className={classes.popup}>
                    <Grid className={classes.popupDiv + ' ' + classes.subscriptionDiv}>
                        {this.state.hasSubscriptionPaymentType ?
                            <Grid className={classes.mainDiv}>
                                <Grid className={classes.subscriptionItem}>
                                    <Button className={classes.buttonChoose + ' ' + classes.payType} fullWidth variant="contained" onClick={() => this.clickStripeButton()} color={'primary'}>
                                        {this.state.subscriptionType !== '' && this.state.loader ? <CircularProgress color="#fff" size={24} /> : 'USE A CREDIT CARD'}
                                    </Button>
                                    <Button className={classes.buttonChoose + ' ' + classes.payType} fullWidth variant="contained" onClick={() => this.updateTokenInfo()} color={'primary'}>
                                        ENTER A TOKEN
                                    </Button>
                                </Grid>
                            </Grid>
                            :
                            this.state.hasSubscriptionToken ?
                                <Grid className={classes.mainDiv}>
                                    <Grid className={classes.subscriptionItem}>
                                        <TextField
                                            id={"token"}
                                            value={this.state.token}
                                            onChange={this.updatePaymentToken}
                                            onBlur={this.updatePaymentToken}
                                            label="Token"
                                            style={{ marginBottom: '24px' }}
                                            error={this.state.errorMessage !== '' ? true : false}
                                            helperText={this.state.errorMessage}
                                        />
                                        <Button className={classes.buttonChoose + ' ' + classes.payType} fullWidth variant="contained" onClick={() => this.stripeToken()} color={'primary'}>
                                            {this.props.loading ? <CircularProgress size={24} /> : "Submit"}
                                        </Button>
                                    </Grid>
                                </Grid>
                                : this.state.subscriptionType === "" || this.state.subscriptionType === "individual" ?
                                    <Grid className={classes.mainDiv}>
                                        <Grid className={classes.subscriptionTypes}>
                                                <Grid className={classes.subscriptionItem}>
                                                    <Grid className={classes.subscriptionHeader}>
                                                        <span className={classes.popupHeading}>
                                                        {!this.state.isSubscribed ? "Sign up for" : "Subscribed to "} a subscription from CAD 39.99 +hst per month (billed every month)
                                                </span>
                                                        {this.state.isSubscribed === 1 &&
                                                        <Button className={classes.button} variant="contained" color="primary" onClick={() => this.props.cancelSubscription(account.data.subscriptionType, account.data.active_subscription_id, account.data.isOwnerOfSubscription === 1 ? 0 : 1, 'current')}>
                                                            {account.data.isOwnerOfSubscription === 1 ? 'Cancel/Change' :
                                                                    this.props.loading && this.props.edit_key === 'cancel_subscription' ? <CircularProgress className={classes.button} size={21} /> :
                                                                        "Cancel"
                                                                }
                                                            </Button>
                                                        }
                                                    </Grid>
                                                    <p className={classes.subscriptionItemList + ' ' + classes.subscriptionFirstItem}>
                                                    <Dot /> 1 RhynoGo dispatch per member - RhynoGo includes phlebotomy, IM injections, Pre-screen visit, Wellness visit, IV Therapy and COVID-19 testing (not covered)
                                            </p>
                                                    <p className={classes.subscriptionItemList}>
                                                    <Dot /> Dedicated Patient Care Coordinator
                                            </p>
                                                    <p className={classes.subscriptionItemList}>
                                                    <Dot /> Initial in-person physical assessment (one-time)
                                            </p>
                                                <p className={classes.subscriptionItemList}>
                                                    <Dot /> Customized care plan
                                                </p>
                                                <p className={classes.subscriptionItemList}>
                                                    <Dot /> Welcome package
                                                </p>
                                                <p className={classes.subscriptionItemList}>
                                                    <Dot /> Free cancellation at any time
                                            </p>
                                                    <p className={classes.subscriptionItemList}>
                                                    <Dot /> A la carte menu, member discount
                                            </p>
                                                    <p className={classes.subscriptionItemList}>
                                                    <Dot /> $19.99 +hst/month for each additional family member (must be from the same household)
                                            </p>
                                                    {!this.state.isSubscribed &&
                                                        <Button className={classes.buttonChoose} fullWidth variant="contained" onClick={() => this.setSubscriptionType('family')} color={'primary'}>
                                                            Choose
                                                </Button>
                                                    }
                                                </Grid>
                                        </Grid>
                                    </Grid>
                                    : this.state.subscriptionType === "family" ?
                                        <GetFamilyEmailsList loading={this.props.loading} emailsList={this.setEmailList} />
                                        :
                                        ""
                        }
                        <StripeCheckOut appointmentData={this.state.amountForStripe} addAppointment={this.stripeToken} />
                    </Grid>

                    {account.data.subscriptionStatus === 1 && (account.data.subscriptionType === 'family' || account.data.subscriptionType === 'individual') &&
                        account.data.noOfSubscriptions > 1 &&
                        <Grid className={classes.popupDiv + ' ' + classes.subscriptionDiv}>
                            <Grid className={classes.subscriptionItemsDiv}>
                                <Grid className={classes.subscriptionTypes}>
                                    <Grid className={classes.subscriptionItem}>
                                        <Grid className={classes.subscribedDiv}>

                                            <Grid className={classes.subscriptionItemsDiv}>
                                                <span>Invited to family Subscription @ 39.99 per month (billed every 3 months)</span>
                                                <Button className={classes.button} variant="contained" color="primary" onClick={() => this.props.cancelSubscription('family', account.data.active_subscription_id, 1, 'other')}>
                                                    {this.state.loading && this.props.edit_key === 'other' ? <CircularProgress className={classes.button} size={21} /> : "Cancel"}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }

                </Grid>
            </Grid>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        account: state.account,
    };
};
export default connect(mapStateToProps, null)(withStyles(styles)(RhynoGo))