import React, { Component } from 'react'
import { connect } from 'react-redux'
import withStyles from "@material-ui/core/styles/withStyles"
import { getBillingHistory, generateEDTFle } from '../../store/actions/BillingAction'
import { Redirect } from 'react-router-dom'
import { Table, TableBody, TableHead, TableRow, TableCell, Button, CircularProgress } from '@material-ui/core'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import GetAppIcon from '@material-ui/icons/GetApp';



const styles = theme => ({
  fontWeight: {
    fontWeight: 700,
  },
  pointer: {
    cursor: 'pointer'
  },
  autocomplete: {
    width: '50%',
    margin: theme.spacing(0, 1, 2)
  },
  autocomplete1: {
    width: '100%',
    margin: theme.spacing(0, 1, 2)
  },
  alert: {
    marginBottom: theme.spacing(3)
  },
  table: {
    marginBottom: theme.spacing(2)
  },
  rating: {
    fontWeight: 700
  },
  selector: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  export: {
    float: 'right',
    backgroundColor: '#795bb2 !important',
    padding: theme.spacing(1, 2),
    marginLeft: theme.spacing(1),
    color: '#fff !important',
    borderRadius: theme.spacing(.5),
    border: 'transparent',
    cursor: 'pointer'
  },
  addCustomFee: {
    width: '100%',
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
  addCustomFeeFields: {
    margin: theme.spacing(.7, 0) + " !important"
  },
  date: {
    width: 'max-content !important',
    margin: '0 1rem !important'
  },
  filter: {
    display: 'flex',
    alignItems: 'center'
  },
  pagination: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1)
  },
  icon: {
    cursor: 'pointer'
  },
  uploadClass: {
    display: 'none'
  },
});

class BillingHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModel: false,
      appointmentId: '',
      fromDate: moment(),
      loading: '',
      edtData: { data: [] },
      download_data: '',
      fileNameToDownload: ''
    };
    this._isMounted = false;
  }
  async componentDidMount() {
    this._isMounted = true
    this.getEDTDetailsFromApi(0, 'load')
    this.props.setTotalCalls(1)
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getEDTDetailsFromApi = async (pagination_id = 0, type = 'next') => {
    if (this._isMounted)
      this.setState({ loading: type })
    let result = await this.props.getBillingHistory(JSON.stringify({ pagination_id: pagination_id, type: type, doctorId: this.props.auth.uid }))
    this.setState({ loading: '', edtData: result })
  }

  changeDate = (e, type) => {
    this.setState({ [type]: moment(e) })
  }
  generateEDTFile = async () => {
    await this.props.generateEDTFle(JSON.stringify({ doctorId: this.props.auth.uid, month: moment(this.state.fromDate).format('M'), year: moment(this.state.fromDate).format('YYYY') }))
    this.getEDTDetailsFromApi(0, 'load')
  }
  downloadFile = (record) => {
    console.log(record)
    this.setState({ download_data: `data:text/plain;charset=utf-8,${record.fileData}`, fileNameToDownload: record.fileName }, () => {
      document.getElementById('download_pdf').click()
    })
  }
  render() {
    let monthNamesList = { 0: "January", 1: "February", 2: "March", 3: "April", 4: "May", 5: "June", 6: "July", 7: "August", 8: "September", 9: "October", 10: "November", 11: "December" }
    const { classes, auth, account } = this.props;
    if (!auth.uid) {
      return <Redirect to="/signin" />;
    }
    if (!auth.isVerified) {
      return <Redirect to="/verify" />;
    }
    if (account.data.country !== 'Canada' || account.data.state !== 'Ontario') {
      return <Redirect to="/billing" />;
    }
    return (
      <div>
        <div className={classes.filter}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              variant="inline"
              className={classes.date}
              value={this.state.fromDate}
              format="MMMM, yyy"
              onChange={(e) => this.changeDate(e, 'fromDate')}
            />
          </MuiPickersUtilsProvider>
        </div>

        <Button className={classes.export} onClick={this.generateEDTFile}>
          Generate EDT File
        </Button>
        {this.state.loading === 'load' ?
          <CircularProgress size={24} />
          :

          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.fontWeight}>Month</TableCell>
                <TableCell className={classes.fontWeight}>File Name</TableCell>
                <TableCell className={classes.fontWeight}>Create Date</TableCell>
                <TableCell className={classes.fontWeight}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                this.state.edtData.data.length > 0 ?
                  this.state.edtData.data.map((edtItem, key) => {
                    return (<TableRow key={key}>
                      <TableCell>{monthNamesList[edtItem.month]}</TableCell>
                      <TableCell>{edtItem.fileName}</TableCell>
                      <TableCell>{moment(edtItem.createdAt * 1000).format("D/MMM/YYYY")}</TableCell>
                      <TableCell><GetAppIcon className={classes.icon} onClick={() => { this.downloadFile(edtItem) }} /></TableCell>
                    </TableRow>)
                  })
                  :
                  <TableRow>
                    <TableCell colSpan={10} className={classes.fontWeight}>No Record Found</TableCell>
                  </TableRow>
              }
            </TableBody>
          </Table>
        }
        <div className={classes.pagination}>
          <div>{this.state.edtData.hasPrevious && <Button className={classes.export} onClick={() => this.getEDTDetailsFromApi(this.state.edtData.pagination_id, 'previous')}>
            {this.state.loading === 'Previous' ? <CircularProgress size={24} /> : "Previous"}
          </Button>}</div>
          <div>{this.state.edtData.hasNext && <Button className={classes.export} onClick={() => this.getEDTDetailsFromApi(this.state.edtData.pagination_id)}>
            {this.state.loading === 'Next' ? <CircularProgress size={24} /> : "Next"}
          </Button>}</div>
        </div>
        <a className={classes.uploadClass} id={"download_pdf"} content={this.state.download_data} href={this.state.download_data} download={this.state.fileNameToDownload}>Download</a>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    account: state.account,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getBillingHistory: (data) => getBillingHistory(data),
    generateEDTFle: (data) => generateEDTFle(data)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BillingHistory));