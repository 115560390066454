import { APIURL } from '../../config.js'
import functions from '../../assets/js/function'

// CREATE NEW SUBSCRIPTION FOR USER
export const createSubscription = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data;
    return fetch(APIURL + "/createSubscription", {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .catch(error => {
        return {
          message: error
        }
      });
  } else {
    return {
      message: 'token error'
    }
  }
}

// CANCEL SUBSCRIPTION FOR USER
export const cancelSubscription = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data;
    return fetch(APIURL + "/cancelSubscription", {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .catch(error => {
        return {
          message: error
        }
      });
  } else {
    return {
      message: 'token error'
    }
  }
}

// UPDATE SUBSCRIPTION MEMBERS
export const updateSubscriptionMembers = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data;
    return fetch(APIURL + "/updateSubscriptionMembers", {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .catch(error => {
        return {
          message: error
        }
      });
  } else {
    return {
      message: 'token error'
    }
  }
}