import React, { Component } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Main from './main'
// import Notification from '../src/components/notifications/Notifications'

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#fff',
            main: '#00bbbe',
            dark: '#00897b'
        },
        secondary: {
            main: '#795bb2',
        },
        lightGrey:{
            main:'rgb(110,127,127)'
        }
    },
    typography: {
        useNextVariants: true
    }
});
class App extends Component {
    render() {
        return (
            <div className="App">
                <MuiThemeProvider theme={theme}>
                    <Main></Main>
                    {/* <Notification /> */}
                </MuiThemeProvider>
            </div>
        );
    }
}

export default App;