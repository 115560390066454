import React, { Component } from "react";
import { connect } from "twilio-video"
import withStyles from '@material-ui/styles/withStyles'
import { APIURL } from '../../config.js'
import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'
import VideocamIcon from '@material-ui/icons/Videocam'
import VideocamOffIcon from '@material-ui/icons/VideocamOff'
import CallEndIcon from '@material-ui/icons/CallEnd'
import Timer from 'react-compound-timer'
import CallIcon from '@material-ui/icons/Call'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import { isMobileOnly } from "react-device-detect"
import Fullscreen from "react-full-screen"
import getConnection from '../getConnection/getConnection'
import { Grid, CircularProgress } from "@material-ui/core"
import $ from 'jquery'


const styles = theme => ({
  videoApp: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 'calc(100vh - 216px) !important',
    //maxHeight: '80vh',
    backgroundColor: 'black',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      minHeight: '100vh',
      maxHeight: '100vh',
    }
  },
  fullPage: {
    maxHeight: '100vh',
    minHeight: '100vh !important',
  },
  actions: {
    position: 'absolute',
    bottom: '10px',
    left: '10px',
    display: 'flex',
    zIndex: 1
  },
  button: {
    backgroundColor: '#00bbbe',
    color: '#fff',
    display: 'flex',
    marginRight: '15px',
    borderRadius: '100px',
    border: 'unset',
    padding: '7px',
    outline: 'unset',
    cursor: 'pointer'
  },
  activeButton: {
    backgroundColor: '#fff',
    color: '#00bbbe'
  },
  cancelButton: {
    backgroundColor: '#ff2abc',
    color: '#fff'
  },
  timer: {
    position: 'absolute',
    textAlign: 'left',
    color: '#fff',
    backgroundColor: "#6f6f6fab",
    fontSize: '20px',
    padding: theme.spacing(0, .7),
    left: '10px',
    top: '10px',
    fontWeight: '900'
  },
  media: {
    fontSize: '16px',
    fontWeight: 700,
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%'
  },
  progress: {
    color: '#fff'
  },
  localMedia: {
    width: '130px',
    height: '130px',
    float: 'right',
    position: 'absolute',
    zIndex: 1,
    right: '14px',
    top: 'calc(100% - 130px)'
  },
  video: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  loadingText: {
    position: 'absolute',
    top: '45%',
    width: '100%',
    left: 0
  },
})
var $localRoom = ''
var $remoteRoom = ''
var $localParticipants = ''
var $remoteParticipants = ''
class VideoApp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      identity: null,
      peerUserId: 0,
      peerIdentity: "",
      remoteVideoLoaded: false,
      roomName: 'video_' + props.doctorId + '_' + props.userId + '_' + props.AppointmentId,  // Room Name   
      roomNameErr: false, // Track error for room name TextField  
      previewTracks: null,
      localMediaAvailable: false,
      hasJoinedRoom: false,
      hasParticipantsJoinedRoom: false,
      activeRoom: '', // Track the current active room  
      jwt: '',
      audioMic: true,
      videoMic: true,
      roomJoined: false,
      isMobile: isMobileOnly ? true : false,
      isFull: isMobileOnly ? true : false
    }

    this.joinRoom = this.joinRoom.bind(this);
    this.leaveRoom = this.leaveRoom.bind(this);
    this.muteUnMuteAudio = this.muteUnMuteAudio.bind(this);
    this.muteUnMuteVideo = this.muteUnMuteVideo.bind(this);
    this.getTwillioToken()
  }

  getTwillioToken = () => {
    const currentUserName = this.props.username;
    if (currentUserName.length === 0) {
      console.log("Please enter the username!");
      return;
    }

    fetch(APIURL + '/video/token', {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      method: 'POST',
      body: `identity=${encodeURIComponent(this.props.username)}`
    })
      .then(res => res.json())
      .then(results => {
        const identity = results.identity;
        const jwt = results.token;
        this.setState(
          {
            identity,
            jwt
          }, () => {
            if (jwt.length === 0 || identity.length === 0) {
              console.log("Issue to fetch token!");
            } else {
              this.setState({ userName: currentUserName });
              this.joinRoom();
            }
          });
      });
  }

  joinRoom = async () => {
    $localRoom = $('#localVideo');
    $remoteRoom = $('#remoteVideo');
    $localParticipants = $('div#localParticipant', $localRoom);
    $remoteParticipants = $('div#remoteParticipant', $remoteRoom);
    if (!this.state.roomName.trim()) {
      this.setState({ roomNameErr: true });
      return;
    }
    let connectOptions = await getConnection(this.state.roomName)
    const room = await connect(this.state.jwt, connectOptions);
    this.setState({ activeRoom: room })
    const localParticipant = room.localParticipant;
    // Access the already published LocalTracks.
    this.setupParticipantContainer(localParticipant, room)

    // Handle the TrackPublications already published by the Participant.
    localParticipant.tracks.forEach(publication => {
      this.trackPublished(publication, localParticipant);
    });

    // Handle theTrackPublications that will be published by the Participant later.
    localParticipant.on('trackPublished', publication => {
      this.trackPublished(publication, localParticipant);
    });


    room.participants.forEach(participant => {
      this.setupParticipantContainer(participant, room)
      // Handle the TrackPublications already published by the Participant.
      participant.tracks.forEach(publication => {
        this.trackPublished(publication, participant);
      });

      // Handle theTrackPublications that will be published by the Participant later.
      participant.on('trackPublished', publication => {
        this.trackPublished(publication, participant);
      });

      this.setState({ roomJoined: true })
      document.getElementById("startTimerButton").click()
    })

    room.on('participantConnected', participant => {
      console.log(`Participant connected: ${participant.identity}`);
      this.setupParticipantContainer(participant, room)
      // Handle the TrackPublications already published by the Participant.
      participant.tracks.forEach(publication => {
        this.trackPublished(publication, participant);
      });

      // Handle theTrackPublications that will be published by the Participant later.
      participant.on('trackPublished', publication => {
        this.trackPublished(publication, participant);
      });

      this.setState({ roomJoined: true })
      document.getElementById("startTimerButton").click()
    });

    room.on('participantDisconnected', participant => {
      console.log(`participant disconnected hook on room: ${participant.identity}`)
      console.log('room.participants.size')
      console.log(room.participants.size)
      this.participantDisconnected(participant, room);
      document.getElementById("pauseTimerButton").click()
      // setTimeout(() => {
      //   if (room.participants.size === 0)
      //     this.props.endCall()
      // }, 2000);
    });


    room.once('disconnected', (room, error) => {

      // Clear the event handlers on document and window..
      window.onbeforeunload = null;

      room.localParticipant.tracks.forEach(publication => {
        const attachedElements = publication.track.detach();
        attachedElements.forEach(element => element.remove());
      });

      document.getElementById("pauseTimerButton").click()
    })

  }

  participantDisconnected = (participant, room) => {
    console.log('participant disconnected')
    let $participants = ''
    if (this.state.activeRoom.localParticipant === participant)
      $participants = $localParticipants
    else
      $participants = $remoteParticipants
    // Remove the Participant's media container.
    $(`div#${participant.sid}`, $participants).remove();
    this.setState({ roomJoined: false })
  }

  attachTrack = (track, participant) => {
    console.log('tracks attached')
    let $participants = ''
    if (this.state.activeRoom.localParticipant === participant)
      $participants = $localParticipants
    else
      $participants = $remoteParticipants
    // Attach the Participant's Track to the thumbnail.
    const $media = $(`div#${participant.sid} > ${track.kind}`, $participants);
    $media.css('opacity', '');
    track.attach($media.get(0));
  }
  detachTrack = (track, participant) => {
    console.log('tracks detached')
    let $participants = ''
    if (this.state.activeRoom.localParticipant === participant)
      $participants = $localParticipants
    else
      $participants = $remoteParticipants
    // Detach the Participant's Track from the thumbnail.
    const $media = $(`div#${participant.sid} > ${track.kind}`, $participants);
    $media.css('opacity', '0');
    track.detach($media.get(0));
  }
  trackPublished = (publication, participant) => {
    // If the TrackPublication is already subscribed to, then attach the Track to the DOM.
    if (publication.track) {
      this.attachTrack(publication.track, participant);
    }

    // Once the TrackPublication is subscribed to, attach the Track to the DOM.
    publication.on('subscribed', track => {
      console.log('tracks attached')
      this.attachTrack(track, participant);
    });

    // Once the TrackPublication is unsubscribed from, detach the Track from the DOM.
    publication.on('unsubscribed', track => {
      console.log('tracks detached')
      this.detachTrack(track, participant);
    });
  }
  setupParticipantContainer = (participant, room) => {
    const { identity, sid } = participant;
    // Add a container for the Participant's media.
    const $container = $(`<div class="participant" data-identity="${identity}" id="${sid}">
      <audio autoplay ${participant === room.localParticipant ? 'muted' : ''} style="opacity: 0"></audio>
      <video autoplay muted playsinline style="opacity: 0"></video>
    </div>`);
    // Add the Participant's container to the DOM.
    let $participants = ''
    console.log($localParticipants)
    if (room.localParticipant === participant)
      $participants = $localParticipants
    else
      $participants = $remoteParticipants
    $participants.append($container);


    if (room.localParticipant !== participant && !this.state.remoteVideoLoaded) {
      var v = $('#remoteVideo #remoteParticipant div video')[0]
      v.addEventListener("loadeddata", () => {
        console.log('video loaded successfully')
        this.setState({ remoteVideoLoaded: true })
      });
    }
  }



  confirmLeaveRoom(props) {
    this.setState({ isFull: false })
    // confirm({
    //   title: 'Are you sure to Close the chat session?',
    //   okText: 'Yes',
    //   okType: 'danger',
    //   cancelText: 'No',
    //   onOk: () => {
    //     props.endCall()
    //   },
    //   onCancel() {
    //   },
    // });
    this.leaveRoom()
  }
  leaveRoom() {
    this.setState({ isFull: false })
    this.state.activeRoom.localParticipant.videoTracks.forEach(publication => {
      publication.track.stop();
      publication.unpublish();
    });
    this.state.activeRoom.disconnect();
    this.props.startVideoCall()
    this.setState({ hasJoinedRoom: false, localMediaAvailable: false, peerIdentity: '', roomJoined: false });
  }
  muteUnMuteAudio = () => {
    var localParticipant = this.state.activeRoom.localParticipant;
    localParticipant.audioTracks.forEach(publication => {
      if (this.state.audioMic) {
        publication.track.disable();
      } else {
        publication.track.enable();
      }
    });
    this.setState({ audioMic: !this.state.audioMic })
  }
  muteUnMuteVideo = () => {
    var localParticipant = this.state.activeRoom.localParticipant;
    localParticipant.videoTracks.forEach(publication => {
      if (this.state.videoMic) {
        publication.track.disable();
      } else {
        publication.track.enable();
      }
    });
    this.setState({ videoMic: !this.state.videoMic })
  }
  hideBoxes = (val) => {
    this.props.hideBoxes(val)
  }
  // componentDidMount() {
  //   this.props.onRef(this)
  // }
  // componentWillUnmount() {
  //   this.props.onRef(null)
  // }
  fullScreen = () => {
    console.log('fullscreen method called')
    this.setState({ isFull: true })
  }
  checkDevice = () => {
    if (isMobileOnly) {
      // alert('its mobile')
    }
  }

  render() {
    const { classes } = this.props
    // /* Hide 'Join Room' button if user has already joined a room */  
    // let joinOrLeaveRoomButton = this.state.hasJoinedRoom ? (  
    //   <button className="btn btn-warning" onClick={this.leaveRoom} > Leave Room</button>  
    // ) : (  
    //     <button className="btn btn-success ml-2" onClick={this.getTwillioToken} >Join Room</button>  
    //   );  
    // /** */  

    return (
      <React.Fragment>
        <div className={"videoSection"}>
          <div className={"videoHeader chatHeader"}>
            <p><CallIcon />Ongoing Call</p>
            <FullscreenIcon id="fullscreen" onClick={this.fullScreen} />
          </div>
          <Fullscreen
            enabled={this.state.isFull}
            onChange={isFull => this.setState({ isFull })}
          >
            <Grid className={this.state.isFull === true ? classes.videoApp + ' ' + classes.fullPage : classes.videoApp}>
              <Grid id="localVideo" className={this.state.roomJoined ? classes.media + ' ' + classes.localMedia : classes.media} >
                <div id="localParticipant" ref="groupChat_localMedia" className={classes.video}></div>
                <span className={classes.loadingText}>Waiting For Local Media Loading <CircularProgress thickness={7} className={classes.progress} size={17} /></span>
              </Grid>
              <Grid id="remoteVideo" className={classes.media} style={{ display: this.state.roomJoined ? 'block' : 'none' }}>
                <div id="remoteParticipant" style={{ display: this.state.remoteVideoLoaded === false ? 'none' : 'flex' }} ref="remoteMedia" className={classes.video}></div>
                {this.state.remoteVideoLoaded === false ? <span className={classes.loadingText}>Waiting For Remote Media Loading <CircularProgress thickness={7} className={classes.progress} size={17} /></span> : ""}
              </Grid>
              <Grid className={classes.timer}>
                <Timer
                  startImmediately={false}
                  onStart={() => console.log('onStart hook')}
                >
                  {({ start, resume, pause, stop, reset, timerState }) => (
                    <React.Fragment>
                      <div>
                        <Timer.Minutes />:
                          <Timer.Seconds />
                      </div>
                      <div>
                        <button style={{ display: 'none' }} id="startTimerButton" onClick={start}></button>
                        <button style={{ display: 'none' }} id="pauseTimerButton" onClick={pause}></button>
                      </div>
                    </React.Fragment>
                  )}
                </Timer>
              </Grid>
              <Grid className={classes.actions}>
                {
                  this.state.audioMic
                    ?
                    <div className="card-footer"><button className={classes.button} onClick={this.muteUnMuteAudio} > <MicIcon /></button></div>
                    :
                    <div className="card-footer"><button className={classes.button + " " + classes.activeButton} onClick={this.muteUnMuteAudio} > <MicOffIcon /></button></div>
                }
                {
                  this.state.videoMic
                    ?
                    <div className="card-footer"><button className={classes.button + " " + classes.activeButton} onClick={this.muteUnMuteVideo} > <VideocamIcon /></button></div>
                    :
                    <div className="card-footer"><button className={classes.button} onClick={this.muteUnMuteVideo} > <VideocamOffIcon /></button></div>
                }
                <div className="card-footer"><button className={classes.button + ' ' + classes.cancelButton} onClick={() => this.confirmLeaveRoom(this.props)} > <CallEndIcon /></button></div>
              </Grid>
              <div style={{ display: !this.state.roomJoined ? "block" : "none", position: isMobileOnly ? "absolute" : "unset", top: 0, marginTop: '20px', textAlign: 'left', fontSize: '15px' }}>Waiting for the other party to join</div>
            </Grid>
          </Fullscreen>
        </div>
      </React.Fragment>
    )
  }

}
export default withStyles(styles)(VideoApp)