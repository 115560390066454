import authReducer from './authReducer'
import accountReducer from './accountReducer'
import AppointmentReducer from './appointmentReducer'
import { RynoGoUsersReducer } from './RynoGoUsersReducer'
import { SubAccountsReducer } from './SubAccountsReducer'
import BillingReducer from './billingReducer'
import General from './generalReducer'
import EMR from './EMRReducer'
import { combineReducers } from 'redux'
const rootReducer = combineReducers({
    auth: authReducer,
    account: accountReducer,
    general: General,
    appointment: AppointmentReducer,
    billing: BillingReducer,
    emr: EMR,
    rhynoGoUsers: RynoGoUsersReducer.reducer,
    subAccount: SubAccountsReducer.reducer
})

export default rootReducer