import React, { useState, useEffect } from 'react'
import Functions from '../../assets/js/function'
import { connect, useSelector } from 'react-redux'
import { Typography, CircularProgress, Grid } from '@material-ui/core'
import moment from 'moment'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'

function RequisitionList(props) {
    const { authState } = useSelector(
        (state) => ({ authState: state.auth })
    );

    useEffect(() => {
        if (authState) {
            if (!authState.uid) {
                window.location.href = "/signin"
            }
            if (!authState.isVerified) {
                window.location.href = "/verify"
            }
        }
    }, [authState]);

    const [requisitions, setRequisitions] = useState([])
    const [loader, setLoader] = useState(true)
   
    useEffect((props) => {
    const getRequisitionList = async () => {
			let data = await Functions.getPharmacyRequisitions({ id: authState.uid })
        setRequisitions(data.requisitionList)
        setLoader(false)
    }
        getRequisitionList()
    }, [authState])
    return (
        <Grid style={gridStyle}>
            {loader ? <CircularProgress size={24} /> : requisitions.length === 0 ? <h4>No Requisitions Found</h4> :
                <>
                    {requisitions.map(req => {
                        return (
                            <Typography key={req.id} align={"left"} style={itemstyle}>
                                {decodeURI(req.name)}
                                {' on '}
                                {moment(req.createdAt).format('MMMM D, YYYY H:mm:ss a')}
                                {req.link && req.link !== '' ? <a href={req.link} target="_blank" rel="noopener noreferrer"><PictureAsPdfIcon color="primary" /></a> : ""}
                            </Typography>
                        )
                    })}
                </>
            }
        </Grid>
    )
}

const gridStyle = {
    backgroundColor: 'white',
    padding: '34px',
    borderRadius: '20px',
    boxShadow: '1px 1px 5px - 1px gray'
}
const itemstyle = {
    borderBottom: '1px solid gray',
    width: 'fit-content',
    padding: '10px 4px 4px',
    textTransform: 'capitalize'
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};
export default connect(mapStateToProps, null)(RequisitionList)
