import { SubAccountsReducer } from '../../../store/reducers/SubAccountsReducer'
import { APIURL } from '../../../config.js'
import Functions from '../../../assets/js/function'
import moment from 'moment'
const SubAccountsActions = SubAccountsReducer.actions

const switchToOtherAccount = (data) => dispatch => {
    dispatch(SubAccountsActions.update(data))
    window.location.reload()
    return ''
}

/*************************************************************************
ADD SUB ACCOUNT
*************************************************************************/
const addSubAccount = async (data) => {
    try {
        var message = '';
        let jsonmap = JSON.stringify(data);
        // GET CURRENT LOGGED IN USER AUTH TOKEN
        let firebaseToken = await Functions.getIdToken()
        // CALL API TO CREATE RECORD
        await fetch(APIURL + '/createSubAccount', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
            .then(response => response.json())
            .then(data => {
                message = { status: data.status, message: data.message }
            })
            .catch(error => {
                message = { status: 0, message: error.message }
            });

        return message
    }
    catch (error) {
        return { status: 0, message: error.messsage }
    }
}

/*************************************************************************
SET SUBACCOUNT PASSWORD
*************************************************************************/
const setSubAccountPassword = async (data) => {
    try {
        var message = '';
        let jsonmap = JSON.stringify(data);
        // CALL API TO SET PASSWORD
        await fetch(APIURL + '/setAccountPassword', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json' }
        })
            .then(response => response.json())
            .then(data => {
                message = { message: 'success' }
            })
            .catch(error => {
                message = { message: error.message }
            });

        return message
    }
    catch (error) {
        return { message: error.messsage }
    }
}

/*************************************************************************
GET SUBACCOUNTS LIST
*************************************************************************/
const getAllSubAccountsList = async (data) => {
    try {
        var message = '';
        let jsonmap = JSON.stringify(data);
        // GET CURRENT LOGGED IN USER AUTH TOKEN
        let firebaseToken = await Functions.getIdToken()
        // CALL API TO SET PASSWORD
        await fetch(APIURL + '/getSubAccountsList', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
            .then(response => response.json())
            .then(data => {
                message = { status: 1, message: 'success', data: data.data, count: data.count }
            })
            .catch(error => {
                message = { status: 0, message: error.message }
            });
        return message
    }
    catch (error) {
        return { status: 0, message: error.messsage }
    }
}

/*************************************************************************
CHECK IF ANY SUBACCOUNT IS GREATER THAN OR EQUAL TO 16 YEARS THEN FORCE MAIN USER TO ADD HIS EMAIL AND PHONE
*************************************************************************/
const isSubAccountGreaterThan16 = (data) => dispatch => {
    data.map(account => {
        if (moment().diff(moment(account.dob), 'years') >= 16 && !account.isMainAccount) {
            dispatch(SubAccountsActions.removeSubAccount(account))
        }
        return ''
    })
    return ''
}

/*************************************************************************
REMOVE SUB ACCOUNT
*************************************************************************/
const removeSubAccount = () => dispatch => {
    dispatch(SubAccountsActions.removeSubAccount(null))
    return ''
}

/*************************************************************************
UPDATE SUBACCOUNT CONTACT INFO
*************************************************************************/
const updateSubAccoUntContactInfo = async (data) => {
    try {
        var message = '';
        let jsonmap = JSON.stringify(data);
        // GET CURRENT LOGGED IN USER AUTH TOKEN
        let firebaseToken = await Functions.getIdToken()
        // CALL API TO SET PASSWORD
        await fetch(APIURL + '/updateSubAccoUntContactInfo', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
            .then(response => response.json())
            .then(data => {
                message = { status: data.status, message: data.message }
            })
            .catch(error => {
                message = { status: 0, message: error.message }
            });
        return message
    }
    catch (error) {
        return { status: 0, message: error.messsage }
    }
}
export {
    switchToOtherAccount,
    addSubAccount,
    setSubAccountPassword,
    getAllSubAccountsList,
    isSubAccountGreaterThan16,
    updateSubAccoUntContactInfo,
    removeSubAccount
}