import React from 'react'
import { connect } from 'react-redux'
import ChatApp from './ChatApp'
import VideoApp from './VideoApp'
import MobileVideoApp from '../mobileChat/VideoApp'
// import ZoomVideo from './ZoomVideoApp'
import Progress from './progress'
import { isSupported } from "twilio-video"
import '@progress/kendo-theme-material/dist/all.css'
import withStyles from '@material-ui/styles/withStyles'
import { Button, Grid, TextField, CircularProgress, Box } from '@material-ui/core';
import { Modal, Alert } from 'antd'
import { getEMRData } from '../../store/actions/EMRAction'
import { isMobileOnly, isTablet } from "react-device-detect"
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import VideocamIcon from '@material-ui/icons/Videocam'
import CallIcon from '@material-ui/icons/Call'
import cookie from 'react-cookies'
import { updateAppointmentStatus, getAppointmentDetail, updateRhynoGoStatus, checkRhynoGoStatus, updateRhynoGoTime } from '../../store/actions/appointmentAction'
import AddBilling from '../billing/AddBilling'
import NotesReqs from '../RhynoGoEMR/NotesReqs'
import Rating from '@material-ui/lab/Rating'
import Functions from '../../assets/js/function'
import RhynoGoCalendar from '../appointment/rhynogocalendar'
import styled from 'styled-components';

const StyledModal = styled(Modal)`
.ant-modal-title {
    color: white;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
}
.ant-modal-header{
    background: rgb(0,178,181);
    border-radius: 9px 9px 0 0;
}
.ant-modal-body{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}
.ant-modal-content{
    border-radius: 9px;
}
.ant-modal-close-x{
    color:white;
}
.ant-modal-footer{
    text-align:center;
    border-top:none;
}
`;



const styles = theme => ({
  parent: {
    marginBottom: theme.spacing(2),
    alignItems: 'flex-start',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: 'calc(100% + 48px)',
      margin: '6px -24px 0px',
      display: 'flex',
      position: 'relative',
      alignItems: 'flex-start',
      justifyContent: 'space-around',
    }
  },
  videoSection: {
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      left: '0%',
      top: '19%'
    }
  },
  child: {
    width: '100%'
  },
  chatButtonsList: {
    position: 'absolute',
    top: theme.spacing(1),
    right: '20px',
    zIndex: '9999',
    [theme.breakpoints.down('sm')]: {
      position: 'unset'
    }
  },
  fullScreenText: {
    color: '#0bbec1',
    fontWeight: 700,
    textAlign: 'center',
    width: '100%',
    background: '#ededed',
    padding: '8px',
    borderRadius: '10px'
  },
  chatButtons: {
    color: '#fff',
    fontWeight: 600,
  },
  endRoomButtons: {
    textTransform: 'none',
    fontSize: '1.1rem',
    marginTop: theme.spacing(2)
  },
  rhynoGoButtons: {
    width: '51%',
    fontSize: '1.1rem',
    marginTop: theme.spacing(2),
    backgroundColor: '#795bb2',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#795bb2',
    }
  },
  speakButtons: {
    width: '100%',
    fontSize: '1.1rem',
    textTransform: 'none',
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    }
  }
})

const labels = {
  0.5: 'Useless',
  1: 'Useless+',
  1.5: 'Poor',
  2: 'Poor+',
  2.5: 'Ok',
  3: 'Ok+',
  3.5: 'Good',
  4: 'Good+',
  4.5: 'Excellent',
  5: 'Excellent+',
};
class chat extends React.Component {
  constructor(props) {
    super(props);
    let doctorName = cookie.load('doctorName')
    if (props.account.data)
      this.state = {
        doctorName: doctorName,
        doctorId: this.props.match.params.doctorId,
        userId: this.props.match.params.userId,
        username: props.account.data.fullName,
        appointmentId: this.props.match.params.appointmentId,
        redirectToDashboard: false,
        showVideo: false,
        isBrowserSupported: isSupported,
        roomMembersCount: false,
        showRejectionModel: false,
        showEndRoomModel: false,
        ratingvalue: 4.5,
        speakOption: '',
        phoneNumber: '',
        getSpeakOption: false,
        billingSaved: false,
        mobileOpenedDiv: 'Chat',
        getAppointmentDetailResult: {},
        isRhynoGoAppointment: false,
        isAppointmentCompleted: false,
        rhynoGoAppointmentStatus: 0,
        showDr: true,
        rhynoGoUpdated: false,
        doctorEndSession: false,
        channelRemovedUserData: {},
        saveChatLoader: false,
        showNotesModal: false,
        meetingId: '',
        TwillioConverSationId:'',
        patientName:'not-available',
        doctorName:'not-available'
      };

    if (props.account.data.role === "Doctor") {
      this.props.getEMRData({ doctorId: this.props.match.params.doctorId, userId: this.props.match.params.userId })
    }
  }

  saveRating = () => {
    this.setState({ loading: true })
    Functions.saveDoctorRating(JSON.stringify({ doctorId: this.state.doctorId, rating: this.state.ratingvalue }))
    setTimeout(() => { this.setState({ redirectToDashboard: true }) }, 2000)
  }

  startVideoCall = () => {
    console.log(this.state.getAppointmentDetailResult)
    if (this.state.getAppointmentDetailResult.data.appointment.joinUrl && (isMobileOnly || isTablet)) {
      window.open(this.state.getAppointmentDetailResult.data.appointment.joinUrl, '_blank');

    } else {
      //if (isSupported) {
      if (!this.state.showVideo && isMobileOnly) this.hideBoxes('Video')
      this.setState({ showVideo: !this.state.showVideo })
      //}
      //else
      //this.onChangeBrowserSupport()
    }
  }
  async componentDidMount() {
     
    this.props.setTotalCalls(0)
    // do {
      let result = await this.props.getAppointmentDetail(JSON.stringify({ appointmentId: this.props.match.params.appointmentId }))
      // console.log("result ",result)
      // meetingId = result.data.appointment.meetingId
    //  if (meetingId) {
        this.setState({
          patientName:result?.data?.appointment?.patientName || '',
          doctorName:result?.data?.appointment?.doctorName || '',
          TwillioConverSationId:result.data.appointment.TwillioConverSationId || '',
          getAppointmentDetailResult: result, isAppointmentCompleted: result.data.appointment.status === 'completed' ? true : false, showDr: result.data.appointment.showDr, isRhynoGoAppointment: result.data.appointment.isRhynoGoAppointment })

        // SKYPE CONVERSATION
        // G-Man, 7:50 AM
        // @Muhammad When patient clicks on video option can you immediately display the video? Currently patient has to click on video icon to show the video conference but that is an extra step. Also, please change the message to remove 'click on the video account...'
        // IF SPEAK OPTION IS VIDEO THEN OPEN THE ZOOM MEETING
        if ( this.props.account.data.role === "Doctor")
          this.callChatChild.updateMeetingId(this.props.account.data.role)
     // }

    //   setTimeout(() => {
    //   }, 3000)
    // } while (meetingId === '');
  }

  onChangeBrowserSupport = () => {
    this.setState({ isBrowserSupported: !this.state.isBrowserSupported })
  }
  updateRoomMembersCount = (count) => {
    if (count === 2) {
      this.setState({ roomMembersCount: true })
      Functions.updateAppointmentTemporaryStatus(JSON.stringify({ appointmentId: this.props.match.params.appointmentId }))
    }
  }
  endSession = () => {
    confirmAlert({
      title: 'Confirm',
      message: 'Are you sure to end the chat room session?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            if (!this.state.isAppointmentCompleted) {
              if (!this.state.roomMembersCount) {
                this.setState({ showRejectionModel: !this.state.showRejectionModel })
              } else {
                this.setState({ showEndRoomModel: !this.state.showEndRoomModel })
                if (this.props.account.data.role === "Patient") {
                  if (this.state.isRhynoGoAppointment)
                    this.checkRhynoGoStatus()
                  else
                    this.redirectToDashboardFunc()
                }
                else {
                  this.removeDoctorFromChat()
                }
              }
            }
            else {
              if (this.props.account.data.role !== "Patient") {
                await this.removeDoctorFromChat()
                this.redirectToDashboardFunc()
              }
              else
                this.redirectToDashboardFunc()
            }
          }
        },
        {
          label: 'No',
          onClick: () => {
          }
        }
      ]
    });
  }
  billingSaved = (redirect) => {
    if (redirect)
      this.setState({ billingSaved: true, showEndRoomModel: false })
    else
      this.setState({ rhynoGoUpdated: true, showEndRoomModel: false })
  }
  onCancel = () => {
    this.setState({ showRejectionModel: !this.state.showRejectionModel })
  }
  updateSpeakOption = (option) => {
    if (option === 'video') {
      // this.callChatChild.sendSystemMessage('Patient has requested a Video Chat appointment. Please click on the video icon at the bottom right to start the video chat.')
      this.callChatChild.sendSystemMessage('Patient has requested a Video Chat appointment.')
      // SKYPE CONVERSATION
      // G-Man, 7:50 AM
      // @Muhammad When patient clicks on video option can you immediately display the video? Currently patient has to click on video icon to show the video conference but that is an extra step. Also, please change the message to remove 'click on the video account...'
      // IF SPEAK OPTION IS VIDEO THEN OPEN THE ZOOM MEETING
      // console.log("this.state.meetingId",this.state.meetingId)
      // if (this.state.meetingId !== '')
          this.callChatChild.updateMeetingId( this.props.account.data.role)
    }
    this.setState({ speakOption: option })
  }
  updateGetSpeakOption = () => {
    this.setState({ getSpeakOption: true })
  }
  updateSpeakNumber = () => {
    if (this.state.phoneNumber !== '') {
      this.callChatChild.sendSystemMessage('Patient has requested a Voice Chat at ' + this.state.phoneNumber)
      this.setState({ checkphoneNumber: true })
    }
  }
  cancelAppointment = async (option) => {
    let cancelledBy = 'Patient'
    if (this.props.account.data.role === 'Doctor')
      cancelledBy = 'Doctor'
    let dataObject = {
      id: this.state.appointmentId,
      status: 'cancelled',
      doctorId: this.state.doctorId,
      userId: this.state.userId,
      cancelledBy: cancelledBy,
      cancelOption: option,
      userName: this.props.account.data.role !== 'Doctor' ? this.state.userName : this.state.doctorName
    }
    await this.props.updateAppointmentStatus(JSON.stringify(dataObject))
    this.setState({ redirectToDashboard: true })
  }
  redirectToDashboardFunc = () => {
    this.setState({ redirectToDashboard: true })
  }
  openDocumentSection = () => {
    this.setState({ showNotesModal: !this.state.showNotesModal })
  }
  hideBoxes = (section) => {
    this.setState({ mobileOpenedDiv: section })
  }
  updateRhynoGoAppointment = async (status) => {
    await this.props.updateRhynoGoStatus(JSON.stringify({ status: status, appointmentId: this.state.appointmentId }))
    this.setState({ isRhynoGoAppointment: false })
  }
  updateRhynoGoTime = async (time) => {
    let outTime = 1
    let slotDate = new Date(time * 1000)
    if (slotDate.getHours() > 7 && slotDate.getHours() < 19)
      outTime = 0

    await this.props.updateRhynoGoTime(JSON.stringify({ outTime: outTime, time: time, appointmentId: this.state.appointmentId, active_subscription_id: this.props.account.data.active_subscription_id }))
    this.setState({ isRhynoGoAppointment: false })
    this.redirectToDashboardFunc()
  }
  checkRhynoGoStatus = async () => {
    let result = await this.props.checkRhynoGoStatus(JSON.stringify({ appointmentId: this.state.appointmentId }))
 
    this.setState({ rhynoGoAppointmentStatus: result.appointmentStatus })
    if (result.appointmentStatus === 0) {
      setTimeout(() => {
        this.checkRhynoGoStatus()
      }, 5000)
    }
  }

  updateDataForChannelMember = (channel_member_data) => {
    this.setState({ channelRemovedUserData: channel_member_data })
  }

  removeDoctorFromChat = async () => {
    await Functions.removeDoctorFromChat(JSON.stringify(this.state.channelRemovedUserData))
  }

  endPatientSession = () => {
    if (this.props.account.data.role === "Patient") {
      if (this.state.isRhynoGoAppointment && !this.state.isAppointmentCompleted) {
        this.setState({ showEndRoomModel: !this.state.showEndRoomModel })
        this.checkRhynoGoStatus()
      }
      else
        this.setState({ doctorEndSession: true })
    }
  }

  saveChatAttachments = async () => {
    this.setState({ saveChatLoader: true })
    this.callChatChild.updateAttachments()
  }

  updateAppointmentAttachments = async (list) => {
    await Functions.saveAttachmentToPatientDocuments(this.state.userId, this.state.appointmentId, list)
    this.setState({ saveChatLoader: false })
  }
  render() {
    const { classes, account, auth } = this.props
    if (!auth.uid) {
      // return <Redirect to="/signin" />;
      window.location.href = "/signin"
    }
    if (!auth.isVerified) {
      // return <Redirect to="/verify" />;
      window.location.href = "/verify"
    }
    if (this.state.redirectToDashboard === true) {
      // return <Redirect to="/dashboard" />;
      window.location.href = "/dashboard"
    }

    return (
      <div className="container">
        <div className={classes.chatButtonsList}>
          {account.data.role === 'Doctor' ?
            <>
              <Button className={classes.chatButtons} onClick={this.saveChatAttachments} style={{ top: '.5rem', backgroundColor: '#795bb2', marginRight: '20px' }}>{this.state.saveChatLoader ? <CircularProgress size={24} /> : "SAVE ATTACHMENTS"}</Button>
              <Button className={classes.chatButtons} onClick={this.openDocumentSection} style={{ top: '.5rem', backgroundColor: '#795bb2', marginRight: '20px' }}>SEND DOCUMENT</Button>
            </>
            : ""}
          <Button className={classes.chatButtons} style={{
            top: '.5rem',
            backgroundColor: '#bd1111'
          }}
            onClick={this.endSession}>End Session</Button>
        </div>
        <div>
          <div className={classes.parent}>
            <Grid xs={12} sm={account.data.role === 'Doctor' ? this.state.showVideo ? 3 : 6 : this.state.showVideo ? 6 : 12} style={{ display: isMobileOnly ? this.state.mobileOpenedDiv === 'Chat' ? "block" : "none" : "" }} className={classes.child}>
              <ChatApp 
              // TwillioConverSationId={this.state.TwillioConverSationId || 'not found'}
              // AppointmentDetail={this.props.getAppointmentDetail(JSON.stringify({ appointmentId: this.props.match.params.appointmentId }))}
               role={account.data.role} 
               updateAppointmentAttachments={this.updateAppointmentAttachments} 
               endPatientSession={this.endPatientSession} 
               updateDataForChannelMember={this.updateDataForChannelMember} 
               videoStatus={this.state.showVideo} 
               onRef={ref => (this.callChatChild = ref)} 
               username={this.state.username}
                hideBoxes={this.hideBoxes}
                 updateGetSpeakOption={this.updateGetSpeakOption} 
                 updateRoomMembersCount={this.updateRoomMembersCount}
                  startVideoCall={this.startVideoCall} 
                  doctorId={this.state.doctorId}
                  patientName={this.state.patientName}
                  doctorName={this.state.doctorName}
                   userId={this.state.userId} 
                   AppointmentId={this.props.match.params.appointmentId} />
            </Grid>
            {this.state.showVideo === true ?
              <Grid xs={12} sm={6} style={{ display: isMobileOnly ? this.state.mobileOpenedDiv === 'Video' ? "block" : "none" : "" }} className={classes.child + ' ' + classes.videoSection}>
                {!(isMobileOnly || isTablet) ?
                 // <ZoomVideo  username={this.state.username} meetingId={this.state.getAppointmentDetailResult.data.appointment.meetingId} passcode={this.state.getAppointmentDetailResult.data.appointment.passcode} />
                 <VideoApp username={this.state.username} startVideoCall={this.startVideoCall} doctorId={this.state.doctorId} userId={this.state.userId} AppointmentId={this.props.match.params.appointmentId} /> 
                 :
                  !isMobileOnly ?
                    <VideoApp username={this.state.username} startVideoCall={this.startVideoCall} doctorId={this.state.doctorId} userId={this.state.userId} AppointmentId={this.props.match.params.appointmentId} />
                    :
                    <MobileVideoApp
                      role={account.data.role}
                      hideBoxes={this.hideBoxes}
                      username={this.state.username}
                      doctorId={this.state.doctorId}
                      userId={this.state.userId}
                      startVideoCall={this.startVideoCall}
                      AppointmentId={this.props.match.params.appointmentId} />
                }
              </Grid>
              : ""}
            {account.data.role === 'Doctor' ?
              <Grid xs={12} sm={this.state.showVideo ? 3 : 6} className={classes.child} style={{ display: isMobileOnly ? this.state.mobileOpenedDiv === 'Progress' ? "block" : "none" : "" }}>
                <Progress role={account.data.role} videoStatus={this.state.showVideo} hideBoxes={this.hideBoxes} doctorId={this.state.doctorId} userId={this.state.userId} />
                {this.state.showNotesModal ?
                  <NotesReqs type={'notes'} closeDocumentSection={this.openDocumentSection} patientName={this.state.username} isRhynoGoPatient={1} patientId={this.state.userId} doctorId={this.state.doctorId} appointmentId={this.state.appointmentId} />
                  :
                  ""
                }
              </Grid>
              : ""}
          </div>
        </div>


        {/* VIDEO CHAT NOT SUPPORTED BY BROWSER MODEL */}
        <Modal
          title={"Browser Not Supported"}
          visible={!this.state.isBrowserSupported}
          width={400}
          onCancel={this.onChangeBrowserSupport}
          footer={
            <Grid>
              <Grid>
                <Button color="primary" variant="contained" onClick={this.onChangeBrowserSupport}>Ok</Button>
              </Grid>
            </Grid>
          }
        >
          <p>Your browser does not support Video and Audio Calls, please update to latest versions of Microsoft Edge, Google Chrome, Safari or Opera</p>
        </Modal>



        {/* VIDEO CHAT END SESSION MODEL WHEN ANY OF THE PARTY NOT JOINING THE ROOM*/}
        <StyledModal
          title={"End Chat Room"}
          visible={this.state.showRejectionModel}
          width={460}
          onCancel={this.onCancel}
          footer={
            <Grid>
              <Grid>
                <Button color="primary" variant="contained" onClick={this.onCancel}>Cancel</Button>
              </Grid>
            </Grid>
          }
        >
          <div style={{ textAlign: 'center' }}>
            {this.props.account.data.role === 'Doctor' ?
              <h2 style={{ fontWeight: 600 }}>We noticed that your patient is yet to join the appointment. Why do you wish to cancel?</h2>
              :
              <h2 style={{ fontWeight: 600 }}>We noticed that {this.state.showDr && "Dr."} {this.state.doctorName}{!this.state.showDr && ", NP"} is yet to join the appointment. Why do you wish to cancel?</h2>
            }
            <Button variant="outlined" className={classes.endRoomButtons} onClick={() => this.cancelAppointment(1)}>I have an emergency and have to go</Button>
            <Button variant="outlined" className={classes.endRoomButtons} onClick={() => this.cancelAppointment(2)}>I am unable to wait for the {this.props.account.data.role === 'Doctor' ? 'patient' : 'provider'} any longer</Button>
          </div>
        </StyledModal>

        {/* 
        SKYPE CONVERSATION
        G-Man, 7:50 AM
        @Muhammad When patient clicks on video option can you immediately display the video? Currently patient has to click on video icon to show the video conference but that is an extra step. Also, please change the message to remove 'click on the video account...'
        IF SPEAK OPTION IS VIDEO AND MEETING IS NOT SETUP THEN SHOW THIS MODAL
        */}
        {/* PREPARING FOR ZOOM MEETING TO CREATE*/}
        {/* <StyledModal
          title={"Zoom Meeting"}
          visible={this.state.meetingId === '' && this.state.speakOption === 'video'}
          width={460}
          footer={null}
        >
          <div style={{ textAlign: 'center' }}>
            <h2 style={{ fontWeight: 600 }}>Please wait, Video session is being prepared</h2>
          </div>
        </StyledModal> */}



        {/* VIDEO/AUDIO CHAT OPTION MODEL */}
        <StyledModal
          title={"Voice or Video"}
          visible={this.state.speakOption === '' && account.data.role !== 'Doctor' && this.state.getSpeakOption ? true : false}
          width={450}
          onCancel=''
          footer={<Grid></Grid>}
        >
          <div style={{ textAlign: 'center' }}>
            <h2 style={{ fontWeight: 600 }}>How would you like to speak to {this.state.showDr && "Dr."} {this.state.doctorName}{!this.state.showDr && ", NP"}?</h2>
            <Button variant="outlined" className={classes.speakButtons} onClick={() => this.updateSpeakOption('audio')}><CallIcon style={{ marginRight: '16px' }} /> VOICE CHAT</Button>
            <Button variant="outlined" className={classes.speakButtons} onClick={() => this.updateSpeakOption('video')}><VideocamIcon style={{ marginRight: '16px' }} /> VIDEO CHAT</Button>
          </div>
        </StyledModal>


        {/* CHAT ROOM ENDED BY DOCTOR MODEL */}
        <Modal
          title={"End Chat Room"}
          visible={account.data.role === 'Doctor' && this.state.showEndRoomModel ? true : false}
          width={650}
          onCancel={null}
          closable={false}
          className={"ModelToHideFooter"}
          footer={<Grid></Grid>}
        >
          {this.state.isRhynoGoAppointment ?
            <div style={{ textAlign: 'center' }}>
              <h2 style={{ fontWeight: 600 }}>Would you like to confirm the patient's RhynoGo appointment?</h2>
              <Button variant="outlined" className={classes.rhynoGoButtons} onClick={() => this.updateRhynoGoAppointment('yes')}> Yes</Button>
              <Button variant="outlined" className={classes.rhynoGoButtons} onClick={() => this.updateRhynoGoAppointment('no')}> No</Button>
            </div>
            :
            <AddBilling appointmentId={this.state.appointmentId} billingSaved={this.billingSaved} getAppointmentDetailResult={this.state.getAppointmentDetailResult} isNewAppointment={true} />
          }
        </Modal>



        {/* CHAT ROOM ENDED BY PATIENT MODEL */}
        <Modal
          title={"End Chat Room"}
          visible={account.data.role !== 'Doctor' && this.state.showEndRoomModel && !this.state.isRhynoGoAppointment ? true : false}
          width={450}
          onCancel=''
          footer={<Grid>
            {this.state.loading ?
              <CircularProgress size={24} /> :
              <Grid>
                <Button color="secondary" variant="contained" onClick={this.saveRating}>Save</Button>
              </Grid>
            }
          </Grid>}
        >
          <p className={classes.rating}>Please Rate The Doctor</p>
          {this.state.loading ? <Alert type="success" message={"Rating added successfully, you are now redirected to dashboard"}></Alert> : ""}
          <Grid className={classes.ratingBox}>
            <Rating
              name="hover-feedback"
              value={this.state.ratingvalue}
              precision={0.5}
              onChange={(event, newValue) => {
                this.setState({ ratingvalue: newValue })
              }}
            />
            <Box className={classes.rating} ml={2}>{labels[this.state.ratingvalue]}</Box>
          </Grid>
        </Modal>

        {/* CHAT ROOM ENDED BY PATIENT MODEL */}
        <Modal
          title={"End Chat Room"}
          visible={account.data.role !== 'Doctor' && this.state.showEndRoomModel && this.state.isRhynoGoAppointment ? true : false}
          width={this.state.rhynoGoAppointmentStatus === 1 ? 1000 : 450}
          onCancel=''
          footer={<Grid></Grid>}
        >
          {this.state.rhynoGoAppointmentStatus === 0 ?
            <h2 style={{ fontWeight: 600 }}>Please wait while the provider approves your RhynoGo appointment</h2>
            :
            this.state.rhynoGoAppointmentStatus === 2 ?
              <h2 style={{ fontWeight: 600 }}>Unfortunately, your provider has canceled the RhynoGo appointment</h2>
              :
              <RhynoGoCalendar updateRhynoGoTime={this.updateRhynoGoTime} appointmenttype={"rhynoGo"} />
          }

        </Modal>

        {/* BILLING SAVED MODEL */}
        <Modal
          title={"Billing Saved"}
          visible={this.state.billingSaved ? true : false}
          width={450}
          onCancel=''
          footer={<Grid>
            <Grid>
              <Button color="primary" variant="contained" onClick={this.redirectToDashboardFunc}>Ok</Button>
            </Grid>
          </Grid>}
        >
          <p>Billing Saved Successfully</p>
        </Modal>

        {/* DOCTOR SESSION ENDED MODEL */}
        <Modal
          title={"Provider End Session"}
          visible={this.state.doctorEndSession}
          width={450}
          onCancel=''
          footer={<Grid>
            <Grid>
              <Button color="primary" variant="contained" onClick={this.redirectToDashboardFunc}>Continue</Button>
            </Grid>
          </Grid>}
        >
          <p>The provider has ended this session</p>
        </Modal>

        {/* RHYNOGO SAVED MODEL */}
        <Modal
          title={"Appointment Status Updated"}
          visible={this.state.rhynoGoUpdated ? true : false}
          width={450}
          onCancel=''
          footer={<Grid>
            <Grid>
              <Button color="primary" variant="contained" onClick={this.redirectToDashboardFunc}>Ok</Button>
            </Grid>
          </Grid>}
        >
          <p>
            {this.state.isRhynoGoAppointment ? 'RhynoGo Appointment status is updated successfully. Now you are redirected to dashboard.' : 'Appointment completed successfully'}
          </p>
        </Modal>

        {/* GET PHONE NUMBER FROM USER WHEN PATIENT WANT CALL ON NUMBER MODEL */}
        <StyledModal
          title={"Phone Number"}
          visible={this.state.speakOption === 'audio' && !this.state.checkphoneNumber ? true : false}
          width={450}
          onCancel=''
          footer={<Grid></Grid>}
        >
          <div style={{ textAlign: 'center' }}>
            <h2 style={{ fontWeight: 500 }}>What's the best phone number for {this.state.showDr && "Dr."} {this.state.doctorName}{!this.state.showDr && ", NP"} to reach you?</h2>
            <TextField
              name={"phoneNumber"}
              value={this.state.phoneNumber}
              variant={'outlined'}
              label={'Phone Number'}
              onChange={(e) => this.setState({ phoneNumber: e.target.value })}
            />
            <Button className={classes.speakButtons} onClick={() => this.updateSpeakNumber()}>Continue</Button>
          </div>
        </StyledModal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {

  return {
    auth: state.auth,
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAppointmentStatus: (data) => updateAppointmentStatus(data),
    updateRhynoGoStatus: (data) => updateRhynoGoStatus(data),
    updateRhynoGoTime: (data) => updateRhynoGoTime(data),
    checkRhynoGoStatus: (data) => checkRhynoGoStatus(data),
    getEMRData: (data) => dispatch(getEMRData(data)),
    getAppointmentDetail: (data) => getAppointmentDetail(data)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(chat));