import React from 'react'
import PropTypes from 'prop-types'
import { Button, Grid } from '@material-ui/core'
import ModalStyle from '../../modelStyle'

function GetRequisitionType({ changeRequisition }) {
    const styles = {
        urgent: {
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            width: '250px',
            height: '100px',
            borderRadius: '20px',
            backgroundColor: 'rgb(255 221 160)',
            '&:hover': {
                cursor: 'pointer',
                background: 'rgb(255 198 97)',
            }
        },
        regular: {
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            width: '250px',
            height: '100px',
            borderRadius: '20px',
            backgroundColor: 'rgb(255,234,234)',
            '&:hover': {
                cursor: 'pointer',
                background: 'rgb(251 174 174)',
            }
        },
        component: {
            padding: '45px 0 0',
        },
        buttons: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '20px'
        }
    }
    return (
        <div>
            <ModalStyle
                className="modal"
                title={null}
                closable={false}
                visible={true}
                width={700}
                zIndex={"2000"}
                footer={<></>}
            >
                <Grid style={styles.component}>
                    <h3 className="w-100">Please Select Requisition Type</h3>
                    <Grid style={styles.buttons}>
                        <Button
                            style={styles.urgent}
                            onClick={() => {
                                changeRequisition('urgent PCR')
                            }}
                        >Stat PCR</Button>
                        <Button
                            style={styles.regular}
                            onClick={() => {
                                changeRequisition('regular PCR')
                            }}
                        >Routine PCR</Button>
                    </Grid>
                </Grid>
            </ModalStyle>
        </div >
    )
}

GetRequisitionType.propTypes = {
    changeRequisition: PropTypes.func
}

export default GetRequisitionType

