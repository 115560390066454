import React from 'react'
import { Modal } from 'antd'
import styled from 'styled-components'
import MedicalForm from './MedicalForm'
import SocialForm from './SocialForm'
import AllergyForm from './AllergyForm'
import MedicationForm from './Medication/MedicationForm'
import ProgressTemplateForm from './ProgressTemplateForm'
import NotesForm from './Notes/NotesForm'
import ChatForm from './Chats/ChatForm'

const StyledModal = styled(Modal)`
.ant-modal-title {
    color: white;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
}
.ant-modal-header{
    background: rgb(0,178,181);
    border-radius: 9px 9px 0 0;
}
.ant-modal-body{
    justify-content: space-between
}
.ant-modal-content{
    border-radius: 9px;
}
.ant-modal-close-x{
    color:white;
}
.ant-modal-footer{
    text-align:center;
}
.modal-body{
    display:flex;
    justify-content:space-between;
}
`;

function SectionModal({ patientName, isRhynoGoPatient, type, onCloseModel, id, row, data }) {

    const label = {
        "notes": "Add a Notes/Reqs/Rx/Referrals",
        "templates": "Add Progress Note Templates",
        "medical": "Add Medical History",
        "social": "Add Social History",
        "medication": "Add Medication History",
        "allergy": "Add Allergies History",
        "chat": "Add Progress Note",
    }

    return (
        <StyledModal
            title={label[type]}
            className='modal'
            visible={true}
            width={type === 'notes' ? 500 : 570}
            onCancel={onCloseModel}
            footer={null}
        >
            {
                type === 'medical' ? <MedicalForm patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} data={data} onCloseModel={onCloseModel} row={row} id={id} />
                    :
                    type === 'templates' ? <ProgressTemplateForm patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} data={data} onCloseModel={onCloseModel} row={row} id={id} />
                        :
                        type === 'social' ? <SocialForm patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} data={data} onCloseModel={onCloseModel} row={row} id={id} />
                            :
                            type === 'allergy' ? <AllergyForm patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} data={data} onCloseModel={onCloseModel} row={row} id={id} />
                                :
                                type === 'medication' ? <MedicationForm patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} data={data} onCloseModel={onCloseModel} row={row} id={id} />
                                    :
                                    type === 'notes' ? <NotesForm patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} data={data} onCloseModel={onCloseModel} />
                                        :
                                        type === 'chat' ? <ChatForm patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} data={data} onCloseModel={onCloseModel} />
                                            :
                                            ""
            }
        </StyledModal>
    )
}

export default SectionModal
