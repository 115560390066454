import React, { Component } from 'react'
import withStyles from '@material-ui/styles/withStyles'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import { TextField, Checkbox, Typography, FormControlLabel, Grid, Radio } from '@material-ui/core'

import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers'

import moment from 'moment'

const ExpansionPanel = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column'
    },
}))(MuiExpansionPanelDetails);




const styles = theme => ({
    gridBorder: {
        borderBottom: '1px solid #dfdfdf'
    },
    input: {
        margin: '3px 3px',
        width: '100px'
    },
    input1: {
        margin: '3px 0px 3px 3px',
        width: '180px'
    },
    hr: {
        border: '1px solid grey',
        width: '1px',
        margin: '5px'
    },
    hepatitis: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    psa: {
        display: 'flex',
        justifyContent: 'space-between'
    }
})


class RequisitionForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            expandedItem: false,
            form: {
                doctor_name: '',
                license_number: '',
                clinic_name: 'RHYNOCARE INC',
                doctor_address: '',
                doctor_zip: '',
                doctor_phone: '',
                doctor_fax: '',
                cc: false,
                other_provider_name: '',
                other_provider_license: '',
                other_provider_clinic: '',
                other_provider_address: '',
                other_provider_zip: '',
                other_provider_phone: '',
                other_provider_fax: '',
                health_card: '',
                last_name: '',
                first_name: '',
                date_of_birth: null,
                sex: { radio: "" },
                address: '',
                zip: '',
                phone: '',
                travel_to: '',
                travel_date: null,
                return_date: null,
                exposure: { radio: '' },
                exposure_details: '',
                exposure_history: null,
                vaccination_status: { radio: '' },
                asymptomatic: false,
                fever: false,
                pregnant: false,
                symptomatic: false,
                pneumonia: false,
                other: false,
                cough: false,
                throat: false,
                symptom_onset: null,
                specify: '',
            }

        }
    }

    addMore = () => {
        this.setState({ noOftests: parseInt(this.state.noOftests) + 1 })
    }

    handlePanelChange = (panel) => (event, newExpanded) => {
        this.setState({ expandedItem: newExpanded ? panel : false })
    }

    handleCheckBoxChange = (e) => {
        let formData = this.state.form
        if (formData[e.currentTarget.name].hasOwnProperty('radio'))
            formData[e.currentTarget.name] = { radio: e.target.value }
        else
            formData[e.currentTarget.name] = !this.state.form[e.currentTarget.name]
        this.setState({ form: formData })
        this.props.updateRequisitionData(formData)
    }
    handleInputBoxChange = (e) => {
        let formData = this.state.form
        formData[e.currentTarget.name] = e.currentTarget.value
        this.props.updateRequisitionData(formData)
        this.setState({ form: formData })
    }

    currentDateChange = async (currentDate, name) => {
        if (currentDate !== 0) {
            let formData = this.state.form
            formData[name] = moment(currentDate).format('YYYY/MM/DD')
            this.setState({ form: formData })
            this.props.updateRequisitionData(formData)
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <ExpansionPanel square expanded={this.state.expandedItem === 'panel1'} onChange={this.handlePanelChange('panel1')}>
                    <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>Submitter Lab</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid className={classes.gridBorder}>

                            <TextField
                                key={'doctor_name'}
                                name={"doctor_name"}
                                value={this.state.form["doctor_name"]}
                                onChange={this.handleInputBoxChange}
                                onBlur={this.handleInputBoxChange}
                                style={{ marginBottom: '10px' }}
                                label={"First Name"}
                            />

                            <TextField
                                key={'license_number'}
                                name={"license_number"}
                                value={this.state.form["license_number"]}
                                onChange={this.handleInputBoxChange}
                                onBlur={this.handleInputBoxChange}
                                style={{ marginBottom: '10px' }}
                                label={"License No"}
                            />

                            <TextField
                                key={'clinic_name'}
                                name={"clinic_name"}
                                value={this.state.form["clinic_name"]}
                                onChange={this.handleInputBoxChange}
                                onBlur={this.handleInputBoxChange}
                                style={{ marginBottom: '10px' }}
                                label={"Clinic Name"}
                            />

                            <TextField
                                key={'doctor_address'}
                                name={"doctor_address"}
                                value={this.state.form["doctor_address"]}
                                onChange={this.handleInputBoxChange}
                                onBlur={this.handleInputBoxChange}
                                style={{ marginBottom: '10px' }}
                                label={"Address"}
                            />

                            <TextField
                                key={'doctor_zip'}
                                name={"doctor_zip"}
                                value={this.state.form["doctor_zip"]}
                                onChange={this.handleInputBoxChange}
                                onBlur={this.handleInputBoxChange}
                                style={{ marginBottom: '10px' }}
                                label={"Postal Code"}
                            />

                            <TextField
                                key={'doctor_phone'}
                                name={"doctor_phone"}
                                value={this.state.form["doctor_phone"]}
                                onChange={this.handleInputBoxChange}
                                onBlur={this.handleInputBoxChange}
                                style={{ marginBottom: '10px' }}
                                label={"Phone"}
                            />

                            <TextField
                                key={'doctor_fax'}
                                name={"doctor_fax"}
                                value={this.state.form["doctor_fax"]}
                                onChange={this.handleInputBoxChange}
                                onBlur={this.handleInputBoxChange}
                                style={{ marginBottom: '10px' }}
                                label={"Fax"}
                            />

                            <Grid>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.form.cc} onChange={this.handleCheckBoxChange} name="cc" />}
                                    label="CC to other Authorized Health Care Provider"
                                />
                            </Grid>

                            <TextField
                                disabled={!this.state.form.cc}
                                key={'other_provider_name'}
                                name={"other_provider_name"}
                                value={this.state.form["other_provider_name"]}
                                onChange={this.handleInputBoxChange}
                                onBlur={this.handleInputBoxChange}
                                style={{ marginBottom: '10px' }}
                                label={"First Name"}
                            />

                            <TextField
                                disabled={!this.state.form.cc}
                                key={'other_provider_license'}
                                name={"other_provider_license"}
                                value={this.state.form["other_provider_license"]}
                                onChange={this.handleInputBoxChange}
                                onBlur={this.handleInputBoxChange}
                                style={{ marginBottom: '10px' }}
                                label={"License No"}
                            />

                            <TextField
                                disabled={!this.state.form.cc}
                                key={'other_provider_clinic'}
                                name={"other_provider_clinic"}
                                value={this.state.form["other_provider_clinic"]}
                                onChange={this.handleInputBoxChange}
                                onBlur={this.handleInputBoxChange}
                                style={{ marginBottom: '10px' }}
                                label={"Clinic Name"}
                            />

                            <TextField
                                disabled={!this.state.form.cc}
                                key={'other_provider_address'}
                                name={"other_provider_address"}
                                value={this.state.form["other_provider_address"]}
                                onChange={this.handleInputBoxChange}
                                onBlur={this.handleInputBoxChange}
                                style={{ marginBottom: '10px' }}
                                label={"Address"}
                            />

                            <TextField
                                disabled={!this.state.form.cc}
                                key={'other_provider_zip'}
                                name={"other_provider_zip"}
                                value={this.state.form["other_provider_zip"]}
                                onChange={this.handleInputBoxChange}
                                onBlur={this.handleInputBoxChange}
                                style={{ marginBottom: '10px' }}
                                label={"Postal Code"}
                            />

                            <TextField
                                disabled={!this.state.form.cc}
                                key={'other_provider_phone'}
                                name={"other_provider_phone"}
                                value={this.state.form["other_provider_phone"]}
                                onChange={this.handleInputBoxChange}
                                onBlur={this.handleInputBoxChange}
                                style={{ marginBottom: '10px' }}
                                label={"Phone"}
                            />

                            <TextField
                                disabled={!this.state.form.cc}
                                key={'other_provider_fax'}
                                name={"other_provider_fax"}
                                value={this.state.form["other_provider_fax"]}
                                onChange={this.handleInputBoxChange}
                                onBlur={this.handleInputBoxChange}
                                style={{ marginBottom: '10px' }}
                                label={"Fax"}
                            />

                        </Grid>

                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel square expanded={this.state.expandedItem === 'panel2'} onChange={this.handlePanelChange('panel2')}>
                    <ExpansionPanelSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>Patient Information</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>

                        <TextField
                            key={'health_card'}
                            name={"health_card"}
                            value={this.state.form["health_card"]}
                            onChange={this.handleInputBoxChange}
                            onBlur={this.handleInputBoxChange}
                            style={{ marginBottom: '10px' }}
                            label={"Health Card No"}
                        />

                        <TextField
                            key={'first_name'}
                            name={"first_name"}
                            value={this.state.form["first_name"]}
                            onChange={this.handleInputBoxChange}
                            onBlur={this.handleInputBoxChange}
                            style={{ marginBottom: '10px' }}
                            label={"First Name"}
                        />

                        <TextField
                            key={'last_name'}
                            name={"last_name"}
                            value={this.state.form["last_name"]}
                            onChange={this.handleInputBoxChange}
                            onBlur={this.handleInputBoxChange}
                            style={{ marginBottom: '10px' }}
                            label={"Last Name"}
                        />
                        <h3>Date Of Birth</h3>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                variant="inline"
                                autoOk
                                label={"Date of Birth"}
                                value={this.state.form.date_of_birth ? this.state.form.date_of_birth : null}
                                onChange={(e) => this.currentDateChange(e, 'date_of_birth')}
                                format={'yyyy/MM/dd'}
                                className={classes.textField}
                                name={'date_of_birth'}
                            />
                        </MuiPickersUtilsProvider>

                        <label>Sex: </label>
                        <Grid>
                            <FormControlLabel
                                control={<Radio value="Male" checked={this.state.form.sex.radio === 'Male' ? true : false} onChange={this.handleCheckBoxChange} name="sex" />}
                                label="Male"
                            />

                            <FormControlLabel
                                control={<Radio value="Female" checked={this.state.form.sex.radio === 'Female' ? true : false} onChange={this.handleCheckBoxChange} name="sex" />}
                                label="Female"
                            />
                        </Grid>

                        <TextField
                            key={'address'}
                            name={"address"}
                            value={this.state.form["address"]}
                            onChange={this.handleInputBoxChange}
                            onBlur={this.handleInputBoxChange}
                            style={{ marginBottom: '10px' }}
                            label={"Address"}
                        />

                        <TextField
                            key={'zip'}
                            name={"zip"}
                            value={this.state.form["zip"]}
                            onChange={this.handleInputBoxChange}
                            onBlur={this.handleInputBoxChange}
                            style={{ marginBottom: '10px' }}
                            label={"Postal Code"}
                        />

                        <TextField
                            key={'phone'}
                            name={"phone"}
                            value={this.state.form["phone"]}
                            onChange={this.handleInputBoxChange}
                            onBlur={this.handleInputBoxChange}
                            style={{ marginBottom: '10px' }}
                            label={"Patient Phone No"}
                        />

                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel square expanded={this.state.expandedItem === 'panel3'} onChange={this.handlePanelChange('panel3')}>
                    <ExpansionPanelSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography>Travel History</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>

                        <TextField
                            key={'travel_to'}
                            name={"travel_to"}
                            value={this.state.form["travel_to"]}
                            onChange={this.handleInputBoxChange}
                            onBlur={this.handleInputBoxChange}
                            style={{ marginBottom: '10px' }}
                            label={"Travel To"}
                        />

                        <label>Date of Travel</label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                variant="inline"
                                autoOk
                                label={"Date of Travel"}
                                value={this.state.form.travel_date ? this.state.form.travel_date : null}
                                onChange={(e) => this.currentDateChange(e, 'travel_date')}
                                format={'yyyy/MM/dd'}
                                className={classes.textField}
                                name={'travel_date'}
                            />
                        </MuiPickersUtilsProvider>
                        <label>Date of Return</label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                variant="inline"
                                autoOk
                                label={"Date of Return "}
                                value={this.state.form.return_date ? this.state.form.return_date : null}
                                onChange={(e) => this.currentDateChange(e, 'return_date')}
                                format={'yyyy/MM/dd'}
                                className={classes.textField}
                                name={'return_date'}
                            />
                        </MuiPickersUtilsProvider>

                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel square expanded={this.state.expandedItem === 'panel4'} onChange={this.handlePanelChange('panel4')}>
                    <ExpansionPanelSummary aria-controls="panel4d-content" id="panel4d-header">
                        <Typography>Exposure History</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <label >Exposure to probable, or confirmed case?</label>

                        <Grid>
                            <FormControlLabel
                                control={<Radio value="Yes" checked={this.state.form.exposure.radio === 'Yes' ? true : false} onChange={this.handleCheckBoxChange} name="exposure" />}
                                label="Yes"
                            />

                            <FormControlLabel
                                control={<Radio value="No" checked={this.state.form.exposure.radio === 'No' ? true : false} onChange={this.handleCheckBoxChange} name="exposure" />}
                                label="No"
                            />
                        </Grid>

                        <TextField
                            key={'exposure_details'}
                            name={"exposure_details"}
                            value={this.state.form["exposure_details"]}
                            onChange={this.handleInputBoxChange}
                            onBlur={this.handleInputBoxChange}
                            style={{ marginBottom: '10px' }}
                            label={"Exposure Detail"}
                        />

                        <label>Date of symptom onset of contact (yyyy/mm/dd):</label>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                variant="inline"
                                autoOk
                                value={this.state.form.exposure_history ? this.state.form.exposure_history : null}
                                onChange={(e) => this.currentDateChange(e, 'exposure_history')}
                                format={'yyyy/MM/dd'}
                                className={classes.textField}
                                name={'exposure_history'}
                            />
                        </MuiPickersUtilsProvider>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel square expanded={this.state.expandedItem === 'panel8'} onChange={this.handlePanelChange('panel8')}>
                    <ExpansionPanelSummary aria-controls="panel8d-content" id="panel8d-header">
                        <Typography>COVID-19 Vaccination Status</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid>
                            <FormControlLabel
                                control={<Radio value="Received" checked={this.state.form.vaccination_status.radio === 'Received' ? true : false} onChange={this.handleCheckBoxChange} name="vaccination_status" />}
                                label="Received all required doses >14 days ago"
                            />

                            <FormControlLabel
                                control={<Radio value="Unimmunized" checked={this.state.form.vaccination_status.radio === 'Unimmunized' ? true : false} onChange={this.handleCheckBoxChange} name="vaccination_status" />}
                                label="Unimmunized / partial series / ≤14 days after final dose"
                            />

                            <FormControlLabel
                                control={<Radio value="Unknown" checked={this.state.form.vaccination_status.radio === 'Unknown' ? true : false} onChange={this.handleCheckBoxChange} name="vaccination_status" />}
                                label="Unknown"
                            />
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel square expanded={this.state.expandedItem === 'panel9'} onChange={this.handlePanelChange('panel9')}>
                    <ExpansionPanelSummary aria-controls="panel9d-content" id="panel9d-header">
                        <Typography>Clinical Information</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <FormControlLabel
                            control={<Checkbox checked={this.state.form.asymptomatic} onChange={this.handleCheckBoxChange} name="asymptomatic" />}
                            label="Asymptomatic"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={this.state.form.fever} onChange={this.handleCheckBoxChange} name="fever" />}
                            label="Fever"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={this.state.form.pregnant} onChange={this.handleCheckBoxChange} name="pregnant" />}
                            label="Pregnent"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={this.state.form.symptomatic} onChange={this.handleCheckBoxChange} name="symptomatic" />}
                            label="Symptomatic"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={this.state.form.pneumonia} onChange={this.handleCheckBoxChange} name="pneumonia" />}
                            label="Pneumonia"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={this.state.form.cough} onChange={this.handleCheckBoxChange} name="cough" />}
                            label="cough"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={this.state.form.throat} onChange={this.handleCheckBoxChange} name="throat" />}
                            label="Sore Throat"
                        />
                        <h3>Date of symptom onset (yyyy/mm/dd)</h3>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                variant="inline"
                                autoOk
                                value={this.state.form.symptom_onset ? this.state.form.symptom_onset : null}
                                onChange={(e) => this.currentDateChange(e, 'symptom_onset')}
                                format={'yyyy/MM/dd'}
                                className={classes.textField}
                                name={'symptom_onset'}
                            />
                        </MuiPickersUtilsProvider>

                        <FormControlLabel
                            control={<Checkbox checked={this.state.form.other} onChange={this.handleCheckBoxChange} name="other" />}
                            label="Other (Specify)"
                        />
                        <TextField
                            disabled={!this.state.form.other}
                            key={'specify'}
                            name={"specify"}
                            value={this.state.form["specify"]}
                            onChange={this.handleInputBoxChange}
                            onBlur={this.handleInputBoxChange}
                            style={{ marginBottom: '10px' }}
                            label={"Other Information"}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>

            </div>
        );
    }
}

export default withStyles(styles)(RequisitionForm)