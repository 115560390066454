// stripe.button.component.jsx
import React from 'react'
import StripeCheckout from 'react-stripe-checkout'
import { STRIPEPUBLICKEY } from '../../config'


const StripeCheckoutButton = ({ appointmentData, addAppointment }) => {
    let priceForStripeReceived = parseFloat((appointmentData.paidamount + ((appointmentData.paidamount * 13) / 100)).toFixed(2))
    const priceForStripe = Math.round(priceForStripeReceived * 100)
    let publishableKey = STRIPEPUBLICKEY;

    let testingUserIds = ['NMT7HwfacqeBG9ejgWQyGQ4ZPFZ2', 'ItqormvFw3bfjfYsNCduonl4pZj2', 'OIVzXvc9BcOD7lvBZPA4KnOnXSo2']
    if (appointmentData.hasOwnProperty('userId') && testingUserIds.indexOf(appointmentData.userId) > -1) {
        // publishableKey = STRIPEPUBLICKEYTEST
    }
    const onToken = async (token) => {
        if (token.hasOwnProperty("id")) {
            // dispatch(updateAppointment({token:token.id, amount:price, recordId: recordid}))
            appointmentData.stripetoken = token.id
            appointmentData.paidamount = priceForStripeReceived
            addAppointment(appointmentData)
        }
    };

    let descriptionText = `Paid amount: $${parseFloat(appointmentData.paidamount || 0).toFixed(2)} + HST`
    if (appointmentData.holdamount > 0)
      {   descriptionText += `, hold amount: $${parseFloat(appointmentData.holdamount).toFixed(2)}`}
        console.log('descriptionText :',descriptionText)


    return (
        <div id="stripeButton" style={{ display: 'none' }}>
            <StripeCheckout
                label='Book Now'
                name='RhynoCare'
                currency='CAD'
                allowRememberMe={false}
                // image='https://www.freakyjolly.com/wp-content/uploads/2020/04/fj-logo.png'
                description={!(appointmentData.hasOwnProperty('isSignUp') && appointmentData.isSignUp) ? descriptionText : ""}
                amount={priceForStripe}
                panelLabel={!(appointmentData.hasOwnProperty('isSignUp') && appointmentData.isSignUp) ? 'Book Now' : 'Continue'}
                token={onToken}
                // token="localhost:3002/pay"
                stripeKey={publishableKey}

            />
        </div>
    )
}
export default StripeCheckoutButton