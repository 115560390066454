import { APIURL, WEBSITEURL } from '../../config.js'
import functions from '../../assets/js/function'

export const getAppointmentsList = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data
    return fetch(APIURL + '/getAppointment', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            data: data.data,
            message: '',
            pagination_id: data.pagination_id
          }
        }
        else {
          return {
            message: data.message,
            data: '',
            pagination_id: data.pagination_id
          }
        }
      })
      .catch(error => {
        return {
          error: error.message,
          data: '',
          pagination_id: 0
        }
      });
  }
  else {
    return ''
  }
}

export const getAppointmentsDocsList = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data;
    // return fetch(APIURL + '/getAppointmentsDocsList', {
    return fetch(APIURL + '/getPatientDocHistory', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            data: data.data,
            pagination_id: data.pagination_id,
            message: '',
          }
        }
        else {
          return {
            message: data.message,
            pagination_id: data.pagination_id,
            data: '',
          }
        }
      })
      .catch(error => {
        return {
          error: error.message,
          pagination_id: 0,
          data: '',
        }
      });
  }
  else {
    return ''
  }
}

export const getNotesList = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data;
    return fetch(APIURL + '/getNotes', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            type: 'EMR_NOTES_LIST',
            data: data.data,
            pagination_id: data.pagination_id,
            message: '',
            faxUrl: ''
          }
        }
        else {
          return {
            type: 'EMR_NOTES_LIST',
            message: data.message,
            pagination_id: data.pagination_id,
            data: '',
            faxUrl: ''
          }
        }
      })
      .catch(error => {
        return {
          type: 'EMR_NOTES_LIST',
          error: error.message,
          pagination_id: 0,
          data: '',
          faxUrl: ''
        }
      });
  }
  else {
    return ''
  }
}

export const CreateNote = async (data) => {
  console.log('EMR Action')
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let response = await fetch(APIURL + '/createNote', {
      method: 'POST',
      async: false,
      body: data,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
    response = await response.json()
    if (response.status === 1) {
      return {
        type: 'EMR_NOTES_LIST',
        data: response.data,
        pagination_id: response.pagination_id,
        message: response.message,
        status: 1,
        pdfContent: response.pdfContent
      }
    }
    else {
      return {
        type: 'EMR_NOTES_ERROR',
        message: response.message,
        pagination_id: response.pagination_id,
        data: '',
        status: 0,
        pdfContent: response.pdfContent
      }
    }
  }
  else {
    return ''
  }
}
export const CreatePatientDoc = async (data) => {
  console.log('EMR Action')
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let response = await fetch(APIURL + '/CreatePatientDoc', {
      method: 'POST',
      async: false,
      body: data,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
    response = await response.json()
    if (response.status === 1) {
      return {
        data: response.data,
        pagination_id: response.pagination_id,
        message: '',
        status: 1,
        pdfContent: response.pdfContent
      }
    }
    else {
      return {
        message: response.message,
        pagination_id: response.pagination_id,
        data: '',
        status: 0,
        pdfContent: response.pdfContent
      }
    }
  }
  else {
    return ''
  }
}

export const UpdateNote = (data) => {
  return async (dispatch, getState) => {
    let firebaseToken = await functions.getIdToken()
    if (firebaseToken !== '') {
      let jsonmap = data;
      fetch(APIURL + '/updateNote', {
        method: 'POST',
        async: false,
        body: jsonmap,
        headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 1) {
            dispatch({
              type: 'EMR_NOTES_LIST',
              data: data.data,
              message: '',
              faxUrl: ''
            })
          }
          else {
            dispatch({
              type: 'EMR_NOTES_ERROR',
              message: data.message,
              data: ''
            })
          }
        })
        .catch(error => {
          dispatch({
            type: 'EMR_NOTES_ERROR',
            error: error.message,
            data: ''
          })
        });
    }
    else {
      return ''
    }
  }
}


export const DeleteNote = (data) => {
  return async (dispatch, getState) => {
    let firebaseToken = await functions.getIdToken()
    if (firebaseToken !== '') {
      let jsonmap = data;
      fetch(APIURL + '/deleteNote', {
        method: 'POST',
        async: false,
        body: jsonmap,
        headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 1) {
            dispatch({
              type: 'EMR_NOTES_LIST',
              data: data.data,
              message: '',
              faxUrl: ''
            })
          }
          else {
            dispatch({
              type: 'EMR_NOTES_ERROR',
              message: data.message,
              data: ''
            })
          }
        })
        .catch(error => {
          dispatch({
            type: 'EMR_NOTES_ERROR',
            error: error.message,
            data: ''
          })
        });
    }
    else {
      return ''
    }
  }
}


export const getMedicalHistoryList = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data
    return fetch(APIURL + '/getMedicalHistory', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            type: 'EMR_MEDICAL_HISTORY_LIST',
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            type: 'EMR_MEDICAL_HISTORY_LIST',
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          type: 'EMR_MEDICAL_HISTORY_LIST',
          error: error.message,
          pagination_id: 0,
          data: ''
        }
      });
  }
  else {
    return ''
  }
}

export const createMedicalHistory = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data;
    return fetch(APIURL + '/createMedicalHistory', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            type: 'EMR_MEDICAL_HISTORY_LIST',
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            type: 'EMR_MEDICAL_HISTORY_ERROR',
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          type: 'EMR_MEDICAL_HISTORY_ERROR',
          error: error.message,
          pagination_id: 0,
          data: ''
        }
      });
  } else {
    return ''
  }
}


export const updateMedicalHistory = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data;
    return fetch(APIURL + '/updateMedicalHistory', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            type: 'EMR_MEDICAL_HISTORY_LIST',
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            type: 'EMR_MEDICAL_HISTORY_ERROR',
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          type: 'EMR_MEDICAL_HISTORY_ERROR',
          error: error.message,
          pagination_id: 0,
          data: ''
        }
      });
  } else {
    return ''
  }
}


export const deleteMedicalHistory = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data;
    return fetch(APIURL + '/deleteMedicalHistory', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            type: 'EMR_MEDICAL_HISTORY_LIST',
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            type: 'EMR_MEDICAL_HISTORY_ERROR',
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          type: 'EMR_MEDICAL_HISTORY_ERROR',
          error: error.message,
          pagination_id: 0,
          data: ''
        }
      });
  } else {
    return ''
  }
}

export const getSocialHistoryList = async (data) => {
  let firebaseToken = await functions.getIdToken()

  if (firebaseToken !== '') {
    let jsonmap = data;
    return fetch(APIURL + '/getSocialHistory', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            type: 'EMR_SOCIAL_HISTORY_LIST',
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            type: 'EMR_SOCIAL_HISTORY_LIST',
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          type: 'EMR_SOCIAL_HISTORY_LIST',
          error: error.message,
          pagination_id: 0,
          data: ''
        }
      });
  } else {
    return ''
  }

}
export const createSocialHistory = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data;
    return fetch(APIURL + '/createSocialHistory', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            type: 'EMR_SOCIAL_HISTORY_LIST',
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            type: 'EMR_SOCIAL_HISTORY_ERROR',
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          type: 'EMR_SOCIAL_HISTORY_ERROR',
          error: error.message,
          pagination_id: 0,
          data: ''
        }
      });
  } else {
    return ''
  }
}

export const updateSocialHistory = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data;
    return fetch(APIURL + '/updateSocialHistory', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            type: 'EMR_SOCIAL_HISTORY_LIST',
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            type: 'EMR_SOCIAL_HISTORY_ERROR',
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          type: 'EMR_SOCIAL_HISTORY_ERROR',
          error: error.message,
          pagination_id: 0,
          data: ''
        }
      });
  } else {
    return ''
  }
}


export const deleteSocialHistory = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data;
    return fetch(APIURL + '/deleteSocialHistory', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            type: 'EMR_SOCIAL_HISTORY_LIST',
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            type: 'EMR_SOCIAL_HISTORY_ERROR',
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          type: 'EMR_SOCIAL_HISTORY_ERROR',
          error: error.message,
          pagination_id: 0,
          data: ''
        }
      });
  } else {
    return ''
  }
}


export const getMedicationHistoryList = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data
    return fetch(APIURL + '/getMedicationHistory', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            type: 'EMR_MEDICATION_LIST',
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            type: 'EMR_MEDICATION_LIST',
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          type: 'EMR_MEDICATION_LIST',
          error: error.message,
          pagination_id: 0,
          data: ''
        }
      });
  } else {
    return ''
  }
}
export const createMedicationsHistory = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data
    return fetch(APIURL + '/createMedicationsHistory', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            type: 'EMR_MEDICATION_LIST',
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            type: 'EMR_MEDICATION_ERROR',
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          type: 'EMR_MEDICATION_ERROR',
          error: error.message,
          pagination_id: 0,
          data: ''
        }
      });
  } else {
    return ''
  }
}


export const updateMedicationsHistory = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data
    return fetch(APIURL + '/updateMedicationsHistory', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            type: 'EMR_MEDICATION_LIST',
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            type: 'EMR_MEDICATION_ERROR',
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          type: 'EMR_MEDICATION_ERROR',
          error: error.message,
          pagination_id: 0,
          data: ''
        }
      });
  } else {
    return ''
  }
}


export const deleteMedicationsHistory = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data
    return fetch(APIURL + '/deleteMedicationsHistory', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            type: 'EMR_MEDICATION_LIST',
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            type: 'EMR_MEDICATION_ERROR',
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          type: 'EMR_MEDICATION_ERROR',
          error: error.message,
          pagination_id: 0,
          data: ''
        }
      });
  } else {
    return ''
  }
}



export const getAllergiesList = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data;
    return fetch(APIURL + '/getAllergiesHistory', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            type: 'EMR_ALLERGIES_LIST',
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            type: 'EMR_ALLERGIES_LIST',
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          type: 'EMR_ALLERGIES_LIST',
          error: error.message,
          pagination_id: 0,
          data: ''
        }
      });
  } else {
    return ''
  }
}


export const createAllergyHistory = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data
    return fetch(APIURL + '/createAllergyHistory', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            type: 'EMR_ALLERGIES_LIST',
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            type: 'EMR_ALLERGIES_ERROR',
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          type: 'EMR_ALLERGIES_ERROR',
          error: error.message,
          pagination_id: 0,
          data: ''
        }
      });
  } else {
    return ''
  }
}


export const updateAllergyHistory = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data
    return fetch(APIURL + '/updateAllergyHistory', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            type: 'EMR_ALLERGIES_LIST',
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            type: 'EMR_ALLERGIES_ERROR',
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          type: 'EMR_ALLERGIES_ERROR',
          error: error.message,
          pagination_id: 0,
          data: ''
        }
      });
  } else {
    return ''
  }
}


export const deleteAllergyHistory = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data
    return fetch(APIURL + '/deleteAllergyHistory', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            type: 'EMR_ALLERGIES_LIST',
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            type: 'EMR_ALLERGIES_ERROR',
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          type: 'EMR_ALLERGIES_ERROR',
          error: error.message,
          pagination_id: 0,
          data: ''
        }
      });
  } else {
    return ''
  }
}

export const getNoteTemplates = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data;
    return fetch(APIURL + '/getNoteTemplates', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          error: error.message,
          pagination_id: 0,
          data: ''
        }
      });
  } else {
    return ''
  }
}

export const createNoteTemplate = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data
    return fetch(APIURL + '/createNoteTemplate', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          error: error.message,
          pagination_id: 0,
          data: ''
        }
      });
  } else {
    return ''
  }
}

export const updateNoteTemplate = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data
    return fetch(APIURL + '/updateNoteTemplate', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          error: error.message,
          pagination_id: 0,
          data: ''
        }
      });
  } else {
    return ''
  }
}

export const deleteNoteTemplate = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data
    return fetch(APIURL + '/deleteNoteTemplate', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          error: error.message,
          pagination_id: 0,
          data: ''
        }
      });
  } else {
    return ''
  }
}

export const getChatsHistoryList = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = JSON.stringify(data);
    return fetch(APIURL + '/getChatsHistoryList', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          error: error.message,
          pagination_id: 0,
          data: ''
        }
      });
  } else {
    return ''
  }
}
export const createChatsHistory = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data;
    return fetch(APIURL + '/createChatsHistory', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          error: error.message,
          pagination_id: error.pagination_id,
          data: ''
        }
      });
  } else {
    return ''
  }
}
export const updateChatsHistory = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data;
    return fetch(APIURL + '/updateChatsHistory', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          error: error.message,
          pagination_id: error.pagination_id,
          data: ''
        }
      });
  }
  else {
    return ''
  }
}
export const deleteChatsHistory = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data;
    return fetch(APIURL + '/deleteChatsHistory', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            data: data.data,
            pagination_id: data.pagination_id,
            message: ''
          }
        }
        else {
          return {
            message: data.message,
            pagination_id: data.pagination_id,
            data: ''
          }
        }
      })
      .catch(error => {
        return {
          error: error.message,
          pagination_id: error.pagination_id,
          data: ''
        }
      });
  } else {
    return ''
  }
}
export const getUserInfo = (data) => {
  return async (dispatch, getState) => {
    let firebaseToken = await functions.getIdToken()
    if (firebaseToken !== '') {
      let jsonmap = JSON.stringify(data);
      fetch(APIURL + '/getUserInfo', {
        method: 'POST',
        async: false,
        body: jsonmap,
        headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 1) {
            dispatch({
              type: 'EMR_USER_INFO',
              data: data.data,
              message: ''
            })
          }
          else {
            dispatch({
              type: 'EMR_USER_INFO',
              message: data.message,
              data: ''
            })
          }
        })
        .catch(error => {
          dispatch({
            type: 'EMR_USER_INFO',
            error: error.message,
            data: ''
          })
        });
    } else {
      return ''
    }
  }
}

export const updateUserInfo = (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: 'EMR_USER_INFO',
      data,
      message: ''
    })
  }
}

export const updateEMRStateData = (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: data.type,
      data: data.data,
      message: ''
    })
  }
}

export const getPrescriptionsList = () => {
  return (dispatch, getState) => {
    fetch(WEBSITEURL + '/prescriprions.json', {
      method: 'GET',
      async: false,
      headers: { 'content-type': 'application/json' }
    })
      .then(response => response.json())
      .then(data => {
        dispatch({
          type: 'EMR_PRESCRIPTION_LIST',
          data: data.prescription,
          message: ''
        })
      })
      .catch(error => {
        dispatch({
          type: 'EMR_PRESCRIPTION_LIST',
          error: error.message,
          data: ''
        })
      });
  }
}

export const setUserInfoToNull = () => {
  return (dispatch, getState) => {
    dispatch({
      type: 'RESET STATE'
    })
  }
}

export const getEMRData = (data) => {
  return async (dispatch, getState) => {
    let firebaseToken = await functions.getIdToken()
    if (firebaseToken !== '') {
      let jsonmap = JSON.stringify(data);
      fetch(APIURL + '/getEMRData', {
        method: 'POST',
        async: false,
        body: jsonmap,
        headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
      })
        .then(response => response.json())
        .then(data => {
          let typeArray = {
            'notes': 'EMR_NOTES_LIST',
            'appointments': 'EMR_APPOINTMENT_LIST',
            'appointmentsDocs': 'EMR_APPOINTMENTDOCS_LIST',
            'medical': 'EMR_MEDICAL_HISTORY_LIST',
            'social': 'EMR_SOCIAL_HISTORY_LIST',
            'medication': 'EMR_MEDICATION_LIST',
            'allergies': 'EMR_ALLERGIES_LIST',
            'chats': 'EMR_CHAT_LIST',
            'userInfo': 'EMR_USER_INFO',
          }
          for (let [key, val] of Object.entries(data)) {
            val = JSON.parse(val)
            if (val.status === 1) {
              dispatch({
                type: typeArray[key],
                data: val.data,
                message: ''
              })
            }
            else {
              dispatch({
                type: typeArray[key],
                message: val.message,
                data: ''
              })
            }
          }
        })
        .catch(error => {
          dispatch({
            type: 'EMR_ERROR',
            error: error.message,
            data: ''
          })
        });
    } else {
      return ''
    }
  }
}



export const getUserDashboardData = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = JSON.stringify(data);
    let response = await fetch(APIURL + '/getUserDashboardData', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
    response = await response.json()
    return response
  }
  else {
    return ''
  }
}