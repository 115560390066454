import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Avatar, Button, Link, Typography, CircularProgress } from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles"

const styles = theme => ({
    search: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        padding: theme.spacing(6, 'calc(100% - 1400px)'),
        fontFamily: 'revert'
    },
    searchItem: {
        display: 'flex',
        flexDirection: 'column',
        width: '270px',
        margin: theme.spacing(0, 1, 2),
        padding: theme.spacing(1, 1, 1),
        backgroundColor: '#fff',
        alignItems: 'center',
        textAlign: 'left',
        justifyContent: 'space-between',
        border: '1px solid #8080801f',
        borderRadius: theme.spacing(1)
    },
    searchItemDetail: {
        width: '100%',
        display: 'flex',
        borderBottom: '1px solid #8080802e',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'space-between',
    },
    text: {
        marginBottom: theme.spacing(1),
        fontSize: theme.spacing(2),
        padding: theme.spacing(0, 1)
    },
    labelDesc: {
        color: theme.palette.lightGrey.main
    },
    nameText: {
        fontWeight: 700,
        fontFamily: 'inherit',
        textTransform: 'uppercase',
        marginBottom: theme.spacing(1.5)
    },
    specialtyText: {
        width: '100%',
        color: theme.palette.lightGrey.main,
        // fontWeight: 600,
        fontFamily: 'inherit',
        marginTop: theme.spacing(1.5),
        // borderBottom: '2px solid #795bb2',
    },
    fullWidth: {
        width: '100%'
    },
    avatar: {
        margin: 'auto',
        width: '150px',
        height: '150px'
    },
    listItems: {
        width: '100%',
        // borderTop: '1px solid #b3b0b0',
        display: 'flex',
        justifyContent: 'space-between'
    },
    acountInfoText: {
        color: theme.palette.lightGrey.main,
    },
    bookNowButton: {
        marginTop: '1%',
        width: "100%",
        borderRadius: '10px',
        border: 'none',
        color: 'white',
        height: '50px',
        padding: "7px",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: "#1f4037"
        },
    }
})


class SearchResults extends Component {
    constructor(props) {
        super(props)
        /* FOR TASK RHYN-102
          TASK DESCRIPTION: Add patient tab and subaccounts for age <16
          CHECK IS REDUX SUBACCOUNT STATE HAS KEY subAccountId THEN GET ALL DATA RELATED TO SUBACCOUNT */
        this.state = {
            loader: false,
            loadingKey: -1,
            userId: props.subAccount.subAccountId ? props.subAccount.subAccountId : props.auth.uid
        };
    }
    clickButton = async (key, Id, Name, specialty, state, patientState, showList, holdamount, slotTime) => {
        let stepNo = 2
        this.setState({ loader: true, loadingKey: key })
        if (this.props.searchType === 'onDemand') {
            stepNo = 3
        }
        let showDr = true
        if (specialty === 'Nurse Practitioner')
            showDr = false

        if (holdamount === undefined || holdamount === null)
            holdamount = 100

        this.props.updateStepNo(stepNo, { doctorId: Id, doctorName: Name, appointmentType: this.props.searchType, doctorSpecialty: specialty, showDr: showDr, doctorState: state, patientState: patientState, previousStep: 1, showList: showList, userId: this.state.userId, userName: this.props.userName, holdamount: holdamount, appointmentduration: slotTime })
    }
    render() {
        const { list, classes, userState } = this.props
        let searchResult = []
        if (list.search_doctors.length === 0) {
            searchResult = this.props.emptyListMessage
        }
        else {
            searchResult = (list.search_doctors).map((doctor, key) =>
                <Grid key={key} className={classes.searchItem + " shadow"}>
                    <Grid className={classes.searchItemDetail}>
                        <Grid className={classes.fullWidth}>
                            <Avatar variant='square' className={classes.avatar} src={doctor['photo']}></Avatar>
                            <br></br>
                            <Typography className={classes.nameText}>{doctor.name}</Typography>
                            {doctor.speciality ?
                                <Typography className={classes.specialtyText}>{doctor.speciality}</Typography>
                                :
                                ""}
                        </Grid>
                        <Typography className={classes.acountInfoText}>{doctor.accountInfo}</Typography>
                        <Grid className={classes.fullWidth}>
                            <Grid className={classes.listItems}>
                                <Typography className={classes.text}>Languages:</Typography>
                                <Typography className={classes.labelDesc}>{doctor.language && doctor.language !== '' ? doctor.language.join(', ') : ''}</Typography>
                            </Grid>
                            <Grid className={classes.listItems}>
                                <Typography className={classes.text}>Location:</Typography>
                                <Typography className={classes.labelDesc}>{doctor.location}</Typography>
                            </Grid>
                            {this.props.searchType === 'onDemand' ?
                                <Grid className={classes.listItems}>
                                    <Typography className={classes.text}>Patients:</Typography>
                                    <Typography className={classes.labelDesc}>{doctor.patients + " in queue"}</Typography>
                                </Grid>
                                :
                                ""
                            }
                        </Grid>
                    </Grid>
                    <Grid className={classes.fullWidth}>
                        <Link onClick={() => this.clickButton(key, doctor.id, doctor.name, doctor.speciality, doctor.location, userState, doctor.showList, doctor.holdAmount, doctor.slotTime)} underline={'none'} >
                            <Button variant="contained"
                                className={classes.bookNowButton}
                                fullWidth>
                                {this.state.loader && this.state.loadingKey === key ?
                                    <CircularProgress size={24} />
                                    :
                                    this.props.searchType === 'onDemand' ?
                                        'Book Now'
                                        :
                                        "Schedule"
                                }
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            )
        }
        return (
            <Grid className={classes.search}>
                {searchResult}
            </Grid>
        );
    }
}
const mapStateToProps = (state) => {
    /* FOR TASK RHYN-102
    TASK DESCRIPTION: Add patient tab and subaccounts for age <16
    GET THE SUBACCOUNT STATE TO GET DATA ACCORDING TO SUBACCOUNT IS SUBACCOUNT DATA EXIST IN REDUX */
    return {
        list: state.appointment,
        userZone: state.account.data.zone,
        userState: state.account.data.state,
        userName: state.account.data.fullName,
        auth: state.auth,
        subAccount: state.subAccount
    };
};
export default connect(mapStateToProps, null)(withStyles(styles)(SearchResults));