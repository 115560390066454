
import { makeStyles } from '@material-ui/core/styles';
import Background from '../../assets/images/bg.jpg';

const useStyles = makeStyles(theme => ({
  sidebar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(' + Background + ')',
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
    flex: '35%',
    maxWidth: '35%',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    padding: '0 80px',
    position: 'fixed',
    width: '35%',
    top: 0,
    bottom: 0,
    left: 0,
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      flex: '100%',
      maxWidth: '100%',
      width: '100%',
      padding: 0,
    },
  },
  button: {
    color: '#fff !important',
    padding: ".2rem 2rem",
    fontSize: '1rem',
    backgroundColor: "#795bb5",
    marginBottom: theme.spacing(1)
  },
  buttonWrapper: {
    display: 'flex',
    marginTop: '3rem',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  sidebarInnerWrapper: {
    paddingBottom: '0%'
  },
  imgWrapper: {
    maxWidth: 220,
    margin: '0 auto'
  },
  brandLogo: {
    width: '100%'
  }
}));

export default useStyles;