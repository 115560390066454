import React, { Component } from "react";
import { connect } from "twilio-video"
import withStyles from '@material-ui/styles/withStyles'
import { APIURL } from '../../config.js'
import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'
import VideocamIcon from '@material-ui/icons/Videocam'
import VideocamOffIcon from '@material-ui/icons/VideocamOff'
import CallEndIcon from '@material-ui/icons/CallEnd'
import Timer from 'react-compound-timer'
import ChatIcon from '@material-ui/icons/Chat'
import NoteIcon from '@material-ui/icons/Note'
import { isMobileOnly } from "react-device-detect"
import { Grid, CircularProgress } from "@material-ui/core"
import getConnection from '../getConnection/getConnection'
import $ from 'jquery'


const styles = theme => ({
  videoApp: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '72vh',
    maxHeight: 'calc(100vh - 80px)',
    backgroundColor: 'black',
    color: 'white',
  },
  fullPage: {
    maxHeight: '100vh'
  },
  actions: {
    position: 'absolute',
    bottom: '10px',
    left: '10px',
    display: 'flex'
  },
  button: {
    backgroundColor: '#00bbbe',
    color: '#fff',
    display: 'flex',
    marginRight: '15px',
    borderRadius: '100px',
    border: 'unset',
    padding: '7px',
    outline: 'unset'
  },
  activeButton: {
    backgroundColor: '#fff',
    color: '#00bbbe'
  },
  cancelButton: {
    backgroundColor: '#ff2abc',
    color: '#fff'
  },
  timer: {
    position: 'absolute',
    textAlign: 'left',
    color: '#fff',
    backgroundColor: "#6f6f6fab",
    padding: theme.spacing(0, .7),
    fontSize: '20px',
    left: '10px',
    top: '10px',
    fontWeight: '900'
  },
  media: {
    fontSize: '16px',
    fontWeight: 700,
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
  },
  progress: {
    color: '#fff'
  },
  localMedia: {
    width: '100px',
    height: '100px',
    float: 'right',
    position: 'absolute',
    zIndex: 1,
    right: '12px',
    top: '20px'
  },
  video: {
    height: '100%',
    display: 'flex'
  },
  loadingText: {
    position: 'absolute',
    top: '45%',
    width: '100%',
    left: 0
  },
})
var $localRoom = ''
var $remoteRoom = ''
var $localParticipants = ''
var $remoteParticipants = ''

class VideoApp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      identity: null,
      peerUserId: 0,
      peerIdentity: "",
      remoteVideoLoaded: false,
      roomName: 'video_' + props.doctorId + '_' + props.userId + '_' + props.AppointmentId,  // Room Name   
      roomNameErr: false, // Track error for room name TextField  
      previewTracks: null,
      localMediaAvailable: false,
      hasJoinedRoom: false,
      hasParticipantsJoinedRoom: false,
      activeRoom: '', // Track the current active room  
      jwt: '',
      audioMic: true,
      videoMic: true,
      roomJoined: false,
      isMobile: true,
      role: props.role,
    }

    this.joinRoom = this.joinRoom.bind(this);
    this.leaveRoom = this.leaveRoom.bind(this);
    this.muteUnMuteAudio = this.muteUnMuteAudio.bind(this);
    this.muteUnMuteVideo = this.muteUnMuteVideo.bind(this);
    this.getTwillioToken()
  }

  getTwillioToken = () => {
    const currentUserName = this.props.username;
    if (currentUserName.length === 0) {
      console.log("Please enter the username!");
      return;
    }

    // Fetch an AccessToken to join the Room.
    fetch(APIURL + '/video/token', {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      method: 'POST',
      body: `identity=${encodeURIComponent(this.props.username)}`
    })
      .then(res => res.json())
      .then(results => {

        // Extract the AccessToken from the Response.
        const identity = results.identity;
        const jwt = results.token;
        console.log('token is ')
        console.log(jwt)
        this.setState(
          {
            identity,
            jwt
          }, () => {
            if (jwt.length === 0 || identity.length === 0) {
              console.log("Issue to fetch token!");
            } else {
              this.setState({ userName: currentUserName });
              console.log('calling join room function')
              this.joinRoom();
            }
          });
      });
  }

  joinRoom = async () => {
    try {
      console.log("setting Variable")
      $localRoom = $('#localVideo');
      $remoteRoom = $('#remoteVideo');
      $localParticipants = $('div#localParticipant', $localRoom);
      $remoteParticipants = $('div#remoteParticipant', $remoteRoom);
      if (!this.state.roomName.trim()) {
        this.setState({ roomNameErr: true });
        return;
      }


      let connectOptions = await getConnection(this.state.roomName)

      console.log(connectOptions)
      const room = await connect(this.state.jwt, connectOptions);

      this.setState({ activeRoom: room })
      const localParticipant = room.localParticipant;
      console.log("localparticipant")
      console.log(localParticipant)
      // Access the already published LocalTracks.
      console.log("Calling setupParticipant Container")
      this.setupParticipantContainer(localParticipant, room)

      // Handle the TrackPublications already published by the Participant.
      console.log("Local tracks publication")
      localParticipant.tracks.forEach(publication => {
        console.log(publication)
        this.trackPublished(publication, localParticipant);
      });

      // Handle theTrackPublications that will be published by the Participant later.
      console.log("trackPublished")
      localParticipant.on('trackPublished', publication => {
        console.log(publication)
        this.trackPublished(publication, localParticipant);
      });

      console.log("room all users set container and tracks")
      room.participants.forEach(participant => {
        console.log("Setting Remote User Container")
        console.log(participant)
        this.setupParticipantContainer(participant, room)
        // Handle the TrackPublications already published by the Participant.
        console.log("Setting Up remote participant tracks")
        participant.tracks.forEach(publication => {
          console.log(publication)
          this.trackPublished(publication, participant);
        });

        // Handle theTrackPublications that will be published by the Participant later.
        console.log("tracks published")
        participant.on('trackPublished', publication => {
          console.log(publication)
          this.trackPublished(publication, participant);
        });
        this.setState({ roomJoined: true })
        document.getElementById("startTimerButton").click()
      })

      room.on('participantConnected', participant => {
        console.log("Participant Connected")
        console.log(participant)
        console.log(`Participant connected: ${participant.identity}`);
        console.log("Setting Participant Container")
        this.setupParticipantContainer(participant, room)
        // Handle the TrackPublications already published by the Participant.
        console.log("Settin Joined user tracks")
        participant.tracks.forEach(publication => {
          console.log(publication)
          this.trackPublished(publication, participant);
        });

        // Handle theTrackPublications that will be published by the Participant later.
        participant.on('trackPublished', publication => {
          console.log(publication)
          this.trackPublished(publication, participant);
        });
        this.setState({ roomJoined: true })
        document.getElementById("startTimerButton").click()
      });

      room.on('participantDisconnected', participant => {
        console.log('room.participants.size')
        console.log(room.participants.size)
        this.participantDisconnected(participant, room);
        document.getElementById("pauseTimerButton").click()
        setTimeout(() => {
          if (room.participants.size === 0)
            this.props.endCall()
        }, 2000);
      });


      room.once('disconnected', (room, error) => {

        window.onbeforeunload = null;
        room.localParticipant.tracks.forEach(publication => {
          const attachedElements = publication.track.detach();
          attachedElements.forEach(element => element.remove());
        });


        document.getElementById("pauseTimerButton").click()
        // this.state.activeRoom = null;

      })
    }
    catch (error) {
      this.joinRoom()
    }
  }

  participantDisconnected = (participant, room) => {
    console.log("Participant DisConnected method called")
    let $participants = ''
    if (this.state.activeRoom.localParticipant === participant)
      $participants = $localParticipants
    else
      $participants = $remoteParticipants
    // Remove the Participant's media container.
    $(`div#${participant.sid}`, $participants).remove();

  }

  attachTrack = (track, participant) => {
    console.log("Attach Track Method Called")
    console.log(track)
    console.log(participant)
    let $participants = ''
    if (this.state.activeRoom.localParticipant === participant)
      $participants = $localParticipants
    else
      $participants = $remoteParticipants
    // Attach the Participant's Track to the thumbnail.
    const $media = $(`div#${participant.sid} > ${track.kind}`, $participants);
    console.log("Media")
    console.log($media)
    $media.css('opacity', '');
    track.attach($media.get(0));
  }

  detachTrack = (track, participant) => {
    console.log("detach Method called")
    let $participants = ''
    if (this.state.activeRoom.localParticipant === participant)
      $participants = $localParticipants
    else
      $participants = $remoteParticipants
    // Detach the Participant's Track from the thumbnail.
    const $media = $(`div#${participant.sid} > ${track.kind}`, $participants);
    console.log("Media")
    console.log($media)
    $media.css('opacity', '0');
    track.detach($media.get(0));
  }

  trackPublished = (publication, participant) => {
    console.log("track published method called")
    console.log(publication.track)
    console.log(publication)
    console.log(participant)
    // If the TrackPublication is already subscribed to, then attach the Track to the DOM.
    if (publication.track) {
      console.log("Calling Attach track method")
      this.attachTrack(publication.track, participant);
    }

    // Once the TrackPublication is subscribed to, attach the Track to the DOM.
    console.log("Tracks Subscribed")
    publication.on('subscribed', track => {
      console.log(track)
      console.log("Calling Attach track method")
      this.attachTrack(track, participant);
    });

    // Once the TrackPublication is unsubscribed from, detach the Track from the DOM.
    console.log("track unsubscribed")
    publication.on('unsubscribed', track => {
      console.log(track)
      console.log("Calling detach Track")
      this.detachTrack(track, participant);
    });
  }
  setupParticipantContainer = (participant, room) => {
    console.log("setup participant container method")
    console.log(participant)
    const { identity, sid } = participant;
    // Add a container for the Participant's media.
    const $container = $(`<div class="participant" data-identity="${identity}" id="${sid}">
      <audio autoplay ${participant === room.localParticipant ? 'muted' : ''} style="opacity: 0"></audio>
      <video autoplay muted playsinline style="opacity: 0"></video>
    </div>`);
    console.log("container")
    console.log($container)
    // Add the Participant's container to the DOM.
    let $participants = ''
    if (room.localParticipant === participant)
      $participants = $localParticipants
    else
      $participants = $remoteParticipants
    $participants.append($container);
    console.log("Remote video check to show remote video")
    if (room.localParticipant !== participant && !this.state.remoteVideoLoaded) {
      console.log("its remote user")
      console.log("remote video show ")
      var v = $('#remoteVideo #remoteParticipant div video')[0]
      v.addEventListener("loadeddata", () => {
        console.log('video loaded successfully')
        this.setState({ remoteVideoLoaded: true })
      });
    }
  }



  confirmLeaveRoom(props) {
    this.leaveRoom()
  }
  leaveRoom() {
    this.state.activeRoom.localParticipant.videoTracks.forEach(publication => {
      publication.track.stop();
      publication.unpublish();
    });
    this.state.activeRoom.disconnect();
    this.props.startVideoCall()
    this.props.hideBoxes('Chat')
    this.setState({ hasJoinedRoom: false, localMediaAvailable: false, peerIdentity: '', roomJoined: false });
  }
  muteUnMuteAudio = () => {
    var localParticipant = this.state.activeRoom.localParticipant;
    localParticipant.audioTracks.forEach(publication => {
      if (this.state.audioMic) {
        publication.track.disable();
      } else {
        publication.track.enable();
      }
    });
    this.setState({ audioMic: !this.state.audioMic })
  }
  muteUnMuteVideo = () => {
    var localParticipant = this.state.activeRoom.localParticipant;
    localParticipant.videoTracks.forEach(publication => {
      if (this.state.videoMic) {
        publication.track.disable();
      } else {
        publication.track.enable();
      }
    });
    this.setState({ videoMic: !this.state.videoMic })
  }
  hideBoxes = (val) => {
    this.props.hideBoxes(val)
  }

  checkDevice = () => {
    if (isMobileOnly) {
      // alert('its mobile')
    }
  }

  render() {
    const { classes } = this.props
    // /* Hide 'Join Room' button if user has already joined a room */  
    // let joinOrLeaveRoomButton = this.state.hasJoinedRoom ? (  
    //   <button className="btn btn-warning" onClick={this.leaveRoom} > Leave Room</button>  
    // ) : (  
    //     <button className="btn btn-success ml-2" onClick={this.getTwillioToken} >Join Room</button>  
    //   );  
    // /** */  

    return (
      <React.Fragment>

        <div className={"videoSection"}>
          <Grid className={classes.videoApp + ' ' + classes.fullPage}>
            <Grid id="localVideo" className={this.state.roomJoined ? classes.media + ' ' + classes.localMedia : classes.media} >
              <div id="localParticipant" ref="groupChat_localMedia" className={classes.video}></div>
              <span className={classes.loadingText}>Waiting For Local Media Loading <CircularProgress thickness={7} className={classes.progress} size={17} /></span>
            </Grid>

            <Grid id="remoteVideo" className={classes.media} style={{ display: this.state.roomJoined ? 'block' : 'none' }}>
              <div id="remoteParticipant" style={{ display: this.state.remoteVideoLoaded === false ? 'none' : 'flex' }} ref="remoteMedia" className={classes.video}></div>
              {this.state.remoteVideoLoaded === false ? <span className={classes.loadingText}>Waiting For Remote Media Loading <CircularProgress thickness={7} className={classes.progress} size={17} /></span> : ""}
            </Grid>
            <Grid className={classes.timer}>
              <Timer
                startImmediately={false}
                onStart={() => console.log('onStart hook')}
              >
                {({ start, resume, pause, stop, reset, timerState }) => (
                  <React.Fragment>
                    <div>
                      <Timer.Minutes />:
                        <Timer.Seconds />
                    </div>
                    <div>
                      <button style={{ display: 'none' }} id="startTimerButton" onClick={start}></button>
                      <button style={{ display: 'none' }} id="pauseTimerButton" onClick={pause}></button>
                    </div>
                  </React.Fragment>
                )}
              </Timer>
            </Grid>
            <Grid className={classes.actions}>
              {
                this.state.audioMic
                  ?
                  <div className="card-footer"><button className={classes.button} onClick={this.muteUnMuteAudio} > <MicIcon /></button></div>
                  :
                  <div className="card-footer"><button className={classes.button + " " + classes.activeButton} onClick={this.muteUnMuteAudio} > <MicOffIcon /></button></div>
              }
              {
                this.state.videoMic
                  ?
                  <div className="card-footer"><button className={classes.button + " " + classes.activeButton} onClick={this.muteUnMuteVideo} > <VideocamIcon /></button></div>
                  :
                  <div className="card-footer"><button className={classes.button} onClick={this.muteUnMuteVideo} > <VideocamOffIcon /></button></div>
              }
              <div className="card-footer"><button className={classes.button + " " + classes.activeButton} onClick={() => this.hideBoxes('Chat')} > <ChatIcon /></button></div>
              {this.state.role === 'Doctor' ? <div className="card-footer"><button className={classes.button + " " + classes.activeButton} onClick={() => this.hideBoxes('Progress')} > <NoteIcon /></button></div> : ""}
              <div className="card-footer"><button className={classes.button + ' ' + classes.cancelButton} onClick={() => this.confirmLeaveRoom(this.props)} > <CallEndIcon /></button></div>
            </Grid>
            <div style={{ display: !this.state.roomJoined ? "block" : "none", position: isMobileOnly ? "absolute" : "unset", top: 0, marginTop: '20px', textAlign: 'left', fontSize: '15px' }}>Waiting for the other party to join</div>
          </Grid>
        </div>
      </React.Fragment>
    )
  }

}
export default withStyles(styles)(VideoApp)