import React, { useState } from 'react'
//redux
import { connect } from 'react-redux'
import { updateProfile } from '../../../store/actions/authActions'
import { Grid, Button, TextField, MenuItem, Select, CircularProgress } from '@material-ui/core'
//MUI
import withStyles from "@material-ui/core/styles/withStyles"
import EditIcon from '@material-ui/icons/Edit'
//Components
import LocationsInputs from './LocationsInputs'
import BirthdayInput from './BirthdayInput'
import ExpireCardInput from './ExpireCardInput'
import ZonesInput from './ZonesInput'
import NotificationsInput from './NotificationInputChoice'
import { usePlacesWidget } from "react-google-autocomplete";
import { GOOGLEMAPKEY } from '../../../config'
import { formatHealthCard } from '../../../assets/js/general'
var AddressParser = require('parse-google-address');
const styles = theme => ({
    container: {
        width: '22rem',
        marginLeft: '23%',
        textAlign: 'left',
    },
    editIcon: {
        fontSize: '1rem',
        color: theme.palette.lightGrey.main,
    },
    label: {
        color: theme.palette.lightGrey.main,
    },
    inputGrid: {
        marginTop: '4%'
    },
    textFieldInput: {
        '& .MuiInputBase-root': {
            color: theme.palette.lightGrey.main,
        },
        "& .Mui-focused": {
            color: 'black'
        }
    },
    selectInput: {
        width: '100%',
        '& .MuiSelect-root': {
            color: theme.palette.lightGrey.main,
        },
        "& .Mui-focused": {
            color: 'black'
        }
    },
    buttonUpdate: {
        width: "120px",
        borderRadius: '10px',
        border: 'none',
        color: 'white',
        height: '40px',
        padding: "7px",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: "#1f4037"
        },
        marginTop: '4%'
    },
})
const Form = (props) => {
    const { classes, auth, account, availablecountries, availableStates, subAccount } = props;
    // const uid = auth.uid;
    const uid = subAccount && subAccount.subAccountId ? subAccount.subAccountId : auth.uid
    // console.log(props)
    //fields

    const [firstName, setFirstName] = useState(account.data.firstName)
    const [lastName, setLastName] = useState(account.data.lastName)
    const [agencyName, setAgencyName] = useState(account.data.agencyName)
    const [pharmacyName, setPharmacyName] = useState(account.data.pharmacyName)
    const [dateOfBirth, setDateOfBirth] = useState(account.data.dateofBirth)
      //skypeConversation 30-MAY 2:54
     //3- also, this is related to 2... please make sure the account page where there is a health card input,
     // limit the characters to numbers and letters only and autocapitalize the letters.
    // Disallow spaces and hyphens there please.
    // Dev : formatHealthCard() called this function which will return value that will
    // limit the characters to numbers and letters only and autocapitalize the letters
    const [healthCardNo, setHealthCardNo] = useState(account.data.hCard)
    const [dateOfHCExp, setDateOfHCExp] = useState(account.data.dateofHCExp)
    const [sex, setSex] = useState(account.data.gender)
    const [mobileNumber, setMobileNumber] = useState(account.data.phone)
    const [streetAddress, setStreetAddress] = useState(account.data.street)
    const [city, setCity] = useState(account.data.city)
    const [country, setCountry] = useState(account.data.country)
    const [province, setProvince] = useState(account.data.state)
    const [postCode, setPostCode] = useState(account.data.zip)
    const [zone, setZone] = useState(account.data.zone)
    const [notificationType, setNotificationType] = useState(account.data.notificationType)
    const [loading, setLoading] = useState(false)
    const onSave = async () => {
        setLoading(true);
        let availableCountriesList = props.availablecountries
        for (let i = 0; i < availableCountriesList.length; i++) {
            if (availableCountriesList[i].name === country) {

                let savingObject = [
                    { country: country },
                    { countryCode: availableCountriesList[i].code },
                    { countryDialCode: availableCountriesList[i].dial_code },
                    { state: province },
                    { firstName: firstName },
                    { lastName: lastName },
                    { hCard: healthCardNo },
                    { dateofHCExp: dateOfHCExp },
                    { phone: mobileNumber },
                    { street: streetAddress },
                    { city: city },
                    { zip: postCode },
                    { zone: zone },
                    { notificationType: notificationType }
                ]

                if (account.data.role === 'Patient') {
                    savingObject.push({ gender: sex })
                    savingObject.push({ dateofBirth: dateOfBirth })
                }
                else if (account.data.role === 'Agency') {
                    savingObject.push({ agencyName: agencyName })
                }
                else if (account.data.role === 'Pharmacy') {
                    savingObject.push({ pharmacyName: pharmacyName })
                }
                let result = await props.updateProfile(uid,savingObject)
                props.updateProfileData(result)
                setLoading(false)
            }
        }
        // if(this.state.error === false){
        //   console.log('error true',this.state.error)
        //   let result = await this.props.updateProfile(this.state.uid, [{ [key]: value }])
        //   this.props.updateProfileData(result)
        //   this.setState({ loading: false, edit_key: '', edit_val: '' })
        // }
        // else{
        //   this.setState({ loading: false })
        // }
    }
    const { ref } = usePlacesWidget({
		apiKey:GOOGLEMAPKEY,
		onPlaceSelected: (place) => {
		  //console.log(place);
		  //console.log(place.formatted_address);		  
		  AddressParser.Parse_Reverse_GeoCode_Address(place.address_components, function (err, ParsedData) {
				if (err) console.error(err);
				console.log("Parsed Data--->", ParsedData);
				setPostCode(ParsedData.Postal_Code)
				setCountry(ParsedData.Country)
				setProvince(ParsedData.Area_Level_1)
				setCity(ParsedData.Locality)
				let StreetAddress = [ParsedData.Street_Number,ParsedData.Street_Address , ParsedData.Route,]
				setStreetAddress( StreetAddress.filter(Boolean).join())
			});

		},
		options: {
		  types: ["address"]
		},
	});
    return (
        <div className={classes.container}>
            <Grid className={classes.inputGrid}>
                <label className={classes.label}>First & Middle Name</label>
                <TextField
                    className={classes.textFieldInput}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <EditIcon className={classes.editIcon} />
                        )
                    }}
                />
            </Grid>
            <Grid className={classes.inputGrid}>
                <label className={classes.label}>Last Name</label>
                <TextField
                    value={lastName}
                    className={classes.textFieldInput}
                    onChange={(e) => setLastName(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <EditIcon className={classes.editIcon} />
                        )
                    }}
                />
            </Grid>
            {account.data.role === 'Agency' ? <>
                <Grid className={classes.inputGrid}>
                    <label className={classes.label}>Agency Name</label>
                    <TextField
                        value={agencyName}
                        className={classes.textFieldInput}
                        onChange={(e) => setAgencyName(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <EditIcon className={classes.editIcon} />
                            )
                        }}
                    />
                </Grid>
            </>
                :
                <></>
            }
            {account.data.role === 'Pharmacy' ? <>
                <Grid className={classes.inputGrid}>
                    <label className={classes.label}>Pharmacy Name</label>
                    <TextField
                        value={pharmacyName}
                        className={classes.textFieldInput}
                        onChange={(e) => setPharmacyName(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <EditIcon className={classes.editIcon} />
                            )
                        }}
                    />
                </Grid>
            </>
                :
                <></>
            }
            {account.data.role === 'Patient' ? <>
                <Grid className={classes.inputGrid}>
                    <label className={classes.label}>Sex</label>
                    <br></br>
                    <Select
                        value={sex}
                        className={classes.selectInput}
                        onChange={(e) => setSex(e.target.value)}
                        label="Sex:"
                    >
                        <MenuItem value='Male'>Male</MenuItem>
                        <MenuItem value='Female'>Female</MenuItem>
                    </Select>
                </Grid>
                <Grid className={classes.inputGrid}>
                    <BirthdayInput birthday={dateOfBirth} changeBirthday={setDateOfBirth} />
                </Grid>
            </> : <></>
            }
            {account.data.role === 'Patient' ? <>
                <Grid className={classes.inputGrid}>
                    <label className={classes.label}>Health Card Number</label>
                    <TextField
                        className={classes.textFieldInput}
                        value={healthCardNo}
                        inputProps={{maxLength:12}}
                        onChange={(e) => {  

                            //skypeConversation 30-MAY 2:54
                            //3- also, this is related to 2... please make sure the account page where there is a health card input,
                            // limit the characters to numbers and letters only and autocapitalize the letters.
                            // Disallow spaces and hyphens there please.
                            // Dev : formatHealthCard() called this function which will return value that will
                            // limit the characters to numbers and letters only and autocapitalize the letters
                            const value = formatHealthCard(e.target.value || '' ) || ''
                            setHealthCardNo(value) }}
                        InputProps={{
                            endAdornment: (
                                <EditIcon className={classes.editIcon} />
                            )
                        }}
                    />
                </Grid>
                <Grid className={classes.inputGrid}>
                    <ExpireCardInput dateOfHCExp={dateOfHCExp} changeDateOfHCExp={setDateOfHCExp} />
                </Grid>
            </>
                :
                <></>
            }
            <Grid className={classes.inputGrid}>
                <label className={classes.label}>Phone Number</label>
                <TextField
                    className={classes.textFieldInput}
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <EditIcon className={classes.editIcon} />
                        )
                    }}
                />
            </Grid>
            <Grid className={classes.inputGrid}>
                <label className={classes.label}>Street Address</label>
                <TextField
                    inputRef={ref}
                    className={classes.textFieldInput}
                    value={streetAddress}
                    onChange={(e) => { setStreetAddress(e.target.value) }}
                    InputProps={{
                        endAdornment: (
                            <EditIcon className={classes.editIcon} />
                        )
                    }}
                />
            </Grid>
            <Grid className={classes.inputGrid}>
                <label className={classes.label}>City</label>
                <TextField
                    className={classes.textFieldInput}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <EditIcon className={classes.editIcon} />
                        )
                    }}
                />
            </Grid>
            <Grid className={classes.inputGrid}>
                <LocationsInputs
                    country={country} changeCountry={setCountry}
                    province={province} changeProvince={setProvince}
                    account={account}
                    availablecountries={availablecountries}
                    availableStates={availableStates}
                />
            </Grid>
            <Grid className={classes.inputGrid}>
                <label className={classes.label}>Postal code</label>
                <TextField
                    className={classes.textFieldInput}
                    value={postCode.toUpperCase()}
                    onChange={(e) => {
                        let val = e.target.value.toUpperCase()
                        val = val.replace(' ', '')
                        setPostCode(val)
                    }
                    }
                    InputProps={{
                        endAdornment: (
                            <EditIcon className={classes.editIcon} />
                        )
                    }}
                />
            </Grid>
            <Grid className={classes.inputGrid}>
                <ZonesInput zone={zone} changeZone={setZone} />
            </Grid>
            <Grid className={classes.inputGrid}>
                <NotificationsInput notification={notificationType} changeNotificationType={setNotificationType} />
            </Grid>
            <Button
                className={classes.buttonUpdate}
                onClick={() => onSave()}
            >
                {loading ? <CircularProgress size={24} style={{ color: 'white' }} /> : <>Update</>}
            </Button>
        </div>
    )
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateProfile: (uid, data) => updateProfile(uid, data),
        updateProfileData: (data) => dispatch(data)
    }
}
export default connect(null, mapDispatchToProps)(withStyles(styles)(Form))