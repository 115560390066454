export const TopTitle = "RhynoCare";
export const APIURL = "https://us-central1-telemedicine-2e69d.cloudfunctions.net/api";
export const WEBSITEURL = "https://telemedicine.doctors-finder.com";
export const APIKEY = "AIzaSyAQIvAvqwcLsAuK2bUDirgbXkZrpcJsP2s";
export const AUTHDOMAIN = "telemedicine-2e69d.firebaseapp.com";
export const DATABASEURL = "https://telemedicine-2e69d.firebaseio.com";
export const STORAGEBUCKET = "telemedicine-2e69d.appspot.com";
export const PRIVATESTORAGEBUCKET = "gs://telemedicine-2e69d_private";
export const SEARCHRECORDSLIMIT = "20";
export const CONVERSATIONTIMEINMILLISECONDS = 5*60*1000; // 5 Minutes
export const TIMEEXPIRY = 1000 * 60 * 60 * 24 * 90; // 90 Days
export const STRIPEPUBLICKEY = "pk_test_g4QEX9LDFxTFWwY1KYHgZZgq00OETqmNOu";
export const STRIPEPUBLICKEYTEST = "pk_test_g4QEX9LDFxTFWwY1KYHgZZgq00OETqmNOu";
export const GOOGLEMAPKEY = "AIzaSyAcobkJytd_hcucWeMrRcwNOgfPKg10mjI";
export const RECAPTCHASITEKEY = "6Lc2SNAZAAAAAN7Ddh864TTZnQnEdnpROrGwN-NS";
export const GETNOTIFICATIONTIME = 1000 * 60;
// SKIP 2F EMAIL
export const SKIP2FEMAILS = '@testaccount.com'

// BOKKING COSTS
export const COVID19COST = 119.99
export const AGENCYURGENTPCRCOST = 249.99
export const AGENCYPCRCOST = 115.00
export const URGENTPCRCOST = 249.99
export const RAPIDPCRCOST = 134.99
// export const RAPIDANTIGENCOST = [59.99, 49.99, 39.99]
// SKYPE CONVERSATION 27 apr 2022
// 1) Change rapid antigen pricing to 70 + HST per person, regardless of number of patients.
// UPDATE THE PRICE TO 70
export const RAPIDANTIGENCOST = [70, 70, 70]
export const REGULARPCRCOST = 99.99
// SKYPE COMVERSATION 13 APR
// Please change the at home wellness visit cost to 49.99 + HST
// UPDATE COST TO 49.99
export const NOMEMBERCOST = 49.99
export const RHYNOGOCOST = 39.99
export const MEMBERCOST = 15.99
export const IVTHERAPYMEMBERCOST = 79.99
export const IVTHERAPYNOMEMBERCOST = 99.99
export const SUBSCRIPTIONOWNERPRICE = 39.99
export const SUBSCRIPTIONMEMBERPRICE = 19.99

export const AVAILABLEDOCTORCOUNTRIES = [
  { "name": "United States of America", "dial_code": "+1", "code": "US" },
  { "name": "Canada", "dial_code": "+1", "code": "CA" }
]
export const AVAILABLECOUNTRIES = [
  {
    "name": "Afghanistan",
    "dial_code": "+93",
    "code": "AF"
  },
  {
    "name": "Albania",
    "dial_code": "+355",
    "code": "AL"
  },
  {
    "name": "Algeria",
    "dial_code": "+213",
    "code": "DZ"
  },
  {
    "name": "Andorra",
    "dial_code": "+376",
    "code": "AD"
  },
  {
    "name": "Angola",
    "dial_code": "+244",
    "code": "AO"
  },
  {
    "name": "Antigua and Barbuda",
    "dial_code": "+1268",
    "code": "AG"
  },
  {
    "name": "Argentina",
    "dial_code": "+54",
    "code": "AR"
  },
  {
    "name": "Armenia",
    "dial_code": "+374",
    "code": "AM"
  },
  {
    "name": "Australia",
    "dial_code": "+61",
    "code": "AU"
  },
  {
    "name": "Austria",
    "dial_code": "+43",
    "code": "AT"
  },
  {
    "name": "Azerbaijan",
    "dial_code": "+994",
    "code": "AZ"
  },
  {
    "name": "Bahamas",
    "dial_code": "+1 242",
    "code": "BS"
  },
  {
    "name": "Bahrain",
    "dial_code": "+973",
    "code": "BH"
  },
  {
    "name": "Bangladesh",
    "dial_code": "+880",
    "code": "BD"
  },
  {
    "name": "Barbados",
    "dial_code": "+1 246",
    "code": "BB"
  },
  {
    "name": "Belarus",
    "dial_code": "+375",
    "code": "BY"
  },
  {
    "name": "Belgium",
    "dial_code": "+32",
    "code": "BE"
  },
  {
    "name": "Belize",
    "dial_code": "+501",
    "code": "BZ"
  },
  {
    "name": "Benin",
    "dial_code": "+229",
    "code": "BJ"
  },
  {
    "name": "Bhutan",
    "dial_code": "+975",
    "code": "BT"
  },
  {
    "name": "Bolivia",
    "dial_code": "+591",
    "code": "BO"
  },
  {
    "name": "Bosnia and Herzegovina",
    "dial_code": "+387",
    "code": "BA"
  },
  {
    "name": "Botswana",
    "dial_code": "+267",
    "code": "BW"
  },
  {
    "name": "Brazil",
    "dial_code": "+55",
    "code": "BR"
  },
  {
    "name": "Brunei",
    "dial_code": "+673",
    "code": "BN"
  },
  {
    "name": "Bulgaria",
    "dial_code": "+359",
    "code": "BG"
  },
  {
    "name": "Burkina Faso",
    "dial_code": "+226",
    "code": "BF"
  },
  {
    "name": "Burundi",
    "dial_code": "+257",
    "code": "BI"
  },
  {
    "name": "Cambodia",
    "dial_code": "+855",
    "code": "KH"
  },
  {
    "name": "Cameroon",
    "dial_code": "+237",
    "code": "CM"
  },
  {
    "name": "Canada",
    "dial_code": "+1",
    "code": "CA"
  },
  {
    "name": "Cabo Verde",
    "dial_code": "+238",
    "code": "CV"
  },
  {
    "name": "Central African Republic",
    "dial_code": "+236",
    "code": "CF"
  },
  {
    "name": "Chad",
    "dial_code": "+235",
    "code": "TD"
  },
  {
    "name": "Chile",
    "dial_code": "+56",
    "code": "CL"
  },
  {
    "name": "China",
    "dial_code": "+86",
    "code": "CN"
  },
  {
    "name": "Colombia",
    "dial_code": "+57",
    "code": "CO"
  },
  {
    "name": "Comoros",
    "dial_code": "+269",
    "code": "KM"
  },
  {
    "name": "Congo (Congo-Brazzaville)",
    "dial_code": "+242",
    "code": "CG"
  },
  {
    "name": "Democratic Republic of the Congo",
    "dial_code": "+243",
    "code": "CD"
  },
  {
    "name": "Costa Rica",
    "dial_code": "+506",
    "code": "CR"
  },
  {
    "name": "C�te d'Ivoire",
    "dial_code": "+225",
    "code": "CI"
  },
  {
    "name": "Croatia",
    "dial_code": "+385",
    "code": "HR"
  },
  {
    "name": "Cuba",
    "dial_code": "+53",
    "code": "CU"
  },
  {
    "name": "Cyprus",
    "dial_code": "+537",
    "code": "CY"
  },
  {
    "name": "Czechia (Czech Republic)",
    "dial_code": "+420",
    "code": "CZ"
  },
  {
    "name": "Denmark",
    "dial_code": "+45",
    "code": "DK"
  },
  {
    "name": "Djibouti",
    "dial_code": "+253",
    "code": "DJ"
  },
  {
    "name": "Dominica",
    "dial_code": "+1 767",
    "code": "DM"
  },
  {
    "name": "Dominican Republic",
    "dial_code": "+1 849",
    "code": "DO"
  },
  {
    "name": "Ecuador",
    "dial_code": "+593",
    "code": "EC"
  },
  {
    "name": "Egypt",
    "dial_code": "+20",
    "code": "EG"
  },
  {
    "name": "El Salvador",
    "dial_code": "+503",
    "code": "SV"
  },
  {
    "name": "Equatorial Guinea",
    "dial_code": "+240",
    "code": "GQ"
  },
  {
    "name": "Eritrea",
    "dial_code": "+291",
    "code": "ER"
  },
  {
    "name": "Estonia",
    "dial_code": "+372",
    "code": "EE"
  },
  {
    "name": "Ethiopia",
    "dial_code": "+251",
    "code": "ET"
  },
  {
    "name": "Fiji",
    "dial_code": "+679",
    "code": "FJ"
  },
  {
    "name": "Finland",
    "dial_code": "+358",
    "code": "FI"
  },
  {
    "name": "France",
    "dial_code": "+33",
    "code": "FR"
  },
  {
    "name": "Gabon",
    "dial_code": "+241",
    "code": "GA"
  },
  {
    "name": "Gambia",
    "dial_code": "+220",
    "code": "GM"
  },
  {
    "name": "Georgia",
    "dial_code": "+995",
    "code": "GE"
  },
  {
    "name": "Germany",
    "dial_code": "+49",
    "code": "DE"
  },
  {
    "name": "Ghana",
    "dial_code": "+233",
    "code": "GH"
  },
  {
    "name": "Greece",
    "dial_code": "+30",
    "code": "GR"
  },
  {
    "name": "Grenada",
    "dial_code": "+1 473",
    "code": "GD"
  },
  {
    "name": "Guatemala",
    "dial_code": "+502",
    "code": "GT"
  },
  {
    "name": "Guinea",
    "dial_code": "+224",
    "code": "GN"
  },
  {
    "name": "Guinea-Bissau",
    "dial_code": "+245",
    "code": "GW"
  },
  {
    "name": "Guyana",
    "dial_code": "+595",
    "code": "GY"
  },
  {
    "name": "Haiti",
    "dial_code": "+509",
    "code": "HT"
  },
  {
    "name": "Holy See",
    "dial_code": "+379",
    "code": "VA"
  },
  {
    "name": "Honduras",
    "dial_code": "+504",
    "code": "HN"
  },
  {
    "name": "Hungary",
    "dial_code": "+36",
    "code": "HU"
  },
  {
    "name": "Iceland",
    "dial_code": "+354",
    "code": "IS"
  },
  {
    "name": "India",
    "dial_code": "+91",
    "code": "IN"
  },
  {
    "name": "Indonesia",
    "dial_code": "+62",
    "code": "ID"
  },
  {
    "name": "Iran",
    "dial_code": "+98",
    "code": "IR"
  },
  {
    "name": "Iraq",
    "dial_code": "+964",
    "code": "IQ"
  },
  {
    "name": "Ireland",
    "dial_code": "+353",
    "code": "IE"
  },
  {
    "name": "Israel",
    "dial_code": "+972",
    "code": "IL"
  },
  {
    "name": "Italy",
    "dial_code": "+39",
    "code": "IT"
  },
  {
    "name": "Jamaica",
    "dial_code": "+1 876",
    "code": "JM"
  },
  {
    "name": "Japan",
    "dial_code": "+81",
    "code": "JP"
  },
  {
    "name": "Jordan",
    "dial_code": "+962",
    "code": "JO"
  },
  {
    "name": "Kazakhstan",
    "dial_code": "+7 7",
    "code": "KZ"
  },
  {
    "name": "Kenya",
    "dial_code": "+254",
    "code": "KE"
  },
  {
    "name": "Kiribati",
    "dial_code": "+686",
    "code": "KI"
  },
  {
    "name": "North Korea",
    "dial_code": "+850",
    "code": "KP"
  },
  {
    "name": "South Korea",
    "dial_code": "+82",
    "code": "KR"
  },
  {
    "name": "Kuwait",
    "dial_code": "+965",
    "code": "KW"
  },
  {
    "name": "Kyrgyzstan",
    "dial_code": "+996",
    "code": "KG"
  },
  {
    "name": "Laos",
    "dial_code": "+856",
    "code": "LA"
  },
  {
    "name": "Latvia",
    "dial_code": "+371",
    "code": "LV"
  },
  {
    "name": "Lebanon",
    "dial_code": "+961",
    "code": "LB"
  },
  {
    "name": "Lesotho",
    "dial_code": "+266",
    "code": "LS"
  },
  {
    "name": "Liberia",
    "dial_code": "+231",
    "code": "LR"
  },
  {
    "name": "Libya",
    "dial_code": "+218",
    "code": "LY"
  },
  {
    "name": "Liechtenstein",
    "dial_code": "+423",
    "code": "LI"
  },
  {
    "name": "Lithuania",
    "dial_code": "+370",
    "code": "LT"
  },
  {
    "name": "Luxembourg",
    "dial_code": "+352",
    "code": "LU"
  },
  {
    "name": "North Macedonia",
    "dial_code": "+389",
    "code": "MK"
  },
  {
    "name": "Madagascar",
    "dial_code": "+261",
    "code": "MG"
  },
  {
    "name": "Malawi",
    "dial_code": "+265",
    "code": "MW"
  },
  {
    "name": "Malaysia",
    "dial_code": "+60",
    "code": "MY"
  },
  {
    "name": "Maldives",
    "dial_code": "+960",
    "code": "MV"
  },
  {
    "name": "Mali",
    "dial_code": "+223",
    "code": "ML"
  },
  {
    "name": "Malta",
    "dial_code": "+356",
    "code": "MT"
  },
  {
    "name": "Marshall Islands",
    "dial_code": "+692",
    "code": "MH"
  },
  {
    "name": "Mauritania",
    "dial_code": "+222",
    "code": "MR"
  },
  {
    "name": "Mauritius",
    "dial_code": "+230",
    "code": "MU"
  },
  {
    "name": "Mexico",
    "dial_code": "+52",
    "code": "MX"
  },
  {
    "name": "Micronesia",
    "dial_code": "+691",
    "code": "FM"
  },
  {
    "name": "Moldova",
    "dial_code": "+373",
    "code": "MD"
  },
  {
    "name": "Monaco",
    "dial_code": "+377",
    "code": "MC"
  },
  {
    "name": "Mongolia",
    "dial_code": "+976",
    "code": "MN"
  },
  {
    "name": "Montenegro",
    "dial_code": "+382",
    "code": "ME"
  },
  {
    "name": "Morocco",
    "dial_code": "+212",
    "code": "MA"
  },
  {
    "name": "Mozambique",
    "dial_code": "+258",
    "code": "MZ"
  },
  {
    "name": "Myanmar",
    "dial_code": "+95",
    "code": "MM"
  },
  {
    "name": "Namibia",
    "dial_code": "+264",
    "code": "NA"
  },
  {
    "name": "Nauru",
    "dial_code": "+674",
    "code": "NR"
  },
  {
    "name": "Nepal",
    "dial_code": "+977",
    "code": "NP"
  },
  {
    "name": "Netherlands",
    "dial_code": "+31",
    "code": "NL"
  },
  {
    "name": "New Zealand",
    "dial_code": "+64",
    "code": "NZ"
  },
  {
    "name": "Nicaragua",
    "dial_code": "+505",
    "code": "NI"
  },
  {
    "name": "Niger",
    "dial_code": "+227",
    "code": "NE"
  },
  {
    "name": "Nigeria",
    "dial_code": "+234",
    "code": "NG"
  },
  {
    "name": "Norway",
    "dial_code": "+47",
    "code": "NO"
  },
  {
    "name": "Oman",
    "dial_code": "+968",
    "code": "OM"
  },
  {
    "name": "Pakistan",
    "dial_code": "+92",
    "code": "PK"
  },
  {
    "name": "Palau",
    "dial_code": "+680",
    "code": "PW"
  },
  {
    "name": "Palestine State",
    "dial_code": "+970",
    "code": "PS"
  },
  {
    "name": "Panama",
    "dial_code": "+507",
    "code": "PA"
  },
  {
    "name": "Papua New Guinea",
    "dial_code": "+675",
    "code": "PG"
  },
  {
    "name": "Paraguay",
    "dial_code": "+595",
    "code": "PY"
  },
  {
    "name": "Peru",
    "dial_code": "+51",
    "code": "PE"
  },
  {
    "name": "Philippines",
    "dial_code": "+63",
    "code": "PH"
  },
  {
    "name": "Poland",
    "dial_code": "+48",
    "code": "PL"
  },
  {
    "name": "Portugal",
    "dial_code": "+351",
    "code": "PT"
  },
  {
    "name": "Qatar",
    "dial_code": "+974",
    "code": "QA"
  },
  {
    "name": "Romania",
    "dial_code": "+40",
    "code": "RO"
  },
  {
    "name": "Russia",
    "dial_code": "+7",
    "code": "RU"
  },
  {
    "name": "Rwanda",
    "dial_code": "+250",
    "code": "RW"
  },
  {
    "name": "Saint Kitts and Nevis",
    "dial_code": "+1 869",
    "code": "KN"
  },
  {
    "name": "Saint Lucia",
    "dial_code": "+1 758",
    "code": "LC"
  },
  {
    "name": "Saint Vincent and the Grenadines",
    "dial_code": "+1 784",
    "code": "VC"
  },
  {
    "name": "Samoa",
    "dial_code": "+685",
    "code": "WS"
  },
  {
    "name": "San Marino",
    "dial_code": "+378",
    "code": "SM"
  },
  {
    "name": "Sao Tome and Principe",
    "dial_code": "+239",
    "code": "ST"
  },
  {
    "name": "Saudi Arabia",
    "dial_code": "+966",
    "code": "SA"
  },
  {
    "name": "Senegal",
    "dial_code": "+221",
    "code": "SN"
  },
  {
    "name": "Serbia",
    "dial_code": "+381",
    "code": "RS"
  },
  {
    "name": "Seychelles",
    "dial_code": "+248",
    "code": "SC"
  },
  {
    "name": "Sierra Leone",
    "dial_code": "+232",
    "code": "SL"
  },
  {
    "name": "Singapore",
    "dial_code": "+65",
    "code": "SG"
  },
  {
    "name": "Slovakia",
    "dial_code": "+421",
    "code": "SK"
  },
  {
    "name": "Slovenia",
    "dial_code": "+386",
    "code": "SI"
  },
  {
    "name": "Solomon Islands",
    "dial_code": "+677",
    "code": "SB"
  },
  {
    "name": "Somalia",
    "dial_code": "+252",
    "code": "SO"
  },
  {
    "name": "South Africa",
    "dial_code": "+27",
    "code": "ZA"
  },
  {
    "name": "Spain",
    "dial_code": "+34",
    "code": "ES"
  },
  {
    "name": "Sri Lanka",
    "dial_code": "+94",
    "code": "LK"
  },
  {
    "name": "Sudan",
    "dial_code": "+249",
    "code": "SD"
  },
  {
    "name": "South Sudan",
    "dial_code": "+211",
    "code": "SS"
  },
  {
    "name": "Suriname",
    "dial_code": "+597",
    "code": "SR"
  },
  {
    "name": "Eswatini (fmr. 'Swaziland')",
    "dial_code": "+268",
    "code": "SZ"
  },
  {
    "name": "Sweden",
    "dial_code": "+46",
    "code": "SE"
  },
  {
    "name": "Switzerland",
    "dial_code": "+41",
    "code": "CH"
  },
  {
    "name": "Syria",
    "dial_code": "+963",
    "code": "SY"
  },
  {
    "name": "Tajikistan",
    "dial_code": "+992",
    "code": "TJ"
  },
  {
    "name": "Tanzania",
    "dial_code": "+255",
    "code": "TZ"
  },
  {
    "name": "Thailand",
    "dial_code": "+66",
    "code": "TH"
  },
  {
    "name": "Timor-Leste",
    "dial_code": "+670",
    "code": "TL"
  },
  {
    "name": "Togo",
    "dial_code": "+228",
    "code": "TG"
  },
  {
    "name": "Tonga",
    "dial_code": "+676",
    "code": "TO"
  },
  {
    "name": "Trinidad and Tobago",
    "dial_code": "+1 868",
    "code": "TT"
  },
  {
    "name": "Tunisia",
    "dial_code": "+216",
    "code": "TN"
  },
  {
    "name": "Turkey",
    "dial_code": "+90",
    "code": "TR"
  },
  {
    "name": "Turkmenistan",
    "dial_code": "+993",
    "code": "TM"
  },
  {
    "name": "Tuvalu",
    "dial_code": "+688",
    "code": "TV"
  },
  {
    "name": "Uganda",
    "dial_code": "+256",
    "code": "UG"
  },
  {
    "name": "Ukraine",
    "dial_code": "+380",
    "code": "UA"
  },
  {
    "name": "United Arab Emirates",
    "dial_code": "+971",
    "code": "AE"
  },
  {
    "name": "United Kingdom",
    "dial_code": "+44",
    "code": "GB"
  },
  {
    "name": "United States of America",
    "dial_code": "+1",
    "code": "US"
  },
  {
    "name": "Uruguay",
    "dial_code": "+598",
    "code": "UY"
  },
  {
    "name": "Uzbekistan",
    "dial_code": "+998",
    "code": "UZ"
  },
  {
    "name": "Vanuatu",
    "dial_code": "+678",
    "code": "VU"
  },
  {
    "name": "Venezuela",
    "dial_code": "+58",
    "code": "VE"
  },
  {
    "name": "Vietnam",
    "dial_code": "+84",
    "code": "VN"
  },
  {
    "name": "Yemen",
    "dial_code": "+967",
    "code": "YE"
  },
  {
    "name": "Zambia",
    "dial_code": "+260",
    "code": "ZM"
  },
  {
    "name": "Zimbabwe",
    "dial_code": "+263",
    "code": "ZW"
  }
]
export const CONFIGUSSTATESLIST = [
  {
    "name": "Alabama",
    "code": "AL"
  },
  {
    "name": "Alaska",
    "code": "AK"
  },
  {
    "name": "American Samoa",
    "code": "AS"
  },
  {
    "name": "Arizona",
    "code": "AZ"
  },
  {
    "name": "Arkansas",
    "code": "AR"
  },
  {
    "name": "California",
    "code": "CA"
  },
  {
    "name": "Colorado",
    "code": "CO"
  },
  {
    "name": "Connecticut",
    "code": "CT"
  },
  {
    "name": "Delaware",
    "code": "DE"
  },
  {
    "name": "District of Columbia",
    "code": "DC"
  },
  {
    "name": "Florida",
    "code": "FL"
  },
  {
    "name": "Georgia",
    "code": "GA"
  },
  {
    "name": "Guam",
    "code": "GU"
  },
  {
    "name": "Hawaii",
    "code": "HI"
  },
  {
    "name": "Idaho",
    "code": "ID"
  },
  {
    "name": "Illinois",
    "code": "IL"
  },
  {
    "name": "Indiana",
    "code": "IN"
  },
  {
    "name": "Iowa",
    "code": "IA"
  },
  {
    "name": "Kansas",
    "code": "KS"
  },
  {
    "name": "Kentucky",
    "code": "KY"
  },
  {
    "name": "Louisiana",
    "code": "LA"
  },
  {
    "name": "Maine",
    "code": "ME"
  },
  {
    "name": "Maryland",
    "code": "MD"
  },
  {
    "name": "Massachusetts",
    "code": "MA"
  },
  {
    "name": "Michigan",
    "code": "MI"
  },
  {
    "name": "Minnesota",
    "code": "MN"
  },
  {
    "name": "Mississippi",
    "code": "MS"
  },
  {
    "name": "Missouri",
    "code": "MO"
  },
  {
    "name": "Montana",
    "code": "MT"
  },
  {
    "name": "Nebraska",
    "code": "NE"
  },
  {
    "name": "Nevada",
    "code": "NV"
  },
  {
    "name": "New Hampshire",
    "code": "NH"
  },
  {
    "name": "New Jersey",
    "code": "NJ"
  },
  {
    "name": "New Mexico",
    "code": "NM"
  },
  {
    "name": "New York",
    "code": "NY"
  },
  {
    "name": "North Carolina",
    "code": "NC"
  },
  {
    "name": "North Dakota",
    "code": "ND"
  },
  {
    "name": "Northern Mariana Islands",
    "code": "MP"
  },
  {
    "name": "Ohio",
    "code": "OH"
  },
  {
    "name": "Oklahoma",
    "code": "OK"
  },
  {
    "name": "Oregon",
    "code": "OR"
  },
  {
    "name": "Pennsylvania",
    "code": "PA"
  },
  {
    "name": "Puerto Rico",
    "code": "PR"
  },
  {
    "name": "Rhode Island",
    "code": "RI"
  },
  {
    "name": "South Carolina",
    "code": "SC"
  },
  {
    "name": "South Dakota",
    "code": "SD"
  },
  {
    "name": "Tennessee",
    "code": "TN"
  },
  {
    "name": "Texas",
    "code": "TX"
  },
  {
    "name": "Utah",
    "code": "UT"
  },
  {
    "name": "Vermont",
    "code": "VT"
  },
  {
    "name": "Virgin Islands",
    "code": "VI"
  },
  {
    "name": "Virginia",
    "code": "VA"
  },
  {
    "name": "Washington",
    "code": "WA"
  },
  {
    "name": "West Virginia",
    "code": "WV"
  },
  {
    "name": "Wisconsin",
    "code": "WI"
  },
  {
    "name": "Wyoming",
    "code": "WY"
  }
]

export const CONFIGCANADASTATESLIST = [
  {
    "name": "Alberta",
    "code": "AB"
  },
  {
    "name": "British Columbia",
    "code": "BC"
  },
  {
    "name": "Manitoba",
    "code": "Manitoba"
  },
  {
    "name": "New Brunswick",
    "code": "New Brunswick"
  },
  {
    "name": "Newfoundland and Labrador",
    "code": "Newfoundland and Labrador"
  },
  {
    "name": "Northwest Territories",
    "code": "Northwest Territories"
  },
  {
    "name": "Nova Scotia",
    "code": "Nova Scotia"
  },
  {
    "name": "Nunavut",
    "code": "Nunavut"
  },
  {
    "name": "Ontario",
    "code": "ON"
  },
  {
    "name": "Prince Edward Island",
    "code": "Prince Edward Island"
  },
  {
    "name": "Quebec",
    "code": "Quebec"
  },
  {
    "name": "Saskatchewan",
    "code": "Saskatchewan"
  },
  {
    "name": "Yukon",
    "code": "Yukon"
  }
]
export const ZOOMURL = "https://zoomapp-70cd2.web.app/";