import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Fab, TextField, Button, CircularProgress, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core'
import withStyles from '@material-ui/styles/withStyles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from "@material-ui/icons/Add"
import { Modal, Alert } from 'antd'
import moment from 'moment'
import { createChatsHistory, updateChatsHistory, deleteChatsHistory, getChatsHistoryList, getUserInfo } from '../../store/actions/EMRAction'
import Functions from '../../assets/js/function'
import RefreshIcon from '@material-ui/icons/Refresh'

const styles = theme => ({
    parent: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        width: '100%',
        flexDirection: 'column',
        paddingBottom: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
    panel: {
        padding: 0,
        width: '100%',
        maxWidth: '100%',
        backgroundColor: '#fff',
        // boxShadow: 'none',
        borderBottom: '1px solid #80808036',
        marginBottom: theme.spacing(2)
    },
    title: {
        textAlign: 'left',
        fontWeight: 700,
        color: '#fff',
        fontSize: '20px'
    },
    titleSpan: {
        fontSize: '16px'
    },
    titleDiv: {
        backgroundColor: '#00bbbe',
        display: 'flex',
        flexDirection: 'column',
        padding: '15px'
    },
    info: {
        textAlign: 'left',
        fontWeight: 700,
        color: '#000',
        padding: '15px',
        fontSize: '18px'
    },
    fab: {
        marginBottom: theme.spacing(1.5)
    },
    list: {
        display: 'flex',
        flexDirection: 'column'
    },
    listButtons: {
        display: 'flex',
        marginTop: theme.spacing(2)
    },
    listButton: {
        marginRight: theme.spacing(2)
    },
    progress: {
        marginLeft: theme.spacing(2)
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        textAlign: 'left'
    },
    error: {
        marginBottom: theme.spacing(2)
    },
    doctorsList: {
        display: 'flex',
        alignItems: 'center',
        margin: '2rem 3% 1rem',
        width: '94%',
        flexDirection: 'row',
        textAlign: 'left'
    }
})



class NormalChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            addChat: false,
            addVideoChat: false,
            chatVal: '',
            disable: true,
            disableEdit: false,
            error: false,
            editerror: false,
            loader: false,
            expansionkey: -1,
            messageEditVal: '',
            doctorId: props.doctorId,
            searchDoctorId: '',
            userId: props.userId,
            comingPage: props.pageType,
            usersArray: [],
            loadItems: true,
            chatHistoryList: { data: [] },
            responseMessage: '',
            pagination_id: 0,
            searchPatientsList: {}
        }
    }
    componentDidMount() {
        this.getUsersList()
        this.getChatHistoryAllList(this.props.doctorId)
        this.props.getUserInfo({ userId: this.props.userId })
    }
    addDragedItems = (item) => {
        let doctorName = this.state.searchPatientsList[this.props.doctorId].name
        let doctorSpeciality = this.state.searchPatientsList[this.props.doctorId].speciality
        let previousData = this.state.chatHistoryList.data
        previousData.splice(0, 0, {
            created_at: Math.floor(new Date().getTime() / 1000),
            updated_at: Math.floor(new Date().getTime() / 1000),
            doctorId: this.props.doctorId,
            doctorName: doctorName,
            speciality: doctorSpeciality,
            message: item
        })
        this.setState({ chatHistoryList: { data: previousData }, chatVal: item }, async () => {
            this.onSave()
        })
    }
    getUsersList = async () => {
        let searchPatientsList = {}
        if (this.props.userId) {
            let result = await Functions.searchPatients({ type: 'Patient', accountId: this.props.userId })
            let usersArray = []
            let doctorIdsList = []
            for (let i = 0; i < result.data.length; i++) {
                usersArray.push(<MenuItem key={i} value={result.data[i].id}>{result.data[i].name} - {result.data[i].speciality}</MenuItem>)
                doctorIdsList.push(result.data[i].id)
                searchPatientsList[result.data[i].id] = { name: result.data[i].name, speciality: result.data[i].speciality }
            }

            if (usersArray.length === 0)
                usersArray.push(<MenuItem key={0} value={-1}>No Doctors Found</MenuItem>)
            else
                usersArray.push(<MenuItem key={-1} value={doctorIdsList.join(',')}>All</MenuItem>)
            this.setState({ usersArray: usersArray, searchPatientsList: searchPatientsList })
        }
    }
    componentDidUpdate() {
        if (this.state.loader === true && this.props.message !== '') {
            this.setState({ errormsg: this.props.message, loader: false, editerrormsg: this.props.message })
        }
        else {
            setTimeout(() => {
                if (this.state.loader === true && this.props.message === '') {
                    this.setState({ errormsg: '', addChat: false, addVideoChat: false, loader: false, chatVal: '', editerrormsg: '' })
                }
            }, 1000)
        }
    }
    addChat = () => {
        this.setState({ addChat: true })
    }
    addVideoChat = () => {
        this.setState({ addVideoChat: true })
    }
    onSave = async () => {
        this.setState({ loader: true })
        let searchIdsArray = (this.state.searchDoctorId).split(',')
        let doctorId = searchIdsArray[0]
        if (searchIdsArray.length === 1) {
            searchIdsArray = []
        }
        let pagination_id = this.state.pagination_id
        let result = await this.props.createChatsHistory(JSON.stringify({ message: this.state.chatVal, userId: this.state.userId, doctorId: this.state.doctorId, viewerId: doctorId, pagination_id: pagination_id, searchDoctorIds: searchIdsArray }))
        let previousData = result.data
        this.setState({ chatHistoryList: { data: previousData }, responseMessage: result.message, pagination_id: result.pagination_id })
    }
    onUpdate = async (type, id) => {
        this.setState({ loader: true })
        let searchIdsArray = (this.state.searchDoctorId).split(',')
        let doctorId = searchIdsArray[0]
        if (searchIdsArray.length === 1) {
            searchIdsArray = []
        }

        let result = ''
        let pagination_id = this.state.pagination_id

        if (type === 1)
            result = await this.props.updateChatsHistory(JSON.stringify({ message: this.state.messageEditVal, id: id, userId: this.state.userId, doctorId: this.state.doctorId, viewerId: doctorId, pagination_id: pagination_id, searchDoctorIds: searchIdsArray }))
        else {
            result = await this.props.deleteChatsHistory(JSON.stringify({ id: id, userId: this.state.userId, doctorId: this.state.doctorId, viewerId: doctorId, pagination_id: pagination_id, searchDoctorIds: searchIdsArray }))
            this.setState({ expansionkey: -1 })
        }
        let previousData = result.data
        this.setState({ chatHistoryList: { data: previousData }, responseMessage: result.message, pagination_id: result.pagination_id })
    }
    onCancel = () => {
        this.setState({ errormsg: '', addChat: false, addVideoChat: false, loader: false, chatVal: '' })
    }
    onClick = (key, chat, doctorId, chatId) => {
        if (key === this.state.expansionkey)
            key = -1
        if (key >= 0 && doctorId !== this.props.doctorId) {
            Functions.chatViewedByProvider(JSON.stringify({ createdBy: doctorId, viewedBy: this.props.doctorId, chatId: chatId }))
        }
        this.setState({ expansionkey: key, messageEditVal: chat })
    }
    handleChange = (e) => {
        this.setState({ chatVal: e.target.value }, () => {
            if (this.state.chatVal !== '') {
                this.setState({ disable: false, error: false })
            }
            else {
                this.setState({ error: true, disable: true })
            }
        }
        )
    }
    handleEditChange = (e) => {
        this.setState({ messageEditVal: e.target.value }, () => {
            if (this.state.messageEditVal !== '') {
                this.setState({ disableEdit: false, editerror: false })
            }
            else {
                this.setState({ editerror: true, disableEdit: true })
            }
        }
        )
    }

    getChatHistoryAllList = async (list, doctorChanged = 0) => {
        this.setState({ searchDoctorId: list }, this.checkDisable)
        let searchIdsArray = (list).split(',')
        let doctorId = searchIdsArray[0]
        if (searchIdsArray.length === 1) {
            searchIdsArray = []
        }
        let pagination_id = this.state.pagination_id
        if (doctorChanged) {
            pagination_id = 0
        }
        let result = await this.props.getChatsHistoryList({ doctorId: doctorId, userId: this.props.userId, searchDoctorIds: searchIdsArray, pagination_id: pagination_id })
        let previousData = this.state.chatHistoryList.data
        if (doctorChanged || pagination_id === 0)
            previousData = result.data
        else
            previousData = previousData.concat(result.data)
        this.setState({ loadItems: false, chatHistoryList: { data: previousData }, responseMessage: result.message, pagination_id: result.pagination_id })
    }

    render() {
        const { userInfo, classes } = this.props;
        const boxShadow = '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
        return (
            <div className={classes.parent} style={{ boxShadow: boxShadow }}>
                {this.state.comingPage !== 'progress' ?
                    <Grid>
                        <FormControl variant="outlined" className={classes.doctorsList}>
                            <InputLabel id="user">Select Doctor To View Notes</InputLabel>
                            <Select
                                labelId="user"
                                label="Select Doctor To View Notes"
                                name="doctorId"
                                variant="outlined"
                                required
                                fullWidth
                                className={classes.alignLeft}
                                value={this.state.searchDoctorId}
                                onChange={(event) => this.getChatHistoryAllList(event.target.value, 1)}
                            >
                                {this.state.usersArray}
                            </Select>
                            <RefreshIcon className={classes.cursor} onClick={this.getUsersList} />
                        </FormControl>
                    </Grid>
                    :
                    ""
                }
                {userInfo.fullName ?
                    <>
                        {this.state.comingPage !== 'progress' ? <div className={classes.titleDiv}>
                            <Grid className={classes.title}>{userInfo.fullName}<span className={classes.titleSpan}>-{userInfo.phone}</span></Grid>
                            <span className={classes.title + ' ' + classes.titleSpan}>{userInfo.email}-{userInfo.street}, {userInfo.city}, {userInfo.state}, {userInfo.country}</span>
                        </div> : ""}
                        {this.state.comingPage !== 'progress' ?
                            <Grid className={classes.info}>
                                {userInfo.fullName}
                                {', DOB '}
                                {moment(userInfo.dateofBirth).format('MMMM D, YYYY')}
                                {', '}
                                {userInfo.gender === 'Male' ? 'M' : 'F'}
                            </Grid> : ""}

                        {this.state.chatHistoryList.data.length === 0 ?
                            <p key={0}>No History Found</p>
                            :
                            <>
                                {!this.state.loadItems ?
                                    this.state.chatHistoryList.data.length === 0 ?
                                        <p key={0}>No Social History Found</p> :
                                        <>
                                            {this.state.chatHistoryList.data.map((chat, key) => {
                                                return <ExpansionPanel key={key} expanded={key === this.state.expansionkey ? true : false} onChange={this.expanded} className={classes.panel}>
                                                    <ExpansionPanelSummary onClick={() => this.onClick(key, chat.message, chat.doctorId, chat.id)}
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <div className={classes.header}>
                                                            <Typography>Progress Note: Date - {moment(chat.created_at * 1000).format('MMMM D, YYYY')}
                                                                {chat.doctorId !== this.props.doctorId ?
                                                                    <div>
                                                                        {chat.doctorName} ({chat.speciality})
                                        </div>
                                                                    : ""}
                                                            </Typography>
                                                            <Typography>Last Edited: {moment(chat.updated_at * 1000).format('MMMM D, YYYY')}</Typography>
                                                        </div>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails className={classes.list}>
                                                        {this.state.editerrormsg ?
                                                            <Grid className={classes.error}>
                                                                <Alert type="error" closable={true} message={this.state.editerrormsg}></Alert>
                                                            </Grid>
                                                            : ""}
                                                        <TextField
                                                            type="text"
                                                            variant="outlined"
                                                            margin="normal"
                                                            multiline
                                                            autoFocus
                                                            required
                                                            label="Message"
                                                            error={this.state.editerror}
                                                            helperText={this.state.editerror ? "Message field required" : ""}
                                                            value={this.state.messageEditVal}
                                                            onChange={this.handleEditChange}
                                                        />
                                                        <div className={classes.listButtons}>
                                                            {this.state.loader ?
                                                                <CircularProgress size={24} /> :
                                                                chat.doctorId === this.props.doctorId ?
                                                                    <div>
                                                                        <Button className={classes.listButton} color="primary" disabled={this.state.disableEdit} variant="contained" onClick={() => this.onUpdate(1, chat.id)}>Save</Button>
                                                                        <Button variant="contained" color="secondary" onClick={() => this.onUpdate(0, chat.id)}>Delete</Button>
                                                                    </div>
                                                                    : ""
                                                            }
                                                        </div>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            })
                                            }
                                            {this.state.pagination_id !== -1 &&
                                                <div className={classes.listButtons + " " + classes.progress}>
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => this.getChatHistoryAllList(this.state.searchDoctorId)}
                                                        className={classes.listButton}
                                                    >
                                                        View More
                                </Button>
                                                </div>
                                            }
                                        </>
                                    :
                                    <CircularProgress size={24} />
                                }
                            </>
                        }



                        {/* {ChatsList} */}
                        {this.state.addVideoChat ?
                            <Grid className={classes.info}>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    margin="normal"
                                    multiline
                                    autoFocus
                                    required
                                    label="Note"
                                    error={this.state.error}
                                    helperText={this.state.error ? "Note field required" : ""}
                                    value={this.state.chatVal}
                                    onChange={this.handleChange}
                                />
                                <div className={classes.listButtons}>
                                    {this.state.loader ?
                                        <CircularProgress size={24} /> :
                                        <div>
                                            <Button className={classes.listButton} color="primary" disabled={this.state.disable} variant="contained" onClick={this.onSave}>Save</Button>
                                            <Button variant="contained" color="secondary" onClick={this.onCancel}>Cancel</Button>
                                        </div>
                                    }
                                </div>
                            </Grid>
                            : ""}
                        <Fab color="secondary" className={classes.add + " " + classes.fab} onClick={this.state.comingPage !== 'progress' ? this.addChat : this.addVideoChat}>
                            <AddIcon />
                        </Fab>
                        <Modal
                            title={"Add Note"}
                            visible={this.state.addChat}
                            width={800}
                            onCancel={this.onCancel}
                            footer={
                                <Grid>
                                    {this.state.loader ?
                                        <CircularProgress size={24} /> :
                                        <Grid>
                                            <Button color="secondary" disabled={this.state.disable} variant="contained" onClick={this.onSave}>Save</Button>
                                            <Button variant="outlined" onClick={this.onCancel}>Cancel</Button>
                                        </Grid>
                                    }
                                </Grid>
                            }
                        >

                            <Grid className={classes.modal}>
                                {this.state.errormsg ?
                                    <Grid className={classes.error}>
                                        <Alert type="error" closable={true} message={this.state.errormsg}></Alert>
                                    </Grid>
                                    : ""}
                            </Grid>
                            <Grid>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    margin="normal"
                                    multiline
                                    autoFocus
                                    required
                                    label="Note"
                                    error={this.state.error}
                                    helperText={this.state.error ? "Note field required" : ""}
                                    value={this.state.chatVal}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                        </Modal>
                    </>
                    :
                    <CircularProgress className={classes.progress} size={24} />
                }
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        userInfo: state.emr.UserInfo,
        message: state.emr.message,
        chats: state.emr.ChatsHistoryList
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getChatsHistoryList: (data) => getChatsHistoryList(data),
        createChatsHistory: (data) => createChatsHistory(data),
        updateChatsHistory: (data) => updateChatsHistory(data),
        deleteChatsHistory: (data) => deleteChatsHistory(data),
        getUserInfo: (data) => dispatch(getUserInfo(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NormalChat))