import React, { Component } from 'react'
import withStyles from '@material-ui/styles/withStyles'
import { TextField, Grid } from '@material-ui/core'

const styles = theme => ({
    space: {
        margin: theme.spacing(1, 0) + " !important"
    }
})

class RequisitionForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            form: {
                Note: ''
            },
        }
    }

    onChange = (e) => {
        // GET VALUES ARRAY
        let valuesArray = this.state.form
        // SET VALUE FOR A SPECIFIC FIELD OF FORM
        valuesArray[e.target.name] = e.target.value

        // SET STATE VARIABLES FOR ERROR AND VALUES
        this.setState({ form: valuesArray })
        this.props.updateRequisitionData(valuesArray)
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid>
                <TextField
                    multiline
                    rows={10}
                    variant="outlined"
                    label="Note *"
                    name={"Note"}
                    className={classes.space}
                    error={!this.state.form.Note && this.props.formSubmitted}
                    helperText={!this.state.form.Note && this.props.formSubmitted ? "Note field required" : ""}
                    value={this.state.form.Note}
                    onChange={this.onChange}
                    onBlur={this.onChange}
                />
            </Grid>
        );
    }
}

export default withStyles(styles)(RequisitionForm)