
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    popup: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column'
    },
    popupDiv: {
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    popupButton: {
        margin: theme.spacing(2, 0),
        fontFamily: 'inherit',
        color: '#ffffff'
    },
    popupHeading: {
        fontFamily: 'inherit',
        fontWeight: 700,
        paddingBottom: theme.spacing(6),
        color: '#795bb2',
        fontSize: theme.spacing(2.4)
    },
    font: {
        fontFamily: 'revert',
    },
    mainDiv: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    formButton: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    mobile_number: {
        display: 'flex',
        alignItems: 'center'
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    formOuterWrapper: {
        display: 'flex',
        flex: 1,
        padding: "100px 150px",
        marginLeft: '35%',
        backgroundColor: "#fafafa",
        [theme.breakpoints.down(1600)]: {
            padding: "50px 80px",
        },
        [theme.breakpoints.down(1366)]: {
            padding: "40px 60px",
        },
        [theme.breakpoints.down(1100)]: {
            marginLeft: '0',
            padding: "15px",
        },
    },
    paper: {
        padding: theme.spacing(5, 10),
        color: theme.palette.text.secondary,
        width: '60%',
        backgroundColor: "#fff",
        [theme.breakpoints.down(1366)]: {
            padding: theme.spacing(3, 7),
        },
        [theme.breakpoints.down(1100)]: {
            padding: theme.spacing(2, 2),
        },
    },
    switchAccount: {
        backgroundColor: 'rgb(255,165,112)',
        borderRadius: '0.5rem',
        width: '9rem',
        heigth: '6rem',
        color: 'white',
        '& :hover': {
            cursor: 'pointer'
        },
    },
    accountUserName: {
        margin: 'auto 10px'
    },
    switchAccountDiv:{
        display: 'flex',
        [theme.breakpoints.down('sm')]:{
            flexDirection: 'column',
            alignItems: 'center',
            margin: '20px 0'
        }
    }
}));

export default useStyles