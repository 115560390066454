import React, { useState, useEffect } from 'react'
import { Grid, Typography, CircularProgress, Fab } from '@material-ui/core'
import { progress, sectionDetail, header, mainStyle, fabStyle } from './style'
import { getAllProgressTemplates } from './API/API'
import ViewMore from './ViewMore'
import TableRow from './TableRow'
import SectionModal from './SectionModal'
import AddIcon from "@material-ui/icons/Add"
import { Alert } from 'antd'

function ProgressTemplates({ isRhynoGoPatient, patientName, type, patientId, doctorId, noteTemplateDrag }) {

    const [templates, setTemplates] = useState([])
    const [pagination, setPagination] = useState('')
    const [loader, setLoader] = useState(true)
    const [openModel, setOpenModel] = useState(false)
    const [successMessage, setSuccessMessage] = useState()

    const getAllUserProgressTemplates = async () => {
        setLoader(true)
        let result = await getAllProgressTemplates(patientId, doctorId, pagination, isRhynoGoPatient, patientName)
        setTemplates(templates.concat(result.data))
        setPagination(result.pagination_id)
        setLoader(false)
    }

    useEffect(() => {
        getAllUserProgressTemplates()
    }, [])

    const openCloseModel = (e, isSuccess = false, action, data, pagination_id, isChild = false) => {
        if (isSuccess) {
            setSuccessMessage(`Template ${action} Successfully`)
            setTemplates(data)
            setPagination(pagination_id)
        }
        else {
            setSuccessMessage('')
        }
        if (!isChild) {
            setOpenModel(!openModel)
        }
    }

    const alertClosed = () => {
        setSuccessMessage('')
    }

    return (
        <Grid style={mainStyle}>
            <Grid style={sectionDetail}>
                {successMessage && <Alert type="success" closable={true} onClose={alertClosed} message={successMessage}></Alert>}
                <Grid key={-1}>
                    <Typography style={header}>{'Template'}</Typography>
                    <Typography>{''}</Typography>
                </Grid>
                {templates.map((template, key) =>
                    <div ref={noteTemplateDrag} key={key} className={"asdasdasdasd"}>
                        <TableRow
                            key={key}
                            row={template}
                            type="templates"
                            id={template.id}
                            col1={template.name}
                            patientName={patientName}
                            isRhynoGoPatient={isRhynoGoPatient}
                            data={{ patientId, doctorId, pagination }}
                            onChangeRecord={openCloseModel} />
                    </div>
                )
                }
                {loader && <CircularProgress style={progress} size={24} />}
                {pagination !== -1 && <ViewMore loader={loader} getMoreRecords={getAllUserProgressTemplates} />}
            </Grid >
            <Fab color="primary" style={fabStyle}>
                <AddIcon onClick={openCloseModel} />
            </Fab>
            {openModel && <SectionModal patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} type={type} onCloseModel={openCloseModel} data={{ patientId, doctorId, pagination }} />}
        </Grid>
    )
}

export default ProgressTemplates
