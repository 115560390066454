import React, { Component } from 'react'
import withStyles from "@material-ui/core/styles/withStyles"
import { Grid, CircularProgress } from '@material-ui/core'
import Address from './Address'
import Filters from './filters'
import Calendar from './calendar'
import OptionList from './optionsList'
import Reason from './reason'
import HealthCard from './healthCard'
import Forms from './forms'
import SickNotes from './sicknote'
import AddDocuments from './adddocuments'
import RhynoGoDocuments from './rhynogodocuments'
import { connect } from 'react-redux'
import RhynoGoCalendar from './rhynogocalendar'
import RhynoSelectUsers from './RhynoSelectUsers'
import { verifyHC } from '../../store/actions/appointmentAction'
import SwitchAccount from '../subAccounts/SwitchAccount'
import { isMobile } from "react-device-detect"

const styles = () => ({
    font: {
        fontFamily: 'revert',
    }
})
class Steps extends Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = { appointmentData: { speciality: '' } }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true
        this.calculateStepNo(true)
    }
    calculateStepNo = async (isStart, type = "") => {
        let step = -1
        let cardDetails = { healthCard: '', HCExpiryDate: '' }
        if (this.props.account.role === 'Agency') {
            step = 1
            cardDetails.appointmentType = 'rhynogo'
            cardDetails.showProviderAppointmentTypes = true
            cardDetails.isAgency = 'Agency'
        }
        else {
            await this.updateStepNo(step, {})
            if (isStart) {
                if (this.props.account.country === '') {
                    step = 0
                }
                else {
                    step = 1
                }
            }
            else {
                let data = this.state.appointmentData
                data.appointmentType = type
                data.showProviderAppointmentTypes = true
                this.setState({ appointmentData: data })
                if (!this.props.account.hasOwnProperty('hCard_added') || (this.props.account.hCard_added && this.props.hCard_added === true && this.props.hCard === '')) {
                    step = 0
                }
                else if (this.props.account.hCard_added === false) {
                    step = 1
                } else {
                    let result = await this.props.verifyHC(JSON.stringify({ card: this.props.account.hCard, expiryDate: this.props.account.dateofHCExp, state: this.props.account.state }))
                    if (result.status === 1) {
                        step = 1
                        cardDetails = { healthCard: this.props.account.hCard, HCExpiryDate: this.props.account.dateofHCExp }
                    }
                    else {
                        step = 0
                    }
                }

            }
        }
        this.updateStepNo(step, cardDetails)
    }
    updateStepNo = async (step, appointmentData) => {
        if (this._isMounted) {
            if (appointmentData.hasOwnProperty('hideRhynoGoOption') && appointmentData.hideRhynoGoOption)
                await this.setState({ step: step, appointmentData: appointmentData })
            else
                await this.setState({ step: step, appointmentData: { ...this.state.appointmentData, ...appointmentData } })
        }
    }
    render() {
        let { classes } = this.props
        return (
            <Grid className={classes.font}>
                {/* SKYPE CONVERSATION
                G-Man, 9:43 AM
                Let us just keep it on:
                - Right side of logo on desktop
                - Top of all pages on Account, appointments, book now, Dashboard in mobile.
                SHOW SWITCH ACCOUNT BUTTON IN MOBILE VIEW */}
                {isMobile && <SwitchAccount />}
                {
                    this.state.step === 0 ?
                        <Address stepNo={this.state.step} updateStepNo={this.updateStepNo} />
                        : this.state.step === 1 ?
                            <Filters updateStepNo={this.updateStepNo} calculateStepNo={this.calculateStepNo} appointmentData={this.state.appointmentData} />
                            :
                            this.state.step === 2 ?
                                <Calendar updateStepNo={this.updateStepNo} appointmentData={this.state.appointmentData} />
                                :
                                this.state.step === 3 ?
                                    <OptionList updateStepNo={this.updateStepNo} appointmentData={this.state.appointmentData} />
                                    :
                                    this.state.step === 4 ?
                                        <Reason updateStepNo={this.updateStepNo} appointmentData={this.state.appointmentData} />
                                        :
                                        this.state.step === 5 ?
                                            <HealthCard updateStepNo={this.updateStepNo} appointmentData={this.state.appointmentData} />
                                            :
                                            this.state.step === 6 ?
                                                <Forms updateStepNo={this.updateStepNo} appointmentData={this.state.appointmentData} />
                                                :
                                                this.state.step === 7 ?
                                                    <SickNotes updateStepNo={this.updateStepNo} appointmentData={this.state.appointmentData} />
                                                    :
                                                    this.state.step === 8 ?
                                                        <AddDocuments updateStepNo={this.updateStepNo} appointmentData={this.state.appointmentData} />
                                                        :
                                                        this.state.step === 9 ?
                                                            <RhynoGoDocuments updateStepNo={this.updateStepNo} appointmentData={this.state.appointmentData} />
                                                            :
                                                            this.state.step === 10 ?
                                                                <RhynoGoCalendar updateStepNo={this.updateStepNo} appointmentData={this.state.appointmentData} />
                                                                :
                                                                this.state.step === 11 ?
                                                                    <RhynoSelectUsers updateStepNo={this.updateStepNo} appointmentData={this.state.appointmentData} />
                                                                    :
                                                                    <CircularProgress size={24} />
                }
            </Grid>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        account: state.account.data
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        verifyHC: (data) => verifyHC(data)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Steps));