import React, { useState } from 'react'
import RequisitionForm from './RequisitionForm'
import { Button, Grid, CircularProgress } from '@material-ui/core'
import { CreateNote } from '../../../../store/actions/EMRAction'
import { viewMoreStyle } from '../../style'

function Covid19Requisition({ onCloseModel, isRhynoGoPatient, patientName, data }) {

    const [covidRequisitionData, setCovidRequisitionData] = useState({})
    const [loader, setLoader] = useState(false)

    const updateRequisitionData = (data) => {
        setCovidRequisitionData(data)
    }

    const cancelCovidRequisition = async () => {
        onCloseModel(false, {}, 1)
    }

    const saveRequisition = async () => {
        setLoader(true)

        let updatedFormData = {}
        updatedFormData = { ...covidRequisitionData, ...updatedFormData }
        for (var propName in updatedFormData) {
            if (updatedFormData[propName] === null || updatedFormData[propName] === undefined) {
                delete updatedFormData[propName];
            }
            if (typeof (updatedFormData[propName]) === 'object') {
                if (updatedFormData[propName].radio === '')
                    delete updatedFormData[propName];
            }
        }
        // CREATE COVID REQUISITION 
        let sentObj = JSON.stringify({
            noteType: 'CovidRequisition',
            userId: data.patientId,
            doctorId: data.doctorId,
            isRhynoGoPatient,
            patientName,
            userData: {},
            doctorData: {},
            formData: updatedFormData,
            pagination_id: data.pagination,
            appointmentId: data.hasOwnProperty('appointmentId') ? data.appointmentId : ""
        })
        // CREATE NOTE 
        let response = await CreateNote(sentObj)
        setLoader(false)
        onCloseModel(true, response.data, response.pagination_id)
    }

    return (
        <div>
            <RequisitionForm updateRequisitionData={updateRequisitionData} />
            <Grid style={buttonStyle}>
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={loader}
                    className={'btnCustomClass lightGreyClasstbn'}
                    style={viewMoreStyle}
                    onClick={cancelCovidRequisition}>Cancel</Button>
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={loader}
                    className={'btnCustomClass lightBlueClasstbn'}
                    style={viewMoreStyle}
                    onClick={saveRequisition}>{loader ? <CircularProgress size={24} /> : "Save"}</Button>
            </Grid>
        </div>
    )
}
const buttonStyle = {
    display: 'flex',
    justifyContent: 'space-between'
}
export default Covid19Requisition
