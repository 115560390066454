import React, { useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
//redux
import { updateAppointmentStatus, verifyHC, updateAppointmentDataAPI } from '../../../store/actions/appointmentAction'
import { connect } from 'react-redux'
//Ant-d
import { Modal } from 'antd'
//mui
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/button'
//utils
import { updateResults } from './utils'
//components
import Calendar from '../../appointment/calendar';
import RhynoGoCalendar from '../../appointment/rhynogocalendar'
const CalendarModal = (props) => {
  const { cancel, appointmentData, changeData } = props;
  // console.log(props)
  const [setStripeOb] = useState(
    { show: false, id: '', status: '', doctorId: '', paidamount: 0, holdamount: 0 }
  )
  /* FOR TASK RHYN-102
    TASK DESCRIPTION: Add patient tab and subaccounts for age <16
    CHECK IS REDUX SUBACCOUNT STATE HAS KEY subAccountId THEN GET ALL DATA RELATED TO SUBACCOUNT */
  // const userId = props.parentProps.auth.uid;
  const userId = props.parentProps.subAccount.subAccountId ? props.parentProps.subAccount.subAccountId : props.parentProps.auth.uid
  const fullName = props.parentProps.account.data.fullName;

  const cancelAppointment = async (id, status, doctorId, stripeKey = '', hold = 0) => {
    let title = ''
    if (status === 'accepted') {
      title = "Are you sure to accept the appointment time change?"
    }
    else if (status === 'cancelled') {
      title = "Are you sure to cancel the appointment?"
    }
    confirmAlert({
      title: 'Confirm',
      message: title,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            let dataObject = {
              id: id,
              status: status,
              doctorId: doctorId,
              userId: userId,
              userName: fullName
            }
            if (stripeKey === '-1') {
              dataObject.bookhCard = props.parentProps.account.data.hCard
              dataObject.bookdateofHCExp = Math.floor(new Date(props.parentProps.account.data.dateofHCExp).getTime() / 1000)
            }
            else if (stripeKey !== '') {
              dataObject.stripetoken = stripeKey
              dataObject.holdamount = hold
            }
            let result = await props.updateAppointmentStatus(JSON.stringify(dataObject))
            changeData(updateResults(result.data))
            //close
            cancel()
          }
        },
        {
          label: 'No',
          onClick: () => {
          }
        }
      ]
    });
  }
  const updateAppointment = (start) => {
    confirmAlert({
      title: 'Confirm',
      message: 'Are you sure to update the appointment?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            // document.getElementsByClassName('MuiPopover-root')[0].style.display = 'none'
            // console.log('appointment added by')
            // console.log(this.state.appointmentData.addedBy)
            if (appointmentData.addedBy === 'doctor') {
              //   console.log('appointment added by doctor')
              // CHECK USER NEED TO ENTER STRIPE DETAILS
              let result = await props.verifyHC(JSON.stringify({ doctorId: appointmentData.doctorId, card: props.parentProps.account.data.hCard, expiryDate: Math.floor(new Date(props.parentProps.account.data.dateofHCExp).getTime() / 1000), state: props.parentProps.account.data.state }))
              if (result.status === 1) {
                // console.log('user has card and its valid')
                updateChangeAppointment(start, '-1')
              } else {
                // console.log('ask user to add stripe')
                let object = { start: start, show: true, id: appointmentData.id, status: 'accepted', doctorId: appointmentData.doctorId, paidamount: 0, holdamount: appointmentData.holdamount }
                await setStripeOb({ stripeObject: object })
                document.getElementById('stripeButton').childNodes[0].click();
              }
            }
            else {
              // console.log('payment not needed, appointment added by patient')
              let end = new Date(start * 1000)
              end.setMinutes(end.getMinutes() + appointmentData.duration)

              let receivedDateInMiliSeconds = new Date(end).getTime()
              let timeStampInUTC = Math.ceil(receivedDateInMiliSeconds / 1000)

              let updatedAppointmentObj = {
                id: appointmentData.id,
                startDate: start,
                previousStartDate: appointmentData.start,
                status: appointmentData.status,
                endDate: timeStampInUTC,
                isStartChanged: true,
                appointmentType: appointmentData.appointmentType,
                doctorId: appointmentData.doctorId,
                userId: userId,
                updatedFrom: 'patient'
              }
              let result = await props.updateAppointmentDataAPI(JSON.stringify(updatedAppointmentObj))
              changeData(updateResults(result.data))
              //close
              cancel()
            }
          }
        },
        {
          label: 'No',
          onClick: () => {
            //close
            cancel()
          }
        }
      ]
    });
  }
  const updateChangeAppointment = async (start, stripe) => {
    let dataObject = {
      id: appointmentData.id,
      status: 'accepted',
      doctorId: appointmentData.doctorId,
      userId: userId,
    }
    if (stripe === '-1') {
      dataObject.bookhCard = props.parentProps.account.data.hCard
      dataObject.bookdateofHCExp = Math.floor(new Date(props.parentProps.account.data.dateofHCExp).getTime() / 1000)
    }
    else if (stripe !== '') {
      dataObject.stripetoken = stripe
      dataObject.holdamount = appointmentData.holdamount
    }
    await props.updateAppointmentStatus(JSON.stringify(dataObject))
    let end = new Date(start * 1000)
    end.setMinutes(end.getMinutes() + appointmentData.duration)
    let receivedDateInMiliSeconds = new Date(end).getTime()
    let timeStampInUTC = Math.ceil(receivedDateInMiliSeconds / 1000)
    let updatedAppointmentObj = {
      id: appointmentData.id,
      startDate: start,
      previousStartDate: appointmentData.start,
      status: appointmentData.status,
      endDate: timeStampInUTC,
      isStartChanged: true,
      appointmentType: appointmentData.appointmentType,
      doctorId: appointmentData.doctorId,
      userId: userId,
      updatedFrom: 'patient'
    }
    let result = await props.updateAppointmentDataAPI(JSON.stringify(updatedAppointmentObj))
    changeData(updateResults(result.data))
    //close
    cancel()
  }

  return (
    <div>
      <Modal
        visible={true}
        width={1200}
        zIndex={2000}
        onCancel={() => cancel()}
        closable={true}
        footer={
          <Grid>
            {/* {appointmentData.urgent === false ?  */}
            <Button style={{ backgroundColor: '#dc004e', color: '#fff' }}
              variant="contained"
              onClick={() => cancelAppointment(appointmentData.id, 'cancelled', appointmentData.doctorId)}
            >Cancel Appointment</Button>
            {/* :null} */}
          </Grid>
        }
      >
        <Grid>
          {appointmentData.appointmentType === 'rhynogo' ?
            <RhynoGoCalendar updateAppointment={updateAppointment} appointmentData={appointmentData} />
            :
            <Calendar updateAppointment={updateAppointment} appointmentData={appointmentData} />
          }
        </Grid>

      </Modal>
    </div>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateAppointmentStatus: (data) => updateAppointmentStatus(data),
    verifyHC: (data) => verifyHC(data),
    updateAppointmentDataAPI: (data) => updateAppointmentDataAPI(data),
  }
}
export default connect(null, mapDispatchToProps)(CalendarModal)