import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import RhynoGoUser from './RhynoGoUser'

function ShowAllPatients({ setPatientId, setPatientName, setIsRhynoGoPatient }) {

    const authState = useSelector(state => state.auth);
    const rhynoGoUsers = useSelector(state => state.rhynoGoUsers)

    useEffect(() => {
        if (authState) {
            if (!authState.uid) {
                window.location.href = "/signin"
            }
            if (!authState.isVerified) {
                window.location.href = "/verify"
            }
        }
    }, [authState]);

    return (
        <div>
            {rhynoGoUsers && rhynoGoUsers.users && rhynoGoUsers.users.map((user, key) =>
                <RhynoGoUser key={key} user={user} setPatientName={setPatientName} setPatientId={setPatientId} setIsRhynoGoPatient={setIsRhynoGoPatient} />
            )
            }
        </div>
    )
}

export default ShowAllPatients
