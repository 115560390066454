import React, { useState, useEffect } from 'react'
import { Grid, Typography, CircularProgress } from '@material-ui/core'
import { progress, sectionDetail, item } from './style'
import { getAllAppointments } from './API/API'
import moment from 'moment'
import ViewMore from './ViewMore'

function Appointments({ patientId, doctorId, patientName, isRhynoGoPatient }) {

    const [appointmentsList, setAppointmentsList] = useState([])
    const [pagination, setPagination] = useState('')
    const [loader, setLoader] = useState(true)

    const getAllUserAppointments = async () => {
        setLoader(true)
        let result = await getAllAppointments(patientId, doctorId, pagination, isRhynoGoPatient, patientName)
        setAppointmentsList(appointmentsList.concat(result.data))
        setPagination(result.pagination_id)
        setLoader(false)
    }

    useEffect(() => {
        getAllUserAppointments()
    }, [])

    return (
        <Grid style={sectionDetail}>
            {appointmentsList.map((appointment, key) =>
                <Typography key={key} style={item}>
                    {moment(appointment.appointmentFor * 1000).format('MMMM D, YYYY') + ' at ' + moment(appointment.appointmentFor * 1000).format('LT')}
                </Typography>
            )}
            {loader && <CircularProgress style={progress} size={24} />}
            {pagination !== -1 && <ViewMore getMoreRecords={getAllUserAppointments} />}
        </Grid >
    )
}

export default Appointments