import React, { useEffect, useState } from 'react'
import { Grid, Button, CircularProgress } from '@material-ui/core'
import { noteItemsButton } from '../style'
import OntarioLabRequisition from './OntarioLabRequisition/OntarioLabRequisition'
import Covid19Requisition from './Covid19Requisition/Covid19Requisition'
import DoctorNote from './DoctorNote/DoctorNote'
import Fax from './Fax'
import ImageRequisition from './ImageRequisition/ImageRequisition'
import Referral from './Referral/Referral'
import TravelPCRRequisition from './TravelPCR/TravelPCRRequisition'
import Uploads from './Uploads/Uploads'
import { Alert } from 'antd'
import moment from 'moment'
import { useSelector } from 'react-redux';

function NotesForm({ patientName, isRhynoGoPatient, onCloseModel, data }) {
    const { userInfo } = useSelector(
        (state) => ({
            userInfo: state.emr.UserInfo
        })
    );

    const [note, setNote] = useState('')
    const [loader, setLoader] = useState(false)
    const [labelObj, setLabelObj] = useState({})
    const [successMessage, setSuccessMessage] = useState('')

    const downloadLabel = () => {
        document.getElementById('downlaod_label').click()
    }

    useEffect(() => {
        let filecontent = "Name: " + userInfo.fullName + "\n\nDate of Birth: " + moment(userInfo.dateofBirth).format("D-MMM-YYYY")
        filecontent = 'data:application/txt;base64,' + Buffer.from(filecontent).toString('base64')
        let fileObject = { content: filecontent, label: userInfo.fullName + " Label.txt" }
        setLabelObj(fileObject)
    }, []);

    const dataAddedSuccessfully = (added = false, data, pagination_id) => {
        setNote('')
        onCloseModel(null, added, data, pagination_id, true)
        if (added) {
            setSuccessMessage("Note Added Successfully")
        }
    }

    const alertClosed = () => {
        setSuccessMessage('')
    }

    return (
        <Grid>
            {note === '' ?
                <>
                    {successMessage && <Alert type="success" closable={true} onClose={alertClosed} message={successMessage}></Alert>}

                    <Button style={noteItemsButton} variant="outlined" onClick={() => setNote('LabRequisition')}>Ontario Blood Lab Requisition</Button>
                    <Button style={noteItemsButton} variant="outlined" onClick={() => setNote('CovidRequisition')}>Ontario COVID-19 Requisition</Button>
                    <Button style={noteItemsButton} variant="outlined" onClick={() => setNote('ImageRequisition')}>Image Requisition</Button>
                    <Button style={noteItemsButton} variant="outlined" onClick={() => setNote('DoctorNote')}>Doctor's Note</Button>
                    <Button style={noteItemsButton} variant="outlined" onClick={() => setNote('Referral')}>Referral</Button>
                    <Button style={noteItemsButton} variant="outlined" onClick={() => setNote('Results')}>Upload</Button>
                    <Button style={noteItemsButton} variant="outlined" onClick={() => setNote('Covid19TravelPCRRequisition')}>COVID-19 TRAVEL PCR REQUISITION</Button>
                    <Button style={noteItemsButton} variant="outlined" onClick={downloadLabel}>DOWNLOAD LABEL</Button>
                    <Button style={noteItemsButton} variant="outlined" onClick={() => {
                        setLoader(true);
                        setNote('FaxDocument')
                    }
                    }
                    >{loader && note === 'FaxDocument' ?
                        <CircularProgress size={25} />
                        :
                        "FAX A DOCUMENT"
                        }</Button>
                    <a style={{ display: 'none' }} id={"downlaod_label"} content={labelObj && labelObj.content ? labelObj.content : ""} href={labelObj && labelObj.content ? labelObj.content : ""} download={labelObj && labelObj.label ? labelObj.label : ""}>Download</a>

                </>
                :
                note === 'LabRequisition' ?
                    <OntarioLabRequisition data={data} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} onCloseModel={dataAddedSuccessfully} />
                    :
                    note === 'CovidRequisition' ?
                        <Covid19Requisition data={data} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} onCloseModel={dataAddedSuccessfully} />
                        :
                        note === 'ImageRequisition' ?
                            <ImageRequisition data={data} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} onCloseModel={dataAddedSuccessfully} />
                            :
                            note === 'DoctorNote' ?
                                <DoctorNote data={data} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} onCloseModel={dataAddedSuccessfully} />
                                :
                                note === 'Referral' ?
                                    <Referral data={data} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} onCloseModel={dataAddedSuccessfully} />
                                    :
                                    note === 'Results' ?
                                        <Uploads data={data} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} onCloseModel={dataAddedSuccessfully} />
                                        :
                                        note === 'Covid19TravelPCRRequisition' ?
                                            <TravelPCRRequisition data={data} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} onCloseModel={dataAddedSuccessfully} />
                                            :
                                            note === 'FaxDocument' ?
                                                <Fax />
                                                :
                                                ""
            }
        </Grid>
    )
}

export default NotesForm
