import React, { Component } from 'react'
import withStyles from "@material-ui/core/styles/withStyles"
import { Grid, Button, Select, FormControl, InputLabel, MenuItem, TextField, CircularProgress } from '@material-ui/core'
import { AVAILABLECOUNTRIES, CONFIGUSSTATESLIST, CONFIGCANADASTATESLIST, GOOGLEMAPKEY } from '../../config'
import { connect } from 'react-redux'
import { updateProfile } from '../../store/actions/authActions'
import GoogleStreetAddress from './googleStreetAddress'; 

const styles = theme => ({
    popup: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column',
    },
    popupDiv: {
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            width: '100%'
        }
    },
    popupButton: {
        marginBottom: theme.spacing(2),
        fontFamily: 'inherit',
        color: '#ffffff'
    },
    popupHeading: {
        fontFamily: 'inherit',
        fontWeight: 700,
        paddingBottom: theme.spacing(6),
        color: '#795bb2',
        fontSize: theme.spacing(2.4)
    },
    font: {
        fontFamily: 'revert',
        width: '100%'
    },
    mainDiv: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    formButton: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    textLeft: {
        textAlign: 'left',
    },
    fieldsDiv: {
        display: 'flex',
        flexDirection: 'column',
        '& .MuiFormControl-root': {
            borderRadius: '0.2rem',
        },
        '& .MuiFormLabel-root': {
            paddingLeft: '3%'
        },
        '& .MuiInputBase-root': {
            paddingLeft: '3%'
        }
    },
    selecBoxesContainer: {
        display: 'contents',
        '& .MuiFormControl-root': {
            border: 'none',
        },

    },
    halfWidth: {
        maxWidth: 'calc(50% - 20px)',
        flex: 'calc(50% - 20px)',
        margin: '0 10px 20px',
        [theme.breakpoints.down(767)]: {
            maxWidth: 'calc(100%)',
            flex: 'calc(100%)',
            margin: '0 0 15px',
        },
    },
    mbzero: {
        marginBottom: theme.spacing(0) + ' !important'
    },
    buttonConfirm: {
        width: "100%",
        borderRadius: '10px',
        marginBottom: '5%',
        border: 'none',
        color: 'white',
        height: '50px',
        padding: "7px",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: "#1f4037"
        },
    }
})
class ConfirmAddress extends Component {

    constructor(props) {
        super(props)
        this._isMounted = false;
        let countriesList = [], USStatesList = [], CanadaStatesList = []
        let availableCountries = AVAILABLECOUNTRIES
        if (availableCountries) {
            countriesList = availableCountries.map((availablecountry, key) =>
                <MenuItem value={availablecountry.name} key={key}>{availablecountry.name}</MenuItem>
            )
        }
        let availableStates = {
            "US": CONFIGUSSTATESLIST,
            "Canada": CONFIGCANADASTATESLIST
        }
        if (availableStates) {
            USStatesList = (availableStates.US).map((availablestate, key) =>
                <MenuItem value={availablestate.name} key={key}>{availablestate.name}</MenuItem>
            )
            CanadaStatesList = (availableStates.Canada).map((availablestate, key) =>
                <MenuItem value={availablestate.name} key={key}>{availablestate.name}</MenuItem>
            )
        }
        this.state = {
            isFormValid: true,
            street_adderss: props.account.role === 'Patient' && props.account.temporary_street ? props.account.temporary_street : '',
            city: props.account.role === 'Patient' && props.account.temporary_city ? props.account.temporary_city : '',
            state: props.account.role === 'Patient' && props.account.temporary_state ? props.account.temporary_state : '',
            country: props.account.role === 'Patient' && props.account.temporary_country ? props.account.temporary_country : '',
            countryCode: props.account.role === 'Patient' && props.account.temporary_countryCode ? props.account.temporary_countryCode : '',
            countryDialCode: props.account.role === 'Patient' && props.account.temporary_countryDialCode ? props.account.temporary_countryDialCode : '',
            zip: props.account.role === 'Patient' && props.account.temporary_zip ? props.account.temporary_zip : '',
            phone_number: '',
            email: '',
            countriesList: countriesList,
            usStates: USStatesList,
            canadaStates: CanadaStatesList,
            uid: props.auth.uid,
            loading: false,
        }
    }

    componentDidMount() {
        this._isMounted = true
        if (!this.props.account.temporary_country || this.props.account.role !== 'Patient')
            this.browserLocation(GOOGLEMAPKEY)
    }

    fetchCoordinates = () => {
        return new Promise((resolve, reject) => {
            try {

                if (!navigator.geolocation) {
                    reject("Browser does not support geolocation API");
                }

                navigator.geolocation.getCurrentPosition(
                    location => {
                        resolve(location.coords);
                    },
                    error => {
                        switch (error.code) {
                            case "PERMISSION_DENIED":
                                return reject("Permission denied to get location");
                            case "TIMEOUT":
                                return reject("Timeout waiting for user response");
                            case "POSITION_UNAVAILABLE":
                            default: {
                                return reject("Cannot detect user location");
                            }
                        }
                    },
                    {
                        timeout: 1000,
                    }
                );

            } catch (error) {
                return reject("Browser does not support geolocation API");
            }
        });
    }

    fetchAddressWithGoogle = apiKey => async ({ longitude, latitude }) => {
        try {
            let result = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=false&key=${apiKey}`,
                { mode: "cors" }
            )
            let returnData = result
            if (result.ok) {
                returnData = result.json();
            }
            if (result.status === "OK") {
                returnData = result;
            }
            return returnData
        }
        catch (e) {
            throw new Error(`Cannot query Google Maps API: ${e}`);
        }
    }
    extractCountry = res => {
        try {
            let countryName = res.results
                .reduce((comps, res) => comps.concat(res.address_components), [])
                .filter(x => x)
                .find(comp => comp.types.includes("country"))
                .long_name;
            let stateName = res.results
                .reduce((comps, res) => comps.concat(res.address_components), [])
                .filter(x => x)
                .find(comp => comp.types.includes("administrative_area_level_1"))
                .long_name;
                console.log({ country: countryName, state: stateName })
            return { country: countryName, state: stateName }
        } catch (e) {
            throw new Error(`Cannot get country code from Google Maps response`);
        }
    }

    browserLocation = async apiKey => {
        try {
            let result = await this.fetchCoordinates()
                .then(await this.fetchAddressWithGoogle(apiKey))
                .then(await this.extractCountry)
                .catch((error) => {
                    console.log('error',error)
                    let staticCountry = 'Canada'
                    let staticState = 'Ontario'

                    if (this._isMounted)
                        this.setState({ country: staticCountry, state: staticState })
                    for (let i = 0; i < AVAILABLECOUNTRIES.length; i++) {
                        if (staticCountry === AVAILABLECOUNTRIES[i].name) {
                            if (this._isMounted)
                                this.setState({ 'countryCode': AVAILABLECOUNTRIES[i].code, 'countryDialCode': AVAILABLECOUNTRIES[i].dial_code })
                        }
                    }
                })
            if (result) {
                if (this._isMounted)
                    this.setState({ country: result.country, state: result.state })
                for (let i = 0; i < AVAILABLECOUNTRIES.length; i++) {
                    if (result.country === AVAILABLECOUNTRIES[i].name) {
                        if (this._isMounted)
                            this.setState({ 'countryCode': AVAILABLECOUNTRIES[i].code, 'countryDialCode': AVAILABLECOUNTRIES[i].dial_code })
                    }
                }
            }
        }
        catch (error) {

        }
    }


    UpdateUserProfile = async () => {
        if (this.state.country === '' || this.state.state === '' || this.state.street_adderss === '' || this.state.zip === '' || this.state.state === '' || (this.state.phone_number === '' && this.props.account.role !== 'Patient') || (this.state.email === '' && this.props.account.role !== 'Patient')) {
            //console.log('error')
            this.setState({ isFormValid: false })
        }
        else {
            this.setState({ loading: true })
            let availableCountriesList = AVAILABLECOUNTRIES
            for (let i = 0; i < availableCountriesList.length; i++) {
                if (availableCountriesList[i].name === this.state.country) {
                    let result = await this.props.updateProfile(this.state.uid, [
                        { temporary_country: this.state.country },
                        { temporary_street: this.state.street_adderss },
                        { temporary_state: this.state.state },
                        { temporary_zip: this.state.zip },
                        { temporary_city: this.state.city },
                        { temporary_countryCode: availableCountriesList[i].code },
                        { temporary_countryDialCode: availableCountriesList[i].dial_code }
                    ])
                    this.props.updateProfileData(result)
                }
            }
            setTimeout(() => {
                this.props.checkConfirmPostcode(this.state.phone_number, this.state.email)
                this.setState({ loading: false })
            }, 1000)
        }
    }

    isModifierKey = (event) => {
        const key = event.keyCode;
        return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
            (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
            (key > 36 && key < 41) || // Allow left, up, right, down
            (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
            )
    };
	setSelectedPlace = (ParsedData) => {
		let StreetAddress = [ParsedData.Street_Number,ParsedData.Street_Address , ParsedData.Route,]
		let myStreeAddress = StreetAddress.filter(Boolean).join()
		this.setState({ zip: ParsedData.Postal_Code, country: ParsedData.Country,state:ParsedData.Area_Level_1,city: ParsedData.Locality,street_adderss:myStreeAddress})		
	}
	setStreetAddress  = (adderss) => {
		this.setState({street_adderss:adderss})
	}

    formatPhoneNumber = (event) => {
        if (this.isModifierKey(event)) { return; }

        // I am lazy and don't like to type things more than once
        let target = event.target.value;
        target = target.replace(/^0+/, '')
        const input = target.replace(/\D/g, '').substring(0, 10); // First fifteen digits of input only
        target = input
        const zip = input.substring(0, 3);
        const middle = input.substring(3, 6);
        const last = input.substring(6, 15);

        if (input.length > 6) { target = `(${zip}) ${middle} - ${last}`; }
        else if (input.length > 3) { target = `(${zip}) ${middle}`; }
        else if (input.length > 0) { target = `(${zip}`; }
        return target
    };


    // FORM DATA UPDATED
    change = (e, text) => {
        if (this._isMounted) {
            if (text === 'country') {
                for (let i = 0; i < AVAILABLECOUNTRIES.length; i++) {
                    if (e.target.value === AVAILABLECOUNTRIES[i].name) {
                        this.setState({ 'countryCode': AVAILABLECOUNTRIES[i].code, 'countryDialCode': AVAILABLECOUNTRIES[i].dial_code })
                    }
                }
            }
            this.setState({ isFormValid: true, [text]: e.target.value })

            if (text === 'zip') {
                let val = e.target.value.toUpperCase()
                val = val.replace(' ', '')
                val = val.replace(/[^a-zA-Z0-9]/g, '')
                val = val.toUpperCase()
                this.setState({ isFormValid: true, [text]: val })
            }
            if (text === 'phone_number') {
                let phoneVal = this.formatPhoneNumber(e)
                this.setState({ isFormValid: true, [text]: phoneVal })
            }
            if (text === 'email') {
                let emailVal = e.target.value
                if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(emailVal))) {
                    this.setState({ isFormValid: true, [text]: emailVal })
                }
            }
        }
    }
    render() {
        let { classes } = this.props

        return (
            <Grid className={classes.font}>
                <Grid className={classes.popup}>
                    <Grid className={classes.popupDiv} >
                        <Grid className={classes.mainDiv}>
                            <Grid className={classes.fieldsDiv}>
                                <GoogleStreetAddress setSelectedPlace={this.setSelectedPlace} street_adderss={this.state.street_adderss} setStreetAddress={this.setStreetAddress} isFormValid={this.state.isFormValid} />
								
                                <TextField
                                    value={this.state.city}
                                    onChange={(e) => this.change(e, 'city')}
                                    onBlur={(e) => this.change(e, 'city')}
                                    label="City *"
                                    style={{ marginBottom: '24px' }}
                                    error={this.state.city === '' && !this.state.isFormValid ? true : false}
                                    helperText={this.state.city === '' && !this.state.isFormValid ? "City is required" : ""}
                                />
                                <div className={classes.selecBoxesContainer}>
                                    {this.state.country === 'United States of America' || this.state.country === 'Canada' ?
                                        <FormControl variant="outlined" className={classes.textLeft} style={{ marginBottom: '24px' }}>
                                            <InputLabel id="state">Province/State *</InputLabel>
                                            <Select
                                                labelId="state"
                                                id="state"
                                                name="state"
                                                label="Province/State *"
                                                variant="outlined"
                                                className={classes.textLeft}
                                                value={this.state.state}
                                                error={this.state.state === '' && !this.state.isFormValid ? true : false}
                                                onChange={(e) => this.change(e, "state")}
                                            >
                                                {this.state.country === 'United States of America' ? this.state.usStates : this.state.canadaStates}
                                            </Select>
                                            {this.state.state === '' && !this.state.isFormValid && (
                                                <p className={classes.mbzero + " MuiFormHelperText-root Mui-error"}>{"State is required"}</p>
                                            )}
                                        </FormControl>
                                        :
                                        <TextField
                                            value={this.state.state}
                                            onChange={(e) => this.change(e, 'state')}
                                            onBlur={(e) => this.change(e, 'state')}
                                            label="Province/State *"
                                            style={{ marginBottom: '24px' }}
                                            error={this.state.state === '' && !this.state.isFormValid ? true : false}
                                            helperText={this.state.state === '' && !this.state.isFormValid ? "State is required" : ""}

                                        />
                                    }
                                    <FormControl variant="outlined" className={classes.textLeft} style={{ marginBottom: '24px' }}>
                                        <InputLabel id="country">Country *</InputLabel>
                                        <Select
                                            labelId="country"
                                            id="country"
                                            name="country"
                                            label="Country *"
                                            variant="outlined"
                                            className={classes.textLeft}
                                            fullWidth
                                            value={this.state.country}
                                            error={this.state.country === '' && !this.state.isFormValid ? true : false}
                                            onChange={(e) => this.change(e, "country")}
                                        >
                                            {this.state.countriesList}
                                        </Select>
                                        {this.state.country === '' && !this.state.isFormValid && (
                                            <p className={classes.mbzero + " MuiFormHelperText-root Mui-error"}>{"country is required"}</p>
                                        )}
                                    </FormControl>
                                </div>


                                <TextField
                                    value={this.state.zip.toUpperCase()}
                                    onChange={(e) => this.change(e, 'zip')}
                                    onBlur={(e) => this.change(e, 'zip')}
                                    label="Postal Code/Zip *"
                                    style={{ marginBottom: '24px' }}
                                    error={this.state.zip === '' && !this.state.isFormValid ? true : false}
                                    helperText={this.state.zip === '' && !this.state.isFormValid ? "Postal Code/Zip is required" : ""}
                                />
                                {this.props.account.role !== 'Patient' ?
                                    <>
                                        <TextField
                                            value={this.state.email}
                                            onChange={(e) => this.change(e, 'email')}
                                            onBlur={(e) => this.change(e, 'email')}
                                            label="Email *"
                                            style={{ marginBottom: '24px' }}
                                            error={this.state.email === '' && !this.state.isFormValid ? true : false}
                                            helperText={this.state.email === '' && !this.state.isFormValid ? "Email is required" : ""}
                                        />
                                        <TextField
                                            value={this.state.phone_number}
                                            onChange={(e) => this.change(e, 'phone_number')}
                                            onBlur={(e) => this.change(e, 'phone_number')}
                                            label="Phone Number *"
                                            style={{ marginBottom: '24px' }}
                                            error={this.state.phone_number === '' && !this.state.isFormValid ? true : false}
                                            helperText={this.state.phone_number === '' && !this.state.isFormValid ? "Phone Number is required" : ""}
                                        />
                                    </>
                                    :
                                    <></>
                                }
                            </Grid>
                            <Grid className={classes.formButton}>
                                <Button variant="contained"
                                    fullWidth
                                    onClick={() => this.UpdateUserProfile()}
                                    color={'primary'}
                                    className={classes.buttonConfirm}>
                                    {this.state.loading ?
                                        <CircularProgress style={{ color: 'white' }} size={21} /> :
                                        "Confirm"
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        account: state.account.data
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateProfile: (uid, data) => updateProfile(uid, data),
        updateProfileData: (data) => dispatch(data)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConfirmAddress))
