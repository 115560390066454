import React, { useState } from 'react'
import { Alert } from 'antd'
import { Grid, TextField } from '@material-ui/core';
import ModelFooter from '../ModelFooter'
import { modelBody, modelRow, formRowLastField } from '../style'
import { createUpdateMedicationHistoryItem, deleteMedicationHistoryItem } from '../API/API'
import AutoCompleteComponent from '../AutoCompleteComponent'
import { Link } from 'react-router-dom';

function AddMedicationToFile({ patientName, isRhynoGoPatient, row, id, onCloseModel, data }) {
    const [medication, setMedication] = useState(row && row.medication ? row.medication : '')
    const [frequency, setReaction] = useState(row && row.frequency ? row.frequency : '')
    const [isCustom, setIsCustom] = useState(false)

    const [message, setMessage] = useState('')
    const [loader, setLoader] = useState(false)
    const [loaderType, setLoaderType] = useState('')
    const [formSubmitted, setFormSubmitted] = useState(false)

    const saved = async () => {
        setFormSubmitted(true)

        if (frequency && medication) {
            setLoader(true)
            setLoaderType('save')
            let result = ''
            if (id)
                result = await createUpdateMedicationHistoryItem({ frequency: frequency, medication: medication, userId: data.patientId, doctorId: data.doctorId, pagination_id: data.pagination, id: id, patientName: patientName, isRhynoGoPatient: isRhynoGoPatient })
            else
                result = await createUpdateMedicationHistoryItem({ frequency: frequency, medication: medication, userId: data.patientId, doctorId: data.doctorId, pagination_id: data.pagination, patientName: patientName, isRhynoGoPatient: isRhynoGoPatient })

            setLoader(false)
            if (result.message)
                setMessage(result.message)
            else
                onCloseModel('', true, 'Saved', result.data, result.pagination_id)
        }
    }
    const deleted = async () => {
        setLoader(true)
        setLoaderType('delete')
        let result = await deleteMedicationHistoryItem({ userId: data.patientId, doctorId: data.doctorId, pagination_id: data.pagination, id: id, patientName: patientName, isRhynoGoPatient: isRhynoGoPatient })
        setLoader(false)
        if (result.message)
            setMessage(result.message)
        else
            onCloseModel('', true, 'Deleted', result.data, result.pagination_id)
    }

    return (
        <>
            <div className='modal-body' style={modelBody}>
                <Grid>
                    {message ?
                        <Grid >
                            <Alert type="error" closable={true} message={message}></Alert>
                        </Grid>
                        : ""}
                </Grid>
                <Grid style={modelRow}>
                    <Link href="#" onClick={() => setIsCustom(!isCustom)}>{isCustom ? "Search Medication" : "Add Custom Medication"}</Link>
                </Grid>
                <Grid style={modelRow}>
                    <AutoCompleteComponent isCustom={isCustom} type={"Medication"} formSubmitted={formSubmitted} value={medication} setValue={setMedication} />
                    <TextField
                        type="text"
                        variant="outlined"
                        margin="normal"
                        style={formRowLastField}
                        label="Frequency"
                        value={frequency}
                        onChange={(e) => setReaction(e.target.value)}
                        error={formSubmitted && !frequency}
                        helperText={formSubmitted && !frequency && "Frequency Field Required"}
                    />
                </Grid>
            </div >
            <ModelFooter saveClicked={saved} onCloseModel={onCloseModel} deleteClicked={deleted} loader={loader} id={id} loaderType={loaderType} />
        </>
    )
}

export default AddMedicationToFile
