import React from 'react'
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import BackupIcon from '@material-ui/icons/Backup'
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'
const SignedOutLinks = (props) => {

    return (
        <List>
            <ListItem key={1} button divider component="a" onClick={props.signOut} href="/signin"><ListItemIcon><PersonAddIcon /></ListItemIcon><ListItemText primary="SIGN IN"></ListItemText></ListItem>
            <ListItem key={2} button divider component="a" onClick={props.signOut} href="/signup" ><ListItemIcon><BackupIcon /></ListItemIcon><ListItemText primary="SIGN UP"></ListItemText></ListItem>
            <ListItem key={3} button divider component="a" onClick={props.signOut} href="/register" ><ListItemIcon><BackupIcon /></ListItemIcon><ListItemText primary="SIGN UP AS A PROVIDER"></ListItemText></ListItem>
        </List>
    )
}
const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}
export default connect(null, mapDispatchToProps)(SignedOutLinks)