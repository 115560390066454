import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Button, CircularProgress } from '@material-ui/core'
import withStyles from '@material-ui/styles/withStyles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import { getNotesList } from '../../store/actions/EMRAction'
import moment from 'moment'


// CSS STYLES
const styles = theme => ({
    parent: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
        position: 'relative',
        width: '100%',
        '& .MuiPaper-root': {
            borderRadius: '20px',
            // maxHeight:'200px',
            minHeight: '50px',
            // overflow:'scroll'

        },
    },
    header: {
        fontWeight: 'bold'
    },
    panel: {
        padding: 0,
        width: '100%',
        maxWidth: '100%',
        marginBottom: theme.spacing(3)
    },

    list: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'baseline',
        paddingBottom: theme.spacing(5)
    },
})

class PatientNotes extends Component {

    // SETTING STATE VARIABLE
    constructor(props) {
        super(props);
        this.state = {
            userId: props.userId,
            doctorId: props.userId,
            expanded: true,
            openModal: false,
            loadinguploader: false,
            modalWidth: 400,
            loadItems: true,
            notesHistoryList: { data: [] }
        }
    }

    // SHOW HIDE NOTES LIST
    expanded = () => {
        this.setState({ expanded: !this.state.expanded })
    }

    // SET ONREF SO THAT PATIENT ID CAN BE SET VIA PARENT 
    componentDidMount() {
        this.getNotesHistory()
    }
    getNotesHistory = async () => {
        let result = await this.props.getNotesList(JSON.stringify({ userId: this.state.userId, doctorId: this.state.doctorId, pagination_id: this.state.pagination_id }))

        let previousData = this.state.notesHistoryList.data
        previousData = previousData.concat(result.data)
        this.setState({ loadItems: false, notesHistoryList: { data: previousData }, responseMessage: result.message, pagination_id: result.pagination_id })
    }

    render() {
        const { classes, parentPage } = this.props;
        let parentPageClass = ''
        if (parentPage === 'chat')
            parentPageClass = 'hideNotesPage'
        return (
            <Grid className={classes.parent}>
                <ExpansionPanel expanded={this.state.expanded} onChange={this.expanded} className={classes.panel + " " + parentPageClass}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.header}>Documents sent by provider</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.list}>
                        <>
                            {!this.state.loadItems ?
                                this.state.notesHistoryList.data.length === 0 ?
                                    <p key={0}>No Notes Found</p> :
                                    <>

                                        {this.state.notesHistoryList.data.map((note, key) => {
                                            return <Typography key={key}>
                                                {note.name}
                                                {' on '}
                                                {moment(note.created_at * 1000).format('MMMM D, YYYY')}
                                                {note.url && note.url !== '' ? <a href={note.url} target="_blank" rel="noopener noreferrer"><PictureAsPdfIcon color="primary" /></a> : ""}
                                            </Typography>
                                        })
                                        }
                                        {this.state.pagination_id !== -1 &&
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => this.getNotesHistory()}
                                                className={classes.submit + " " + classes.searchItem}
                                            >
                                                View More
                            </Button>
                                        }
                                    </>
                                :
                                <CircularProgress size={24} />
                            }
                        </>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Grid>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        account: state.account,
        userInfo: state.emr.UserInfo,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getNotesList: (val) => getNotesList(val)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PatientNotes));