import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import SignupSidebar from './SignupSidebar'
import useStyles from './signup.style'
import SignInForm from "./login"
import { isMobile } from "react-device-detect"


function SignIn(props) {
    const classes = useStyles();
    const { auth } = props;
    let location = useLocation()
    if (auth.uid && auth.hasOwnProperty('is2FValidate') && auth.is2FValidate) return <Redirect to='/dashboard' />
    if (auth.signedInUserId && auth.isVerified === false) return <Redirect to='/verify' />
    return (<div>
        {isMobile ?
            location.search === '?visible' ?
                <div className={classes.container + " negativeMarign"}>
                    <div className={classes.formOuterWrapper + " formOuterWrapper"}>
                        <SignInForm {...props} />
                    </div>
                </div>
                :
                <div className={classes.container + " negativeMarign"}>
                    <SignupSidebar />
                </div>
            :
            <div className={classes.container + " negativeMarign"}>
                <SignupSidebar />
                <div className={classes.formOuterWrapper + " formOuterWrapper"}>
                    <SignInForm {...props} />
                </div>
            </div>
        }
    </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}
export default connect(mapStateToProps)(SignIn);