import React, { useState } from 'react'
import { Button, Grid, CircularProgress } from "@material-ui/core"
import RequisitionItem from './RequisitionItem'
import RequisitionCreatedModal from './RequisitionCreatedModal'
import moment from 'moment'
import Functions from '../../assets/js/function'
import { Modal } from 'antd'

export default function RequisitionsItemsList({ pharmacyName, pharmacyId, requisitionType }) {
    const [requisitionData, setRequisitionData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [loader, setLoader] = useState(false)
    const [loaderKey, setLoaderKey] = useState('')
    const [showConsentModel, setshowConsentModel] = useState(false)
    const [requisitionKey, setrequisitionKey] = useState('')
    const [consentName, setConsentName] = useState('')

    const styles = {
        main: {
            padding: '45px 0 0',
            display: 'flex',
            flexDirection: 'column',
            width: '80%',
            alignItems: 'center',
            margin: 'auto'
        },
        endButton: {
            alignSelf: 'end',
            marginTop: '20px',
            marginBottom: '30px'
        },
        buttonDiv: {
            textAlign: 'right'
        }
    }
    const showConsent = (key) => {

        let requisitionObject = { note: '', phone: '', first_name: '', email: '', last_name: '', date_of_birth: '', zip: '', gender: '', health_card_number: '', datetime: '', collection_date: '', collection_time: '', passport_no: '' }
        let reqdata = requisitionData
        let isFormValid = true
        let appointmentMembers = [];
        if (reqdata.length === 0) {
            isFormValid = false
            reqdata[0] = requisitionObject
        }
        reqdata = reqdata.map(req => {

            appointmentMembers.push(req.first_name + " " + req.last_name);

            if (!(req.collection_date === '' && req.collection_date === 'Invalid date' && req.collection_time === '' && req.collection_time === 'Invalid date')) {
                req.datetime = req.collection_date + ' ' + req.collection_time
                req.collectionDatetimeUtc = moment(req.collection_date + ' ' + req.collection_time).unix()
            }
            if (!(req.date_of_birth === '' && req.date_of_birth === 'Invalid date')) {
                req.dateOfBirthDatetimeUtc = moment(req.date_of_birth).unix()
            }

            req = { ...requisitionObject, ...req }
            if (req.first_name === '' || req.phone === '' || req.email === '' || req.last_name === '' || req.date_of_birth === '' || req.date_of_birth === 'Invalid date' || req.gender === '' || req.zip === '' || req.collection_date === '' || req.collection_date === 'Invalid date' || req.collection_time === '' || req.collection_time === 'Invalid date') {
                isFormValid = false
            }
            return req
        })
        setRequisitionData(reqdata)
        if (isFormValid) {
            setConsentName(appointmentMembers.join(" , "))
            setshowConsentModel(true)
            setrequisitionKey(key)
        }
    };
    const hideConsentModel = () => {
        setshowConsentModel(false)
        submitRequisition(requisitionKey);
    };
    const submitRequisition = async (key) => {

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        setLoader(true)
        setLoaderKey(key)
        let reqdata = requisitionData.filter(Boolean)
        let sentObj = {}
        sentObj.requisition = reqdata
        sentObj.pharmacyid = pharmacyId
        sentObj.pharmacyName = pharmacyName
        sentObj.requisitionType = requisitionType
        sentObj.consent = true
        await Functions.addPharmacyRequisitions(sentObj)
        setLoader(false)
        setLoaderKey('')
        setShowModal(true)
    }

    const updateRequisitionsData = (item, index) => {
        let reqdata = requisitionData
        reqdata[index] = item
        setRequisitionData(reqdata)
    }
    return (
        <Grid style={styles.main}>
            <Grid>
                {Array.from(Array(10)).map((item, key) => {
                    return <>
                        <RequisitionItem updateRequisitionsData={updateRequisitionsData} requisitionData={requisitionData && requisitionData[key] ? requisitionData[key] : {}} key={key} index={key} />
                        <Grid style={styles.buttonDiv} key={"grid" + key}>
                            <Button variant="contained" disabled={loader && loaderKey !== key} key={key} width={'auto'} className={"btnCustomClass lightBlueClasstbn"} style={styles.endButton} onClick={() => showConsent(key)}>
                                {loader && loaderKey === key ? <CircularProgress key={"laoder" + key} size={24} />
                                    :
                                    "Generate Requisition"
                                }
                            </Button>
                        </Grid>
                    </>
                })}
            </Grid>
            {showModal && <RequisitionCreatedModal />}
            <Modal
                title={"CONSENT"}
                visible={showConsentModel}
                width={600}
                // onCancel={hideConsentModel}
                footer={
                    <Grid>
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={hideConsentModel}
                        >
                            CONSENT
						</Button>
                    </Grid>
                }
            >
                <p>
                   {consentName} consent(s) to the testing samples being taken.
			</p>
            </Modal>
        </Grid>

    )
}
