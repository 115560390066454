import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
    Grid,
    Button,
    TextField,
    CircularProgress,
    InputAdornment,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import { Modal } from 'antd'
import TC from "../registration/TC";
import { RECAPTCHASITEKEY } from "../../config";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import moment from "moment";
import { signUp, resetDefaultProps } from "../../store/actions/authActions";
import { connect } from "react-redux";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const styles = (theme) => ({
    popup: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        flexDirection: "column",
    },
    popupDiv: {
        backgroundColor: "#fff",
        padding: theme.spacing(5),
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "35rem",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
            width: "100%",
        },
    },
    popupButton: {
        marginBottom: theme.spacing(2),
        fontFamily: "inherit",
        color: "#ffffff",
    },
    popupHeading: {
        fontFamily: "inherit",
        fontWeight: 700,
        paddingBottom: theme.spacing(6),
        color: "#795bb2",
        fontSize: theme.spacing(2.4),
    },
    font: {
        fontFamily: "revert",
    },
    mainDiv: {
        display: "flex",
        flexDirection: "column",
        width: "60%",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    formButton: {
        display: "flex",
        justifyContent: "space-between",
    },
    mobile_number: {
        display: "flex",
        alignItems: "center",
    },
    cursorPointer: {
        cursor: "pointer",
    },
});
class password extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this._isMounted = false;
        this.state = {
            isFormValid: true,
            password: "",
            confirm_password: "",
            isValidPassword: true,
            token: "",
            loading: false,
            showPassword: false,
            showConfirmPassword: false,
            checkedTC: false,
            setshowTCModal: false,
            click: false,
            showTCModal: false,
        };
        if (this.props.authError !== "") this.props.resetDefaultProps();
    }

    componentWillUpdate(prevProps) {
        if (this.props.authError !== "")
            setTimeout(() => {
                this.props.resetDefaultProps();
            }, 5000);
    }

    componentDidMount() {
        this._isMounted = true;
        loadReCaptcha(RECAPTCHASITEKEY);
    }

    updateFormData = (step) => {
        this.props.updateStepNo(step, { password: this.state.password });
    };

    changeTC = (status) => {
        this.setState({ setshowTCModal: !this.state.stateshowTCModal });
        if (status) {
            document.getElementById("checkedTC").click();
        }
    };

    // FORM DATA UPDATED
    change = (e, text) => {
        if (this._isMounted) {
            this.setState({ isFormValid: true, [text]: e.target.value });
        }
    };
    verifyCallback = (recaptchaToken) => {
        this.setState({ token: recaptchaToken });
    };
    validateForm = () => {
        this.setState({ click: true });
        if (!this.state.checkedTC) {
            this.setState({
                isFormValid: false,
                authError: "must select terms and condtions",
            });
        } else {
            let isValidPassword =
                /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})((?=.*[A-Z]){1}).*$/.test(
                    this.state.password
                );
            if (
                this.state.password !== "" &&
                this.state.confirm_password === this.state.password &&
                isValidPassword
            ) {
                this.myRef.current.execute();
                this.setState({
                    isFormValid: true,
                    isValidPassword: true,
                    loading: true,
                });
                let registerData = this.props.registerData;
                registerData = {
                    ...registerData,
                    password: this.state.password,
                    zone: moment.tz.guess(),
                    type: "Patient",
                    captcha: this.state.token,
                };
                this.props.signUp(registerData);
                setTimeout(() => {
                    this.setState({ loading: false });
                }, 2000);
            } else
                this.setState({ isFormValid: false, isValidPassword: isValidPassword });
        }
    };
    render() {
        let { classes } = this.props;

        return (
            <>
                {/* Model for displaying Terms & condtions start here*/}
                <Modal
                    visible={this.state.showTCModal}
                    width={800}
                    onCancel={() => { this.setState({ showTCModal: false }) }}
                    footer={
                        <div>
                            <Button
                                color=""
                                variant="contained"
                                onClick={() => { this.setState({ showTCModal: false, checkedTC: true }) }}  >
                                ACCEPT
              </Button>
                        </div>
                    }
                >
                    <TC />
                </Modal>
                {/* Model for displaying Terms & condtions ends here */}

                <Grid className={classes.font}>
                    <ReCaptcha
                        ref={this.myRef}
                        sitekey={RECAPTCHASITEKEY}
                        verifyCallback={this.verifyCallback}
                    />
                    {this.props.authError ? (
                        <h4 className="red-text-center">{this.props.authError}</h4>
                    ) : null}

                    <Grid className={classes.popup}>
                        <Grid className={classes.popupDiv}>
                            <Grid className={classes.mainDiv}>
                                <span className={classes.popupHeading}>
                                    Please choose a passwords
                </span>
                                <TextField
                                    value={this.state.password}
                                    onChange={(e) => this.change(e, "password")}
                                    onBlur={(e) => this.change(e, "password")}
                                    label="Password *"
                                    type={this.state.showPassword ? "text" : "Password"}
                                    style={{ marginBottom: "24px" }}
                                    error={
                                        (this.state.password === "" ||
                                            !this.state.isValidPassword) &&
                                            !this.state.isFormValid
                                            ? true
                                            : false
                                    }
                                    helperText={
                                        !this.state.isFormValid
                                            ? this.state.password === ""
                                                ? "Password is required"
                                                : !this.state.isValidPassword
                                                    ? "Password must contain at least 8 characters, one uppercase, and one special case character"
                                                    : ""
                                            : ""
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                className={classes.cursorPointer}
                                                position="end"
                                                onClick={() => {
                                                    this.setState({
                                                        showPassword: !this.state.showPassword,
                                                    });
                                                }}
                                            >
                                                {this.state.showPassword ? (
                                                    <Visibility />
                                                ) : (
                                                        <VisibilityOff />
                                                    )}
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <TextField
                                    value={this.state.confirm_password}
                                    onChange={(e) => this.change(e, "confirm_password")}
                                    onBlur={(e) => this.change(e, "confirm_password")}
                                    label="Confirm Password *"
                                    type={this.state.showConfirmPassword ? "text" : "Password"}
                                    style={{ marginBottom: "24px" }}
                                    error={
                                        (this.state.confirm_password === "" ||
                                            this.state.password !== this.state.confirm_password) &&
                                            !this.state.isFormValid
                                            ? true
                                            : false
                                    }
                                    helperText={
                                        !this.state.isFormValid
                                            ? this.state.confirm_password === ""
                                                ? "Confirm Password is required"
                                                : this.state.password !== this.state.confirm_password
                                                    ? "Confirm Password not matched with Password"
                                                    : ""
                                            : ""
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                className={classes.cursorPointer}
                                                position="end"
                                                onClick={() => {
                                                    this.setState({
                                                        showConfirmPassword:
                                                            !this.state.showConfirmPassword,
                                                    });
                                                }}
                                            >
                                                {this.state.showConfirmPassword ? (
                                                    <Visibility />
                                                ) : (
                                                        <VisibilityOff />
                                                    )}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <FormGroup row style={{ width: "100%", marginBottom: "10px" }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.checkedTC}
                                                name="checkedTC"
                                                id="checkedTC"
                                                onClick={() =>
                                                    this.setState({ checkedTC: !this.state.checkedTC })
                                                }
                                            // onChange={change.bind('checkedTC', !this.state.checkedTC)}
                                            // helperText={this.state.checkedTC ? errors.checkedTC : ""}
                                            // error={this.state.checkedTC && Boolean(errors.checkedTC)}
                                            />
                                        }
                                    />
                                    <label style={{ width: "80%", marginTop: "10px" }}
                                        onClick={() =>
                                            this.setState({ showTCModal: true })
                                        }
                                    >
                                        I accept the{" "}
                                        <span
                                            className={classes.cursorPointer}
                                            onClick={() =>
                                                this.setState({
                                                    checkedTC: !this.state.checkedTC,
                                                    click: true,
                                                })
                                            }
                                        >
                                            Terms and Conditions
                    </span>
                                    </label>
                                    {!this.state.checkedTC && this.state.click && (
                                        <p
                                            className="MuiFormHelperText-root Mui-error"
                                            style={{ marginTop: "-20px", marginBottom: "20px" }}
                                        >
                                            <br />
                                            {"Must Accept Terms and Conditions"}
                                        </p>
                                    )}
                                </FormGroup>

                                <Grid className={classes.formButton}>
                                    <Button
                                        variant="contained"
                                        onClick={() => this.updateFormData(5)}
                                        color={"primary"}
                                        className={classes.popupButton}
                                    >
                                        Back
                  </Button>
                                    <Button
                                        variant="contained"
                                        onClick={() => this.validateForm()}
                                        color={"secondary"}
                                        className={classes.popupButton}
                                    >
                                        {this.state.loading && <CircularProgress size={21} />}
                                        {!this.state.loading && "Sign Up"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        signUp: (newUser) => dispatch(signUp(newUser)),
        resetDefaultProps: () => dispatch(resetDefaultProps()),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(password));
