import React, { Component } from 'react'
import withStyles from "@material-ui/core/styles/withStyles"
import { Grid, Button, TextField, MenuItem, CircularProgress } from '@material-ui/core'
import { AVAILABLECOUNTRIES } from '../../config'
import MuiPhoneNumber from "material-ui-phone-number";

const styles = theme => ({
    popup: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column'
    },
    popupDiv: {
        backgroundColor: '#fff',
        padding: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '35rem',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            width: '100%'
        }
    },
    popupButton: {
        marginBottom: theme.spacing(2),
        fontFamily: 'inherit',
        color: '#ffffff'
    },
    popupHeading: {
        fontFamily: 'inherit',
        fontWeight: 700,
        paddingBottom: theme.spacing(6),
        color: '#795bb2',
        fontSize: theme.spacing(2.4)
    },
    font: {
        fontFamily: 'revert',
    },
    mainDiv: {
        display: 'flex',
        flexDirection: 'column',
        width: '75%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    formButton: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    mobile_number: {
        display: 'flex',
        alignItems: 'center'
    }
})
class contactInfo extends Component {

    constructor(props) {
        super(props)
        this._isMounted = false;

       this.state = {
            inValidEmail: false,
            isFormValid: true,
            countryDialCode: props.registerData.countryDialCode ? props.registerData.countryDialCode : '+1',
            email: props.registerData.email ? props.registerData.email : '',
            confirm_email: props.registerData.email ? props.registerData.email : '',
            phone: props.registerData.hasOwnProperty('phone') ? props.registerData.phone : '',
            countryCodesList: { data: [] }
        }
    }

    componentDidMount() {
        let allCountries = AVAILABLECOUNTRIES
        let countriesList = []
        for (let i = 0; i < allCountries.length; i++)
            countriesList.push(<MenuItem value={allCountries[i].dial_code} key={i}>{allCountries[i].dial_code}</MenuItem>)

        this.setState({ countryCodesList: { data: countriesList } })
        this._isMounted = true
    }

    updateFormData = (step) => {
        this.props.updateStepNo(step, { phone: this.state.phone, email: this.state.email, countryDialCode: this.state.countryDialCode })
    }

    isModifierKey = (event) => {
        const key = event.keyCode;
        return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
            (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
            (key > 36 && key < 41) || // Allow left, up, right, down
            (
                // Allow Ctrl/Command + A,C,V,X,Z
                (event.ctrlKey === true || event.metaKey === true) &&
                (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
            )
    };


    formatPhoneNumber = (event) => {
        if (this.isModifierKey(event)) { return ''; }
        // I am lazy and don't like to type things more than once
        let target = event.target.value;
        target = target.replace(/^0+/, '')
        const input = target.replace(/\D/g, '').substring(0, 10); // First fifteen digits of input only
        target = input
        const zip = input.substring(0, 3);
        const middle = input.substring(3, 6);
        const last = input.substring(6, 15);

        if (input.length > 6) { target = `(${zip}) ${middle} - ${last}`; }
        else if (input.length > 3) { target = `(${zip}) ${middle}`; }
        else if (input.length > 0) { target = `(${zip}`; }
        return target
    };


    // FORM DATA UPDATED
    change = (e, text) => {
        let val = e.target.value
        if (text === 'phone') {
            val = this.formatPhoneNumber(e)
        }
        else {
            val = val.trim()
        }
        if (this._isMounted) {
            this.setState({ isFormValid: true, [text]: val, inValidEmail: false })
            if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(val)) && text === 'email') {
                this.setState({ isFormValid: false, [text]: val, inValidEmail: true })
            }
        }
    }
    setPhoneChange = (e, country) => {       
        let countryCode = country.dialCode;
        let user_phoneno = e.replace(countryCode, "");
        let phoneValue = this.formatPhoneNumber_v2(user_phoneno);
        this.setState({
            country_code:country.countryCode,
            phone: user_phoneno,
            phone_no: phoneValue,
            countryDialCode: "+" + country.dialCode,
        });
    };


    formatPhoneNumber_v2 = (value) => {
        //if (this.isModifierKey(event)) { return ''; }
        // I am lazy and don't like to type things more than once
        let target = value
        target = target.replace(/^0+/, '')
        const input = target.replace(/\D/g, '').substring(0, 10); // First fifteen digits of input only
        target = input
        const zip = input.substring(0, 3);
        const middle = input.substring(3, 6);
        const last = input.substring(6, 15);

        if (input.length > 6) { target = `(${zip}) ${middle} - ${last}`; }
        else if (input.length > 3) { target = `(${zip}) ${middle}`; }
        else if (input.length > 0) { target = `(${zip}`; }
        return target
    };
    validateForm = () => {
        // if (this.state.phone !== '' && this.state.email !== '' && this.state.confirm_email === this.state.email)
        if (this.state.phone !== '' && this.state.email !== '')
            if (this.state.countryDialCode === '+1' && this.state.phone.length < 16)
                this.setState({ isFormValid: false })
            else
                this.updateFormData(6)
        else
            this.setState({ isFormValid: false })
    }
    render() {
        let { classes } = this.props

        return (
            <Grid className={classes.font}>
                <Grid className={classes.popup}>
                    <Grid className={classes.popupDiv}>
                        <Grid className={classes.mainDiv}>
                            <span className={classes.popupHeading}>

                                {this.props.registerData.accountType === 'own' ?
                                    "Please enter your contact information"
                                    :
                                    "Please enter patient's contact information"
                                }
                            </span>
                            <Grid className={classes.mobile_number}>
                                <MuiPhoneNumber
                                    name="phone"
                                    label="Phone Number"
                                    defaultCountry={'ca'}
                                    className="materialPhoneNumber"
                                    onChange={(e, country) => this.setPhoneChange(e, country)}
                                    style={{ marginBottom: "24px" }}
                                    error={(this.state.phone === "" || (this.state.phone.length < 16 && this.state.countryDialCode === "+1")) && !this.state.isFormValid ? true : false}
                                    helperText={!this.state.isFormValid ? this.state.phone === "" ? "Mobile is required" : this.state.phone.length < 16 && this.state.countryDialCode === "+1" ? "Phone Number must be 10 digits long" : "" : ""}
                                />
                            </Grid>

                            <TextField
                                value={this.state.email}
                                onChange={(e) => this.change(e, 'email')}
                                onBlur={(e) => this.change(e, 'email')}
                                label="Email Address *"
                                style={{ marginBottom: '24px' }}
                                error={(this.state.email === '' || this.state.inValidEmail) && !this.state.isFormValid ? true : false}
                                disabled={this.props.registerData.disabledEmail}
                                // helperText={this.state.email === '' && !this.state.isFormValid ? "Email is required" : ""}
                                helperText={!this.state.inValidEmail ? this.state.email === '' && !this.state.isFormValid ? "Email is required" : "" : "Invalid Email"}
                            />

                            {/* <TextField
                                value={this.state.confirm_email}
                                onChange={(e) => this.change(e, 'confirm_email')}
                                onBlur={(e) => this.change(e, 'confirm_email')}
                                label="Confirm Email Address"
                                style={{ marginBottom: '24px' }}
                                disabled={this.props.registerData.disabledEmail}
                                error={(this.state.confirm_email === '' || this.state.email !== this.state.confirm_email) && !this.state.isFormValid ? true : false}
                                helperText={!this.state.isFormValid ? this.state.confirm_email === '' ? "Confirm Email is required" : "Confirm Email not matched with email" : ""}
                            /> */}


                            {/* FOR TASK RHYN-102
                            TASK DESCRIPTION: Add patient tab and subaccounts for age <16
                            IF isSubAccountCompleteProfile KEY IS SET IN PROPS THEN SUBACCOUTN IS TURNED TO 16 AND NEED TO UPDATE CONTACT INFO 
                            SO WE WILL HIDE THE BACK BUTTON AND CHANGE TO NEXT BUTTON TEXT
                            */}

                            <Grid className={classes.formButton}>
                                {!(this.props.registerData.hasOwnProperty("isSubAccountCompleteProfile") && this.props.registerData.isSubAccountCompleteProfile) ?
                                    <Button variant="contained" onClick={() => this.updateFormData(4)} color={'primary'} className={classes.popupButton}>
                                        Back
                                </Button>
                                    : ""}
                                <Button variant="contained" onClick={() => this.validateForm()} color={'secondary'} className={classes.popupButton}>
                                    {this.props.registerData.hasOwnProperty("isSubAccountCompleteProfile") && this.props.registerData.isSubAccountCompleteProfile ?
                                        this.props.registerData.loader ? <CircularProgress size={24} /> : "Update" :
                                        "Next"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        )
    }
}
export default withStyles(styles)(contactInfo);
