import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import withStyles from '@material-ui/styles/withStyles'
import { withRouter } from "react-router-dom";
import { getAppointments } from '../../store/actions/appointmentAction'
import moment from 'moment'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const styles = theme => ({
  parent: {
    display: 'flex',
    flexDirection: 'column',
    // borderRadius: theme.spacing(.5),
    height: '10rem',
    padding: theme.spacing(1),
    position: 'relative',
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '0.5rem',
    // width:'fit-content'
  },
  heading: {
    textAlign: 'left',
    fontSize: '1.2rem',
    fontWeight: 600,
    color: 'white',
    position: 'relative',
    top: '0.3rem',
    left: '1rem',
  },
  appointmentList: {
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'Roboto Regular',
    [theme.breakpoints.down('sm')]: { flexDirection: 'column' }
  },
  appointmentsection: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    "&:first-Child": {
      borderRight: '1px solid #d4d2d2'
    },
    [theme.breakpoints.down('sm')]: {
      "&:first-Child": {
        borderRight: '0px solid #d4d2d2'
      }
    }
  },
  textCenter: {
    justifyContent: 'center'
  },
  appointmentData: {
    fontSize: '.95rem',
    fontFamily: 'inherit',
    textAlign: 'left',
    fontWeight: 600,
    marginBottom: 0
  },
  request: {
    display: 'flex',
    borderTop: '1px solid #d4d2d2',
    padding: theme.spacing(1),
    alignItems: 'center'
  },
  button: {
    color: '#fff !important',
    marginLeft: theme.spacing(.5),
    width: '100%'
  },
  urgentButton: {
    color: '#fff !important',
    marginLeft: theme.spacing(.5),
    width: '100%',
    backgroundColor: '#803030 !important'
  },
  body: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  appointments: {
    backgroundColor: 'rgb(255,165,112)',
    borderRadius: '0.5rem',
    width: '9rem',
    heigth: '6rem',
    color: 'white',
    '& :hover': {
      cursor: 'pointer'
    },
    margin: '1%',
  },
  appointmentNumber: {
    fontWeight: '500',
    fontSize: '3rem'
  },
  firstAppointment: {
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    width: '9rem',
    heigth: '6rem',
    '& :hover': {
      cursor: 'pointer'
    },
    margin: '1%',
  },
  secondAppointment: {
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    width: '9rem',
    heigth: '6rem',
    '& :hover': {
      cursor: 'pointer'
    },
    margin: '1%',
  },
  doctorName: {
    fontWeight: 'bold',
    fontSize: '.8rem',
    marginTop: '20%'
  },
  date: {
    fontSize: '.7rem',
    color: theme.palette.primary.main
  },
  time: {
    color: theme.palette.secondary.main,
    fontSize: '.7rem',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'center'
  }
})
class AppointmentList extends Component {
  constructor(props) {
    super(props);
    // console.log(props)
    this.state = {
      userId: '',
      // chatPage: isMobileOnly? 'mobileChat':'chat',
      chatPage: 'chat',
      loading: false,
      changedRequests: [],
      upcommingRequests: [],
      appointmentData: { id: '', doctorId: '', userId: '', doctorName: '', type: "", appointementType: '' },
      stripeObject: { show: false, id: '', status: '', doctorId: '', paidamount: 0, holdamount: 0 },
      showCalendar: false,
      redirectToChat: '',
      acceptedKey: ''
    }
    if (props.account && props.account.data) {
      // this.state.userId = props.auth.uid

      /* FOR TASK RHYN-102
        TASK DESCRIPTION: Add patient tab and subaccounts for age <16
        CHECK IS REDUX SUBACCOUNT STATE HAS KEY subAccountId THEN GET ALL DATA RELATED TO SUBACCOUNT */
      this.state.userId = props.subAccount.subAccountId ? props.subAccount.subAccountId : props.auth.uid
    }
  }
  async componentDidMount() {
    this.updateAppointmentsList()
  }
  updateAppointmentsList = async () => {
    let result = await this.props.getAppointments({ userId: this.state.userId })
    this.updateResults(result.data)
    // console.log(result)
  }
  getDate = (text) => {
    const stringArray = text.split(' ')
    const index = stringArray.indexOf('on')
    let date = stringArray.slice(index + 1, index + 3).join(' ')
    date = date.substring(0, date.length - 1)
    return date
  }

  getDoctorNameFromRhynoGoAppointments = (text) => {
    const stringArray = text.split(' ')
    const indexToCut = stringArray.indexOf('on')
    const drName = stringArray.slice(0, indexToCut).join(' ')
    return drName;
  }

  getTime = (text) => {
    const stringArray = text.split(' ')
    const index = stringArray.indexOf('at')
    let time = stringArray.slice(index + 1, index + 3)
    return time;
  }
  updateResults = (result) => {
    let changedRequests = []
    let upcommingRequests = []
    let currentTime = Math.floor(new Date().getTime() / 1000)
    for (let i = 0; i < result.length; i++) {
      let drText = 'Dr. '
      let npText = ''
      if (!result[i].showDr) {
        drText = ''
        npText = ', NP'
      }

      let drName = drText + result[i].doctorName + npText
      result[i].doctorName = drName

      if (result[i].status === 'changed') {
        let remainingSeconds = result[i].appointmentFor - currentTime
        if (result[i].appointmentType === 'rhynogo' || (result[i].hasOwnProperty('isRhynoGoAppointment') && result[i].isRhynoGoAppointment)) {
          drName = 'RhynoGo '
          if (remainingSeconds >= 300)
            drName += 'for ' + result[i].reason
        }
        let text = drName + " on " + moment(result[i].appointmentFor * 1000).format('MMM D, YYYY [at] h:mm a')
        if (result[i].appointmentType === 'rhynogo') {
          text = drName + " on " + moment.tz(result[i].appointmentFor * 1000, 'Canada/Eastern').format('MMM D, YYYY [at] h:mm a')
        }
        upcommingRequests.push({
          text: text,
          id: result[i].id,
          doctorId: result[i].doctorId,
          userId: this.state.userId,
          doctorName: result[i].doctorName,
          remainingSeconds: remainingSeconds,
          appointmentType: result[i].appointmentType,
          start: result[i].appointmentFor,
          duration: result[i].duration,
          addedBy: result[i].addedBy,
          holdamount: result[i].holdamount,
          status: result[i].status,
          urgent: false
        })
      } else {

        let remainingSeconds = 0
        let text = ''
        let unique = false
        if (result[i].appointmentFor > 0) {
          remainingSeconds = result[i].appointmentFor - currentTime
          if (result[i].appointmentType === 'rhynogo' || (result[i].hasOwnProperty('isRhynoGoAppointment') && result[i].isRhynoGoAppointment)) {
            drName = 'RhynoGo '
            if (remainingSeconds >= 300)
              drName += 'for ' + result[i].reason
          }
          text = drName + " on " + moment(result[i].appointmentFor * 1000).format('MMM D, YYYY [at] h:mm a')
          if (result[i].appointmentType === 'rhynogo') {
            text = drName + " on " + moment.tz(result[i].appointmentFor * 1000, 'Canada/Eastern').format('MMM D, YYYY [at] h:mm a')
          }
        }
        else {
          unique = true
          text = 'RhynoGo for ' + result[i].reason
        }
        upcommingRequests.push({
          text: text,
          id: result[i].id,
          doctorName: result[i].doctorName,
          doctorId: result[i].doctorId,
          remainingSeconds: remainingSeconds,
          appointmentType: result[i].appointmentType,
          start: result[i].appointmentFor,
          duration: result[i].duration,
          addedBy: result[i].addedBy,
          holdamount: result[i].holdamount,
          status: result[i].status,
          urgent: unique
        })
      }
    }
    this.setState({ changedRequests: changedRequests, upcommingRequests: upcommingRequests })
  }

  moveToAllAppointments = () => {
    this.props.history.push("/All_appointments");
    // history.push("/All_appointments");
  }

  render() {

    const { classes } = this.props;
    // console.log(this.state.upcommingRequests)
    return (
      <Grid className={classes.parent}>
        <h2 className={classes.heading}>Upcoming appointments</h2>
        <Grid className={classes.body}>
          <Grid
            onClick={this.moveToAllAppointments}
            className={classes.appointments}>
            <Typography className={classes.appointmentNumber}>
              {this.state.upcommingRequests.length}
            </Typography>
            <Typography
              style={{
                fontWeight: 'bold',
                position: 'relative',
                bottom: '1rem'
              }}
            >Appointments
            </Typography>
          </Grid>
          {this.state.upcommingRequests.length > 0 ?
            <Grid
              onClick={this.moveToAllAppointments}
              className={classes.firstAppointment}>
              <Typography className={classes.doctorName}>
                {this.state.upcommingRequests[0].appointmentType === "rhynogo" ? this.getDoctorNameFromRhynoGoAppointments(this.state.upcommingRequests[0].text) : this.state.upcommingRequests[0].doctorName}
              </Typography>
              <Box className={classes.flexBox}>
                <Typography className={classes.date}>
                  {this.getDate(this.state.upcommingRequests[0].text)} -
              </Typography>
                <Typography className={classes.time}>
                  {this.getTime(this.state.upcommingRequests[0].text)}
                </Typography>
              </Box>
            </Grid>
            :
            null
          }
          {this.state.upcommingRequests.length > 1 ?
            <Grid
              onClick={this.moveToAllAppointments}
              className={classes.secondAppointment}>
              <Typography className={classes.doctorName}>
                {/* {this.state.upcommingRequests[1].doctorName} */}
                {this.state.upcommingRequests[1].appointmentType === "rhynogo" ? this.getDoctorNameFromRhynoGoAppointments(this.state.upcommingRequests[1].text) : this.state.upcommingRequests[1].doctorName}
              </Typography>
              <Box className={classes.flexBox}>
                <Typography className={classes.date} >
                  {this.getDate(this.state.upcommingRequests[1].text)} -
              </Typography>
                <Typography className={classes.time}>
                  {this.getTime(this.state.upcommingRequests[1].text)}
                </Typography>
              </Box>
            </Grid>
            :
            null
          }
        </Grid>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => {
  /* FOR TASK RHYN-102
    TASK DESCRIPTION: Add patient tab and subaccounts for age <16
    GET THE SUBACCOUNT STATE TO GET DATA ACCORDING TO SUBACCOUNT IS SUBACCOUNT DATA EXIST IN REDUX */
  return {
    auth: state.auth,
    account: state.account,
    subAccount: state.subAccount
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAppointments: (data) => getAppointments(data),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(AppointmentList)));