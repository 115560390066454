import React, { useState, useEffect } from 'react'
import { Grid, Typography, CircularProgress, Fab } from '@material-ui/core'
import { progress, sectionDetail, tableRow, headerItem, mainStyle, fabStyle } from './style'
import { getAllMedicalHistory, updateEMRState } from './API/API'
import ViewMore from './ViewMore'
import TableRow from './TableRow'
import SectionModal from './SectionModal'
import AddIcon from "@material-ui/icons/Add"
import { Alert } from 'antd'
import { useDispatch } from 'react-redux'

function MedicalHistory({ isRhynoGoPatient, patientName, type, patientId, doctorId }) {

    const [medicalHistory, setMedicalHistory] = useState([])
    const [pagination, setPagination] = useState('')
    const [loader, setLoader] = useState(true)
    const [openModel, setOpenModel] = useState(false)
    const [successMessage, setSuccessMessage] = useState()
    const dispatch = useDispatch()

    const getAllUserMedicalHistory = async () => {
        setLoader(true)
        let result = await getAllMedicalHistory(patientId, doctorId, pagination, isRhynoGoPatient, patientName)
        let allMedicalHistory = medicalHistory.concat(result.data)
        setMedicalHistory(allMedicalHistory)
        setPagination(result.pagination_id)
        setLoader(false)
        dispatch(updateEMRState({ data: allMedicalHistory, type: "EMR_MEDICAL_HISTORY_LIST" }))
    }

    useEffect(() => {
        getAllUserMedicalHistory()
    }, [])

    const openCloseModel = (e, isSuccess = false, action, data, pagination_id, isChild = false) => {
        if (isSuccess) {
            setSuccessMessage(`Medical History ${action} Successfully`)
            setMedicalHistory(data)
            dispatch(updateEMRState({ data: data, type: "EMR_MEDICAL_HISTORY_LIST" }))
            setPagination(pagination_id)
        }
        else {
            setSuccessMessage('')
        }
        if (!isChild) {
            setOpenModel(!openModel)
        }
    }

    const alertClosed = () => {
        setSuccessMessage('')
    }

    return (
        <Grid style={mainStyle}>
            <Grid style={sectionDetail}>
                {successMessage && <Alert type="success" closable={true} onClose={alertClosed} message={successMessage}></Alert>}
                <Grid style={tableRow} key={-1}>
                    <Typography style={headerItem}>{'Past Diagnose'}</Typography>
                    <Typography style={headerItem}>{'Year'}</Typography>
                    <Typography>{''}</Typography>
                </Grid>
                {medicalHistory.map((medical, key) => <TableRow
                    key={key}
                    type="medical"
                    id={medical.id}
                    row={medical}
                    col1={medical.diagnose}
                    col2={medical.year}
                    patientName={patientName}
                    isRhynoGoPatient={isRhynoGoPatient}
                    data={{ patientId, doctorId, pagination }}
                    onChangeRecord={openCloseModel}
                />
                )
                }
                {loader && <CircularProgress style={progress} size={24} />}
                {pagination !== -1 && <ViewMore loader={loader} getMoreRecords={getAllUserMedicalHistory} />}
            </Grid >
            <Fab color="primary" style={fabStyle}>
                <AddIcon onClick={openCloseModel} />
            </Fab>
            {openModel && <SectionModal patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} type={type} onCloseModel={openCloseModel} data={{ patientId, doctorId, pagination }} />}
        </Grid>
    )
}

export default MedicalHistory
