import React, { useState } from 'react'
import ContactInfo from '../patientSignup/contactInfo'
import { useSelector, useDispatch } from 'react-redux'
import { updateSubAccoUntContactInfo, removeSubAccount } from './API/API'
import { Modal, Alert } from 'antd'
import { Button } from '@material-ui/core';
import { Redirect } from 'react-router-dom';

function CompleteSubAccountData() {

    const updatedSubAccountData = useSelector(state => state.subAccount)

    const dispatch = useDispatch()

    // SET DEFAULT STATE FOR SUBACCOUNT
    const [registerData, setRegisterData] = useState({ isSubAccountCompleteProfile: true, loader: false })
    const [error, setError] = useState('')
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)
    const [redirectToDashBoard, setRedirectToDashBoard] = useState(false)
    const [needToUpdateSubAccountData, setNeedToUpdateSubAccountData] = useState(updatedSubAccountData && updatedSubAccountData.removedSubAccountData ? true : false)
    const [userName] = useState(updatedSubAccountData && updatedSubAccountData.removedSubAccountData && updatedSubAccountData.removedSubAccountData.name ? updatedSubAccountData.removedSubAccountData.name : '')

    // SUBMIT FORM 
    const submitForm = async (stepNo, data) => {
        // UPDATE THE LOADING STATE
        setRegisterData({ isSubAccountCompleteProfile: true, loader: true })
        // ADD SUBACCOUNT ID IN SENDING OBJECT
        data.uid = updatedSubAccountData.removedSubAccountData.id
        // CALL METHOD TO UDPATE CONTACT INFO
        let res = await updateSubAccoUntContactInfo(data)
        if (res.status) {
            // UPDATE REDUX STATE
            dispatch(removeSubAccount())
            setShowSuccessMessage(true)
        }
        else {
            setError(res.message)
        }
        // UPDATE LAODER
        setRegisterData({ isSubAccountCompleteProfile: true, loader: false })
    }

    const redirectToDashBoardFun = () => {
        setNeedToUpdateSubAccountData(false)
        setShowSuccessMessage(false)
        setRedirectToDashBoard(true)
    }
    return (
        <>
            {
                redirectToDashBoard && <Redirect to="/dashboard" />
            }
            {/* CONTACT INFO MODAL */}
            <Modal
                title={`Update ${userName} Profile`}
                visible={needToUpdateSubAccountData}
                width={'35rem'}
                className={"subaccountUpdatedModel"}
                footer={null}
            >
                {error && <Alert message={error} type="error" />}
                <ContactInfo updateStepNo={submitForm} registerData={registerData} />

            </Modal>

            {/* CONTACT INFO UPDATED SUCCESS MODEL */}
            <Modal
                title={`${userName} Profile Update`}
                visible={showSuccessMessage}
                footer={<Button color="secondary" variant="contained" onClick={redirectToDashBoardFun}>Ok</Button>}
            >
                <h3>{`${userName} contact info updated successfully.`}</h3>
            </Modal>
        </>
    )
}

export default CompleteSubAccountData
