import firebase from "firebase/app"
import "firebase/auth";
import "firebase/storage";
import { APIKEY, AUTHDOMAIN, DATABASEURL, STORAGEBUCKET, PRIVATESTORAGEBUCKET } from "../../config.js";
// Set the configuration for your app
// TODO: Replace with your app's config object
var firebaseConfig = {
    apiKey: APIKEY,
    authDomain: AUTHDOMAIN,
    databaseURL: DATABASEURL,
    storageBucket: STORAGEBUCKET
};
firebase.initializeApp(firebaseConfig);
let storage = firebase.storage().ref()

let privateBucket = firebase.app().storage(PRIVATESTORAGEBUCKET).ref()
// Get a reference to the storage service, which is used to create references in your storage bucket

export default { storage: storage, privateBucket: privateBucket, auth: firebase.auth() }