import React from 'react'
import { Button } from '@material-ui/core'
import { viewMoreStyle } from './style'

function ViewMore({ getMoreRecords, loader }) {
    return (
        <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loader}
            onClick={() => getMoreRecords()}
            className={'btnCustomClass lightBlueClasstbn'}
            style={viewMoreStyle}
        >View More</Button>
    )
}

export default ViewMore
