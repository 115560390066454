import React, { Component } from 'react'
import withStyles from "@material-ui/core/styles/withStyles"
import { Grid, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'

const styles = theme => ({
    popup: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column'
    },
    popupDiv: {
        backgroundColor: '#fff',
        padding: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '35rem',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            width: '100%',
        }
    },
    popupButton: {
        marginBottom: theme.spacing(2),
        fontFamily: 'inherit'
    },
    popupHeading: {
        fontFamily: 'inherit',
        fontWeight: 700,
        paddingBottom: theme.spacing(6),
        color: '#795bb2',
        fontSize: theme.spacing(2.4)
    },
    font: {
        fontFamily: 'revert',
    },
    mainDiv: {
        display: 'flex',
        flexDirection: 'column'
    }
})
class RegisterChoice extends Component {

    constructor(props) {
        super(props)
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true
    }

    updateAccountType = (type) => {
        this.props.updateStepNo(2, { accountType: type })
    }
    render() {
        let { classes } = this.props

        return (
            <Grid className={classes.font}>
                <Grid className={classes.popup}>
                    <Grid className={classes.popupDiv}>
                        <Grid className={classes.mainDiv}>
                            <span className={classes.popupHeading}>I am registering an account for</span>
                            <Button variant="contained" onClick={() => this.updateAccountType('own')} color={'secondary'} className={classes.popupButton}>
                                Myself
                            </Button>
                            <Button variant="contained" onClick={() => this.updateAccountType('other')} color={'secondary'} className={classes.popupButton}>
                                someone else
                            </Button>
                        </Grid>
                    </Grid>
                    <Link className={classes.link} to="/register">Sign up as a Provider</Link>

                </Grid>
            </Grid>
        )
    }
}
export default withStyles(styles)(RegisterChoice);
