import React from 'react'
import { Button, Grid } from '@material-ui/core'
import { usersListItem } from './style'
import { getPatientDetail } from './API/API'
import { useDispatch } from 'react-redux'

function RhynoGoUser({ user, setPatientId, setPatientName, setIsRhynoGoPatient }) {

    const dispatch = useDispatch()

    return (
        <Grid key={user.id} style={divStyle}>
            <span style={usersListItem} > {user.name}</span>
            <span style={usersListItem}>{user.dob}</span>
            <Button
                variant="contained"
                onClick={() => {
                    setPatientId(user.id)
                    setPatientName(user.name)
                    setIsRhynoGoPatient(user.isRhynoGo)
                    dispatch(getPatientDetail(user))
                }
                }
                color={'secondary'}
                className={"btnCustomClass lightGreenClasstbn"}>
                View History
            </Button>
        </Grid>
    )
}

const divStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 25px',
    margin: '20px',
    backgroundColor: 'white',
    borderRadius: '10px'
}

export default RhynoGoUser