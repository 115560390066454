import React, { useState } from 'react'
import { Alert } from 'antd'
import { Grid, TextField } from '@material-ui/core';
import ModelFooter from '../ModelFooter'
import { modelBody, modelRow, formRowLastField } from '../style'
import { createUpdateChatItem } from '../API/API'

function ChatForm({ patientName, isRhynoGoPatient, id, onCloseModel, data }) {
    const [chat, setChat] = useState('')

    console.log(data)
    const [message, setMessage] = useState('')
    const [loader, setLoader] = useState(false)
    const [loaderType, setLoaderType] = useState('')
    const [formSubmitted, setFormSubmitted] = useState(false)

    const saved = async () => {
        setFormSubmitted(true)

        if (chat) {
            setLoader(true)
            setLoaderType('save')
            let result = await createUpdateChatItem({ message: chat, userId: data.patientId, doctorId: data.doctorId, searchDoctorIds:[], viewerId: data.doctorId, pagination_id: data.pagination_id, patientName: patientName, isRhynoGoPatient: isRhynoGoPatient })
            setLoader(false)
            if (result.message)
                setMessage(result.message)
            else
                onCloseModel('', true, 'Saved', result.data, result.pagination_id)
        }
    }

    return (
        <>
            <div className='modal-body' style={modelBody}>
                <Grid>
                    {message ?
                        <Grid >
                            <Alert type="error" closable={true} message={message}></Alert>
                        </Grid>
                        : ""}
                </Grid>
                <Grid style={modelRow}>
                    <TextField
                        type="text"
                        variant="outlined"
                        margin="normal"
                        style={formRowLastField}
                        label="Message"
                        value={chat}
                        onChange={(e) => setChat(e.target.value)}
                        error={formSubmitted && !chat}
                        helperText={formSubmitted && !chat && "Chat Field Required"}
                    />
                </Grid>
            </div >
            <ModelFooter saveClicked={saved} onCloseModel={onCloseModel} id={id} loader={loader} loaderType={loaderType} />
        </>
    )
}

export default ChatForm
