import React from 'react'
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import DashboardIcon from '@material-ui/icons/Dashboard'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import AssessmentIcon from '@material-ui/icons/Assessment'
import { connect } from 'react-redux'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import { signOut } from '../../store/actions/authActions'


const SignedInLinks = (props) => {
    return (
        <List>
            {props.account && props.account.data && !props.account.data.RhynoGo &&
                <ListItem key={1} button divider component="a" href="/dashboard" ><ListItemIcon><DashboardIcon /></ListItemIcon><ListItemText primary="APPOINTMENTS"></ListItemText></ListItem>
            }
            <ListItem key={2} button divider component="a" href="/account" ><ListItemIcon><AccountCircleIcon /></ListItemIcon><ListItemText primary="ACCOUNT"></ListItemText></ListItem>
            <ListItem key={3} button divider component="a" href="/emr" ><ListItemIcon><AssessmentIcon /></ListItemIcon><ListItemText primary="EMR"></ListItemText></ListItem>
            <ListItem key={4} button divider component="a" href="/billing" ><ListItemIcon><MonetizationOnIcon /></ListItemIcon><ListItemText primary="BILLING"></ListItemText></ListItem>
            <ListItem key={5} button divider component="a" href="/contact" ><ListItemIcon><ContactMailIcon /></ListItemIcon><ListItemText primary="CONTACT"></ListItemText></ListItem>
            <ListItem key={7} button divider component="a" onClick={props.signOut} ><ListItemIcon><ExitToAppIcon /></ListItemIcon><ListItemText primary="SIGN OUT"></ListItemText></ListItem>
        </List>
    )
}
const mapStateToProps = (state) => {
    return {
        account: state.account,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignedInLinks)
