import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Steps from './steps.js'


class Appointment extends Component {
    componentDidMount() {
        this.props.setTotalCalls(0)
    }
    render() {
        const { auth } = this.props;
        if (!auth.uid) {
            return <Redirect to="/signin" />;
        }
        if (!auth.isVerified) {
            return <Redirect to="/verify" />;
        }

        return (
            <div className="content">
                <Steps />
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};
export default connect(mapStateToProps)(Appointment);