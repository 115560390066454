import React, { useState, useEffect } from 'react'
import RequisitionsItemsList from './requisitionsItemsList'
import GetRequisitionType from './getRequisitionType'
import { useSelector, connect } from 'react-redux';

const CreateRequisition = (props) => {
    const { authState } = useSelector(
        (state) => ({ authState: state.auth })
      );

    useEffect(() => {
        if (authState) {
            if (!authState.uid) {
                window.location.href = "/signin"
            }
            if (!authState.isVerified) {
                window.location.href = "/verify"
            }
        }
    }, [authState]);
    const [requisitionType, setRequisitionType] = useState('')
    return (
        <div>
            {requisitionType === '' ?
                <GetRequisitionType changeRequisition={setRequisitionType} />
                :
                <RequisitionsItemsList pharmacyName={props.account.pharmacyName} pharmacyId={props.auth.uid} requisitionType={requisitionType} />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        account: state.account.data
    };
};
export default connect(mapStateToProps, null)(CreateRequisition)
