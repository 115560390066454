import React, { useState } from 'react'
import { TextField, Grid, Button, CircularProgress } from '@material-ui/core'
import { modelBody, modelRow, buttonCancel, buttonSave, modelFooter, circularProgress } from '../style'
import { Alert } from 'antd'

function FaxForm({ message, id, fax, setFax, saved, onCloseModel, formSubmitted, loader, loaderType }) {
    const [isUpdate, setIsUpdate] = useState(id ? false : true)
    return (
        <div>
            <div className='modal-body' style={modelBody}>
                <Grid>
                    {message ?
                        <Grid >
                            <Alert type="error" closable={true} message={message}></Alert>
                        </Grid>
                        : ""}
                </Grid>
                {!isUpdate
                    ?
                    <>
                        <h2>Do you want to send the fax?</h2>
                        <Grid style={modelFooter}>
                            <Button style={buttonSave} variant="outlined" onClick={() => setIsUpdate(true)}>Yes</Button>
                            <Button variant="contained" style={buttonCancel} onClick={saved}>{loader && loaderType === 'delete' ? <CircularProgress style={circularProgress} size={24} /> : "No"}</Button>
                        </Grid>
                    </>
                    :
                    <>
                        <Grid style={modelRow}>
                            <TextField
                                type="text"
                                variant="outlined"
                                margin="normal"
                                label="Fax"
                                value={fax}
                                error={formSubmitted && !fax}
                                helperText={formSubmitted && !fax && "Fax Field Required"}
                                onChange={(e) => setFax(e.target.value)}
                            />
                        </Grid>
                        <Grid style={modelFooter}>
                            <Button style={buttonSave} variant="outlined" onClick={saved}>{loader && loaderType === 'save' ? <CircularProgress style={circularProgress} size={24} /> : "Send"}</Button>
                            < Button variant="contained" style={buttonCancel} onClick={onCloseModel}>Cancel</Button>
                        </Grid>
                    </>
                }
            </div>
        </div>
    )
}

export default FaxForm
