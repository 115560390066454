import React from 'react'

function Fax() {
    return (
        <div>
            Fax
        </div>
    )
}

export default Fax
