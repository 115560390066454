import React, { Component } from 'react'
import withStyles from "@material-ui/core/styles/withStyles"
import { Grid, Button, Select, FormControl, InputLabel, MenuItem, TextField, CircularProgress, Avatar } from '@material-ui/core'
import { AVAILABLECOUNTRIES, CONFIGUSSTATESLIST, CONFIGCANADASTATESLIST, GOOGLEMAPKEY } from '../../config'
import { connect } from 'react-redux'
import { updateProfile } from '../../store/actions/authActions'
import LocalHospitalRoundedIcon from '@material-ui/icons/LocalHospitalRounded'
import GetHealthCard from './getHealthCard.js'
import GoogleStreetAddress from './googleStreetAddress'; 
const styles = theme => ({
    popup: {
        display: 'flex',
        justifyContent: 'center',
        width: '35rem',
        margin: 'auto',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    popupDiv: {
        // backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: theme.spacing(7),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            width: '92%'
        }
    },
    popupButton: {
        marginBottom: theme.spacing(2),
        fontFamily: 'inherit',
        color: '#ffffff'
    },
    popupHeading: {
        fontFamily: 'inherit',
        fontWeight: 700,
        paddingBottom: theme.spacing(6),
        color: '#795bb2',
        fontSize: theme.spacing(2.4)
    },
    font: {
        fontFamily: 'revert',
        width: '100%'
    },
    mainDiv: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    formButton: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    textLeft: {
        textAlign: 'left'
    },
    fieldsDiv: {
        display: 'flex',
        flexDirection: 'column'
    },
    popupP: {
        margin: theme.spacing(2, 0),
        fontSize: '1.1rem',
        fontFamily: 'inherit',
        fontWeight: 600,
    },
    avatar: {
        margin: theme.spacing(1, 'auto'),
        backgroundColor: theme.palette.secondary.main,
    },
    halfWidth: {
        maxWidth: 'calc(50% - 20px)',
        flex: 'calc(50% - 20px)',
        margin: '0 10px 20px',
        [theme.breakpoints.down(767)]: {
            maxWidth: 'calc(100%)',
            flex: 'calc(100%)',
            margin: '0 0 15px',
        },
    },
    mbzero: {
        marginBottom: theme.spacing(0) + ' !important'
    }
})
class Address extends Component {

    constructor(props) {
        super(props)
        this._isMounted = false;
        let countriesList = [], USStatesList = [], CanadaStatesList = []
        let availableCountries = AVAILABLECOUNTRIES
        if (availableCountries) {
            countriesList = availableCountries.map((availablecountry, key) =>
                <MenuItem value={availablecountry.name} key={key}>{availablecountry.name}</MenuItem>
            )
        }
        let availableStates = {
            "US": CONFIGUSSTATESLIST,
            "Canada": CONFIGCANADASTATESLIST
        }
        if (availableStates) {
            USStatesList = (availableStates.US).map((availablestate, key) =>
                <MenuItem value={availablestate.name} key={key}>{availablestate.name}</MenuItem>
            )
            CanadaStatesList = (availableStates.Canada).map((availablestate, key) =>
                <MenuItem value={availablestate.name} key={key}>{availablestate.name}</MenuItem>
            )
        }
        this.state = {
            step: 1,
            isFormValid: true,
            street_adderss: props.account.street,
            city: props.account.city,
            state: props.account.state,
            country: props.account.country,
            countryCode: props.account.countryCode,
            countryDialCode: props.account.countryDialCode,
            zip: props.account.zip,
            countriesList: countriesList,
            usStates: USStatesList,
            canadaStates: CanadaStatesList,
            uid: props.auth.uid,
            loading: false,
        }
    }

    componentDidMount() {
        this._isMounted = true
        if (this.props.account.country === '')
            this.browserLocation(GOOGLEMAPKEY)
        else
            this.setState({ step: 2 })
    }


    fetchCoordinates = () => {
        return new Promise((resolve, reject) => {
            try {

                if (!navigator.geolocation) {
                    reject("Browser does not support geolocation API");
                }

                navigator.geolocation.getCurrentPosition(
                    location => {
                        resolve(location.coords);
                    },
                    error => {
                        switch (error.code) {
                            case "PERMISSION_DENIED":
                                return reject("Permission denied to get location");
                            case "TIMEOUT":
                                return reject("Timeout waiting for user response");
                            case "POSITION_UNAVAILABLE":
                            default: {
                                return reject("Cannot detect user location");
                            }
                        }
                    },
                    {
                        timeout: 1000,
                    }
                );

            } catch (error) {
                return reject("Browser does not support geolocation API");
            }
        });
    }

    fetchAddressWithGoogle = apiKey => async ({ longitude, latitude }) => {
        try {
            let result = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=false&key=${apiKey}`,
                { mode: "cors" }
            )
            let returnData = result
            if (result.ok) {
                returnData = result.json();
            }
            if (result.status === "OK") {
                returnData = result;
            }
            return returnData
        }
        catch (e) {
            throw new Error(`Cannot query Google Maps API: ${e}`);
        }
    }
    extractCountry = res => {
        try {
            let countryName = res.results
                .reduce((comps, res) => comps.concat(res.address_components), [])
                .filter(x => x)
                .find(comp => comp.types.includes("country"))
                .long_name;
            let stateName = res.results
                .reduce((comps, res) => comps.concat(res.address_components), [])
                .filter(x => x)
                .find(comp => comp.types.includes("administrative_area_level_1"))
                .long_name;
            return { country: countryName, state: stateName }
        } catch (e) {
            throw new Error(`Cannot get country code from Google Maps response`);
        }
    }

    browserLocation = async apiKey => {
        try {
            let result = await this.fetchCoordinates()
                .then(await this.fetchAddressWithGoogle(apiKey))
                .then(await this.extractCountry)
                .catch((error) => {
                    let staticCountry = 'Canada'
                    let staticState = 'Ontario'

                    if (this._isMounted)
                        this.setState({ country: staticCountry, state: staticState })
                    for (let i = 0; i < AVAILABLECOUNTRIES.length; i++) {
                        if (staticCountry === AVAILABLECOUNTRIES[i].name) {
                            if (this._isMounted)
                                this.setState({ 'countryCode': AVAILABLECOUNTRIES[i].code, 'countryDialCode': AVAILABLECOUNTRIES[i].dial_code })
                        }
                    }
                })
            if (result) {
                this.setState({ country: result.country, state: result.state })
                for (let i = 0; i < AVAILABLECOUNTRIES.length; i++) {
                    if (result.country === AVAILABLECOUNTRIES[i].name) {
                        this.setState({ 'countryCode': AVAILABLECOUNTRIES[i].code, 'countryDialCode': AVAILABLECOUNTRIES[i].dial_code })
                    }
                }
            }
        }
        catch (error) {
        }
    }



    UpdateUserProfile = async () => {
        if (this.state.country === '' || this.state.state === '' || this.state.street_adderss === '' || this.state.zip === '' || this.state.state === '') {
            this.setState({ isFormValid: false })
        }
        else {
            this.setState({ loading: true })
            let availableCountriesList = AVAILABLECOUNTRIES
            for (let i = 0; i < availableCountriesList.length; i++) {
                if (availableCountriesList[i].name === this.state.country) {
                    let result = await this.props.updateProfile(this.state.uid, [
                        { country: this.state.country },
                        { street: this.state.street_adderss },
                        { state: this.state.state },
                        { zip: this.state.zip },
                        { city: this.state.city },
                        { countryCode: availableCountriesList[i].code },
                        { countryDialCode: availableCountriesList[i].dial_code }
                    ])
                    this.props.updateProfileData(result)
                }
            }
            // this.setState({ loading: false, step: 2 })
            this.props.updateStepNo(1, { healthCard: '', HCExpiryDate: '' })
        }
    }

    // UPDATE HEALTH CARD
    UpdateHealthCard = async (data) => {
        if (data.hCard === '') {
            this.props.updateStepNo(1, { healthCard: data.hCard, HCExpiryDate: data.dateofHCExp, showProviderAppointmentTypes: true })
            let result = await this.props.updateProfile(this.state.uid, [
                data
            ])
            this.props.updateProfileData(result)
        }
        else {
            this.setState({ loading: true })
            let result = await this.props.updateProfile(this.state.uid, [
                data
            ])
            this.props.updateProfileData(result)
            this.props.updateStepNo(1, { healthCard: data.hCard, HCExpiryDate: data.dateofHCExp, showProviderAppointmentTypes: true })
        }
    }

    // FORM DATA UPDATED
    change = (e, text) => {
        if (this._isMounted) {
            if (text === 'country') {
                for (let i = 0; i < AVAILABLECOUNTRIES.length; i++) {
                    if (e.target.value === AVAILABLECOUNTRIES[i].name) {
                        this.setState({ 'countryCode': AVAILABLECOUNTRIES[i].code, 'countryDialCode': AVAILABLECOUNTRIES[i].dial_code })
                    }
                }
            }
            this.setState({ isFormValid: true, [text]: e.target.value })

            if (text === 'zip') {
                let val = e.target.value.toUpperCase()
                val = val.replace(' ', '')
                this.setState({ isFormValid: true, [text]: val })
            }
        }
    }
	setSelectedPlace = (ParsedData) => {
		let StreetAddress = [ParsedData.Street_Number,ParsedData.Street_Address , ParsedData.Route,]
		let myStreeAddress = StreetAddress.filter(Boolean).join()
		this.setState({ zip: ParsedData.Postal_Code, country: ParsedData.Country,state:ParsedData.Area_Level_1,city: ParsedData.Locality,street_adderss:myStreeAddress})		
	}
	setStreetAddress  = (adderss) => {
		this.setState({street_adderss:adderss})
	}
    render() {
        let { classes } = this.props

        return (
            <Grid className={classes.font}>
                <Grid className={classes.popup}>
                    {this.state.step === 1 ?
                        <Grid className={classes.popupDiv} >
                            <Grid className={classes.mainDiv}>
                                <Avatar className={classes.avatar}>
                                    <LocalHospitalRoundedIcon />
                                </Avatar>
                                <p className={classes.popupP}>Please add your permanent address.</p>
                                <Grid className={classes.fieldsDiv}>
                                    <GoogleStreetAddress setSelectedPlace={this.setSelectedPlace} street_adderss={this.state.street_adderss} setStreetAddress={this.setStreetAddress} isFormValid={this.state.isFormValid} />
									
                                    <TextField
                                        value={this.state.city}
                                        onChange={(e) => this.change(e, 'city')}
                                        onBlur={(e) => this.change(e, 'city')}
                                        label="City *"
                                        style={{ marginBottom: '24px' }}
                                        error={this.state.city === '' && !this.state.isFormValid ? true : false}
                                        helperText={this.state.city === '' && !this.state.isFormValid ? "City is required" : ""}
                                    />
                                    {this.state.country === 'United States of America' || this.state.country === 'Canada' ?
                                        <FormControl variant="outlined" className={classes.textLeft} style={{ marginBottom: '24px' }}>
                                            <InputLabel id="state">Province/State *</InputLabel>
                                            <Select
                                                labelId="state"
                                                id="state"
                                                name="state"
                                                label="Province/State *"
                                                variant="outlined"
                                                className={classes.textLeft}
                                                value={this.state.state}
                                                error={this.state.state === '' && !this.state.isFormValid ? true : false}
                                                onChange={(e) => this.change(e, "state")}
                                            >
                                                {this.state.country === 'United States of America' ? this.state.usStates : this.state.canadaStates}
                                            </Select>
                                            {this.state.state === '' && !this.state.isFormValid && (
                                                <p className={classes.mbzero + " MuiFormHelperText-root Mui-error"}>{"State is required"}</p>
                                            )}
                                        </FormControl>
                                        :
                                        <TextField
                                            value={this.state.state}
                                            onChange={(e) => this.change(e, 'state')}
                                            onBlur={(e) => this.change(e, 'state')}
                                            label="Province/State *"
                                            style={{ marginBottom: '24px' }}
                                            error={this.state.state === '' && !this.state.isFormValid ? true : false}
                                            helperText={this.state.state === '' && !this.state.isFormValid ? "State is required" : ""}

                                        />
                                    }
                                    <FormControl variant="outlined" className={classes.textLeft} style={{ marginBottom: '24px' }}>
                                        <InputLabel id="country">Country *</InputLabel>
                                        <Select
                                            labelId="country"
                                            id="country"
                                            name="country"
                                            label="Country *"
                                            variant="outlined"
                                            className={classes.textLeft}
                                            fullWidth
                                            value={this.state.country}
                                            error={this.state.country === '' && !this.state.isFormValid ? true : false}
                                            onChange={(e) => this.change(e, "country")}
                                        >
                                            {this.state.countriesList}
                                        </Select>
                                        {this.state.country === '' && !this.state.isFormValid && (
                                            <p className={classes.mbzero + " MuiFormHelperText-root Mui-error"}>{"country is required"}</p>
                                        )}
                                    </FormControl>
                                    <TextField
                                        value={this.state.zip.toUpperCase()}
                                        onChange={(e) => this.change(e, 'zip')}
                                        onBlur={(e) => this.change(e, 'zip')}
                                        label="Postal Code/Zip *"
                                        style={{ marginBottom: '24px' }}
                                        error={this.state.zip === '' && !this.state.isFormValid ? true : false}
                                        helperText={this.state.zip === '' && !this.state.isFormValid ? "Postal Code/Zip is required" : ""}

                                    />
                                </Grid>
                                <Grid className={classes.formButton}>
                                    <Button variant="contained" fullWidth onClick={() => this.UpdateUserProfile()} color={'secondary'} className={classes.popupButton}>
                                        {this.state.loading ?
                                            <CircularProgress size={21} /> :
                                            "Update Address"
                                        }
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        <Grid className={classes.popupDiv} >
                            <GetHealthCard UpdateHealthCard={this.UpdateHealthCard} />
                        </Grid>
                    }
                </Grid>
            </Grid>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        account: state.account.data
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateProfile: (uid, data) => updateProfile(uid, data),
        updateProfileData: (data) => dispatch(data)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Address))
