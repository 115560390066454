import React, { Component } from 'react'
import NormalChat from '../../components/dashboard/NormalChat'
import { Button } from '@material-ui/core'
import { isMobileOnly } from 'react-device-detect'


class Progress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: props.userId,
      doctorId: props.doctorId
    };
  }
  hideProgressBox = () => {
    this.props.hideProgressBox()
  }
  render() {
    return (
      <div className={"clientChat"} style={{ marginLeft: '5px' }}>
        <div className={"chatHeader"}>
          <p>Progress Note</p>
          {isMobileOnly ?
            <div className={"mobileVideo"}>
              <Button onClick={() => this.props.hideBoxes('Chat')}>Chat</Button>
              {this.props.videoStatus ? <Button onClick={() => this.props.hideBoxes('Video')}>Video</Button> : ""}
            </div>
            : ""}


        </div>
        <NormalChat doctorId={this.state.doctorId} userId={this.state.userId} onRef={ref => this.callChats = ref} pageType={"progress"} />
      </div>
    );
  }
}

export default Progress;