import React from 'react'
import { List, ListItem, ListItemIcon, ListItemText, Grid } from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import AssignmentIndSharpIcon from '@material-ui/icons/AssignmentIndSharp'
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions';
import LogoutIcon from '../../assets/images/LogoutIcon.svg'
import Logo from '../../assets/images/SideBarLogo.svg';
import withStyles from '@material-ui/styles/withStyles'
import TodayIcon from '@material-ui/icons/Today';

const styles = theme => ({
    container: {
        '& .MuiListItemText-root': {
            color: theme.palette.lightGrey.main
        }
    },
    helpBox: {
        backgroundColor: 'rgb(229, 247,247)',
        height: '100px',
        bottom: '20px',
        margin: 'auto',
        position: 'relative',
        width: '90%',
        borderRadius: '9px',
    },
    header: {
        color: '#00bbbe',
        fontWeight: 'bold',
    },
    subHeader: {
        color: theme.palette.lightGrey.main
    },
    imgLogo: {
        width: '9rem',
        marginTop: '1rem',
        marginLeft: '15%'
    },
    imgIcons: {
        width: '1.3rem'
    },
    muiIcons: {
        color: theme.palette.lightGrey.main,
        fontSize: '1.7rem'
    }
})

const SignedInLinks = (props) => {

    const { classes } = props
    return (
        <Grid className={classes.container}>
            <List>
                <ListItem key={2} button divider component="a" href="/requisition" >
                    <ListItemIcon>
                        <AssignmentIndSharpIcon className={classes.muiIcons} />
                    </ListItemIcon>
                    <ListItemText primary="Create Requisition">
                    </ListItemText>
                </ListItem>
                <ListItem key={4} button divider component="a" href="/requisitions_list">
                    <ListItemIcon>
                        <TodayIcon className={classes.muiIcons} />
                    </ListItemIcon>
                    <ListItemText primary="Requisitions"></ListItemText>
                </ListItem>
                <ListItem key={6} button divider component="a" href="/account" >
                    <ListItemIcon>
                        <AccountCircleIcon className={classes.muiIcons} />
                    </ListItemIcon>
                    <ListItemText primary="Account"></ListItemText>
                </ListItem>
                <ListItem key={7} button divider component="a"
                    onClick={() => props.signOut()} >
                    <ListItemIcon>
                        <img className={classes.imgIcons} src={LogoutIcon} alt='logoutButton' />
                    </ListItemIcon>
                    <ListItemText primary="Sign Out"></ListItemText>
                </ListItem>
            </List>
            <Grid>
                <img className={classes.imgLogo} alt='Logo' src={Logo}></img>
            </Grid>
            <Grid className={classes.helpBox}>
                <br></br>
                <span className={classes.header}>Do you need help ?</span>
                <br></br>
                <br></br>
                <a href="tel:+18557496646"><span className={classes.subHeader}>1-855-RHYNO-GO</span></a>
            </Grid>
        </Grid>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignedInLinks))
