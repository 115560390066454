const initState = {}

const accountReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ACCOUNT_DATA':
            state = { authError: action.type, data: action.user_data, availability: state.availability }
            return {
                ...state
            }
        case 'PROFILE_UPDATE_ERROR':
            state = { authError: action.type, message: action.message }
            return {
                ...state
            }
        case 'UPDATE_AVAILABILITY':
            state = { authError: action.type, availability: action.data, data: state.data, message: action.message }
            return {
                ...state
            }
        case 'UPDATE_AVAILABILITY_ERROR':
            state = { authError: action.type, availability: state.availability, data: state.data, message: action.message }
            return {
                ...state
            }
        case 'COPY_AVAILABILITY':
            state = { authError: action.type, availability: action.data, data: state.data, copymessage: action.message }
            return {
                ...state
            }
        case 'COPY_AVAILABILITY_ERROR':
            state = { authError: action.type, availability: state.availability, data: state.data, copymessage: action.message }
            return {
                ...state
            }
        default:
            return state;
    }
}

export default accountReducer