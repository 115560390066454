import React, { useState } from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import PrescribtionForm from './PrescribtionForm'
import FaxForm from './FaxForm'
import { Modal } from 'antd'
import moment from 'moment'
import { useSelector } from 'react-redux';
import { deleteMedicationHistoryItem, createUpdatePrescription } from '../API/API'
const { confirm } = Modal

function PrescribeMedication({ patientName, isRhynoGoPatient, row, id, onCloseModel, data }) {

    const { profile, userInfo } = useSelector(
        (state) => ({
            profile: state.account.data,
            userInfo: state.emr.UserInfo
        })
    );

    const [isCustom, setIsCustom] = useState(false)
    const [patientHC, setPatientHC] = useState('')
    const [medication, setMedication] = useState(id ? row.medication : '')
    const [duration, setDuration] = useState(id ? row.duration : '')
    const [frequency, setFrequency] = useState(id ? row.frequency : '')
    const [note, setNote] = useState(id ? row.note : '')
    const [repeats, setRepeats] = useState(id ? row.repeats : '')
    const [noOfRows, setNoOfRows] = useState(0)
    const [prescriptions, setPrescriptions] = useState([])
    const [prescriptionArray, setPrescriptionArray] = useState({
        med1: '',
        din1: '',
        freq1: '',
        rep1: '',
        note1: '',
        med2: '',
        din2: '',
        freq2: '',
        rep2: '',
        note2: '',
        med3: '',
        din3: '',
        freq3: '',
        rep3: '',
        note3: '',
        med4: '',
        din4: '',
        freq4: '',
        rep4: '',
        note4: '',
        med5: '',
        din5: '',
        freq5: '',
        rep5: '',
        note5: '',
        PatientHC: '',
    })
    const [message, setMessage] = useState('')
    const [loader, setLoader] = useState(false)
    const [loaderType, setLoaderType] = useState('')
    const [formSubmitted, setFormSubmitted] = useState(false)
    const [askForFax, setAskForFax] = useState(false)
    const [fax, setFax] = useState('')

    const saved = async () => {
        setFormSubmitted(true)
        if (id || fax) {
            setLoader(true)
            setLoaderType('save')
            setMessage('')

            let showDr = profile.speciality === 'Nurse Practitioner' ? '' : 'Dr. '
            let showNP = profile.speciality === 'Nurse Practitioner' ? ', NP' : ''

            let addressVar = profile.city + ", " + profile.state + ", " + profile.zip + ", " + profile.country
            addressVar = addressVar.replace(', ,', ",")
            // SET DOCTOR DATA VARIABLE
            let doctorData = {
                'DoctorName': showDr + profile.fullName + showNP,
                'DoctorPhone': profile.phone,
                'DoctorFax': profile.fax,
                'DoctorStreet': profile.street,
                'DoctorCityStateZip': addressVar,
                'Lincense': profile.licenseInfo,
                'Billing': profile.billingInfo,
                'signature': profile.signature,
                'Date': moment().format('L')
            }
            // SET DOCTOR DATA VARIABLE
            let userData = {
                'PatientName': userInfo.fullName,
                'PatientDOB': moment(userInfo.dateofBirth).format("D") + '/' + moment(userInfo.dateofBirth).format("M") + '/' + moment(userInfo.dateofBirth).format("YYYY"),
                'PatientHC': userInfo.hCard,
                'PatientSex': userInfo.gender,
                'PatientAddress': userInfo.street + ", " + userInfo.city + ", " + userInfo.state + ", " + userInfo.zip + ", " + userInfo.country,
                'PatientPhone': userInfo.phone,
            }

            // ADD PRESCRIOTION AND SEND FAX
            let formDataComplete = prescriptionArray
            let formDataArray = []
            for (let [key, val] of Object.entries(formDataComplete)) {
                let index = key.substr(key.length - 1)
                let arrayKey = key.substr(0, key.length - 1)
                if (formDataArray.hasOwnProperty(index)) {
                    formDataArray[index][arrayKey] = val
                }
                else {
                    if (!Number.isInteger(parseInt(index))) {
                        arrayKey = key
                        index = '6'
                    }
                    let prescriptionObj = {}
                    prescriptionObj[arrayKey] = val
                    formDataArray[index] = prescriptionObj
                }
            }
            formDataArray.shift()

            // CREATE COVID REQUISITION 
            let sentObj = {
                id: id,
                noteType: 'Prescription',
                userId: data.patientId,
                doctorId: data.doctorId,
                isRhynoGoPatient,
                patientName,
                userData: userData,
                doctorData: doctorData,
                formData: formDataArray,
                faxTo: fax,
                pagination_id: data.pagination
            }

            let response = await createUpdatePrescription(sentObj)

            setLoader(false)
            if (response.status === 0)
                setMessage(response.message)
            else
                onCloseModel('', true, 'Saved', response.data, response.pagination_id)
        }
    }
    const deleted = async () => {
        setLoader(true)
        setLoaderType('delete')
        console.log(JSON.stringify({ id: id, isRhynoGoPatient, patientName, userId: data.patientId, doctorId: data.doctorId, pagination_id: data.pagination }))
        let result = await deleteMedicationHistoryItem({
            id: id, isRhynoGoPatient, patientName, userId: data.patientId, doctorId: data.doctorId, pagination_id: data.pagination
        })
        setLoader(false)
        if (result.message)
            setMessage(result.message)
        else
            onCloseModel('', true, 'Deleted', result.data, result.pagination_id)
    }

    const makePrescriptionRecord = async (noOfRowsForRecords) => {
        confirm({
            title: 'Please confirm ,  you have added ' + noOfRowsForRecords + ' of 5 Prescription',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => {
                console.log('ok')
                setAskForFax(true)
                setFormSubmitted(false)
            },
            onCancel() {
                console.log('cancel')
            },
        });
    }

    // DELETE PRESCRIPTION ITEM FROM LIST
    const onDeletePrescirption = (itemNo) => {
        // GET FORM DATA FROM STATE
        let formDataArray = prescriptionArray
        // UPDATE LIST DATA
        for (let i = itemNo; i < 5; i++) {
            formDataArray["med" + i] = formDataArray["med" + (i + 1)]
            formDataArray["din" + i] = formDataArray["din" + (i + 1)]
            formDataArray["freq" + i] = formDataArray["freq" + (i + 1)]
            formDataArray["rep" + i] = formDataArray["rep" + (i + 1)]
            formDataArray["note" + i] = formDataArray["note" + (i + 1)]

            formDataArray["med" + (i + 1)] = ''
            formDataArray["din" + (i + 1)] = ''
            formDataArray["freq" + (i + 1)] = ''
            formDataArray["rep" + (i + 1)] = ''
            formDataArray["note" + (i + 1)] = ''
        }
        // UPDATE VISIBLE LIST DATA
        let formData = []
        for (let i = 1; i <= 5; i++) {
            if (formDataArray["med" + i] !== '') {
                formData.push(<p key={i} style={{ display: "flex", cursor: 'pointer' }}>{formDataArray["med" + i]}
                    <DeleteIcon onClick={() => onDeletePrescirption(i)} />
                </p>)
            }
        }

        setNoOfRows(noOfRows - 1)
        setPrescriptionArray(formDataArray)
        setPrescriptions(formData)
    }

    const add = (isAddMore) => {
        if (noOfRows === 0)
            setFormSubmitted(true)
        let noOfRowsForRecords = 0
        if (medication && frequency && duration && repeats) {
            // GET VALUES ARRAY
            let formDataArray = prescriptionArray

            for (let i = 1; i <= 5; i++) {
                noOfRowsForRecords++
                if (formDataArray["med" + i] === '') {
                    formDataArray["med" + i] = medication
                    formDataArray["din" + i] = duration
                    formDataArray["freq" + i] = frequency
                    formDataArray["rep" + i] = repeats
                    formDataArray["note" + i] = note
                    formDataArray["PatientHC"] = patientHC

                    setMedication('')
                    setFrequency('')
                    setDuration('')
                    setRepeats('')
                    setNote('')
                    break
                }
            }
            setNoOfRows(noOfRowsForRecords)
            setPrescriptionArray(formDataArray)
            let formData = []
            for (let i = 1; i <= 5; i++) {
                if (formDataArray["med" + i] !== '') {
                    formData.push(<p key={i} style={{ display: "flex", cursor: 'pointer' }}>{formDataArray["med" + i]}
                        <DeleteIcon onClick={() => onDeletePrescirption(i)} />
                    </p>)
                }
            }
            setPrescriptions(formData)
            setFormSubmitted(false)
        }
        else
            noOfRowsForRecords = noOfRows
        if (!isAddMore && noOfRowsForRecords > 0) {
            makePrescriptionRecord(noOfRowsForRecords)
        }
    }

    return (
        <>
            {askForFax
                ?
                <FaxForm message={message} id={id} saved={saved} fax={fax} setFax={setFax} onCloseModel={onCloseModel} formSubmitted={formSubmitted} loader={loader} loaderType={loaderType} />
                :
                <PrescribtionForm
                    message={message}
                    isCustom={isCustom}
                    setIsCustom={setIsCustom}
                    formSubmitted={formSubmitted}
                    medication={medication}
                    setMedication={setMedication}
                    duration={duration}
                    setDuration={setDuration}
                    frequency={frequency}
                    setFrequency={setFrequency}
                    repeats={repeats}
                    setRepeats={setRepeats}
                    note={note}
                    setNote={setNote}
                    add={add}
                    onCloseModel={onCloseModel}
                    prescriptions={prescriptions}
                    noOfRows={noOfRows}
                    id={id}
                    deleted={deleted}
                    loader={loader}
                    loaderType={loaderType}
                />
            }
        </>
    )
}

export default PrescribeMedication
