import React from 'react'
import { Button } from '@material-ui/core'
import useStyles from './signupSidebar.style'
import brandLogo from '../../assets/images/logo_bg.png'
import { useLocation } from 'react-router-dom'
import { isMobile } from "react-device-detect"

export default function SignupSidebar() {
    let location = useLocation();
    const classes = useStyles();
    return (
        <div className={classes.sidebar + " notLoggedInPages"}>
            <div className={classes.sidebarInnerWrapper}>
                <div className={classes.imgWrapper}>
                    <img className={classes.brandLogo} src={brandLogo} alt="brand logo" />
                </div>

                {isMobile ?
                    <div className={classes.buttonWrapper}>
                        <Button className={classes.button} href="/signin?visible" variant="contained" color="primary">
                            SIGN IN
                        </Button>
                        <Button href="/signup" className={classes.button} variant="contained" color="primary">
                            SIGN UP
                        </Button>
                        <Button href="/register" className={classes.button} variant="contained" color="primary">
                            SIGN UP AS A PROVIDER
                        </Button>
                    </div>
                    :
                    // FOR TASK RHYN-102
                    // TASK DESCRIPTION: Add patient tab and subaccounts for age <16
                    // IF URL HAS SET_PASSWORD KEY THEN SHOW THE SIGN IN/REGISTER LINKS
                    location.pathname.startsWith('/set_password') ?
                        <div className={classes.buttonWrapper}>
                            <Button className={classes.button} href="/signin" variant="contained" color="primary">
                                SIGN IN
                            </Button>
                            <Button href="/signup" className={classes.button} variant="contained" color="primary">
                                SIGN UP
                            </Button>
                            <Button href="/register" className={classes.button} variant="contained" color="primary">
                                SIGN UP AS A PROVIDER
                            </Button>
                        </div>
                        :
                        location.pathname.indexOf('/signin') === -1 ?
                            <div className={classes.buttonWrapper}>
                                <Button className={classes.button} href="/signin" variant="contained" color="primary">
                                    SIGN IN
                                </Button>
                            </div>
                            :
                            <div className={classes.buttonWrapper}>
                                <Button href="/signup" className={classes.button} variant="contained" color="primary">
                                    SIGN UP
                                </Button>
                                <Button href="/register" className={classes.button} variant="contained" color="primary">
                                    SIGN UP AS A PROVIDER
                                </Button>
                            </div>
                }
            </div>
        </div>
    )
}