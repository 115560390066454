import { APIURL, WEBSITEURL } from '../../config.js'
import firebase from '../../assets/js/firebase'
import functions from '../../assets/js/function'


export const signIn = (credentials) => {
  return async (dispatch, getState) => {
    // Login With EMAIL AND  PASSWORD 
    try {
      // await firebase.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
      let isLogin = await firebase.auth.signInWithEmailAndPassword(
        credentials.email,
        credentials.password
      )

      // Check User Successfully Loggedin
      if (isLogin.hasOwnProperty('user')) {

        let firebaseToken = await functions.getIdToken()
        if (firebaseToken !== '') {
          let jsonmap = JSON.stringify({ isSendCode: credentials.isSendCode, uid: isLogin.user.uid });
          fetch(APIURL + '/login', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
          })
            .then(response => response.json())
            .then(data => {
              if (data.status === 1) {
                dispatch({
                  type: 'ACCOUNT_DATA',
                  user_data: data.data
                })
                dispatch({
                  type: 'LOGIN_SUCCESS',
                  uid: isLogin.user.uid,
                  isVerified: data.isVerified,
                  verificationType: data.verificationType,
                  hasMobileNumber: data.hasMobileNumber,
                })
              }
              else {
                dispatch({
                  type: 'LOGIN_ERROR',
                  message: data.message
                })
              }
            })
            .catch(error => {
              dispatch({
                type: 'LOGIN_ERROR',
                payload: error
              })
            });
        } else {
          return ''
        }
      }
    }
    catch (err) {
      // Return Error Response
      console.log('err.code')
      console.log(err)
      if (err.code === "auth/too-many-requests") {
        dispatch({
          type: 'LOGIN_ERROR',
          message: err.message
        })
      }
      else {
        dispatch({
          type: 'LOGIN_ERROR',
          message: 'username or password does not match'
        })
      }
    }
  }
}

export const resetDefaultProps = () => {
  return async (dispatch, getState) => {
    return dispatch({
      type: "SIGNUP_ERROR",
      message: ""
    })
  }
}

export const signUp = (userDetail) => {
  return async (dispatch, getState) => {
    if (userDetail.email === '') {
      return dispatch({
        type: "SIGNUP_ERROR",
        message: "Email addess is badly formated"
      })
    }
    let jsonmap = JSON.stringify({ captcha: userDetail.captcha });
    let response = await fetch(APIURL + '/verifycaptcha', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json' }
    })
    response = await response.json()
    if (response.status === 1) {
      try {
        // CREATE USER WITH EMAIL AND PASSWORD
        let user = await firebase.auth.createUserWithEmailAndPassword(
          userDetail.email,
          userDetail.password
        )
        // Check User Created Successfully
        if (user.hasOwnProperty('user')) {
          let firebaseToken = await functions.getIdToken()
          if (firebaseToken !== '') {
            userDetail.uid = user.user.uid
            jsonmap = JSON.stringify(userDetail);
            fetch(APIURL + '/register', {
              method: 'POST',
              async: false,
              body: jsonmap,
              headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
            })
              .then(response1 => response1.json())
              .then(data => {
                if (data.status === 1) {
                  dispatch({
                    type: 'SIGNUP_SUCCESS',
                    uid: data.user_id,
                    hasMobileNumber: data.hasMobileNumber,
                    isVerified: data.hasOwnProperty('data') && data.data.hasOwnProperty('isVerified') ? data.data.isVerified : false,
                    verificationType: data.hasOwnProperty('data') && data.data.hasOwnProperty('verificationType') ? data.data.verificationType : false
                  })
                  let accountData = data.data
                  if (accountData.hasOwnProperty('data'))
                    accountData = accountData.data
                  dispatch({
                    type: 'ACCOUNT_DATA',
                    user_data: accountData
                  })
                }
                else {
                  dispatch({
                    type: 'SIGNUP_ERROR',
                    message: data.message
                  })
                }
              })
              .catch(error => {
                dispatch({
                  type: 'SIGNUP_ERROR',
                  message: error.message
                })
              });
          } else {
            return ''
          }
        }
      } catch (error) {
        dispatch({
          type: 'SIGNUP_ERROR',
          message: error.message
        })
      }
    }
    else {
      dispatch({
        type: 'SIGNUP_ERROR',
        message: response.message
      })
    }

  }
}

export const verify2F = (uid, code) => {
  return async (dispatch, getState) => {
    let firebaseToken = await functions.getIdToken()
    if (firebaseToken !== '') {
      let jsonmap = JSON.stringify({ userId: uid, code: code });
      fetch(APIURL + "/VerifyTwoFactorCode", {
        method: 'POST',
        async: false,
        body: jsonmap,
        headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 1) {
            dispatch({
              type: 'TwoFactor_Code',
              status: true,
              message: data.message,
            })
            dispatch({
              type: 'ACCOUNT_DATA',
              user_data: data.data
            })
          }
          else {
            dispatch({
              type: 'TwoFactor_Code',
              status: false,
              message: data.message
            })
          }
        })
        .catch(error => {
          dispatch({
            type: 'TwoFactor_Code',
            status: false,
            message: error.message
          })
        });
    } else {
      return ''
    }
  }
}

export const getAccountVerification = (uid) => {
  return async (dispatch, getState) => {
    let firebaseToken = await functions.getIdToken()
    if (firebaseToken !== '') {
      let jsonmap = JSON.stringify({ uid: uid });
      fetch(APIURL + "/getAccountVerification", {
        method: 'POST',
        async: false,
        body: jsonmap,
        headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 1) {
            dispatch({
              type: 'EMAIL_VERIFIED',
              status: true
            })
            dispatch({
              type: 'ACCOUNT_DATA',
              user_data: data.data
            })
          }
          else {
            dispatch({
              type: 'EMAIL_VERIFIED',
              status: false,
              verificationType: data.hasOwnProperty('verificationType') ? data.verificationType : 'user'
            })
          }
        })
        .catch(error => {
          dispatch({
            type: 'EMAIL_VERIFIED',
            status: false
          })
        });
    } else {
      return ''
    }
  }
}

export const signOut = () => {
  return (dispatch, getState) => {
    firebase.auth.signOut().then(function () {
      dispatch({
        type: 'SIGN_OUT'
      })
    }, function (error) {
      dispatch({
        type: 'SIGN_OUT'
      })
    });
  }
}
export const updateProfile = async (uid, data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = JSON.stringify({ uid: uid, data: data });
    return fetch(APIURL + "/updateProfile", {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            type: 'ACCOUNT_DATA',
            user_data: data.data
          }
        }
        else {
          return {
            type: 'PROFILE_UPDATE_ERROR',
            message: data.message
          }
        }
      })
      .catch(error => {
        // return {
        //   type: 'PROFILE_UPDATE_ERROR',
        //   message: error.message
        // }
        return ''
      });
  } else {
    return {
      type: 'PROFILE_UPDATE_ERROR',
      message: 'token error'
    }
  }
}

export const getUpdatedUserInfo = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = JSON.stringify(data);
    return fetch(APIURL + '/getUserInfo', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          return {
            type: 'ACCOUNT_DATA',
            user_data: data.data
          }
        }
        else {
          return {
            type: 'PROFILE_UPDATE_ERROR',
            message: data.message
          }
        }
      })
      .catch(error => {
        // return {
        //   type: 'PROFILE_UPDATE_ERROR',
        //   message: error.message
        // }
        return ''
      });
  } else {
    return {
      type: 'PROFILE_UPDATE_ERROR',
      message: 'token error'
    }
  }
}


export const createAndCheckAvailability = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data;
    return fetch(APIURL + "/createAndCheckAvailability", {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        return data
      })
      .catch(error => {
        return { success: 0, message: error.message }
      });
  } else {
    return {
      type: 'PROFILE_UPDATE_ERROR',
      message: 'token error'
    }
  }
}
export const forgetPassword = (credentials) => {
  return (dispatch, getState) => {
    let jsonmap = JSON.stringify(credentials);
    fetch(APIURL + '/forgetPassword', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json' }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 1) {
          dispatch({
            type: 'FORGET_PASSWORD_SUCCESS',
            message: data.message
          })
        }
        else {
          dispatch({
            type: 'FORGET_PASSWORD_ERROR',
            message: data.message
          })
        }
      })
      .catch(error => {
        dispatch({
          type: 'FORGET_PASSWORD_ERROR',
          payload: error
        })
      });
  }
}
export const resendAccountVerification = (userId, type) => {
  return async (dispatch, getState) => {
    let firebaseToken = await functions.getIdToken()
    if (firebaseToken !== '') {
      let jsonmap = JSON.stringify({ uid: userId, type: type });
      fetch(APIURL + '/resendAccountVerification', {
        method: 'POST',
        async: false,
        body: jsonmap,
        headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 1) {
            dispatch({
              type: 'RESEND_PASSWORD_SUCCESS',
              message: data.message
            })
          }
          else {
            dispatch({
              type: 'RESEND_PASSWORD_ERROR',
              message: data.message
            })
          }
        })
        .catch(error => {
          dispatch({
            type: 'RESEND_PASSWORD_ERROR',
            payload: error
          })
        });
    } else {
      return ''
    }
  }
}

// SENT VERIFICATION CODE 
export const sendVerificationCode = (data) => {
  return async (dispatch, getState) => {
    let firebaseToken = await functions.getIdToken()
    if (firebaseToken !== '') {
      let jsonmap = JSON.stringify({ uid: data.uid, codeType: data.codeType });
      fetch(APIURL + '/sendVerificationCode', {
        method: 'POST',
        async: false,
        body: jsonmap,
        headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 1) {
            dispatch({
              type: 'RESEND_CODE',
              message: data.message
            })
          }
          else {
            dispatch({
              type: 'RESEND_CODE',
              message: data.message
            })
          }
        })
        .catch(error => {
          dispatch({
            type: 'RESEND_CODE',
            message: error.message
          })
        });
    } else {
      return ''
    }
  }
}


// RESENT VERIFICATION CODE 
export const resendVerificationCode = (data) => {
  return async (dispatch, getState) => {
    let firebaseToken = await functions.getIdToken()
    if (firebaseToken !== '') {
      let jsonmap = JSON.stringify({ uid: data.uid, codeType: data.codeType });
      fetch(APIURL + '/resendVerificationCode', {
        method: 'POST',
        async: false,
        body: jsonmap,
        headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 1) {
            dispatch({
              type: 'RESEND_CODE',
              message: data.message
            })
          }
          else {
            dispatch({
              type: 'RESEND_CODE',
              message: data.message
            })
          }
        })
        .catch(error => {
          dispatch({
            type: 'RESEND_CODE',
            message: error.message
          })
        });
    } else {
      return ''
    }
  }
}


export const updateAvailability = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data;
    return fetch(APIURL + '/updateAvailability', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        return data
      })
      .catch(error => {
        return {
          status: 0,
          message: error.message
        }
      })
  } else {
    return {
      status: 0,
      message: 'token not found'
    }
  }
}


export const invitedLinkVisited = async (data) => {
  let jsonmap = data;
  return fetch(APIURL + '/invitedLinkVisited', {
    method: 'POST',
    async: false,
    body: jsonmap,
    headers: { 'content-type': 'application/json' }
  })
    .then(response => response.json())
    .then(data => {
      return data
    })
    .catch(error => {
      return {
        status: 0,
        message: error.message
      }
    })
}

export const copyAvailability = (uid, date) => {
  return async (dispatch, getState) => {
    let firebaseToken = await functions.getIdToken()
    if (firebaseToken !== '') {
      let jsonmap = JSON.stringify({ 'uid': uid, 'date': date });
      fetch(APIURL + '/copyAvailability', {
        method: 'POST',
        async: false,
        body: jsonmap,
        headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
      })
        .then(response => response.json())
        .then(data => {
          if (data.status === 1) {
            dispatch({
              type: 'COPY_AVAILABILITY',
              data: data.data,
              message: data.message
            })
          }
          else {
            dispatch({
              type: 'COPY_AVAILABILITY_ERROR',
              message: data.message
            })
          }
        })
        .catch(error => {
          dispatch({
            type: 'COPY_AVAILABILITY_ERROR',
            payload: error
          })
        });
    } else {
      return ''
    }
  }
}
export const deleteAvailability = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = data;
    return fetch(APIURL + '/deleteAvailability', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        return data
      })
      .catch(error => {
        return {
          status: 0,
          message: error.message
        }
      });
  } else {
    return {
      status: 0,
      message: 'token not found'
    }
  }
}
export const getAvailability = async (data) => {
  let firebaseToken = await functions.getIdToken()
  if (firebaseToken !== '') {
    let jsonmap = JSON.stringify(data);
    return fetch(APIURL + '/getAvailability', {
      method: 'POST',
      async: false,
      body: jsonmap,
      headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
    })
      .then(response => response.json())
      .then(data => {
        return data
      })
      .catch(error => {
        return {
          type: 'UPDATE_AVAILABILITY_ERROR',
          payload: error
        }
      });
  } else {
    return {}
  }
}
// export const getSpecialities = () => {
//   return ( dispatch, getState) => {
//     fetch(WEBSITEURL+'/speciality.json')
//   .then(response => response.json())
//   .then(specialities => {
//     dispatch({
//       type: 'UPDATE_SPECIALITY',
//       specialities: specialities.specialities
//     })
//   })
//   } 
// }
export const getSpecialities = () => {
  return (dispatch, getState) => {
    fetch(APIURL + '/getAllSpecialties', {
      method: 'POST',
    })
      .then(response => response.json())
      .then(specialities => {
        dispatch({
          type: 'UPDATE_SPECIALITY',
          specialities: specialities.data
        })
      })
  }
}
export const getCountries = () => {
  return (dispatch, getState) => {
    fetch(WEBSITEURL + '/countries.json')
      .then(response => response.json())
      .then(countries => {
        dispatch({
          type: 'UPDATE_COUNTRIES',
          countries: countries.countries
        })
      })
  }
}
export const getStates = () => {
  return (dispatch, getState) => {
    fetch(WEBSITEURL + '/states.json')
      .then(response => response.json())
      .then(states => {
        dispatch({
          type: 'UPDATE_STATES',
          states: states.states
        })
      })
  }
}
export const getLanguages = () => {
  return (dispatch, getState) => {
    fetch(WEBSITEURL + '/languages.json')
      .then(response => response.json())
      .then(languages => {
        dispatch({
          type: 'UPDATE_LANGUAGES',
          languages: languages.languages
        })
      })
  }
}
export const getCodes = () => {
  return (dispatch, getState) => {
    fetch(WEBSITEURL + '/billingcodes.json')
      .then(response => response.json())
      .then(codes => {
        dispatch({
          type: 'UPDATE_CODES',
          codes: codes.codes
        })
      })
  }
}
