import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Fab, TextField, Button, CircularProgress, Link } from '@material-ui/core'
import withStyles from '@material-ui/styles/withStyles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from "@material-ui/icons/Add"
import EditIcon from '@material-ui/icons/Edit'
import styled from 'styled-components';
import { Alert, Modal } from 'antd'
import { createAllergyHistory, updateAllergyHistory, deleteAllergyHistory, getAllergiesList } from '../../store/actions/EMRAction'
import { Autocomplete } from '@material-ui/lab'
import Functions from '../../assets/js/function'
import { DragSource } from "react-dnd";


const StyledModal = styled(Modal)`
.ant-modal-title {
    color: white;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
}
.ant-modal-header{
    background: rgb(0,178,181);
    border-radius: 9px 9px 0 0;
}
.ant-modal-body{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}
.ant-modal-content{
    border-radius: 9px;
}
.ant-modal-close-x{
    color:white;
}
.ant-modal-footer{
    text-align:center;
    border-top:none;
}
`;

const styles = theme => ({
    parent: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2),
        position: 'relative',
        width: '100%',
        '& .MuiPaper-root': {
            borderRadius: '20px',
            // maxHeight:'200px',
            minHeight: '50px',
            // overflow:'scroll'

        },
    },
    // expandedPanel:{
    //     overflow:'scroll',
    //     heigth:'100px'
    // },
    panel: {
        padding: 0,
        width: '100%',
        // backgroundColor:'red',
        marginBottom: theme.spacing(3),
        borderRadius: '10px',

    },
    modalWrap: {
        zIndex: '1500',
    },
    modal: {
        display: 'flex',
        flexDirection: 'column'
    },
    error: {
        marginBottom: theme.spacing(2) + 'px !important'
    },
    heading: {
        marginLeft: theme.spacing(4)
    },
    list: {
        // display: 'flex',
        flexDirection: 'column',
        alignItems: 'baseline',
        paddingBottom: theme.spacing(5)
    },
    add: {
        color: 'white',
        bottom: '0 !important',
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    item: {
        borderBottom: '1px solid #80808036',
        width: '100%',
        marginBottom: theme.spacing(1),
        textAlign: 'left',
        display: 'grid',
        justifyItems: 'baseline',
        gridTemplateColumns: '48% 48% 4%'
    },
    itemHead: {
        fontWeight: 700,
    },
    itemButton: {
        cursor: 'pointer',
        fontSize: '15px',
        '&:hover': {
            color: theme.palette.primary.main
        }
    },
    field: {
        marginBottom: theme.spacing(2)
    },
    autocomplete: {
        width: "200px",
    },
    popper: {
        zIndex: 2000,
    },
    paper: {
        width: '300px',
    },
    medication: {
        textAlign: 'left',
        wordBreak: 'break-all'
    },
    customMessage: {
        flex: "0 0 100%",
        marginBottom: theme.spacing(2)
    },
    buttonCancel: {
        backgroundColor: 'rgb(240, 242, 245)',
        width: "120px",
        color: theme.palette.lightGrey.main,
        padding: "7px",
        borderRadius: '10px',
        border: 'none',
        height: '50px',

    },
    buttonDelete: {
        width: "120px",
        borderRadius: '10px',
        border: 'none',
        height: '50px',
        padding: "7px",
        color: 'white',
        backgroundColor: "#df4759",
        "&:hover": {
            backgroundColor: "#c31432",
        },
    },
    buttonSave: {
        width: "120px",
        borderRadius: '10px',
        border: 'none',
        color: 'white',
        height: '50px',
        padding: "7px",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: "#1f4037"
        },
    },
    viewMoreButton: {
        width: "120px",
        borderRadius: '10px',
        border: 'none',
        padding: "7px",
        height: '3rem',
        textTransform: 'none',
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: "#1f4037"
        },
    }
})

const tile = {
    beginDrag(props, monitor, component) {
        return { type: "allergy", history: component.state.allergyHistoryList.data };
    }
};

const collect = (connect) => ({
    dragSource: connect.dragSource()
});

class Allergies extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: true,
            allergiesHistory: false,
            allergy: '',
            reaction: '',
            disable: true,
            alergyerror: false,
            reactionerror: false,
            loader: false,
            showAutocompleteList: false,
            id: '',
            doctorId: props.doctorId,
            userId: props.userId,
            customMedication: false,
            showCustomMedication: false,
            allergyListArray: [],
            allergyHistoryList: { data: [] },
            responseMessage: '',
            pagination_id: 0,
            loadItems: true,
        }
    }

    componentDidMount() {
        this.getAllergyHistory()
    }
    componentDidUpdate() {
        if (this.state.loader === true && this.state.responseMessage !== '') {
            this.setState({ errormsg: this.state.responseMessage, loader: false, disable: false })
        }
        else {
            setTimeout(() => {
                if (this.state.loader === true && this.state.responseMessage === '')
                    this.setState({ errormsg: '', addAllergiesHistory: false, loader: false, reaction: '', allergy: '', id: '', disable: true, showAutocompleteList: false, customMedication: false })
            }, 1000)
        }
    }
    expanded = () => {
        this.setState({ expanded: !this.state.expanded })
    }
    addAllergiesHistory = () => {
        this.setState({ addAllergiesHistory: true })
    }
    getAllergyHistory = async () => {
        let result = await this.props.getAllergiesList(JSON.stringify({ userId: this.state.userId, doctorId: this.state.doctorId, pagination_id: this.state.pagination_id }))
        let previousData = this.state.allergyHistoryList.data
        if (this.state.pagination_id === 0)
            previousData = result.data
        else
            previousData = previousData.concat(result.data)
        this.setState({ loadItems: false, allergyHistoryList: { data: previousData }, responseMessage: result.message, pagination_id: result.pagination_id })
    }
    onSave = async () => {
        this.setState({ loader: true })
        let result = ''
        if (this.state.id === '')
            result = await this.props.createAllergyHistory(JSON.stringify({ reaction: this.state.reaction, allergy: this.state.allergy, userId: this.state.userId, doctorId: this.state.doctorId, pagination_id: this.state.pagination_id }))
        else
            result = await this.props.updateAllergyHistory(JSON.stringify({ reaction: this.state.reaction, allergy: this.state.allergy, userId: this.state.userId, doctorId: this.state.doctorId, id: this.state.id, pagination_id: this.state.pagination_id }))
        let previousData = result.data
        this.setState({ allergyHistoryList: { data: previousData }, responseMessage: result.message, pagination_id: result.pagination_id })
    }
    onDelete = async () => {
        this.setState({ loader: true })
        let result = await this.props.deleteAllergyHistory(JSON.stringify({ id: this.state.id, userId: this.state.userId, doctorId: this.state.doctorId, pagination_id: this.state.pagination_id }))
        let previousData = result.data
        this.setState({ allergyHistoryList: { data: previousData }, responseMessage: result.message, pagination_id: result.pagination_id })
    }
    onUpdate = async (reaction, id, allergy) => {
        this.setState({ addAllergiesHistory: true, reaction: reaction, id: id, allergy: allergy, disable: false, showAutocompleteList: false })
    }
    onCancel = () => {
        this.setState({ disable: true })
        this.setState({ errormsg: '', addAllergiesHistory: false, loader: false, reaction: '', allergy: '', showAutocompleteList: false })
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, this.checkDisable)
        if (e.target.value === '')
            this.setState({ reactionerror: true })
        else
            this.setState({ reactionerror: false })
    }
    allergyChange = (e, val) => {
        let value = ''
        if (val !== null)
            value = val
        this.setState({ allergy: value, showAutocompleteList: false }, this.checkDisable)
        if (value === '')
            this.setState({ allergyerror: true })
    }
    allergyType = async (e, val) => {
        if (val.length > 3) {
            let allergyArray = []
            this.setState({ allergy: val })
            let result = await Functions.searchMedicationList(val)
            allergyArray = result.data
            this.setState({ showAutocompleteList: true, allergyListArray: allergyArray })
            if (allergyArray.length === 0)
                this.setState({ customMedication: true, showAutocompleteList: false })
            else
                this.setState({ customMedication: false })
            if (allergyArray.length === 1 && allergyArray[0] === val) {
                this.setState({ showAutocompleteList: false })
            }
        }
        else if (val.length < 4)
            this.setState({ allergy: val, showAutocompleteList: false })
    }
    checkDisable = () => {
        if (this.state.allergy !== '' && this.state.reaction !== '')
            this.setState({ disable: false })
        else
            this.setState({ disable: true })
    }
    setShowCustomMedication = () => {
        this.setState({ showCustomMedication: !this.state.showCustomMedication })
    }
    render() {
        const { dragSource, classes } = this.props;
        return dragSource(
            <div className={classes.parent}>
                <ExpansionPanel
                    // TransitionProps={{
                    //     timeout: 1
                    //     }}
                    expanded={this.state.expanded} onChange={this.expanded}
                    className={classes.panel}
                    classes={{ expanded: classes.expandedPanel }}

                >
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading + " " + classes.itemHead}>Allergies</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.list}>
                        <>
                            {!this.state.loadItems ?
                                this.state.allergyHistoryList.data.length === 0 ?
                                    <p key={0}>No Allergy History Found</p> :
                                    <>
                                        <Grid className={classes.item} key={-1}>
                                            <Typography className={classes.itemHead}>{'Allergy'}</Typography>
                                            <Typography className={classes.itemHead}>{'Reaction'}</Typography>
                                            <Typography className={classes.itemHead}>{''}</Typography>
                                        </Grid>
                                        {this.state.allergyHistoryList.data.map((allergyHistory, key) => {
                                            let allergy = allergyHistory.allergy.split(' ');
                                            return <Grid className={classes.item} key={key}>
                                                <Typography className={classes.medication}>{allergy[0]} </Typography>
                                                <Typography>{allergyHistory.reaction}</Typography>
                                                <EditIcon onClick={() => this.onUpdate(allergyHistory.reaction, allergyHistory.id, allergyHistory.allergy)} className={classes.itemButton} />
                                            </Grid>
                                        })
                                        }
                                        {this.state.pagination_id !== -1 &&
                                            <Button
                                                type="submit"
                                                onClick={() => this.getAllergyHistory()}
                                                className={classes.viewMoreButton}
                                            >
                                                View More
                                            </Button>
                                        }
                                    </>
                                :
                                <CircularProgress size={24} />
                            }
                        </>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <Fab color="primary" className={classes.add} onClick={this.addAllergiesHistory}>
                    <AddIcon />
                </Fab>
                {/* TODO FIX */}
                {this.state.addAllergiesHistory ?
                    <StyledModal
                        className="modal"
                        title={"Add Allergies History"}
                        visible={true}
                        // visible={this.state.addAllergiesHistory}
                        width={515}
                        zIndex={"2000"}
                        onCancel={this.onCancel}
                        footer={
                            <Grid>
                                {this.state.loader ?
                                    <CircularProgress size={24} /> :
                                    //add class to style buttons spacing
                                    <Grid>
                                        {this.state.id !== "" ?
                                            <Button style={{ marginRight: '10%' }} className={classes.buttonDelete} variant="contained" onClick={this.onDelete}>Delete</Button> : ""}
                                        {this.state.id !== "" ?
                                            <Button style={{ marginRight: '10%' }} className={classes.buttonCancel} variant="outlined" onClick={this.onCancel}>Cancel</Button>
                                            : <Button style={{ marginRight: '50%' }} className={classes.buttonCancel} variant="outlined" onClick={this.onCancel}>Cancel</Button>
                                        }
                                        <Button disabled={this.state.disable} className={classes.buttonSave} variant="contained" onClick={this.onSave}>Save</Button>
                                    </Grid>
                                }
                            </Grid>
                        }
                    >

                        <Grid className={classes.modal}>
                            {this.state.errormsg ?
                                <Grid className={classes.error}>
                                    <Alert type="error" closable={true} message={this.state.errormsg}></Alert>
                                </Grid>
                                : ""}
                        </Grid>
                        <Grid className={classes.customMessage}>
                            <Link onClick={this.setShowCustomMedication} variant="body2">
                                {!this.state.showCustomMedication ?
                                    "Add custom allergy"
                                    : "Click here to search"}
                            </Link>
                        </Grid>
                        {!this.state.showCustomMedication ?
                            <div>
                                <Grid className={classes.field}>
                                    <Autocomplete
                                        id="combo-box-demo"
                                        open={this.state.showAutocompleteList}
                                        value={this.state.allergy}
                                        options={this.state.allergyListArray}
                                        classes={{ popper: classes.popper, paper: classes.paper }}
                                        className={classes.autocomplete + ' medication_search_box'}
                                        getOptionLabel={(option) => option}
                                        onChange={this.allergyChange}
                                        freeSolo
                                        onInputChange={this.allergyType}
                                        renderInput={(params) => <TextField {...params} label="Search medication" variant="outlined" />}
                                    />
                                </Grid>
                            </div>
                            : ""
                        }
                        {this.state.showCustomMedication ?
                            <Grid>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    name="allergy"
                                    className={classes.error}
                                    label="Allergy"
                                    value={this.state.allergy}
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            : ""}
                        <Grid>
                            <TextField
                                type="text"
                                variant="outlined"
                                margin="normal"
                                required
                                style={{ width: '200px' }}
                                name="reaction"
                                label="Reaction"
                                error={this.state.reactionerror}
                                helperText={this.state.reactionerror ? "Reaction field required" : ""}
                                value={this.state.reaction}
                                onChange={this.handleChange}
                            />
                        </Grid>
                        {this.state.showCustomMedication ?
                            <>
                                <Grid className={classes.item} key={-1}>
                                    <Typography className={classes.itemHead}>{'Allergy'}</Typography>
                                    <Typography className={classes.itemHead}>{'Reaction'}</Typography>
                                    <Typography className={classes.itemHead}>{''}</Typography>
                                </Grid>
                                {this.state.allergyHistoryList.data.map((allergyHistory, key) => {
                                    let allergy = allergyHistory.allergy.split(' ');
                                    return <Grid className={classes.item} key={key}>
                                        <Typography className={classes.medication}>{allergy[0]} </Typography>
                                        <Typography>{allergyHistory.reaction}</Typography>
                                        <EditIcon onClick={() => this.onUpdate(allergyHistory.reaction, allergyHistory.id, allergyHistory.allergy)} className={classes.itemButton} />
                                    </Grid>
                                })
                                }
                            </>
                            : null}
                    </StyledModal>
                    : null}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createAllergyHistory: (data) => createAllergyHistory(data),
        updateAllergyHistory: (data) => updateAllergyHistory(data),
        deleteAllergyHistory: (data) => deleteAllergyHistory(data),
        getAllergiesList: (data) => getAllergiesList(data),
    }
}
export default connect(null, mapDispatchToProps)(withStyles(styles)(DragSource("history", tile, collect)(Allergies)))