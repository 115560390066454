import React from 'react'
import { Modal } from 'antd'

function CookiesModal() {
    return (
        <div>
            {/* CONTACT INFO UPDATED SUCCESS MODEL */}
            <Modal
                title={`Enable Browser Cookies`}
                visible={!navigator.cookieEnabled}
                footer={null}
            >
                <h3>{`Cookies are not enabled in browser, please enable before continuing.`}</h3>
            </Modal>
        </div>
    )
}

export default CookiesModal
