const initState = {}

const accountReducer = (state = initState, action) => {
    switch (action.type) {
        case 'UPDATE_SPECIALITY':
            state = { authError: action.type, specialities: action.specialities, countries: state.countries, states: state.states, languages: state.languages, codesList: state.codes }
            return {
                ...state
            }
        case 'UPDATE_COUNTRIES':
            state = { authError: action.type, specialities: state.specialities, countries: action.countries, states: state.states, languages: state.languages, codesList: state.codes }
            return {
                ...state
            }
        case 'UPDATE_STATES':
            state = { authError: action.type, specialities: state.specialities, countries: state.countries, states: action.states, languages: state.languages, codesList: state.codes }
            return {
                ...state
            }
        case 'UPDATE_LANGUAGES':
            state = { authError: action.type, specialities: state.specialities, countries: state.countries, states: state.states, languages: action.languages, codesList: state.codes }
            return {
                ...state
            }
        case 'UPDATE_CODES':
            state = { authError: action.type, specialities: state.specialities, countries: state.countries, states: state.states, languages: state.languages, codesList: action.codes }
            return {
                ...state
            }
        default:
            return state;
    }
}

export default accountReducer