import React, { Component } from 'react'
import { Grid, TableCell, CircularProgress } from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles"
import { isMobileOnly } from "react-device-detect"
import { ViewState, EditingState } from "@devexpress/dx-react-scheduler"
import {
    Scheduler,
    MonthView,
    Appointments,
    Toolbar,
    DateNavigator,
    AppointmentTooltip,
} from "@devexpress/dx-react-scheduler-material-ui"
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EventIcon from '@material-ui/icons/Event';
import Functions from '../../assets/js/function'

const styles = theme => ({
    scheduler: {
        width: '95%',
        fontFamily: 'revert',
        maxWidth: '1200px',
        margin: 'auto',
        paddingTop: theme.spacing(5),
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            "&>div>div.MuiToolbar-regular": {
                display: 'flex',
                flexDirection: 'column'
            }
        }
    },
    toolbarForTest: {
        display: 'none'
    },
    cell: {
        color: "#838383 !important",
        position: "relative",
        userSelect: "none",
        verticalAlign: "top",
        padding: 0,
        height: 100,
        border: '1px solid #e7e7e7',
        fontWeight: 500,
    },
    hideCell: {
        visibility: 'hidden',
        lineHeight: 0,
        padding: 0,
        fontSize: 0,
        border: 0
    },
    text: {
        padding: "0.5em",
        textAlign: "center"
    },
    appointment: {
        borderRadius: "10px",
        backgroundColor: "#7e57c2",
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        fontFamily: 'inherit',
        "&:hover": {
            opacity: 0.6,
            backgroundColor: "#7e57c2",
        }
    },
    apptContent: {
        "&>div>div": {
            whiteSpace: "normal !important",
            lineHeight: 1.2,
            fontSize: '1rem',
            fontWeight: 600,
        }
    },
    flexibleSpace: {
        flex: "none",
        fontSize: '1.2rem',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: { width: '100%' }
    },
    slotsList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    slotsListItem: {
        width: '49%',
        textAlign: 'center',
        backgroundColor: '#7e57c2',
        borderRadius: '3px',
        marginBottom: '3%',
        padding: '1% .5%',
        color: '#fff',
        fontFamily: 'inherit',
        fontSize: '1rem',
        fontWeight: 600,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: { width: '100%' }
    },
    customTollTip: {
        padding: '10px 0'
    },
    dateClass: {
        fontSize: theme.spacing(3),
        color: "#00bbbe"
    },
    headerClass: {
        backgroundColor: '#854da4',
        borderRadius: '10px 10px 0px 0',
        padding: '10px 0px',
        fontSize: '1.2rem',
        fontWeight: 600,
        color: '#fff'
    },
    textField: {
        position: 'absolute',
        visibility: 'hidden',
        left: 0,
        top: '30px'
    }
})

const DayScaleCell = (props) => (
    <MonthView.DayScaleCell
        {...props}
        style={{ textAlign: "center", fontWeight: "bold" }}
    />
);

// #FOLD_BLOCK
const CellBase = React.memo(
    ({
        classes,
        startDate,
        formatDate,
        ...restProps
        // #FOLD_BLOCK
    }) => {
        const isFirstMonthDay = startDate.getDate() === 1;
        const formatOptions = isFirstMonthDay
            ? { day: "numeric", month: "long" }
            : { day: "numeric" };
        return (
            <>
                {
                    !restProps.otherMonth ?
                        <TableCell tabIndex={0} className={classes.cell}>
                            <div className={classes.content}></div>
                            <div className={classes.text}>
                                {formatDate(startDate, formatOptions)}
                            </div>
                        </TableCell>
                        :
                        <TableCell tabIndex={0} className={classes.hideCell}>
                            <div className={classes.content}></div>
                            <div className={classes.text}>
                                {formatDate(startDate, formatOptions)}
                            </div>
                        </TableCell>
                }
            </>
        );
    }
);

const TimeTableCell = withStyles(styles, { name: "Cell" })(CellBase);

const Appointment = withStyles(styles, {
    name: "Appointment"
})(({ classes, ...restProps }) => (
    <Appointments.Appointment {...restProps} className={classes.appointment} />
));

const AppointmentContent = withStyles(styles, {
    name: "AppointmentContent"
})(({ classes, ...restProps }) => (
    <Appointments.AppointmentContent
        {...restProps}
        className={classes.apptContent}
    />
));


class RhynoGoCalendar extends Component {
    constructor(props) {
        super(props)
        let currentDateTime = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }))
        let isConsideredAsCurrentDay = false
        if (currentDateTime.getHours() > 22 || (currentDateTime.getHours() === 22 && currentDateTime.getMinutes() > 30)) {
            isConsideredAsCurrentDay = true
        }
        console.log("isConsideredAsCurrentDay", isConsideredAsCurrentDay)
        let addedDays = 0
        if (isMobileOnly && !(this.props.appointmentData.hasOwnProperty('isUrgentPCR') && this.props.appointmentData.isUrgentPCR === true) && !((this.props.appointmentData.hasOwnProperty('addedBy') && this.props.appointmentData.addedBy === "agency") || (this.props.appointmentData.hasOwnProperty('isAgency') && this.props.appointmentData.isAgency === 'Agency')))
            addedDays = 1
            console.log("addedDays", addedDays)
        this.state = {
            currentDate: moment.tz("Canada/Eastern").add(addedDays, 'days'),
            data: [],
            loader: false,
            type: props.appointmenttype ? props.appointmenttype : (props.appointmentData && props.appointmentData.type) ? props.appointmentData.type : "",
            next: 0,
            previous: 0,
            open: false,
            startTimeHour: 8,
            endTimeHour: 20,
            discardedHours: [],
            activeDays: [],
            extraTime: {},
            hourGap: props.appointmentData && props.appointmentData.hasOwnProperty('isUrgentPCR') && props.appointmentData.isUrgentPCR === true ? 0 : 4,
            hoursdifference: 0,
            discardedDaysHours: {},
            isConsideredAsCurrentDay: isConsideredAsCurrentDay
        };
        this._isMounted = false;
        this.commitChanges = this.commitChanges.bind(this);
        this.currentDateChange = async (currentDate, action = 'n') => {
            if (this._isMounted) {
                this.setState({ open: false })
                this.updateMobileCurrentDate(currentDate, action)
            }
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true
        this.getAllTimeOff()
    }
	
    getAllTimeOff = async () => {
        // FOR TASK RHYN-107
        // Separate postal codes, availability and time off
        // SET isIvBU TO TRUE IF IV THERAPY AND BLOOD URINE TEST TO GET CALENDAR SLOTS TIME FROM IVBU COLLECTIONS ELSE GET CALENDAR SLOTS TIME FROM NORMAL COLLECTIONS
		// Iv = thropy BU blood urine Test key
        let isIvBU = false
        if (this.props.appointmentData.reason === 'AT HOME BLOOD AND URINE TESTING' || this.props.appointmentData.covidType === 'IV THERAPY AND HYDRATION') {
            isIvBU = true
        }
        let zipCodesHoursData = await Functions.getZipCodesHours(this.props.appointmentData.userSelectedZipCode, isIvBU)
        if (zipCodesHoursData.status) {
            let data = zipCodesHoursData.data
            // FOR TASK RHYN-107
            // Separate postal codes, availability and time off
            // SET ACTIVE DAYS RECEIVED FROM API
            this.setState({ startTimeHour: data.startHour, endTimeHour: data.endHour, discardedHours: data.discardedHour, extraTime: data.extraTime, activeDays: data.days })
        }

        let result = await Functions.getAllTimeOff(isIvBU)
        if (result.status) {
            this.setState({ discardedDaysHours: result.data })
            this.updateMobileCurrentDate(this.state.currentDate)
        }
    }
	
    updateMobileCurrentDate = (currentDate, action = 'n') => {

        if (isMobileOnly) {
            let mobileDate = new Date(currentDate)
            console.log("mobileDate :",mobileDate)
            if (new Date(currentDate).getTime() > new Date().getTime()) {
                this.setState({ currentDate: mobileDate, data: [] }, () => { this.clickButton(action) })
            }
        }
        else
            this.setState({ currentDate: currentDate, data: [] }, () => { this.clickButton() })
    }
    nextStep = (slot) => {
        console.log(slot ," : Slot  : ",  new Date(slot*1000) )
        let stepNo = 5
        this.props.updateStepNo(stepNo, { slot, previousStep: 10 })
    }
    Content = withStyles(styles, { name: 'Content' })(({
        children, appointmentData, classes, ...restProps
    }) => (
            <AppointmentTooltip.Content className={classes.customTollTip + ' appointmentSlotsModel'} {...restProps} appointmentData={appointmentData}>
                <Grid container alignItems="center">
                    <Grid item xs={12} className={classes.slotsList}>
                        {appointmentData.slots.map((item, key) => {
                            let slotText = item.text
                            item = parseInt(item.time)
                            let returnedItem = ''
                            this.state.type === "" ?
                                returnedItem = <span onClick={() => { this.nextStep(item) }} className={classes.slotsListItem} key={key}>{slotText}</span>
                                :
                                this.state.type === 'update' ?
                                    returnedItem = <span onClick={() => { this.props.updateAppointment(item) }} className={classes.slotsListItem} key={key}>{slotText}</span>
                                    :
                                    returnedItem = <span onClick={() => { this.props.updateRhynoGoTime(item) }} className={classes.slotsListItem} key={key}>{slotText}</span>

                            return returnedItem
                        })}
                    </Grid>
                </Grid>
            </AppointmentTooltip.Content>
        ));
    // #FOLD_BLOCK
    commitChanges({ added, changed, deleted }) {
        if (this._isMounted) {
            this.setState((state) => {
                let { data } = state;
                if (added) {
                    const startingAddedId =
                        data.length > 0 ? data[data.length - 1].id + 1 : 0;
                    data = [...data, { id: startingAddedId, ...added }];
                }
                if (changed) {
                    data = data.map((appointment) =>
                        changed[appointment.id]
                            ? { ...appointment, ...changed[appointment.id] }
                            : appointment
                    );
                }
                if (deleted !== undefined) {
                    data = data.filter((appointment) => appointment.id !== deleted);
                }
                return { data };
            });
        }
    }
    // GENERATE DATE
    calculateDate = (date) => {
        console.log("calculateDate ",date)
        // Set Date To 00:00:00 AM
        let receivedDate = new Date(date)
        let newCreatedDate = new Date()
        newCreatedDate.setHours(receivedDate.getTimezoneOffset() / (-60))
        newCreatedDate.setHours(0)
        newCreatedDate.setSeconds(0)
        newCreatedDate.setMilliseconds(0)
        newCreatedDate.setMinutes(0)
        if (!isMobileOnly)
            newCreatedDate.setDate(1)
        else
            newCreatedDate.setDate(receivedDate.getDate())
        newCreatedDate.setMonth(receivedDate.getMonth())
        newCreatedDate.setFullYear(receivedDate.getFullYear())

        let receivedDateInMiliSeconds = new Date(newCreatedDate).getTime()
        let timeStampInUTC = Math.ceil(receivedDateInMiliSeconds / 1000)
        console.log("timeStampInUTC ",timeStampInUTC)
        return timeStampInUTC
    }

    clickButton = async (action = 'n') => {

        if (this._isMounted) {
            this.setState({ loader: true })
        }
        // CREATE TIME SLOTS

        // Today Date / Calander Date Only For Day is stored in the currentDate 
        let calendarDate = new Date(this.state.currentDate)

        let estStartTime = moment.tz("Canada/Eastern");
        estStartTime = moment.tz(estStartTime, "Canada/Eastern")
        let currentESTTime = moment.tz(estStartTime, "Canada/Eastern").unix()
        let estStartTimeHours = moment.tz(estStartTime, "Canada/Eastern").format('HH')
        let calendarYMD = calendarDate.getFullYear() + '' + calendarDate.getMonth() + '' + calendarDate.getDate()
        let currentYMD = (new Date().getFullYear()) + '' + (new Date().getMonth()) + '' + (new Date().getDate())
        if (currentYMD === calendarYMD && estStartTimeHours >= 18) {
            calendarDate.setDate(calendarDate.getDate() + 1)
            this.setState({ currentDate: calendarDate }, () => this.clickButton())
        } else {

            if (!isMobileOnly) {
                let currentDateinUnix = moment.tz("Canada/Eastern").unix()
                let calendarCurrentDate = moment.tz(this.state.currentDate, "Canada/Eastern").unix()
                if (calendarCurrentDate > currentDateinUnix) {
                    estStartTime = moment.tz(this.state.currentDate, "Canada/Eastern").startOf('month').add(parseInt(this.state.hourGap), 'hours').format("YYYY-MM-DD 08:00:00");
                }
                else {
                    estStartTime = moment.tz(this.state.currentDate, "Canada/Eastern").add(parseInt(this.state.hourGap), 'hours').format("YYYY-MM-DD 08:00:00")
                }

            } else {
                let now = moment.tz(this.state.currentDate,"Canada/Eastern")
                console.log("now",now.hours())
               if((now.hours()-24) > 4){
                estStartTime = moment.tz(this.state.currentDate, "Canada/Eastern").add(parseInt(this.state.hourGap), 'hours').format("YYYY-MM-DD 08:00:00")
                }else{
                    estStartTime = moment.tz(this.state.currentDate, "Canada/Eastern").format("YYYY-MM-DD 08:00:00")
                }
            }
            let estEndTime = estStartTime
            if (!isMobileOnly) {
                estEndTime = moment.tz(estEndTime, "Canada/Eastern").endOf('month').format('YYYY-MM-DD 23:59:59')
            } else {
                estEndTime = moment.tz(estEndTime, "Canada/Eastern").format('YYYY-MM-DD 23:59:59')
                console.log(" estEndTime ", estEndTime)
            }
            let startTimeInSeconds = moment.tz(estStartTime, "Canada/Eastern").unix()//Math.floor(new Date(estStartTime).getTime() / 1000)
            let endTimeInSeconds = moment.tz(estEndTime, "Canada/Eastern").unix()// Math.floor(new Date(estEndTime).getTime() / 1000)

            let slotsArray = []

            let inAllowedTime = true

            let startHour = 0
            let endHour = 0

            if (startTimeInSeconds < endTimeInSeconds) {
                let startSlot = startTimeInSeconds
                let endSlot = startSlot + (2 * 60 * 60)
                while (endSlot <= endTimeInSeconds) {

                    //As this is the UnixTime stamp so we need to give microsecond to moment that's why we have to mulitpley with 1000 here to get the correct  hours
                    startHour = moment.tz(startSlot * 1000, "Canada/Eastern").format('HH') //new Date(startSlot * 1000).getHours()
                    endHour = moment.tz(endSlot * 1000, "Canada/Eastern").format('HH') //new Date(endSlot * 1000).getHours()
                    startHour = parseInt(startHour)
                    endHour = parseInt(endHour)
                    let discardedKey = moment.tz(startSlot * 1000, "Canada/Eastern").format('YYYY-M-D') //time.getFullYear() + '-' + (time.getMonth() + 1) + '-' + time.getDate()                     

                    if (this.state.extraTime && this.state.extraTime.hasOwnProperty(discardedKey)) {
                        if (this.state.extraTime[discardedKey].discardedHour.indexOf(startHour) === -1
                            && startHour >= parseInt(this.state.extraTime[discardedKey].startHour)
                            && endHour <= parseInt(this.state.extraTime[discardedKey].endHour) && startHour < endHour)
                            inAllowedTime = true
                        else
                            inAllowedTime = false
                    }
                    else if (this.state.discardedHours.indexOf(startHour) === -1
                        && startHour >= parseInt(this.state.startTimeHour)
                        && endHour <= parseInt(this.state.endTimeHour)
                        && startHour < endHour) {
                        inAllowedTime = true
                    }
                    else {
                        inAllowedTime = false
                    }


                    if (startSlot > currentESTTime && endSlot > currentESTTime && inAllowedTime) {

                        let slotName = ''

                        let canPush = true
                        if (this.state.discardedDaysHours[discardedKey] && !(this.state.extraTime && this.state.extraTime.hasOwnProperty(discardedKey))) {
                        // if (this.state.discardedDaysHours[discardedKey]) {
                            let currentHours = startHour
                            let discardedDaysHoursValue = this.state.discardedDaysHours[discardedKey]
                            if (currentHours >= discardedDaysHoursValue[0] && currentHours < discardedDaysHoursValue[1]) {
                                canPush = false
                            }
                        }
                        // FOR TASK RHYN-107
                        // TASK DESCRIPTION: Separate postal codes, availability and time off
                        // CHECK IN DAYS ARRAY IF EXIST THEN ADD IN ARRAY ELSE DISCARD THAT DAY
                        if (canPush) {
                            let day = moment.tz(startSlot * 1000, "Canada/Eastern").format('ddd')
                            if (this.state.activeDays.indexOf(day) === -1) {
                                canPush = false
                            }
                        }
                        if (canPush) {
                            let startTimeUnix = moment.tz(startSlot * 1000, "Canada/Eastern").unix()
                            slotsArray.push({ [startTimeUnix]: slotName })
                        }
                    }
                    startSlot = endSlot
                    endSlot = endSlot + (2 * 60 * 60)

                }
            }
            if ((isMobileOnly && slotsArray.length > 0) || !isMobileOnly) {
                console.log("slotsArray.length ",slotsArray.length)
                // END TIME SLOTS
                let timeSlotDifference = 15
                let appointments = {}
                for (let i = 0; i < slotsArray.length; i++) {
                    let slotTime1 = slotsArray[i]

                    let slotTime, slotText
                    for (let [key, value] of Object.entries(slotTime1)) {
                        slotTime = key
                        slotText = value
                    }
                    let key = moment.tz(slotTime * 1000, "Canada/Eastern").format("YYYY-MM-DD")
                    let hours = parseInt(moment.tz(slotTime * 1000, "Canada/Eastern").format('HH'))
                    let famPm = ' AM '
                    let lamPm = (hours + 2) > 11 ? ' PM ' : ' AM '

                    if (hours > 11) {
                        famPm = ' PM '
                        hours = hours > 12 ? hours - 12 : hours
                    }
                    slotText = hours.toString().padStart(2, '0') + ":00" + famPm + 'TO ' + (hours + 2 > 12 ? (hours + 2) - 12 : hours + 2).toString().padStart(2, '0') + ":00" + lamPm
                    if (isMobileOnly)
                        key = key + '-' + slotTime
                    if (appointments.hasOwnProperty(key)) {
                        let counter = parseInt(appointments[key].count) + 1
                        let slots = appointments[key].slots
                        slots.push({ time: slotTime, text: slotText })
                        appointments[key].count = counter
                        appointments[key].title = counter + " slots available"
                        appointments[key].slots = slots
                        console.log("appointments[key] ",appointments[key]  )
                    }
                    else {

                        let currentYMD = moment.tz(slotTime * 1000, "Canada/Eastern").format("YYYY-MM-DD")
                        let ymdDate = moment.tz("Canada/Eastern").format("YYYY-MM-DD")

                        if (isMobileOnly) { currentYMD = ymdDate }

                        if (!isMobileOnly) { calendarYMD = ymdDate }

                        let calendarYMD1 = calendarYMD
                        if (this.state.isConsideredAsCurrentDay)
                            calendarYMD1 = moment.tz("Canada/Eastern").add(1, 'days').format("YYYY-MM-DD")

                        let dateComparison = false
                        if (currentYMD !== calendarYMD && currentYMD !== calendarYMD1)
                            dateComparison = true
                        if ((this.props.appointmentData.hasOwnProperty('isUrgentPCR') && this.props.appointmentData.isUrgentPCR === true) || (this.props.appointmentData.hasOwnProperty('addedBy') && this.props.appointmentData.addedBy === "agency") || (this.props.appointmentData.hasOwnProperty('isAgency') && this.props.appointmentData.isAgency === 'Agency') || (dateComparison && !(this.props.appointmentData.hasOwnProperty('isUrgentPCR') && this.props.appointmentData.isUrgentPCR === true))) {
                            appointments[key] = {
                                count: 1,
                                title: 1 + " slots available",
                                slots: [{ time: slotTime, text: slotText }],
								//As we have to display the day on the calendar so we have fixed the time for that form 12:00 AM to 23:59 PM  complete 24 hours
								//We don't need timezone becuase RhynoGo Appointments only booked in the canda from where ever so we don't need to worry about the timezone conversion.
                                startDate: moment.tz(slotTime* 1000,"Canada/Eastern").format("YYYY-MM-DDT12:00") ,
                                endDate: moment.tz(slotTime* 1000,"Canada/Eastern").add(parseInt(timeSlotDifference), 'minutes').format("YYYY-MM-DDT23:59")  
                            }
                        }
                    }
                }
                appointments = Object.values(appointments)
                if (this._isMounted) {

                    let next = moment.tz(this.state.currentDate,"Canada/Eastern").add(1, 'days')
                    let previous = moment.tz(this.state.currentDate,"Canada/Eastern").subtract(1, 'days')

                    this.setState({ data: appointments, loader: false, next: next, previous: previous })
                }
            }
            else {
                let newDate = action === 'n' ? moment.tz(this.state.currentDate,"Canada/Eastern").add(1, 'days') : moment.tz(this.state.currentDate,"Canada/Eastern").subtract(1, 'days')
                this.updateMobileCurrentDate(newDate, action)
            }
        }
    }

    render() {
        const { classes } = this.props
        return (
            <Grid className={classes.scheduler + " appointmentScheduler"}>
                {!isMobileOnly ?
                    <Scheduler data={this.state.data}>
                        <EditingState onCommitChanges={this.commitChanges} />
                        <ViewState
                            currentDate={this.state.currentDate}
                            onCurrentDateChange={this.currentDateChange}
                        />
                        <MonthView
                            timeTableCellComponent={TimeTableCell}
                            dayScaleCellComponent={DayScaleCell}
                        />
                        <Appointments
                            appointmentComponent={Appointment}
                            appointmentContentComponent={AppointmentContent}
                        />
                        <Toolbar />
                        {/* <Toolbar flexibleSpaceComponent={this.flexibleSpace} /> */}
                        <DateNavigator />
                        <AppointmentTooltip
                            contentComponent={this.Content}
                            showCloseButton
                        />
                        {this.state.loader ? <div style={{ position: 'absolute', zIndex: 1, top: '50%', left: '50%' }}><CircularProgress style={{ border: 0 }} size={35} /></div> : ""}
                    </Scheduler>
                    :
                    <>
                        <div className={classes.headerClass}>
                            <div>
                                <ChevronLeftIcon fontSize={'large'} color={this.state.previous === 0 ? 'disabled' : 'inherit'} onClick={() => this.currentDateChange(this.state.previous, 'p')} style={{ zIndex: 10 }} />
                                <ChevronRightIcon fontSize={'large'} color={this.state.next === 0 ? 'disabled' : 'inherit'} onClick={() => this.currentDateChange(this.state.next, 'n')} style={{ zIndex: 10 }} />
                                <EventIcon fontSize={'large'} onClick={() => { this.setState({ open: !this.state.open }) }} style={{ zIndex: 10 }} />

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                        disableToolbar
                                        variant="inline"
                                        autoOk
                                        label="Only calendar"
                                        helperText="No year selection"
                                        value={this.state.currentDate && this.state.currentDate}
                                        onChange={this.currentDateChange}
                                        open={this.state.open}
                                        className={classes.textField}
                                    />
                                </MuiPickersUtilsProvider>

                            </div>
                            <span>Choose amongst the available dates & times</span>
                        </div>
                        <p className={classes.dateClass} variant="contained">{this.state.currentDate > 0 && moment.tz(this.state.currentDate,"Canada/Eastern").format('dddd - MMMM D, YYYY')}</p>
                        <Grid item xs={12} className={classes.slotsList}>
                            {this.state.loader ?
                                <CircularProgress style={{ border: 0 }} size={24} />
                                :
                                this.state.data.length > 0 ?

                                    this.state.data.map((item, key) => {
                                        console.log("item",item)
                                        let slotText = item.slots[0].text
                                        item = parseInt(item.slots[0].time)
                                        let returnedItem = ''
                                        this.state.type === "" ?
                                            returnedItem = <span onClick={() => { this.nextStep(item) }} className={classes.slotsListItem} key={key}>{slotText}</span>
                                            :
                                            this.state.type === 'update' ?
                                                returnedItem = <span onClick={() => { this.props.updateAppointment(item) }} className={classes.slotsListItem} key={key}>{slotText}</span>
                                                :
                                                returnedItem = <span onClick={() => { this.props.updateRhynoGoTime(item) }} className={classes.slotsListItem} key={key}>{slotText}</span>

                                        return returnedItem
                                    })
                                    // this.state.type === "" ?
                                    //     this.state.data.map((item, key) => {
                                    //         item = item.slots[0]
                                    //         return <span onClick={() => { this.nextStep(item) }} className={classes.slotsListItem} key={key}>{moment(new Date(item * 1000)).format("hh:mm A")} TO {moment(new Date(item * 1000)).add(2, 'hours').format("hh:mm A")}</span>
                                    //     })
                                    //     :
                                    //     this.state.type === 'update'
                                    //         ?
                                    //         this.state.data.map((item, key) => {
                                    //             item = item.slots[0]
                                    //             return <span onClick={() => { this.props.updateAppointment(item) }} className={classes.slotsListItem} key={key}>{moment(new Date(item * 1000)).format("hh:mm A")} TO {moment(new Date(item * 1000)).add(2, 'hours').format("hh:mm A")}</span>
                                    //         })
                                    //         :
                                    //         this.state.data.map((item, key) => {
                                    //             item = item.slots[0]
                                    //             return <span onClick={() => { this.props.updateRhynoGoTime(item) }} className={classes.slotsListItem} key={key}>{moment(new Date(item * 1000)).format("hh:mm A")} TO {moment(new Date(item * 1000)).add(2, 'hours').format("hh:mm A")}</span>
                                    //         })
                                    :
                                    "No Slot Are Found"
                            }
                        </Grid>
                    </>
                }
            </Grid>
        );
    }
}
export default withStyles(styles)(RhynoGoCalendar)