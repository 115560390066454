import React, { Component } from 'react'
import withStyles from "@material-ui/core/styles/withStyles"
import { Grid, Button, TextField } from '@material-ui/core'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'

const styles = theme => ({
    popup: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column'
    },
    popupDiv: {
        backgroundColor: '#fff',
        padding: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '35rem',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            width: '100%'
        }
    },
    popupButton: {
        marginBottom: theme.spacing(2),
        fontFamily: 'inherit',
        color: '#ffffff',
        width: '30%',
        margin: 'auto'
    },
    popupButton1: {
        marginBottom: theme.spacing(2),
        fontFamily: 'inherit',
        color: '#ffffff',
    },
    popupHeading: {
        fontFamily: 'inherit',
        fontWeight: 700,
        paddingBottom: theme.spacing(6),
        color: '#795bb2',
        fontSize: theme.spacing(2.4)
    },
    font: {
        fontFamily: 'revert',
    },
    mainDiv: {
        display: 'flex',
        flexDirection: 'column',
        width: '75%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    formButton: {
        display: 'flex',
        justifyContent: 'space-between'
    }
})
class healthCard extends Component {

    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = {
            isFormValid: true,
            card_type: props.registerData.hasOwnProperty('hCard') && props.registerData.hCard !== '' ? 'yes' : '',
            hCard: props.registerData.hasOwnProperty('hCard') ? props.registerData.hCard : '',
            HCExpDate: props.registerData.hasOwnProperty('dateofHCExp') ? props.registerData.dateofHCExp : null
        }
    }

    componentDidMount() {
        this._isMounted = true
    }

    updateCardType = (type) => {
        this.setState({ card_type: type })
    }

    updateFormData = (step) => {
        this.props.updateStepNo(step, { hCard: this.state.hCard, dateofHCExp: this.state.HCExpDate })
    }
    // FORM DATA UPDATED
    change = (e, text) => {
        if (this._isMounted) {
            if (text === 'hCard') {
                let value = (e.target.value).trim()
                value = value.replace(/[^a-zA-Z0-9]/g, '')
                value = value.toUpperCase();
                this.setState({ hCard: value })
            }
            else {
                this.setState({ HCExpDate: moment(e).format('YYYY/MM/DD') })
            }
        }
    }
    validateForm = () => {
        if (this.state.HCExpDate !== null && this.state.hCard !== '' && this.state.HCExpDate !== 'Invalid date')
            this.updateFormData(7)
        else
            this.setState({ isFormValid: false })
    }
    render() {
        let { classes } = this.props

        return (
            <Grid className={classes.font}>
                <Grid className={classes.popup}>
                    <Grid className={classes.popupDiv} >
                        {this.state.card_type === '' ?
                            <Grid className={classes.mainDiv}>
                                <span className={classes.popupHeading}>
                                    {this.props.registerData.accountType === 'own' ?
                                        "Do you have a provincial health card?"
                                        :
                                        "Does the patient have a provincial health card?"
                                    }
                                </span>
                                <Button variant="contained" onClick={() => this.updateCardType('yes')} color={'secondary'} className={classes.popupButton}>
                                    Yes
                                </Button>
                                <Button variant="contained" onClick={() => this.updateFormData(7)} color={'secondary'} className={classes.popupButton}>
                                    No
                                </Button>
                            </Grid>
                            :
                            this.state.card_type === 'yes' ?
                                <Grid className={classes.mainDiv}>
                                    <span className={classes.popupHeading}>{this.props.registerData.accountType === 'own' ?
                                        "Please enter your health card infomation(optional)"
                                        :
                                        "Please enter patient's health card infomation(optional)"
                                    }</span>

                                    <TextField
                                        value={this.state.hCard}
                                        onChange={(e) => this.change(e, 'hCard')}
                                        onBlur={(e) => this.change(e, 'hCard')}
                                        label="Health Card"
                                        style={{ marginBottom: '24px' }}
                                        autoFocus
                                        error={this.state.hCard === '' && !this.state.isFormValid ? true : false}
                                        helperText={this.state.hCard === '' && !this.state.isFormValid ? "Health Card is required" : ""}
                                    />

                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            variant="inline"
                                            id="HCExpDate"
                                            name="HCExpDate"
                                            label="Date of Expiry"
                                            value={this.state.HCExpDate}
                                            // placeholder={"mm/dd/yyyy"}
                                            // format="MM/dd/yyyy"
                                            placeholder={"dd/mm/yyyy"}
                                            format="dd/MM/yyyy"
                                            style={{ marginBottom: '24px' }}
                                            onChange={(e) => this.change(e, 'HCExpDate')}
                                            // onBlur={(e) => this.change(e,'HCExpDate')}
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={this.state.HCExpDate === null && !this.state.isFormValid ? true : this.state.HCExpDate === 'Invalid date' && !this.state.isFormValid ? true : false}
                                            helperText={this.state.HCExpDate === null && !this.state.isFormValid ? "HC Expire Date is required" : this.state.HCExpDate === 'Invalid date' && !this.state.isFormValid ? 'HC Expire Date format is incorrect' : ""}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <Grid className={classes.formButton}>
                                        <Button variant="contained" onClick={() => this.updateFormData(5)} color={'primary'} className={classes.popupButton1}>
                                            Back
                                    </Button>
                                        <Button variant="contained" onClick={() => this.validateForm()} color={'secondary'} className={classes.popupButton1}>
                                            Next
                                    </Button>
                                    </Grid>
                                </Grid>
                                :
                                ""
                        }
                    </Grid>

                </Grid>
            </Grid>
        )
    }
}
export default withStyles(styles)(healthCard);
