import firebase from './firebase.js'
import { APIURL } from '../../config.js'
import { signOut } from '../../store/actions/authActions'
import store from '../../createStore'

const functions = {
    async saveImage(picData, type, noteType = '') {
        var types = {
            'account_check': 'Account Detail',
            'profile_photo': 'Profile Image',
            'requisitions': 'Requisitions Shared',
            'Appointment': 'Appointment',
        }
        let fileName = picData.target.files[0].name
        if (noteType === 'Covid19TravelResult' || noteType === 'Covid19TravelPCRRequisition') {
            if (noteType === 'Covid19TravelResult') {
                fileName = "COVID-19 PCR Result.pdf"
            }
            fileName = fileName.split('.')
            fileName[fileName.length - 1] = new Date().getTime() + '.' + fileName[fileName.length - 1]
            fileName = fileName.join('')
            fileName = encodeURI(fileName)
        }
        console.log("fileName", encodeURI(fileName))
        var file = picData.target.files[0]
        let mainImage = firebase.storage.child(types[type] + '/' + fileName)
        var result = await mainImage.put(file)
        var name = result.metadata.name
        var url = await mainImage.getDownloadURL()

        if (noteType === 'Covid19TravelResult') {

            try {
                console.log('error.message')
                let privateMainImage = firebase.privateBucket.child('Covid19 Travel Result/' + fileName)
                let result1 = await privateMainImage.put(file)
                console.log("result", result1)
                console.log(privateMainImage)
            } catch (error) {
                console.log(error.message)
            }

        }

        return JSON.stringify({ name: name, path: url })
    },
    async saveAttachmentToPatientDocuments(userId, appointmentId, filesUrl) {
        try {
            let fileUpdatedList = []
            for (let i = 0; i < filesUrl.length; i++) {
                const mainImage = firebase.storage.child('Appointment/' + filesUrl[i].name)

                const response = await fetch(filesUrl[i].url)
                const data = await response.blob()
                const metadata = {
                    type: filesUrl[i].contentType
                }

                const meta = new File([data], filesUrl[i].url, metadata);

                var result = await mainImage.put(meta)
                var name = result.metadata.name
                var url = await mainImage.getDownloadURL()
                fileUpdatedList.push({ name: name, path: url })
            }
            if (fileUpdatedList.length > 0) {
                let jsonmap = JSON.stringify({ userId, appointmentId, attachments: fileUpdatedList });
                let firebaseToken = await this.getIdToken()
                await fetch(APIURL + '/saveChatAttachments', {
                    method: 'POST',
                    async: false,
                    body: jsonmap,
                    headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
                })
            }
            return 1
        }
        catch (error) {
            console.log(error)
            return 0
        }
    },
    getWeekName(week, year) {
        var month = [];
        month[0] = "Jan";
        month[1] = "Feb";
        month[2] = "Mar";
        month[3] = "Apr";
        month[4] = "May";
        month[5] = "Jun";
        month[6] = "Jul";
        month[7] = "Aug";
        month[8] = "Sep";
        month[9] = "Oct";
        month[10] = "Nov";
        month[11] = "Dec";
        var simple = new Date(year, 0, 1 + (week - 1) * 7);
        var ISOweekStart = simple;
        ISOweekStart.setDate(simple.getDate() - simple.getDay());
        let date = month[ISOweekStart.getMonth()] + ' ' + ISOweekStart.getDate() + ' to '
        let d = new Date(ISOweekStart);
        d.setDate(ISOweekStart.getDate() + 6)
        date += month[d.getMonth()] + ' ' + d.getDate()
        return date
    },
    async createOrder(data) {
        let jsonmap = JSON.stringify(data);
        var message = '';
        let firebaseToken = await this.getIdToken()
        await fetch(APIURL + '/addAppointment', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
            .then(response => response.json())
            .then(data => {
                message = [data.message, data.status, data.appointmentId]
            })
            .catch(error => {
                message = [error.message, data.status]
            });
        return message
    },
    async searchPatients(data) {
        let jsonmap = JSON.stringify(data);
        var message = '';
        let firebaseToken = await this.getIdToken()
        await fetch(APIURL + '/searchUsers', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
            .then(response => response.json())
            .then(data => {
                message = data
            })
            .catch(error => {
                message = error
            });
        return message
    },
    async searchPatientsFromAlgolia(data) {
        let jsonmap = JSON.stringify(data);
        var message = '';
        let firebaseToken = await this.getIdToken()
        await fetch(APIURL + '/searchPatientsFromAlgolia', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
            .then(response => response.json())
            .then(data => {
                message = data
            })
            .catch(error => {
                message = error
            });
        return message
    },
    async saveChatMessages(data) {
        let jsonmap = data;
        var message = '';
        let firebaseToken = await this.getIdToken()
        await fetch(APIURL + '/saveChatMessages', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
        return message
    },
    async saveDoctorRating(data) {
        let jsonmap = data;
        var message = '';
        let firebaseToken = await this.getIdToken()
        await fetch(APIURL + '/saveDoctorRating', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
        return message
    },
    async updateAppointmentTemporaryStatus(data) {
        let jsonmap = data;
        let firebaseToken = await this.getIdToken()
        await fetch(APIURL + '/updateAppointmentTemporaryStatus', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
    },
    async saveDoctorBilling(data) {
        let jsonmap = data;
        var message = '';
        let firebaseToken = await this.getIdToken()
        await fetch(APIURL + '/saveDoctorBilling', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
        return message
    },
    async updateDoctorBilling(data) {
        let jsonmap = data;
        var message = '';
        let firebaseToken = await this.getIdToken()
        await fetch(APIURL + '/updateDoctorBilling', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
        return message
    },
    async CreateRequisition(data) {
        let jsonmap = data;
        var message = '';
        let firebaseToken = await this.getIdToken()
        await fetch(APIURL + '/CreateRequisition', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
            .then(response => response.json())
            .then(data => {
                message = data
            })
            .catch(error => {
                message = error
            });
        return message
    },
    async sendEmail(data) {
        let jsonmap = data;
        var message = '';
        let firebaseToken = await this.getIdToken()
        await fetch(APIURL + '/sendEmail', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
            .then(response => response.json())
            .then(data => {
                message = data
            })
            .catch(error => {
                message = error
            });
        return message
    },
    async getAppointmentFiles(data) {
        let jsonmap = data;
        var message = '';
        let firebaseToken = await this.getIdToken()
        await fetch(APIURL + '/getAppointmentFiles', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
            .then(response => response.json())
            .then(data => {
                message = data
            })
            .catch(error => {
                message = error
            });
        return message
    },
    async getNotificationsList(id) {
        if (id) {
            let jsonmap = JSON.stringify({ userId: id })
            var message = '';
            let firebaseToken = await this.getIdToken()
            await fetch(APIURL + '/getNotificationsList', {
                method: 'POST',
                async: false,
                body: jsonmap,
                headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
            })
                .then(response => response.json())
                .then(data => {
                    message = data
                })
                .catch(error => {
                    message = error
                });
            return message
        }

    },
    async updateNotificationsList(id) {
        let jsonmap = JSON.stringify({ userId: id })
        var message = '';
        let firebaseToken = await this.getIdToken()
        await fetch(APIURL + '/updateNotificationsList', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
            .then(response => response.json())
            .then(data => {
                message = data
            })
            .catch(error => {
                message = error
            });
        return message
    },
    async verify2F(data) {
        let jsonmap = data;
        var message = '';
        let firebaseToken = await this.getIdToken()
        await fetch(APIURL + '/VerifyTwoFactorCode', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
            .then(response => response.json())
            .then(data => {
                message = data
            })
            .catch(error => {
                message = error
            });
        return message
    },
    async isValidVoucherCode(data) {
        let jsonmap = data;
        var message = '';
        let firebaseToken = await this.getIdToken()
        await fetch(APIURL + '/isValidVoucherCode', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
            .then(response => response.json())
            .then(data => {
                message = data
            })
            .catch(error => {
                message = error
            });
        return message
    },
    async verifyAccount(data) {
        let jsonmap = data;
        var message = '';
        let firebaseToken = await this.getIdToken()
        await fetch(APIURL + '/verifyAccount', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
            .then(response => response.json())
            .then(data => {
                message = data
            })
            .catch(error => {
                message = error
            });
        return message
    },
    async chatViewedByProvider(data) {
        let jsonmap = data;
        let firebaseToken = await this.getIdToken()
        await fetch(APIURL + '/chatViewedByProvider', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
        return ''
    },
    async removeDoctorFromChat(data) {
        let jsonmap = data;
        let firebaseToken = await this.getIdToken()
        await fetch(APIURL + '/removeDoctorFromChat', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
        return ''
    },
    getIdToken() {
        return new Promise((resolve, reject) => {
            firebase.auth.onAuthStateChanged(function (user) {
                if (user) {
                    user.getIdToken()
                        .then(function (idToken) {
                            resolve(idToken)
                        }).catch(function (error) {
                            store.dispatch(signOut())
                            resolve('')
                        });
                } else {
                    store.dispatch(signOut())
                    resolve('')
                }
            });
        });
    },
    async searchMedicationList(val) {
        let jsonmap = JSON.stringify({ value: val });
        var message = '';
        let firebaseToken = await this.getIdToken()
        await fetch(APIURL + '/searchMedicationList', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
            .then(response => response.json())
            .then(data => {
                message = data
            })
            .catch(error => {
                message = error
            });
        return message
    },
    async getAllTimeOff(isIvBU) {
        var message = '';
        let firebaseToken = await this.getIdToken()
        let jsonmap = JSON.stringify({ isIvBU });
        await fetch(APIURL + '/getAllTimeOff', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
            .then(response => response.json())
            .then(data => {
                message = data
            })
            .catch(error => {
                message = error
            });
        return message
    },
    async getZipCodesHours(val, isIvBU) {
        var message = '';
        let firebaseToken = await this.getIdToken()
        let jsonmap = JSON.stringify({ zipCode: val, isIvBU });
        await fetch(APIURL + '/getZipCodesHours', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
            .then(response => response.json())
            .then(data => {
                message = data
            })
            .catch(error => {
                message = error
            });
        return message
    },
    async searchPrescriptionList(val, type) {
        let jsonmap = JSON.stringify({ value: val, type: type });
        var message = '';
        let firebaseToken = await this.getIdToken()
        await fetch(APIURL + '/searchPrescriptionList', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
            .then(response => response.json())
            .then(data => {
                message = data
            })
            .catch(error => {
                message = error
            });
        return message
    },
    async addPharmacyRequisitions(data) {
        let jsonmap = JSON.stringify(data);
        var message = '';
        let firebaseToken = await this.getIdToken()
        await fetch(APIURL + '/addPharmacyRequisitions', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
            .then(response => response.json())
            .then(data => {
                message = data
            })
            .catch(error => {
                message = error
            });
        return message
    },
    async getPharmacyRequisitions(data) {
        let jsonmap = JSON.stringify(data);
        var message = '';
        let firebaseToken = await this.getIdToken()
        await fetch(APIURL + '/getPharmacyRequisitions', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
            .then(response => response.json())
            .then(data => {
                message = data
            })
            .catch(error => {
                message = error
            });
        return message
    },
    async providerUpdateAppointmentStatus(data) {
        let jsonmap = JSON.stringify(data);
        var message = '';
        let firebaseToken = await this.getIdToken()
        await fetch(APIURL + '/providerUpdateAppointmentStatus', {
            method: 'POST',
            async: false,
            body: jsonmap,
            headers: { 'content-type': 'application/json', 'authorization': 'Bearer ' + firebaseToken }
        })
            .then(response => response.json())
            .then(data => {
                message = data
            })
            .catch(error => {
                message = error
            });
        return message
    }
}
export default functions