import { createSlice } from '@reduxjs/toolkit';

export const RynoGoUsersReducer = createSlice({
    name: 'rhynoGoUsers',
    initialState: {
        users: []
    },
    reducers: {
        listUsers: (state, action) => {
            state.users = action.payload
        },
    },
});
