import React, { useState } from 'react'
import { Grid, Button, TextField, CircularProgress, InputAdornment } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import useStyles from '../styles/style'
import { useParams } from 'react-router-dom'
import { setSubAccountPassword } from '../API/API'

function SetPasswordForm() {
    const classes = useStyles();
    const params = useParams()

    const [isFormValid, setIsFormValid] = useState(true)
    const [password, setPassword] = useState('')
    const [confirm_password, setConfirm_password] = useState('')
    const [isValidPassword, setIsValidPassword] = useState(true)
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const validateForm = async () => {
        let isValidPassword = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})((?=.*[A-Z]){1}).*$/.test(password)
        setIsValidPassword(isValidPassword)
        if (password !== '' && confirm_password === password && isValidPassword) {
            setIsFormValid(true)
            setLoading(true)
            let registerData = { password: password, uid: params.id }
            console.log(registerData)
            let res = await setSubAccountPassword(registerData)
            console.log(res)
            setLoading(false)

        }
        else {
            setIsFormValid(false)
        }
    }
    return (
        <Grid className={classes.font}>
            <Grid className={classes.popup}>
                <Grid className={classes.popupDiv} >
                    <Grid className={classes.mainDiv}>
                        <span className={classes.popupHeading}>Please choose a password</span>
                        <TextField
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onBlur={(e) => setPassword(e.target.value)}
                            label="Password *"
                            type={showPassword ? "text" : "Password"}
                            style={{ marginBottom: '24px' }}
                            error={(password === '' || !isValidPassword) && !isFormValid ? true : false}
                            helperText={!isFormValid ? password === '' ? "Password is required" : !isValidPassword ? "Password must contain at least 8 characters, one uppercase, and one special case character" : "" : ""}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment className={classes.cursorPointer} position="end" onClick={() => { setShowPassword(!showPassword) }}>
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </InputAdornment>
                            }}
                        />

                        <TextField
                            value={confirm_password}
                            onChange={(e) => setConfirm_password(e.target.value)}
                            onBlur={(e) => setConfirm_password(e.target.value)}
                            label="Confirm Password *"
                            type={showConfirmPassword ? "text" : "Password"}
                            style={{ marginBottom: '24px' }}
                            error={(confirm_password === '' || password !== confirm_password) && !isFormValid ? true : false}
                            helperText={!isFormValid ? confirm_password === '' ? "Confirm Password is required" : password !== confirm_password ? "Confirm Password not matched with Password" : "" : ""}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment className={classes.cursorPointer} position="end" onClick={() => { setShowConfirmPassword(!showConfirmPassword) }}>
                                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                    </InputAdornment>
                            }}
                        />

                        <Grid className={classes.formButton}>
                            <Button variant="contained" onClick={validateForm} color={'secondary'} className={classes.popupButton}>
                                {loading && <CircularProgress size={21} />}
                                {!loading && 'Set Password'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    )
}

export default SetPasswordForm
