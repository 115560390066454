import React, { Component } from 'react'
import { Grid, Button, FormControl, InputAdornment, InputLabel, OutlinedInput, TextField, CircularProgress } from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles"
import functionsList from '../../assets/js/function.js'
import StripeCheckOut from './stripe'
import { Modal } from 'antd'
import cookie from 'react-cookies'
import { connect } from 'react-redux'
import { MEMBERCOST } from '../../config'
import { getUpdatedUserInfo } from '../../store/actions/authActions'

const styles = theme => ({
    font: {
        fontFamily: 'Roboto Regular',
    },
    popup: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    popupDiv: {
        backgroundColor: '#fff',
        padding: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: '2rem',
        [theme.breakpoints.down('sm')]: { padding: theme.spacing(2) }
    },
    popupHeading: {
        fontFamily: 'inherit',
        fontWeight: 700,
        color: '#383636',
        fontSize: '1.2rem'
    },
    popupButton: {
        marginTop: theme.spacing(2),
        fontFamily: 'inherit',
        fontSize: '1rem',
    },
    textTransform: {
        textTransform: 'unset'
    },
    files: {
        margin: theme.spacing(8, 0),
        width: '100%',
    },
    uploadClass: {
        display: 'none'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    uploadFileDiv: {
        marginBottom: theme.spacing(2)
    },
    scheduleButton: {
        width: "120px",
        borderRadius: '10px',
        border: 'none',
        color: 'white',
        height: '50px',
        padding: "7px",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: "white"
        },
    },
    backButton: {
        backgroundColor: 'rgb(240, 242, 245)',
        width: "120px",
        color: theme.palette.lightGrey.main,
        padding: "7px",
        borderRadius: '10px',
        border: 'none',
        height: '50px',
        "&:hover": {
            backgroundColor: "rgb(240, 242, 245)",
        },
    },
    uploadDoc: {
        whiteSpace: 'nowrap',
        width: 'calc(100% - 160px)',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    buttonUploadDocuments: {
        width: "120px",
        borderRadius: '10px',
        border: 'none',
        color: 'white',
        height: '50px',
        padding: "7px",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: "#1f4037"
        },
    },
    uppecaseFirstLetter: {
        textTransform: 'capitalize'
    }
})

let AppointmentNumber = ''
class AddDocuments extends Component {
    constructor(props) {
        super(props)
        let rhynoGoCost = props.appointmentData.rhynoGoCost
        let propsDate = ''
        if (!props.appointmentData.hasOwnProperty('slot') || props.appointmentData.slot === undefined || props.appointmentData.slot === null) {
            propsDate = Math.ceil(new Date().getTime() / 1000)
        }
        else {
            propsDate = props.appointmentData.slot
        }
        let outTime = 1

        let slotDate = new Date(propsDate * 1000)
        if (slotDate.getHours() > 7 && slotDate.getHours() < 19)
            outTime = 0

        if (props.appointmentData.rhynoGoCost === -1) {
            if (this.props.appointmentData.doctorId === "") {
                if (props.account.isRhynoGoLimitReached && outTime) {
                    rhynoGoCost = MEMBERCOST
                }
                else {
                    rhynoGoCost = 0
                }
            }
            else if (props.appointmentData.hasOwnProperty('doctorSpecialty') && props.appointmentData.doctorSpecialty === 'Nurse Practitioner') {
                if (props.account.isNPLimitReached && outTime) {
                    rhynoGoCost = MEMBERCOST
                }
                else {
                    rhynoGoCost = 0
                }
            }
            // SKYPE COMVERSATION 13 APR
            // Please change the at home wellness visit cost to 49.99 + HST
            // Also, there is a bug if the provider is changed to family medicine; at home well ness visit charge is -1$ hold amount, please fix. For a subscriber, it should be 0 based on the number of visits but otherwise it should be 49.99 + HST.
            // IF ITS OUT TIME AND RHYNOGOLIMITREACHED is true THEN IT WILL BE MEMBES COST DEDUCTED OTHER WISE IT WILL BE 0 AMOUNT
            else if (props.appointmentData.hasOwnProperty('doctorSpecialty') && props.appointmentData.doctorSpecialty === 'Family Medicine') {
                if (props.account.isRhynoGoLimitReached && outTime) {
                    rhynoGoCost = MEMBERCOST
                }
                else {
                    rhynoGoCost = 0
                }
            }
        }
        this._isMounted = false;
        let isCovid19True = this.props.appointmentData.hasOwnProperty('isCovid19') ? this.props.appointmentData.isCovid19 : false
        this.state = {
            UploadFiles: 1,
            bookUploadFiles: {},
            appointmentData: {},
            loader: false,
            redirectToPage: false,
            redirectTo: '',
            modalValue: 0,
            message: '',
            rhynoGoCost: rhynoGoCost,
            slotTime: propsDate,
            outTime: outTime,
            showOutHoursModal: false,
            isCovid19: isCovid19True,
            showVoucherCodeModel: false,
            askForVoucherCode: false,
            isValidVoucherCode: true,
            voucherCodeValue: '',
            voucherAmount: 0,
            MembersNameList: '',
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true

        // SKYPE CONVERSATION 6 MAY 2022
        // 1) Please change the Terms to this file and re-enable the Terms checkbox. On checking the terms checkbox, consent should be logged in firestore as the terms has consent is defined in the terms. Also, please log consent for additional patient bookings using that account as well. Example, if patient 1 books for patient 1 and patient 2, please add consent log for patient 2 when booking is made.
        // 2) Please ask for voucher code from just PCR to all 6 rhynogo services.
        // - Please let me know as soon as these 2 are done.
        // ADD CHECK THAT APPOINTMENT IS RHYNOGO or LEADS TO RHYNOGO
        if (this.state.isCovid19 || (this.props.appointmentData.hasOwnProperty('isRhynoGoAppointment') && this.props.appointmentData.isRhynoGoAppointment)) {
            // let validReasonsForVoucherCode = ['PCR']
            // if (validReasonsForVoucherCode.includes(this.props.appointmentData.reason) && !(this.props.appointmentData.hasOwnProperty('isAgency') && this.props.appointmentData.isAgency))
            if ((this.props.appointmentData.hasOwnProperty('isRhynoGoAppointment') && this.props.appointmentData.isRhynoGoAppointment) && !(this.props.appointmentData.hasOwnProperty('isAgency') && this.props.appointmentData.isAgency))
                this.setState({ showVoucherCodeModel: true })
            else {
                this.bookAppointment();
                // this.setState({ showConsentModel: true });
            }

            // IF APPOINTMET DATA HAS MEMBERDATA KEY THEN FILL DATA
            if (this.props.appointmentData.hasOwnProperty('membersData')) {
                let appointmentMembers = [];
                let appointmentMembersList = this.props.appointmentData.membersData;
                for (let [, obj] of Object.entries(appointmentMembersList)) {
                    appointmentMembers.push(obj.first_name + " " + obj.last_name);
                }
                this.setState({ MembersNameList: appointmentMembers.join(" , ") });
            }
        }
    }
    askForVoucherCode = () => {
        this.setState({ askForVoucherCode: !this.state.askForVoucherCode })
    }
    nextStep = (step) => {
        this.props.updateStepNo(step, { previousStep: 8 })
    }
    addAppointment = async (appointment) => {
        let result = await functionsList.createOrder(appointment)
        if (result[1] === 1) {

            // UPDATE PROFILE SO THAT SUBSCRIPTION STATS WILL GETS UPDATE
            let profileResult = await this.props.getUpdatedUserInfo({ userId: appointment.userId })
            this.props.updateProfileData(profileResult)

            let page = '/dashboard'
            if (appointment.appointmentType === 'onDemand') {
                cookie.save('doctorName', appointment.doctorName)
                page = '/chat/' + appointment.doctorId + "/" + appointment.userId + "/" + result[2]
            }
            if (this.state.isCovid19)
                page = '/covid19form/' + this.props.appointmentData.slot

            if (this.props.appointmentData.hasOwnProperty('isAgency') && this.props.appointmentData.isAgency)
                page = '/all_appointments'
            this.setState({ loader: false, modalValue: 1, message: result[0], redirectTo: page })
        }
        else {
            this.setState({ loader: false, modalValue: 2, message: result[0] })
        }
    }

    showOutHoursModal = () => {
        if ((this.props.appointmentData.appointmentType === 'rhynogo') || (this.props.appointmentData.hasOwnProperty('isRhynoGoAppointment') && this.props.appointmentData.isRhynoGoAppointment)) {
            // console.log('Its RhynoGo Appointment')
            if (this.props.appointmentData.doctorId === "" && this.props.appointmentData.rhynoGoCost === -1) {
                if (this.props.account.isRhynoGoLimitReached && this.state.outTime) {
                    this.setState({ showOutHoursModal: !this.state.showOutHoursModal })
                }
                else {
                    this.bookAppointment()
                }
            }
            else if (this.props.appointmentData.hasOwnProperty('doctorSpecialty') && this.props.appointmentData.doctorSpecialty === 'Nurse Practitioner' && this.props.appointmentData.rhynoGoCost === -1) {
                if (this.props.account.isNPLimitReached && this.state.outTime) {
                    this.setState({ showOutHoursModal: !this.state.showOutHoursModal })
                }
                else {
                    this.bookAppointment()
                }
            }
            else {
                this.bookAppointment()
            }
        }
        else {
            this.bookAppointment()
        }
    }
    showConsent = () => {
        this.bookAppointment();
        this.setState({ showVoucherCodeModel: false });
        // this.setState({ showConsentModel: true });
    };
    // hideConsentModel = () => {
    //     this.setState({ showConsentModel: false });
    //     this.bookAppointment();
    // };
    bookAppointment = async () => {
        this.setState({ loader: true, showOutHoursModal: false, showVoucherCodeModel: false })
        // let isValidHC = this.props.appointmentData.isValidHC
        let appointmentObject = {
            appointmentType: this.props.appointmentData.appointmentType,
            doctorId: this.props.appointmentData.doctorId,
            doctorName: this.props.appointmentData.doctorName,
            userId: this.props.appointmentData.userId,
            userName: this.props.appointmentData.userName,
            bookreason: this.props.appointmentData.reason,
            bookdateofHCExp: this.props.appointmentData.HCExpiryDate,
            bookhCard: this.props.appointmentData.healthCard,
            appointmentFor: this.props.appointmentData.slot,
            bookCardType: this.props.appointmentData.patientState,
            bookdoctorNote: this.props.appointmentData.noteOption,
            bookforms: this.props.appointmentData.noOfPages,
            holdamount: this.props.appointmentData.holdamount,
            appointmentDuration: this.props.appointmentData.appointmentduration,
            isCovid19: this.props.appointmentData.hasOwnProperty('isCovid19') ? this.props.appointmentData.isCovid19 : false,
            files: Object.values(this.state.bookUploadFiles),
            addedBy: this.props.appointmentData.hasOwnProperty('isAgency') && this.props.appointmentData.isAgency ? 'agency' : 'patient',
            active_subscription_id: this.props.account.active_subscription_id,
            isRhynoGoAppointment: this.props.appointmentData.hasOwnProperty('isRhynoGoAppointment') ? this.props.appointmentData.isRhynoGoAppointment : false,
            rhynoGoDocuments: this.props.appointmentData.hasOwnProperty('rhynoGoDocuments') ? this.props.appointmentData.rhynoGoDocuments : [],
            reasonType: this.props.appointmentData.hasOwnProperty('covidType') ? this.props.appointmentData.covidType : '',
            doctorSpecialty: this.props.appointmentData.doctorSpecialty,
            outTime: this.state.outTime,
            rhynoGoAddress: this.props.appointmentData.hasOwnProperty('rhynoGoAddress') ? this.props.appointmentData.rhynoGoAddress : '',
            userSelectedZipCode: this.props.appointmentData.hasOwnProperty('userSelectedZipCode') ? this.props.appointmentData.userSelectedZipCode : '',
            numberOfUsers: this.props.appointmentData.hasOwnProperty('numberOfUsers') ? this.props.appointmentData.numberOfUsers : 1,
            membersData: this.props.appointmentData.hasOwnProperty('membersData') ? this.props.appointmentData.membersData : [],
            travelCountry: this.props.appointmentData.hasOwnProperty('travelCountry') ? this.props.appointmentData.travelCountry : '',
            swab: this.props.appointmentData.hasOwnProperty('swab') ? this.props.appointmentData.swab : '',
            isUrgentPCR: this.props.appointmentData.hasOwnProperty('isUrgentPCR') ? this.props.appointmentData.isUrgentPCR : false,
            voucherCode: this.state.voucherCodeValue,
            consent: true
        }
        // if (this.props.appointmentData.hasOwnProperty('doctorSpecialty') && this.props.appointmentData.doctorSpecialty === 'Nurse Practitioner') {
        //     isValidHC = false
        // }
        // if (!isValidHC) {
        //     appointmentObject.bookhCard = ''
        //     appointmentObject.bookdateofHCExp = ''
        // }
        if (!appointmentObject.hasOwnProperty('appointmentFor') || appointmentObject.appointmentFor === undefined) {
            // let newCreatedDate = new Date()
            // newCreatedDate.setHours(newCreatedDate.getTimezoneOffset()/(-60))
            // appointmentObject.appointmentFor = Math.ceil(new Date().getTime() / 1000)
            appointmentObject.appointmentFor = this.state.slotTime
        }
        let notesAmount = 0
        if (this.props.appointmentData.hasOwnProperty('noteOption') && (this.props.appointmentData.noteOption === 2 || this.props.appointmentData.noteOption === 3))
            notesAmount = 20
        let paidAmount = this.props.appointmentData.hasOwnProperty('noOfPages') ? parseInt(this.props.appointmentData.noOfPages * 10) : 0
        // console.log(paidAmount)
        paidAmount = paidAmount + notesAmount
        // console.log(paidAmount)
        appointmentObject.rhynoGoAmount = 0

        if (this.props.appointmentData.hasOwnProperty('isRhynoGoAppointment') && this.props.appointmentData.isRhynoGoAppointment) {
            let rhynoGoAmount = this.state.rhynoGoCost
            rhynoGoAmount = parseFloat(rhynoGoAmount)
            appointmentObject.rhynoGoAmount = rhynoGoAmount
            paidAmount = paidAmount + rhynoGoAmount
            // if (this.props.appointmentData.hasOwnProperty('doctorSpecialty') && this.props.appointmentData.doctorSpecialty === 'Nurse Practitioner') {
            //     appointmentObject.holdamount = 0
            // }
        }

        appointmentObject.skipBilling = false
        if (parseInt(appointmentObject.holdamount) === 0 && this.props.appointmentData.hasOwnProperty('doctorSpecialty') && this.props.appointmentData.doctorSpecialty === 'Nurse Practitioner') {
            appointmentObject.skipBilling = true
        }

        if ((this.props.appointmentData.hasOwnProperty('doctorSpecialty') && this.props.appointmentData.doctorSpecialty === 'Nurse Practitioner') || appointmentObject.bookhCard !== '') {
            appointmentObject.holdamount = 0
        }

        if (this.state.voucherAmount > 0)
            paidAmount = paidAmount - (this.state.voucherAmount * (this.props.appointmentData.numberOfUsers || 1))

        appointmentObject.paidamount = paidAmount
        await this.setState({ appointmentData: appointmentObject })

        // console.log('Appointment Object Data')
        // console.log(appointmentObject)
        // console.log(isValidHC)
        // console.log(paidAmount)
        // console.log(appointmentObject.paidamount)
        // console.log(appointmentObject.holdamount)
        // console.log(this.props.appointmentData.doctorSpecialty)
        // if (!(this.props.appointmentData.doctorSpecialty === 'Nurse Practitioner' && parseInt(appointmentObject.holdamount) === 0) || (paidAmount > 0 || !isValidHC)) {
        if (parseInt(appointmentObject.holdamount) > 0 || paidAmount > 0) {
            document.getElementById('stripeButton').childNodes[0].click();
        }
        else {
            appointmentObject.stripetoken = ''
            this.addAppointment(appointmentObject)
        }
    }

    onImageChangeHandler = async (e) => {
        let filesArray = this.state.bookUploadFiles
        this.setState({ imageLoader: true })
        var result = await functionsList.saveImage(e, 'Appointment')
        result = JSON.parse(result)
        filesArray[AppointmentNumber] = { url: result.path, name: result.name }
        if (this._isMounted)
            this.setState({ bookUploadFiles: filesArray, imageLoader: false })
        if (this.state.UploadFiles < 5) {
            if (this._isMounted)
                this.setState({ UploadFiles: this.state.UploadFiles + 1 })
        }
        AppointmentNumber = ''

    }
    handleFileSelect = (type, key) => {
        AppointmentNumber = type
        if (this._isMounted)
            this.setState({ imageLoaderkey: key })
        document.getElementById('file').click();
    }

    modalClick = () => {
        if (this.state.modalValue === 1) {

            this.setState({ redirectToPage: true })
        }
        else {
            this.setState({ modalValue: 0 })
        }
    }

    bookScheduleLater = () => {
        this.props.updateStepNo(1, { speciality: '', hideRhynoGoOption: true })
    }

    changeVoucherCode = (val) => {
        this.setState({ voucherCodeValue: val })
    }

    verifyVoucherCode = async () => {
        var result = await functionsList.isValidVoucherCode(JSON.stringify({ voucherCode: this.state.voucherCodeValue }))
        if (result.status) {
            this.setState({ voucherCodeValue: result.voucherCode, voucherAmount: result.voucherAmount, showVoucherCodeModel: false }, () => {
                // this.showConsent();
                this.bookAppointment();
            })
        }
        else {
            this.setState({ isValidVoucherCode: false })
        }
    }
    render() {
        let { classes } = this.props
        if (this.state.redirectToPage === true) {
            // return <Redirect to={this.state.redirectTo} />;
            window.location.href = this.state.redirectTo
        }
        return (
            <Grid className={classes.font}>
                <Grid className={classes.popup}>
                    <Grid className={classes.popupDiv} style={{ width: '31rem' }}>
                        {!this.state.isCovid19 ? <>
                            <span className={classes.popupHeading}>
                                If you have any documents to upload(forms, referrals, health card) please upload them here
                        </span>
                            <Grid className={classes.files}>
                                {
                                    this.state.UploadFiles > 0 ?
                                        Array.from(Array(parseInt(this.state.UploadFiles))).map((val, key) => {
                                            return (
                                                <FormControl
                                                    fullWidth
                                                    variant="outlined"
                                                    className={classes.uploadFileDiv}
                                                    key={key}
                                                >
                                                    <InputLabel htmlFor={"file_" + (key + 1)} className={classes.uploadDoc}>
                                                        {this.state.bookUploadFiles["document" + (key + 1)] ? this.state.bookUploadFiles["document" + (key + 1)]["name"] : 'Documents'}
                                                    </InputLabel>
                                                    <OutlinedInput
                                                        id={"file_" + (key + 1)}
                                                        type="text"
                                                        disabled={true}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <Button
                                                                    type="button"
                                                                    fullWidth
                                                                    className={classes.buttonUploadDocuments}
                                                                    onClick={() => this.handleFileSelect('document' + (key + 1), key)}
                                                                >
                                                                    {
                                                                        this.state.imageLoader && this.state.imageLoaderkey === key ?
                                                                            <CircularProgress size={21} /> :
                                                                            'Upload'
                                                                    }
                                                                </Button>
                                                            </InputAdornment>
                                                        }
                                                        labelWidth={70}
                                                    />
                                                </FormControl>

                                            )
                                        })
                                        : ""
                                }
                            </Grid>
                        </>
                            :
                            <span className={classes.files + ' ' + classes.popupHeading}>
                                Please wait while your appointment will be Scheduled.
                            </span>
                        }
                        <Grid className={classes.buttons}>
                            <Button variant="contained"
                                onClick={() => this.nextStep(this.props.appointmentData.previousStep)}
                                className={classes.backButton}>
                                Back
                        </Button>
                            <Button variant="contained" onClick={() => this.showOutHoursModal()}
                                color={'secondary'}
                                className={classes.scheduleButton}>
                                {this.state.loader ?
                                    <CircularProgress style={{ color: 'white' }} size={24} />
                                    :
                                    this.props.appointmentData.appointmentType === "onDemand" ? "Book Now" : "Schedule"
                                }
                            </Button>
                            <StripeCheckOut appointmentData={this.state.appointmentData} addAppointment={this.addAppointment} />
                        </Grid>
                    </Grid>
                </Grid>
                {this.state.modalValue === 1 || this.state.modalValue === 2 ?
                    <Modal
                        title={""}
                        visible={(this.state.modalValue === 2 || this.state.modalValue === 1) ? true : false}
                        width={400}
                        onCancel={this.onCancel}
                        footer={
                            <Grid>
                                <Button color="secondary" variant="contained" onClick={this.modalClick}>OK</Button>
                            </Grid>
                        }
                    >
                        <Grid className={classes.modal + ' ' + classes.uppecaseFirstLetter}>
                            <p>{this.state.message}</p>
                        </Grid>
                    </Modal>
                    : ''}
                <TextField
                    name="file"
                    type="file"
                    id="file"
                    className={classes.uploadClass}
                    onChange={this.onImageChangeHandler}
                />


                <Modal
                    title={"Additional Charges"}
                    visible={this.state.showOutHoursModal ? true : false}
                    width={600}
                    onCancel={this.onCancel}
                    footer={
                        <Grid>
                            <Button color="secondary" variant="contained" onClick={this.bookAppointment}>Proceed</Button>
                            <Button color="secondary" variant="contained" onClick={this.bookScheduleLater}>Schedule Later</Button>
                        </Grid>
                    }
                >
                    <Grid className={classes.modal}>
                        <p className={classes.popupHeading}>It is currently after hours and would entail an additional charge for this service. You may proceed now or schedule an appointment for a later time.</p>
                    </Grid>
                </Modal>

                <Modal
                    title={"Voucher Code"}
                    visible={this.state.showVoucherCodeModel ? true : false}
                    width={600}
                    onCancel={this.onCancel}
                    footer={
                        <Grid>
                            {this.state.askForVoucherCode ?
                                <>
                                    <Button color="secondary" variant="contained" onClick={this.askForVoucherCode}>Cancel</Button>
                                    <Button disabled={!Boolean(this.state.voucherCodeValue)} color="secondary" variant="contained" onClick={this.verifyVoucherCode}>Continue</Button>
                                </>
                                :
                                <>
                                    <Button color="secondary" variant="contained" onClick={this.askForVoucherCode}>Yes</Button>
                                    <Button color="secondary" variant="contained" onClick={this.showConsent}>No</Button>
                                </>
                            }
                        </Grid>
                    }
                >
                    <Grid className={classes.modal}>
                        {this.state.askForVoucherCode ?
                            <TextField
                                value={this.state.voucherCodeValue}
                                onChange={(e) => this.changeVoucherCode(e.target.value)}
                                label="Voucher Code"
                                style={{ marginBottom: '24px' }}
                                autoFocus
                                error={!this.state.isValidVoucherCode}
                                helperText={!this.state.isValidVoucherCode ? "Voucher Code is invalid" : ""}
                            />
                            :
                            <p className={classes.popupHeading}>Do you have a voucher code?</p>
                        }
                    </Grid>
                </Modal>
                <Modal
                    title={"CONSENT"}
                    visible={this.state.showConsentModel ? true : false}
                    width={600}
                    onCancel={this.onCancel}
                    footer={
                        <Grid>
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={() => this.hideConsentModel()}
                            >
                                CONSENT
							</Button>
                        </Grid>
                    }
                >
                    <p>
                        {this.state.MembersNameList} consent(s) to the testing samples being taken.
				</p>
                </Modal>
            </Grid>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        account: state.account.data
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getUpdatedUserInfo: (data) => getUpdatedUserInfo(data),
        updateProfileData: (data) => dispatch(data)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddDocuments))