import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles"
import { Button, CircularProgress, TextField, Container, Grid, MenuItem, Select, FormControl, InputLabel, Avatar, Typography } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import Functions from '../../assets/js/function'
import EmailIcon from '@material-ui/icons/Email'
import RefreshIcon from '@material-ui/icons/Refresh'




const styles = theme => ({
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: '#fff',
    borderRadius: theme.spacing(7),
    padding: `${theme.spacing(4)}px ${theme.spacing(10)}px ${theme.spacing(10)}px`,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2)
    }
  },
  actions: {
    [theme.breakpoints.down('sm')]: { flexDirection: 'column' }
  },
  container: {
    maxWidth: '800px',
    height: 'fit-content',
    paddingTop: '20px',
  },
  avatar: {
    width: '80px',
    margin: ' auto',
    position: 'relative',
    bottom: '80px',
    height: '80px',
    backgroundColor: theme.palette.primary.main,
  },
  emailIcon: {
    fontSize: '30px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderRadius: theme.spacing(1),
  },
  alignLeft: {
    textAlign: 'left',
    borderRadius: theme.spacing(1)
  },
  pages: {
    display: 'flex',
    marginTop: theme.spacing(1)
  },
  pageItem: {
    width: '80px !important',
    marginLeft: '0 !important'
  },
  uploadClass: {
    display: 'none'
  },
  rowDirection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  cursor: {
    cursor: 'pointer'
  },
  contactHeader: {
    position: 'relative',
    bottom: '50px',
    fontWeight: 'bold',
    fontSize: '30px',
  },
  contactSubheader: {
    position: 'relative',
    bottom: '20px',
    fontSize: '20px',
    color: theme.palette.lightGrey.main,
  },
  inputField: {
    borderRadius: theme.spacing(1),
    color: theme.palette.lightGrey.main,
  }

});

class BookNow extends Component {
  constructor(props) {
    super(props)
    if (this.props.account && this.props.account.role)
      this.state = {
        usersArray: [],
        userName: '',
        content: '',
        subject: '',
        loading: false,
        disabled: true,
        error: '',
        errorstatus: ''
      }
  }
  componentDidMount() {
    this.getUsersList()
  }
  getUsersList = async () => {
    let result = await Functions.searchPatients({ type: this.props.account.role, accountId: this.props.auth.uid })
    let usersArray = []
    for (let i = 0; i < result.data.length; i++)
      usersArray.push(<MenuItem key={i} value={result.data[i].id}>{result.data[i].name}</MenuItem>)
    usersArray.push(<MenuItem key={-1} value={'RhynoCare'}>{'RhynoCare'}</MenuItem>)
    if (usersArray.length === 0)
      usersArray.push(<MenuItem key={0}>No Record Found</MenuItem>)
    this.setState({ usersArray: usersArray })
  }
  sendEmail = async (e) => {
    e.preventDefault()
    this.setState({ loading: true })
    let result = await Functions.sendEmail(JSON.stringify({ content: this.state.content, subject: this.state.subject, receiverId: this.state.userName, senderId: this.props.auth.uid }))
    this.setState({ errorstatus: result.status, error: result.message })
    this.setState({ loading: false, userName: '', content: '', subject: '' })
  }
  componentDidUpdate(prevProps, prevState) {

  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, this.checkDisable)
  }
  checkDisable = () => {
    if (this.state.content !== '' && this.state.userName !== '' && this.state.subject !== '')
      this.setState({ disabled: false })
    else
      this.setState({ disabled: true })
  }
  render() {
    const { auth, classes, account } = this.props
    if (!auth.uid) {
      return <Redirect to="/signin" />;
    }
    if (!auth.isVerified) {
      return <Redirect to="/verify" />;
    }
    return (
      <Container className={classes.container} component="main" maxWidth="sm">
        {this.state ?
          <div className={classes.paper + ' shadow'}>
            <Avatar className={classes.avatar}>
              <EmailIcon className={classes.emailIcon} />
            </Avatar>
            <Typography className={classes.contactHeader} component="h1" variant="h5">
              {account.role === 'Doctor' ? 'Contact User' : 'Contact Provider'}
            </Typography>
            <Typography className={classes.contactSubheader} component="h2">
              What Would you like to do?
              </Typography>
            <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
              {this.state.error !== '' ? <h4 className="red-text-center">{this.state.error}</h4> : ""}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <FormControl variant="outlined" className={classes.rowDirection} fullWidth>
                    <InputLabel id="user">{account.role === 'Doctor' ? 'Select User' : 'Select Provider'}</InputLabel>
                    <Select
                      labelId="user"
                      label={account.role === 'Doctor' ? 'Select User' : 'Select Provider'}
                      name="userName"
                      variant="outlined"
                      required
                      fullWidth
                      className={classes.alignLeft}
                      value={this.state.userName}
                      onChange={this.onChange}
                    >
                      {this.state.usersArray}
                    </Select>
                    <RefreshIcon className={classes.cursor} onClick={this.getUsersList} />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="subject"
                    multiline
                    rowsMax={40}
                    variant="outlined"
                    fullWidth
                    className={classes.inputField}
                    value={this.state.subject}
                    onChange={this.onChange}
                    required
                    label="Mail Subject"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    name="content"
                    multiline
                    rowsMax={40}
                    rows={10}
                    variant="outlined"
                    fullWidth
                    className={classes.inputField}
                    value={this.state.content}
                    onChange={this.onChange}
                    required
                    label="Mail Content"
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                disabled={this.state.disabled}
                className={classes.submit}
                onClick={this.sendEmail}
              >
                {this.state.loading && <CircularProgress size={21} />}
                {!this.state.loading && 'Send'}
              </Button>
            </form>
          </div>
          : <CircularProgress size={24} />}
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    account: state.account.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BookNow));