import React, { useState, useEffect } from 'react'
import ShowAllPatients from './ShowAllPatients'
import EMRComponents from './EMRComponents'
import SearchAllPatients from './SearchAllPatients'
import { Grid } from '@material-ui/core'
import { firstPage } from './style'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useSelector } from 'react-redux';

function RhynoGoEMR(props) {
    const [patientId, setPatientId] = useState('')
    const [patientName, setPatientName] = useState('')
    const [isRhynoGoPatient, setIsRhynoGoPatient] = useState(false)

    const { authState } = useSelector(
        (state) => ({ authState: state.auth })
      );
        
      useEffect(() => {
        if (authState) {
          if (!authState.uid) {
            window.location.href = "/signin"
          }
          if (!authState.isVerified) {
            window.location.href = "/verify"
          }
        }
      }, [authState]);

    return (
        <Grid style={firstPage}>
            <SearchAllPatients setPatientId={setPatientId} setPatientName={setPatientName} setIsRhynoGoPatient={setIsRhynoGoPatient} patientId={props.match.params.id} />
            {patientId ?
                <DndProvider backend={HTML5Backend}>
                    <EMRComponents patientId={patientId} patientName={patientName} isRhynoGoPatient={isRhynoGoPatient} />
                </DndProvider>
                :
                <ShowAllPatients setPatientId={setPatientId} setPatientName={setPatientName} setIsRhynoGoPatient={setIsRhynoGoPatient} />
            }
        </Grid>
    )
}

export default RhynoGoEMR
