import React, { useState } from 'react'
import PersonalInfo from '../patientSignup/personalInfo'
import GetPatientName from '../patientSignup/getPatientName'
import ContactInfo from '../patientSignup/contactInfo'
import HealthCard from '../patientSignup/healthCard'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { addSubAccount } from './API/API'
import { Modal, Alert } from 'antd'
import { Button } from '@material-ui/core';

function AddPatients() {
    const auth = useSelector(state => state.auth)
    const profile = useSelector(state => state.account.data)

    // IF USER IS NOT LOGGED IN REDIRECT TO LOGIN PAGE
    if (!auth.uid) {
        window.location.href = "/signin"
    }
    // IF USER IS NOT VERIFIED REDIRECT TO VERIFY PAGE
    if (!auth.isVerified) {
        window.location.href = "/verify"
    }

    // SET DEFAULT STATE FOR SUBACCOUNT
    const [registerData, setRegisterData] = useState({ type: 'Patient', city: '', state: '', country: '', invitedBy: '', firstName: '', lastName: '', email: '', dateofBirth: null, accountType: 'subsaccount', hCard: '', dateofHCExp: null, countryDialCode: '', phone: '', gender: '' })
    const [step, setStep] = useState(1)
    const [loader, setLoader] = useState(false)
    const [showModel, setShowModel] = useState(false)
    const [error, setError] = useState('')

    // UPDATE STEPS AND SAVE IN STATE
    const updateStepNo = async (stepNo, data) => {
        // IF RECEIVED DATA HAS DATE OF BIRTH THEN CHECK MEMBER WILL BE SUBACCOUNT OR NOT. IF MEMBER IS 16 YEARS OLD THEN ITS NOT SUBACCOUNT OTHERWISE IT IS
        if (data.hasOwnProperty('dateofBirth') && data.dateofBirth) {
            data.isSubAccount = true
            if (moment().diff(moment(data.dateofBirth), 'years') >= 16) {
                data.isSubAccount = false
            }
            else {
                // UPDATE STEP NO IF MEMBER IS LESS THAN 16 YEARS, SKIP NEXT STEP
                if (stepNo > step)
                    stepNo = stepNo + 1
            }
        }

        // RETURNED STEPS ARE STARTED FROM 3 AS SAME COMPONENTS ARE USED FOR PATIENT REGISTERATION AND SUBACCOUNTS. PATIENT SIGNUP HAS 2 EXTRA STEPS AT START THOSE ARE CURRENTLY NOT IN WORKING
        // WE WILL START FROM STEP 1. SO WE WILL NEED TO DECREASE THE STEP BY 2
        stepNo = stepNo - 2

        // SKIP CONTACT INFO IF ADDED MEMBER IS SUBACCOUNT(LESS THAN 16 YEARS)
        if (stepNo < step && stepNo === 3 && registerData.isSubAccount === true) {
            stepNo = stepNo - 1
        }

        // UPDATE STEP NO AND UPDATE ALL MEMBER INFORMATION
        setStep(stepNo)
        let updatedData = registerData
        updatedData = { ...updatedData, ...data }
        setRegisterData(updatedData)
        // IF ITS LAST STEP THEN SUBMIT FORM
        if (stepNo > 4) {
            registerAccount(updatedData)
        }
    }

    // CREATE ACCOUNT
    const registerAccount = async (data) => {
        // SET ALL MEMBER DATA TO SEND TO API
        let email = data.email
        if (!email) {
            email = profile.email
            email = email.replace('@', `_${moment().unix()}@`)
        }
        let accountData = {
            "invitedBy": "",
            "email": email,
            "disabledEmail": false,
            "guardian_full_name": "",
            "guardian_relationship": "",
            "isLegalGuardian": "",
            "firstName": data.firstName,
            "lastName": data.lastName,
            "dateofBirth": data.dateofBirth,
            "gender": data.gender,
            "phone": data.phone,
            "countryDialCode": data.countryDialCode,
            "zone": moment.tz.guess(),
            "hCard": data.hCard,
            "dateofHCExp": data.dateofHCExp,
            "type": "Patient",
            "isSubAccount": data.isSubAccount,
            "parentId": auth.uid,
        }

        setLoader(true)
        setShowModel(true)
        // CALL ADDING SUBACCOUNT METHOD
        let res = await addSubAccount(accountData)
        if (!res.status) {
            setError(res.message)
            setShowModel(false)
        }
        setLoader(false)
    }

    const redirectToDashBoardFun = () => {
        window.location.href = "/dashboard"
    }
    return (
        <div style={centerDiv}>
            {error && <Alert message={error} type="error" />}
            {step === 1 ?
                <GetPatientName updateStepNo={updateStepNo} registerData={registerData} />
                : step === 2 ?
                    <PersonalInfo updateStepNo={updateStepNo} registerData={registerData} />
                    : step === 3 ?
                        <ContactInfo updateStepNo={updateStepNo} registerData={registerData} />
                        :
                        <HealthCard updateStepNo={updateStepNo} registerData={registerData} />
            }

            <Modal
                title={`Create Sub Account`}
                visible={showModel}
                footer={loader ? null : <Button color="secondary" variant="contained" onClick={redirectToDashBoardFun}>Ok</Button>}
            >
                <h3>{loader ? "Subaccount creation is In Progress. please wait. " : "Sub Account Created Succesfully"}</h3>
            </Modal>
        </div>
    )
}

const centerDiv = {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '20px auto'
}

export default AddPatients
