import React, { Component } from 'react'
import { Grid, Button, Avatar, TextField } from '@material-ui/core'
import withStyles from "@material-ui/core/styles/withStyles"
import Sick from "../../assets/images/sick.png"
import Skin from "../../assets/images/skin.png"
import Referral from "../../assets/images/referral.png"
import Prescription from "../../assets/images/prescription.png"
import Other from "../../assets/images/other.png"
import Stress from "../../assets/images/stress.png"
import Covid19 from "../../assets/images/covid19.png"
import BloodText from "../../assets/images/bloodtest.png"
import WellnessVisit from "../../assets/images/wellnesstest.png"
import IVTAH from "../../assets/images/ivtah.png"
import ECG from "../../assets/images/ecg.png"
import INJECTIONS from "../../assets/images/injections.png"
import CRONICPREVENTATIVE from "../../assets/images/cronic_preventative.png"
import COVID19ASSESSMENT from "../../assets/images/covid19assessment.png"
import EYEANDEAR from "../../assets/images/eande.png"
import KIDSHEALTH from "../../assets/images/kidshealth.png"
import MENWOMENHEALTH from "../../assets/images/mwhealth.png"
import SINUSCOUGH from "../../assets/images/sinus.png"
import FLU from "../../assets/images/flu.png"
import FOODPOISIONING from "../../assets/images/foodpoisoning.png"
import HANGOVER from "../../assets/images/hangover.png"
import HYDRATION from "../../assets/images/hydration.png"
import MULTI12 from "../../assets/images/multi12.png"
import MAYER from "../../assets/images/mayer.png"
import { connect } from 'react-redux'
import { URGENTPCRCOST, RAPIDPCRCOST, NOMEMBERCOST, IVTHERAPYMEMBERCOST, IVTHERAPYNOMEMBERCOST, RAPIDANTIGENCOST, AGENCYURGENTPCRCOST, AGENCYPCRCOST, RHYNOGOCOST } from '../../config'
import styled from 'styled-components'
import { Modal } from 'antd'

const availableSpecialitesForWellness = ['Nurse Practitioner', 'Family Medicine']
const styles = theme => ({
    font: {
        fontFamily: 'Roboto Regular',
    },
    popup: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    popupDiv: {
        backgroundColor: '#fff',
        padding: theme.spacing(5),
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: { padding: theme.spacing(2) }
    },
    popupHeading: {
        fontFamily: 'inherit',
        fontWeight: 700,
        color: '#292929',
        fontSize: '1.2rem'
    },
    popupSubHeading: {
        fontSize: '1rem',
        paddingTop: theme.spacing(4),
        marginBottom: 0,
    },
    textTransform: {
        textTransform: 'unset'
    },
    reasonList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    avatar: {
        width: '125px',
        height: '125px',
        marginTop: theme.spacing(3)
    },
    popupButton: {
        marginTop: theme.spacing(5),
        fontFamily: 'inherit',
        fontSize: '1rem',
        color: "#fff",
        padding: '0px 50px',
        [theme.breakpoints.down('sm')]: { marginTop: theme.spacing(2) }
    },
    reasonDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
    },
    reasonButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.down('sm')]: { flexDirection: 'column' }
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    rhynogoDivWidth: {
        width: '210px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: { width: '100%' }
    },
    covidTypes: {
        display: 'flex',
        paddingTop: theme.spacing(4),
        [theme.breakpoints.down('sm')]: { flexDirection: 'column' }
    },
    covidItem: {
        margin: theme.spacing(0, 2),
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: { width: '93%' }
    },
    covidButton: {
        marginTop: theme.spacing(3),
        [theme.breakpoints.down('sm')]: { margin: theme.spacing(3, 0) }
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
})

const AppointmentReason = styled(Modal)`
.ant-modal-title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
}
.ant-modal-header{
    border-radius: 9px 9px 0 0;
    border-bottom:none;
}
.ant-modal-body{
    text-align: center;
    // justify-content: center;
}
.ant-modal-content{
    
    border-radius: 9px;
}
.ant-modal-footer{
    text-align:center;
    border-top:none;

}


`
class Reason extends Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        if (props.appointmentData.appointmentType === 'rhynogo' || (props.appointmentData.hasOwnProperty('isRhynoGoAppointment') && props.appointmentData.isRhynoGoAppointment)) {
            if (props.appointmentData.hasOwnProperty('reason') && props.appointmentData.reason !== '')
                this.props.updateStepNo(5, { previousStep: props.appointmentData.previousStep })
        }
        else if (props.appointmentData.doctorSpecialty !== 'Family Medicine') {
            this.props.updateStepNo(5, { reason: '', previousStep: props.appointmentData.previousStep, rhynoGoCost: 0, covidType: '' })
        }

        this.state = {
            getReason: false,
            reason: '',
            showError: false,
            showDiv: '',
            covidType: '',
            isCovid19: false,
            cost: 0,
            showIVTherapyDiv: false,
            allowedSpecialities: { data: [] },
            showUrgentPCR: false
        }
    }
    selectOtherReasons = (reason, divType, cost = 0) => {
        this.setState({ covidType: reason, showDiv: divType, cost: cost })
    }

    nextStep = async (step) => {
        let isCovid19True = this.state.isCovid19
        let isUrgentPCR = false
        if (isCovid19True) {
            let listOfCovid19 = ['RAPID ANTIGEN', 'PCR', 'URGENT PCR']

            if (this.state.reason === 'URGENT PCR') {
                isUrgentPCR = true
            }
            if (listOfCovid19.indexOf(this.state.reason) === -1) {
                isCovid19True = false
            }
        }
        this.props.updateStepNo(step, { isUrgentPCR: isUrgentPCR, isCovid19: isCovid19True, reason: this.state.reason, covidType: this.state.covidType, rhynoGoCost: this.state.cost, allowedSpecialities: this.state.allowedSpecialities.data, previousStep: 4 })
    }

    getReason = () => {
        if (this._isMounted)
            this.setState({ getReason: !this.state.getReason })
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true

        if (this.props.appointmentData.hasOwnProperty('isAgency') && this.props.appointmentData.isAgency) {
            this.selectOtherReasons('"AT HOME COVID-19 TESTING FOR TRAVEL (<12 HOURS)"', 'covid19')
        }
    }
    changeReason = (e) => {
        if (this._isMounted) {
            if (e.target.value === '')
                this.setState({ showError: true, reason: e.target.value })
            else
                this.setState({ showError: false, reason: e.target.value })
        }
    }
    setCovidType = (type, cost, specialitiesArray) => {
        let reason = this.state.covidType
        this.setState({ covidType: type, reason: reason, cost: cost, allowedSpecialities: { data: specialitiesArray } }, () => { this.nextStep(9) })
    }
    setIVTherapyReason = (reason) => {
        this.setState({ reason: reason, showIVTherapyDiv: true })
    }
    // Please call 416-830-8181 prior to booking
    setReason = (reason, price = 0, step = 5, specialitiesArray = [], isCovid19 = false) => {
        if (reason === 'URGENT PCR') {
            this.setState({ showUrgentPCR: true, reason: reason, cost: price, isCovid19: isCovid19, allowedSpecialities: { data: specialitiesArray } })
        }
        else {
            this.setState({ reason: reason, cost: price, isCovid19: isCovid19, allowedSpecialities: { data: specialitiesArray } }, () => { this.nextStep(step) })
        }
    }
    setPrice = (price) => {
        this.setState({ cost: price }, () => { this.nextStep(10) })
    }
    CancelUrgentPCR = () => {
        this.setState({ showUrgentPCR: false })
    }
    render() {
        let { classes, account } = this.props
        return (
            <Grid className={classes.font}>
                <Grid className={classes.popup}>
                    <Grid className={classes.popupDiv}
                        style={{ width: this.props.appointmentData.appointmentType !== 'rhynogo' ? '35rem' : '45rem' }}>

                        <AppointmentReason
                            className="modal"
                            title={<></>}
                            closable={false}
                            visible={this.state.showUrgentPCR}
                            width={500}
                            zIndex={"2000"}
                            footer={<></>}

                        >
                            <Grid className={classes.mainDiv}>
                                <h2>Please call 416-830-8181 prior to booking</h2>
                                <Grid className={classes.buttons}>
                                    <Button variant="contained" onClick={() => this.CancelUrgentPCR()} color={'primary'} className={classes.popupButton + ' ' + classes.whiteColor}>
                                        Cancel
                                    </Button>
                                    <Button variant="contained" onClick={() => this.nextStep(11)} color={'primary'} className={classes.popupButton + ' ' + classes.whiteColor}>
                                        Ok
                                    </Button>
                                </Grid>
                            </Grid>
                        </AppointmentReason>

                        {this.props.appointmentData.appointmentType !== 'rhynogo' ?
                            <span className={classes.popupHeading}>How can {this.props.appointmentData.showDr && "Dr."} <span style={{ textTransform: "Capitalize" }}>{this.props.appointmentData.doctorName}</span>{!this.props.appointmentData.showDr && ", NP"} can help you?</span>
                            :
                            this.state.showDiv === 'welness' ?
                                <span className={classes.popupHeading}>Select the reason for your assessment</span>
                                :
                                <span className={classes.popupHeading}>Please choose a RhynoGo Service</span>
                        }
                        {!this.state.getReason ?
                            this.props.appointmentData.appointmentType !== 'rhynogo' ?
                                <Grid>
                                    <Grid className={classes.reasonList}>
                                        <div className={classes.cursorPointer} onClick={() => this.setReason('Feeling Sick')}><Avatar alt="Feeling Sick" src={Sick} className={classes.avatar}></Avatar> Feeling Sick</div>
                                        <div className={classes.cursorPointer} onClick={() => this.setReason('Skin Problem')}><Avatar alt="Skin Problem" src={Skin} className={classes.avatar}></Avatar> Skin Problems</div>
                                        <div className={classes.cursorPointer} onClick={() => this.setReason('Stress & Wellness')}><Avatar alt="Stress & Wellness" src={Stress} className={classes.avatar}></Avatar> Stress & Wellness</div>
                                        <div className={classes.cursorPointer} onClick={() => this.setReason('Prescription')}><Avatar alt="Prescription" src={Prescription} className={classes.avatar}></Avatar> Prescription</div>
                                        <div className={classes.cursorPointer} onClick={() => this.setReason('Referral')}><Avatar alt="Referral" src={Referral} className={classes.avatar}></Avatar> Referral</div>
                                        <div className={classes.cursorPointer} onClick={this.getReason}><Avatar alt="Something Else" src={Other} className={classes.avatar}></Avatar> Something Else</div>
                                    </Grid>
                                    <Button variant="contained"
                                        onClick={() => {
                                            this.nextStep(this.props.appointmentData.previousStep, '')
                                        }
                                        }
                                        color={'primary'} className={classes.popupButton}>
                                        Back
                                    </Button>
                                </Grid>
                                :
                                this.state.showIVTherapyDiv ?
                                    <Grid className={classes.mainDiv}>
                                        <p className={classes.popupHeading + ' ' + classes.popupSubHeading}>
                                            Have you seen a RhynoCare provider provider for consultation regarding this?
                                    </p>
                                        <Grid className={classes.buttons}>
                                            <Button variant="contained" onClick={() => this.setReason(this.state.reason, account.subscriptionStatus === 0 ? IVTHERAPYNOMEMBERCOST : IVTHERAPYMEMBERCOST, 1, ['Nurse Practitioner'])} color={'primary'} className={classes.popupButton + ' ' + classes.whiteColor}>
                                                No
                                        </Button>
                                            <Button variant="contained" onClick={() => this.setPrice(account.subscriptionStatus === 0 ? IVTHERAPYNOMEMBERCOST : IVTHERAPYMEMBERCOST)} color={'primary'} className={classes.popupButton + ' ' + classes.whiteColor}>
                                                Yes
                                        </Button>
                                        </Grid>
                                    </Grid>
                                    :
                                    this.state.showDiv === 'covid19' ?
                                        <Grid className={classes.mainDiv}>
                                            <Grid className={classes.covidTypes}>
                                                <Grid className={classes.covidItem}>
                                                    <span className={classes.popupHeading}>
                                                        {`URGENT PCR < 6 HOURS: $ ${this.props.appointmentData.hasOwnProperty('isAgency') && this.props.appointmentData.isAgency ? AGENCYURGENTPCRCOST : URGENTPCRCOST} + HST`}
                                                    </span>
                                                    <Button className={classes.covidButton} fullWidth variant="contained" onClick={() => this.setReason('URGENT PCR', this.props.appointmentData.hasOwnProperty('isAgency') && this.props.appointmentData.isAgency ? AGENCYURGENTPCRCOST : URGENTPCRCOST, 11, [], 1)} color={'secondary'}>
                                                        Choose
                                                    </Button>
                                                </Grid>
                                                <Grid className={classes.covidItem}>
                                                    <span className={classes.popupHeading}>
                                                        PCR TESTING: ${this.props.appointmentData.hasOwnProperty('isAgency') && this.props.appointmentData.isAgency ? AGENCYPCRCOST : RAPIDPCRCOST} + HST
                                                    </span>
                                                    <Button className={classes.covidButton} fullWidth variant="contained" onClick={() => this.setReason('PCR', this.props.appointmentData.hasOwnProperty('isAgency') && this.props.appointmentData.isAgency ? AGENCYPCRCOST : RAPIDPCRCOST, 11, [], 1)} color={'secondary'}>
                                                        Choose
                                                    </Button>
                                                </Grid>
                                                {this.props.appointmentData.hasOwnProperty('isAgency') && this.props.appointmentData.isAgency ? "" :
                                                    <Grid className={classes.covidItem}>
                                                        {/* SKYPE CONVERSATION 27 apr 2022
                                                        1) Change rapid antigen pricing to 70 + HST per person, regardless of number of patients.
                                                        UPDATE THE PRICE TO 70 */}
                                                        <span className={classes.popupHeading}>
                                                            ANTIGEN TESTING: $70 + HST Per Person (US, EUROPE & CARIBBEAN ONLY)
                                                    </span>
                                                        <Button className={classes.covidButton} fullWidth variant="contained" onClick={() => this.setReason('RAPID ANTIGEN', RAPIDANTIGENCOST, 11, [], 1)} color={'secondary'}>
                                                            Choose
                                                    </Button>
                                                    </Grid>
                                                }
                                            </Grid>
                                            <Button variant="contained" onClick={() => {
                                                if (this.props.appointmentData.hasOwnProperty('isAgency') && this.props.appointmentData.isAgency)
                                                    this.nextStep(this.props.appointmentData.previousStep, '')
                                                else
                                                    this.selectOtherReasons('', '')
                                            }} color={'primary'} className={classes.popupButton + ' ' + classes.whiteColor}>
                                                Back
                                    </Button>
                                        </Grid>
                                        :
                                        this.state.showDiv === 'welness' ?
                                            <Grid>
                                                <Grid className={classes.reasonList}>
                                                    <div className={classes.cursorPointer + " " + classes.rhynogoDivWidth} onClick={() => this.setReason('RHYNOGO COVID-19 ASSESSMENT', account.subscriptionStatus === 0 ? NOMEMBERCOST : -1, 1, availableSpecialitesForWellness)}><Avatar alt="COVID-19 ASSESSMENT" src={COVID19ASSESSMENT} className={classes.avatar}></Avatar> COVID-19 ASSESSMENT</div>
                                                    <div className={classes.cursorPointer + " " + classes.rhynogoDivWidth} onClick={() => this.setReason('RHYNOGO SINUS, COUGH & ALLERGY', account.subscriptionStatus === 0 ? NOMEMBERCOST : -1, 1, availableSpecialitesForWellness)}><Avatar alt="SINUS, COUGH & ALLERGY" src={SINUSCOUGH} className={classes.avatar}></Avatar> SINUS, COUGH & ALLERGY</div>
                                                    <div className={classes.cursorPointer + " " + classes.rhynogoDivWidth} onClick={() => this.setReason('RHYNOGO EYE & EAR', account.subscriptionStatus === 0 ? NOMEMBERCOST : -1, 1, availableSpecialitesForWellness)}><Avatar alt="EYE & EAR" src={EYEANDEAR} className={classes.avatar}></Avatar> EYE & EAR</div>
                                                    <div className={classes.cursorPointer + " " + classes.rhynogoDivWidth} onClick={() => this.setReason('RHYNOGO MEN & WOMEN\'S HEALTH', account.subscriptionStatus === 0 ? NOMEMBERCOST : -1, 1, availableSpecialitesForWellness)}><Avatar alt="MEN & WOMEN\'S HEALTH" src={MENWOMENHEALTH} className={classes.avatar}></Avatar> MEN & WOMEN'S HEALTH</div>
                                                    <div className={classes.cursorPointer + " " + classes.rhynogoDivWidth} onClick={() => this.setReason('RHYNOGO KID\'S HEALTH', account.subscriptionStatus === 0 ? NOMEMBERCOST : -1, 1, availableSpecialitesForWellness)}><Avatar alt="KID'S HEALTH" src={KIDSHEALTH} className={classes.avatar}></Avatar> KID'S HEALTH</div>
                                                    <div className={classes.cursorPointer + " " + classes.rhynogoDivWidth} onClick={() => this.setReason('RHYNOGO CHRONIC & PREVENTATIVE', account.subscriptionStatus === 0 ? NOMEMBERCOST : -1, 1, availableSpecialitesForWellness)}><Avatar alt="CHRONIC & PREVENTATIVE" src={CRONICPREVENTATIVE} className={classes.avatar}></Avatar> CHRONIC & PREVENTATIVE</div>
                                                </Grid>
                                                <Button variant="contained" onClick={() => this.selectOtherReasons('', '')} color={'primary'} className={classes.popupButton}>
                                                    Back
                                    </Button>
                                            </Grid>
                                            :
                                            this.state.showDiv === 'hydration' ?
                                                <Grid>
                                                    <Grid className={classes.reasonList}>
                                                        <div className={classes.cursorPointer + " " + classes.rhynogoDivWidth} onClick={() => this.setIVTherapyReason('REHYDRATION THERAPY')}><Avatar alt="REHYDRATION THERAPY" src={HYDRATION} className={classes.avatar}></Avatar> REHYDRATION THERAPY</div>
                                                        <div className={classes.cursorPointer + " " + classes.rhynogoDivWidth} onClick={() => this.setIVTherapyReason('HANGOVER THERAPY')}><Avatar alt="HANGOVER THERAPY" src={HANGOVER} className={classes.avatar}></Avatar> HANGOVER THERAPY</div>
                                                        <div className={classes.cursorPointer + " " + classes.rhynogoDivWidth} onClick={() => this.setIVTherapyReason('FOOD POISONING RELIEF')}><Avatar alt="FOOD POISONING RELIEF" src={FOODPOISIONING} className={classes.avatar}></Avatar> FOOD POISONING RELIEF</div>
                                                        <div className={classes.cursorPointer + " " + classes.rhynogoDivWidth} onClick={() => this.setIVTherapyReason('MULTI 12 COCKTAIL')}><Avatar alt="MULTI 12 COCKTAIL" src={MULTI12} className={classes.avatar}></Avatar> MULTI 12 COCKTAIL</div>
                                                        <div className={classes.cursorPointer + " " + classes.rhynogoDivWidth} onClick={() => this.setIVTherapyReason('MYER’S COCKTAIL')}><Avatar alt="MYER’S COCKTAIL" src={MAYER} className={classes.avatar + ' mayersClass'}></Avatar> MYER’S COCKTAIL</div>
                                                        <div className={classes.cursorPointer + " " + classes.rhynogoDivWidth} onClick={() => this.setIVTherapyReason('FLU RELIEF')}><Avatar alt="FLU RELIEF" src={FLU} className={classes.avatar}></Avatar> FLU RELIEF</div>
                                                    </Grid>
                                                    <Button variant="contained" onClick={() => this.selectOtherReasons('', '')} color={'primary'} className={classes.popupButton}>
                                                        Back
                                    </Button>
                                                </Grid>
                                                :
                                                <Grid>
                                                    <Grid className={classes.reasonList}>
                                                        <div className={classes.cursorPointer + " " + classes.rhynogoDivWidth} onClick={() => this.selectOtherReasons('"AT HOME COVID-19 TESTING FOR TRAVEL (<12 HOURS)"', 'covid19')}><Avatar alt="AT HOME COVID-19 TESTING FOR TRAVEL (<12 HOURS)" src={Covid19} className={classes.avatar}></Avatar> {"AT HOME COVID-19 TESTING FOR TRAVEL (<12 HOURS)"}</div>
                                                        {/* <div className={classes.cursorPointer + " " + classes.rhynogoDivWidth} onClick={() => this.setReason('AT HOME COVID-19 TESTING FOR TRAVEL (<12 HOURS)', COVID19COST, 10,availableSpecialitesForWellness, 1)}><Avatar alt="AT HOME COVID-19 TESTING FOR TRAVEL (<12 HOURS)" src={Covid19} className={classes.avatar}></Avatar> {"AT HOME COVID-19 TESTING FOR TRAVEL (<12 HOURS)"}</div> */}
                                                        <div className={classes.cursorPointer + " " + classes.rhynogoDivWidth} onClick={() => this.setReason('AT HOME BLOOD AND URINE TESTING', account.subscriptionStatus === 0 ? RHYNOGOCOST : -1, 9, availableSpecialitesForWellness)}><Avatar alt="AT HOME BLOOD AND URINE TESTING" src={BloodText} className={classes.avatar}></Avatar> AT HOME BLOOD AND URINE TESTING</div>
                                                        <div className={classes.cursorPointer + " " + classes.rhynogoDivWidth} onClick={() => this.setReason('AT HOME ECG TESTING', account.subscriptionStatus === 0 ? RHYNOGOCOST : -1, 9, availableSpecialitesForWellness)}><Avatar alt="AT HOME ECG TESTING" src={ECG} className={classes.avatar}></Avatar> AT HOME ECG TESTING</div>
                                                        <div className={classes.cursorPointer + " " + classes.rhynogoDivWidth} onClick={() => this.setReason('AT HOME IM/SUBQ INJECTIONS', account.subscriptionStatus === 0 ? RHYNOGOCOST : -1, 9, availableSpecialitesForWellness)}><Avatar alt="AT HOME IM/SUBQ INJECTIONS" src={INJECTIONS} className={classes.avatar}></Avatar> AT HOME IM/SUBQ INJECTIONS</div>
                                                        <div className={classes.cursorPointer + " " + classes.rhynogoDivWidth} onClick={() => this.selectOtherReasons('AT HOME WELLNESS VISITS', 'welness')}><Avatar alt="AT HOME WELLNESS VISITS" src={WellnessVisit} className={classes.avatar}></Avatar> AT HOME WELLNESS VISITS</div>
                                                        <div className={classes.cursorPointer + " " + classes.rhynogoDivWidth} onClick={() => this.selectOtherReasons('IV THERAPY AND HYDRATION', 'hydration')}><Avatar alt="IV THERAPY AND HYDRATION" src={IVTAH} className={classes.avatar}></Avatar> IV THERAPY AND HYDRATION</div>
                                                    </Grid>
                                                    <Button variant="contained" onClick={() => this.nextStep(this.props.appointmentData.previousStep, '')} color={'primary'} className={classes.popupButton}>
                                                        Back
                                </Button>
                                                </Grid>
                            :
                            <Grid className={classes.reasonDiv}>
                                <div onClick={this.getReason}><Avatar alt="Referral" src={Other} className={classes.avatar}></Avatar> Something Else</div>
                                <TextField
                                    value={this.state.reason}
                                    onChange={this.changeReason}
                                    label="Reason"
                                    variant="outlined"
                                    style={{ marginTop: '24px' }}
                                    required={true}
                                    error={this.state.showError}
                                />
                                <Grid className={classes.reasonButtons}>
                                    <Button variant="contained" onClick={this.getReason} color={'primary'} className={classes.popupButton}>
                                        Back
                                    </Button>
                                    <Button variant="contained" onClick={() => this.nextStep(5)} color={'secondary'} className={classes.popupButton}>
                                        Continue
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        account: state.account.data
    };
};
export default connect(mapStateToProps, null)(withStyles(styles)(Reason))